import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Banner, BannerContent, Heading, Subheading, Press, PressContent, Button } from './BannerSection.style';
import WsjImg from '../../../../assets/images/logos/wsj-logo-white.png';
import AbaImg from '../../../../assets/images/logos/abajournal-logo-white.png';
import BloombergImg from '../../../../assets/images/logos/bloomberg-logo-white.png';
import ForbesImg from '../../../../assets/images/logos/forbes-logo-white.png';
import Law360Img from '../../../../assets/images/logos/law360-logo-white.png';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <BannerContent>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                LexShares offers law firm financing
              </Heading>
              <Subheading>
                LexShares provides non-recourse litigation funding designed specifically for attorneys and law firms.
              </Subheading>
            </Cell>
          </Grid>
          <Grid>
            <Cell small={12} className="text-center">
              <Button href="/funding/case">
                Get Funding
              </Button>
            </Cell>
          </Grid>

          <Press>
            <PressContent>
              <img src={WsjImg}/>
              <img src={AbaImg}/>
              <img src={BloombergImg}/>
              <img src={ForbesImg}/>
              <img src={Law360Img}/>
            </PressContent>
          </Press>
        </BannerContent>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
