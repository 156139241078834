import React from "react";
import Carousel from '@brainhubeu/react-carousel';
import { Cell } from 'react-foundation';
import { CarouselContainer, LeftArrow, RightArrow } from './CaseStudiesCarousel.style';
import CaseStudyCard from '../CaseStudyCard';

import '@brainhubeu/react-carousel/lib/style.css';
import './CaseStudiesCarousel.css';

function CaseStudiesCarousel({caseStudies = []}) {
  const renderCaseStudyCards = () => {
    return caseStudies.map((caseStudy, i) => {
      return (
        <Cell small={12} medium={6} large={4} key={`case-study-carousel-${i}`}>
          <CaseStudyCard {...caseStudy} />
        </Cell>
      );
    });
  }

  return (
    <CarouselContainer>
      <Carousel
        arrows
        slidesPerPage={3}
        slidesPerScroll={3}
        breakpoints={{
          864: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
            arrows: false,
            arrowLeft: null,
            arrowRight: null
          },
          1200: {
            slidesPerPage: 2,
            slidesPerScroll: 2
          }
        }}
        arrowLeft={<LeftArrow />}
        arrowRight={<RightArrow />}
        addArrowClickHandler
        infinite
        dots
        slides={renderCaseStudyCards()}
      >
      </Carousel>
    </CarouselContainer>
  );
}

export default CaseStudiesCarousel;
