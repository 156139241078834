import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import axios from 'axios';
import InputField from '../../Form/InputField';
import { Alert, Error, Form, Container, InputSubmit } from './Content.style';

const Content = ({userPasswordPath, csrfToken}) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      user: {
        email: email,
      },
      authenticity_token: csrfToken
    }

    axios.post(`${userPasswordPath}.json`, payload).then((response) => {
      window.location = response.data.location;
      setErrorMessage("");
      setIsSubmitting(false);
    }).catch((e) => {
      setErrorMessage(e.response.data.error);
      setIsSubmitting(false);
    })
  }

  return(
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid className="grid-x grid-margin-x align-center">
          <Cell small={12} medium={7} large={5}>
            {errorMessage && <Alert>Please review the problems below</Alert>}
            <InputField
              type="email"
              label="Email"
              name="email"
              changeHandler={(e) => setEmail(e.target.value)}
              value={email}
              required={true}
              autoFocus={true}
            />
            {errorMessage && <Error>{errorMessage}</Error>}
            <InputSubmit type="submit" value="Reset Password" disabled={isSubmitting} />
          </Cell>
        </Grid>
      </Form>
    </Container>
  )
}

export default Content;