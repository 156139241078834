import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Nav, LeftNav, RightNav, Name, Title } from './NavSection.style';

function NavSection({employee}) {
  return (
    <Fragment>
      <Nav>
        <Grid>
          <Cell small={6}>
            <LeftNav href={employee.prev.path}>
              <Name>
                { employee.prev.full_name }
              </Name>
              <Title>
                { employee.prev.title }
              </Title>
            </LeftNav>
          </Cell>

          <Cell small={6}>
            <RightNav href={employee.next.path}>
              <Name>
                { employee.next.full_name }
              </Name>
              <Title>
                { employee.next.title }
              </Title>
            </RightNav>
          </Cell>
        </Grid>
      </Nav>
    </Fragment>
  )
}

export default NavSection;
