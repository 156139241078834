import styled from 'styled-components';

import leftArrowIcon from '../../../../assets/images/icons/left-arrow.png';
import rightArrowIcon from '../../../../assets/images/icons/right-arrow.png';

export const CarouselContainer = styled.div`
  width: 100%;
`

export const LeftArrow = styled.div`
  background: url(${leftArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const RightArrow = styled.div`
  background: url(${rightArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`
