import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import * as Globals from '../../Globals';

export const Banner = styled(SB.Banner)`
  background-color: ${props => props.theme.colors.white};
  color: black;

  ${breakpoint('large')`
    padding: 0;
  `}
`;

export const Content = styled(SB.Content)`
  ${breakpoint('large')`
    padding: 8% 3% 6%;
  `}
`;

export const BannerProp = styled(SB.Diamonds)``;

export const Heading = styled(SB.Heading)`
  margin-bottom: 40px;
`;

export const Subheading = styled(SB.Subheading)`
  max-width: 672px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const Button = styled(Globals.ButtonLink)`
  width: 100%;

  ${breakpoint('medium')`
    width: 208px;
  `}
`

export const ButtonInverse = styled(Button)`
  background: transparent !important;
  color: #8247ba;
  border: 2px solid #8247ba;
  padding: 13px 24px 13px 24px;
  &:hover {
    background: transparent !important;
    color: #8247ba !important;
    opacity: .7;
  }
`
