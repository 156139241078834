import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation'
import axios from 'axios';

import { Checkmark, Content, Form, FormContainer, FormHeader, Header, InfoContainer, InputField, InputSubmit, Logo, LogosContainer, SecondaryHeader, SecondarySubheading, Section, Subheading, CheckmarkContainer, InfoItem, ListItem } from './GuideSection.style';
const Images = require.context('../../../../assets/images', true);

const GuideSection = ({litigationFinanceRequestsPath, guideConfirmationPath, csrfToken}) => {
  const [email, setEmail] = useState("");
  const list = ['Gain an in-depth understanding of litigation finance', 'Understand how experts select cases', 'Review industry growth and regulations', 'Look into the future of litigation finance'];

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      litigation_finance_request: {
        email: email,
        referral_link: window.location.href,
      },
      authenticity_token: csrfToken
    }

    axios.post(litigationFinanceRequestsPath, payload).then(() => {
      window.location = guideConfirmationPath;
    })
  }

  return (
    <Section>
      <Content>
        <Grid className="grid-x grid-margin-x">
          <Cell className="small-order-2 large-order-1" large={6} medium={12}>
            <InfoContainer>
              <Header>
                Download our free litigation finance guide.
              </Header>
              <Subheading>
                Litigation finance can help enable attorneys, law firms, plaintiffs and in-house legal departments to secure capital, reduce risk, and improve litigation outcomes.
              </Subheading>
              <LogosContainer>
                <Grid className="grid-x grid-margin-x">
                  <Cell small={3}>
                    <Logo src={Images("./logos/wsj-logo-white.png").default} />
                  </Cell>
                  <Cell small={3}>
                    <Logo src={Images("./logos/bloomberg-logo-white.png").default} />
                  </Cell>
                  <Cell small={3}>
                    <Logo src={Images("./logos/law360-logo-white.png").default} />
                  </Cell>
                  <Cell small={3}>
                    <Logo src={Images("./logos/forbes-logo-white.png").default} />
                  </Cell>
                </Grid>
              </LogosContainer>
              <SecondarySubheading>
                Litigation finance can help enable attorneys, law firms, plaintiffs and in-house legal departments to secure capital, reduce risk, and improve litigation outcomes.
              </SecondarySubheading>
            </InfoContainer>
          </Cell>
          <Cell className="small-order-1 large-order-2" large={6} medium={12}>
              <SecondaryHeader>
                Download our free litigation finance guide.
              </SecondaryHeader>
            <FormContainer>
              <FormHeader>Get the free guide</FormHeader>
              {list.map((e) => (
                <ListItem key={e}>
                  <CheckmarkContainer>
                    <Checkmark src={Images("./icons/checkmark.png").default} />
                  </CheckmarkContainer>
                  <InfoItem>{e}</InfoItem>
                </ListItem>
              ))}
              <Form onSubmit={handleSubmit}>
                <Grid className="grid-x">
                  <Cell small={12} medium={7}>
                    <InputField type="email" placeholder="Email Address" name="email" onChange={(e) => setEmail(e.target.value)} value={email} required={true} />
                  </Cell>
                  <Cell small={12} medium={5}>
                    <InputSubmit type="submit" value="Download Guide" />
                  </Cell>
                </Grid>
              </Form>
            </FormContainer>
          </Cell>
        </Grid>
      </Content>
    </Section>
  )
}

export default GuideSection;