import React, { Fragment } from 'react'
import { Button } from './InputButton.style'

function InputButton({value = 'Submit', disabled = false, disabledColor = null, type=null, color = null}) {
  return (
    <Fragment>
      <Button type={type} disabled={disabled} disabledColor={disabledColor} color={color}>{value}</Button>
    </Fragment>
  )
}

export default InputButton
