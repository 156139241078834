import axios from 'axios'

/**
 * Serves as a wrapper around Axios in order to provide some global
 * configuration.
 */

const instance = axios.create()

// Handles certain global errors, such as when a session expires.
instance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 406) {
      // Redirects user back to login page due to expired session.
      window.location.href = '/users/sign_in'
    }
    return Promise.reject(error)
  }
);

export default instance
