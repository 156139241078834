import React, {Fragment, useEffect} from 'react'
import Feed from './Feed'
import Mixpanel from '../../Mixpanel'
import {
  Content,
  Heading
} from './ActivityView.style'

/**
 * Wrapper for the long scroll feed that shows blog, article and case widgets.
 */
function ActivityView({viewClickHandler, onboarded, csrfToken, investmentCount,
  completedStep, completedPercent, articles, courtCases, setAlertBar}) {

  useEffect(() => {
    Mixpanel.track('Viewed activity feed')
  }, [])

  return (
    <Fragment>
      <Content>
        <Heading>Activity Feed</Heading>
          <Feed
            onboarded={onboarded}
            completedStep={completedStep}
            completedPercent={completedPercent}
            articles={articles}
            courtCases={courtCases}
            csrfToken={csrfToken}
            setAlertBar={setAlertBar}
            viewClickHandler={viewClickHandler}
            investmentCount={investmentCount}
          />
      </Content>
    </Fragment>
  )
}

export default ActivityView
