import React, {useState, useEffect} from 'react'
import {Cell, Grid} from 'react-foundation'
import SideColumn from '../SideColumn'
import InvestmentHistoryWidget from './InvestmentHistoryWidget'
import InvestmentTypeWidget from './InvestmentTypeWidget'
import InvestmentProfileWidget from './InvestmentProfileWidget'
import FundInvestments from './FundInvestmentsWidget'
import axios from '../../../Axios'
import Mixpanel from '../../../Mixpanel'

/**
 * Wrapper component for all LexShares portfolio performance data related to
 * historical performance, investment activity, investment types and profile
 * of the cases.
 */
function LexSharesPortfolio({investmentCount, extendedCaseVisibility}) {
  //eslint-disable-next-line
  let [metrics, setMetrics] = useState(null);

  /**
   * Pulls in the required data for the IRR widget. The reason this
   * data is not pulled by the widgets itself is because the widget
   * is also utilized to render investor specific performance.
   */
  useEffect(() => {
    axios.get(`/widget/lexshares-portfolio.json`).then(res => {
      setMetrics(res.data)
    })
  }, [])

  useEffect(() => {
    Mixpanel.track('Viewed LexShares portfolio tab')
  }, [])

  return (
    <Grid className="grid-margin-x">
      <Cell medium={12} large={8}>
        { extendedCaseVisibility &&
          <FundInvestments />
        }
        <InvestmentHistoryWidget />
        <InvestmentTypeWidget />
        <InvestmentProfileWidget />
      </Cell>
    </Grid>
  )
}

export default LexSharesPortfolio
