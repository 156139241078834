import React, { Fragment } from "react";
import { Cell, Grid } from "react-foundation";
import { Banner, Header, Link } from "./LearnMoreBanner.style";
import BannerLink from "../../BannerLink";

const LearnMoreBanner = ({ fundingCasePath }) => {
  return (
    <Fragment>
      <BannerLink link="/blog">
        <Banner data={fundingCasePath}>
          <Grid className="grid-x grid-margin-x">
            <Cell small={12}>
              <Header>
                Want to learn more about litigation finance?{" "}
                <Link href="/resources">Visit our resource center here</Link>.
              </Header>
            </Cell>
          </Grid>
        </Banner>
      </BannerLink>
    </Fragment>
  );
};

export default LearnMoreBanner;
