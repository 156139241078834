import React, { Fragment } from 'react'
import {
  LsRadio,
  Input, 
  RadioButton,
  TextContainer,
  Text,
  Label
} from './InputRadio.style'

function InputRadio({name, label = "Placeholder Text",
  changeHandler = null, description, value, selected = false}) {
  return (
    <Fragment>
      <LsRadio>
        <Input type="radio" value={value} name={name} onChange={changeHandler} defaultChecked={selected} />
        <RadioButton />
        <TextContainer>
          <Text>
            <Label>{label}</Label>
          </Text>
          <Text>
            {description}
          </Text>
        </TextContainer>
      </LsRadio>
    </Fragment>
  )
}

export default InputRadio
