import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as SB from './../../SimpleBanner'
import * as Globals from '../../Globals'

export const Section = styled.div`
  border-top: 1px solid #ccc;
  display: none;
  &.selected {
    display: block;
  }
`

export const Content = styled(SB.Content)`
  max-width: 1235px;
  margin: 50px auto;
  padding: 0px 7%;
  text-align: left;
  min-height: 65px;
  ${breakpoint('medium')`
    padding: 0px 7%;
    text-align: left;
  `}
  ${breakpoint('large')`
    padding: 0px 2%;
  `}
`

export const ColumnHeading = styled.h4`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 35px;
`

export const BigHeading = styled(Globals.H3)`
  clear: both;
  margin-bottom: 40px;
  ${breakpoint('large')`
    font-size: 32px;
  `}
  &:not(:first-of-type) {
    padding-top: 40px;
  }
`

export const Subcopy = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 25px;
  margin-top: -26px;
`

export const CardsInfo = styled.p`
  margin-bottom: 40px;
`
