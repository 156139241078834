import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import {
  BannerSection,
  ContentSection
} from '../../components/litigation-finance-101'
import CallToActionSection from '../../components/CallToActionSection';
import theme from '../../config/theme';
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from '../../components/Footer'

const Page = styled.div`
  background-color: ${props => props.theme.colors.lightPurple};
`

class LitigationFinance101 extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar theme="transparent" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor} currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <Page>
            <BannerSection />
            <ContentSection csrfToken={this.props.csrfToken}/>
            <CallToActionSection heading="Learn whether funding is right for your case." tagline="LexShares’ team of legal and investment professionals can help you evaluate your litigation to determine if funding should be considered." cta="Explore Funding" link="/funding/case"  />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default LitigationFinance101
