import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Different, Content, Heading, Subheading, Bold, Header, Summary, Icon } from './DifferentSection.style';
import SuitcaseImg from '../../../../assets/images/icons/suitcase-money.png';
import HandMoneyImg from '../../../../assets/images/icons/hand-money.png';

function DifferentSection() {
  return (
    <Different>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                How is law firm financing different than traditional bank loans?
              </Heading>
              <Subheading>
                Law firm financing provides a practical and accessible alternative means of financing to bank loans or lines of credit. Traditional banks not only require mainstream collateral in exchange for credit, but also typically require a personal guarantee from the partners of the law firm. <Bold>Banks will not accept unearned contingency fees from cases as collateral.</Bold>
              </Subheading>
              <Subheading>
                Specifically, law firm funding differs from traditional bank loans and lines of credit in two distinct ways:
              </Subheading>

              <Grid className="grid-margin-x inform">
                <Cell medium={6} small={12}>
                  <Grid className="grid-margin-x">
                    <Cell small={2} className="hide-for-medium">
                      <Icon>
                        <img src={SuitcaseImg}/>
                      </Icon>
                    </Cell>
                    <Cell small={10} medium={12}>
                      <Icon className="show-for-medium">
                        <img src={SuitcaseImg}/>
                      </Icon>
                      <Header className="small-text-left medium-text-center">
                       No Fixed Payments
                      </Header>
                      <Summary className="small-text-left medium-text-center">
                        Law firm funding is specifically designed for attorneys and law firms, and does not require fixed payments.
                      </Summary>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell medium={6} small={12}>
                  <Grid className="grid-margin-x">
                    <Cell small={2} className="hide-for-medium">
                      <Icon>
                        <img src={HandMoneyImg}/>
                      </Icon>
                    </Cell>
                    <Cell small={10} medium={12}>
                      <Icon className="show-for-medium">
                        <img src={HandMoneyImg}/>
                      </Icon>
                      <Header className="small-text-left medium-text-center">
                      Non-Recourse Capital


                      </Header>
                      <Summary className="small-text-left medium-text-center">
                        Law firm financing is not a loan--rather a non-recourse advancement of capital, collateralized by future contingent fees.

                      </Summary>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>

              <Subheading>
                The availability of bank loans and lines of credit to law firms can be an uncertain and lengthy process, leaving attorneys and law firms without the necessary capital to pursue new business, execute marketing campaigns, and grow their practices.
              </Subheading>
            </Cell>
          </Grid>
        </Section>
      </Content>
    </Different>
  )
}

export default DifferentSection;
