import React from "react";
import {
  Container,
  DiamondBackground,
  ImageContainer,
} from "./BannerImage.style";
import ReportCover from '../../../../assets/images/barometer/report-cover.jpg';

const BannerImage = () => {
  return (
    <Container>
      <DiamondBackground />
      <ImageContainer>
        <img src={ReportCover} />
      </ImageContainer>
    </Container>
  );
};

export default BannerImage;
