import styled from 'styled-components'
import * as Globals from '../../../Globals'

export const Heading = styled(Globals.H1)`
  text-align: center;
  margin-top: 60px;
`

export const Subheading = styled.p`
  text-align: center;
  margin-bottom: 35px;
`

export const SelectContainer = styled.div`
  max-width: 800px;
  margin: 35px auto 35px auto;
`

export const AttestContainer = styled.div`
  margin-top: 20px;
`
