import styled from 'styled-components';
import * as Globals from '../../Globals';
import breakpoint from 'styled-components-breakpoint';

export const Section = styled.div`
  background: #f7f7f7;
`;

export const Content = styled.div`
  padding: 80px 30px;

  ${breakpoint('large')`
    padding: 80px 120px;
  `}

  background-color: #fefefe;
  height: 100%;
`

export const ContentHeading = styled(Globals.H2)`
  margin-bottom: 22px;
  -webkit-font-smoothing: auto;
`;

export const ContentBody = styled(Globals.Description)`
  line-height: 1.9;
`;

export const Divider = styled.div`
  border-top: 1px solid #bc74ff;
  padding-top: 50px;
  margin-top: 50px;
`;

export const SummaryHeader = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 15px;
  font-weight: 600;
`

export const SummarySection = styled.div`
  padding: 60px 30px;

  ${breakpoint('large')`
    padding: 60px 120px 80px 80px;
  `}
`

export const Title = styled(Globals.Body)`
  font-weight: bold;
  margin-bottom: 10px;
`

export const Description = styled(Globals.Description)``

export const SummaryDivider = styled.div`
  border-top: 1px solid #d4d4d4;
  padding-top: 30px;
  margin-top: 30px;
}
`
