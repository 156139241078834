import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import Hero from '../../../../assets/images/case_studies/hero.jpg';

export const Banner = styled(SB.Banner)`
  background: url(${Hero}) no-repeat center center/cover;

  ${breakpoint('large')`
    padding: 0;
  `}
`;

export const Content = styled(SB.Content)`
  padding: 13% 3%;

  ${breakpoint('large')`
    padding: 13% 3%;
    max-width: 1025px;
  `}
`;

export const BannerProp = styled(SB.Diamonds)`
`;

export const Heading = styled(SB.Heading)`
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;

  ${breakpoint('medium')`
    text-align: center;
  `}
`;

export const Subheading = styled(SB.Subheading)`
  text-align: center;


  ${breakpoint('medium')`
    text-align: center;
  `}

  margin: 10px auto 30px auto;
  max-width: 710px;
`;
