import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from '../../SimpleBanner';
import * as Globals from '../../Globals';

export const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center !important;
  filter: blur(1px);
  ${breakpoint('medium')`
    filter: blur(2px);
  `}
`

export const Banner = styled(SB.Banner)`
  padding: 0;
  ${breakpoint('large')`
    padding: 0;
  `}
`;

export const Content = styled(SB.Content)`
  padding: 10% 3% 12% 3% !important;
`;


export const Heading = styled(SB.Heading)`
  text-align: center;
  font-size: 32px;
  margin-bottom: 0;
  line-height: 32px !important;
  ${breakpoint('medium')`
    text-align: center;
  `}
`;

export const Label = styled(Globals.Body)`
  margin: 10px auto 30px auto;
  max-width: 700px;
  text-align: center;
  font-weight: bold;
`;

export const Vs = styled(SB.Heading)`
  color: #bc74ff;
  line-height: 0.5;
  text-align: center;
  font-size: 32px;
  margin-bottom: 0;
`
