import React, {Fragment, useState, useEffect} from 'react'
import Bar from '../../../../Charts/Bar'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text
} from './FundInvestmentTypes.style'

/**
 * Displays vertical bar chart showing case type spread for all
 * fund investments.
 */
function FundInvestmentTypes({fundId}) {
  let [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`/widget/fund-investment-types/${fundId}.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Widget className="js-fund-investment-types-widget">
      <Content loading={data ? "false" : "true"}>
        { data &&
          <Fragment>
            <Heading>{data.name} Case Investment Types</Heading>
            <DateRange>{data.date_range}</DateRange>          
            <Text>
              The following chart represents all of your {data.name} investments
              categorized by primary case type (some litigations are comprised of
              multiple claim types).
            </Text>
            <Bar data={data.types} tipLabel="cases" />
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default FundInvestmentTypes
