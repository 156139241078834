import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Banner, BannerContent, BannerProp, Heading, Subheading } from './BannerSection.style';
import BannerImg from '../../../../assets/images/shared/banner-prop.svg'

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <BannerProp>
          <img src={BannerImg} />
        </BannerProp>

        <BannerContent>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Frequently asked questions
              </Heading>
              <Subheading>
                For general inquiries, please contact us at info@lexshares.com
              </Subheading>
            </Cell>
          </Grid>
        </BannerContent>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
