import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const ChartContainer = styled.div`
  height: 200px;
`

export const Legend = styled.div`
  display: block;
  position: relative;
  margin-top: 40px;
  margin-left: 45px;
`

export const Break = styled.div`
  clear: both;
`

export const LegendLabel = styled.div`
  float: left;
  width: 100%;
  font-size: 13px;
  color: white;
  position: relative;
  margin-bottom: 10px;
  margin-right: 40px;
  &::before {
    position: absolute;
    left: -25px;
    top: 2px;
    content: ' ';
    height: 14px;
    width: 14px;
    background-color: ${props => props.color};
    border-radius: 50%;
  }
  ${breakpoint('medium')`
    width: 250px;
    height: 40px;
  `}
  ${breakpoint('large')`
    width: 170px;
  `}
`
