import * as yup from 'yup'
import InputField from '../../Form/InputField'
import InputButton from '../../Form/InputButton'
import InputSelect from '../../Form/InputSelect'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { Grid, Cell } from 'react-foundation';
import { isEmpty } from 'lodash'
import axios from 'axios'
import {
  COUNTRIES, US_STATES
} from '../../../config/constants.js.erb'

const schema = yup.object().shape({
  first_name: yup.string().required().label("First name"),
  last_name: yup.string().required().label("Last name"),
  street: yup.string().required().label("Street"),
  city: yup.string().required().label("City"),
  state: yup.string().when('country', {
    is: "United States",
    then: yup.string().required()
  }).label("State"),
  zip: yup.string().when('country', {
    is: "United States",
    then: yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}),
  }).label("Zip"),
  country: yup.string().required().label("Country"),
  phone: yup.string().when('country', {
    is: "United States",
    then: yup.string().required().matches(/^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be (000) 000-0000"}),
  }).when('country', {
    is: "Canada",
    then: yup.string().required().matches(/^\+1 (\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be +1 (000) 000-0000"}),
  }).label("Phone"),
  phone_extension: yup.string().label("Phone extension"),
  dob: yup.string().required().label("Date of Birth").matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/, {message: "Date of birth format must be mm/dd/yyyy"})
})

function PersonalInfoForm({csrfToken, investor}) {
  const formRef = useRef(null)

  const defaultValues = {
    first_name: investor.user.first_name || '',
    last_name: investor.user.last_name || '',
    street: investor.home_address.street,
    city: investor.home_address.city,
    state: investor.home_address.state,
    zip: investor.home_address.zip,
    country: investor.home_address.country || "United States",
    phone: investor.phone,
    phone_extension: investor.phone_extension,
    dob: investor.dob
  }

  const stateOptions = [{label: "Select a state", value: ''}, ...US_STATES]

  const handleSubmit = ({first_name, last_name, street, city, state, zip, country, phone, phone_extension, dob}) => {

    const payload = {
      user_attributes: { id: investor.user.id, first_name, last_name },
      home_address_attributes: { id: investor.home_address.id, street, city, state, zip, country },
      phone,
      phone_extension,
      dob,
      authenticity_token: csrfToken
    }

    axios.put(`/investors/${investor.id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  const phoneFormat = (country) => {
    if(country == "United States") {
      return "(###) ###-####"
    }

    if(country == "Canada") {
      return "+1 (###) ###-####"
    }
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, setFieldValue, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-personal-info">
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="First name"
                  name="first_name"
                  changeHandler={handleChange}
                  error={errors.first_name}
                  value={values.first_name}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Last name"
                  name="last_name"
                  changeHandler={handleChange}
                  error={errors.last_name}
                  value={values.last_name}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell small={12}>
                <InputField
                  type="text"
                  label="Street"
                  name="street"
                  changeHandler={handleChange}
                  error={errors.street}
                  value={values.street}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="City"
                  name="city"
                  changeHandler={handleChange}
                  error={errors.city}
                  value={values.city}
                />
              </Cell>
              <Cell medium={6} className="js-state-cell">
                {values.country == "United States" && <InputSelect
                  label="State"
                  name="state"
                  options={stateOptions}
                  error={errors.state}
                  value={stateOptions.find(option => option.value === values.state)}
                  changeHandler={(option) => setFieldValue("state", option.value)}
                />}
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Zip/Postal"
                  name="zip"
                  changeHandler={handleChange}
                  error={errors.zip}
                  value={values.zip}
                />
              </Cell>
              <Cell medium={6}>
                <InputSelect
                  label="Country"
                  name="country"
                  options={COUNTRIES}
                  error={errors.country}
                  value={COUNTRIES.find(option => option.value === values.country)}
                  changeHandler={(option) => setFieldValue("country", option.value)}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <Grid className="grid-margin-x">
                  <Cell large={8}>
                    <InputField
                      type="number"
                      label="Phone number"
                      name="phone"
                      format={phoneFormat(values.country)}
                      changeHandler={handleChange}
                      error={errors.phone}
                      value={values.phone}
                      mask="_"
                    />
                  </Cell>
                  <Cell large={4}>
                    <InputField
                      type="text"
                      label="Extension"
                      name="phone_extension"
                      changeHandler={handleChange}
                      error={errors.phone_extension}
                      value={values.phone_extension}
                    />
                  </Cell>
                </Grid>
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="number"
                  label="Date of birth"
                  name="dob"
                  format="##/##/####"
                  changeHandler={handleChange}
                  error={errors.dob}
                  value={values.dob}
                  mask="_"
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-y">
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default PersonalInfoForm
