import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Cell, Grid } from "react-foundation";
import breakpoint from "styled-components-breakpoint";
import theme from "../../config/theme";
import {
  ShowBannerSection,
  MoreCaseStudiesSection,
  StatsSection,
  CaseStudyDetailSection,
} from "../../components/case-studies";
import LearnMoreSection from "../../components/LearnMoreSection";
import FooterBackground from "../../../assets/images/cases/cta.jpg";
import Navbar from "../../components/attorney-resource-center/Navbar/Navbar";
import Footer from "../../components/Footer";
import Sidenav from "../../components/attorney-resource-center/Sidenav/Sidenav";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const SidenavContainer = styled.div`
  display: none;

  ${breakpoint("medium")`
    display: block;
  `}
`;

class CaseStudiesShow extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar
            attorneyResourceCenterPath={this.props.attorneyResourceCenterPath}
            caseStudiesPath={this.props.caseStudiesPath}
            fundingAttorneysPath={this.props.fundingAttorneysPath}
            fundingPlaintiffsPath={this.props.fundingPlaintiffsPath}
            fundingGeneralCounselPath={this.props.fundingGeneralCounselPath}
            fundingCasePath={this.props.fundingCasePath}
            rootPath={this.props.rootPath}
            fundingInsightsPath={this.props.fundingInsightsPath}
            regulationsTrendPath={this.props.regulationsTrendPath}
            slipOpinionPath={this.props.slipOpinionPath}
          />
          <Page>
            <Grid>
              <Cell small={12} medium={4} large={2}>
                <SidenavContainer>
                  <Sidenav
                    caseStudiesPath={this.props.caseStudiesPath}
                    fundingAttorneysPath={this.props.fundingAttorneysPath}
                    fundingPlaintiffsPath={this.props.fundingPlaintiffsPath}
                    fundingGeneralCounselPath={
                      this.props.fundingGeneralCounselPath
                    }
                    fundingCasePath={this.props.fundingCasePath}
                    rootPath={this.props.rootPath}
                    fundingInsightsPath={this.props.fundingInsightsPath}
                    regulationsTrendPath={this.props.regulationsTrendPath}
                    attorneyResourceCenterPath={
                      this.props.attorneyResourceCenterPath
                    }
                    whitepapersPath={this.props.whitepapersPath}
                  />
                </SidenavContainer>
              </Cell>
              <Cell small={12} medium={8} large={10}>
                <ShowBannerSection caseStudy={this.props.caseStudy} />
                <StatsSection caseStudy={this.props.caseStudy} />
                <CaseStudyDetailSection caseStudy={this.props.caseStudy} />
                <MoreCaseStudiesSection caseStudies={this.props.caseStudies} />
                <LearnMoreSection
                  background={FooterBackground}
                  heading="Have a case that may need funding?"
                  subheading="LexShares funds attorneys, law firms and plaintiffs with commercial lawsuits, and is one of the most active litigation finance firms in the world."
                  buttonText="Get Funding"
                  buttonLink="/funding/case"
                />
              </Cell>
            </Grid>
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default CaseStudiesShow;
