import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import InputField from '../../../../components/Form/InputField'
import InputCheck from '../../../../components/Form/InputCheck'
import InputSelect from '../../../../components/Form/InputSelect'

import {
  Heading,
  PaymentMethodContainer,
  OptionButton,
  Label,
  PaymentOption
} from './InvestmentAmount.style'

function InvestmentAmount({
  inputHandler, error, minimum, maximum, newBankAccount, paymentMethod,
  paymentMethodHandler, bankChangeHandler, investmentMethods, accountTypeHandler,
  investmentMethodHandler, currentAch, amount, investmentMethod,
  accountNumber, verifyAccountNumber, routingNumber, accountType
  }) {

  const accountTypeOptions = [
    { value: 'CHECKING', label: 'Checking', name: 'accountType' },
    { value: 'SAVING', label: 'Savings', name: 'accountType' },
  ]

  return (
    <Fragment>
      <Heading>Investment amount</Heading>
      <Grid className="grid-margin-x">
        <Cell small={12} className="js-amount-cell">
          <InputField
            value={amount}
            type="currency"
            label="Input investment amount"
            name="amount"
            range={minimum ? `${minimum},${maximum}` : null}
            changeHandler={inputHandler}
            error={error.amount}
          />
        </Cell>
      </Grid>
      <Grid className="grid-margin-x">
        <Cell small={12} medium={6} className="js-method-cell">
          <InputSelect
            value={investmentMethod}
            label="Select an existing investment method"
            options={investmentMethods}
            changeHandler={investmentMethodHandler}
            error={error.investmentMethod}
            clearable={false}
            name="investment-method"
          /> 
        </Cell>
        <Cell small={12} medium={6}>
          <PaymentMethodContainer>
            <Label>Select payment method</Label>
            { (!investmentMethod || (investmentMethod.value != 'retirement_account')) && 
              <OptionButton
                className={paymentMethod == 'bank' ? 'selected js-bank-link' : 'js-bank-link'}
                onClick={() => paymentMethodHandler('bank')}
              >
                Bank (ACH)
              </OptionButton>
            }
            <OptionButton
              className={paymentMethod == 'wire' ? 'selected js-wire-link' : 'js-wire-link'}
              onClick={() => paymentMethodHandler('wire')}
            >
              Wire
            </OptionButton>
          </PaymentMethodContainer>
        </Cell>
      </Grid>
      <Grid className="grid-margin-x">
        <Cell small={12}>
          { paymentMethod == 'wire' &&
            <PaymentOption>
              <p>You will be provided wire instructions via email shortly.</p>
            </PaymentOption>
          }
          { paymentMethod == 'bank' &&
            <PaymentOption>
              { currentAch &&
                <Fragment>
                  <p>Your account ending in {currentAch} will be debited shortly.</p>
                  <InputCheck
                    name="new-bank"
                    changeHandler={bankChangeHandler}
                    label="Click here if you wish to provide a new account."
                    checked={newBankAccount}
                  />
                </Fragment>
              }
              { (newBankAccount || !currentAch) &&
                <Fragment>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={6}>
                      <InputField
                        type="number"
                        label="Account number"
                        name="account-number"
                        value={accountNumber}
                        changeHandler={inputHandler}
                        error={error.accountNumber}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <InputField
                        type="number"
                        label="Verify account number"
                        name="verify-account-number"
                        value={verifyAccountNumber}
                        changeHandler={inputHandler}
                        error={error.verifyAccountNumber}
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={6}>
                      <InputField
                        type="number"
                        label="Routing number"
                        name="routing-number"
                        value={routingNumber}
                        changeHandler={inputHandler}
                        error={error.routingNumber}
                      />
                    </Cell>
                    <Cell small={12} medium={6} className="js-account-type-cell">
                      <InputSelect
                        label="Account type"
                        options={accountTypeOptions}
                        changeHandler={accountTypeHandler}
                        error={error.accountType}
                        name="account-type"
                        value={accountType}
                      />
                    </Cell>
                  </Grid>
                </Fragment>
              }
            </PaymentOption>
          }
        </Cell>
      </Grid>
    </Fragment>
  )
}

export default InvestmentAmount
