import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint'
import * as Globals from './../../Globals';

export const How = styled.div`
  background-color: #f7f7f7;
`;

export const Content = styled.div`
  padding-bottom: 75px;
  max-width: 1035px;
  margin: 0 auto;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`

export const Heading = styled(Globals.H2)`
  margin-bottom: 40px;
  text-align: center;

  ${breakpoint('large')`
    margin-bottom: 65px;
  `}
`;

export const Icon = styled.div`
  text-align: center;
  margin-bottom: 30px;

  img {
    max-width: 39px;
    max-height: 39px;
    image-rendering: -webkit-optimize-contrast;
  }
`

export const Header = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
}
`

export const Summary = styled(Globals.Description)`
  margin-bottom: 30px;
  -webkit-font-smoothing: auto;

  ${breakpoint('medium')`
    margin-bottom: 60px;
  `}
`

export const LinkContainer = styled.div`
  text-align: center;
  margin: 0;
  &::after {
    content: '\f0da';
    font-family: 'FontAwesome';
    color: #1488B6;
    top: 0px;
    margin-left: 5px;
    font-size: 14px;
    position: relative;
  }
`

export const Link = styled.a`
  text-transform: uppercase;
  font-weight: 600;
  font-size: .9rem;
  margin: 5px 0;
  color: #1488B6;
  outline: none;
`
