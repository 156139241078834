import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Heading, Subheading, Button, InvestmentContainer } from './InvestmentsSection.style';
import { InvestmentCard } from '../../funding';


function InvestmentsSection({investments = null, csrfToken = null}) {

  const renderInvestments = () => {
    return investments.map((investment, i) => {
      return (
        <Cell small={12} large={4} key={i}>
          <InvestmentContainer>
            <InvestmentCard {...investment} background="#FFFFFF" csrfToken={csrfToken} />
          </InvestmentContainer>
        </Cell>
      );
    });
  }

  return (
    <Fragment>
      <Section>
        <Grid className="grid-margin-x">
          <Cell small={12}>
            <Heading>
              Over 100 case investments and counting.
            </Heading>
            <Subheading>
              We evaluate thousands of legal claims each year in an effort to offer the best possible investment opportunities.
            </Subheading>
            <Grid className="align-center">
              { renderInvestments() }
            </Grid>
            <Button href="/cases">
              View All
            </Button>
          </Cell>
        </Grid>
      </Section>
    </Fragment>
  )
}

export default InvestmentsSection;
