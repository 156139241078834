import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import theme from "../../../../config/theme";

export const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
`;

export const InputButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  ${breakpoint("medium")`
    width: 200px;
  `}
`;

export const ForgotPasswordLink = styled.a`
  color: ${theme.colors.teal};
`;

export const SignUpText = styled.p`
  font-family: "proxima-nova" !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
`;

export const SignUpLink = styled.a`
  color: ${theme.colors.teal};
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.errorRed};
`;
