import React from 'react'
import Mixpanel from '../Mixpanel'
import {
  CardContainer,
  DocDate,
  Title,
  Description,
  Button
} from './DownloadCard.style'

function DownloadCard({date, title, description, link, text="Download",
  trackedData, track = false}) {

  const trackClick = (title) => {
    if (track) {
      trackedData['documentTitle'] = title
      Mixpanel.track('User downloaded document', trackedData)
    }
  }

  return (
    <CardContainer
      className="js-download-card"
      hasDescription={description ? true : false}
    >
      { date &&
        <DocDate>{date}</DocDate>
      }
      <Title dangerouslySetInnerHTML={{__html: title}} />
      <Description dangerouslySetInnerHTML={{__html: description}} />
      <Button href={link} target="_blank" onClick={() => trackClick(title, link)}>{text}</Button>
    </CardContainer>
  )
}

export default DownloadCard
