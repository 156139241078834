import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import { CheckmarkContainer, Description, Header, InfoItem, ListContainer, ListItem } from './SlipOpinionInfo.style';
import CheckBlackImg from '../../../../assets/images/icons/checkmark-black.png';

const SlipOpinionInfo = () => {
  const list = ['The most important litigation funding news', 'Top insights from legal practitioners and industry experts', 'Direct access to special resources, guides, and whitepapers', 'The latest regulatory developments and ethical best practices']
  return(
    <Fragment>
      <Grid>
        <Cell small={12} medium={6}>
          <Header>Why subscribe to The Slip Opinion?</Header>
          <Description>
            Of the key trends shaping U.S. litigation, few are more significant than the emergence of litigation funding. Our curated newsletter keeps attorneys briefed on this evolving practice.
          </Description>
        </Cell>
        <Cell small={12} medium={6}>
          <ListContainer>
            {list.map((e) => (
              <ListItem key={e}>
                <CheckmarkContainer>
                  <img src={CheckBlackImg} />
                </CheckmarkContainer>
                <InfoItem>{e}</InfoItem>
              </ListItem>
            ))}
          </ListContainer>
        </Cell>
      </Grid>
    </Fragment>
  )

}

export default SlipOpinionInfo;