import * as yup from 'yup'
import InputField from '../../../Form/InputField'
import InputButton from '../../../Form/InputButton'
import InputSelect from '../../../Form/InputSelect'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { Grid, Cell } from 'react-foundation';
import { get, isEmpty } from 'lodash';
import axios from 'axios'
import {
  US_STATES
} from '../../../../config/constants.js.erb'

const schema = yup.object().shape({
  name: yup.string().required().label("Name"),
  street: yup.string().required().label("Street"),
  city: yup.string().required().label("City"),
  state: yup.string().required().label("State"),
  zip: yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}).label("Zip")
})

function RetirementAccountForm({csrfToken, investor}) {
  const formRef = useRef(null)
  const defaultValues = {
    name: get(investor, 'retirement_account.name') || '',
    street: get(investor, 'retirement_account.address.street') || '',
    city: get(investor, 'retirement_account.address.city') || '',
    state: get(investor, 'retirement_account.address.state') || '',
    zip: get(investor, 'retirement_account.address.zip') || ''
  }

  const handleSubmit = ({name, street, city, state, zip}) => {
    const payload = {
      retirement_account_attributes: {
        id: get(investor, 'retirement_account.id'), name,
        address_attributes: {
          id: get(investor, 'retirement_account.address.id'), street, city, state, zip
        }
      },
      authenticity_token: csrfToken
    }

    axios.put(`/investors/${investor.id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  const stateOptions = [{label: "Select a state", value: ''}, ...US_STATES]

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, setFieldValue, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-retirement">
            <Grid className="grid-margin-x">
              <Cell small={12}>
                <InputField
                  type="text"
                  label="Account title"
                  name="name"
                  changeHandler={handleChange}
                  error={errors.name}
                  value={values.name}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Street"
                  name="street"
                  changeHandler={handleChange}
                  error={errors.street}
                  value={values.street}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="City"
                  name="city"
                  changeHandler={handleChange}
                  error={errors.city}
                  value={values.city}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6} className="js-state-cell">
                <InputSelect
                  label="State"
                  name="state"
                  options={stateOptions}
                  error={errors.state}
                  value={stateOptions.find(option => option.value === values.state)}
                  changeHandler={(option) => setFieldValue("state", option.value)}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Zip/Postal"
                  name="zip"
                  changeHandler={handleChange}
                  error={errors.zip}
                  value={values.zip}
                />
              </Cell>
            </Grid>
            <Grid small={12}>
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default RetirementAccountForm
