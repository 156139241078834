import React from 'react';
import { Cell, Grid } from 'react-foundation';
import ArticleCard from '../ArticleCard';

function ArticleList(props) {
  return (
    <Grid>
      {
        props.articles.map((a) => (
          <Cell small={12} key={a.id}>
            <ArticleCard {...a} />
          </Cell>
        ))
      }
    </Grid>
  )
}

export default ArticleList;
