import styled from 'styled-components'
import { Field } from 'formik'

export const Label = styled.label`
  color: ${props => props.labelColor ? props.labelColor : 'black'};
  font-size: 15px;
  &.tip {
    &:after {
      content: "?";
      font-weight: bold;
      margin-left: 4px;
      margin-top: -6px;
      position: absolute;
      color: ${props => props.theme.colors.purple};
    }
  }
`

export const Textarea = styled(Field)`
  height: 100px;
  line-height: 1.2em;
`

export const Input = styled.input`
`

export const InputContainer = styled.div`
  input {
    height: 45px;
    border: 1px solid ${props => props.theme.colors.mediumGray};
    border-radius: 3px;
    padding: 20px 15px;
    font-size: 17px;
    font-weight: 300;
    box-shadow: unset;
    &:focus {
      border: 1px solid ${props => props.theme.colors.lightPurple};
    }
    &.error {
      border: 1px solid red;
    }
  }
  input::placeholder {
    color:  #333;
    opacity: 0.5;
  }
`

export const Hint = styled.p`
  font-size: 12px;
  color: #979797;
`

export const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: -10px;
`
