import styled from 'styled-components';
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  padding: 7% 7%;
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const InputButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  ${breakpoint('medium')`
    width: 235px;
  `}
`;

export const Error = styled.small`
  background-color: white;
  color: #bd0000;
  padding: 0px;
  font-style: normal;
  font-size: 1rem;
  margin-top: -15px;
  margin-bottom: 5px;
  display: block;
`;

export const Alert = styled.div`
  background-color: ${theme.colors.veryLightPurple};
  border-color: ${theme.colors.veryLightPurple};
  margin-bottom: 30px;
  border-radius: 3px;
  padding: 20px;
  color: white;
`;