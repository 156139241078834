import React, { Fragment, useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, Heading, TabContainer, TabItem,
  ItemHeader, ItemDescription, Headline, HeadlineCard,
  Button, ButtonInverse, Disclaimer
} from './BenefitsSection.style';

function BenefitsSection({
  tabs, heading, primaryButtonText = "Submit a Case", primaryButtonLink = "/funding/case", secondaryButtonText = "Learn More", secondaryButtonLink = "/guide", disclaimer = null
}) {

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const renderTabs = () => {
    return tabs.map((tab, i) => {
      return(
        <TabItem key={i} onClick={() => setSelectedTab(tab)} selected={tab.quote == selectedTab.quote}>
          <ItemHeader className="item-header">
            { tab.title }
          </ItemHeader>
          <ItemDescription>
            { tab.description }
          </ItemDescription>
        </TabItem>
      )
    })
  }

  return (
    <Fragment>
      <Section className="js-benefits-section">
        <Content>
          <Grid>
            <Cell large={6} small={12}>
              <Heading>
                { heading }
              </Heading>
              <TabContainer>
                { renderTabs() }
              </TabContainer>

              <Grid className="grid-margin-x grid-margin-y">
                <Cell medium={5} small={12}>
                  <Button href={ primaryButtonLink }>
                    { primaryButtonText }
                  </Button>
                </Cell>

                <Cell medium={5} small={12}>
                  <ButtonInverse href={ secondaryButtonLink }>
                    { secondaryButtonText }
                  </ButtonInverse>
                </Cell>
              </Grid>
            </Cell>
            <Cell large={6} small={12} className="grid-x align-bottom">
              <HeadlineCard className="grid-x align-center">
                <Headline >
                  { selectedTab.quote }
                </Headline>
              </HeadlineCard>
            </Cell>
          </Grid>
          { disclaimer && <Disclaimer>
            { disclaimer }
          </Disclaimer> }
        </Content>
      </Section>
    </Fragment>
  )
}

export default BenefitsSection;
