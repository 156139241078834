import React from "react";
import { Grid, Cell } from "react-foundation";
import {
  Container,
  ContentContainer,
  DescriptionContainer,
  Heading,
  Description,
  CheckmarkSection,
  CheckmarkHeading,
  SectionHeading,
} from "./DescriptionSection.style";
import CheckmarkWhite from '../../../../assets/images/icons/checkmark-white-in-purple-background.png';

const DescriptionSection = () => {
  return (
    <>
      <Container>
        <Grid>
          <Cell small={12} large={6}>
            <ContentContainer>
              <DescriptionContainer>
                <Heading>
                  Pulling Back the Curtain on Litigation Finance
                </Heading>
                <Description>
                  Litigation funding is a powerful tool for lawyers and
                  claimants with meritorious cases. Despite the industry&apos;s
                  recent growth, however, legal practitioners have had precious
                  few insights into the qualities that make a case attractive to
                  third-party funders — until now.
                  <br />
                  <br />
                  For the first time, LexShares has made public data
                  intelligence produced by its proprietary Diamond Mine software
                  platform, which its team used to analyze the investment
                  potential of more than 30,000 federal and state cases in 2021.
                  The result is a rare, data-driven review of the types of
                  matters that lend themselves to outside investment in the eyes
                  of a tech-enabled litigation funder.
                </Description>
              </DescriptionContainer>
            </ContentContainer>
          </Cell>
        </Grid>
      </Container>
      <CheckmarkSection>
        <Grid className="grid-margin-x grid-margin-y align-center">
          <Cell
            className="text-center"
            small={12}
            style={{ marginBottom: "20px" }}
          >
            <SectionHeading>About This Report</SectionHeading>
          </Cell>
          <Cell className="text-center" small={12} medium={2}>
            <img src={CheckmarkWhite}/>
            <CheckmarkHeading>
              31,265 Federal and State Cases Surveyed
            </CheckmarkHeading>
          </Cell>
          <Cell className="text-center" small={12} medium={2}>
            <img src={CheckmarkWhite}/>
            <CheckmarkHeading>Analysis by Claim Type</CheckmarkHeading>
          </Cell>
          <Cell className="text-center" small={12} medium={2}>
            <img src={CheckmarkWhite}/>
            <CheckmarkHeading>Jurisdictional Breakdowns</CheckmarkHeading>
          </Cell>
          <Cell className="text-center" small={12} medium={2}>
            <img src={CheckmarkWhite}/>
            <CheckmarkHeading>Law Firm Analysis</CheckmarkHeading>
          </Cell>
          <Cell className="text-center" small={12} medium={2}>
            <img src={CheckmarkWhite}/>
            <CheckmarkHeading>
              Insider Commentary from LexShares
            </CheckmarkHeading>
          </Cell>
        </Grid>
      </CheckmarkSection>
    </>
  );
};

export default DescriptionSection;
