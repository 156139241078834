import React, { useEffect } from 'react';
import { Container, ImageTile, Link, MainTitle, SubText, Title, Topic } from "./HoverMenu.style";

const HoverMenu = ({ focusedTopicPath, topic }) => {
  const TOPIC_MAPPING = {
    "funding-insights": {
      header: "Funding Insights",
    },
    "regulations-and-trends": {
      header: "Regulations and Trends",
    },
  }

  useEffect(() => {
    $('.hover-menu__container').addClass('slideout');
  }, [])

  return (
    <Container className="hover-menu__container">
      <SubText>Featured in {TOPIC_MAPPING[topic].header}</SubText>
      <Link href={focusedTopicPath}>View More</Link>
    </Container>
  )
}

export default HoverMenu;