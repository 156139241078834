import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import leftArrowIcon from '../../../../assets/images/icons/left-arrow.png';
import rightArrowIcon from '../../../../assets/images/icons/right-arrow.png';

export const CarouselContainer = styled.div`
  width: 100%;
`

export const LeftArrow = styled.div`
  background: url(${leftArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const RightArrow = styled.div`
  background: url(${rightArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const InvestmentContainer = styled.div`
  min-height: 100%;
  display: flex;
  padding: 5px;
  padding-bottom: 30px;

  ${breakpoint('medium')`
    width: 392px;
    margin: 0 auto;
  `}

  ${breakpoint('large')`
    width: 100%;
  `}
`;
