import React, { Fragment } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../../config/theme'
import Navbar from '../../components/investor-dashboard/Navbar'
import Footer from '../../components/Footer'
import BannerSection from '../../components/cases-show/BannerSection'
import EngagementControls from '../../components/cases-show/EngagementControls'
import BackgroundInformation from '../../components/cases-show/BackgroundInformation'
import FundInformation from '../../components/cases-show/FundInformation'
import CaseDocuments from '../../components/cases-show/CaseDocuments'
import WaitlistPop from '../../components/cases-show/WaitlistPop'
import InvestmentFunnel from '../../components/cases-show/InvestmentFunnel'
import InvestmentCompleted from '../../components/cases-show/InvestmentFunnel/InvestmentCompleted'
import TabControl from '../../components/TabControl'
import Mixpanel from '../../components/Mixpanel'
import PrefundedPop from '../../components/PrefundedPop'

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`

class CasesShow extends React.Component {
  constructor(props) {
    super(props)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.displayWaitlist = this.displayWaitlist.bind(this)
    this.displayInvestFunnel = this.displayInvestFunnel.bind(this)
    this.displayPrefundingPop = this.displayPrefundingPop.bind(this)
    this.hideInvestFunnel = this.hideInvestFunnel.bind(this)
    this.hideWaitlist = this.hideWaitlist.bind(this)
    this.hidePrefundingPop = this.hidePrefundingPop.bind(this)
    this.pushNewState = this.pushNewState.bind(this)
    this.confirmInvestment = this.confirmInvestment.bind(this)
    this.initiateInvestment = this.initiateInvestment.bind(this)
    this.disablePushNotifications = this.disablePushNotifications.bind(this)
    this.updateAlertBar = this.updateAlertBar.bind(this)
    this.trackedData = this.trackedData.bind(this)

    // Override default view if passed in the url as ref
    let views = TabControl.getViews(window)
    let currentTab = views[0] ? views[0].replace(' ', '-') : 'tab-1'

    // Sends user back to default view on refresh and strips the anchor.
    if (TabControl.isRefresh(window)) {
      currentTab = 'tab-1'
    }

    this.state = {
      // high level state information
      progress: this.props.court_case.progress,
      amountRemaining: props.court_case.amount_remaining,
      fundingGoal: props.court_case.funding_goal,
      amountRaised: props.court_case.amount_raised,
      amountFundedText: props.court_case.amount_funded_long_text,
      progressText: props.court_case.progress_text,
      minimumInvestment: props.court_case.minimum_numeric_investment, 
      maximumInvestment: props.court_case.maximum_numeric_investment,
      maximumInvestmentFormatted: props.court_case.maximum_investment,
      investButtonEnabled: props.court_case.invest_button_enabled,
      investButtonText: props.court_case.invest_button_text,
      currentTab: currentTab,
      waitlistPop: false,
      funnelPop: false,
      prefundingPop: false,
      investmentPending: false,
      invested: false,
      pushNotifications: props.court_case.marketplace_fund ? false : true,
      investable: props.court_case.is_currently_investable,
      waitlistable: props.court_case.is_currently_waitlistable
    }
  }

  trackedData() {
    return {
      caseId: this.props.court_case.id,
      caseCaption: this.props.court_case.name,
      reputationScore: this.props.investor.reputation,
      pcarId: this.props.court_case.pcar_id,
      standardCaseType: this.props.court_case.standard_case_type,
      standardDamages: this.props.court_case.standard_damages,
      standardJurisdiction: this.props.court_case.standard_jurisdiction,
      stage: this.props.court_case.stage,
      offeringSize: this.props.court_case.funding_goal,
      minimumInvestment: this.state.minimumInvestment
    }
  }

  handleTabChange(event) {
    const tabId = event.target.getAttribute('data-tab')
    this.setState({currentTab: tabId})
    const tabName = {'tab-1': 'case details', 'tab-2': 'documents'}[tabId]
    Mixpanel.track(`Viewed ${tabName} tab`, this.trackedData())
  }

  displayPrefundingPop() {
    this.setState({prefundingPop: true})
    return false
  }

  displayWaitlist() {
    this.setState({waitlistPop: true})
    Mixpanel.track('Clicked waitlist button', this.trackedData())
  }

  displayInvestFunnel() {
    this.setState({funnelPop: true})
    Mixpanel.track('Clicked invest button', this.trackedData()) 
  }

  hideInvestFunnel() {
    this.setState({funnelPop: false})
  }

  hideWaitlist() {
    this.setState({waitlistPop: false})
  }

  hidePrefundingPop() {
    this.setState({prefundingPop: false})
  }

  initiateInvestment() {
    this.setState({investmentPending: true})
  }

  confirmInvestment() {
    this.setState({
      invested: true,
      waitlistPop: false,
      investmentPending: false,
      pushNotifications: false
    })
  }

  disablePushNotifications() {
    this.setState({pushNotifications: false})
  }

  updateAlertBar() {
    const sharedUi = new LexShares.SharedUI()
    const alertType = this.state.progress >= 90 ? 'warning' : 'info'
    const alertText = 
      this.props.court_case.prefunded
        ? `${this.state.amountRemaining} capacity remaining.`
        : `This case is ${this.state.progress}% funded. Remaining capacity for investors is ${this.state.amountRemaining}.`
    sharedUi.showAlert(alertType, alertText)
  }

  pushNewState(data) {
    const previousMinimum = parseInt(this.state.minimumInvestment)
    const newGlobalMinimum = data.minimum_amount.replace(/,|\$/g, '')
    const newInvestorMinimium = newGlobalMinimum < previousMinimum
      ? newGlobalMinimum : previousMinimum
    let pushedState = {
      progress: data.numeric_progress,
      amountRemaining: data.total_remaining,
      amountRaised: data.total_raised_formatted,
      amountFundedText: data.amount_funded_long_text,
      progressText: data.progress_text,
      investable: data.can_be_invested_in,
      waitlistable: data.can_be_waitlisted_in,
      minimumInvestment: newInvestorMinimium,
      maximumInvestment: data.maximum_amount.replace(/,|\$/g, ''), //data.total_remaining.replace(/,|\$/g, ''),
      maximumInvestmentFormatted: data.maximum_amount
    }
    if (!data.can_be_invested_in && data.can_be_waitlisted_in) {
      pushedState['investButtonText'] = 'Join Waitlist'
      pushedState['waitlistPop'] = !this.state.invesmentPending ? true : false
    }
    this.setState(pushedState)
    this.updateAlertBar()
  }

  componentDidMount() {
    // initiate Ably connection for real-time notifications
    if (this.state.pushNotifications) {
      try {
        const apiKey = this.props.ably_key
        const caseId = this.props.court_case.id
        const client = new Ably.Realtime({key: apiKey})
        const channelName = 'cases-' + caseId
        const self = this
        // Watches for remaining capacity
        const channel = client.channels.get(channelName)
        channel.subscribe('progress', function(message) {
          const data = JSON.parse(message.data)
          if (self.state.pushNotifications) {
            self.pushNewState(data)
          }
        })
        // Watches for case launch
        if (!this.state.investable) {
          const launchChannel = client.channels.get('launch-watch-' + caseId)
          launchChannel.subscribe('status', function() {
            location.reload()
          })
        }
      } catch(e) {
        console.log('Could not connect to Ably');
      }
    }
    if (this.state.investable && !this.props.court_case.marketplace_fund) {
      this.updateAlertBar()
    }

    // Initiate Mixpanel tracking
    Mixpanel.identify(this.props.investor.user_id)
    Mixpanel.track(`Viewed case info page`, this.trackedData())
  }

  render () {
    const court_case = this.props.court_case
    const investor = this.props.investor
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
            clickHandler={this.handleViewChange}
            hasInvestments={this.props.investor.has_investments}
            hasTaxDocs={this.props.investor.has_tax_docs}
          />
          <Page>
            { this.state.invested &&
              <InvestmentCompleted caseName={court_case.name} />
            }
            { this.state.investable && this.state.funnelPop ?
              <InvestmentFunnel
                amountRemaining={this.state.amountRemaining}
                minimumInvestment={this.state.minimumInvestment}
                maximumInvestment={this.state.maximumInvestment}
                maximumInvestmentFormatted={this.state.maximumInvestmentFormatted}
                investmentMethods={investor.investment_methods}
                suitabilityObjectives={investor.suitability_objectives}
                suitabilityExperience={investor.suitability_experience}
                suitabilityRisk={investor.suitability_risk}
                suitabilityAssets={investor.suitability_assets}
                suitabilitySecurities={investor.suitability_securities}
                suitabilityTax={investor.suitability_tax}
                caseName={court_case.name}
                caseId={court_case.id}
                investorId={investor.id}
                closeHandler={this.hideInvestFunnel}
                disableNotificationsHandler={this.disablePushNotifications}
                csrfToken={this.props.csrfToken}
                confirmationHandler={this.confirmInvestment}
                pendingHandler={this.initiateInvestment}
                trackedData={this.trackedData()}
              />
              :
              <Fragment>
                <BannerSection
                  type={court_case.case_type}
                  title={court_case.name}
                  description={court_case.full_case_description}
                  stage={court_case.stage}
                  damages={court_case.damages}
                  progress={this.state.progress}
                  fundingGoal={this.state.fundingGoal}
                  dealSize={court_case.deal_size}
                  prefunded={court_case.prefunded}
                  amountRaised={this.state.amountRaised}
                  offeringSize={court_case.offering_size}
                  prefundingAmount={court_case.prefunding_amount}
                  prefundingDescription={court_case.prefunding_description}
                  marketplaceFund={this.props.court_case.marketplace_fund}
                  commitment={this.props.court_case.amount_invested_in_case}
                  fundInvestments={this.props.court_case.fund_investments}
                  amountFundedText={this.state.amountFundedText}
                  progressText={this.state.progressText}
                  state={court_case.state}
                  displayPrefundingPop={this.displayPrefundingPop}
                />
                <EngagementControls
                  investButtonEnabled={this.state.investButtonEnabled}
                  investButtonText={this.state.investButtonText}
                  investable={this.state.investable}
                  investButtonHandler={
                    this.state.investable ? this.displayInvestFunnel : 
                    this.state.waitlistable ? this.displayWaitlist : null
                  }
                  minimumInvestment={court_case.original_minimum_numeric_investment}
                  maximumInvestment={court_case.original_maximum_numeric_investment}
                  tabLinkHandler={this.handleTabChange}
                  currentTab={this.state.currentTab}
                  documentCount={court_case.document_count}
                  fundedOrResolved={court_case.funded_or_resolved}
                  marketplaceFund={this.props.court_case.marketplace_fund}
                />
                { this.props.court_case.marketplace_fund
                  ?
                    <FundInformation
                      visible={this.state.currentTab == "tab-1"}
                      investments={this.props.court_case.fund_investments}
                    />
                  :
                    <BackgroundInformation
                      visible={this.state.currentTab == "tab-1"}
                      amountRemaining={this.state.amountRemaining}
                      caseId={this.props.court_case.id}
                      court_case={this.props.court_case}
                      investmentAnalysisUrl={court_case.investment_analysis_graphic_url}
                      investmentAnalysisDisclosure={court_case.investment_analysis_disclosure}
                      hasUnderwritingAnalysis={court_case.underwriting_analysis}
                      whyWeLikeIt={court_case.why_we_like_it}
                      whatToConsider={court_case.what_to_consider}
                      background={court_case.background}
                      plaintiffName={court_case.plaintiff_name}
                      plaintiffDescription={court_case.plaintiff_description}
                      defendantName={court_case.defendant_name}
                      defendantDescription={court_case.defendant_description}
                      longOfferingSize={court_case.long_offering_size}
                      prefunded={court_case.prefunded}
                      prefundingAmount={court_case.prefunding_amount}
                      prefundingDescription={court_case.prefunding_description}
                      judgeName={court_case.judge_name}
                      jurisdiction={court_case.jurisdiction}
                      lawFirmName={court_case.law_firm_name}
                      lawFirmDescription={court_case.law_firm_description}
                      lawFirmFounded={court_case.law_firm_founded}
                      lawFirmLead={court_case.law_firm_lead}
                      lawFirmFeeStructure={court_case.law_firm_fee_structure}
                      lawFirmSize={court_case.law_firm_size}
                      lawFirmLawyers={court_case.law_firm_lawyers}
                      lawFirmWebSite={court_case.law_firm_website}
                      lawFirmLinkedIn={court_case.law_firm_linkedin}
                      funded={court_case.funded}
                      resolved={court_case.resolved}
                      courtCaseName={court_case.name}
                      investable={this.state.investable}
                      trackedData={this.trackedData()}
                    />
                }
                <CaseDocuments
                  visible={this.state.currentTab == "tab-2"}
                  packet={court_case.investor_packet}
                  psa={court_case.psa}
                  documents={court_case.case_documents}
                  marketplaceFund={this.props.court_case.marketplace_fund}
                  trackedData={this.trackedData()}
                />
                {(this.state.waitlistable && !this.state.investmentPending) &&
                  <WaitlistPop
                    visible={this.state.waitlistPop} 
                    hideHandler={this.hideWaitlist}
                    csrfToken={this.props.csrfToken}
                    courtCaseId={court_case.id}
                  />
                }
              </Fragment>
            }
          { court_case.prefunded &&
            <PrefundedPop popOnly={true} showPop={this.state.prefundingPop} externalCloseHandler={this.hidePrefundingPop}/>
          }
          <Footer />
          </Page>
        </ThemeProvider>
      </Fragment>
    )
  }
}

export default CasesShow
