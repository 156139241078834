import React, { useEffect, useState } from "react";
import { Cell, Grid, Menu } from "react-foundation";
import {
  TopBarLogo,
  MenuContainer,
  MenuLink,
  MenuItem,
  MainMenu,
  SubMenuLink,
  SubMenuItem,
  SubMenu,
  TopBar,
  LogoLink,
  MobileTopBar,
  TopBarRight,
  MenuItemRight,
  TopBarLogoRight,
  MenuButton,
  TopBarLeft,
  PrimaryButton,
  SecondaryButton,
  MenuRight,
  ContactUsButton,
  VerticalDivider,
  ButtonInverse,
  MenuHeading,
  IconContainer,
  SubMenuLinkHeading,
  SubMenuLinkDescription,
  DownloadGuide,
  PurpleBorder,
} from "./NewNavbar.style";
import SlideDown from "../SlideDown";
import SignUpModal from "./components/SignUpModal";
import LogInModal from "./components/LogInModal";
import { useMediaQuery } from "../../hooks/use-media-query";
import { disableScrolling, enableScrolling } from "../../config/utils";
import PersonInCourthouse from '../../../assets/images/icons/person-in-courthouse.png';
import BarChartTrendUp from '../../../assets/images/icons/bar-chart-trend-up.png';
import MenuImg from '../../../assets/images/icons/menu.png';
import DocFolded from '../../../assets/images/icons/document-folded.png';
import WhiteClose from '../../../assets/images/icons/white-close.png';
import BarometerThumbnail from '../../../assets/images/barometer/thumbnail.jpg';
import ArrowPurple from '../../../assets/images/icons/arrow-purple.png';
import SkinnyArrow from '../../../assets/images/icons/skinny-arrow.png';
import Buildings from '../../../assets/images/icons/buildings.png';
import Gavel from '../../../assets/images/icons/gavel.png';
import DollarSign from '../../../assets/images/icons/dollar-sign.png';
import DocumentSearch from '../../../assets/images/icons/dollar-sign.png';
import TwoPeople from '../../../assets/images/icons/two-people.png';
import Award from '../../../assets/images/icons/two-people.png';
import FaqImg from '../../../assets/images/icons/faq.png';
import Career from '../../../assets/images/icons/career.png';
import UsaCalifornia from '../../../assets/images/icons/usa-and-california-flag.png';
import PlaintiffGuide from '../../../assets/images/attorney_resource_center/plaintiff-guide-book-center.png';

function NewNavbar({
  userSignedIn,
  currentRole,
  isInvestor,
  roleDashboardPath,
  roleProfilePath,
  name,
  csrfToken,
  theme,
  hasTaxDocs,
}) {
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [trueWhite, setTrueWhite] = useState(false);
  const [alertPresent, setAlertPresent] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [logInVisible, setLogInVisible] = useState(false);
  const [menuItemHover, setMenuItemHover] = useState({
    forAttorneys: false,
    forBusinesses: false,
    aboutUs: false,
    loop: true,
  });
  const [ran, setRan] = useState(true);
  const isDesktop = useMediaQuery("md") || ran;

  const getType = () => {
    if (scrolled) {
      return "scrolled";
    }
    if (trueWhite) {
      return "trueWhite";
    }
    return theme;
  };

  const openLogInModal = (e) => {
    e.preventDefault();
    setLogInVisible(true);
  };

  const handleScroll = () => {
    let scrollTop = window.scrollY;
    if (scrollTop > 80 && !scrolled) {
      setScrolled(true);
    }
    if (scrollTop == 0 && scrolled) {
      setScrolled(false);
    }
  };

  const handleChange = () => {
    if (document.querySelectorAll('.full-screen-white-modal').length > 0) {
      setTrueWhite(true);
    } else {
      setTrueWhite(false);
    }
  };

  const signOut = () => {
    fetch("/users/sign_out", {
      method: "DELETE",
      redirect: "follow",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }).then((res) => {
      window.location = res.url;
    });
  };

  const toggleAlertPadding = () => {
    if (document.querySelectorAll('.alert').length > 0) {
      setAlertPresent(true);
    } else {
      setAlertPresent(false);
    }
  };

  useEffect(() => {
    $(".top-bar").foundation();
    setInterval(() => {
      toggleAlertPadding();
    }, 100);
    setRan(false);
  }, []);

  const toggleMenu = (val) => {
    setActive(val);
    if (val) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  };

  const onMouseLeave = () => {
    setTimeout(function () {
      handleScroll();
      setMenuItemHover({
        forAttorneys: false,
        forBusinesses: false,
        aboutUs: false,
      });
    }, 400);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [scrolled]);

  useEffect(() => {
    let MutationObserver =
      window.MutationObserver ||
      window.WebKitMutationObserver ||
      window.MozMutationObserver;
    let observer = new MutationObserver(handleChange);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }, [trueWhite]);

  const topMargin = isDesktop ? (alertPresent ? "122px" : "80px") : "0px";

  return (
    <>
      {signUpVisible && (
        <SignUpModal hideHandler={() => setSignUpVisible(false)} />
      )}
      {logInVisible && (
        <LogInModal
          csrfToken={csrfToken}
          hideHandler={() => setLogInVisible(false)}
        />
      )}
      <MenuContainer onMouseLeave={onMouseLeave}>
        <MobileTopBar shown={active.toString()}>
          <TopBarLogo>
            <Menu>
              <LogoLink type={getType()} href="/" />
            </Menu>
          </TopBarLogo>
          <TopBarLogoRight>
            {active || (
              <MenuButton onClick={() => toggleMenu(true)}>
                <img src={MenuImg} alt="Menu" />
              </MenuButton>
            )}
            {active && (
              <MenuButton onClick={() => toggleMenu(false)}>
                <img src={WhiteClose} alt="White close" />
              </MenuButton>
            )}
          </TopBarLogoRight>
        </MobileTopBar>

        <SlideDown show={active}>
          <TopBar type={getType()} margin={alertPresent.toString()}>
            <TopBarLogo>
              <Menu>
                <LogoLink type={getType()} href="/" />
              </Menu>
            </TopBarLogo>

            <TopBarLeft full={userSignedIn.toString()}>
              <MainMenu
                isDropdown={true}
                isVertical={true}
                data-multi-open="false"
                data-responsive-menu="accordion large-dropdown"
                className="large-horizontal"
              >
                <MenuItem onMouseEnter={() => setScrolled(true)}>
                  <MenuLink
                    type={getType()}
                    onMouseLeave={() => setMenuItemHover({ ...menuItemHover })}
                    onMouseEnter={() =>
                      setMenuItemHover({
                        forAttorneys: true,
                        forBusinesses: false,
                        aboutUs: false,
                        loop: false,
                      })
                    }
                  >
                    For Attorneys
                    <img src={SkinnyArrow} alt="Skinny arrow" />
                    {menuItemHover.forAttorneys && <PurpleBorder width="100" />}
                  </MenuLink>
                  <SubMenu
                    style={{
                      marginTop: topMargin,
                    }}
                    isVertical={true}
                  >
                    <Grid>
                      <Cell large={4}>
                        <MenuHeading>Litigation Financing</MenuHeading>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={PersonInCourthouse} alt="Person in Courthouse" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/funding/attorneys">
                                <SubMenuLinkHeading>
                                  How We Fund
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Get an in-depth overview of our process, and
                                  the types of investments we make.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={BarChartTrendUp} alt="Bar chart trend up" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/cases">
                                <SubMenuLinkHeading>
                                  Case Portfolio
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Review legal claims LexShares has funded, with
                                  more than $100 million invested to date.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell large={4}>
                        <MenuHeading>Learn More</MenuHeading>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={DocFolded} alt="Document" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/whitepapers">
                                <SubMenuLinkHeading>
                                  Whitepapers
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Access whitepapers, and other
                                  insights provided by our in-house team of
                                  former litigators.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={PersonInCourthouse} alt="Person in Courthouse" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/case-studies">
                                <SubMenuLinkHeading>
                                  Case Studies
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Read about real LexShares investments and how
                                  they helped our clients.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell large={4}>
                        <MenuHeading>Get Started</MenuHeading>
                        <Grid>
                          <Cell small={3}>
                            <img src={BarometerThumbnail} alt="Barometer" />
                          </Cell>
                          <Cell small={9}>
                            <SubMenuItem>
                              <SubMenuLink href="/barometer">
                                <SubMenuLinkHeading>
                                  The Litigation Funding Barometer
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  A special report on which cases appeal to
                                  litigation finance firms and why, using data
                                  from more than 30,000 federal and state
                                  filings.
                                </SubMenuLinkDescription>
                                <DownloadGuide>
                                  Download Now
                                  <img src={ArrowPurple} alt="arrow purple" />
                                </DownloadGuide>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                    </Grid>
                  </SubMenu>
                </MenuItem>
                <MenuItem onMouseEnter={() => setScrolled(true)}>
                  <MenuLink
                    type={getType()}
                    onMouseLeave={() => setMenuItemHover({ ...menuItemHover })}
                    onMouseEnter={() =>
                      setMenuItemHover({
                        forAttorneys: false,
                        forBusinesses: true,
                        aboutUs: false,
                        loop: false,
                      })
                    }
                  >
                    For Businesses
                    <img src={SkinnyArrow} alt="Person in Courthouse" />
                    {menuItemHover.forBusinesses && (
                      <PurpleBorder width="110" />
                    )}
                  </MenuLink>
                  <SubMenu
                    style={{
                      marginTop: topMargin,
                    }}
                    isVertical={true}
                    id="navbar__for-businesses-submenu"
                  >
                    <Grid>
                      <Cell large={4}>
                        <MenuHeading>Litigation Financing</MenuHeading>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={Buildings} alt="Buildings" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/funding/plaintiffs">
                                <SubMenuLinkHeading>
                                  How We Fund
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Learn about the LexShares investment process
                                  and the types of investments we typically
                                  make.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={DocumentSearch} alt="Document search" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/cases">
                                <SubMenuLinkHeading>
                                  Case Portfolio
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Review data on the many case types LexShares
                                  has funded since 2014.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell large={4}>
                        <MenuHeading>Learn More</MenuHeading>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={Gavel} alt="Gavel" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/litigation-finance-101">
                                <SubMenuLinkHeading>
                                  Litigation Finance 101
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Get up to speed on how litigation finance
                                  investments work and who they can help.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={DollarSign} alt="Dollar sign" />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/case-studies">
                                <SubMenuLinkHeading>
                                  Case Studies
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Learn how LexShares funding has supported real
                                  businesses involved in litigation.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell large={4}>
                        <MenuHeading>Get Started</MenuHeading>
                        <Grid>
                          <Cell small={3}>
                            <img src={PlaintiffGuide} alt="Plaintiff Guide" />
                          </Cell>
                          <Cell small={9}>
                            <SubMenuItem>
                              <SubMenuLink href="/whitepapers/business-guide">
                                <SubMenuLinkHeading>
                                  A Business Leader’s Introduction to Litigation
                                  Finance
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Learn how companies can turn pending lawsuits
                                  into liquid capital with this helpful primer
                                  for CEOs, founders, owners, and operators.
                                </SubMenuLinkDescription>
                                <DownloadGuide>
                                  Download Guide&nbsp;
                                  <img src={ArrowPurple} />
                                </DownloadGuide>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                    </Grid>
                  </SubMenu>
                </MenuItem>
                <MenuItem onMouseEnter={() => setScrolled(true)}>
                  <MenuLink
                    type={getType()}
                    onMouseLeave={() => setMenuItemHover({ ...menuItemHover })}
                    onMouseEnter={() =>
                      setMenuItemHover({
                        forAttorneys: false,
                        forBusinesses: false,
                        aboutUs: true,
                        loop: false,
                      })
                    }
                  >
                    About Us
                    <img src={SkinnyArrow} />
                    {menuItemHover.aboutUs && <PurpleBorder width="73" />}
                  </MenuLink>
                  <SubMenu
                    style={{
                      marginTop: topMargin,
                    }}
                    isVertical={true}
                    id="navbar__about-us-submenu"
                  >
                    <Grid>
                      <Cell large={4}>
                        <MenuHeading>About LexShares</MenuHeading>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={Award} />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/press">
                                <SubMenuLinkHeading>Press</SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Browse media coverage of LexShares’ recent
                                  growth and business milestones.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell large={4}>
                        <MenuHeading>&nbsp;</MenuHeading>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={FaqImg} />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="/faq">
                                <SubMenuLinkHeading>FAQ</SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Find answers to common questions about
                                  litigation finance and our investor
                                  marketplace.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                        <Grid>
                          <Cell small={1}>
                            <IconContainer>
                              <img src={Career} />
                            </IconContainer>
                          </Cell>
                          <Cell small={11}>
                            <SubMenuItem>
                              <SubMenuLink href="https://www.linkedin.com/company/lexshares-inc-/jobs">
                                <SubMenuLinkHeading>Careers</SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Join one of the most active litigation finance
                                  firms in the world, based in Boston and New
                                  York.
                                </SubMenuLinkDescription>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell large={4}>
                        <MenuHeading>News</MenuHeading>
                        <Grid>
                          <Cell small={3}>
                            <img src={UsaCalifornia} />
                          </Cell>
                          <Cell small={9}>
                            <SubMenuItem>
                              <SubMenuLink href="/blog">
                                <SubMenuLinkHeading>
                                  Firm & Market Updates
                                </SubMenuLinkHeading>
                                <SubMenuLinkDescription>
                                  Visit our corporate blog for company news,
                                  market commentaries, and investor insights.
                                </SubMenuLinkDescription>
                                <DownloadGuide>
                                  Read More&nbsp;
                                  <img src={ArrowPurple} />
                                </DownloadGuide>
                              </SubMenuLink>
                            </SubMenuItem>
                          </Cell>
                        </Grid>
                      </Cell>
                    </Grid>
                  </SubMenu>
                </MenuItem>
                {userSignedIn && (
                  <MenuItem className="hide-for-large">
                    <MenuLink
                      type={getType()}
                      onMouseLeave={() =>
                        setMenuItemHover({ ...menuItemHover, loop: true })
                      }
                    >
                      {name}
                      <img src={SkinnyArrow} />
                    </MenuLink>
                    <SubMenu
                      style={{
                        marginTop: isDesktop
                          ? alertPresent
                            ? "122px"
                            : "80px"
                          : "0px",
                      }}
                      isVertical={true}
                    >
                      {isInvestor && currentRole && (
                        <SubMenuItem>
                          <SubMenuLink
                            href={roleProfilePath}
                            style={{ marginTop: "16px" }}
                          >
                            My Profile
                          </SubMenuLink>
                        </SubMenuItem>
                      )}
                      {isInvestor && currentRole && hasTaxDocs && (
                        <SubMenuItem>
                          <SubMenuLink href="/tax-center">
                            Tax Center
                          </SubMenuLink>
                        </SubMenuItem>
                      )}
                      {currentRole && (
                        <SubMenuItem>
                          <SubMenuLink href={roleDashboardPath}>
                            Dashboard
                          </SubMenuLink>
                        </SubMenuItem>
                      )}
                      <SubMenuItem>
                        <SubMenuLink onClick={() => signOut()}>
                          Log Out
                        </SubMenuLink>
                      </SubMenuItem>
                    </SubMenu>
                  </MenuItem>
                )}
              </MainMenu>
            </TopBarLeft>
            {userSignedIn && (
              <TopBarRight className="show-for-large">
                <Menu
                  isDropdown={true}
                  isVertical={true}
                  data-responsive-menu="accordion large-dropdown"
                  className="large-horizontal"
                >
                  <MenuItem onMouseEnter={() => setScrolled(true)}>
                    <MenuLink
                      type={getType()}
                      onMouseLeave={() =>
                        setMenuItemHover({ ...menuItemHover, loop: true })
                      }
                    >
                      {name}
                    </MenuLink>
                    <SubMenu
                      style={{
                        marginTop: isDesktop
                          ? alertPresent
                            ? "122px"
                            : "80px"
                          : "0px",
                      }}
                      isVertical={true}
                    >
                      {isInvestor && currentRole && (
                        <SubMenuItem>
                          <SubMenuLink href={roleProfilePath}>
                            My Profile
                          </SubMenuLink>
                        </SubMenuItem>
                      )}
                      {isInvestor && currentRole && hasTaxDocs && (
                        <SubMenuItem>
                          <SubMenuLink href="/tax-center">
                            Tax Center
                          </SubMenuLink>
                        </SubMenuItem>
                      )}
                      {currentRole && (
                        <SubMenuItem>
                          <SubMenuLink href={roleDashboardPath}>
                            Dashboard
                          </SubMenuLink>
                        </SubMenuItem>
                      )}
                      <SubMenuItem>
                        <SubMenuLink onClick={() => signOut()}>
                          Log Out
                        </SubMenuLink>
                      </SubMenuItem>
                    </SubMenu>
                  </MenuItem>
                </Menu>
              </TopBarRight>
            )}
            <TopBarRight>
              <MenuRight>
                <MenuItemRight className="show-for-large">
                  <ContactUsButton type={getType()} href="/funding/case">
                    Contact Us
                  </ContactUsButton>
                </MenuItemRight>

                <MenuItemRight className="show-for-large">
                  <VerticalDivider type={getType()} />
                </MenuItemRight>

                <MenuItemRight className="show-for-large">
                  <MenuLink
                    type={getType()}
                    onMouseLeave={() =>
                      setMenuItemHover({ ...menuItemHover, loop: true })
                    }
                    href="/invest"
                  >
                    Investors
                  </MenuLink>
                </MenuItemRight>

                {userSignedIn || (
                  <MenuItemRight className="show-for-large">
                    <ButtonInverse
                      type={getType()}
                      onClick={(e) => openLogInModal(e)}
                    >
                      Login
                    </ButtonInverse>
                  </MenuItemRight>
                )}

                {userSignedIn || (
                  <MenuItemRight className="hide-for-large">
                    <PrimaryButton href="/funding/case">
                      Contact Us
                    </PrimaryButton>
                  </MenuItemRight>
                )}

                {userSignedIn || (
                  <MenuItemRight className="hide-for-large">
                    <SecondaryButton onClick={(e) => openLogInModal(e)}>
                      Log in
                    </SecondaryButton>
                  </MenuItemRight>
                )}

                {userSignedIn && (
                  <MenuItemRight className="show-for-large">
                    <ButtonInverse type={getType()} onClick={() => signOut()}>
                      Log out
                    </ButtonInverse>
                  </MenuItemRight>
                )}
              </MenuRight>
            </TopBarRight>
          </TopBar>
        </SlideDown>
      </MenuContainer>
    </>
  );
}

export default NewNavbar;
