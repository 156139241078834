import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../../Globals'

export const Heading = styled(Globals.H1)`
  text-align: center;
  margin-top: 60px;
`

export const DocuSign = styled.div`
  margin: 30px auto 60px auto;  
`

export const TimeNotice = styled.div`
  text-align: center;
  font-weight: bold;
  p {
    font-weight: bold;
  }
  img {
    height: 100px;
  }
`

export const Frame = styled.iframe`
  min-height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  border: 0px;
  ${breakpoint('medium')`
    min-height: 1200px;
  `}
`
