import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import SafeCaseImg from '../../../../assets/images/icons/safe-case.png';
import GovernmentImg from '../../../../assets/images/icons/government.png';
import BusinessImg from '../../../../assets/images/icons/business-area.png';

import { Section, Content, Heading, Subheading } from './PersonasSection.style';
import SimpleCard from '../SimpleCard';

function PersonasSection() {
  const personas = [
    {
      src: SafeCaseImg,
      heading: "Corporate Plaintiffs",
      description: "Create flexibility by covering working capital, legal fees, and expenses.",
      href: '/funding/plaintiffs'
    },
    {
      src: GovernmentImg,
      heading: "Attorneys & Law Firms",
      description: "Manage your firm’s risk and enable client friendly engagement structures.",
      href: '/funding/attorneys'
    },
    {
      src: BusinessImg,
      heading: "In-House Legal Departments",
      description: "Discover how to transform your cost center into a profit center.",
      href: '/funding/general-counsel'
    }
  ]


  const renderPersonaCards = () => {
    return personas.map((persona, i) => {
      return (
        <Cell large={4} medium={6} small={12} key={i}>
          <SimpleCard {...persona} />
        </Cell>
      )
    });
  }

  return (
    <Fragment>
      <Section className="js-personas">
        <Content>
          <Grid className="grid-margin-x align-center">
            <Cell large={12}>
              <Heading>
                Supporting law firms, businesses, and legal departments.
              </Heading>
              <Subheading>
                We offer attorneys, law firms, plaintiffs and in-house legal departments flexible, non-recourse capital through our online marketplace and dedicated litigation finance fund.
              </Subheading>

              <Grid className="grid-margin-x grid-margin-y align-center">
                { renderPersonaCards() }
              </Grid>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default PersonasSection;
