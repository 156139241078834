import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Content = styled.div`
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`
