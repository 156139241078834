import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';
import DiamondBackground from '../../../../assets/images/backgrounds/diamond-array-gray';

export const Section = styled.div`
  background: #9450d4;
  background: linear-gradient(90deg, #9450d4 0%, #591db4 100%);
  position: relative;
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 8% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  margin-bottom: 30px;
  color: white !important;
`;

export const Subheading = styled(Globals.P)`
  margin-bottom: 30px;
  color: white;
`;

export const Ebook = styled.div`
  position: absolute;
  left: 250px;
  top: 250px;
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`

export const Guide = styled.div`
  position: absolute;
  top: 60px;
  left: 80px;
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`

export const List = styled.div`
  margin-bottom: 30px;
`

export const Item = styled(Globals.Description)`
  position: relative;
  padding-left: 30px;
  color: white;
  font-weight: bold;
  ine-height: 15px;
  margin-bottom: 8px;
`

export const Checkmark = styled.div`
  position: absolute;
  left: 0;
  width: 20px;
  img {
    width: 100%;
  }
`

export const Download = styled.div``

export const DownloadInput = styled.input`
  border-radius: 3px !important;
  padding: 20px 15px;
  font-size: 17px;
  font-weight: 300;
  box-shadow: unset;
  margin: 8px 0;
  height: 51px !important;
  width: 100%;
  border: none;
  ${breakpoint('medium')`
    margin: 0;
    border-radius: 3px 0 0 3px !important;
  `}
`

export const DownloadButton = styled.button`
  background: #914cde;
  font-size: 13px;
  letter-spacing: 1px;
  color: #fefefe;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  background-color: #1488B6;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  margin: 8px 0;
  &:hover {
    background: #007095;
    color: #fefefe;
  }

  ${breakpoint('medium')`
    width: 200px;
    margin: 0;
    border-radius: 0 3px 3px 0px;
  `}
`

export const DiamondProp = styled.div`
  background: url(${DiamondBackground}) no-repeat center center/cover;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 300px;
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`;

export const MobileImage = styled.div`
  display: block;

  img {
    margin: 0 auto;
    display: block;
  }

  ${breakpoint('large')`
    display: none;
  `}
`
