import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from './../../Globals';
import theme from '../../../config/theme';

export const Container = styled.div`
  background: ${theme.colors.lightPurple};
  padding: 25px;

  ${breakpoint('medium')`
    padding: 75px 100px;
  `};
`;

export const Heading = styled(Globals.H2)`
  text-align: center;
  margin-bottom: 0px;

  ${breakpoint('medium')`
    margin-bottom: 84px;
  `};
`;

export const ProductHeading = styled(Globals.H6)`
  font-size: 22px !important;
  line-height: 26px !important;
  text-align: center;
  color: ${theme.colors.darkGrey};
  margin: 20px 0px 14px 0px;

  ${breakpoint('medium')`
    margin: 40px 0px 14px 0px;
  `};
`;

export const ImageContainer = styled.div`
  padding-top: 20px;

  ${breakpoint('medium')`
    padding-top: 0px;
  `};

  img {
    margin: auto;
    width: 22px;

    ${breakpoint('medium')`
      width: 44px;
    `};
  }
`;

export const ImageBackground = styled.div`
  margin: auto;
  height: 50px;
  width: 50px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  background-color: white;
  color: white;
  text-align: center;
  font-size: 2em;

  ${breakpoint('medium')`
    height: 100px;
    width: 100px;
    line-height: 100px;
  `};
`;

export const ProductDescription = styled(Globals.P)`
  font-size: 16px !important;
  line-height: 25px !important;
  text-align: center;
  color: ${theme.colors.darkGrey};
  margin: 0px;

  ${breakpoint('medium')`
    margin: 0px 50px;
  `};
`;

export const SubHeading = styled(Globals.H2)`
  text-align: center;
  margin-top: 50px;
  color: ${theme.colors.darkGrey};

  ${breakpoint('medium')`
    margin-top: 121px;
  `};
`;

export const Description = styled(Globals.P)`
  margin: auto;
  font-size: 16px !important;
  line-height: 25px !important;
  text-align: center;
  color: ${theme.colors.darkGrey};
  margin-top: 25px;
  max-width: 650px;
`;

export const ViewCaseButton = styled(Globals.ButtonLink)`
  width: 100%;
  background: ${theme.colors.mediumPurple};
  margin-top: 30px;

  ${breakpoint('medium')`
    min-width: 185px;
    width: unset;
    margin-top: 0px;
  `};
  ${breakpoint('large')`
    min-width: 232px;
    margin-top: 35px;
  `};
`

export const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 55px;
`;