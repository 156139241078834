import React from "react";
import {
  Container,
  Content,
  Paragraph,
  Button,
  ImageContainer,
  InfoContainer,
  Name,
  Title,
  EmailLink,
} from "./ContentSection.style";
import MattRImg from '../../../../assets/images/about/matt-reason.jpg';

function ContentSection({ caseSubmissionName }) {
  return (
    <Container>
      <Content>
        <Paragraph>Hi {caseSubmissionName},</Paragraph>
        <Paragraph>
          Thank you for providing us with some additional color regarding your
          litigation funding request.
        </Paragraph>
        <Paragraph>
          Your inquiry is now being reviewed by our Investments team. If your
          case fits our investment criteria, you can expect to hear back from us
          within the next 3 days.
        </Paragraph>
        <Paragraph>
          For any additional questions, you can email us directly at{" "}
          <EmailLink href="mailto:cases@lexshares.com">
            cases@lexshares.com
          </EmailLink>
          .
        </Paragraph>
        <Paragraph>
          <Button href="mailto:cases@lexshares.com">Contact Us</Button>
        </Paragraph>
        <Paragraph>
          <ImageContainer>
            <img src={MattRImg} />
          </ImageContainer>
          <InfoContainer>
            <Name>Matt Reason</Name>
            <Title>Chief Revenue Officer</Title>
          </InfoContainer>
        </Paragraph>
      </Content>
    </Container>
  );
}

export default ContentSection;
