import styled from "styled-components";
import * as Globals from "./../../Globals";
import breakpoint from "styled-components-breakpoint";
import theme from "../../../config/theme";
import Arrow from "../../../../assets/images/icons/arrow-down-black.png";
export const Section = styled.div`
  background: ${theme.colors.lightPurple};
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint("medium")`
    padding: 7% 7%;
  `}

  ${breakpoint("large")`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  text-align: center;
  color: white;
`;

export const FilterSection = styled.div`
  padding: 15px;
  text-align: left;

  ${breakpoint("medium")`
    text-align: center;
  `}
`;

export const Label = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  margin: 10px 15px;

  ${breakpoint("medium")`
    margin: 0px 15px 0px 0px;
  `}
`;

export const Dropdown = styled.select`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 30px;
  margin: 10px 0px 10px 0px;
  vertical-align: middle;
  background-position: right -4px center;
  background-image: url(${Arrow});
  width: ${(props) => props.width}px;

  ${breakpoint("medium")`
    display: inline-block;
    margin: 0px 30px 0px 0px;
  `}
`;

export const DropdownSection = styled.div`
  display: block;

  ${breakpoint("medium")`
    display: inline-block;
  `}
`;

export const LoadMoreLink = styled.a`
  text-align: center;
  display: block;
  text-transform: uppercase;
  color: #914cde;
  font-weight: bold;
  margin-top: 10px;
  &:after {
    content: "\f078";
    font-family: "FontAwesome";
    color: #7d7d7d;
    font-weight: normal;
    margin: 0 0 0 15px;
  }
`;
