import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import * as Globals from '../../Globals';

export const Section = styled(SB.Banner)``;

export const Content = styled(SB.Content)`
  max-width: 1230px;
  text-align: left;
  ${breakpoint('large')`
    padding: 5% 20px;
  `}
`;

export const BannerProp = styled(SB.Diamonds)``;

export const Label = styled.div`
  font-size: 13px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #8247BA;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
`

export const Heading = styled(SB.Heading)`
  margin-bottom: 20px;
`;

export const Subheading = styled(Globals.Body)`
  margin-bottom: 40px;
`;
