import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';

import { Section, Content, Heading, Subheading, BannerProp } from './PersonasSection.style';
import SimpleCard from '../SimpleCard';
import SafeCaseImg from '../../../../assets/images/icons/safe-case.png';
import GovernmentImg from '../../../../assets/images/icons/government.png';
import BusinessImg from '../../../../assets/images/icons/business-area.png';
import DiamonArrayImg from '../../../../assets/images/backgrounds/diamond-array-purple.png';

function PersonasSection() {
  const personas = [
    {
      src: SafeCaseImg,
      heading: "Corporate Plaintiffs",
      description: "Create flexibility by covering working capital, legal fees, and expenses.",
      href: '/funding/plaintiffs'
    },
    {
      src: GovernmentImg,
      heading: "Attorneys & Law Firms",
      description: "Manage your firm’s risk and enable client friendly engagement structures.",
      href: '/funding/attorneys'
    },
    {
      src: BusinessImg,
      heading: "In-House Legal Departments",
      description: "Discover how to transform your cost center into a profit center.",
      href: '/funding/general-counsel'
    }
  ]


  const renderPersonaCards = () => {
    return personas.map((persona, i) => {
      return (
        <Cell large={4} medium={6} small={12} key={i}>
          <SimpleCard {...persona} />
        </Cell>
      )
    });
  }

  return (
    <Fragment>
      <Section>
        <BannerProp>
          <img src={DiamonArrayImg} />
        </BannerProp>
        <Content>
          <Grid className="grid-margin-x align-center">
            <Cell large={12}>
              <Heading>
                Lift the financial burden of litigation.
              </Heading>
              <Subheading>
                LexShares capital can help enable attorneys, law firms, plaintiffs, and in-house legal departments to secure capital, reduce risk and improve litigation outcomes.
              </Subheading>

              <Grid className="grid-margin-x grid-margin-y align-center">
                { renderPersonaCards() }
              </Grid>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default PersonasSection;
