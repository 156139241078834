import React, { useState } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { Formik } from 'formik';
import { Grid, Cell } from 'react-foundation';
import * as yup from 'yup';
import { COUNTRIES, US_STATES } from '../../../config/constants.js.erb';
import InputButton from '../../Form/InputButton'
import InputSelect from '../../Form/InputSelect';
import InputField from '../../Form/InputField';
import ProgressBar from '../../ProgressBar';
import { ButtonContainer, Container, FinishProfileLaterContainer, FinishProfileLaterLink, Heading, NextStepButton } from './OnboardingStepOne.style';
import FocusError from '../../FocusError/FocusError';

const stateOptions = [{label: "Select a state", value: ''}, ...US_STATES];

const OnboardingStepOne = ({investor, csrfToken, setOnboardingStep, setCurrentInvestor}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    first_name: yup.string().required().label("First name"),
    last_name: yup.string().required().label("Last name"),
    street: yup.string().required().label("Street"),
    city: yup.string().required().label("City"),
    state: yup.string().when('country', {
      is: "United States",
      then: yup.string().required()
    }).label("State"),
    zip: yup.string().required().when('country', {
      is: "United States",
      then: yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}),
    }).label("Zip"),
    country: yup.string().required().label("Country"),
    dob: yup.string().required().label("Date of birth").matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/, {message: "Date of birth format must be mm/dd/yyyy"}),
    phone: yup.string().required().when('country', {
      is: "United States",
      then: yup.string().required().matches(/^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be (000) 000-0000"}),
    }).when('country', {
      is: "Canada",
      then: yup.string().required().matches(/^\+1 (\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be +1 (000) 000-0000"}),
    }).label("Phone"),
    extension: yup.string().label("Phone extension"),
    linkedin_url: yup.string().matches(/^https?:\/\/www.linkedin.com/, {message: "The format must be http://www.linkedin.com/profile"}).label("Linkedin Url"),
  })

  const defaultValues = {
    first_name: get(investor, 'user.first_name') || '',
    last_name: get(investor, 'user.last_name') || '',
    street: get(investor, 'home_address.street') || '',
    city: get(investor, 'home_address.city') || '',
    state: get(investor, 'home_address.state') || '',
    zip: get(investor, 'home_address.zip') || '',
    country: get(investor, 'home_address.country') || 'United States',
    dob: get(investor, 'dob') || '',
    phone: get(investor, 'phone') || '',
    phone_extension: get(investor, 'phone_extension') || '',
    employer: get(investor, 'employer') || '',
    position: get(investor, 'position') || '',
    linkedin_url: get(investor, 'linkedin_url') || '',
  }

  const phoneFormat = (country) => {
    if(country == "United States") {
      return "(###) ###-####"
    }

    if(country == "Canada") {
      return "+1 (###) ###-####"
    }
  }

  const handleSubmit = ({first_name, last_name, street, state, city, zip, dob, country, phone, phone_extension, employer, position, linkedin_url}) => {
    setIsSubmitting(true);
    const payload = {
      investor: {
        user_attributes: {
          first_name: first_name,
          last_name: last_name,
          id: investor.id,
        },
        home_address_attributes: {
          street: street,
          city: city,
          state: state,
          zip: zip,
          country: country,
          id: get(investor, 'home_address.id') || '',
        },
        dob: dob,
        phone: phone,
        phone_extension: phone_extension,
        investor_summary_attributes: {
          employer: employer,
          position: position,
          linkedin_url: linkedin_url,
          id: investor.investor_summary_id,
        },
        has_completed_profile: 0,
      },
      authenticity_token: csrfToken
    }

    axios.patch(`/investors/${investor.id}.json`, payload).then(response => {
      setOnboardingStep(2);
      setCurrentInvestor(response.data.investor);
    }).catch((e) => {
      setIsSubmitting(false);
      const shared_ui = new LexShares.SharedUI();
      shared_ui.showAlert('error', e.response.data.error, false);
    })
  }

  return(
    <Container>
      <ProgressBar step={1} steps={5} percent={20} />
      <Heading>Basic Information</Heading>
      <Formik
        initialValues={defaultValues}
        onSubmit={(e) => handleSubmit(e) }
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {
          ({ errors, handleSubmit, handleChange, values, setFieldValue, isValid  }) =>
            <form onSubmit={ handleSubmit }>
              <FocusError />
              <Grid className="grid-margin-x">
                <Cell small={12} medium={6}>
                  <InputField
                    type="text"
                    label="First name"
                    name="first_name"
                    changeHandler={handleChange}
                    error={errors.first_name}
                    value={values.first_name}
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <InputField
                    type="text"
                    label="Last name"
                    name="last_name"
                    changeHandler={handleChange}
                    error={errors.last_name}
                    value={values.last_name}
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-x">
                <Cell small={12}>
                  <InputField
                    type="text"
                    label="Street"
                    name="street"
                    changeHandler={handleChange}
                    error={errors.street}
                    value={values.street}
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-x">
                <Cell small={12} medium={6}>
                  <InputField
                    type="text"
                    label="City"
                    name="city"
                    changeHandler={handleChange}
                    error={errors.city}
                    value={values.city}
                  />
                </Cell>
                <Cell small={12} medium={6} className="js-state-cell">
                  {values.country == "United States" &&
                    <InputSelect
                      label="State"
                      name="state"
                      options={stateOptions}
                      error={errors.state}
                      value={stateOptions.find(option => option.value === values.state)}
                      changeHandler={(option) => setFieldValue("state", option.value)}
                    />
                  } 
                </Cell>
              </Grid>
              <Grid className="grid-margin-x">
                <Cell small={12} medium={6}>
                  <InputField
                    type="text"
                    label="Zip/Postal"
                    name="zip"
                    changeHandler={handleChange}
                    error={errors.zip}
                    value={values.zip}
                    placeholder="02139"
                  />
                </Cell>
                <Cell small={12} medium={6} className="js-state-cell__country">
                  <InputSelect
                    label="Country"
                    name="country"
                    options={COUNTRIES}
                    error={errors.country}
                    value={COUNTRIES.find(option => option.value === values.country)}
                    changeHandler={(option) => setFieldValue("country", option.value)}
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-x">
                <Cell small={12} medium={6}>
                  <InputField
                    type="number"
                    label="Date of birth"
                    name="dob"
                    format="##/##/####"
                    changeHandler={handleChange}
                    error={errors.dob}
                    value={values.dob}
                    mask="_"
                  />
                </Cell>
                <Cell small={12} medium={4}>
                  <InputField
                    type="number"
                    label="Phone"
                    name="phone"
                    format={phoneFormat(values.country)}
                    changeHandler={handleChange}
                    error={errors.phone}
                    value={values.phone}
                    mask="_"
                  />
                </Cell>
                <Cell small={12} medium={2}>
                  <InputField
                    type="text"
                    label="Extension"
                    name="phone_extension"
                    changeHandler={handleChange}
                    error={errors.phone_extension}
                    value={values.phone_extension}
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-x">
                <Cell small={12} medium={6}>
                  <InputField
                    type="text"
                    label="Employer"
                    name="employer"
                    changeHandler={handleChange}
                    error={errors.employer}
                    value={values.employer}
                    placeholder="Company name"
                  />
                </Cell>
                <Cell small={12} medium={6}>
                  <InputField
                    type="text"
                    label="Position"
                    name="position"
                    changeHandler={handleChange}
                    error={errors.position}
                    value={values.position}
                    placeholder="CEO"
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-x">
                <Cell small={12}>
                  <InputField
                    type="text"
                    label="LinkedIn Profile URL"
                    name="linkedin_url"
                    changeHandler={handleChange}
                    error={errors.linkedin_url}
                    value={values.linkedin_url}
                    placeholder="https://www.linkedin.com/user"
                  />
                </Cell>
              </Grid>

              <ButtonContainer>
                <NextStepButton>
                  <InputButton
                    type="submit"
                    color="#1488B6"
                    value={isSubmitting ? 'Submitting..' : 'Continue'}
                    name="submit-button"
                    disabled={isSubmitting || !isValid}
                  />
                </NextStepButton>
              </ButtonContainer>
              <FinishProfileLaterContainer>
                <FinishProfileLaterLink href="/dashboard">Finish Profile Later</FinishProfileLaterLink>
              </FinishProfileLaterContainer>
            </form>
        }
      </Formik>
    </Container>
  )
}

export default OnboardingStepOne;