import React, { useState, Fragment } from 'react'
import { PopupHeading, Description, DropArea, DocumentList, Document, DropzoneContainer, LoaderContainer } from './AccreditationEntity.style'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import ScaleLoader from "react-spinners/ScaleLoader";
import ModalPop from "../../../ModalPop"
import moment from "moment"

function AccreditationEntity({csrfToken, investor, type}) {

  const getDocuments = () => {
    switch (type) {
      case "individual":
        return investor.accreditation_documents
      case "entity":
        return investor.entity.accreditation_documents
      case "trust":
        return investor.trust.accreditation_documents
    }
  }

  const [documents, setDocuments] = useState(getDocuments())
  const [loading, setLoading] = useState(false)
  const [popupShown, setPopupShown] = useState(false)

  const handleDrop = files => {
    const uploaders = files.map(file => {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", file);
      formData.append("authenticity_token", csrfToken)
      formData.append("entity", type)
      return axios.post(`/investors/${investor.id}/accreditation_documents`, formData, {"X-CSRF-Token": csrfToken}).then(response => {
        const data = response.data;
        setDocuments([...documents, {file_url: data.file.url, created_at: moment().format('YYYY-MM-DD')}])
        setLoading(false)
      })
    });

    axios.all(uploaders).then(() => { });
  }

  const getDescription = () => {
    switch (type) {
      case "individual":
        return investor.investor_status_description
      case "entity":
        return investor.entity_status_description
      case "trust":
        return investor.trust_status_description
    }
  }

  const getForm = () => {
    switch (type) {
      case "individual":
        return <a href="/accreditation_forms/individual"> Individual form </a>
      case "entity":
        return <a href="/accreditation_forms/entity"> Entity form </a>
      case "trust":
        return <a href="/accreditation_forms/trust"> Trust form </a>
    }
  }

  let investorContent =
    <Fragment>
      <PopupHeading>
        Accredited investors
      </PopupHeading>
      <p>
        Accredited investors meet standards defined by the US Securities and Exchange Commission which allow them to invest in certain private securities offerings. The SEC web site contains the <a target="_blank" href="http://www.sec.gov/answers/accred.htm" rel="noreferrer">full definition</a>. In general, any of the following would meet the standard:
      </p>
      <ul>
        <li>
          Individuals with annual income over $200K (individually) or $300K (with spouse) over the last 2 years and an expectation of the same this year
        </li>
        <li>
          Individuals with net assets over $1 million, excluding their primary residence
        </li>
        <li>
          An institution with over $5 million in assets, such as a venture fund or a trust
        </li>
        <li>
          An entity made up entirely of accredited investors
        </li>
      </ul>
    </Fragment>

  return (
    <>
      <Description>
        { getDescription() }
      </Description>

      <Description>
        In order to invest through LexShares, per SEC rules, you must provide documentation that you meet the definition of an <a href="#" onClick={() => setPopupShown(true)}>accredited investor</a>.
      </Description>

      <ModalPop
        popVisible={popupShown}
        content={investorContent}
        hideHandler={() => setPopupShown(false)}
      />

      <Description>
        Please upload one or more of the following:
      </Description>

      <Description>
        <ul>
          <li>
            Letter signed by a third party (CPA, attorney, investment advisor, broker/dealer) of your choosing.
          </li>
          <ul>
            <li>
              { getForm() }
            </li>
          </ul>
          <li> Income proof: Tax returns or W2s for the past two years. </li>
          <li> Asset proof: Copy of recent account or brokerage statements showing the value of your account(s). </li>
        </ul>
      </Description>

      <DropzoneContainer>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DropArea>
                Add file or drop file here
              </DropArea>
              { loading && <LoaderContainer>
                  <ScaleLoader
                    size={150}
                    color={"#123abc"}
                    loading={loading}
                  />
                </LoaderContainer>
              }
            </div>
          )}
        </Dropzone>
      </DropzoneContainer>

      <DocumentList className="js-documents-list">
        { documents.map((document, i) =>
          <Document key={i}>
            <a href={document.file_url} target="_blank" rel="noreferrer"> Accreditation Document - {document.created_at} </a>
          </Document>
        )}
      </DocumentList>
    </>
  )
}

export default AccreditationEntity
