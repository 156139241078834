import React, { Fragment } from 'react'
import { MediaContent, Medium } from './WhitePressLogos.style'

import WsjImg from '../../../assets/images/logos/wsj-logo-white.png';
import MsnbcImg from '../../../assets/images/logos/msnbc-logo-white.png';
import BloombergImg from '../../../assets/images/logos/bloomberg-logo-white.png';
import ForbesImg from '../../../assets/images/logos/forbes-logo-white.png';

function WhitePressLogos() {
  return (
    <Fragment>
      <MediaContent>
        <Medium>
          <img src={WsjImg} />
        </Medium>
        <Medium>
          <img src={MsnbcImg} />
        </Medium>
        <Medium>
          <img src={BloombergImg} />
        </Medium>
        <Medium>
          <img src={ForbesImg} />
        </Medium>
      </MediaContent>
    </Fragment>
  )
}

export default WhitePressLogos
