import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Hero } from "../../components/funding";
import { LitigationInvestingBenefits } from "../../components/invest";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import LearnMoreSection from "../../components/LearnMoreSection";
import theme from "../../config/theme";
import HeroBackground from "../../../assets/images/invest/hero.jpg";
import PurpleBackground from "../../../assets/images/home/purple-background";
import PressBanner from "../../components/PressBanner";
import InvestorsBlackImg from "../../../assets/images/logos/press-logo-investors-black.svg"

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.nightPurple};
`;

class Invest extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <div className="hero">
              <Hero
                heading="A new way to diversify your portfolio."
                subheading="LexShares makes it possible for individuals to invest in commercial litigation, a largely uncorrelated asset class."
                buttonText="Get Started"
                background={HeroBackground}
                buttonLink="/invest/signup"
              />
            </div>
            <PressBanner src={InvestorsBlackImg} />
            <LitigationInvestingBenefits />
            <LearnMoreSection
              background={PurpleBackground}
              heading="Start investing in litigation finance."
              subheading="With the LexShares platform, you can invest in commercial legal claims and follow along with important case developments."
              buttonText="Get Started"
              buttonLink="/invest/signup"
            />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Invest;
