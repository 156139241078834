import styled from 'styled-components';
import * as Globals from './../../Globals';

export const DealSize = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  color: ${props => props.inverted ? props.theme.colors.white : props.theme.colors.black};
  margin-top: 12px;
  margin-bottom: 12px;
`

export const DealInfo = styled.div`
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: ${props => props.inverted ? props.theme.colors.white : props.theme.colors.black};
  opacity: 0.75;
`

export const DealType = styled.div`
  ${Globals.tiempoFontStack};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${props => props.inverted ? props.theme.colors.white : props.theme.colors.black};
  margin-bottom: 25px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`

export const Resolved = styled.span`
  color: #914cde;
`

export const Header = styled.div`
  padding: 20px;
  margin: -15px 40px 0px;
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  font-size: .9rem;
  font-weight: 600;
  background: #9450d4;
  background: linear-gradient(90deg, #9450d4 0%, #591db4 100%);
}
`

export const ProgressContainer = styled.div`
  width: 200px;
  height: 200px;
  margin: 25px auto;
  div {
    bottom: 0;
  }
`

export const DealStatus = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #8247BA;
`

export const DealParties = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #8247BA;
`

export const Body = styled.div`
  color: ${props => props.theme.colors.black};
`

export const Description = styled(Globals.Description)`
  margin-bottom: 20px;
  color: ${props => props.theme.colors.black};
`

export const Type = styled.div``

export const Details = styled.div`
  color: ${props => props.theme.colors.mediumPurple};
  margin-bottom: 30px;
`

export const Divider = styled.div`
  border: 3px solid ${props => props.theme.colors.black};
  border-radius: 10px;
  width: 15px;
  margin: 20px auto;
`

export const Link = styled(Globals.ButtonLink)`
  margin-top: 35px;
  border-radius: 0;
  background: ${props => props.disabled ? "#34303c" : "#8247BA" };
  cursor: ${props => props.disabled ? "default" : "pointer"};

  &:hover {
    background: ${props => props.disabled ? "#34303c" : "#554267" };
  }
`

export const ReverseLink = styled(Globals.ButtonLink)`
  margin-top: 35px;
  border-radius: 0;
  color: #8247BA;
  border: 1px solid #8247BA;
  background: white;

  &:hover {
    background: #8247BA;
  }
`

export const DealDivider = styled.div`
  margin: 25px 0;
  img {
    height: 23px;
  }
`

export const CaseDescription = styled.div`
  text-align: left;
  font-size: 16px;
  text-align: center;
  color: ${props => props.inverted ? props.theme.colors.white : props.theme.colors.black};
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`
export const Status = styled.div`
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
}
`

export const CardBanner = styled.div`
  color: white;
  background: #8247b9;
  text-align: center;
  font-weight: 600;
  font-size: 0.84em;
  padding: 6px 0 3px;
  margin-bottom: 0;
  text-transform: uppercase;
`

export const Card = styled.div`
  position: relative;
  padding: 35px 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  overflow: hidden;
  background: ${props => props.background ? props.background : 'transparent'};
  border: ${props => props.inverted ? '1px solid #8247ba' : ''}
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 100%;

  display: flex;
  color: unset;

  &:hover {
    color: unset;
  }

  &:focus, &:visited {
    color: unset;
  }
`

export const OfferingSummary = styled.div`

`

export const RaisedTotal = styled.div`

`

export const Progress = styled.div`
  height: 7px;
  margin-bottom: 8px;
  margin-top: 8px;
  background-color: #48454e;
  border-radius: 3px;
  color: white;
`

export const ProgressMeter = styled.div`
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba;
  padding: 3px;
  border-radius: 3px 0 0 3px;
`

export const OfferingSize = styled.div`

`
