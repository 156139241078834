import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { H1, H6 } from '../../Globals'
import theme from '../../../config/theme';

export const Container = styled.div`
  margin: 0px 20px;

  ${breakpoint('large')`
    margin-right: 130px !important;
  `}

  ${breakpoint('medium')`
    margin: 0px 40px;
  `}
`;

export const Header = styled(H1)`
  color: ${theme.colors.backdropPurple};
  font-size: 28px;
  line-height: 130%;
  font-weight: normal;
  top: 74px;
  margin-top: 24px;
  text-align: left;

  ${breakpoint('medium')`
    font-size: 56px;
    line-height: 64px;
    top: 117px;
    margin-top: 38px;
  `}
`;

export const Subheading = styled(H6)`
  color: ${theme.colors.backdropPurple};
  font-family: 'proxima-nova'
  font-size: 18px;
  line-height: 130%;
  top: 120px;
  font-weight: normal;

  ${breakpoint('medium')`
    font-size: 24px;
    line-height: 36px;
    top: 190px;
  `}
`;