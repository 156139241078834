import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  Hero,
  BenefitsSection,
  ProcessSection,
  CallToActionSection,
  CaseStudiesSection,
} from "../../components/funding";
import Footer from "../../components/Footer";
import LearnMoreSection from "../../components/LearnMoreSection";
import theme from "../../config/theme";
import HeroBackground from "../../../assets/images/in_house/hero.jpg";
import FooterBackground from "../../../assets/images/in_house/footer.jpg";
import NewNavbar from "../../components/Navbar/NewNavbar";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;
const tabs = [
  {
    title: "Control Costs",
    description:
      "Pursue claims without negatively impacting your P&L and taking balance sheet risk, by shifting legal expenses to an outside funder.",
    quote:
      "When the economic risk of litigation shifts to a litigation finance partner, the increased potential to convert a company’s legal department from a cost center to a profit generator makes obtaining c-suite approval for proactive litigation easier.",
  },
  {
    title: "Optimize Outcomes",
    description:
      "Funding mitigates the financial burden of litigation, so you do not have to compromise on quality of counsel.",
    quote: `A legal department buttressed by litigation finance can focus on the skills and effectiveness of its team without worrying as much about cost or negotiating the lowest possible fees, avoiding the common pitfall of "getting what you pay for."`,
  },
  {
    title: "Offset Risk",
    description:
      "Limit the likelihood you run out of capital resources for a particular matter, forcing premature, inadequate settlements.",
    quote:
      "With non-recourse funding, which allows a company to shift costs to a third-party and only pay an agreed-upon portion of proceeds at the successful conclusion of litigation, any potential losses are ultimately borne by the financing provider.",
  },
];

class FundingInHouse extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Hero
              heading="Turn your cost center into a profit center."
              subheading="Learn how third-party funding can help corporations offload litigation expenses and monetize prospective legal claims."
              buttonText="Read the Guide"
              background={HeroBackground}
              buttonLink="/guide"
              pressLogo="logos/press-logo-plaintiffs.png"
              secondaryButtonText="Discuss financing"
              secondaryButtonLink="/funding/case"
            />
            <BenefitsSection
              heading="How do in-house legal departments benefit from litigation finance?"
              tabs={tabs}
            />
            <CallToActionSection csrfToken={this.props.csrfToken} />
            <CaseStudiesSection caseStudies={this.props.caseStudies} />
            <ProcessSection />
            <LearnMoreSection
              background={FooterBackground}
              heading="Discuss funding with LexShares."
              subheading="Explore how litigation finance now benefits in-house legal departments by connecting with LexShares' experienced Investments team."
              buttonText="Connect With Lexshares"
              buttonLink="/funding/case"
            />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default FundingInHouse;
