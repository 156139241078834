import React from 'react'
import {
  CardContainer,
  Title,
  Spv,
  Button,
} from './TaxDownloadCard.style'

const TaxDownloadCard = ({title, spvName, link, text="Download"}) => {
  return (
    <CardContainer className="js-download-card">
      <Title dangerouslySetInnerHTML={{__html: title}} />
      <Spv>SPV: {spvName}</Spv>
      <Button href={link} target="_blank" disabled={!link}>{text}</Button>
    </CardContainer>
  )
}

export default TaxDownloadCard;
