import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  BannerSection,
  PersonasSection,
  BenefitsSection,
  ProcessSection,
  CallToActionSection,
  CaseStudiesSection,
  HowItWorksSection,
} from "../../components/funding";
import Footer from "../../components/Footer";
import LearnMoreSection from "../../components/LearnMoreSection";
import theme from "../../config/theme";
import FooterBackground from "../../../assets/images/plaintiffs/footer.jpg";
import NewNavbar from "../../components/Navbar/NewNavbar";

const FundingPage = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;

const tabs = [
  {
    title: "Improve Outcomes",
    description:
      "Achieve recoveries that are more fairly aligned with the case merits.",
    quote:
      "Litigation finance helps equalize access to justice. LexShares’ capital can help improve litigation outcomes for clients by leveling the playing field against economically advantaged defendants.",
  },
  {
    title: "Unlock Liquidity",
    description:
      "Monetize your claim to finance legal fees, working capital for your business, or even a cushion for personal expenses.",
    quote:
      "It can be challenging to effectively operate a business during protracted litigation without the support of additional capital. LexShares enables corporate plaintiffs to monetize otherwise illiquid assets and generate working capital.",
  },
  {
    title: "Reduce Risk",
    description:
      "Mitigate out of pocket hourly fees and litigation expenses, shifting the risk of an adverse outcome.",
    quote:
      "LexShares capital offers a more efficient and equitable means of redistributing risk and equalizing the bargaining power of litigants by providing capital for multiple uses through the use of novel financial products.",
  },
  {
    title: "Access Top Resources",
    description:
      "Leverage greater financial flexibility to engage with the best legal resources available, including attorneys, experts and litigation support services.",
    quote:
      "Litigation finance is intended to improve litigation outcomes, one critical way this is possible is by leveraging an improved financial position to engage with the best, most competitive legal talent and partners.",
  },
];

class Funding extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="white"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <FundingPage>
            <BannerSection />
            <PersonasSection />
            <BenefitsSection
              heading="How do attorneys and plaintiffs benefit from litigation funding?"
              tabs={tabs}
            />
            <ProcessSection />
            <CallToActionSection csrfToken={this.props.csrfToken} />
            <CaseStudiesSection caseStudies={this.props.caseStudies} />
            <HowItWorksSection />
            <LearnMoreSection
              background={FooterBackground}
              heading="Learn about litigation finance."
              subheading="LexShares’ comprehensive guide reviews the ins and outs of litigation finance, covering what attorneys and plaintiffs need to know."
              buttonText="Request free guide"
              buttonLink="/guide"
            />
          </FundingPage>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Funding;
