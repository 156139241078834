import React, {Fragment, useState} from 'react'
import {Cell, Grid} from 'react-foundation'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import ReactTooltip from 'react-tooltip'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from 'axios'
import {
  Widget,
  Heading,
  BasicInfo,
  CaseType,
  SmallHeading,
  SummaryContainer,
  Summary,
  Expand,
  TimelineContainer,
  Clear,
  Description,
  DateTime,
  Link
} from './ActivityWidget.style'

/**
 * Shows all activity for a case associated with an individual investment.
 */
function ActivityWidget({id, caseId, investorId, heading, caseType, amount, investmentDate,
  caseStage, timeline, hasUnreadNotifications, docketUrl, csrfToken,
  updateUnseenNotifications, totalNotifications}) {

  // Expand activity if we have one or no activity updates
  let [expanded, setExpanded] = useState(timeline.length > 1 ? false : true)
  let [hasNotifications, setHasNotifications] = useState(hasUnreadNotifications)

  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const onClick = (isExpanded) => {
    setExpanded(isExpanded)
  }

  const dismissNotifications = () => {
    if (hasNotifications) {
      setHasNotifications(false)
      const payload = {
        court_case_notification: {
          court_case_id: caseId,
          investor_id: investorId
        },
        authenticity_token: csrfToken
      }
      axios.put(`/case-notifications/${caseId}.json`, payload)
      updateUnseenNotifications(totalNotifications - 1)
    }
  }

  return (
    <Widget hide={!expanded} onClick={dismissNotifications}
      highlight={hasNotifications && totalNotifications}
      bottomOffset={(timeline.length > 1 && expanded) || (timeline.length == 0) ? true : false}
      className={`js-case-update-widget${hasNotifications ? ' js-highlighted' : ''}`}>
      <Grid className="grid-margin-x">
        <Cell small={12}>
          <CaseType>{caseType}</CaseType>
          <Heading href={`/cases/${caseId}`} target="_blank">{heading}</Heading>
        </Cell>
      </Grid>
      <BasicInfo>
        <SummaryContainer>
          <SmallHeading>Investment</SmallHeading>
          <Summary>{amount}</Summary>
        </SummaryContainer>
        <SummaryContainer>
          <SmallHeading>Investment Date</SmallHeading>
          <Summary>{investmentDate}</Summary>
        </SummaryContainer>
        <SummaryContainer>
          <SmallHeading>Stage</SmallHeading>
          <Summary>{caseStage}</Summary>
        </SummaryContainer>
        { docketUrl &&
          <SummaryContainer>
            <SmallHeading>Case Docket</SmallHeading>
            <Summary>
              <Link href={docketUrl} target="_blank">Download</Link>
            </Summary>
          </SummaryContainer>
        }
        <Clear />
      </BasicInfo>
      { timeline.length > 0 &&
        <Fragment>
          <TimelineContainer>
            <Timeline key={id}>
              { timeline.map((item, i) => (
                <Fragment key={`timeline-item-${i}`}>
                  <TimelineItem>
                    { large &&
                      <TimelineOppositeContent>
                        <DateTime fontWeight={300} color="textSecondary">
                          {item.friendly_date}
                        </DateTime>
                      </TimelineOppositeContent>
                    }
                    <TimelineSeparator>
                      <TimelineDot
                        className={item.sentiment}
                        data-tip={`LexShares believes this is a ${item.sentiment} update.`}
                        data-place="bottom"
                      />
                      <TimelineConnector className={item.sentiment} />
                    </TimelineSeparator>
                    <TimelineContent>
                      { !large &&
                        <DateTime>
                          {item.friendly_date}
                        </DateTime>
                      }
                      <Description>
                        {item.description}
                      </Description>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              ))}
            </Timeline>
          </TimelineContainer>
          <ReactTooltip
            border={true}
            backgroundColor="#1E1924"
            borderColor="#0b0a0d"
            className="case-update-tooltip"
          />
        </Fragment>
      }
      { (timeline.length > 1) &&
        <Fragment>
          { expanded
            ?
              <Expand onClick={() => {onClick(false)}} expanded={true}>View Less</Expand>
            :
              <Expand onClick={() => {onClick(true)}} collapsed={true} className="js-view-more-link">View More</Expand>
          }
        </Fragment>
      }
    </Widget>
  )
}

export default ActivityWidget
