import styled from 'styled-components';
import { H1 } from '../../Globals';
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  background-color: ${theme.colors.darkGrey};
`;

export const Content = styled.div`
  padding: 12% 3% 10% 3%;
  text-align: center;
  max-width: 1235px;
  margin: 0 auto;

  ${breakpoint('medium')`
    padding: 10% 3% 6% 3%;
  `}

  ${breakpoint('large')`
    padding: 7% 3% 6% 3%;
  `}
`;

export const Header = styled(H1)`
  text-align: center;
  color: ${theme.colors.white};
  margin-top: 3%;
  font-size: 2rem;
`;

export const Tagline = styled.p`
  text-align: center;
  color: ${theme.colors.white};
  margin-top: 3%;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;

  ${breakpoint('medium')`
    font-size: 1.3rem;
  `}
`;