import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Content, InvestmentContainer, Heading, Subheading, Description, MarginCell } from './WaitlistSection.style';
import { InvestmentCard } from '../../funding';


function WaitlistSection({investments = [], csrfToken = null}) {
  const renderInvestments = () => {
    return investments.map((investment, i) => {
      return (
        <InvestmentContainer key={i}>
          <InvestmentCard {...investment} inverted={true} csrfToken={csrfToken} />
        </InvestmentContainer>
      );
    });
  }

  return (
    <Fragment>
      <Section>
        <Content>
          <Heading>
            Join the waitlist
          </Heading>

          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Grid className="align-center">
                <MarginCell className="cell small-12 large-4">
                  { renderInvestments() }
                </MarginCell>
                <Cell small={12} large={4}>
                  <Subheading>
                    Funding Complete
                  </Subheading>
                  <Description>
                    Due to high investor demand, the offering is already fully subscribed.
                  </Description>
                  <Description>
                    However, you can still request access to review the details of the offering, and join the waitlist if you are interested in investing in this opportunity.
                  </Description>
                  <Description>
                    If you join the waitlist, and an allocation opens up for you, our investor operations team will contact you directly.
                  </Description>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default WaitlistSection;
