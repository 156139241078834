import React from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, Heading, Subheading, Step,
  StepNumber, StepTitle, StepDescription,
  StepIcon, Button, Diamond
} from './ProcessSection.style';
import TaskImg from '../../../../assets/images/icons/task-tick.png';
import SearcImg from '../../../../assets/images/icons/search-a.png';
import DealImg from '../../../../assets/images/icons/deal.png';
import WebBankingImg from '../../../../assets/images/icons/web-banking.png';

function ProcessSection({background}) {
  return (
    <Section style={{backgroundColor: `${background}`}}>
      <Diamond>
        <Content>
          <Grid>
            <Cell small={12}>
              <Heading>
                The LexShares law firm funding process
              </Heading>
              <Subheading>
                Attorneys and law firms are eligible to submit claims for review.
              </Subheading>
            </Cell>
          </Grid>
          <Grid>
            <Cell medium={3} small={12} className="step">
              <Step>
                <StepIcon>
                  <img src={TaskImg} />
                </StepIcon>
                <StepNumber>
                  1
                </StepNumber>
                <StepTitle>
                  Apply
                </StepTitle>
                <StepDescription>
                  Submit your application for funding and execute NDA.
                </StepDescription>
              </Step>
            </Cell>
            <Cell medium={3} small={12} className="step">
              <Step>
                <StepIcon>
                  <img src={SearcImg} />
                </StepIcon>
                <StepNumber>
                  2
                </StepNumber>
                <StepTitle>
                  Review
                </StepTitle>
                <StepDescription>
                  LexShares&apos; team reviews your case.
                </StepDescription>
              </Step>
            </Cell>
            <Cell medium={3} small={12} className="step">
              <Step>
                <StepIcon>
                  <img src={DealImg} />
                </StepIcon>
                <StepNumber>
                  3
                </StepNumber>
                <StepTitle>
                  Approve
                </StepTitle>
                <StepDescription>
                  LexShares sends a contract detailing terms of funding.
                </StepDescription>
              </Step>
            </Cell>
            <Cell medium={3} small={12} className="step">
              <Step>
                <StepIcon>
                  <img src={WebBankingImg} />
                </StepIcon>
                <StepNumber>
                  4
                </StepNumber>
                <StepTitle>
                  Fund
                </StepTitle>
                <StepDescription>
            Contract executed and funding deployed.
                </StepDescription>
              </Step>
            </Cell>
          </Grid>
          <Grid>
            <Cell small={12} className="text-center">
              <Button href="/funding/case">
                Submit a claim now
              </Button>
            </Cell>
          </Grid>
        </Content>
      </Diamond>
    </Section>
  )
}

export default ProcessSection;
