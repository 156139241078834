import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Grid, Cell } from "react-foundation";
import InvestmentCardWhite from "../InvestmentCardWhite";

const cards = [
  {
    case_type: "Breach of Contract",
    long_offering_size: "$1,500,000",
    case_description:
      "The defendant allegedly breached a licensing agreement, failing to comply with its obligations to develop, market, and sell the plaintiff's product.",
    funding_type: "Single Case",
  },
  {
    case_type: "Theft of Trade Secrets",
    long_offering_size: "$2,300,000",
    case_description:
      "Fortune 500 defendants allegedly misappropriated the plaintiff's trade secrets after executives breached multiple non-disclosure agreements.",
    funding_type: "Single Case",
  },
  {
    case_type: "Law Firm Funding",
    long_offering_size: "$4,000,000",
    case_description:
      "A law firm funding arrangement collateralized by a portfolio of cases in the pharmaceutical industry.",
    funding_type: "Portfolio",
  },
  {
    case_type: "Settlement",
    long_offering_size: "$3,500,000",
    case_description:
      "A settlement acceleration arrangement involving multiple real estate disputes related to an executed settlement.",
    funding_type: "Single Case",
  },
  {
    case_type: "Breach of Fiduciary Duty",
    long_offering_size: "$475,000",
    case_description:
      "A government entity, in its capacity as trustee, allegedly breached its duties to the plaintiff by mismanaging key assets.",
    funding_type: "Single Case",
  },
  {
    case_type: "Fraud",
    long_offering_size: "$2,000,000",
    case_description:
      "The defendants allegedly defrauded the plaintiffs by squeezing them out of their equity interest in the company.",
    funding_type: "Single Case",
  },
];

const InvestmentCardsSection = () => {
  return (
    <StyledGrid className="align-center grid-margin-x grid-margin-y">
      {cards.map((investment, i) => (
        <Cell large={4} key={`investment-card-${i}`}>
          <InvestmentCardWhite
            key={i}
            investment={investment}
            className="investment-card"
          />
        </Cell>
      ))}
    </StyledGrid>
  );
};

export default InvestmentCardsSection;

const StyledGrid = styled(Grid)`
  .investment-card {
    ${breakpoint("large")`
      min-height: 500px;
  `}
  }
`;
