import React, { Fragment } from 'react';
import breakpoint from 'styled-components-breakpoint';
import { Grid, Cell } from 'react-foundation';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import Navbar from '../../components/attorney-resource-center/Navbar/Navbar';
import Footer from '../../components/Footer';
import WhitepaperCta from '../../components/whitepapers-show/WhitepaperCta/WhitepaperCta';
import WhitepaperBannerImage from '../../components/whitepapers-show/WhitepaperBannerImage/WhitepaperBannerImage';
import WhitepaperTopics from '../../components/whitepapers-show/WhitepaperTopics/WhitepaperTopics';

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`;

const Container = styled.div`
  margin: 33px 20px;

  ${breakpoint('medium')`
    margin: 40px 0px;
    padding: 80px 0px 90px 70px;
  `}

  ${breakpoint('large')`
    margin: 80px 0px;
    padding: 120px 0px 130px 80px;
  `}
`;

class WhitepapersShow extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Page>
            <Navbar attorneyResourceCenterPath={this.props.attorneyResourceCenterPath} caseStudiesPath={this.props.caseStudiesPath} fundingAttorneysPath={this.props.fundingAttorneysPath} fundingPlaintiffsPath={this.props.fundingPlaintiffsPath} fundingGeneralCounselPath={this.props.fundingGeneralCounselPath} fundingCasePath={this.props.fundingCasePath} rootPath={this.props.rootPath} fundingInsightsPath={this.props.fundingInsightsPath} regulationsTrendPath={this.props.regulationsTrendPath} slipOpinionPath={this.props.slipOpinionPath} whitepapersPath={this.props.whitepapersPath} />
            <Container>
              <Grid>
                <Cell small={12} medium={6} className="small-order-2 medium-order-1">
                  <WhitepaperCta whitepaper={this.props.whitepaper} whitepapersPath={this.props.whitepapersPath} csrfToken={this.props.csrfToken} />
                </Cell>
                <Cell small={12} medium={6} className="small-order-1 medium-order-2">
                  <WhitepaperBannerImage whitepaper={this.props.whitepaper} />
                </Cell>
              </Grid>
              <WhitepaperTopics whitepaper={this.props.whitepaper} />
            </Container>
          </Page>
          <Footer bgColor="lighterPurple"/>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default WhitepapersShow;
