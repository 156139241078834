import React, { useState } from 'react';
import { Grid, Cell } from 'react-foundation';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from 'yup';
import InputButton from '../../Form/InputButton';
import InputCheck from '../../Form/InputCheck';
import ProgressBar from '../../ProgressBar';
import { BackButton, ButtonsContainer, Container, ErrorMessage, FinishProfileLaterContainer, FinishProfileLaterLink, FormContainer, Heading, Subheading, SubheadingContainer } from './OnboardingStepTwo.style';
import FocusError from '../../FocusError/FocusError';

const OnboardingStepTwo = ({setOnboardingStep, accountTypes, setAccountTypes, investor, csrfToken, setCurrentInvestor}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object({
    account_individual: yup.boolean(),
    account_entity: yup.boolean(),
    account_trust: yup.boolean(),
    account_ira: yup.boolean(),
  })
  .test(
    'atLeastOneChecked',
    null,
    (obj) => {
      if (obj.account_individual || obj.account_entity || obj.account_trust || obj.account_ira) {
        return true;
      } else {
        return new yup.ValidationError(
          'You must select at least one option.',
          null,
          'accountCount'
        )
      }
    }
  );

  const defaultValues = {...accountTypes};

  const handleSubmit = ({account_individual, account_entity, account_trust, account_ira}) => {
    setIsSubmitting(true);
    const payload = {
      investor: {
        account_individual: 1,
        account_entity: account_entity ? 1 : 0,
        account_trust: account_trust ? 1 : 0,
        account_ira: account_ira ? 1 : 0,
        has_completed_profile: 0,
      },
      authenticity_token: csrfToken
    }

    axios.patch(`/investors/${investor.id}.json`, payload).then((response) => {
      setAccountTypes({
        account_individual: account_individual,
        account_entity: account_entity,
        account_trust: account_trust,
        account_ira: account_ira,
      })

      account_individual && !account_entity && !account_trust && !account_ira ? setOnboardingStep(3) : setOnboardingStep(2.5);
      setCurrentInvestor(response.data.investor);
    }).catch((e) => {
      setIsSubmitting(false);
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    })
  }

  const accountOne = `<strong>INDIVIDUAL</strong><br>
  <p>I will be investing in my individual capactiy.
  `

  const accountTwo = `<strong>ENTITY</strong><br>
  <p>I will be investing through an entity.
  `

  const accountThree = `<strong>TRUST</strong><br>
  <p>I will be investing through a trust.
  `

  const accountFour = `<strong>IRA</strong><br>
  <p>I will be investing from a retirement account.
  `

  return(
    <Container>
      <ProgressBar step={2} steps={5} percent={40} />
      <Heading>Connect Account</Heading>
      <SubheadingContainer>
        <Subheading>Select the account type you want to connect to your LexShares investor profile. You can always change or add account types once your profile is complete. <strong>Select all that apply.</strong></Subheading>
      </SubheadingContainer>
      <Formik
        initialValues={defaultValues}
        onSubmit={(e) => handleSubmit(e)}
        validationSchema={schema}
      >
        {
          ({ errors, handleSubmit, setFieldValue }) =>
            <form onSubmit={ handleSubmit }>
              <FocusError />
              <FormContainer className={errors.accountCount ? 'error' : null}>
                <Grid className="grid-margin-x">
                  <Cell small={12}>
                    <InputCheck
                      changeHandler={(event) => event.target.checked = true}
                      label={accountOne}
                      name="account_individual"
                      checked={accountTypes.account_individual}
                      error={errors.account_individual}
                      blockFormatting={true}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12}>
                    <InputCheck
                      changeHandler={(event) => setFieldValue("account_entity", event.target.checked)}
                      label={accountTwo}
                      name="account_entity"
                      checked={accountTypes.account_entity}
                      error={errors.account_entity}
                      blockFormatting={true}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12}>
                    <InputCheck
                      changeHandler={(event) => setFieldValue("account_trust", event.target.checked)}
                      label={accountThree}
                      name="account_trust"
                      checked={accountTypes.account_trust}
                      error={errors.account_trust}
                      blockFormatting={true}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12}>
                    <InputCheck
                      changeHandler={(event) => setFieldValue("account_ira", event.target.checked)}
                      label={accountFour}
                      name="account_ira"
                      checked={accountTypes.account_ira}
                      error={errors.account_ira}
                      blockFormatting={true}
                    />
                  </Cell>
                </Grid>
                {Object.keys(errors).length > 0 && <ErrorMessage>{Object.values(errors).join(', ')}</ErrorMessage>}
              </FormContainer>
              <ButtonsContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6} className="medium-order-1 small-order-2">
                    <BackButton onClick={() => setOnboardingStep(1)}>Back</BackButton>
                  </Cell>
                  <Cell small={12} medium={6} className="medium-order-2 small-order-1">
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value={isSubmitting ? 'Submitting..' : 'Continue'}
                      name="submit-button"
                      disabled={isSubmitting}
                    />
                  </Cell>
                </Grid>
              </ButtonsContainer>
            </form>
        }
      </Formik>
      <FinishProfileLaterContainer>
        <FinishProfileLaterLink href="/dashboard">Finish Profile Later</FinishProfileLaterLink>
      </FinishProfileLaterContainer>
    </Container>
  )
}

export default OnboardingStepTwo;