import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'

export const Content = styled.div`
`

export const Widget = styled.div`
  padding: 21px 31px;
  background-color: #1E1924;
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
`

export const Loading = styled.p`
  color: white;
  text-align: center;
  font-style: italic;
  font-weight: 300;
`


export const Heading = styled(Globals.H3)`
  color: white;
  margin-bottom: 50px;
`

export const Text = styled.p`
  color: white;
`

export const Tabs = styled.div`
  margin-top: 40px;
  color: white;
  margin-bottom: 35px;
  margin-left: 3px;
`

export const Link = styled.a`
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  ${breakpoint('medium')`
    margin-right: 30px;
  `}
  &:hover, &.selected {
    color: #8247BA;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 120%;
      height: 5px;
      background-color: #8247BA;
      margin-top: 10px;
      margin-left: -10%;
    }
  }
`
