import React, { useState } from 'react';
import { Formik } from 'formik'
import { Grid, Cell } from 'react-foundation';
import axios from 'axios'
import InputButton from '../../Form/InputButton';
import ProgressBar from '../../ProgressBar';
import { BackButton, ButtonsContainer, Container, Heading, ImportantHeader, ImportantContent } from './OnboardingStepFive.style';
import FocusError from '../../FocusError/FocusError';

const OnboardingStepFice = ({setOnboardingStep, csrfToken, investor, setCurrentInvestor}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const payload = {
      investor: {
        has_completed_profile: 1,
      },
      authenticity_token: csrfToken
    }

    axios.patch(`/investors/${investor.id}.json`, payload).then((response) => {
      setCurrentInvestor(response.data.investor);
      window.location = '/dashboard';
    }).catch((e) => {
      setIsSubmitting(false);
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    })
  }

  return(
    <Container>
      <ProgressBar step={5} steps={5} percent={100} />
      <Heading>Complete your profile</Heading>
      <ImportantHeader>Please note:</ImportantHeader>
      <ImportantContent>You will be emailed when new opportunities are available for investment on the LexShares platform. Thank you for being a LexShares investor.</ImportantContent>

      <Formik
        initialValues={{}}
        onSubmit={(e) => handleSubmit(e) }
      >
        {
          ({ handleSubmit }) =>
            <form onSubmit={ handleSubmit }>
              <FocusError />
              <ButtonsContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6} className="medium-order-1 small-order-2">
                    <BackButton onClick={() => setOnboardingStep(4)}>Back</BackButton>
                  </Cell>
                  <Cell small={12} medium={6} className="medium-order-2 small-order-1">
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value={isSubmitting ? 'Submitting..' : 'Complete'}
                      name="submit-button"
                      disabled={isSubmitting}
                    />
                  </Cell>
                </Grid>
              </ButtonsContainer>
            </form>
        }
      </Formik>
    </Container>
  )
}

export default OnboardingStepFice;