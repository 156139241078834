import React from 'react';
import { Cell, Grid } from 'react-foundation'
import { Container, Content, Header } from './Banner.style';

const Banner = () => {
  return(
    <Container>
      <Content>
        <Grid className="grid-x grid-margin-x">
          <Cell small={12}>
            <Header>Confirm your email</Header>
          </Cell>
        </Grid>
      </Content>
    </Container>
  )
}

export default Banner;