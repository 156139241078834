import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as SB from './../../SimpleBanner'
import * as Globals from '../../Globals'

export const Section = styled.div`
  border-top: 1px solid #ccc;
  display: none;
  font-size: 14px;
  &.selected {
    display: block;
  }
  .grid-heading {
    font-weight: bold;
  }
`

export const Container = styled.div`
  overflow-x: auto;
  margin-bottom: 30px;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    height: 7px !important;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(130,71,186,0.6);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(42,34,51,1);
  }
`

export const Investments = styled.div`
  width: 830px; 
  padding-bottom: 15px;
`

export const Content = styled(SB.Content)`
  max-width: 1235px;
  margin: 50px auto;
  padding: 0px 7%;
  text-align: left;
  min-height: 65px;
  ${breakpoint('medium')`
    padding: 0px 7%;
    text-align: left;
  `}
  ${breakpoint('large')`
    padding: 0px 2%;
  `}
`

export const SubsectionContainer = styled.div`
  padding: 0 0;
  margin-top: 30px;
  margin-bottom: 15px;
  &:first-of-type {
    margin-top: 0px;
  }
`

export const Subsection = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  &:last-of-type {
    border-bottom: 0px;
  }
  ${breakpoint('large')`
    margin: 0 0px;
  `}
  video {
    width: 100%;
  }
  img, iframe {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    & +p {
      text-align: center;
      margin-bottom: 40px;
    }
    &.inline-block {
      display: inline-block;
    }
  }
  blockquote {
    margin: 0;
    border: 0;
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
`

export const SmallHeading = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
`


export const MediumHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
`

export const BigHeading = styled(Globals.H3)`
  ${breakpoint('large')`
    font-size: 32px;
  `}
`

export const Label = styled.span`
  font-weight: bold;
  line-height: 30px;
  display: inline-block;
  margin-right: 5px;
`

export const InfoText = styled.span`
  line-height: 30px;
  font-size: 14px;
  display: block;
`

export const SiteLink = styled.a`
  color: ${props => props.theme.colors.neonPurple};
  display: inline-block;
  margin-right: 8px;
  &:active {
    color: ${props => props.theme.colors.inactivePurple}
  }
  &:hover {
    color: ${props => props.theme.colors.inactivePurple}
  }
`

export const ColumnHeading = styled.h4`
  font-weight: bold;
  font-size: 20px;
`


export const CashFlowImage = styled.img`
  margin-top: 30px;
`

export const InvestmentAnalysisDisclosure = styled.p`
  font-size: 12px;
  text-align: left;
  margin-top: 20px;
`
