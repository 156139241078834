import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation'
import { Banner, Header, Link } from './ExploreFundingOptionsBanner.style';

const ExploreFundingOptionsBanner = ({fundingCasePath}) => {
  return(
    <Fragment>
      <Banner data={fundingCasePath}>
        <Grid className="grid-x grid-margin-x">
          <Cell small={12}>
            <Header>
                Have a case that may need funding? Explore your funding options{' '}
                <Link href={fundingCasePath}>here</Link>.
            </Header>
          </Cell>
        </Grid>
      </Banner>
    </Fragment>
  )
}

export default ExploreFundingOptionsBanner;