import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import Hero from '../../../../assets/images/backgrounds/diamond-array-purple.png'

export const Container = styled.div`
  width: 186px;
  margin: auto;

  ${breakpoint('medium')`
    width: 250px;
    margin: 0px 40px 0px auto;
  `}

  ${breakpoint('large')`
    width: 400px;
    margin: 0px 188px 100px auto;
  `}
`;

export const DiamondBackground = styled(SB.Banner)`
  display: none;

  ${breakpoint('large')`
    display: block;
    background: url(${Hero}) no-repeat center center/cover;
    position: absolute;
    right: 22px;
    top: unset;
    width: 410px;
    height: 332px;
    margin-top: 310px;
  `}
`;

export const ImageContainer = styled.div`
  margin-top: 0px;
  position: relative;

  ${breakpoint('medium')`
    width: 400px;
    right: 100px;
  `}

  ${breakpoint('large')`
    width: 500px;
    right: 100px;
  `}
`;

export const Image = styled.img`
`;