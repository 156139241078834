import styled from 'styled-components'
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.Widget)`
  padding: 0;
  background-color: #1E1924;
  min-height: 458px;
  cursor: pointer;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const TitleBar = styled(Globals.WidgetTitleBar)`
`

export const Logo = styled(Globals.WidgetLogo)`
`

export const Title = styled(Globals.WidgetTitle)`
`

export const DateStamp = styled(Globals.WidgetDateStamp)`
`

export const Image = styled.div`
  width: 100%;
  height: 250px;
  background: url(${props => props.source}) no-repeat center center/cover;
`

export const Container = styled.div`
  padding: 21px 31px;
`

export const Heading = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: white;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: white;
  margin-top: 13px;
  -webkit-font-smoothing: antialiased;
`
