import React, { useState } from 'react';
import OnboardingStepOne from '../OnboardingStepOne/OnboardingStepOne';
import OnboardingStepTwo from '../OnboardingStepTwo/OnboardingStepTwo';
import OnboardingStepTwoContinued from '../OnboardingStepTwoContinued/OnboardingStepTwoContinued';
import OnboardingStepThree from '../OnboardingStepThree/OnboardingStepThree';
import OnboardingStepFour from '../OnboardingStepFour/OnboardingStepFour';
import OnboardingStepFive from '../OnboardingStepFive/OnboardingStepFive';
import { Container } from './Form.style';

const Form = ({csrfToken, investor}) => {
  const [onboardingStep, setOnboardingStep] = useState(1);
  const [currentInvestor, setCurrentInvestor] = useState(investor);
  const [accountTypes, setAccountTypes] = useState({
    account_individual: true,
    account_entity: investor.entity != null,
    account_trust: investor.trust != null,
    account_ira: investor.retirement_account != null,
  })

  const setOnboardingStepAndScroll = (step) => {
    setOnboardingStep(step);
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return(
    <Container>
      {onboardingStep === 1 && <OnboardingStepOne csrfToken={csrfToken} investor={currentInvestor} setCurrentInvestor={setCurrentInvestor} setOnboardingStep={setOnboardingStepAndScroll}/>}
      {onboardingStep === 2 && <OnboardingStepTwo csrfToken={csrfToken} investor={currentInvestor} setCurrentInvestor={setCurrentInvestor} setOnboardingStep={setOnboardingStepAndScroll} accountTypes={accountTypes} setAccountTypes={setAccountTypes}/>}
      {onboardingStep === 2.5 && <OnboardingStepTwoContinued csrfToken={csrfToken} investor={currentInvestor} setCurrentInvestor={setCurrentInvestor} accountTypes={accountTypes} setOnboardingStep={setOnboardingStepAndScroll} setAccountTypes={setAccountTypes} />}
      {onboardingStep === 3 && <OnboardingStepThree csrfToken={csrfToken} investor={currentInvestor} setCurrentInvestor={setCurrentInvestor} setOnboardingStep={setOnboardingStepAndScroll} accountTypes={accountTypes} />}
      {onboardingStep === 4 && <OnboardingStepFour csrfToken={csrfToken} investor={currentInvestor} setCurrentInvestor={setCurrentInvestor} setOnboardingStep={setOnboardingStepAndScroll}/>}
      {onboardingStep === 5 && <OnboardingStepFive csrfToken={csrfToken} investor={currentInvestor} setCurrentInvestor={setCurrentInvestor} setOnboardingStep={setOnboardingStepAndScroll}/>}
    </Container>
  )
}

export default Form;