import React, { useState } from 'react';
import axios from 'axios';

import { Container, ErrorMessage, Form, InputSubmit, InputField } from './SubscribeForm.style';
import Mixpanel from '../../Mixpanel'

const SubscribeForm = ({csrfToken, slipOpinionRequestsPath}) => {
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      slip_opinion: {
        email: email
      },
      authenticity_token: csrfToken
    }

    axios.post(slipOpinionRequestsPath, payload).then(() => {
      window.location = '/resources?slip_confirmation=true';
      setSubscribed(true);
      setErrorMessage("");
      Mixpanel.track('Subscribed via Slip Opinion widget')
    }).catch((e) => {
      setErrorMessage(e.response.data.errors);
    })
  }

  return(
    <Container>
      <Form onSubmit={handleSubmit} >
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <InputField type="email" disabled={subscribed ? 'disabled' : ''} placeholder="Email address" name="email" onChange={(e) => setEmail(e.target.value)} value={email} required={true} />
        <InputSubmit type="submit" disabled={subscribed ? 'disabled' : ''} value={subscribed ? '✓ Subscribed' : 'Subscribe'} />
      </Form>
    </Container>
  )
}

export default SubscribeForm;