import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from '../../../SimpleBanner';

export const Banner = styled(SB.Banner)`
  padding: 0;
`;

export const BannerContent = styled(SB.Content)`
  max-width: 1235px;
  padding: 2% 0 6% 4%;
  ${breakpoint('medium')`
    padding: 5% 3% 0 5%;
  `}
  ${breakpoint('large')`
    padding: 3% 3% 1% 4%;
  `}
`;

export const Heading = styled(SB.Heading)`
  text-align: center;
`

export const Description = styled.div`
  font-size: 16px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 1.6;
  font-weight: bold;
  -webkit-font-smoothing: auto;
  margin-bottom: 16px;
`
