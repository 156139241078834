import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as SB from "./../../SimpleBanner";
import Hero from "../../../../assets/images/backgrounds/diamond-array-purple.png";

export const Container = styled.div`
  width: 186px;
  margin: auto;
  position: absolute;

  ${breakpoint("medium")`
    width: 250px;
    margin: 0px 40px 0px auto;
  `}

  ${breakpoint("large")`
    width: 400px;
    margin: 0px;
  `}
`;

export const DiamondBackground = styled(SB.Banner)`
  display: none;

  ${breakpoint("large")`
    display: block;
    background: url(${Hero}) no-repeat center center/cover;
    position: absolute;
    top: unset;
    width: 410px;
    height: 332px;
    margin-top: 430px;
    margin-left: 300px;
  `}
`;

export const ImageContainer = styled.div`
  margin-top: 0px;
  margin-left: 100px;
  position: relative;
  width: 500px;
`;
