import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../../Globals'

const positiveColor = '#34B53A'
const negativeColor = '#7b7b7b'
const neutralColor = '#7936b5'

export const Description = styled.p`
  font-family: 'proxima-nova' !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  -webkit-font-smoothing: antialiased;
`

export const DateTime = styled.p`
  font-size: 12px !important;
  margin-bottom: 10px;
  font-family: 'proxima-nova' !important;
  font-weight: 300 !important;
  -webkit-font-smoothing: antialiased;
`

export const Widget = styled(Globals.ExpandableWidget)`
  &.open {
    height: 100%;
  }

  .MuiTimeline-root {
    margin-top: 0px;
    padding-left: 0;
    margin-left: 0;
    padding-right: 0;
    ${breakpoint('medium')`
      margin-top: 30px;
      padding-right: 16px;
    `}
  }

  .MuiTimelineOppositeContent-root {
    padding: 0px 5px;
    margin-top: 2px;
  }
 
  .MuiTimelineItem-oppositeContent {
    max-width: 122px !important;
    margin-right: 15px;
    padding-top: 0px;
    p { 
      color: white !important;
      font-size: 12px;
    }
  }

  .MuiTimelineItem-content {
    margin-left: 15px;
    margin-bottom: 20px;
    padding-top: 0px;
    margin-top: -2px;
    p {
      color: white;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
    }
    p.date {
      font-size: 12px;
      margin-bottom: 10px;
      font-family: 'proxima-nova' !important;
      font-weight: 300 !important;
    }
    p.description {
      font-family: 'proxima-nova' !important;
      font-size: 16px !important;
      font-weight: 300 !important;
    }
  }

  .MuiTimelineConnector-root {
    width: 1px;
    background-color: #373737 !important;
  }

  .MuiTimelineDot-root {
    border-width: 3px;
    margin-bottom: 0;
    margin-top: 3px;
    box-shadow: 0px 3px 1px -2px;
    &.positive {
      background-color: ${positiveColor} !important;
      color: unset !important;
    }
    &.neutral {
      background-color: ${neutralColor} !important;
      color: unset !important;
    }
    &.negative {
      background-color: ${negativeColor} !important;
      color: unset !important;
    }
  }

  .MuiTimelineItem-root:last-of-type .MuiTimelineSeparator-root .MuiTimelineConnector-root {
    display: none;
  }

  .MuiTimelineItem-missingOppositeContent::before {
    display: none;
    ${breakpoint('medium')`
      display: block;
    `}
  }

  .case-update-tooltip {
    opacity: 1 !important;
    font-weight: 300;
    font-size: 12px;
    color: #F8F7FA;
    font-family: 'proxima-nova' !important;
  }
`

export const Expand = styled(Globals.WidgetExpander)`
`

export const CaseType = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.colors.purple};
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const Heading = styled.a`
  ${Globals.tiempoFontStack};
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: white;
  margin-bottom: 20px;
  text-decoration: underline;
  display: block;
  position: relative;
  z-index: 9;
  &:hover, &:visited  {
    color: white;
  }
`

export const BasicInfo = styled.div`
  max-width: 500px;
`

export const SmallHeading = styled.div`
  font-weight: 300;
  color: white;
  font-size: 12px;
  line-height: 16px;
`

export const SummaryContainer = styled.div`
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
  ${breakpoint('medium')`
    margin-right: 35px;
    margin-bottom: 0px;
  `}
`

export const Summary = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height; 36px;
  color: white;
  ${breakpoint('medium')`
    font-size: 16px;
  `}
`

export const TimelineContainer = styled.div`
  padding-top: 5px;
  clear: both;
  padding-top: 15px;
  ${breakpoint('medium')`
    padding-top: 5px;
  `}
`

export const Link = styled.a`
  color: ${props => props.theme.colors.purple};
  z-index: 900;
  position: relative;
  &:hover, &:visited, &:active {
    color: ${props => props.theme.colors.purple};
  }
`

export const Clear = styled.div`
  clear: both;
`
