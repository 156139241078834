import React, { Fragment } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import {
  AccelerateSection, BannerSection, BenefitsSection,
  ProcessSection, DifferentSection, IntroSection,
  HowSection, WhySection, TypesSection, CriteriaSection
} from '../../components/law-firm-funding';
import LearnMoreSection from '../../components/LearnMoreSection'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import CTA from '../../../assets/images/law_firm_funding/cta.jpg';

const Page = styled.div`
  background-color: ${props => props.theme.colors.backdropPurple} !important;
`

class LawFirmFunding extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="transparent" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor}  currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <Page>
            <BannerSection />
            <IntroSection />
            <HowSection />
            <WhySection />
            <DifferentSection />
            <AccelerateSection />
            <BenefitsSection />
            <TypesSection />
            <ProcessSection />
            <CriteriaSection />
            <LearnMoreSection background={CTA} heading="Get funding from LexShares" subheading="Submit information about your firm to LexShares to discuss financing opportunities." buttonText="Get Funding" buttonLink="/funding/case" largeButton={false} />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default LawFirmFunding;
