import React, { Fragment, useRef, useEffect, useState } from 'react'
import { Grid, Cell } from 'react-foundation';
import {
  Section, Content, Nav, NavHeading, NavList, NavItem, FaqSection,
  FaqBody, FaqHeading, BodyHeading, BodySection, Link, Quote, QuoteAuthor,
  QuoteAuthorBackground, QuoteContainer, Sidebar, DownloadFormContainer, List,
  ListItem, Main, BodyLink, EndNote, EndNoteContainer, EndNoteText
} from './ContentSection.style';
import DownloadForm from '../DownloadForm';

const OFFSET = 110

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  const y = ele.getBoundingClientRect().top + window.pageYOffset - OFFSET;
  window.scrollTo({top: y, behavior: 'smooth'});
};

function ContentSection({csrfToken}) {
  const [visibleSection, setVisibleSection] = useState();

  const sidebarRef = useRef(null);

  const faqsList = [
    {
      label: "What is litigation finance?",
      title: "What is litigation finance?",
      ref: useRef(null),
      body: <FaqBody>
        <BodySection>Litigation finance (also called litigation funding or legal financing) is the practice where a third party unrelated to the lawsuit provides capital to a plaintiff involved in litigation in return for a portion of any financial recovery from the lawsuit.</BodySection>
        <BodySection>Litigation finance unlocks the value of legal claims by providing capital to plaintiffs before their cases are resolved. This type of financing has existed for more than 20 years in the United States and is increasingly becoming a mainstream solution that helps equalize access to the legal system. Fortune 500 companies, major universities and businesses of all sizes have benefited from litigation funding in recent years.</BodySection>
        <BodySection>Maya Steinitz, a legal scholar and University of Iowa College of Law professor with industry expertise, refers to the emergence of litigation finance as “likely the most important development in civil justice of our time.”<EndNote><a href="#endnote">2</a></EndNote></BodySection>
      </FaqBody>
    },
    {
      body: <FaqBody className="show-for-small-only"> <DownloadForm csrfToken={csrfToken} /> </FaqBody>,
      ref: useRef(null)
    },
    {
      label: "Unequal Access",
      title: "Unequal access to the legal system",
      ref: useRef(null),
      body: <FaqBody>
        <BodySection>In much of the world, access to justice requires abundant capital because litigation is expensive. Many factors contribute to the cost of commercial litigation: attorney fees, research, depositions, interrogatories, motions, conferences, witness preparation, trials, subpoenas, appeals, as well as expenses associated with court fees, consultants, and investigations.</BodySection>
        <BodySection>All too often litigants who seek fair dispute resolution are unable to pursue their claims due to the high costs associated with lawsuits. Many plaintiffs who have a compelling case will choose to delay or even abandon legal recourse. A great imbalance of resources exists between average and wealthy litigants, creating impediments to judicial access and distortion of legal outcomes for the undercapitalized. Lawsuit financing helps solve this challenge by giving plaintiffs an effective substitute for standard lines of credit, such as loans.</BodySection>
        <BodySection>Capital provided by a litigation funding firm may directly pay for legal expenses, including attorneys’ fees, expert witness fees and court expenses. Funding providers generally specialize either in consumer lawsuit funding, which may serve personal injury victims who need to pay for living expenses, or in commercial legal financing, which supports businesses involved in complex lawsuits. Companies may need funding to secure working capital when sources are unavailable or insufficient, or their owners may require coverage for personal expenses. LexShares primarily focuses on lawsuit financing for commercial cases.</BodySection>
        <BodySection>Most commercial litigation finance companies do not provide traditional loans. Solutions are instead structured as non-recourse investments, which means that the funding recipient owes nothing if the lawsuit does not result in a recovery. If the case reaches a positive outcome, then the funding recipient would owe a predetermined portion of any damages recovered. The vast majority of litigation funding firms, including LexShares, do not attempt to control or influence the prosecution of any lawsuit they fund for practical and ethical reasons.</BodySection>
      </FaqBody>
    },
    {
      label: "Parties Involved",
      title: "Parties involved in commercial litigation finance",
      ref: useRef(null),
      body: <FaqBody>
        <BodySection>There are three primary participants in third-party litigation funding: plaintiffs, investors, and attorneys.</BodySection>
        <BodySection>
          <BodyHeading>
            PLAINTIFFS
          </BodyHeading>

          Plaintiffs are individuals or companies involved in lawsuits that need to fund litigation expenses, working capital or personal expenses. Follow the link for more information about <BodyLink href="https://www.lexshares.com/funding/plaintiffs">how plaintiff litigation funding works</BodyLink>.
        </BodySection>
        <BodySection>
          <BodyHeading>
            ATTORNEYS AND LAW FIRMS
          </BodyHeading>
            In most lawsuit funding transactions, attorneys serve as custodians of funds for all of the litigation’s stakeholders and distribute those funds accordingly. In some cases, law firms representing multiple plaintiffs on contingency fee arrangements may also receive legal financing directly. Follow the link to learn more about <BodyLink href="https://www.lexshares.com/law-firm-funding">law firm funding</BodyLink>. For more information about how LexShares works with attorneys, <BodyLink href="/funding/attorneys">click here</BodyLink>.
        </BodySection>
        <BodySection>
          <BodyHeading>
            INVESTORS
          </BodyHeading>
            Legal claims investment is a fast-growing alternative asset class that is relatively new to the U.S. Participating investors purchase portions of future proceeds from a lawsuit via an upfront cash payment. LexShares&apos; unique business model allows <BodyLink href="https://www.lexshares.com/blog/accredited-investors-podcast">accredited investors</BodyLink> to participate in pre-vetted investment offerings that have been posted to our litigation finance marketplace. To learn more about how the LexShares platform works for investors, <BodyLink href="https://www.lexshares.com/invest">click here</BodyLink>.
        </BodySection>
      </FaqBody>
    },
    {
      label: "Benefits of Litigation Finance",
      title: "Litigation finance offers a number of benefits",
      ref: useRef(null),
      body:  <FaqBody>
        <BodySection>Litigation finance companies provide a number of important benefits to plaintiffs, attorneys, law firms and investors.</BodySection>
        <BodySection>
          <BodyHeading>
            PLAINTIFFS
          </BodyHeading>
          <List>
            <ListItem>
              Helps undercapitalized plaintiffs continue to pursue meritorious cases by financing commercial litigation expenses
            </ListItem>
            <ListItem>
              Provides capital injections for already-filed cases that experience funding constraints
            </ListItem>
            <ListItem>
              Unlocks liquidity for working capital
            </ListItem>
            <ListItem>
              Provides a cushion for personal expenses
            </ListItem>
            <ListItem>
              Allows companies to manage how litigation costs affect their balance sheets
            </ListItem>
            <ListItem>
              Enables greater access to top legal resources
            </ListItem>
          </List>
        </BodySection>
        <BodySection>
          <BodyHeading>
            ATTORNEYS AND LAW FIRMS
          </BodyHeading>
          <List>
            <ListItem>
              Allows attorneys and law firms to accept cases from plaintiffs who otherwise could not afford their fees
            </ListItem>
            <ListItem>
              Provides capital for key litigation expenses, including expert witness fees
            </ListItem>
            <ListItem>
              Reduces the risk that clients will run out of money during litigation
            </ListItem>
            <ListItem>
              Enables attorneys and law firms to offer more flexible payment arrangements to prospective clients
            </ListItem>
            <ListItem>
              Increases plaintiffs’ effectiveness by providing funding for working capital and personal expenses
            </ListItem>
            <ListItem>
              Helps achieve recoveries that more accurately reflect case merits and damages
            </ListItem>
          </List>
        </BodySection>
        <BodySection>
          <BodyHeading>
            INVESTORS
          </BodyHeading>
          <List>
            <ListItem>
              Access to a growing alternative asset class
            </ListItem>
            <ListItem>
              Investments in legal claims are generally uncorrelated to capital markets
            </ListItem>
            <ListItem>
              Outsized historical returns compared with other alternative asset classes
            </ListItem>
            <ListItem>
              Moderate time to liquidity compared to other alternative investments
            </ListItem>
          </List>
        </BodySection>
      </FaqBody>
    },
    {
      label: "Attorney ethics and litigation finance",
      title: "Attorney ethics and litigation finance",
      ref: useRef(null),
      body: <FaqBody>
        <BodySection>
          Several states have issued bar ethics opinions that permit alternative litigation finance transactions, provided attorneys fulfill certain disclosure requirements and avoid conflicts of interest. For example, attorneys and law firms are allowed to share information about cases with investors after receiving the client’s consent. Moreover, they are allowed to honor a client’s written assignment or lien for a portion of the proceeds from a recovery. The New York City Bar Association has recently recognized the growth of litigation funding and commented that it is a &quot;valuable means for paying the costs of pursuing a legal claim.&quot;
        </BodySection>
        <BodySection>
          So far, more than 30 jurisdictions have issued ethics opinions relating to litigation finance. We have included commentary from a handful of state bar groups below.
        </BodySection>
        <BodySection>
          <Link target="_blank" href="http://www.calbar.ca.gov/Portals/0/documents/ethics/Opinions/Formal-Opinion-No-2020-204-Litigation-Funding.pdf">
            California Bar
          </Link>
          <Link target="_blank" href="http://documents.nycbar.org/files/Report_to_the_President_by_Litigation_Funding_Working_Group.pdf">
            New York City Bar
          </Link>
          <Link target="_blank" href="http://www.isba.org/sites/default/files/ethicsopinions/92-09.pdf">
            Illinois Bar
          </Link>
          <Link target="_blank" href="http://web.archive.org/web/20140725081514/http://www.lacba.org/showpage.cfm?pageid=433">
            Los Angeles County Bar
          </Link>
        </BodySection>
        <QuoteContainer>
          <Quote>
            “We conclude that lawyers and the clients they serve would benefit if lawyers have less restricted access to funding.”
          </Quote>
          <QuoteAuthor>
            — The NYCBA Working Group on Litigation Funding
            <EndNote><a href="#endnote">3</a></EndNote>
          </QuoteAuthor>
        </QuoteContainer>
      </FaqBody>
    },
    {
      label: "Legal Doctrines",
      title: "Legal doctrines relating to litigation finance",
      ref: useRef(null),
      body: <FaqBody>
        <BodySection>LexShares respects the legal issues and ethical considerations relating to investments in lawsuits. Notably, litigation finance intersects with the doctrines of maintenance, champerty, and barratry, as well as attorney-client privilege and work product immunity.</BodySection>
        <BodySection>
          <BodyHeading>
            Maintenance, Champerty and Barratry
          </BodyHeading>
        </BodySection>
        <BodySection>
          Maintenance involves an arrangement where a party supports another to enable him or her to further a legal claim. Champerty is a specific form of maintenance, where an unrelated party strikes a bargain with a litigant to financially support the litigation in return for a share of the proceeds from that claim. Finally, barratry entails the encouragement of another to bring or continue a claim.
        </BodySection>
        <BodySection>
          In medieval England, maintenance and champerty were used by the powerful as a means of settling scores. Feudal lords and other privileged members of society would often support legal disputes of others against the supporter’s personal or political enemy. Most colonies that imported their laws from England – including many U.S. states – passed laws designed to protect litigants from “officious intermeddling” and profiteering from the sale of legal claims to third parties.
        </BodySection>
        <BodySection>
          These three doctrines historically prohibited third-party litigation funding in the U.S. and most other common law countries. However, the modern view expressed by many courts is that maintenance, champerty and barratry are obsolete and do not reflect the realities of today’s legal industry -- particularly as other means of curtailing legal system abuses have been implemented. Widespread exceptions to these doctrines recognize that financial considerations often influence access to justice. Most states now permit litigation finance, champerty, maintenance, and barratry as they refuse to void contracts based on these outdated doctrines.
        </BodySection>
        <BodySection>
          <BodyHeading>
            Attorney-Client Privilege and Attorney Work Product Immunity
          </BodyHeading>
          The attorney-client privilege is a legal concept that protects certain communications between attorney and client from disclosure to the opposition. In addition, the work-product immunity protects materials prepared by attorneys and third parties in anticipation of litigation from discovery. The two concepts are separate legal doctrines but frequently cited together.
        </BodySection>
        <BodySection>
          Recent cases support the view that litigation finance transactions preserve the protections afforded by both doctrines. 
        </BodySection>
        <BodySection>
          Notably, in <i>Miller UK Ltd. v. Caterpillar, Inc.<EndNote><a href="#endnote">4</a></EndNote></i>, the court determined that the presence of a litigation funder did not waive work-product protection because disclosure did not “substantially increase the likelihood that an adversary would obtain the materials where (the) claimant had oral and written confidentiality agreements with prospective and actual funders.” 
        </BodySection>
        <BodySection>
          In <i>Devon IT, Inc., v. IBM Corp.<EndNote><a href="#endnote">5</a></EndNote></i>, a court held that discussions with litigation investors are covered by the work-product doctrine. The judge in that case explained that since the investors and Devon shared a &quot;common interest&quot; in the outcome of the case and had entered into the confidentiality agreement, their communications were protected. Executing non-disclosure agreements is standard practice in most lawsuit financing transactions.
        </BodySection>
        <BodySection>
          A litigation finance firm generally should not require privileged attorney-client communications to accurately assess most cases. In a practical sense, issues regarding the waiver of the privilege apply to only a small class of information. The client is free to tell a prospective funder anything other than what the client communicated to the attorney, without waiving the privilege. An investor can expect to receive substantial information from the plaintiff about the claim without ever creating waiver problems.
        </BodySection>
        <BodySection>
          LexShares&apos; managing member and broker-dealer, WealthForge, can evaluate the merits of cases without access to privileged information. The case evaluation process generally focuses on the legal team&apos;s assessment of the case as well as documents that are already subject to discovery.
        </BodySection>
      </FaqBody>
    },
    {
      label: "Additional Resources",
      title: "Additional resources",
      ref: useRef(null),
      body: <FaqBody>
        <BodySection>
          As commercial litigation finance becomes more accepted in the U.S., a growing number of legal scholars are paying attention to the field. Some of the most notable commentaries include:
        </BodySection>
        <BodySection>
          <Link target="_blank" href="https://www.americanbar.org/content/dam/aba/directories/policy/annual-2020/111a-annual-2020.pdf">
            American Bar Association Best Practices for Third-Party Litigation Funding (Resolution 111A)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="https://lawreview.law.ucdavis.edu/issues/53/2/essays/files/53-2_Steinitz.pdf">
            Maya Steinitz, “Follow the Money? A Proposed Approach for Disclosure of Litigation Finance Agreements,” UC Davis Law Review (2019)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="https://ir.law.fsu.edu/cgi/viewcontent.cgi?article=2556&context=lr">
            David R. Glickman, &quot;Embracing Third-Party Litigation Finance,&quot; Florida State Law Review (2016)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="http://www.minnesotalawreview.org/wp-content/uploads/2012/03/Steinitz_PDF.pdf">
            Maya Steinitz, Whose Claim Is This Anyway? Third Party Litigation Funding, Minnesota Law Review (2011)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="http://papers.ssrn.com/sol3/papers.cfm?abstract_id=1593329">
            Anthony Sebok, The Inauthentic Claim, Vanderbilt Law Review (2011)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="https://www.uclalawreview.org/revolution-in-progress-third-party-funding-of-american-litigation/">
            Jason Lyon, &quot;A Revolution in Progress,&quot; UCLA Law Review (2010)
          </Link>
        </BodySection>
        <BodySection id="endnote">
          <Link target="_blank" href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1962677">
            Jonathan Molot, &quot;Litigation Finance: A Market Solution to a Procedural Problem,&quot; Georgetown Law Journal (2010)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="http://www.rand.org/content/dam/rand/pubs/occasional_papers/2010/RAND_OP306.pdf">
            Steven Garber, &quot;Alternative Litigation Financing in the United States,&quot; RAND Corporation (2009)
          </Link>
        </BodySection>
        <BodySection>
          <Link target="_blank" href="https://www.law.nyu.edu/sites/default/files/CCJ%20Mandatory%20Disclosure%20Book.pdf">
            “Mandatory Disclosure Rules for Dispute Financing,” Center on Civil Justice New York University School of Law
          </Link>
        </BodySection>
        <EndNoteContainer>
          <EndNoteText>
            <Link target="_blank" href="https://cases.justia.com/new-york/other-courts/2013-ny-slip-op-33066-u.pdf?ts=1396133199">
              <EndNote>1</EndNote>
              Lawsuit Funding LLC v Lessoff, NY Slip Op 33066(U) (2013)
            </Link>
          </EndNoteText>
          <EndNoteText>
            <Link target="_blank" href="https://lawreview.law.ucdavis.edu/issues/53/2/essays/files/53-2_Steinitz.pdf">
              <EndNote>2</EndNote>
              Maya Steinitz, Follow the Money? A Proposed Approach for Disclosure of Litigation Finance Agreements, UC Davis Law Review (2019)
            </Link>
          </EndNoteText>
          <EndNoteText>
            <Link target="_blank" href="http://documents.nycbar.org/files/Report_to_the_President_by_Litigation_Funding_Working_Group.pdf">
              <EndNote>3</EndNote>
              The New York City Bar Association Working Group On Litigation Funding, Report to the President (2020)
            </Link>
          </EndNoteText>
          <EndNoteText>
            <EndNote>4</EndNote>
            Devon IT, Inc., v. IBM Corp., 2012 WL 4748160 (E.D. Pa.)
          </EndNoteText>
          <EndNoteText>
            <EndNote>5</EndNote>
            Miller UK Ltd. v. Caterpillar, Inc. 17 F. Supp. 3d 711 (N.D. Ill. 2014)
          </EndNoteText>
        </EndNoteContainer>
      </FaqBody>
    }
  ]

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + OFFSET + 1;

      const selected = faqsList.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);

          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.label !== visibleSection) {
        setVisibleSection(selected.label);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <Fragment>
      <Section>
        <Content>
          <Grid>
            <Cell large={3} className="show-for-large">
              <Sidebar ref={sidebarRef}>
                <Nav>
                  <NavHeading>
                    Contents
                  </NavHeading>
                  <NavList>
                    {
                      faqsList.map((faq, i) =>
                        faq.label && <NavItem selected={visibleSection == faq.label} key={i} onClick={() => scrollTo(faq.ref.current)}>
                          {faq.label}
                        </NavItem>
                      )
                    }
                  </NavList>
                </Nav>
                <DownloadFormContainer>
                  <DownloadForm csrfToken={csrfToken} />
                </DownloadFormContainer>
              </Sidebar>
            </Cell>

            <Cell large={8}>
              <Main>
                <QuoteContainer>
                  <Quote>
                    “Litigation funding allows lawsuits to be decided on their merits, and not based on which party has deeper pockets or stronger appetite for protracted litigation.”
                  </Quote>
                  <QuoteAuthor>
                    — Eileen Bransten
                    <EndNote><a href="#endnote">1</a></EndNote>
                  </QuoteAuthor>
                  <QuoteAuthorBackground>
                    New York Supreme Court Justice
                  </QuoteAuthorBackground>
                </QuoteContainer>

                {
                  faqsList.map((faq, i) =>
                    <FaqSection ref={faq.ref} key={i}>
                      <FaqHeading>
                        { faq.title }
                      </FaqHeading>

                      { faq.body }
                    </FaqSection>
                  )
                }
              </Main>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default ContentSection;
