import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Content
} from './ContentSection.style';

function ContentSection() {

  return (
    <Fragment>
      <Content>
        <Grid className="grid-margin-x">
          <Cell small={12}>
            <h5>Last Updated January 8, 2015</h5>
          </Cell>
        </Grid>
        <p>
          LexShares.com, Inc., together with its directors, employees, agents,
          suppliers, partners, and content providers (individually and collectively,
          “LexShares”, &quot;we,&quot; &quot;us,&quot; “our”) is committed to protecting your personal and
          financial information.
        </p>

        <p>
          This Privacy Policy (the “Policy”) answers some questions about your
          nonpublic personally identifiable information and non-personally
          identifiable information we collect in connection with your use of
          www.LexShares.com, including any related subdomain (the “Site”), the
          LexShares services, applications and software provided through or in
          connection with the service, including through a mobile device (the
          &quot;Service&quot;), how the information will be used, what we do with it, how we
          protect it, and your correction or deletion of that information. As of the
          date listed above, we have made some changes to this Policy that may affect
          what information we collect about you from either you or from third parties,
          and with whom that information may be shared. Specifically, certain
          information we collect about you from third parties concerning your
          accredited investor status may be shared with certain other third parties in
          accordance with this Policy.
        </p>

        <p>
          This Policy together with the Terms of Service (the “Terms”) (collectively,
          the “Agreement”) constitutes a contract between you and LexShares and
          governs your use of the Site and/or the Service and set forth the terms and
          conditions between you and LexShares when you access or use the Site or in
          connection with the Service, including participation or browsing. If you
          breach any of the terms of the Agreement, your right to use the Site or
          right to use the Service will terminate immediately.
        </p>

        <p>
          The Agreement does not apply to the practices of companies and third parties
          that LexShares does not own or control or to individuals whom LexShares does
          not employ or manage. LexShares reserves the right to use and control the
          information collected on the Site. By using the Site, you acknowledge that
          you have read and understood the Agreement and consent to the practices
          described in the Agreement.
        </p>

        <p>
          In the course of providing products and services, we need to collect and
          maintain certain nonpublic personally identifiable information about you. We
          may use this personal information about you to: (a) service and maintain
          your account, (b) to provide the Service, (c) facilitate communication
          between you and other users, (d) respond to inquiries from you or your
          representative, (e) develop, offer, and deliver products and services (f) to
          serve advertisements on the Site, (g) to enable our vendors and contractors
          to provide and assist us in the marketing, operation, troubleshooting and
          analysis of the Site and other services, (h) to analyze data to send more
          targeted messages to you or other users of our Site, (i) to conduct research
          and analyze data to improve our products, services, and the Site, (j) to
          deliver email messages like special offers, updates, newsletters, customer
          service, and service announcements, (k) to comply with and meet any
          regulatory requirements for the offer or sale of securities required by law,
          rules or regulations to our affiliates or members, (l) to protect the Site
          or its users, (m) to engage in any other lawful activities we believe are
          consistent with the foregoing; and (n) when required by law or to protect
          against legal liability. This personal information may include your name,
          email address, home address and phone number, date of birth, social security
          number and/or tax identification number, employer, and job title. When you
          subscribe to make an investment in one of the opportunities listed on the
          Site, you will again be asked for some of the same personal data as well as
          some additional data, such as your bank routing number and bank account
          number. This data is needed in order to enable ourselves or our
          broker-dealer partners to utilize your bank account to originate funds
          transfers and later to make investment disbursements to you.
        </p>

        <p>
          We use reasonable efforts to secure access to personally identifiable
          information in our possession, but will not be liable for any failure to
          safeguard such information, whether inadvertent or otherwise.
        </p>

        <p>
          WealthForge Securities, LLC, a Virginia limited liability company
          (“WealthForge”), which is a securities broker/dealer registered with the
          U.S. Securities and Exchange Commission and a member of Financial Industry
          Regulatory Authority, Inc., is a provider of execution and other services
          related to the equity securities transaction aspects of our business, and
          individual personnel of LexShares are registered representatives of
          WealthForge.
        </p>

        <p>
          LexShares also utilizes WealthForge, for payments processing and escrow
          services, in conjunction with their partner bank, with respect to such
          equity transactions.
        </p>

        <p>Your personal information will be shared with WealthForge.</p>

        <p>
          WealthForge is located at 6800 Paragon Place, Suite 237, Richmond, VA 23230,
          telephone: (804) 308-0431;&nbsp;
          <a href="http://www.wealthforgellc.com" target="_blank" rel="noreferrer">
            www.wealthforgellc.com
          </a>
          .
        </p>

        <p>
          Since we do not have direct control over the policies and practices of
          WealthForge, or any third-party vendor or partner, we are not responsible
          for the privacy practices or contents of their sites. In addition to
          notifying WealthForge, we will also notify the party or parties offering (or
          issuing) the investment of your interest, and may notify other users in the
          event that you have chosen to share such information.
        </p>

        <p>
          We may make your information, including non-personally identifiable
          information or personally identifiable information, available on the Site
          and visible to other users of or visitors to the Site. For instance, subject
          to certain preferences and permissions as to visibility you will be allowed
          to make, information you provide about yourself in response to
          questionnaires on the Site may be made available to others such as
          information concerning your status as an accredited investor. As such, other
          users of the Site may be able to personally identify you based on your
          information made available on the Site. As a further example, if you utilize
          third-party services to log into the Site, other users to whom you may be
          connected to through such third-party service may be able to view or follow
          information about you, including non-personally identifiable information or
          personally identifiable information, on the Site.
        </p>

        <p>
          When you register as a user on the Service, you will receive emails that
          confirm specific actions you requested, including emails to which you must
          respond to complete your registration, and notifications confirming your
          registration. Thereafter, you may receive emails when a new investment
          opportunity is offered, as well as updates concerning the progress of the
          funding of such opportunity or offering other relevant information. If you
          make an investment through the Site, we or WealthForge will also send you
          non-brokerage confirmations of the investment and occasional updates as to
          the status of that investment and the timing of distributions relating to
          that investment. We may also send you responses to emails you send us, if
          appropriate. From time to time, we will also send user surveys, requests for
          user feedback regarding user experience and Site operations or marketing
          offers from us or from us on behalf of our marketing partners. The
          completion of these surveys or requests for feedback or acceptance of any
          offer is strictly voluntary. If you do not wish to receive these offers,
          surveys or user feedback emails, please opt out in any offer email you
          receive from us.
        </p>

        <p>
          When you access the Service, we may collect technical and navigational
          information, such as computer browser type, Internet protocol address (“IP
          address”), pages visited, general statistical and tracking information, your
          computer operating system and browser information, and average time spent on
          our Sites. This information may be used, for example, to alert you to
          software compatibility issues, or it may be analyzed to improve our Web
          design and functionality. We train our employees about privacy and limit
          access to this information. To maintain security of your online session and
          prevent unauthorized users from accessing your account we use firewall
          barriers, encryption techniques and authentication procedures.
        </p>

        <p>
          We will not sell, rent, license, or disclose any of your nonpublic
          personally identifiable information to any nonaffiliated third parties,
          except as described below. If you decide to close your account, LexShares
          will continue to adhere to the privacy policies and practices as described
          in this notice.
        </p>

        <p>
          We collect information about our users in four ways: (a) directly from the
          user, (b) from our web server logs, (c) through cookies, web-beacons, and
          server logs, and (d) from third parties with whom we may have agreements to
          perform certain functions or services on our behalf.
        </p>

        <h4>Information Collected Through Users</h4>

        <p>
          Any personal information or content that you voluntarily disclose for
          posting on the Site (“User Content”) becomes available to the public. You
          can view and modify this information within the self-maintenance area of the
          Site. If you remove User Content, copies may remain viewable in cached and
          archived pages or if other users have copied or stored your User Content. We
          reserve the right, but have no obligation, to monitor the User Content you
          post on the site. We have the right to remove any information or material
          posted on the Site for any reason or no reason, including without
          limitation, if in our sole opinion, such information or material violates,
          or may violate, any applicable law, the Agreement, or to protect or defend
          our rights or property or those of any third party. We also reserve the
          right to remove information upon the request of any third party.
        </p>

        <p>
          The following information may be collected in connection with your use of
          the Site and Services:
        </p>

        <ul>
          <li>
            <p>
              Information you provide online and on applications or other forms, or
              through discussions we have with you or your representatives, such as
              your name, address, email address, telephone number, Social Security
              number, date of birth, income, employment information, investment
              experience, and any information about your personal goals or objectives;
            </p>
          </li>
          <li>
            <p>
              Information entered online that may be stored even if you do not
              complete or submit an application;
            </p>
          </li>
          <li>
            <p>
              Information about your transactions with or through us, such as your
              account balance and account history, if applicable;
            </p>
          </li>
          <li>
            <p>
              Information from third parties in order to verify your identity,
              investor status, or to prevent fraud;
            </p>
          </li>
          <li>
            <p>
              Information from third parties that you authorize to provide information
              to us;
            </p>
          </li>
          <li>
            <p>
              Information you provide to us about your external financial accounts in
              order for us to provide you special product and service features; and
            </p>
          </li>
          <li>
            <p>
              Information regarding external financial accounts will not be disclosed
              to any other entities.
            </p>
          </li>
        </ul>

        <h4>E-mail</h4>

        <p>
          If you correspond with us by email, we may retain the content of your email
          messages, your email address and our responses in our email records. We will
          retain emails that are sent from LexShares to you. Emails are subject to
          archival, monitoring or review by and/or disclosure to, someone other than
          the recipient. We retain email correspondence to build records of our
          relationship and to measure and improve our service. We may, over time,
          delete these records if permitted by law.
        </p>

        <p>
          You may receive periodic updates emails from LexShares. You may opt-out of
          the emails at any time. Instructions for unsubscribing are included in each
          email.
        </p>

        <h4>Cookies, Web-Beacons &amp; Server Logs</h4>

        <p>
          Cookies are small text files, which often include a unique but anonymous
          code, sent by a Site server to your Web browser and stored on your computer.
          They recognize you as a repeat visitor, allowing you to log in faster and
          enhance your navigation through the site and the general user experience.
          Cookies do not identify you by name as an individual or by account number.
          Cookies also track traffic patterns on our Site, helping us understand how
          you are using our Site. This information allows us to improve the security,
          content, navigation and functionality of our Site. Cookies note that your
          browser was used to visit certain sites, pages, or advertisements on a
          certain date, but are not used to collect or disseminate any personal
          information.
        </p>

        <p>
          Additionally, we use cookies on our Sites and the sites on which we
          advertise to track advertising performance and to collect aggregate data on
          Web page viewing. We may contract with third-party service providers (e.g.
          Google Analytics) to assist us in better understanding our site visitors.
          These service providers are not permitted to use the information collected
          on our behalf except to help us conduct and improve our business.
        </p>

        <p>
          You can manually disable cookies. You should review the online documentation
          of your browser or consult with the provider of your browser for
          instructions on how to disable cookies. However, disabling cookies may cause
          some parts of the Site to not function properly.
        </p>

        <p>
          A “web beacon” is typically a transparent graphic image (usually 1 pixel x 1
          pixel) that is placed on a site or in an email which allows the website to
          record the simple actions of the user opening the page that contains the
          beacon. “Server logs” can be either a single log file or several log files
          automatically created and maintained by a server of activity performed by
          the server, which can include information about any transaction you conduct
          with the server.
        </p>

        <h4>Internet Protocol (IP) Address</h4>

        <p>
          As you enter our Site, we capture and retain the IP address of the device
          you are using, such as a personal computer or a handheld device. The IP
          address does not identify you or your personal information and is used for
          security purposes only. We may use this information to monitor and prevent
          fraud, diagnose problems, and (anonymously) estimate demographic
          information.
        </p>

        <h4>Nonpublic Personally Identifiable Information</h4>

        <p>
          In the event that you request access to the confidential investment details
          and business details of an offering on the Site or the Service, the email
          address linked to your LexShares user account may be furnished to the issuer
          to facilitate communication between you.
        </p>

        <p>
          LexShares does not sell, rent, or license, or otherwise disclose nonpublic
          personally identifiable information about current or former clients to
          anyone, except in cooperation with the government and regulatory agencies
          with a lawful summons, court order, subpoena, fraud investigation, audit or
          regulatory examination, investigations of a violation of legal rights of
          LexShares or any other person, or as permitted or required by law, to
          non-affiliated third parties that help us provide services to you. For
          example, the United States Securities and Exchange Commission (“SEC”)
          requires that we verify your accredited investor status before you may
          invest in any offerings made available through our Site. Toward that end,
          LexShares employs or contracts with other third parties, companies or
          individuals, including Wealthforge, to perform certain functions or services
          on its behalf. Examples of these services from such third parties,
          companies, or individuals, may include:
        </p>

        <ul>
          <li>
            <p>ID verification;</p>
          </li>
          <li>
            <p>Accreditation/financial/credit verification;</p>
          </li>
          <li>
            <p>Electronic document signing;</p>
          </li>
          <li>
            <p>Payment storing and processing;</p>
          </li>
          <li>
            <p>Sending postal mail and email;</p>
          </li>
          <li>
            <p>Escrow services;</p>
          </li>
          <li>
            <p>Disbursement of funds;</p>
          </li>
          <li>
            <p>Analyzing data;</p>
          </li>
          <li>
            <p>Providing marketing assistance; and</p>
          </li>
          <li>
            <p>Providing customer service.</p>
          </li>
        </ul>

        <p>
          As such, these third parties, companies, or individuals may have access to
          nonpublic personally identifiable information concerning you that they
          provide to us. In turn, in order to comply with SEC requirements, we may
          have to share such information with issuers of securities or other offerings
          made available on our Site, with broker dealers, or with the WealthForge,
          depending on the types of investments you will be making through the Site.
        </p>

        <p>
          All non-affiliated third parties that accept or receive this information
          from us are obligated contractually or by law or regulation to keep this
          information confidential and to use the information only to provide the
          services we ask them to perform. These non-affiliated third parties will be
          provided with only that information necessary to perform their functions on
          our behalf, and we will not allow them to use your personally identifiable
          information for any other purpose. LexShares shall be entitled to transfer
          to a third-party information it maintains, including any personally
          identifiable information, in connection with a transaction or proceeding
          involving a transfer of some or all of the assets relating to the Site or a
          business in connection with which such information is maintained.
        </p>

        <p>
          We may store nonpublic personally identifiable information in locations
          outside of our direct control such as on servers, or databases maintained by
          hosting providers. You may decline to submit nonpublic personally
          identifiable information through LexShares, in which case we may not be able
          to provide certain services to you. You may update or correct your account
          information and email preferences at any time in your online profile.
        </p>

        <p>
          LexShares may buy or sell assets or business offerings. Customer, email, and
          visitor information is generally one of the transferred business assets in
          these types of transactions. We may also transfer such information in the
          course of corporate divestitures, mergers, or dissolution. In any such
          transfer of information, your user information would remain subject to the
          Agreement.
        </p>

        <p>
          If you use our blog on the Site, you should be aware that any personally
          identifiable information you submit there can be read, collected, or used by
          other users of this forum, and could be used to send you unsolicited
          messages. We are not responsible for the personally identifiable information
          you choose to submit in this forum.
        </p>

        <h4>Protecting Your Information</h4>

        <p>
          LexShares uses commercially reasonable physical, managerial, and technical
          safeguards to preserve the integrity and security of your personal
          information. We employ a variety of security measures, including encryption
          and authentication tools. Your personal information is stored behind
          firewalls and is only accessible by a limited number of people who are
          required to keep the information confidential.
        </p>

        <p>
          If you have a unique password (the &quot;Password&quot;) for access to non-public
          areas of the Site, you are solely responsible for all activities that occur
          in connection with your Password. Accordingly, you should take steps to
          protect the confidentiality of your Password. Notify LexShares immediately
          if you become aware of any disclosure, loss, theft or unauthorized use of
          your Password. To protect your privacy and security, maintain the secrecy of
          your unique Password and account information, and for controlling access to
          your email communications from LexShares, at all times.
        </p>

        <p>
          Despite these measures, we cannot guarantee that unauthorized persons will
          always be unable to defeat our security measures.
        </p>

        <h4>Compromise of Personal Information</h4>

        <p>
          In the event that we learn that personal information is compromised as a
          result of a breach of security, LexShares will use its best efforts to
          promptly notify those persons whose personal information has been
          compromised, in accordance with the notification procedures set forth in the
          Agreement, or as otherwise required by applicable law.
        </p>

        <h4>Where You Can View and Correct Your Information</h4>

        <p>
          We urge you to review your information regularly to ensure that it is
          correct and complete. If you believe that any of your information is
          incorrect, or if you have any questions regarding this Policy, please
          contact us at&nbsp;
          <a href="mailto:info@lexshares.com">info@lexshares.com</a>.
        </p>

        <h4>Children&apos;s Privacy</h4>

        <p>
          This Site is intended to be used by adults. It is not intended for children.
          LexShares does not knowingly collect or solicit personal information from
          anyone under the age of thirteen (13) or knowingly allow such persons to
          register. If you are under 13, please do not send any information about
          yourself to us, including your name, address, telephone number, or email
          address. No one under age 13 is allowed to provide any personal information
          to LexShares. In the event that we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible. If you
          believe that we might have any information from or about a child under 13,
          please contact us at info@lexshares.com.
        </p>

        <h4>International Users</h4>

        <p>
          The Service may be accessed by users located outside the United States. If
          you choose to use the Service from the European Union or other regions of
          the world with laws governing data collection and use that may differ from
          U.S. law, then please note that you are transferring your personal
          information outside of those regions to the United States, and that by
          providing your personal information on or through the Site then you consent
          to that transfer.
        </p>

        <h4>Links to Third-Party Sites</h4>

        <p>
          When you select a link to a third-party site, you are subject to the privacy
          and security policies of the third party and LexShares’ Policy is no longer
          in effect. LexShares does not control the privacy policies or the privacy
          practices of any third parties. We are not responsible for the practices
          employed by Sites linked to or from our Site nor the information or content
          contained therein. Your browsing and interaction on any other Site,
          including those that have a link on our Site, is subject to that Site&apos;s own
          rules and policies. Please read over those rules and policies before
          proceeding.
        </p>

        <h4>Notification Procedures</h4>

        <p>
          It is our policy to provide notifications, whether such notifications are
          required by law or are for marketing or other business related purposes, to
          you via email notice, written or hard copy notice, or through conspicuous
          posting of such notice on our Site page, as determined by LexShares in its
          sole discretion. We reserve the right to determine the form and means of
          providing notifications to you, provided that you may opt out of certain
          means of notification as described in the Agreement.
        </p>

        <h4>Opt-out Policy</h4>

        <p>
          We may at times send you email communications with marketing or promotional
          materials. If you prefer not to receive such marketing or promotional emails
          from us, you may unsubscribe completely by emailing us at:&nbsp;
          <a href="mailto:info@lexshares.com">info@lexshares.com</a>.
        </p>

        <p>
          Please note that opt-out requests may take up to forty-eight (48) hours to
          process. Please also note that at times we may need to send you email
          communications that are transactional in nature such as service or
          termination announcements or payment confirmations which are communications
          you will not be able to opt-out of.
        </p>

        <p>
          You will be able to modify the information concerning your account by
          visiting your profile page and editing any information you wish to change.
          You will be able to change your email address, password, contact
          information, or other login information by visiting your account’s settings
          page subject to our website policy. If you wish to delete your account or
          need help modifying account information, please contact us at
          info@lexshares.com. We will require up to forty-eight (48) hours to process
          such requests.
        </p>

        <p>
          You may stop or restrict the placement of our cookies, Google’s cookies and
          third-party cookies on your computer by adjusting your web browser
          preferences or a “Do Not Track” setting in your web browser. If you do so,
          you may still use our website, but this may interfere with some of its
          functionality.
        </p>

        <p>
          You may also clear cookies from your computer via your web browser settings.
          You may also wish to use a Google Analytics opt-out web browser add-on.
          Information on this option is available at: at:{" "}
          <a
            href="http://support.google.com/analytics/bin/answer.py?hl=en&amp;answer=2700409"
            target="_blank" rel="noreferrer"
          >
            http://support.google.com/analytics/bin/answer.py?hl=en&amp;answer=2700409
          </a>
        </p>

        <p>
          If you would like us to remove your personally identifiable information from
          our database, please send a request to:&nbsp;
          <a href="mailto:info@lexshares.com">info@lexshares.com</a>.
        </p>

        <p>
          We are not responsible for removing your personally identifiable information
          from the lists of any third-party services or other third party who has
          previously been provided your information in accordance with this notice.
        </p>

        <h4>Changes to this Privacy Policy</h4>

        <p>
          LexShares reserves the right to make changes to the contents of the Policy,
          modify, add, or remove portions of this notice at its’ sole discretion
          without notice or liability. You agree to review our Privacy Policy along
          with other notices and procedures posted on the Site prior to using our
          Service. Any changes or updates become effective immediately upon posting to
          this Site. Changes to the Policy will be reflected at the top of this Web
          page with a posting of a “last updated” date. Please be sure to check this
          page periodically for changes. You are bound by changes to the Policy when
          you use the site after those changes have been posted.
        </p>

        <p>
          By using the Site, you consent to the Agreement. If you do not agree with
          some, or all of the terms of the Agreement, you may not visit our Site or
          use the Service.
        </p>

        <p>
          If you have questions about the Privacy Policy or would like to suggest
          improvements, please contact us at&nbsp;
          <a href="mailto:info@lexshares.com">info@lexshares.com</a>; call us at
          877-290-4443 or mail us at 125 High Street 25th Floor, Boston, MA 02110.
        </p>

        <h4>Governing Law</h4>

        <p>
          You agree that: (i) the Service shall be deemed solely based in the State of
          New York; and (ii) the Service shall be deemed a passive one that does not
          give rise to personal jurisdiction over LexShares, either specific or
          general, in jurisdictions other than the State of New York. These Terms of
          Service shall be governed by the internal substantive laws of the State of
          New York, without respect to its conflict of laws principles.
        </p>

        <h4>Arbitration of Disputes Brought by a User</h4>

        <p>
          You agree that any claim asserted against LexShares, or its directors,
          officers or employees, or any controversy or claim arising out of or
          relating to this Agreement, the Site, the Service, this Privacy Policy, the
          Terms of Service, or other operating rules, policies, and procedures that
          may be published on the Site (including the question of whether any
          particular matter is arbitrable under this Agreement concerning the
          construction, interpretation and effect of this Agreement or any claims in
          this Agreement, or the rights and liabilities of a User under this
          Agreement), or the breach thereof, shall be settled by arbitration
          administered by the American Arbitration Association in accordance with its
          Commercial Arbitration Rules, and judgment on the award rendered by the
          arbitrator may be entered in any court having jurisdiction thereof. The
          location for any such arbitration shall be in New York City. Only
          individuals who are (1) lawyers engaged full-time in the practice and (2) on
          the AAA register of arbitrators shall be selected as an arbitrator. There
          shall be one arbitrator who shall be chosen in accordance with the rules of
          the AAA. In the event of any claim, controversy or alleged dispute between
          you and LexShares, its members or affiliates you hereby agree to attempt in
          good faith to amicably resolve any such dispure at least thirty (30) days
          before instituting any legal proceeding.
        </p>

        <h4>Resolution of Disputes Brought by LexShares</h4>

        <p>
          You agree that any claim by LexShares against a User may be brought pursuant
          to the rules of the AAA or may be adjudicated by a court of competent
          jurisdiction, either in New York, New York, USA, or where the User is
          located (either the User’s home address or the address of the User’s
          principal place of business). With respect to the foregoing, the manner of
          dispute resolution and its venue is at LexShares’ discretion and without
          reference to the choice of laws provisions of any jurisdiction. The User
          agrees to submit to the personal jurisdiction of the courts located in New
          York, New York, USA. YOU HEREBY WAIVE TRIAL BY JURY.
        </p>

        <h4>Class Action Waiver</h4>

        <p>
          Any claim must be brought in the respective party’s individual capacity, and
          not as a plaintiff or class User in any purported class, collective,
          representative, multiple plaintiff, or similar proceeding (“Class Action”).
          The parties expressly waive any ability to maintain any Class Action in any
          forum. The arbitrator shall not have authority to combine or aggregate
          similar claims or conduct any Class Action nor make an award to any person
          or entity not a party to the arbitration. Any claim that all or part of this
          Class Action Waiver is unenforceable, unconscionable, void, or voidable may
          be determined only by a court of competent jurisdiction and not by an
          arbitrator.
        </p>

        <h4>Waiver of Counterclaims</h4>

        <p>
          You agree to waive all rights to interpose any claims, deductions, setoffs
          or counterclaims of any nature (other then compulsory counterclaims) in any
          action or proceeding with respect to this Agreement or any matter arising
          therefrom or relating hereto or thereto.
        </p>
      </Content>
    </Fragment>
  )
}

export default ContentSection;
