import React, { Fragment, useState } from 'react'
import { Cell, Grid } from 'react-foundation'
import TaxDownloadCard from '../../../TaxDownloadCard'
import moment from 'moment'
import { find } from 'lodash'
import {
  Content, Heading, Tabs, TabsContainer, Tab
} from './TaxDocumentList.style';

const TaxDocumentList = ({ investments = [] }) => {
  const investmentMap = {}

  investments.forEach((investment) => {
    investment.tax_forms.forEach((form) => {
      let date = moment(form.date || form.created_at).year()

      // Hides all previous years per Jay's request
      if (date != 2024) return

      if (investmentMap[date]) {
        investmentMap[date].push(investment)
      } else {
        investmentMap[date] = [investment]
      }
    })
  })

  const getLink = (investment) => {
    let document = find(investment.tax_forms, (form) => {
      return moment(form.date || form.created_at).year() == selectedYear
    })

    if (!document) { return null }

    return `/investments/${investment.id}/documents/${document.id}`
  }

  const [selectedYear, setSelectedYear] = useState(Object.keys(investmentMap).pop())

  const selectedInvestments = investmentMap[selectedYear] || []

  return (
    <Fragment>
      <Tabs>
        <TabsContainer>
          {Object.keys(investmentMap).map((year, i) =>
            <Tab key={i} active={selectedYear == year} onClick={() => setSelectedYear(year)} className="js-tab">
              {year}
            </Tab>
          )}
        </TabsContainer>
      </Tabs>
      <Content>
        <Heading>
          {selectedYear}
        </Heading>
        <Grid className="grid-margin-x">
          {selectedInvestments.map((investment, i) =>
            <Cell key={i} large={3} medium={4} small={12} >
              <TaxDownloadCard
                title={investment.title}
                amount={investment.amount}
                date={investment.created_at}
                description={investment.card_description}
                link={getLink(investment)}
                text={getLink(investment) ? "Download" : "Unavailable"}
                spvName={investment.spv_name}
              />
            </Cell>
          )}
        </Grid>
      </Content>

    </Fragment>
  )
}

export default TaxDocumentList;