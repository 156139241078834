import React from "react";
import { Grid, Cell } from "react-foundation";
import { Container, Heading } from "./RelatedResourcesSection.style";
import WhitepaperCard from "../../whitepapers-index/WhitepaperCard/WhitepaperCard";
import Resource1 from "../../../../assets/images/barometer/lit-fin-201.png";
import Resource2 from "../../../../assets/images/barometer/lit-fin-ethics.png";
import Resource3 from "../../../../assets/images/barometer/disclosure.png";

const whitepapers = [
  {
    slug: "litigation-funding-regulations",
    title:
      "Litigation Funding 201: A Compendium of U.S. Case Law and Regulations",
    description:
      "This in-depth guide examines some of the most noteworthy court rulings, statutes, and other legal considerations that apply to non-recourse funding transactions in various jurisdictions.",
    logo_link: Resource1,
  },
  {
    slug: "ethics",
    title: "Litigation Funding Ethics: An Attorney's Primer",
    description:
      "This guide examines important advisory resources intended to help litigators properly advise clients on funding-related matters while also adhering to relevant ethics guidelines.",
    logo_link: Resource2,
  },
  {
    slug: "https://www.lexshares.com/resources/litigation-funding-disclosure-delaware",
    title: "What Litigation Funding Disclosure In Delaware May Look Like",
    description:
      "The LexShares team examines a standing order issued by Delaware's chief federal judge, which requires litigants to disclose whether their cases are being financed by third parties.",
    logo_link: Resource3,
    tag: "Article",
  },
];

const RelatedResourcesSection = () => {
  return (
    <Container>
      <Heading>Related Resources</Heading>
      <Grid className="grid-margin-x grid-margin-y grid-padding-y">
        {whitepapers.map((whitepaper, index) => (
          <Cell key={`article-${index}`} small={12} medium={4}>
            <WhitepaperCard {...whitepaper} />
          </Cell>
        ))}
      </Grid>
    </Container>
  );
};

export default RelatedResourcesSection;
