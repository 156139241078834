import styled from 'styled-components';
import * as Globals from './../../Globals';
import * as SB from './../../SimpleBanner';
import breakpoint from 'styled-components-breakpoint';


export const Section = styled.div`
  position: relative;
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  text-align: center;
`;

export const Subheading = styled(Globals.P)`
  text-align: center;
  margin-bottom: 70px;
`;

export const CardList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const BannerProp = styled(SB.Diamonds)`
  top: 60%;
  left: -15%;
  display: none;
  z-index: 0;

  ${breakpoint('large')`
    display: block;
  `}
`;
