import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import { Section, Content, Heading } from './MoreCaseStudiesSection.style';
import CaseStudiesCarousel from '../CaseStudiesCarousel';


function MoreCaseStudiesSection({caseStudies = []}) {
  return (
    <Fragment>
      <Section>
        <Content>
          <Grid>
            <Cell small={12}>
              <Heading>
                Read More Case Studies
              </Heading>
              <Grid>
                <CaseStudiesCarousel caseStudies={caseStudies} />
              </Grid>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default MoreCaseStudiesSection;
