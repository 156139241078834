import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Banner, Content, Heading, Subheading, Button, ButtonInverse } from './BannerSection.style';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading center={true}>
                Learn why LexShares is a leader in litigation finance investing.
              </Heading>
              <Subheading center={true}>
                With over 100 legal claims funded and thousands of investors, LexShares is an unparalleled litigation finance investment platform.
              </Subheading>
            </Cell>
          </Grid>
          <Grid className="grid-margin-x grid-margin-y">
            <Cell style={{textAlign: "right"}} medium={6} small={12}>
              <Button href="/invest">
                Start Investing
              </Button>
            </Cell>

            <Cell medium={6} small={12} style={{textAlign: "left"}}>
              <ButtonInverse href="/funding">
                Explore Funding
              </ButtonInverse>
            </Cell>
          </Grid>
        </Content>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
