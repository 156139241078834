import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as Globals from "./../../Globals";
import theme from "../../../config/theme";

export const Container = styled.div`
  padding: 25px;

  ${breakpoint("medium")`
    padding: 100px 100px 75px 120px;
  `};
`;

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: ${theme.colors.darkPurple};
  font-weight: 900;

  ${breakpoint("medium")`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
  `}
`;

export const SubHeading = styled(Globals.H2)`
  ${Globals.proximaFontStack}
  margin: 14px 0px 24px 0px;
  line-height: 22px !important;
  font-weight: normal;
  text-align: center;
  font-size: 17px;
  color: ${theme.colors.darkPurple};

  ${breakpoint("medium")`
    text-align: left;
    margin: 16px 0px 60px 0px;
    line-height: 36px !important;
    max-width: 900px;
    font-size: 30px;
  `}
`;

export const Description = styled(Globals.P)`
  margin: auto;
  font-size: 16px !important;
  line-height: 25px !important;
  text-align: left;
  color: ${theme.colors.darkGrey};
  margin-top: 5px;
`;

export const AboutHeading = styled(Globals.H4)`
  ${Globals.tiempoFontStack}
  text-align: center;
  color: ${theme.colors.darkPurple};
  margin: 20px 0px 14px 0px;
  font-weight: 900;
  min-height: none;

  ${breakpoint("medium")`
    margin: 40px 0px 20px 0px;
  `};

  ${breakpoint("large")`
    min-height: 76px;
  `};
`;

export const AboutDescription = styled(Globals.P)`
  text-align: center;
  color: ${theme.colors.darkPurple};
  margin: 10px 0px 40px 0px;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500;

  ${breakpoint("medium")`
    margin: 0px 24px;
  `};
`;

export const ImageContainer = styled.div`
  text-align: center;
`;
