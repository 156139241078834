import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import { BannerSection, ArticlesSection } from '../../components/press';
import theme from '../../config/theme';
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from '../../components/Footer'

const PressPage = styled.div`
  background-color: ${props => props.theme.colors.lightPurple};
`

class Press extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar theme="transparent" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor} currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <PressPage>
            <BannerSection />
            <ArticlesSection articles={this.props.articles} />
          </PressPage>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Press;
