import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack};
  text-align: center;
  font-size: 32px;
  margin: 40px 0 15px 0;
  line-height: 1.2;

  ${breakpoint('large')`
    font-size: 3.4rem;
  `}
`

export const ImportantHeader = styled.p`
  max-width: 700px;
  text-align: center;
  font-weight: 300;
  margin: 0 auto 16px auto;
`;

export const ImportantContent = styled.p`
  max-width: 700px;
  text-align: center;
  font-weight: 300;
  margin: 0 auto 40px auto;
`;

export const ButtonsContainer = styled.div`
  max-width: 450px;
  margin: 30px auto 30px auto;
`;

export const BackButton = styled(Globals.ButtonSecondaryInverse)`
  margin-top: 35px;
  width: 100%;
  height: 51px;

  ${breakpoint('medium')`
    margin-top: 20px;
  `}
`;