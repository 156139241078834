
import * as yup from 'yup'
import InputField from '../../../Form/InputField'
import InputButton from '../../../Form/InputButton'
import InputSelect from '../../../Form/InputSelect'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { Grid, Cell } from 'react-foundation';
import { isEmpty } from 'lodash'
import axios from 'axios'


const schema = yup.object().shape({
  bank_account_number: yup.string().required().matches(/^\d+$/, {message: "Bank account should only contain numbers"}).label("Bank account number"),
  bank_account_number_confirmation: yup.string().oneOf([yup.ref('bank_account_number')], "Must match bank account number").required().label("Bank account number confirmation"),
  bank_routing_number: yup.string().required().matches(/^\d{9}$/, {message: "Bank routing number must be 9 digits"}).label("Bank routing number"),
  bank_account_type: yup.string().required().label("Bank account type")
})

function IndividualForm({csrfToken, investor}) {
  const formRef = useRef(null)

  const defaultValues = {
    bank_account_number: investor.bank_account_number || '',
    bank_account_number_confirmation: investor.bank_account_number_confirmation || '',
    bank_routing_number: investor.bank_routing_number || '',
    bank_account_type: investor.bank_account_type || ''
  }

  const handleSubmit = ({bank_account_number, bank_account_number_confirmation, bank_routing_number, bank_account_type}) => {
    const payload = {
      investor: {
        bank_account_number,
        bank_account_number_confirmation,
        bank_routing_number,
        bank_account_type
      },
      authenticity_token: csrfToken
    }

    axios.put(`/investors/${investor.id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  const typeOptions = [
    {label: "CHECKING", value: "CHECKING"},
    {label: "SAVINGS", value: "SAVINGS"}
  ]

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, setFieldValue, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-personal-funding">
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Bank account number"
                  name="bank_account_number"
                  changeHandler={handleChange}
                  error={errors.bank_account_number}
                  value={values.bank_account_number}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Bank account number confirmation"
                  name="bank_account_number_confirmation"
                  changeHandler={handleChange}
                  error={errors.bank_account_number_confirmation}
                  value={values.bank_account_number_confirmation}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Bank routing number"
                  name="bank_routing_number"
                  changeHandler={handleChange}
                  error={errors.bank_routing_number}
                  value={values.bank_routing_number}
                />
              </Cell>
              <Cell medium={6} className="js-account-type-cell">
                <InputSelect
                  label="Bank account type"
                  name="bank_account_type"
                  options={typeOptions}
                  error={errors.bank_account_type}
                  value={typeOptions.find(option => option.value === values.bank_account_type)}
                  changeHandler={(option) => setFieldValue("bank_account_type", option.value)}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-y">
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default IndividualForm
