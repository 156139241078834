import React, { Fragment } from 'react';
import ModalPop from '../../ModalPop/ModalPop';
import { Header, Icon, Message } from './SlipOpinonConfirmationPop.style';
import Check from '../../../../assets/images/icons/check.png'

const SlipOpinionConfirmationPop = ({setShowPop}) => {
  const hidePop = () => {
    setShowPop(false)
  }
  const content =
      <Fragment>
        <Header>Thank you for subscribing.</Header>
        <Message>You will receive a confirmation email shortly. We hope The Slip Opinion can be a valuable resource, as it is for thousands of fellow attorneys.</Message>
        <Icon>
          <img src={Check}/>
        </Icon>
      </Fragment>

  return(
    <ModalPop
      popVisible={true}
      content={content}
      hideHandler={hidePop}
      size="large"
    />
  )
}

export default SlipOpinionConfirmationPop;