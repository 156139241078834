import * as yup from 'yup'
import InputField from '../../Form/InputField'
import InputButton from '../../Form/InputButton'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { Grid, Cell } from 'react-foundation';
import { isEmpty } from 'lodash'
import axios from 'axios'

const schema = yup.object().shape({
  employer: yup.string().label("Employer"),
  position: yup.string().label("Position"),
  linkedin_url: yup.string().matches(/^https?:\/\/www.linkedin.com/, {message: "The format must be http://www.linkedin.com/profile"}).label("Linkedin Url")
})

function InvestorSummaryForm({csrfToken, investor}) {

  const formRef = useRef(null)

  const defaultValues = {
    employer: investor.employer || '',
    position: investor.position || '',
    linkedin_url: investor.linkedin_url || ''
  }

  const handleSubmit = ({employer, position, linkedin_url}) => {
    const payload = {
      employer,
      position,
      linkedin_url,
      authenticity_token: csrfToken
    }

    axios.put(`/investor_summaries/${investor.investor_summary_id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-summary">
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Employer"
                  name="employer"
                  changeHandler={handleChange}
                  error={errors.employer}
                  value={values.employer}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Position"
                  name="position"
                  changeHandler={handleChange}
                  error={errors.position}
                  value={values.position}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="LinkedIn profile URL"
                  name="linkedin_url"
                  changeHandler={handleChange}
                  error={errors.linkedin_url}
                  value={values.linkedin_url}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-y">
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default InvestorSummaryForm
