import React, {Fragment, useState, useEffect} from 'react'
import {Cell, Grid} from 'react-foundation'
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  TitleBar,
  Logo,
  Title,
  DateStamp,
  Container,
  DealStatus,
  DealInvested,
  DealInfo,
  ProgressContainer,
  CaseType,
  Parties,
  Description,
  Button,
  InfoContainer
} from './CaseWidget.style'

/**
 * Take in the ID of a case and returns the case card. Provides button to 
 * view case details or request access if case is active.
 */
function CaseWidget({caseId, csrfToken, initAblyWatcher, setAlertBar}) {
  let [courtCase, setCourtCase] = useState(null)
  let [accessRequested, setAccessRequested] = useState(false)

  useEffect(() => {
    axios.get(`/widget/cases/${caseId}.json`).then(res => {
      setCourtCase(res.data)
    })
  }, [])

  const requestAccess = () => {
    const payload = {
      court_case_id: caseId,
      authenticity_token: csrfToken
    }
    axios.post('/private_case_access_requests.json', payload)
      .then(() => {
        setAccessRequested(true)
        initAblyWatcher()
        setAlertBar('You will be notified as soon as your access request is granted.')
      })
    return false
  }

  return (
    <Widget
      className="js-widget js-case-widget"
      active={courtCase && (courtCase.is_currently_investable || courtCase.viewable)}
    >
      <Content loading={courtCase ? "false" : "true"}>
        { courtCase &&
          <Fragment>
            <TitleBar>
              <Logo />
              { courtCase.amount_invested_in_case
                ? 
                  <Title>You invested {courtCase.amount_invested_in_case} into this offering.</Title>
                :
                  <Title>LexShares Investment Offering</Title>
              }
              <DateStamp>{courtCase.display_date_stamp}</DateStamp>
            </TitleBar>
            <Container>
              <Grid className="grid-margin-x align-middle">
                <Cell small={12} medium={5} large={4}>
                  <ProgressContainer>
                    <CircularProgressbarWithChildren value={courtCase.progress} strokeWidth={6} styles={buildStyles({
                      rotation: 0,
                      strokeLinecap: 'butt',
                      pathTransitionDuration: 0.5,
                      pathColor: "#8247BA",
                      trailColor: '#533572'
                    })}>
                      <DealStatus className="js-deal-percent">
                        {courtCase.viewable ? 'Open Soon' : `${courtCase.progress}% Funded`}
                      </DealStatus>
                      <DealInvested>
                        {courtCase.amount_funded_text}
                      </DealInvested>
                      <DealInfo>
                        {courtCase.progress_text}
                      </DealInfo>
                    </CircularProgressbarWithChildren>
                  </ProgressContainer>
                </Cell>
                <Cell small={12} medium={7} large={8}>
                  <InfoContainer>
                    <CaseType>{courtCase.case_type}</CaseType>
                    <Parties dangerouslySetInnerHTML={{__html: `${courtCase.plaintiff_display_name} vs. ${courtCase.defendant_display_name}`}} />
                    <Description dangerouslySetInnerHTML={{__html: courtCase.case_description}} />
                    { courtCase.allowed_access &&
                      <Button href={`/cases/${courtCase.id}`} target="_blank">
                        View case details
                      </Button> 
                    }
                    { ((accessRequested || courtCase.user_pending_access) && (courtCase.is_currently_investable || courtCase.viewable)) &&
                      <Button pending={true}>
                        Request Pending
                      </Button>
                    }
                    { (!accessRequested && !courtCase.allowed_access && !courtCase.user_pending_access && (courtCase.is_currently_investable || courtCase.viewable)) &&
                      <Button
                        onClick={courtCase.onboarded ? requestAccess : {}}
                        href={courtCase.onboarded ? null : '/investors/new'}
                      >
                        Request Access
                      </Button>
                    }
                  </InfoContainer>
                </Cell>
              </Grid>
            </Container>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default CaseWidget
