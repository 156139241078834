import React from 'react';
import { Container, DiamondBackground, Image, ImageContainer } from './WhitepaperBannerImage.style';

const WhitepaperBannerImage = ({whitepaper}) => {
  return (
    <Container>
      <DiamondBackground />
      <ImageContainer>
        <Image src={whitepaper.banner_image_link} />
      </ImageContainer>
    </Container>
  )
}

export default WhitepaperBannerImage;