import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../../SimpleBanner';
import DiamondBackground from '../../../../../assets/images/shared/banner-prop.svg';

export const Content =  styled.div`
  position: relative;
  height: 100%;
  padding: 0;

  ${breakpoint('large')`
    margin-top: 125px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1235px;
  `}
`

export const Heading = styled.div`
  border-bottom: 1px solid #2A2232;
`

export const CloseButton = styled.div`
  font-size: 70px;
  width: 20px;
  color: #FFFFFF;
  font-family: TiemposHeadlineWeb;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
  line-height: 1;
`

export const BannerProp = styled(SB.Diamonds)`
  height: 300px;
  z-index: 100;
  top: 40%;
  position: fixed;
  width: 460px;
  right: 0;
  display: none;
  background: url(${DiamondBackground}) no-repeat center center/cover;
  ${breakpoint('large')` {
    display: block;
  `}
`;

export const HeadingCloseButton = styled.div`
  width: 20px;
  margin-right: 30px;
  margin-top: 8px;
  float: right;
  cursor: pointer;
`

export const Logo =  styled.div`
  height: 40px;
  width: 165px;
  margin: 20px !important;
  img {
    width: 100%;
  }
`

export const LinkContainer = styled.div`
  padding: 10% 2%;
  position: relative;
  z-index: 1000;
  position: fixed;
  left: 20px;
  top: 50%;
  width: calc(100% - 40px);
  transform: translateY(-50%);
  ${breakpoint('large')`
    padding: 10% 7%;
    position: unset;
    width: unset;
    transform: unset;
  `}
`

export const Link = styled.a`
  color: white;
  font-size: 32px;
  line-height: 44px;
  font-family: TiemposHeadlineWeb;
  padding-bottom: 44px;
  border-bottom: 2px solid #282331;
  display: block;
  margin-bottom: 44px;
  text-align: left;
  position: relative;
  z-index: 1000;
  &:hover, &:active, &:focus {
    color: white;
  }
  ${breakpoint('large')`
    font-size: 36px;
    margin-top: 44px;
    border-bottom: 2px solid white;
  `}
`

export const Arrow = styled.div`
  float: right !important;
`
