import styled from "styled-components";
import * as Globals from "../../Globals";
import theme from "../../../config/theme";
import breakpoint from "styled-components-breakpoint";

export const Container = styled.div`
  margin: 16px 0px 0px 0px;
  text-align: center;

  ${breakpoint("medium")`
    text-align: inherit;
  `}
`;

export const Header = styled(Globals.H1)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  color: ${theme.colors.white};
  margin-top: 7px;
  line-height: 29px;
  text-align: center;

  ${breakpoint("medium")`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
    margin-top: 31px;
  `}
`;

export const Subheading = styled(Globals.H6)`
  ${Globals.proximaFontStack}
  margin-top: 14px;
  line-height: 22px !important;
  font-weight: normal;
  color: ${theme.colors.white};
  text-align: center;
  font-size: 17px;

  ${breakpoint("medium")`
    text-align: left;
    margin-top: 16px;
    line-height: 36px !important;
  `}
`;

export const DownloadButton = styled.button`
  text-align: center;
  text-transform: uppercase;
  width: 50%;
  background: ${theme.colors.purple};
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-weight: 800;
  line-height: 13px;
  letter-spacing: 2px;
  ${Globals.proximaFontStack};
  color: ${theme.colors.white};
  cursor: pointer;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid ${theme.colors.purple} !important;
  margin-top: 20px;

  ${breakpoint("medium")`
    border-radius: 3px !important;
    max-width: 210px;
    width: 40%;
  `}

  ${breakpoint("large")`
    display: inline-block;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  `}

  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.backgroundPurple};
  }

  &:focus {
    color: ${theme.colors.white};
  }
`;
