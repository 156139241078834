import styled from 'styled-components';
import * as Globals from '../../Globals';
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  margin: 0px 0px;

  ${breakpoint('medium')`
    margin: 32px 0px;
  `}
`;

export const PublisherLogo = styled.img`
  height: 40px;
`;

export const PublisherText = styled.p`
  ${Globals.proximaFontStack}
  font-size: 16px;
  line-height: 25px;
  color: ${theme.colors.purpleGrey};
  margin-top: 14px;
`;

export const PublisherLink = styled.a`
`;