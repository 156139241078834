import React, { Fragment } from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import Navbar from '../../components/attorney-resource-center/Navbar/Navbar'
import Footer from '../../components/Footer'
import SlipOpinionInfo from '../../components/slip-opinion/SlipOpinionInfo/SlipOpinionInfo';
import SlipOpinionCta from '../../components/slip-opinion/SlipOpinionCta/SlipOpinionCta';

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`;

const Container = styled.div`
  margin: 33px 20px;

  ${breakpoint('medium')`
    margin: 40px 0px;
    padding: 80px 0px 90px 70px;
  `}

  ${breakpoint('large')`
    margin: 80px 0px;
    padding: 120px 0px 130px 80px;
  `}
`;

class SlipOpinion extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Page>
            <Navbar attorneyResourceCenterPath={this.props.attorneyResourceCenterPath} caseStudiesPath={this.props.caseStudiesPath} fundingAttorneysPath={this.props.fundingAttorneysPath} fundingPlaintiffsPath={this.props.fundingPlaintiffsPath} fundingGeneralCounselPath={this.props.fundingGeneralCounselPath} fundingCasePath={this.props.fundingCasePath} rootPath={this.props.rootPath} fundingInsightsPath={this.props.fundingInsightsPath} regulationsTrendPath={this.props.regulationsTrendPath} slipOpinionPath={this.props.slipOpinionPath} hideSlipOpinionCta={true} whitepapersPath={this.props.whitepapersPath} />
            <Container>
              <SlipOpinionCta attorneyResourceCenterPath={this.props.attorneyResourceCenterPath} slipOpinionPath={this.props.slipOpinionPath} csrfToken={this.props.csrfToken} slipOpinionRequestsPath={this.props.slipOpinionRequestsPath} />
              <SlipOpinionInfo />
            </Container>
          </Page>
          <Footer bgColor="lighterPurple"/>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default SlipOpinion;
