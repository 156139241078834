import styled from 'styled-components'
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.Widget)`
  min-height: 706px;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const ChartContainer = styled.div`
  height: 400px;
`

export const Heading = styled(Globals.WidgetHeading)`
`

export const DateRange = styled(Globals.WidgetDateRange)`
`

export const Text = styled(Globals.WidgetText)`
`

export const Disclosure = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 30px;
`
