import styled from 'styled-components'
import * as Globals from '../../../Globals'

export const ChartContainer = styled.div`
  height: 250px;
  margin-top: 30px;
`

export const Widget = styled(Globals.Widget)`
  padding: 21px 31px;
  background-color: #1E1924;
  cursor: pointer;
`

export const Metrics = styled.div `
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export const Metric = styled.div`
  width: ${props => props.single ? "auto" : "50%" };
`

export const Label = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #F8F7FA;
  text-align: center;
`

export const Value = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  color: #F8F7FA;
  text-align: center;
`

export const Heading = styled.p`
  color: white;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`

export const Panel = styled(Globals.Description)`
  max-height: ${props => props.show ? 'unset' : '0px'};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  color: white !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  margin-right: 25px;
  margin-top: 10px;
  p, span, li {
    color: white !important;
    font-size: 14px;
  }
  a, a:visited, a:hover {
    color: ${props => props.theme.colors.purple} !important;
    font-size: 14px;
  }
`
