import styled from "styled-components";
import * as Globals from "../../Globals";
import theme from "../../../config/theme";
import breakpoint from "styled-components-breakpoint";

export const Container = styled.div`
  background-color: rgb(248, 247, 250);
`;

export const ContentContainer = styled.div`
  padding: 20px;

  ${breakpoint("medium")`
    padding: 40px;
  `}

  ${breakpoint("large")`
    padding: 120px 0px 130px 80px;
  `}
`;

export const DescriptionContainer = styled.div`
  margin: 0px;

  ${breakpoint("medium")`
    margin: 0px 60px 0px 0px;
  `}
`;

export const Heading = styled(Globals.H6)`
  font-size: 19px;
  line-height: 32px !important;
  font-weight: bold !important;
  color: ${theme.colors.backdropPurple};
  margin-top: 20px;
  text-align: center;

  ${breakpoint("large")`
    text-align: left;
  `}
`;

export const Description = styled(Globals.P)`
  margin-top: 8px;
  font-size: 16px !important;
  line-height: 27px !important;
  color: ${theme.colors.backdropPurple};

  ${breakpoint("medium")`
    font-size: 24px !important;
    line-height: 36px !important;
  `}
`;

export const CheckmarkSection = styled.div`
  padding: 30px;

  ${breakpoint("medium")`
    padding: 30px 0px;
  `}

  ${breakpoint("large")`
    padding: 60px 0px;
  `}
`;

export const CheckmarkHeading = styled(Globals.H6)`
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 24px !important;
  color: ${theme.colors.backdropPurple};
  margin-top: 15px;
`;

export const SectionHeading = styled(Globals.H6)`
  ${Globals.tiempoFontStack};
  font-size: 32px !important;
  line-height: 32px !important;
  font-weight: bold !important;
  color: ${theme.colors.backdropPurple};
  margin-bottom: 20px;
`;
