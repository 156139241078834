import React, { Fragment } from "react";
import breakpoint from "styled-components-breakpoint";
import { Cell, Grid } from "react-foundation";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import Navbar from "../../components/attorney-resource-center/Navbar/Navbar";
import Sidenav from "../../components/attorney-resource-center/Sidenav/Sidenav";
import { CaseStudiesSection } from "../../components/case-studies";

import Footer from "../../components/Footer";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const Container = styled.div`
  margin-top: 0px;

  ${breakpoint("medium")`
    margin-top: 80px;
  `}
`;

const SidenavContainer = styled.div`
  display: none;

  ${breakpoint("medium")`
    display: block;
  `}
`;

class CaseStudiesIndex extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar
            attorneyResourceCenterPath={this.props.attorneyResourceCenterPath}
            caseStudiesPath={this.props.caseStudiesPath}
            fundingAttorneysPath={this.props.fundingAttorneysPath}
            fundingPlaintiffsPath={this.props.fundingPlaintiffsPath}
            fundingGeneralCounselPath={this.props.fundingGeneralCounselPath}
            fundingCasePath={this.props.fundingCasePath}
            rootPath={this.props.rootPath}
            fundingInsightsPath={this.props.fundingInsightsPath}
            regulationsTrendPath={this.props.regulationsTrendPath}
            slipOpinionPath={this.props.slipOpinionPath}
          />
          <Page>
            <Container>
              <Grid>
                <Cell small={12} medium={4} large={2}>
                  <SidenavContainer>
                    <Sidenav
                      caseStudiesPath={this.props.caseStudiesPath}
                      fundingAttorneysPath={this.props.fundingAttorneysPath}
                      fundingPlaintiffsPath={this.props.fundingPlaintiffsPath}
                      fundingGeneralCounselPath={
                        this.props.fundingGeneralCounselPath
                      }
                      fundingCasePath={this.props.fundingCasePath}
                      rootPath={this.props.rootPath}
                      fundingInsightsPath={this.props.fundingInsightsPath}
                      regulationsTrendPath={this.props.regulationsTrendPath}
                      attorneyResourceCenterPath={
                        this.props.attorneyResourceCenterPath
                      }
                      whitepapersPath={this.props.whitepapersPath}
                    />
                  </SidenavContainer>
                </Cell>
                <Cell small={12} medium={8} large={10}>
                  <CaseStudiesSection caseStudies={this.props.caseStudies} />
                </Cell>
              </Grid>
            </Container>
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default CaseStudiesIndex;
