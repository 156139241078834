import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Overview, Photo, Content, Name, Title, Label, Value,
  Description, Breadcrumb, AboutLink, ContentBody, Info
} from './OverviewSection.style';

function OverviewSection({employee, profile_image}) {
  return (
    <Fragment>
      <Overview>
        <Content>
          <Grid>
            <Cell large={4}>

            </Cell>
            <Cell large={8}>
              <Breadcrumb>
                <AboutLink href="/about">
                  Our Team
                </AboutLink>
                {' '} /  { employee.full_name }
              </Breadcrumb>
              <div className="hide-for-large">
                <Name>
                  { employee.full_name }
                </Name>
                <Title>
                  { employee.title }
                </Title>
              </div>
            </Cell>
          </Grid>
          <Grid>
            <Cell large={4}>
              <Photo>
                <img src={profile_image} />
              </Photo>
            </Cell>
            <Cell large={8}>
              <ContentBody>
                <div className="show-for-large">
                  <Name>
                    { employee.full_name }
                  </Name>
                  <Title>
                    { employee.title }
                  </Title>
                </div>
                <Info>
                  <Grid>
                    <Cell medium={6}>
                      <Label>
                        Email
                      </Label>
                      <Value href={`mailto:${employee.email}`}>
                        { employee.email }
                      </Value>
                    </Cell>
                    { employee.phone && <Cell medium={6}>
                      <Label>
                        Phone
                      </Label>
                      <Value href={`tel:${employee.phone}`}>
                        { employee.phone }
                      </Value>
                    </Cell> }
                  </Grid>
                </Info>

                <Description>
                  { employee.bio }
                </Description>
                <Description>
                  { employee.bio_2 }
                </Description>
                <Description>
                  { employee.bio_3 }
                </Description>
                <Description>
                  { employee.bio_4 }
                </Description>
              </ContentBody>
            </Cell>
          </Grid>
        </Content>
      </Overview>
    </Fragment>
  )
}

export default OverviewSection;
