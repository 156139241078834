import React from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, Heading, Subheading, Step, StepNumber, StepTitle, StepDescription, Link
} from './ProcessSection.style';

function ProcessSection({background}) {
  return (
    <Section style={{backgroundColor: `${background}`}}>
      <Content>
        <Grid>
          <Cell small={12}>
            <Heading>
              The LexShares process
            </Heading>
            <Subheading>
              Plaintiffs are eligible to <Link href="/funding/case">submit claims</Link> directly for review.
            </Subheading>
          </Cell>
        </Grid>
        <Grid>
          <Cell large={3} medium={6} small={12}>
            <Step>
              <StepNumber>
                1
              </StepNumber>
              <StepTitle>
                Apply
              </StepTitle>
              <StepDescription>
                Submit your application for funding and execute NDA.
              </StepDescription>
            </Step>
          </Cell>
          <Cell large={3} medium={6} small={12}>
            <Step>
              <StepNumber>
                2
              </StepNumber>
              <StepTitle>
                Review
              </StepTitle>
              <StepDescription>
                LexShares&apos; team reviews your case.
              </StepDescription>
            </Step>
          </Cell>
          <Cell large={3} medium={6} small={12}>
            <Step>
              <StepNumber>
                3
              </StepNumber>
              <StepTitle>
                Approve
              </StepTitle>
              <StepDescription>
                LexShares sends a contract detailing terms of funding.
              </StepDescription>
            </Step>
          </Cell>
          <Cell large={3} medium={6} small={12}>
            <Step>
              <StepNumber>
                4
              </StepNumber>
              <StepTitle>
                Fund
              </StepTitle>
              <StepDescription>
                Contract executed and funding deployed.
              </StepDescription>
            </Step>
          </Cell>
        </Grid>
      </Content>
    </Section>
  )
}

export default ProcessSection;
