import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals';

export const Logo = styled.img`
  width: 120px;

  margin-bottom: 50px;
  ${breakpoint('medium')`
    margin-top: 40px;
  `}
`

export const Date = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #282331;
  margin-bottom: 20px;
`

export const Title = styled(Globals.H5)`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 120%;
  color: #9450D4;
  margin-bottom: 20px;
`

export const Preview = styled(Globals.Body)`
  margin-bottom: 32px;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #282331;
`

export const Button = styled(Globals.ButtonInverse)``

export const Article = styled.div `
  margin-top: 70px;
`
