import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from './../../Globals';
import * as SB from './../../SimpleBanner';
import Hero from '../../../../assets/images/cases/hero.png';

export const Banner = styled(SB.Banner)`
  background: url(${Hero}) no-repeat center center/cover;

  ${breakpoint('large')`
    padding: 0;
  `}
`;


export const Content = styled(SB.Content)`
  padding: 13% 3%;

  ${breakpoint('large')`
    padding: 13% 3%;
    max-width: 1025px;
  `}
`;

export const BannerProp = styled(SB.Diamonds)`
`;

export const Heading = styled(SB.Heading)`
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;

  ${breakpoint('medium')`
    text-align: center;
  `}
`;

export const Subheading = styled(SB.Subheading)`
  text-align: center;


  ${breakpoint('medium')`
    text-align: center;
  `}

  margin: 10px auto 30px auto;
  max-width: 710px;
`;

export const Button = styled(Globals.ButtonLink)`
  width: 100%;

  ${breakpoint('medium')`
    width: 208px;
  `}
`

export const ButtonInverse = styled(Button)`
  background: transparent !important;
  color: #FFFFFF;
  border: 2px solid white;
  padding: 13px 24px 13px 24px;
  &:hover {
    background: transparent !important;
    color: #FFFFFF !important;
    opacity: .7;
  }
`
