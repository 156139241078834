import styled from 'styled-components'
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.Widget)`
  overflow: auto;
  min-height: 350px;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const Heading = styled(Globals.WidgetHeading)`
`

export const DateRange = styled(Globals.WidgetDateRange)`
`

export const Text = styled(Globals.WidgetText)`
`
