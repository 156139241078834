import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation'
import { Banner, Button, Content, DiamondBackground, Header, Subheading } from './ConfirmationSection.style'

const ConfirmationSection = (props) => {
  return(
    <Fragment>
      <Banner>
        <DiamondBackground />
        <Content>
          <Grid className="grid-x grid-margin-x">
            <Cell large={12}>
              <Header>
                {props.header}
              </Header>
              <Subheading>
                {props.subheading}
              </Subheading>
            </Cell>
          </Grid>
          <Button href={props.buttonPath}>
            {props.buttonText}
          </Button>
        </Content>
      </Banner>
    </Fragment>
  )
}

export default ConfirmationSection;