import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { H2, Body } from '../../Globals';

export const Team = styled.div``

export const Content = styled.div`
  max-width: 100%;
  padding: 10%;
  ${breakpoint('medium')`
    padding: 5%;
  `}
`

export const Heading = styled(H2)`
  text-align: center;
  ${breakpoint('medium')`
    font-weight: 500;
  `}
`

export const Subheading = styled(Body)`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 150%;
  font-size: 18px;
  color: #534566;
`

export const Department = styled.div`
  margin: 0 auto;
  max-width: 950px;
`

export const VerticalSpacer = styled.div`
  margin-bottom: 50px;
`
