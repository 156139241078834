import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../Globals';

export const Banner = styled.div`
  background-color: ${props => props.theme.colors.backdropPurple};
  padding: 15% 0 10%;
  color: white;
  position: relative;
  overflow: hidden;

  ${breakpoint('medium')`
    padding: 8% 0 4%;
  `}
`;

export const Content = styled.div`
  padding: 12%;
  max-width: 1025px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  text-align: center;
  ${breakpoint('medium')`
    padding: 9% 3%;
  `}
`;

export const Diamonds = styled.div`
  right: -70px;
  top: 30%;
  position: absolute;
  pointer-events: none;
  opacity: 0.9;
  img {
    height: 200px;
    ${breakpoint('medium')`
      height: unset;
    `}
  }
  ${breakpoint('medium')`
    right: 0px;
  `}
`;

export const Heading = styled(Globals.H1)`
  text-align: ${props => props.center ? 'center' : 'left'};
`;

export const Subheading = styled(Globals.P)`
  text-align: ${props => props.center ? 'center' : 'left'};
  -webkit-font-smoothing: antialiased;
`;

export const P = styled(Globals.P)`
  text-align: ${props => props.center ? 'center' : 'left'};
`;
