import React, {useEffect, useState} from 'react'
import {Cell, Grid} from 'react-foundation'
import axios from '../../../Axios'

import {
  Widget,
  Heading,
  Title,
  Amount,
  Date,
  Button
} from './RecentInvestmentsWidget.style'

function RecentInvestmentsWidget({viewClickHandler}) {
  let [data, setData] = useState([]);

  const mapValues = (data) => {
    let array = []

    if(data.active.length > 0) {
      data.active.map((investment) => (
        array.push(
          {
            "heading": investment.heading,
            "amount": investment.amount,
            "date": investment.full_investment_date,
            "case_id": investment.case_id
          }
        )
      ))
    }

    setData(array.slice(0, 5))
  }

  useEffect(() => {
    axios.get(`/widget/recent-investments.json`).then(res => {
      mapValues(res.data)
    })
  }, [])

  if(data.length < 1) {
    return null;
  }

  return (
    <Widget className="js-recent-investmenents-widget">
      <Heading>Your Recent Investments</Heading>
      { data.map((investment, i) => (
        <Grid className="grid-margin-x" key={i}>
          <Cell small={8}>
            <Title href={`/cases/${investment.case_id}`} target="_blank">{investment.heading}</Title>
            <Date>Invested on {investment.date}</Date>
          </Cell>
          <Cell small={4}>
            <Amount>{investment.amount}</Amount>
          </Cell>
        </Grid>
       ))}
       <Button onClick={() => viewClickHandler(null, null, 'investment portfolio')}>View all Investments</Button>
    </Widget>
  )
}

export default RecentInvestmentsWidget
