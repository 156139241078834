import React, { Fragment } from 'react';
import ModalPop from '../../ModalPop/ModalPop';
import { Header, Message } from './WhitepaperDownloadedPop.style';

const WhitepaperDownloadedPop = ({setShowPop}) => {
  const hidePop = () => {
    setShowPop(false)
  }
  const content =
      <Fragment>
        <Header>Thank you for downloading this LexShares resource.</Header>
        <Message>Please check your inbox — the requested whitepaper will be emailed to you shortly.</Message>
      </Fragment>

  return(
    <ModalPop
      popVisible={true}
      content={content}
      hideHandler={hidePop}
      size="large"
    />
  )
}

export default WhitepaperDownloadedPop;