import React, { Fragment } from 'react'
import {Cell, Grid} from 'react-foundation'
import moment from 'moment'
import debounce from 'lodash.debounce'
import axios from '../../../Axios'
import ProfileWidget from './ProfileWidget'
import CaseWidget from './CaseWidget'
import PressWidget from './PressWidget'
import BlogWidget from './BlogWidget'
import RecentInvestmentsWidget from '../../SharedWidgets/RecentInvestmentsWidget'
import PortfolioWidget from '../../SharedWidgets/PortfolioWidget'
import AdvisorWidget from '../../SharedWidgets/AdvisorWidget'
import FaqWidget from '../../SharedWidgets/FaqWidget'
import PrivateCaseAccessWatcher from '../../../../components/PrivateCaseAccessWatcher'
import Mixpanel from '../../../Mixpanel'
import {
  LoadingIcon
} from './Feed.style'

/**
 * Long scroll feed that pulls in a month worth of activity updates.
 */
class Feed extends React.Component {
  constructor(props) {
    super(props)
    this.fetchData = this.fetchData.bind(this)
    this.sideWidgets = this.sideWidgets.bind(this)
    this.initAblyWatcher = this.initAblyWatcher.bind(this)
    this.ghostUrl = 'https://lexshares.ghost.io'
    this.ghostKey = '22612699c4909245e7fbd02383'
    this.state = {
      widgets: [],
      error: null,
      hasMore: true,
      isLoading: false,
      currentDate: moment().format('YYYY-MM-DD'),
      startAblyWatch: false,
      scrollCount: 0
    }
  }

  /**
   * Pulls in a month worth of updates and generates activity array, which
   * contains IDs of the individual activity updates. Corresponding widgets
   * are created and pushed on the widgets array.
   */
  fetchData() {
    this.setState({isLoading: true})
    const startDate = moment(this.state.currentDate).subtract(3, 'months').format('YYYY-MM-DD')
    const endDate = moment(this.state.currentDate).format('YYYY-MM-DD')
    axios.get('/widget/feed.json', {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    }).then(res => {
      let widgets = []
      let widgetComponents = []
      const types = ['articles', 'court_cases', 'blog_posts']
      types.forEach((type) => {
        if (res.data[type]) {
          res.data[type].forEach((item) => {
            widgets.push({
              type: type,
              id: item.id,
              sort_by_date_stamp: item.sort_by_date_stamp
            })
          })
        }
      })
      widgets.sort((a, b) => {
        return new Date(b.sort_by_date_stamp) - new Date(a.sort_by_date_stamp)
      })
      widgets.forEach((widget) => {
        if (widget.type == 'articles') {
          widgetComponents.push(
            <PressWidget
              key={`article-widget-${widget.id}`}
              articleId={widget.id}
            />
          )
        }
        if (widget.type == 'court_cases') {
          widgetComponents.push(
            <CaseWidget
              key={`case-widget-${widget.id}`}
              caseId={widget.id}
              csrfToken={this.props.csrfToken}
              initAblyWatcher={this.initAblyWatcher}
              setAlertBar={this.props.setAlertBar}
            />
          )
        }
        if (widget.type == 'blog_posts') {
          widgetComponents.push(
            <BlogWidget
              key={`blog-widget-${Math.random()}`}
              slugName={widget.id}
              ghostUrl={this.ghostUrl}
              ghostKey={this.ghostKey}
            />
          )
        }
      })
      let currentWidgetComponents = this.state.widgets
      currentWidgetComponents.push(...widgetComponents)
      this.setState({
        widgets: currentWidgetComponents,
        isLoading: false,
        currentDate: startDate,
        hasMore: widgetComponents.length == 0 ? false : true
      })
    })

    // Tracks click via Mixpanel only if scrolled.
    let newScrollCount = this.state.scrollCount + 1
    if (this.state.scrollCount > 0) {
      Mixpanel.track('Loaded more activity', {
        scrollCount: this.state.scrollCount
      })
    }
    this.setState({scrollCount: newScrollCount})
  }

  /**
   *  Allows case component to toggle state of Ably realtime notificaiton
   *  watcher so we can begin listening in the event that investor requests
   *  case access from the dashboard.
   */
  initAblyWatcher() {
    this.setState({startAblyWatch: true})
  }

  /**
   * Fetches initial batch of data and sets a listener for future
   * scroll events. Data fetch is initiated whenever a user reaches
   * the bottom of the last activity widget.
   */
  componentDidMount() {
    window.onscroll = debounce(() => {
      const {
        fetchData,
        state: {error, isLoading, hasMore}
      } = this

      if (error || isLoading || !hasMore) return

      const widgets = document.querySelectorAll('.js-widget')
      const lastWidget = widgets[widgets.length- 1]

      if (lastWidget == undefined) return

      if (
        window.innerHeight + document.documentElement.scrollTop
        > (lastWidget.offsetTop + 300)
      ) {
        fetchData()
      }
    }, 300)

    this.fetchData()
  }

  /**
   * Sets the side panel widgets in the correct order depending on
   * previous investor activity.
   */
  sideWidgets() {
    let widgets = []

    if (this.props.investmentCount == 0) {    
      widgets = [
        <FaqWidget key="faq-widget"/>
      ]
    }
    else if (this.props.investmentCount == 1) {
      widgets = [
        <RecentInvestmentsWidget viewClickHandler={this.props.viewClickHandler} key="recent-investments-widget" />,
        <PortfolioWidget viewClickHandler={this.props.viewClickHandler} key="portfolio-widget" />,
        <FaqWidget key="faq-widget" />
      ]
    }
    else if (this.props.investmentCount > 1) {
      widgets = [
        <PortfolioWidget viewClickHandler={this.props.viewClickHandler} key="portfolio-widget" />,
        <RecentInvestmentsWidget viewClickHandler={this.props.viewClickHandler} key="recent-investments-widget" />,
        <FaqWidget key="faq-widget" />
      ]
    }

    return widgets
  } 

  render () {
    return (
      <div id="scrollableDiv">
        <Grid className="grid-margin-x">
          <Cell id="js-widget-cell" medium={12} large={8}>
            { !this.props.onboarded &&
              <ProfileWidget
                steps={5}
                completedStep={this.props.completedStep}
                completedPercent={this.props.completedPercent || 1}
              />
            }
            {
              this.state.widgets.map((widget, i) =>
                <Fragment key={`feed-side-widget-${i}`}>
                  {widget}
                </Fragment>
              )
            }
            { this.state.isLoading &&
              <LoadingIcon />
            }
          </Cell>
          <Cell medium={12} large={4} className="hide-for-medium-only hide-for-small-only">
            {
              this.sideWidgets().map((widget, i) =>
                <Fragment key={`feed-side-widget-mobile-${i}`}>
                  {widget}
                </Fragment>
              )
            }
          </Cell>
        </Grid>
        <PrivateCaseAccessWatcher startAblyWatch={this.state.startAblyWatch} />
      </div>
    )
  }
}

export default Feed
