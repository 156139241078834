import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals';

export const Intro = styled.div`
  background-color: ${props => props.theme.colors.white};
`;

export const Content = styled.div`
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`

export const Section = styled.div`
  max-width: 820px;
  margin: 0 auto;
`


export const Heading = styled(Globals.H2)`
  margin-bottom: 30px;
`;
export const Subheading = styled(Globals.Description) `
  margin-bottom: 1rem;
  -webkit-font-smoothing: auto;
`;

export const Bold = styled.div`
  font-weight: bold;
`

export const ButtonContainer = styled.div`
  text-align: center;
`

export const Button = styled(Globals.ButtonLink)`
  margin-top: 30px;
`
