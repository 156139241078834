import styled from 'styled-components'

export const LsCheck = styled.div`
  padding: 10px;
  position: relative;
`

export const CheckButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  margin: 16px 0px;
  z-index: 0;
  border-radius: 3px;
  border: 2px solid #dfdddd;
  margin-top: 8px;
  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.error {
    border: 1px solid red;
  }
`

export const Input = styled.input`
  position: absolute !important;
  margin-top: -7px;
  margin-left: -7px;
  position: relative;
  z-index: 10;
  opacity: 0;
  width: 100%;
  height: 100%;
  &:checked ~ ${CheckButton} {
    background-color: ${props => props.theme.colors.teal};
    border: 1px solid ${props => props.theme.colors.teal};
  }
`

export const TextContainer = styled.div`
  margin-left: 25px;
  margin-top: -2px;
  line-height: 1.6rem;
  min-width: 250px;
  &.text-block {
    margin-left: 35px;
    margin-top: -7px;
  }
`

export const Text = styled.p`
  font-size: 16px;
`

export const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: -10px;
  margin-left: -10px;
`
