import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import ContentSection from '../../components/raise-funds-case-review/ContentSection/ContentSection';
import theme from '../../config/theme';
import NewNavbar from '../../components/Navbar/NewNavbar'
import Footer from '../../components/Footer'

const Page = styled.div`
  background-color: ${props => props.theme.colors.lightPurple};
`

class RaiseFundsCaseReview extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar theme="trueWhite"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <ContentSection caseSubmissionName={this.props.caseSubmissionName} />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default RaiseFundsCaseReview;
