import styled from 'styled-components'
import * as Globals from '../../../Globals'

export const Heading = styled(Globals.H1)`
  text-align: center;
  margin-top: 60px;
`

export const Subheading = styled.p`
  text-align: center;
`

export const RadioContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  &.error {
    border: 1px solid red;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
  }
`

export const ErrorsContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export const TaxContainer = styled.div`
  max-width: 400px;
  margin: 30px auto 60px auto;
`
