const theme = {
  breakpoints: {
    medium: 640,
    large: 1024,
    xlarge: 1600
  },
  colors: {
    white: '#FFFFFF',
    creamWhite: '#fefefe',
    lightPurple: '#F8F7FA',
    lighterPurple: '#4D2A6E',
    veryLightPurple: '#914cde',
    purple: '#9450D4',
    purpleWhite: '#F8F7FA',
    mediumPurple: '#8247BA',
    darkPurple: '#534566',
    darkerPurple: '#591DB4',
    backdropPurple: '#131017',
    darkestPurple: '#190E26',
    backgroundPurple: '#554267',
    nightPurple: '#100d12',
    inactivePurple: '#746e7f',
    neonPurple: '#A01FE5',
    nightPurple2: '#160D21',
    grey: '#7E7B83',
    mediumGrey: '#D4D4D4',
    lightGrey: '#f5f5f5',
    lighterGrey: '#D3D8DD',
    lightGrey2: '#7d7d7d',
    lightGrey3: '#E2E0EA',
    lightGrey4: '#979797',
    darkGrey: '#282331',
    purpleGrey: '#878291',
    widgetGrey: '#b9b6ba',
    black: '#000000',
    teal: '#1488B6',
    mutedTeal: '#007095',
    errorRed: '#bd0000;'
  }
};

export default theme;
