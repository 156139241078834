import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import {
  BannerSection,
  MetricSection,
  InfoSection,
  InvestmentsSection,
  CallToActionSection,
  PersonasSection
} from '../../components/track-record'

import theme from '../../config/theme';

const Page = styled.div`
  background-color: ${props => props.theme.colors.lightPurple};
`

class TrackRecord extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="trueWhite" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor}  currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <Page>
            <BannerSection />
            <MetricSection metrics={this.props.metrics} />
            <InfoSection metrics={this.props.metrics} />
            <InvestmentsSection investments={this.props.court_cases} csrfToken={this.props.csrfToken} />
            <CallToActionSection investment={this.props.recently_funded_case}/>
            <PersonasSection />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default TrackRecord;
