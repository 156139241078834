import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as Globals from "../../Globals";
import theme from "../../../config/theme";

export const Card = styled.a`
  width: 100%;
`;

export const FeatureImage = styled.img`
  width: 100%;
  height: 200px;
  background: linear-gradient(
      0deg,
      rgba(130, 71, 186, 0.5),
      rgba(130, 71, 186, 0.5)
    ),
    url(${(props) => props.src});
  background-blend-mode: overlay, normal;
  object-fit: cover;

  ${breakpoint("medium")`
    height: 216px;
    border-radius: 5px;
  `}
`;

export const Tag = styled.span`
  color: ${theme.colors.purpleGrey};
  height: 24px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  ${Globals.proximaFontStack};
  padding: 0px 20px 0px 20px;

  &:hover {
    color: ${theme.colors.purpleGrey};
  }

  ${breakpoint("medium")`
    padding: 0px;
  `}
`;

export const Title = styled(Globals.H6)`
  ${Globals.tiempoFontStack};
  color: ${theme.colors.backdropPurple};
  line-height: 130% !important;
  letter-spacing: -0.5px;
  font-weight: normal;
  margin-top: 0px;
  flex-basis: 100%;
  padding: 0px 20px 0px 20px;
  font-size: 22px;

  &:hover {
    color: ${theme.colors.backdropPurple};
  }

  ${breakpoint("medium")`
    line-height: 32px !important;
    padding: 0px;
    font-size: 24px;
  `}
`;

export const Description = styled.p`
  ${Globals.proximaFontStack}
  font-size: 17px;
  line-height: 130%;
  color: ${theme.colors.backdropPurple};
  margin-top: 10px;
  padding: 0px 20px 0px 20px;

  ${breakpoint("medium")`
    padding: 0px;
    font-size: 16px;
    line-height: 24px;
  `}
`;
