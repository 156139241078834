import React from "react";

const isClient = typeof window === "object";

const VALUES = {
  xxs: 0,
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1920,
};

const bpKeys = ["xxs", "xs", "sm", "md", "lg", "xl"];

const breakpoints = {
  keys: bpKeys,
  values: VALUES,
  up: (bp) => `(min-width: ${VALUES[bp]}px)`,
  down: (bp) =>
    `(max-width: ${typeof bp === "number" ? bp : VALUES[bp] - 0.1}px)`,
  only: (bp) => {
    const currentKeyIndex = bpKeys.indexOf(bp);

    if (currentKeyIndex === -1) {
      return "";

      // if it's the last item we won't put a max-width
    } else if (currentKeyIndex === bpKeys.length - 1) {
      return `(min-width: ${VALUES[bp]}px)`;
    }

    const nextBp = bpKeys[currentKeyIndex + 1];

    return `(min-width: ${VALUES[bp]}px) and (max-width: ${
      VALUES[nextBp] - 0.1
    }px)`;
  },
};

export const useMediaQuery = (
  breakpointKey,
  direction = "up",
  defaultState = false
) => {
  const query = breakpoints[direction](breakpointKey);
  const [state, setState] = React.useState(
    isClient ? () => window.matchMedia(query).matches : defaultState
  );

  React.useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
};
