import * as yup from 'yup'
import React, { useRef } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import { Grid, Cell } from 'react-foundation'
import InputSelect from '../../Form/InputSelect'
import InputButton from '../../Form/InputButton'
import InputCheck from '../../Form/InputCheck'
import {RiskLabelSpacer, RateLabelSpacer} from './SuitabilityForm.style'
import { isEmpty } from 'lodash'
import {
  INVESTMENT_OBJECTIVES, INVESTING_EXPERIENCE, INVESTING_RISK_TOLERANCE,
  OTHER_INVESTMENTS, PRIVATE_SECURITIES_PERCENTAGE, TAX_RATE
} from '../../../config/constants.js.erb'

const schema = yup.object().shape({
  investing_objectives: yup.string(),
  investing_experience: yup.string(),
  risk_tolerance: yup.string(),
  other_investments: yup.array().of(yup.string()),
  private_securities_percentage: yup.string(),
  tax_rate: yup.string(),
  asset_attestation: yup.boolean().oneOf([true], 'Must agree to the requirement above')
})

function SuitabilityForm({csrfToken, investor}) {
  const formRef = useRef(null)

  const defaultValues = {
    investing_objectives: investor.investing_objectives || '',
    investing_experience: investor.investing_experience || '',
    risk_tolerance: investor.risk_tolerance || '',
    other_investments: investor.other_investments,
    private_securities_percentage: investor.private_securities_percentage || '',
    tax_rate: investor.tax_rate || '',
    asset_attestation: investor.asset_attestation || false
  }

  const handleSubmit = ({investing_objectives, investing_experience, risk_tolerance, private_securities_percentage, tax_rate, asset_attestation, other_investments}) => {

    const payload = {
      investing_objectives,
      investing_experience,
      risk_tolerance,
      private_securities_percentage,
      tax_rate,
      asset_attestation,
      other_investments: other_investments,
      authenticity_token: csrfToken
    }

    axios.put(`/investors/${investor.id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, setFieldValue, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-suitability">
            <Grid className="grid-margin-x">
              <Cell medium={6} className="js-objectives-cell">
                <InputSelect
                  label="My primary investment objectives are:"
                  name="investing_objectives"
                  options={ INVESTMENT_OBJECTIVES }
                  error={errors.investing_objectives}
                  value={INVESTMENT_OBJECTIVES.find(option => option.value === values.investing_objectives)}
                  changeHandler={(option) => setFieldValue("investing_objectives", option.value)}
                />
              </Cell>
              <Cell medium={6} className="js-experience-cell">
                <InputSelect
                  label="My relevant investment experience is:"
                  name="investing_experience"
                  options={ INVESTING_EXPERIENCE }
                  error={errors.investing_experience}
                  value={INVESTING_EXPERIENCE.find(option => option.value === values.investing_experience)}
                  changeHandler={(option) => setFieldValue("investing_experience", option.value)}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6} className="js-risk-cell">
                <RiskLabelSpacer />
                <InputSelect
                  label="My willingness to accept risk for this investment is:"
                  name="risk_tolerance"
                  options={ INVESTING_RISK_TOLERANCE }
                  error={errors.risk_tolerance}
                  value={INVESTING_RISK_TOLERANCE.find(option => option.value === values.risk_tolerance)}
                  changeHandler={(option) => setFieldValue("risk_tolerance", option.value)}
                />
              </Cell>
              <Cell medium={6} className="js-assets-cell">
                <InputSelect
                  label="Are you currently holding any of the below investments:"
                  name="other_investments"
                  options={ OTHER_INVESTMENTS }
                  error={errors.other_investments}
                  isMulti={true}
                  className="basic-multi-select"
                  clearable={true}
                  value={OTHER_INVESTMENTS.filter(option => values.other_investments.includes(option.value))}
                  changeHandler={(option) => setFieldValue("other_investments", option.map((o) => o.value))}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6} className="js-allocation-cell">
                <InputSelect
                  label="My current portfolio allocation for private securities is less than $150,00, I attest that I have liquid assets equal to, or greater than the aforementioned amount."
                  name="private_securities_percentage"
                  options={ PRIVATE_SECURITIES_PERCENTAGE }
                  error={errors.private_securities_percentage}
                  value={PRIVATE_SECURITIES_PERCENTAGE.find(option => option.value === values.private_securities_percentage)}
                  changeHandler={(option) => setFieldValue("private_securities_percentage", option.value)}
                />
              </Cell>
              <Cell medium={6} className="js-tax-cell">
                <RateLabelSpacer />
                <InputSelect
                  label="My approximate tax rate is:"
                  name="tax_rate"
                  options={ TAX_RATE }
                  error={errors.tax_rate}
                  value={TAX_RATE.find(option => option.value === values.tax_rate)}
                  changeHandler={(option) => setFieldValue("tax_rate", option.value)}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell large={12}>
                <InputCheck
                  label="I attest that I have liquid assets equal to or greater than the aforementioned amount."
                  name="asset_attestation"
                  changeHandler={handleChange}
                  error={errors.asset_attestation}
                  checked={values.asset_attestation}
                />
              </Cell>
            </Grid>
            <Grid>
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default SuitabilityForm
