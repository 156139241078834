import React, { useState } from 'react'
import { get } from 'lodash'
import AccreditationEntity from './AccreditationEntity'
import { Description, Selectors, Selector } from './AccreditationForm.style'

function AccreditationForm({csrfToken, investor}) {
  const [selected, setSelected] = useState("individual")

  return (
    <>
      <Description> Select an option below to view your accreditation status and upload supporting documents </Description>
      <Selectors>
        <Selector href="#" selected={selected == "individual"} onClick={(e) => {e.preventDefault(); setSelected("individual")}}> Individual - {get(investor, 'full_name')} </Selector>
        { get(investor, 'entity.name') && <Selector href="#" selected={selected == "entity"} onClick={(e) => {e.preventDefault(); setSelected("entity")}}> Entity - {get(investor, 'entity.name')} </Selector> }
        { get(investor, 'trust.name') && <Selector href="#" selected={selected == "trust"} onClick={(e) => {e.preventDefault(); setSelected("trust")}}> Trust - {get(investor, 'trust.name')} </Selector> }
      </Selectors>
      { selected == "individual" && <AccreditationEntity csrfToken={csrfToken} investor={investor} type="individual" /> }
      { selected == "entity" && <AccreditationEntity csrfToken={csrfToken} investor={investor} type="entity" /> }
      { selected == "trust" && <AccreditationEntity csrfToken={csrfToken} investor={investor} type="trust" /> }
    </>
  )
}

export default AccreditationForm
