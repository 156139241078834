import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import theme from '../../../config/theme';
import * as Globals from '../../Globals'

export const Container = styled.div`
  margin-top: -25px;

  ${breakpoint('medium')`
    margin-top: 0px;
  `}

  ${breakpoint('large')`
    margin-top: 60px;
  `}
`;

export const Link = styled.a`
  color: ${theme.colors.backdropPurple};

  &:hover {
    color: ${theme.colors.backdropPurple};
  }
`;

export const FeatureImage = styled.img`
  width: 100%;
  height: 200px;
  background: linear-gradient(0deg, rgba(130, 71, 186, 0.5), rgba(130, 71, 186, 0.5)), url(${props => props.src});
  background-blend-mode: overlay, normal;
  border-radius: 5px;
  object-fit: cover;
  margin-top: 30px;

  ${breakpoint('large')`
    height: 421px;
  `}

  ${breakpoint('medium')`
    height: 100%;
  `}
`

export const Tag = styled.p`
  color: ${theme.colors.purpleGrey};
  height: 24px;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  ${Globals.proximaFontStack}

  &:hover {
    color: ${theme.colors.purpleGrey};
  }

  ${breakpoint('large')`
    margin-top: 100px;
  `}
`

export const Title = styled(Globals.H6)`
  ${Globals.tiempoFontStack};
  color: ${theme.colors.backdropPurple}
  line-height: 32px !important;
  letter-spacing: -0.5px;
  font-weight: normal;
  margin-top: 10px;
  flex-basis: 100%;

  ${breakpoint('medium')`
    line-height: 32px !important;
  `}
`;

export const Excerpt = styled.p`
  ${Globals.proximaFontStack}
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.backdropPurple};
  margin-top: 11px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;