import React from "react";
import HoverMenu from "../HoverMenu/HoverMenu";
import {
  Container,
  Header,
  Link,
  List,
  ListItem,
  Subheader,
  Triangle,
} from "./Sidenav.style";
import Mixpanel from "../../Mixpanel";

class Sidenav extends React.Component {
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const footerDistanceFromTop = document
        .querySelector(".js-footer")
        ?.getBoundingClientRect()?.top;
      // 440px is the number of pixels from the top of the menu to the bottom of the sidenav element
      if (footerDistanceFromTop < 440) {
        this.setState({
          position: "relative",
          hoverMenuOpened: false,
        });
      } else {
        this.setState({
          position: "fixed",
        });
      }
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      position: "fixed",
      hoverMenuOpened: false,
      focusedTopicPath: "",
      topic: "",
      hoverMenuDisabled: this.props.hoverMenuDisabled || false,
    };
  }

  render() {
    const isMobile = () => window.innerWidth < 640;
    const showHoverMenu = () =>
      this.state.hoverMenuOpened && !this.state.hoverMenuDisabled;

    const onMouseLeave = () => {
      this.setState({
        hoverMenuOpened: false,
        focusedTopicPath: "",
        topic: "",
      });
      $("body").css("overflow", "visible");
    };

    const onMouseEnter = () => {
      $("body").css("overflow", "hidden");
    };

    const trackClick = (event) => {
      Mixpanel.track(event);
    };

    return (
      <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        <Container
          position={this.state.position}
          hoverMenuOpened={this.state.hoverMenuOpened}
        >
          <Header
            href="/"
            onClick={() => trackClick('Clicked "LexShares" home button')}
          >
            LexShares Home
          </Header>
          <Subheader>Topics</Subheader>
          <List>
            <ListItem>
              <Link
                target="_blank"
                href={this.props.caseStudiesPath}
                onClick={() => trackClick('Clicked "Case Studies"')}
              >
                Case Studies →
              </Link>
            </ListItem>
          </List>
          <Subheader>Funding Solutions</Subheader>
          <List>
            <ListItem>
              <Link
                target="_blank"
                href={this.props.fundingAttorneysPath}
                onClick={() => trackClick('Clicked "For Attorneys"')}
              >
                For Attorneys →
              </Link>
            </ListItem>
            <ListItem>
              <Link
                target="_blank"
                href={this.props.fundingPlaintiffsPath}
                onClick={() => trackClick('Clicked "For Plaintiffs"')}
              >
                For Plaintiffs →
              </Link>
            </ListItem>
            <ListItem>
              <Link
                target="_blank"
                href={this.props.fundingCasePath}
                onClick={() => trackClick('Clicked "Discuss a Case"')}
              >
                Discuss a Case →
              </Link>
            </ListItem>
          </List>
        </Container>
        {showHoverMenu() && !isMobile() && (
          <HoverMenu
            focusedTopicPath={this.state.focusedTopicPath}
            topic={this.state.topic}
          />
        )}
      </div>
    );
  }
}

export default Sidenav;
