import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'

export const Container = styled.div`
  background-color: white;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 3% 3% 5% 3%;
  ${breakpoint('medium')`
    margin-top: 80px;
    padding-top: 0px;
  `}
`

export const Content = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding-top: 3%;
`

export const Heading = styled(Globals.H2)`
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  ${breakpoint('medium')`
    max-width: 450px;
  `}
`

export const BackButton = styled(Globals.ButtonInverse)`
  border-color: ${props => props.theme.colors.teal};
  color: ${props => props.theme.colors.teal};
  width: 100%;
  margin-bottom: 10px;
  &:hover {
    color: ${props => props.theme.colors.mutedTeal} !important;
    border-color: ${props => props.theme.colors.mutedTeal} !important;
  }
  ${breakpoint('medium')`
    width: unset;
  `}
`

export const ContinueButton = styled(Globals.ButtonLink)`
  background: ${props => props.theme.colors.teal};
  width: 100%;
  margin-bottom: 10px;
  &:hover {
    background: ${props => props.theme.colors.mutedTeal};
  }
  ${breakpoint('medium')`
    width: unset;
  `}
`
