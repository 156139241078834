import React from 'react';
import { Grid } from 'react-foundation';
import { Container, Heading, Subheading } from './WhitepapersSection.style';
import WhitepaperCard from '../WhitepaperCard/WhitepaperCard';

const WhitepapersSection = ({whitepapers}) => {
  return(
    <Container>
      <Heading>Whitepapers</Heading>
      <Subheading>Detailed resources developed by our in-house team of former litigators.</Subheading>
      <Grid className="grid-margin-x grid-margin-y grid-padding-y align-left">
        {
          whitepapers.map((whitepaper) => (
            <WhitepaperCard {...whitepaper} key={whitepaper.slug} />
          ))
        }
      </Grid>
    </Container>
  )
}

export default WhitepapersSection;