import styled from 'styled-components'
import * as Globals from '../../../Globals'
import loaderIcon from '../../../../../assets/images/icons/dark-ajax-loader.gif'

export const Content = styled(Globals.FadeContent)`
`

export const LoadingIcon = styled.div`
  background: ${`url(${loaderIcon}) no-repeat center center`};
  height: 32px;
  width: 32px;
  margin: 0 auto;
`

