import React from 'react'
import { Grid, Cell } from 'react-foundation'
import { Container, Content, InfoContainer, H1, FormContainer, H3, ImageContainer } from './ContentSection.style'
import Form from '../Form'
import CheckImg from '../../../../assets/images/icons/checkmark-white-in-purple-background.png';


function ContentSection({csrfToken, caseSubmissionId}) {
  return (
    <Container>
      <Content>
        <Grid className="grid-margin-x">
          <Cell medium={12} large={6} className="small-order-2 large-order-1">
            <InfoContainer>
              <ImageContainer>
                <img src={CheckImg} />
              </ImageContainer>
              <H1>
                Your case has been submitted for review.
              </H1>
            </InfoContainer>
          </Cell>
          <Cell medium={12} large={6} className="medium-order-1 large-order-2">
            <FormContainer>
              <H3>
                Share more details while your inquiry is in review:
              </H3>
              <Form csrfToken={csrfToken} caseSubmissionId={caseSubmissionId}></Form>
            </FormContainer>
          </Cell>
        </Grid>
      </Content>
    </Container>
  )
}

export default ContentSection
