import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { H3 } from './../../Globals'

export const Section = styled.div`
  background: ${props => props.theme.colors.backdropPurple};
`;

export const FormContainer = styled.div`
  display: block;
  background-color: white;
  border-radius: 5px;
  min-height: 400px;
  margin: 0 auto;
  position: relative;
  margin-top: 3%;
  padding: 20px 40px;
  width: 100%;
  z-index: 1;
  ${breakpoint('large')`
    max-width: 370px;
  `}
`

export const Heading = styled(H3)`
  color: black;
  text-align: center;
  font-size: 24px !important;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: proxima-nova !important;
`
