import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint'
import * as Globals from './../../Globals';

export const Different = styled.div`
  background-color: #f7f7f7;
`;

export const Content = styled.div`
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}

  .inform {
    margin-top: 50px;
  }
`

export const Section = styled.div`
  max-width: 820px;
  margin: 0 auto;
`


export const Heading = styled(Globals.H2)`
  margin-bottom: 30px;
`;
export const Subheading = styled(Globals.Description) `
  margin-bottom: 1rem;
  -webkit-font-smoothing: auto;
`;

export const Bold = styled.span`
  font-weight: bold;
`

export const Icon = styled.div`
  text-align: center;
  margin-bottom: 30px;

  img {
    max-width: 39px;
    max-height: 39px;
    image-rendering: -webkit-optimize-contrast;
  }
`

export const Header = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
}
`

export const Summary = styled(Globals.Description)`
  margin-bottom: 30px;
  -webkit-font-smoothing: auto;

  ${breakpoint('medium')`
    margin-bottom: 60px;
  `}
`
