import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Value, Label, Metrics, Metric, Bold, Description } from './MetricSection.style'

function MetricSection({metrics = null}) {
  return (
    <Fragment>
      <Section>
        <Grid className="grid-margin-x">
          <Cell small={12} large={8}>
            <Metrics>
              <Metric>
                <Value>
                   {metrics.total_investments}
                </Value>
                <Label>
                  Legal claim investments to date
                </Label>
              </Metric>
              <Metric>
                <Value>
                  {metrics.sought_by_prospects}B
                </Value>
                <Label>
                  Sought by LexShares case funding prospects
                </Label>
              </Metric>
              <Metric>
                <Value>
                  {metrics.resolved_investments}
                </Value>
                <Label>
                  Fully resolved investments
                </Label>
              </Metric>
            </Metrics>
          </Cell>
          <Cell small={12} large={4}>
            <Description>
              <Bold> Past performance is not indicative of future performance. </Bold>
              <br />
              {metrics.performance_disclosure}
            </Description>
          </Cell>
        </Grid>
      </Section>
    </Fragment>
  )
}

export default MetricSection
