import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../Globals'

export const CardContainer = styled.div`
  padding: 30px 25px;
  border-radius: 10px;
  height: 220px;
  display: block;
  width: 100%;
  max-width: unset;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(107, 107, 107, 0.2);
  ${breakpoint('large')`
    float: left;
    max-width: 275px;
  `}
`

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  overflow: hidden;
  text-align: left;
  ${Globals.tiempoFontStack};
`

export const Spv = styled.div`
  font-size: 15px;
  text-align: left;
  margin-bottom: 15px;
`

export const Button = styled(Globals.ButtonLink)`
  width: 150px;
  min-width: 150px;
  display: block;
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #F8F7FA;
  color: #8247BA;
  cursor: ${props => props.disabled ? "initial" : "pointer"};
  &:hover, &:active, &:visited, &:focus {
    background-color: #F8F7FA;
    color: #8247BA;
  }
`
