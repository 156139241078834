import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, Heading, Subheading, Guide, Ebook,
  List, Item, Checkmark, DownloadInput, Download,
  DownloadButton, MobileImage, DiamondProp
} from './CallToActionSection.style';
import GuideImg from '../../../../assets/images/in_house/guide.png';
import CheckmarkImg from '../../../../assets/images/icons/checkmark.png';
import EbookImg from '../../../../assets/images/in_house/ebook.png';


function CallToActionSection({csrfToken = null}) {

  return (
    <Fragment>
      <Section>
        <DiamondProp />
        <Content>
          <Grid>
            <Cell large={5}>
              <Guide>
                <img src={GuideImg} />
              </Guide>
              <Ebook>
                <img src={EbookImg} />
              </Ebook>
            </Cell>
            <Cell large={7}>
              <Heading>
                Download our free litigation finance guide.
              </Heading>
              <Subheading>
                Litigation finance can help enable attorneys and plaintiffs to secure capital, reduce risk and improve litigation outcomes. In this guide, you will:
              </Subheading>

              <MobileImage>
                <img src={GuideImg} />
              </MobileImage>

              <List>
                <Item>
                  <Checkmark>
                    <img src={CheckmarkImg}/>
                  </Checkmark>
                  Gain an in-depth understanding of litigation finance
                </Item>
                <Item>
                  <Checkmark>
                  <img src={CheckmarkImg}/>
                  </Checkmark>
                  Understand how experts select cases
                </Item>
                <Item>
                  <Checkmark>
                  <img src={CheckmarkImg}/>
                  </Checkmark>
                  Review industry growth and regulations
                </Item>
                <Item>
                  <Checkmark>
                  <img src={CheckmarkImg}/>
                  </Checkmark>
                  Look into the future of litigation finance
                </Item>
              </List>

              <Download>
                <form action="/litigation_finance_requests" method="post">
                  <Grid>
                    <Cell medium={7} small={12}>
                      <DownloadInput type="email" name="litigation_finance_request[email]" placeholder="Email address" required={true} />
                      <input type="hidden" name="authenticity_token" value={csrfToken} />
                    </Cell>
                    <Cell medium={5} small={12}>
                      <DownloadButton type="submit">
                        Download Guide
                      </DownloadButton>
                    </Cell>
                  </Grid>
                </form>
              </Download>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default CallToActionSection;
