import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H6, Body } from '../../Globals';

export const Nav = styled.div`
  background-color: ${props => props.theme.colors.lightPurple};
  padding: 15% 6%;

  ${breakpoint('medium')`
    padding: 4% 6%;
  `}
`;

export const Content = styled.div `

`

export const Name = styled(H6)`
  color: #282331;
`

export const Title = styled(Body)`
  color: ${props => props.theme.colors.purple};
  font-size: 12px;
  line-height: 16px;
  ${breakpoint('medium')`
    font-size: 18px;
    line-height: 150%;
    margin-top: 15px;
  `}
`

export const LeftNav = styled.a`
  text-align: left;
  position: relative;
  display: block;

  &::after {
    content: "";
    border-top: 6px solid transparent;
    border-right: 6px solid #9450D4;
    border-bottom: 6px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    left: -15px;
    z-index: 2;
    top: 6px;
  }
`

export const RightNav = styled.a`
  text-align: right;
  position: relative;
  display: block;

  &::after {
    content: "";
    border-top: 6px solid transparent;
    border-left: 6px solid #9450D4;
    border-bottom: 6px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    right: -15px;
    z-index: 2;
    top: 6px;
  }
`
