import React, {Fragment, useEffect, useState} from 'react'
import ModalPop from '../ModalPop'
import axios from 'axios'
import {
  Header,
  Button
} from './PrivateCaseAccessWatcher.style'

function PrivateCaseAccessWatcher({startAblyWatch = false}) {
  const [popVisible, setPopVisible] = useState(false)
  const [watchAbly, setWatchAbly] = useState(null)
  const [caseId, setCaseId] = useState(null)
  const [privateRequestId, setPrivateRequestId] = useState(null)

  const hidePop = () => {
    setPopVisible(false)
  }

  /**
   * Check if we have any pending private case access rquests for
   * a case that is viewable or approved.
   */ 
  useEffect(() => {
    axios.get(`/private_case_access_requests.json`).then(res => {
      if (res.data.length == 1) {
        setCaseId(res.data[0].court_case_id)
        setPrivateRequestId(res.data[0].id)
        setWatchAbly(true)
      }
    })
  }, [startAblyWatch])

  /**
   * Begin listning to Ably for case access request approvals.
   */
  useEffect(() => {
    if (watchAbly) {
      const apiKey = process.env.ABLY_SUBSCRIBER_KEY
      const channelName = `private-requests-${privateRequestId}`
      const client = new Ably.Realtime(apiKey)
      const channel = client.channels.get(channelName)
      channel.subscribe('update', function(message) {
        const data = JSON.parse(message.data)
        if (data.approved == true) {
          setPopVisible(true)
        }
        channel.unsubscribe()
        client.close()
        setWatchAbly(false)
      })
    }
  }, [watchAbly])

  const content = 
    <Fragment>
      <Header>You have been granted access to review the case.</Header>
      <Button href={`/cases/${caseId}`}>Review Now</Button>
    </Fragment>

  return (
    <ModalPop popVisible={popVisible} content={content} hideHandler={hidePop} size="medium" />
  )
}

export default PrivateCaseAccessWatcher
