import React from 'react'
import {openPopupWidget} from 'react-calendly'
import Mixpanel from '../../../Mixpanel'
import {
  Widget,
  Headshot,
  Heading,
  Text,
  ScheduleButton
} from './AdvisorWidget.style'

/**
 * The purpose of this widget is to connect invstors with our advisor
 * in case that they have questions. Pop up is powered by Calendly.
 */
function AdvisorWidget({description}) {

  const onClick = () => {
    const url = 'https://calendly.com/ray-marchand/lexshares-investor-call'
    openPopupWidget({url})
    Mixpanel.track('Clicked Ray widget')
  }

  return (
    <Widget className="js-advisor-widget">
      <Headshot />
      <Heading>Meet Ray</Heading>
      <Text>{description}</Text>
      <ScheduleButton onClick={onClick}>Schedule a call</ScheduleButton>
    </Widget>
  )
}

export default AdvisorWidget
