import React from "react";
import { Cell, Grid } from "react-foundation";
import {
  CellContent,
  Container,
  Heading,
  ImageBackground,
  ImageContainer,
  SmallTrDescription,
  TrDescription,
} from "./TrackRecord.style";
import CardIcon from '../../../../assets/images/icons/card-icon.png';
import CalculatorIcon from '../../../../assets/images/icons/calculator-icon.png';
import GraphIcon from '../../../../assets/images/icons/graph-icon.png';

function TrackRecord() {
  return (
    <Container>
      <Heading>Investment Track Record</Heading>
      <Grid className="grid-padding-x">
        <Cell small={12} medium={6} large={4} style={{ padding: 0 }}>
          <CellContent>
            <TrDescription>
              <b>50+ case investments</b> made in the past 3 years
            </TrDescription>
            <ImageContainer>
              <ImageBackground>
                <img src={CardIcon} />
              </ImageBackground>
            </ImageContainer>
          </CellContent>
        </Cell>
        <Cell small={12} medium={6} large={4} style={{ padding: 0 }}>
          <CellContent>
            <TrDescription>
              Average deal size of <b>$1.42M</b> since January 2020
            </TrDescription>
            <ImageContainer>
              <ImageBackground>
                <img src={CalculatorIcon} />
              </ImageBackground>
            </ImageContainer>
          </CellContent>
        </Cell>
        <Cell small={12} medium={6} large={4} style={{ padding: 0 }}>
          <CellContent>
            <SmallTrDescription>
              Top litigation practices, boutiques, and AmLaw firms have sought
              funding from LexShares
            </SmallTrDescription>
            <ImageContainer>
              <ImageBackground>
                <img src={GraphIcon} />
              </ImageBackground>
            </ImageContainer>
          </CellContent>
        </Cell>
      </Grid>
    </Container>
  );
}

export default TrackRecord;
