import React, { Fragment } from "react";
import { Cell, Grid } from "react-foundation";
import {
  Banner,
  BannerContent,
  Heading,
  Subheading,
  ImageContainer,
  Button,
  ButtonInverse,
  PillIconContainer,
  Pill,
} from "./BannerSection.style";
import WsjImg from '../../../../assets/images/home/wsj.png';
import BloombergImg from '../../../../assets/images/home/bloomberg-law.png';
import LawImg from '../../../../assets/images/home/law.png';
import FtImg from '../../../../assets/images/home/financial-times.png';
import NytImg from '../../../../assets/images/home/new-york-times.png';
import Law360Img from '../../../../assets/images/home/law-360.png';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <BannerContent>
          <Grid className="grid-margin-x align-middle">
            <Cell large={6}>
              <Heading>Financial strength for your litigation</Heading>
              <PillIconContainer>
                <Pill data-descr="There is no repayment obligation if the case is lost.">
                  Non-Recourse Funding
                </Pill>
                <Pill data-descr="Funding up to 10% of a case's expected recovery value.">
                  $200K - $5M+
                </Pill>
                <Pill
                  data-descr="Flexible deal structures built to support a wide range of client needs."
                  position="last"
                >
                  Single Cases & Portfolios
                </Pill>
              </PillIconContainer>
            </Cell>
            <Cell large={6}>
              <Subheading>
                With LexShares, you are backed by the stability and experience
                of a litigation finance leader.
              </Subheading>
              <div>
                <Button href="/funding/attorneys ">For Attorneys</Button>
                <ButtonInverse href="/funding/plaintiffs">
                  For Businesses
                </ButtonInverse>
              </div>
            </Cell>
          </Grid>
        </BannerContent>
      </Banner>

      <ImageContainer>
        <img src={WsjImg} />
        <img src={BloombergImg} />
        <img src={LawImg} />
        <img src={FtImg} />
        <img src={NytImg} />
        <img src={Law360Img} />
      </ImageContainer>
    </Fragment>
  );
}

export default BannerSection;
