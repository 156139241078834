import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Banner, BannerContent, Heading, Description
} from './BannerSection.style';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <BannerContent>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Tax Center
              </Heading>
            </Cell>
          </Grid>
        </BannerContent>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
