import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as rf from 'react-foundation'

import logo from '../../../../assets/images/logos/lexshares-diamond.png'
import theme from '../../../config/theme';
import * as Globals from '../../Globals';

export const LearnMoreSection = styled.div`
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`;

export const MenuContainer = styled.div`
  position: fixed;
  z-index: 9999;
  margin-top: 37px;
  padding-top: 0;
  width: 100%;
  left: 50%;
  top: 0;
  margin-left: -50%;
  margin-top: 0;
`

export const MenuBox = styled.div`
  justify-content: center;
  flex-grow: 1;
  margin-right: 15px;

  ${breakpoint('medium')`
    margin-right: 80px;
  `}
`;

export const MobileTopBar = styled(rf.TopBar)`
  padding: 15px 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
  margin-top: 0;
  flex-wrap: nowrap;
  padding-top: 0;
  background-color: unset;
  display: flex;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.lighterGrey};
  z-index: 99999;

  ${breakpoint('large')`
    flex-wrap: nowrap;
    display: none;
  `}
`

export const TopBarLogo = styled(rf.TopBarLeft)`
  display: flex;

  ${breakpoint('large')`
    flex: 0 1 auto !important;
  `}
`

export const LogoLink = styled.a`
  padding: 0;
  cursor: pointer;
  height: 35px;
  width: 35px;
  margin: 22px 9px 23px 9px !important;
  background: url(${logo}) no-repeat center center/cover;

  ${breakpoint('medium')`
    margin: 22px 9px 23px 30px !important;
  `}
`

export const TopBarLogoLeft = styled(rf.TopBarLeft)`
  flex: 0 1 auto !important;
  margin: 25px 0px 25px 15px !important;
  
  ${breakpoint('large')`
    margin: 25px 15px !important;
  `}

  ${breakpoint('large')`
    padding-left: 15px;
  `}
`
export const MenuButton = styled.a`
  img {
    width: 20px;
  }
`

export const TopBar = styled(rf.TopBar)`
  padding: 15px 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
  margin-top: 0;
  flex-wrap: wrap;
  padding-top: 0;
  display: none;
  background-color: ${theme.colors.white};
  height: 100%;
  overflow: scroll;
  border-bottom: 1px solid ${theme.colors.lighterGrey};

  ${breakpoint('large')`
    flex-wrap: nowrap;
    display: flex;
    height: unset;
    overflow: visible;
    transition: background-color 0.5s;
    background-color: ${theme.colors.white};
  `}
`

export const MenuTopBar = styled(rf.TopBar)`
  padding: 15px 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
  margin-top: 0;
  flex-wrap: wrap;
  padding-top: 0;
  display: block;
  background-color: ${theme.colors.white};
  height: 100%;
  overflow: scroll;
  border-bottom: 1px solid ${theme.colors.lighterGrey};

  ${breakpoint('large')`
    flex-wrap: nowrap;
    display: flex;
    height: unset;
    overflow: visible;
    transition: background-color 0.5s;
    background-color: ${theme.colors.white};
  `}
`

export const TopBarLeft = styled(rf.TopBarLeft)`
  overflow: scroll;

  ${breakpoint('large')`
    height: unset;
    overflow: unset;
  `}
`

export const MainMenu = styled(rf.Menu)`
  padding: 37px 31px 30px;

  ${breakpoint('large')`
    padding: unset;
  `}
`

export const TopBarRight = styled(rf.TopBarRight)`
  margin-right: 20px;
  margin-left: 0px;
  padding-top: 0px;
  padding-bottom: 30px;
  border-top: 1px solid #282331;

  ${breakpoint('large')`
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 0 !important;
    border-top: 0;
  `}

  ${breakpoint('medium')`
    margin-left: 20px !important;
  `}
`

export const LearnMoreButton = styled.a`
  ${Globals.proximaFontStack}
  width: 104px;
  height: 10.4px;
  top: 35.2px;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white !important;
  background-color: #8247BA;
  border-radius: 3px;
  padding: 15.2px 13px 14.4px 13px;
  margin-right: 21px;

  &:hover {
    color: white;
    background-color: ${theme.colors.backgroundPurple};
  }
`

export const Header = styled.a`
  font-size: 20px;
  margin-top: 20px;
  color: ${theme.colors.backdropPurple};
  padding-left: 5px !important;
  ${Globals.tiempoFontStack}

  &:hover {
    color: ${theme.colors.backdropPurple};
  }
`;

export const Subheading = styled.span`
  width: 308px;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  margin-right: 15px;
`;

export const MenuBar = styled.div`
  width: 22px;
  height: 0px;
  border: 1px solid ${theme.colors.nightPurple};
  margin: 6px;
`;

export const MobileMenuClose = styled.div`
  color: ${theme.colors.nightPurple};
  width: 34px;
  height: 30px;

  &:before {
    content: 'x';
  }
`;

export const ProgressBar = styled.div`
  width: 50%;
  height: 2px;
  color: red;
  background: red;
`;