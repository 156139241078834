import React, { Fragment, useState } from 'react';
import { Heading, Panel, Item } from './Accordion.style';

function Accordion({items=[], headingKey = 'heading', descriptionKey = 'description'}) {
  let [selectedItems, setSelectedItems] = useState([]);

  const onClick = (id) => {
    if (selectedItems.includes(id)) {
      let reducedSelectedItems = selectedItems.filter(_id => _id !== id)
      setSelectedItems(reducedSelectedItems)
    } else {
      setSelectedItems(selectedItems.concat(id));
    }
  };

  const renderItems = () => {
    return items.map(item => {
      return (
        <Item key={item.id}>
          <Heading onClick={() => {onClick(item.id)}} show={selectedItems.includes(item.id)}>
            {item[headingKey]}
          </Heading>
          {
            <Panel show={selectedItems.includes(item.id)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item[descriptionKey]
                }}></div>
            </Panel>
          }
        </Item>
      );
    });
  }

  return (
    <Fragment>
      { renderItems() }
    </Fragment>
  );
}

export default Accordion;
