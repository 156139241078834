import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Categories, Content, Category, Title } from './CategoriesSection.style';
import Accordion from '../../Accordion';

function CategoriesSection({categories}) {
  const renderCategories = () => {
    return categories.map((category) => {
      return (
        <Category key={category.id}>
          <Grid>
            <Cell large={4}>
              <Title>
                { category.name }
              </Title>
            </Cell>
            <Cell large={8}>
              <Accordion items={category.faqs} headingKey={'question'} descriptionKey={'answer'}/>
            </Cell>
          </Grid>
        </Category>
      )
    })
  }

  return (
    <Fragment>
      <Categories>
        <Content>
          { renderCategories() }
        </Content>
      </Categories>
    </Fragment>
  )
}

export default CategoriesSection;
