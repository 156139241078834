import React, { Fragment } from "react";
import ModalPop from "../../ModalPop/ModalPop";
import { Header, Message } from "./BarometerDownloadedPop.style";

const BarometerDownloadedPop = ({ setShowPop }) => {
  const hidePop = () => {
    setShowPop(false);
  };
  const content = (
    <Fragment>
      <Header>
        Thank you for requesting The Litigation Funding Barometer.
      </Header>
      <Message>
        Please check your inbox — a copy of the report will be emailed to you
        shortly.
      </Message>
    </Fragment>
  );

  return (
    <ModalPop
      popVisible={true}
      content={content}
      hideHandler={hidePop}
      size="large"
    />
  );
};

export default BarometerDownloadedPop;
