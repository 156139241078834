import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';


export const Section = styled.div`
  background: #282331;
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  text-align: center;
  color: white;
`;

export const Subheading = styled(Globals.P)`
  text-align: center;
  margin-bottom: 70px;
`;

export const CardList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const InvestmentContainer = styled.div`
  min-height: 100%;
  display: flex;
  padding-bottom: 30px;

  ${breakpoint('medium')`
    width: 392px;
    margin: 0 auto;
  `}

  ${breakpoint('large')`
    width: 100%;
  `}
`;

export const PrefundingNotice = styled.div`
  text-align: center;
  color: white;
  clear: both;
  font-size: 14px;

`

export const ModalHeading = styled(Globals.H2)`
  color: black !important;
  text-align: left !important;
`
