import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';

export const Section = styled.div`

`;

export const Heading = styled(Globals.H2)`
  margin-bottom: 12px;
  text-align: center !important;
`;

export const Subheading = styled(Globals.P)`
  margin-bottom: 40px;
  text-align: center;
`;

export const Step = styled.div`
  text-align: center;
  padding: 25px 40px;
  border-bottom: 1px solid #d4d4d4;

  ${breakpoint('medium')`
    border-bottom: none;
  `}

  ${breakpoint('large')`
    border-right: 1px solid #d4d4d4;
  `}
`

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}

  .cell {
    &:last-child {
      ${Step} {
        border-right: 0;
        border-bottom: 0;
      }
    }
  }
`;

export const StepNumber = styled.div`
  font-weight: 600;
  color: #8247ba;
  margin-bottom: 10px;
`

export const StepTitle = styled.div`
  ${Globals.tiempoFontStack};
  font-size: 1.8rem;
`

export const StepDescription = styled.div`

`

export const Link = styled.a`
  color: #8247ba;
  text-decoration: underline;

  &:hover, &:focus {
    color: #8247ba;
  }
`
