import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import xIcon from '../../../assets/images/icons/x-icon.png'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10,10,10,0.75);
  overflow: hide;
  display: block;
  z-index: 9999999;
  &.hidden {
    display: none;
  }
`

export const CloseIcon = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;
  display: block;
  cursor: pointer;
  background: url(${xIcon}) no-repeat center center/cover;
  height: 20px;
  width: 20px;
`

export const Container = styled.div`
  display: block;
  padding: 60px;
  background-color: ${prop => prop.color ? prop.color : 'white'};
  text-align: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ${breakpoint('medium')`
    width: 60%;
    height: unset;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  `}

  ${breakpoint('large')`
    width: 30%;
  `}

  &.large {
    ${breakpoint('medium')`
      width: 80%;
    `}
    ${breakpoint('large')`
      width: 60%;
    `}
  }

  &.full {
    padding: 0;
    ${breakpoint('medium')`
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 0;
      overflow: hidden;
    `}
  }
`
