import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H2 } from '../../Globals';

export const Quote = styled.div`
  background-color: #8247BA;
  position: relative;
`

export const QuoteProp = styled.div`
  left: 0;
  top: 40px;
  position: absolute;
  display: none;
  pointer-events: none;
  ${breakpoint('large')`
    display: block;
  `}
`;

export const Heading = styled(H2)`
  color: white;
`;

export const Person = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 20px;
  color: white;
  text-align: right;
`

export const Dash = styled.span`
  font-size: 1.2rem;
  color: #bc74ff;
  font-weight: 600;
`

export const Title = styled.div`
  width: 100%;
  text-align: right;
  color: white;
`

export const Content = styled.div`
  max-width: 1000px;
  padding: 30% 15%;
  margin: 0 auto;
  ${breakpoint('large')`
    padding: 5%;
  `}
`
