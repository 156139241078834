import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import { PersonasSection } from "../../components/funding";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import LearnMoreBanner from "../../components/funding/LearnMoreBanner/LearnMoreBanner";
import ConfirmationSection from "../../components/funding/ConfirmationSection/ConfirmationSection";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;

class GuideConfirmation extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <ConfirmationSection
            buttonPath={this.props.fundingCasePath}
            header="Your guide is on its way via email."
            subheading="In the meantime, if you have a case that may benefit from funding, consider submitting an inquiry to our Investments team."
            buttonText="Explore funding options"
          />
          <LearnMoreBanner fundingCasePath={this.props.fundingCasePath} />
          <Page>
            <PersonasSection />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default GuideConfirmation;
