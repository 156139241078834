import mixpanel from 'mixpanel-browser'

const env = process.env.NODE_ENV != 'test'

if (env) mixpanel.init(process.env.MIXPANEL_TOKEN)

let actions = {
  identify: (id) => {
    if (env) mixpanel.identify(id)
  },
  alias: (id) => {
    if (env) mixpanel.alias(id)
  },
  track: (name, props) => {
    if (env) mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      if (env) mixpanel.people.set(props)
    },
  },
}

let Mixpanel = actions

export default Mixpanel
