import React, { Fragment, useState } from 'react';
import { Form, Input, Button, Success, Icon, Heading, Description } from './DownloadForm.style';
import axios from 'axios';
import CheckImg from '../../../../assets/images/icons/check.png';

function DownloadForm({csrfToken}) {
  let [email, setEmail] = useState("");
  let [submitted, setSubmitted] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      litigation_finance_request: {
        email
      },
      authenticity_token: csrfToken
    }

    axios.post("/litigation_finance_requests", payload).then(() => {
      setSubmitted(true);
      gtag('event', 'lit-submission')
    }).catch((e) => {
      console.log(e);
    })
  }

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  }

  const renderForm = () => {
    return <Form onSubmit={onSubmit}>
      <Heading>
        Download the full guide.
      </Heading>
      <Description>
        To learn even more about litigation finance, download our full guide here:
      </Description>
      <Input type="email" onChange={onEmailChange} placeholder="Email address" required={true} />

      <Button type="submit">
        Download Guide
      </Button>
    </Form>
  }

  const renderSuccess = () => {
    return <Success>
      <Heading>
        Check your email.
      </Heading>

      <Description>
        Your litigation finance guide is on its way to you via email, and will arrive shortly.
      </Description>

      <Icon>
        <img src={CheckImg} />
      </Icon>
    </Success>
  }


  return (
    <Fragment>
      { submitted || renderForm() }
      { submitted && renderSuccess() }
    </Fragment>
  )
}

export default DownloadForm;
