import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { How, Content, Heading, Icon, Header, Summary, LinkContainer, Link } from './HowSection.style';

import SafeCaseImg from '../../../../assets/images/icons/safe-case.png';
import BusinessAreaImg from '../../../../assets/images/icons/business-area.png';
import PeopleImg from '../../../../assets/images/icons/people.png';

function HowSection() {
  return (
    <How>
      <Content>

        <Heading>
          How can law firm financing be used?
        </Heading>
        <Grid className="grid-margin-x">
          <Cell medium={4} small={12}>
            <Grid className="grid-margin-x">
              <Cell small={2} className="hide-for-medium">
                <Icon>
                  <img src={SafeCaseImg}/>
                </Icon>
              </Cell>
              <Cell small={10} medium={12}>
                <Icon className="show-for-medium">
                  <img src={SafeCaseImg}/>
                </Icon>
                <Header className="small-text-left medium-text-center">
                  Litigation Expenses
                </Header>
                <Summary className="small-text-left medium-text-center">
                  Funding can cover your hourly fees, expert witnesses, trial exhibits, and court fees.
                </Summary>
              </Cell>
            </Grid>
          </Cell>
          <Cell medium={4} small={12}>
            <Grid className="grid-margin-x">
              <Cell small={2} className="hide-for-medium">
                <Icon>
                  <img src={BusinessAreaImg}/>
                </Icon>
              </Cell>
              <Cell small={10} medium={12}>
                <Icon className="show-for-medium">
                  <img src={BusinessAreaImg}/>
                </Icon>
                <Header className="small-text-left medium-text-center">
                  Working Capital

                </Header>
                <Summary className="small-text-left medium-text-center">
                Use funding to cover firm expenses, from salaries to overhead and operations.


                </Summary>
              </Cell>
            </Grid>
          </Cell>
          <Cell medium={4} small={12}>
          <Grid className="grid-margin-x">
              <Cell small={2} className="hide-for-medium">
                <Icon>
                  <img src={PeopleImg}/>
                </Icon>
              </Cell>
              <Cell small={10} medium={12}>
                <Icon className="show-for-medium">
                  <img src={PeopleImg}/>
                </Icon>
                <Header className="small-text-left medium-text-center">
                Marketing Programs

                </Header>
                <Summary className="small-text-left medium-text-center">
                Litigation finance can help amplify marketing program development.
                </Summary>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
        <LinkContainer>
          <Link href="/guide">
            Read our litigation finance 101 guide
          </Link>
        </LinkContainer>

      </Content>
    </How>
  )
}

export default HowSection;
