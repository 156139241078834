import styled from 'styled-components';
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  padding: 7% 7%;
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const InputButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  ${breakpoint('medium')`
    width: 200px;
  `}
`;

export const ForgotPasswordLink = styled.a`
  color: ${theme.colors.teal};
`;