import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  position: relative;
  padding-top: 10%;
  background-color: black;
  margin: 0 auto;
  padding: 1% 0 0;
`

export const Button = styled.button`
  min-width: unset !important;
  ${breakpoint('medium')`
    min-width: 200px !important;
  `}
`

export const Content = styled.div`
  padding-bottom: 0;
  max-width: 1235px;
  margin: 0 auto;
  margin-top: 2%;
  padding: 10% 7% 0 7%;

  ${breakpoint('medium')`
    padding-bottom: 75px;
    padding: 5% 2%;
  `};
`

export const InfoContainer = styled.div`
  margin-bottom: 45px;
  margin-top: 30px;
  ${breakpoint('large')`
    max-width: 490px;
    margin-bottom: 90px;
  `}
`

export const H1 = styled.h1`
  color: white;
  line-height: 1.2em;
  margin: 85px 0px 30px 0px;
  display: none;

  ${breakpoint('large')`
    display: block;
    font-size: 48px;
    line-height: 58px;
  `}
`

export const H1Secondary = styled.h1`
  color: white;
  line-height: 1.2em;
  margin-bottom: 32px;
  display: block;
  text-align: center;
  ${breakpoint('large')`
    display: none;
  `}
`

export const P = styled.p`
  color: white;
`

export const FormContainer = styled.div`
  padding: 15px 25px 25px 25px;
  background-color: white;
  border-radius: 8px;

  ${breakpoint('medium')`
    padding: 30px 50px 43px 50px;
  `}
`

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SmallP = styled.p`
  font-size: .7em;
  color: white;
`

export const Submit = styled.button`
  min-width: 200px;
`

export const EmailLink = styled.a`
  color: white;

  &:hover {
    color: white;
  }
`;
