import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Banner, Content, Heading, Label, Vs, Background } from './ShowBannerSection.style';

function ShowBannerSection({caseStudy}) {
  return (
    <Fragment>
      <Banner>
        <Background style={{backgroundImage: `linear-gradient(rgba(20, 14, 27, 0.55),rgba(20, 14, 27, 0.55)), url('${caseStudy.banner}')`}} />
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Label>
                { caseStudy.type }
              </Label>
              <Heading>
                { caseStudy.plaintiff_name }
              </Heading>
              <br />
              <Vs>
                vs.
              </Vs>
              <br />
              <Heading>
                { caseStudy.defendant_name }
              </Heading>
            </Cell>
          </Grid>
        </Content>
      </Banner>
    </Fragment>
  )
}

export default ShowBannerSection;
