import React from "react";
import { Grid, Cell } from "react-foundation";
import {
  FooterContainer,
  Content,
  Logo,
  Header,
  Links,
  Link,
  Socials,
  SocialLink,
  Terms,
  TermLink,
  TermSeparator,
  Navigator,
  FooterLegal,
  FooterDescription,
  FooterLink,
} from "./Footer.style";
import LexsharesLogo from '../../../assets/images/logos/lexshares.png';
import FbIcon from '../../../assets/images/icons/facebook-white.png';
import TwitterIcon from '../../../assets/images/icons/twitter-white.png';
import LinkedInIcon from '../../../assets/images/icons/linkedin-white.png';
import AngelIcon from '../../../assets/images/icons/angel-white.png';

function Footer({ bgColor, attorneyPage = false }) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <FooterContainer bgColor={bgColor} className="js-footer">
        <Navigator onClick={() => scrollTop()} />
        <Content>
          <Grid className="grid-margin-x">
            <Cell
              medium={12}
              large={3}
              className="medium-order-2 small-order-2 large-order-1"
            >
              <Logo>
                <a href="/">
                  <img src={LexsharesLogo} />
                </a>
              </Logo>
              <Grid className="grid-margin-x">
                <Cell small={6} medium={3} large={12}>
                  33 Whitehall Street
                  <br />
                  16th Floor
                  <br />
                  New York, NY 10004
                </Cell>
              </Grid>
            </Cell>
            <Cell small={6} medium={4} className="medium-order-1 large-auto">
              <Header>Funding</Header>
              <Links>
                <Link href="/funding/plaintiffs">Corporate Plaintiffs</Link>
                <Link href="/funding/attorneys">Attorney &amp; Law Firms</Link>
                <Link href="/funding/general-counsel">In-House Counsel</Link>
              </Links>
            </Cell>
            <Cell small={6} medium={4} className="medium-order-1 large-auto">
              <Header>Investors</Header>
              <Links>
                <Link href="/invest">Start Investing</Link>
                <Link href="/cases">Case Portfolio</Link>
              </Links>
            </Cell>
            <Cell small={6} medium={4} className="medium-order-1 large-auto">
              <Header>Resources</Header>
              <Links>
                <Link href="/case-studies">Case Studies</Link>
                <Link href="/litigation-finance-101">
                  Litigation Finance 101
                </Link>
                <Link href="/resources">Attorney Resources</Link>
                <Link href="/blog" target="_blank">
                  Firm &amp; Market News
                </Link>
              </Links>
            </Cell>
            <Cell small={6} medium={4} className="medium-order-1 large-auto">
              <Header>Company</Header>
              <Links>
                <Link href="/about">About Us</Link>
                <Link href="/press">Press</Link>
                <Link href="/faqs">FAQ</Link>
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/company/lexshares-inc-/jobs/"
                >
                  Careers
                </Link>
              </Links>
            </Cell>
            <Cell small={6} medium={4} className="medium-order-1 large-auto">
              <Header>Contact</Header>
              <Links>
                <Link href="tel:(877) 290-4443">(877) 290-4443</Link>
                <Link href="mailto:info@lexshares.com">info@lexshares.com</Link>
              </Links>
            </Cell>
          </Grid>
          <Grid className="grid-margin-x">
            <Cell small={12} medium={4}>
              <Socials className="js-social-icons">
                <SocialLink
                  href="https://www.facebook.com/lexshares"
                  target="_blank"
                >
                  <img src={FbIcon} />
                </SocialLink>
                <SocialLink
                  href="https://twitter.com/lexshares"
                  target="_blank"
                >
                  <img src={TwitterIcon} />
                </SocialLink>
                <SocialLink
                  href="https://www.linkedin.com/company/lexshares-inc-"
                  target="_blank"
                >
                  <img src={LinkedInIcon} />
                </SocialLink>
                <SocialLink href="https://angel.co/lexshares-1" target="_blank">
                  <img src={AngelIcon} />
                </SocialLink>
              </Socials>
            </Cell>
            <Cell small={12} medium={8}>
              <Terms>
                <TermLink href="/terms-of-use">Terms of Use</TermLink>
                <TermSeparator>|</TermSeparator>
                <TermLink href="/privacy-policy">Privacy Policy</TermLink>
              </Terms>
            </Cell>
          </Grid>
        </Content>
      </FooterContainer>
      <FooterLegal bgColor={bgColor} className="js-footer-legal">
        <Content>
          <FooterDescription>
            IMPORTANT MESSAGE: www.LexShares.com (the “Site”) is a website owned
            and operated by LexShares, Inc. (“LexShares”). By accessing this
            Site and any pages on this Site, you agree to be bound by its{" "}
            <FooterLink href="/terms-of-use">Terms of Use</FooterLink> and{" "}
            <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>, as
            may be amended from time to time without notice or liability.
            Investment opportunities posted on the Site are indirect investments
            in legal claims offered through single purpose pooled investment
            funds managed by LawShares LLC and sold through{" "}
            <FooterLink href="http://www.wealthforgellc.com/">
              WealthForge Securities, LLC
            </FooterLink>
            , a registered broker-dealer and member{" "}
            <FooterLink href="http://www.finra.org/">FINRA</FooterLink> /{" "}
            <FooterLink href="http://www.sipc.org/">SIPC</FooterLink>.
            WealthForge Securities, LLC and LexShares, Inc are separate
            entities. Please find WealthForge Securities privacy policy{" "}
            <FooterLink href="http://d82jt2cluomp.cloudfront.net/docs/wf-privacy-notice.pdf">
              here
            </FooterLink>
            . Certain employees of LexShares Inc are registered representatives
            of WealthForge Securities LLC (check the background of this firm on{" "}
            <FooterLink href="http://brokercheck.finra.org">
              FINRA&apos;s BrokerCheck
            </FooterLink>
            ). LexShares is not an investment advisor, broker-dealer or
            crowdfunding portal and does not engage in any activities requiring
            any such registration. The securities offerings posted on the Site
            are available to{" "}
            <FooterLink href="http://www.sec.gov/answers/accred.htm">
              Accredited Investors
            </FooterLink>{" "}
            only.
          </FooterDescription>
          <FooterDescription>
            All accredited investors using the Site must acknowledge the
            speculative nature of these investments and accept the high risks
            associated with investing in legal claims including but not limited
            to concentration risk, lack of control over the prosecution of
            underlying claims and claimant&apos;s inability to assert and
            collect on their claims. Investment opportunities posted on this
            Site are “private placements” of securities that are not publicly
            traded, are subject to holding period requirements, and are intended
            for investors who do not need a liquid investment. The plaintiff may
            not prevail in their lawsuit, resulting in a loss of invested
            capital for investors. Investors must be able to afford the loss of
            their entire investment without a change to their lifestyle.
            Diversification does not guarantee profits or protect against
            losses. The securities are offered pursuant to an exemption from the
            registration requirements of the Securities Act of 1933, as amended,
            and are not required to comply with specific disclosure requirements
            that apply to registration under the Securities Act. Neither the US
            Securities and Exchange Commission nor any state regulator or other
            regulatory body has passed upon the merits of or given its approval
            to the securities, the terms of the offerings, or the accuracy or
            completeness of any offering materials. Neither LexShares nor any of
            its directors, officers, employees, representatives, affiliates or
            agents shall have any liability whatsoever arising, for any error or
            incompleteness of fact or opinion in, or lack of care in the
            preparation or publication, of the materials and communication
            herein or the or that the valuation of any securities offering is
            appropriate. LexShares does not give investment advice, provide
            analysis or recommendations regarding any offering posted on the
            Site. Prior results are not indicative of future performance; actual
            results may vary materially. Any testimonials are examples from
            clients or press, and may not be representative of your experience.
            They are not a guarantee of performance and they have not been
            compensated for their testimonials. Articles or information from
            third-party media outside of this domain may discuss LexShares or
            relate to information contained herein, but LexShares does not
            approve and is not responsible for such content. Hyperlinks to
            third-party sites, or reproduction of third-party articles, do not
            constitute an approval or endorsement by LexShares of the linked or
            reproduced content. The Site may contain “forward looking
            statements” which are not guaranteed. IRR reflects the annualized
            rate of return per the xirr Excel function. All investors should
            make their own determination of whether or not to make any
            investment, based on their own independent evaluation and analysis.
            You are strongly advised to consult your legal, tax and financial
            advisors before investing. The securities offered on this Site can
            only be marketed in jurisdictions where public solicitation of
            offerings are permitted; it is solely your responsibility to comply
            with the laws and regulations of your country of residence.
          </FooterDescription>
          <FooterDescription>
            Learn more about investing in private placements from the{" "}
            <FooterLink href="http://www.sec.gov/investor/alerts/ia_solicitation.pdf">
              SEC
            </FooterLink>
            ,{" "}
            <FooterLink href="http://www.finra.org/investors/protectyourself/investoralerts/privateofferings/p339650">
              FINRA
            </FooterLink>{" "}
            or{" "}
            <FooterLink href="/LS_financing_model.pdf">
              LexShares’ specific financing model
            </FooterLink>
            .
          </FooterDescription>
          {attorneyPage && (
            <FooterDescription>
              Chambers & Partners is a third-party provider of legal research
              and analysis. Chambers rankings are determined via independent
              research and client feedback.
            </FooterDescription>
          )}
          <FooterDescription>
            © 2023 LexShares, Inc. All rights reserved.
          </FooterDescription>
        </Content>
      </FooterLegal>
    </>
  );
}

export default Footer;
