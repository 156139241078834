import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { H1, Description } from './../../Globals'

export const Section = styled.div`
  padding-top: 0%;
  background: ${props => props.theme.colors.backdropPurple};
  ${breakpoint('medium')`
    padding-top: 10%;
  `}
`

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}

  .grid-x:nth-child(1) .small-order-2 > h1 {
    display: none;
    ${breakpoint('large')`
      display: block;
    `}
  }

  .grid-x:nth-child(1) .small-order-1 > h1 {
    display: block;
    ${breakpoint('large')`
      display: none;
    `}
  }
`

export const Heading = styled(H1)`
  color: white;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 10%;
  ${breakpoint('medium')`
    margin-top: 0;
  `}
  ${breakpoint('large')`
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
  `}
`

export const Tagline = styled(Description)`
  color: white;
  margin-top: 35px;
  margin-bottom: 25px;
`

export const Diamonds = styled.div`
  position: absolute;
  right: 0;
  opacity: 0.2;
  margin-top: -300px;
  z-index: 0;
`
