import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import UpArrow from '../../../assets/images/icons/up-arrow.png'
import theme from '../../config/theme';

export const FooterContainer = styled.div`
  background-color: ${props => theme.colors[props.bgColor || 'nightPurple']};
  color: white;
  text-align: left;
  overflow: hidden;
  border-top: 1px solid #221d2a;
`

export const Content = styled.div`
  margin: 0 auto;
  padding: 10% 7%;
  max-width: 1235px;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`

export const Navigator = styled.div`
  display: block;
  position: absolute;
  margin-top: -23px;
  z-index: 0;
  margin-left: 50%;
  left: -24px;
  cursor: pointer;
  height: 46px;
  width: 46px;
  background-image: url(${UpArrow});
  background-size: contain;
  background-repeat: no-repeat;
  ${breakpoint('medium')`
    &:hover {
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
`

export const Logo = styled.div`
  margin-bottom: 40px;
  text-align: center;
  ${breakpoint('medium')`
    text-align: left;
    margin-bottom: 20px;
  `}
  img {
    max-width: 150px;
    margin-top: 40px;
    ${breakpoint('large')`
      margin-top: 0;
    `}
  }
`

export const Header = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 20px;
  ${breakpoint('medium')`
    margin-top: 47px
  `}
`

export const Links = styled.div`
  line-height: 1.6;
`

export const Link = styled.a`
  color: white;
  display: block;
  line-height: inherit;
  &:hover, &:active, &:focus {
    color: white;
  }
`

export const Spacer = styled.div`
  clear: both;
  margin-bottom: 24px;
  display: block;
`

export const Socials = styled.div`
  list-style-type: none;
  margin-top: 30px;
  position: relative;
  left: 0;
  margin-left: 0;
  text-align: center;
  ${breakpoint('medium')`
    text-align: left;
  `}
`

export const SocialLink = styled.a`
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  img {
    max-height: 20px;
    max-width: 20px;
  }
`

export const Terms = styled.div`
  margin-top: 30px;
  text-align: center;
  list-style-type: none;
  position: relative;
  ${breakpoint('medium')`
    float: right;
    margin-left: 0;
    left: 0;
  `}
`

export const TermSeparator = styled.div`
  color: white;
  margin: 3px;
  font-weight: 400;
  display: inline-block;
`

export const TermLink = styled.a`
  color: white;
  text-transform: uppercase;
  font-size: .8rem;
  color: white;
  margin: 3px;
  font-weight: 400;
  &:hover, &:active, &:focus {
    color: white;
  }
`

export const FooterLegal = styled.div`
  background-color: ${props => theme.colors[props.bgColor || 'black']};
`

export const FooterLink = styled.a`
  color: #a5a4a4;
  &:hover, &:active, &:focus {
    color: #a5a4a4;
  }  
`

export const FooterDescription = styled.div`
  font-size: 12px;
  color: #a5a4a4;
  font-size: 12px;
  line-height: 1.6;
  font-weight: 400;
  -webkit-font-smoothing: auto;
  margin-bottom: 16px;
`
