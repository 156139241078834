import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import ContentSection from '../../components/raise-funds/ContentSection';
import theme from '../../config/theme';
import NewNavbar from '../../components/Navbar/NewNavbar'
import Footer from '../../components/Footer'

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`

class RaiseFundsNew extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <ContentSection csrfToken={this.props.csrfToken}></ContentSection>
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default RaiseFundsNew;
