import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../../Globals'

export const Heading = styled(Globals.H1)`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
`

export const PaymentMethodContainer = styled.div`
  margin-top: 20px;
  ${breakpoint('medium')`
    float: right;
    margin-top: 0;
  `}
`

export const OptionButton = styled(Globals.ButtonLink)`
  background: #d4d4d4;
  padding: 13px 24px 13px 24px;
  width: 100%;
  &:first-of-type {
    margin: 0 10px 0 0;
  }
  &:hover {
    background-color: ${props => props.theme.colors.purple};
  }
  &.selected {
    background-color: ${props => props.theme.colors.purple};
  }
  margin-bottom: 10px !important;
  ${breakpoint('medium')`
    margin-bottom: 0 !important;
    width: unset;
  `}
`

export const Label = styled.label`
  text-color: black;
  font-size: 15px;
`

export const PaymentOption = styled.div`
  margin-top: 40px;
`
