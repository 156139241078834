import React, {Fragment, useState, useEffect} from 'react'
import Mixpanel from '../../../../Mixpanel'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  TitleBar,
  Logo,
  Title,
  DateStamp,
  Image,
  Container,
  Heading,
  Text
} from './PressWidget.style'

/**
 * Takes in an article ID and returns article summary and associated image.
 */
function PressWidget({articleId}) {
  let [article, setArticle] = useState(null);

  useEffect(() => {
    axios.get(`/widget/articles/${articleId}.json`).then(res => {
      setArticle(res.data)
    })
  }, [])

  const handleClick = () => {
    // Opens article in a new window.
    window.open(`/press`)

    // Tracks click via Mixpanel.
    Mixpanel.track('Clicked content widget', {
      contentType: 'Article',
      contentDate: article.display_date_stamp,
      contentTitle: article.title
    })
  }

  return (
    <Widget onClick={handleClick} className="js-widget js-article-widget">
      <Content loading={article ? "false" : "true"}>
        { article &&
          <Fragment>
            <TitleBar>
              <Logo />
              <Title>LexShares in the News</Title>
              <DateStamp>{article.display_date_stamp}</DateStamp>
            </TitleBar>
            <Image source={article.banner_link}/>
            <Container>
              <Heading>
                {article.title}
              </Heading>
              <Text>
                {article.body}
              </Text>
            </Container>
          </Fragment>
        }
        </Content>
    </Widget>
  )
}

export default PressWidget
