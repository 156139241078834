import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const RiskLabelSpacer = styled.div`
  ${breakpoint('medium')`
    margin-top: 30px;
  `}
`

export const RateLabelSpacer = styled.div`
  ${breakpoint('medium')`
    margin-top: 55px;
  `}
`
