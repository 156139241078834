import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, Heading, Subheading, HeadlineCard,
  CardSection, CardHeader, CardDescription, Icon, Button
} from './HowItWorksSection.style';
import PortfolioImg from '../../../../assets/images/icons/portfolio.png';
import WebBanking from '../../../../assets/images/icons/web-banking.png';

function HowItWorksSection() {
  return (
    <Fragment>
      <Section className="js-how-it-works">
        <Content>
          <Grid>
            <Cell small={12}>
              <Heading>
                How we invest.
              </Heading>
              <Subheading>
                LexShares uses a multi-channel investment approach to ensure speed and certainty of funds.
              </Subheading>

              <HeadlineCard>
                <Grid>
                  <Cell large={6} small={12}>
                    <CardSection>
                      <Grid>
                        <Cell small={12} medium={2}>
                          <Icon>
                            <img src={PortfolioImg} />
                          </Icon>
                        </Cell>
                        <Cell small={12} medium={10}>
                          <CardHeader>
                            LexShares Marketplace Fund
                          </CardHeader>
                          <CardDescription>
                            In June 2020, LexShares launched its second privately managed fund, a $100 million fully discretionary litigation finance fund that gets deployed into each legal investment made by LexShares.
                          </CardDescription>
                        </Cell>
                      </Grid>
                    </CardSection>
                  </Cell>
                  <Cell large={6} small={12}>
                    <CardSection>
                      <Grid>
                        <Cell small={12} medium={2}>
                          <Icon>
                            <img src={WebBanking} />
                          </Icon>
                        </Cell>
                        <Cell small={12} medium={10}>
                          <CardHeader>
                            LexShares Funding Platform
                          </CardHeader>
                          <CardDescription>
                            LexShares operates an online platform that enables institutional and individual accredited investors to participate in certain funding opportunities. Offerings are typically fully funded by LexShares prior to being made available to investors.
                          </CardDescription>
                        </Cell>
                      </Grid>
                    </CardSection>
                  </Cell>
                </Grid>
              </HeadlineCard>
            </Cell>
          </Grid>

          <Grid className="align-center">
            <Button href="/funding/case">
              Explore Funding Options
            </Button>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default HowItWorksSection;
