import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'
import ReactTooltip from 'react-tooltip'
import {
  Label,
  InputContainer,
  Input,
  Hint,
  Error,
  Textarea
} from './InputField.style'

function InputField({
  name, type = 'text', label, labelColor, hint, changeHandler, error, range,
  placeholder, value, format, tooltip, mask, onBlur, autoComplete = 'on'}) {

  const inputLabel = label != null ?
      <Label data-tip={tooltip} className={tooltip ? 'tip' : ''} labelColor={labelColor}>
        {label}
      </Label>
    :
      null
  const inputHint = hint != null ? <Hint>{hint}</Hint> : null

  return (
    <Fragment>
      {inputLabel}
      { tooltip &&
        <ReactTooltip />
      }
      <InputContainer>
        {(() => {
          switch(type) {
            case 'currency':
              return (
                <NumberFormat
                  prefix={'$'}
                  onChange={changeHandler}
                  thousandSeparator={true}
                  name={name}
                  className={error ? 'error' : ''}
                  placeholder="$"
                  value={value}
                  onBlur={onBlur}
                  defaultValue={value}
                  data-range={range ? range : null}
                />);
            case 'number':
              return (
                <NumberFormat
                  prefix={''}
                  onChange={changeHandler}
                  thousandSeparator={false}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  className={error ? 'error' : ''}
                  placeholder={placeholder}
                  format={format}
                  data-range={range ? range : null}
                  mask={mask}
                />
              );
            case 'textarea':
              return (
                <Textarea
                  as="textarea"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={changeHandler}
                  placeholder={placeholder}
                  className={error ? 'error' : ''}
                />
              )
            default:
              return (
                <Input
                  type={type}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={changeHandler}
                  className={error ? 'error' : ''}
                  placeholder={placeholder}
                  autocapitalize="none"
                  novalidate
                  autoComplete={autoComplete}
                />);
          }
        })()}
      </InputContainer>
      {error && (
        <Error>
          {error}
        </Error>
      )}
      {inputHint}
    </Fragment>
  )
}

export default InputField
