import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as Globals from "../../Globals";
import theme from "../../../config/theme";

export const Container = styled.div`
  padding: 20px 20px 20px 20px;

  ${breakpoint("medium")`
    padding: 20px 80px 80px 80px;
  `}
`;

export const Heading = styled(Globals.H6)`
  font-size: 19px;
  line-height: 32px !important;
  font-weight: bold !important;
  color: ${theme.colors.backdropPurple};
  margin-bottom: 20px;

  ${breakpoint("medium")`
    margin-bottom: 40px;
  `}
`;
