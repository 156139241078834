import React, {useEffect, useState} from 'react'
import { Menu } from 'react-foundation'
import {
  TopBarLogo, MenuContainer, MenuLink, MenuItem, MainMenu, SubMenuLink,
  SubMenuItem, SubMenu, TopBar, LogoLink, MobileTopBar, TopBarRight,
  SignUpButton, MenuLinkRight, MenuItemRight, TopBarLogoRight,
  MenuButton, TopBarLeft, PrimaryButton, SecondaryButton, MenuRight
} from './Navbar.style';
import StaticImage from '../StaticImage';
import SlideDown from '../SlideDown';
import SignUpModal from './components/SignUpModal'
import LogInModal from './components/LogInModal'

function Navbar({userSignedIn, currentRole, isInvestor, roleDashboardPath, roleProfilePath, name, csrfToken, theme, hasTaxDocs}) {
  const [active, setActive] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [trueWhite, setTrueWhite] = useState(false)
  const [alertPresent, setAlertPresent] = useState(false)
  const [signUpVisible, setSignUpVisible] = useState(false)
  const [logInVisible, setLogInVisible] = useState(false)

  const getType = () => {
    if (scrolled) { return "scrolled" }
    if (trueWhite) { return "trueWhite" }
    return theme
  }

  const openSignUpModal = (e) => {
    e.preventDefault()
    setSignUpVisible(true)
  }

  const openLogInModal = (e) => {
    e.preventDefault()
    setLogInVisible(true)
  }

  const handleScroll = () => {
    let scrollTop = $(document).scrollTop()
    if(scrollTop > 80 && !scrolled) {
      setScrolled(true)
    }
    if(scrollTop == 0 && scrolled) {
      setScrolled(false)
    }
  }

  const handleChange = () => {
    if ($('.full-screen-white-modal').length > 0) {
      setTrueWhite(true)
    }
    else {
      setTrueWhite(false)
    }
  }

  const signOut = () => {
    fetch('/users/sign_out', {
      method: 'DELETE',
      redirect: 'follow',
      credentials: 'include',
      headers: {
        "X-CSRF-Token": csrfToken
      },
    }).then((res)=> {
      window.location = res.url;
    })
  }

  const toggleAlertPadding = () => {
    if($('.alert').length > 0) {
      setAlertPresent(true)
    } else {
      setAlertPresent(false)
    }
  }

  useEffect(() => {
    $('.top-bar').foundation()
    setInterval(() => {
      toggleAlertPadding()
    }, 100)
  }, [])

  const toggleMenu = (val) => {
    setActive(val)
    if(val) {
      $('body').css('overflow', 'hidden')
    } else {
      $('body').css('overflow', 'visible')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [scrolled])

  useEffect(() => {
    let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    let observer = new MutationObserver(handleChange);
    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  }, [trueWhite])

  return (
    <>
      {signUpVisible && <SignUpModal hideHandler={() => setSignUpVisible(false)} /> }
      {logInVisible && <LogInModal csrfToken={csrfToken} hideHandler={() => setLogInVisible(false)} />}
      <MenuContainer>
        <MobileTopBar shown={active.toString()}>
          <TopBarLogo>
            <Menu>
              <LogoLink type={getType()} href="/" />
            </Menu>
          </TopBarLogo>
          <TopBarLogoRight>
            { active || <MenuButton onClick={() => toggleMenu(true)} >
              <StaticImage src="icons/menu.png" />
            </MenuButton> }
            { active && <MenuButton onClick={() => toggleMenu(false)}>
              <StaticImage src="icons/white-close.png" />
            </MenuButton> }
          </TopBarLogoRight>
        </MobileTopBar>

        <SlideDown show={active}>
          <TopBar type={getType()} margin={alertPresent.toString()}>
            <TopBarLogo>
              <Menu>
                <LogoLink type={getType()} href="/" />
              </Menu>
            </TopBarLogo>

            <TopBarLeft full={userSignedIn.toString()} >
              <MainMenu isDropdown={true} isVertical={true} data-multi-open="false" data-responsive-menu="accordion large-dropdown" className="large-horizontal">
                <MenuItem>
                  <MenuLink type={getType()}>
                    Funding
                    <StaticImage src="icons/skinny-arrow.png" />
                  </MenuLink>
                  <SubMenu isVertical={true}>
                    <SubMenuItem>
                      <SubMenuLink href="/funding/plaintiffs">
                        Corporate Plaintiffs
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/funding/attorneys">
                        Attorneys &amp; Law Firms
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/funding/general-counsel">
                        In-House Counsel
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                  </SubMenu>
                </MenuItem>
                <MenuItem>
                  <MenuLink type={getType()}>
                    Investors
                    <StaticImage src="icons/skinny-arrow.png" />
                  </MenuLink>
                  <SubMenu isVertical={true}>
                    <SubMenuItem>
                      <SubMenuLink href="/invest">
                        Start Investing
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/cases">
                        Case Portfolio
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/track-record">
                        Track Record
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                  </SubMenu>
                </MenuItem>
                <MenuItem>
                  <MenuLink type={getType()}>
                    Resources
                    <StaticImage src="icons/skinny-arrow.png" />
                  </MenuLink>
                  <SubMenu isVertical={true}>
                    <SubMenuItem>
                      <SubMenuLink href="/case-studies">
                        Case Studies
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/litigation-finance-101">
                        Litigation Finance 101
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/resources">
                        Attorney Resources
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/blog" target="_blank">
                        Firm &amp; Market News
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                  </SubMenu>
                </MenuItem>
                <MenuItem>
                  <MenuLink type={getType()}>
                    Company
                    <StaticImage src="icons/skinny-arrow.png" />
                  </MenuLink>
                  <SubMenu isVertical={true}>
                    <SubMenuItem>
                      <SubMenuLink href="/about">
                        About Us
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/press">
                        Press
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="/faqs">
                        FAQ
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuLink href="https://www.linkedin.com/company/lexshares-inc-/jobs/" target='_blank'>
                        Careers
                        <StaticImage src="icons/skinny-arrow.png" />
                      </SubMenuLink>
                    </SubMenuItem>
                  </SubMenu>
                </MenuItem>
                {
                  userSignedIn && <MenuItem className="hide-for-large">
                    <MenuLink type={getType()}>
                      { name }
                      <StaticImage src="icons/skinny-arrow.png" />
                    </MenuLink>
                    <SubMenu isVertical={true}>
                      {
                        isInvestor && currentRole && <SubMenuItem>
                          <SubMenuLink href={roleProfilePath}>
                            My Profile
                            <StaticImage src="icons/skinny-arrow.png" />
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      {
                        isInvestor && currentRole && hasTaxDocs && <SubMenuItem>
                          <SubMenuLink href="/tax-center">
                            Tax Center
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      {
                        currentRole && <SubMenuItem>
                          <SubMenuLink href={roleDashboardPath}>
                            Dashboard
                            <StaticImage src="icons/skinny-arrow.png" />
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      <SubMenuItem>
                        <SubMenuLink onClick={() => signOut()}>
                          Log Out
                          <StaticImage src="icons/skinny-arrow.png" />
                        </SubMenuLink>
                      </SubMenuItem>
                    </SubMenu>
                  </MenuItem>
                }
              </MainMenu>
            </TopBarLeft>
            {
              userSignedIn && <TopBarRight className="show-for-large">
                <Menu isDropdown={true} isVertical={true} data-responsive-menu="accordion large-dropdown" className="large-horizontal">
                  <MenuItem>
                    <MenuLink type={getType()}>
                      { name }
                    </MenuLink>
                    <SubMenu isVertical={true}>
                      {
                        isInvestor && currentRole && <SubMenuItem>
                          <SubMenuLink href={roleProfilePath}>
                            My Profile
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      {
                        isInvestor && currentRole && hasTaxDocs && <SubMenuItem>
                          <SubMenuLink href="/tax-center">
                            Tax Center
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      {
                        currentRole && <SubMenuItem>
                          <SubMenuLink href={roleDashboardPath}>
                            Dashboard
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      <SubMenuItem>
                        <SubMenuLink onClick={() => signOut()} >
                          Log Out
                        </SubMenuLink>
                      </SubMenuItem>
                    </SubMenu>
                  </MenuItem>
                </Menu>
              </TopBarRight>
            }
            <TopBarRight>
              <MenuRight>
              { userSignedIn || <MenuItemRight className="show-for-large">
                <MenuLinkRight type={getType()} href="tel:(877) 290-4443">
                (877) 290-4443
                </MenuLinkRight>
              </MenuItemRight> }

                { userSignedIn || <MenuItemRight className="show-for-large">
                  <MenuLinkRight type={getType()} onClick={(e) => openLogInModal(e)}>
                    Log in
                  </MenuLinkRight>
                </MenuItemRight> }

                { userSignedIn || <MenuItemRight className="show-for-large">
                  <SignUpButton type={getType()} onClick={(e) => openSignUpModal(e)}>
                    Sign Up
                  </SignUpButton>
                </MenuItemRight> }

                { userSignedIn || <MenuItemRight className="hide-for-large">
                  <PrimaryButton onClick={(e) => openSignUpModal(e)}>
                    Sign Up
                  </PrimaryButton>
                </MenuItemRight> }

                { userSignedIn || <MenuItemRight className="hide-for-large">
                  <SecondaryButton onClick={(e) => openLogInModal(e)}>
                    Log in
                  </SecondaryButton>
                </MenuItemRight> }

                { userSignedIn && <MenuItemRight className="hide-for-large">
                  <SecondaryButton onClick={() => signOut()}>
                    Log out
                  </SecondaryButton>
                </MenuItemRight> }
              </MenuRight>
            </TopBarRight>
          </TopBar>
        </SlideDown>
      </MenuContainer>
    </>
  )
}

export default Navbar;
