import styled from 'styled-components';
import * as Globals from './../../Globals';

export const Icon = styled.div`
  width: 40px;
  margin: 0 auto 15px;
`

export const Heading = styled(Globals.Body)`
  font-weight: 600;
  margin: 50px 0 0;
  color: ${props => props.theme.colors.black};
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #18214D;
`

export const Description = styled(Globals.Description)`
  margin-bottom: 20px;
  color: ${props => props.theme.colors.black};
`

export const Link = styled.div`
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #9450D4;
  font-weight: bold;

  &:hover, &:visited {
    color: #9450D4;
  }
`

export const SimpleCardContainer = styled.a`
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 60px 30px 50px;
  display: block;
  transition: all .5s ease;
  position: relative;
  min-height: 270px;
  background: ${props => props.theme.colors.white};
  color: inherit;
  height: 100%;
  &:visited {
    color: inherit;
  }

  &:hover {
    transition: all .4s ease;
    transform: translate3D(0,-1px,0) scale(1.02);
    border-color: ${props => props.theme.colors.purple};

    ${Heading} {
      color: ${props => props.theme.colors.purple};
    }

    ${Link} {
      color: ${props => props.theme.colors.purple};

      &::after {
        color: ${props => props.theme.colors.purple};
      }
    }
  }
`
