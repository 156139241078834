import styled from "styled-components";
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals';
import theme from "../../../config/theme";

export const Container = styled.div`
  padding: 24px 0px 126px 0px;
  min-height: 0px;

  ${breakpoint('medium')`
    padding: 37px 40px 126px 40px;
    min-height: 500px;
  `}

  ${breakpoint('large')`
    padding: 37px 131px 126px 40px;
  `}
`;

export const Heading = styled.h1`
  ${Globals.tiempoFontStack};
  color: ${theme.colors.backdropPurple};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130%;
  text-align: left;
  padding: 0px 20px 0px 20px;

  ${breakpoint('medium')`
    font-size: 56px;
    line-height: 64px;
    padding: 0px;
  `}
`;

export const Subheading = styled.h2`
  ${Globals.proximaFontStack};
  font-style: normal;
  font-weight: normal;
  margin: 10px 0px 24px 0px;
  text-align: left;
  font-size: 18px;
  line-height: 130%;
  padding: 0px 20px 0px 20px;

  ${breakpoint('medium')`
    font-size: 24px;
    line-height: 36px;
    margin: 10px 0px 40px 0px;
    padding: 0px;
  `}
`;