import React from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import { NavSection, OverviewSection } from "../../components/bio";
import Footer from "../../components/Footer";
import NewNavbar from "../../components/Navbar/NewNavbar";

const BioPage = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

class Bio extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <NewNavbar
          theme="trueWhite"
          roleDashboardPath={this.props.roleDashboardPath}
          roleProfilePath={this.props.roleProfilePath}
          userSignedIn={this.props.userSignedIn}
          isInvestor={this.props.isInvestor}
          currentRole={this.props.isInvestor}
          name={this.props.name}
          csrfToken={this.props.csrfToken}
        />
        <BioPage>
          <OverviewSection employee={this.props.employee} profile_image={this.props.profile_image}/>
          <NavSection employee={this.props.employee} />
        </BioPage>
        <Footer />
      </ThemeProvider>
    );
  }
}

export default Bio;
