import React from 'react'
import IndividualForm from './IndividualForm'
import TrustForm from './TrustForm'
import EntityForm from './EntityForm'
import { Heading } from './FundingForm.style'
import { Grid, Cell } from 'react-foundation'

function FundingForm({csrfToken, investor}) {
  return (
    <>
      <Grid>
        <Cell small={12}>
          <Heading> Individual </Heading>
          <IndividualForm csrfToken={csrfToken} investor={investor} />
        </Cell>
      </Grid>
      { investor.trust && <Grid className="pt-8">
          <Cell small={12}>
            <Heading> Trust </Heading>
            <TrustForm csrfToken={csrfToken} investor={investor} />
          </Cell>
        </Grid>
      }
      { investor.entity && <Grid className="pt-8">
          <Cell small={12}>
            <Heading> Entity </Heading>
            <EntityForm csrfToken={csrfToken} investor={investor} />
          </Cell>
        </Grid>
      }
    </>
  )
}

export default FundingForm
