import styled from 'styled-components'
import * as Globals from '../../../Globals'
import bulb from '../../../../../assets/images/investor_dashboard/bulb.png'

export const Icon = styled.div`
  background: url(${bulb}) no-repeat;
  background-size: contain;
  height: 40px;
  margin-bottom: 10px;
`

export const Widget = styled(Globals.Widget)`
  padding: 21px 31px;
  background-color: #1E1924;
`

export const Heading = styled.p`
  color: white;
  font-size: 16px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 15px;
`

export const Question = styled(Globals.Body)`
  font-weight: 400;
  margin-top: 7px;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
  color: white;
  font-size: 16px;

  &::before {
    position: absolute;
    right: -20px;
    top: 8px;
    content: ' ';
    width: 12px;
    height: 12px;
    border-top: 2px solid #8247BA;
    border-left: 2px solid #8247BA;
    transform: rotate(45deg);
    display: ${props => props.show ? 'block' : 'none'}
  }

  &::after {
    position: absolute;
    right: -20px;
    top: 3px;
    content: ' ';
    width: 12px;
    height: 12px;
    border-top: 2px solid #8247BA;
    border-left: 2px solid #8247BA;
    transform: rotate(225deg);
    display: ${props => props.show ? 'none' : 'block' }
  }
`
export const Panel = styled(Globals.Description)`
  max-height: ${props => props.show ? 'unset' : '0px'};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  color: white !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  margin-right: 25px;
  margin-top: 10px;
  p, span, li {
    color: white !important;
    font-size: 14px;
  }
  a, a:visited, a:hover {
    color: ${props => props.theme.colors.purple} !important;
    font-size: 14px;
  } 
`

export const Item = styled.div`
  margin-bottom: 17px;
`
