import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import {
  BannerSection,
  HowItWorksSection,
  InnovativeFinancialSection,
  WhyUsSection,
} from "../../components/home";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import LearnMoreSection from "../../components/LearnMoreSection";
import PurpleBackground from "../../../assets/images/home/purple-background";
import ResourcesSection from "../../components/ResourcesSection";
import LfBook from '../../../assets/images/attorney_resource_center/litigation-finance-book.png';

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;

const initialReview = {
  caseType: "Breach of Fiduciary Duty",
  caseDescription:
    "Shareholders of Sterling Cooper allege the agency's financial advisors failed to disclose significant conflicts of interests with a rival firm after a recent acquisition.",
  damagesAmount: "$20,000,000",
  defendant: "Cutler Gleason Chaough",
  defendantDescription:
    "Owners of a large M&A advisory firm tasked with finding the best possible buyer for Sterling Cooper to prevent the agency from being sold below fair market value.",
};

const funding = {
  damagesAmount: "$2.00M",
};

class Home extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparentBlack"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
            hasTaxDocs={this.props.hasTaxDocs}
          />
          <Page>
            <BannerSection />
            <InnovativeFinancialSection />
            <HowItWorksSection
              initialReview={initialReview}
              leaglUnderwriting={{}}
              funding={funding}
            />
            <ResourcesSection
              imagePath={LfBook}
              heading="Learn the concepts, structures, pricing models, and more"
              subheading="Our comprehensive library of litigation finance content covers everything from introductory concepts to detailed regulatory analysis."
            />
            <WhyUsSection />
            <LearnMoreSection
              background={PurpleBackground}
              heading="Discuss funding with LexShares."
              subheading="To discuss a potential funding need for your business or law firm, please contact us below."
              buttonText="Contact Us"
              buttonLink="/funding/case"
            />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Home;
