import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'
import theme from '../../../config/theme'

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack};
  text-align: center;
  font-size: 32px;
  line-height: 1.2;
  margin: 40px 0 15px 0;

  ${breakpoint('medium')`
    margin: 40px 0 65px 0;
  `}

  ${breakpoint('large')`
    font-size: 3.4rem;
  `}
`

export const AccountTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 60px 0px 30px 0px;
`;

export const ImageContainer = styled.div`
  width: 50px;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  display: inline-flex;
`;

export const IraStreetAddressDescription = styled.p`
  font-weight: 300;
`;


export const AccountType = styled(Globals.H4)`
  font-size: 1.5em !important;
  font-weight: bold;
  display: inline-flex;
  margin: 0 15px;
`;

export const RemoveAccount = styled.a`
  color: ${theme.colors.lightGrey2};
  font-size: .9rem;
  display: inline-flex;
  margin-left: auto;

  &:hover {
    color: ${theme.colors.lightGrey2};
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 30px !important;
  max-width: 450px;
  margin: 0 auto;
`;

export const BackButton = styled(Globals.ButtonSecondaryInverse)`
  margin-top: 35px;
  width: 100%;
  height: 51px;

  ${breakpoint('medium')`
    margin-top: 20px;
  `}
`;