import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';

export const Header = styled(Globals.H2)`
`;

export const Message = styled.p`
  ${Globals.proximaFontStack};
  font-size: 21px;
  line-height: 36px;
  color: ${theme.colors.purpleGrey};
  margin-bottom: 21px;
`;