import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Body, H6 } from '../../Globals';

export const PhotoOverlay = styled.div`
  background: linear-gradient(360deg, rgba(89, 29, 180, 0.25) 0%, rgba(89, 29, 180, 0.01) 75.78%);
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
`

export const Photo = styled.a`
  text-align: center;
  align-items: flex-end;
  display: flex;
  max-width: 270px;
  position: relative;
  width: 100%;
  background: white;
  margin: 0 auto;
  ${breakpoint('large')`
    height: 315px;
  `}
  &:hover {
    ${PhotoOverlay} {
      opacity: 1;
    }
  }
`

export const Flag = styled.div`
  border-left: 45px solid transparent;
  border-right: 45px solid #9450D4;
  border-bottom: 45px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
`;

export const ReadBioLink = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-weight: 800;
  font-size: 13px;
  color: #FFFFFF;
  text-transform: uppercase;
`


export const Name = styled(H6)`
  color: #0a0a0a;
  font-weight: 600;
  text-align: center;
  margin-top: 27px;
`

export const Position =  styled(Body)`
  color: #8A52D8;
  font-weight: 500;
  margin: 0;
  line-height: 1.3;
  text-align: center;
  margin-top: 10px;
  height: 43px;
`

export const Link = styled.a`
  font-size: 18px;
  line-height: 1.2;
  color: #878291;
  text-align: center;
  display: block;
  text-decoration: underline;
  margin-bottom: 10px;
`

export const Email = styled(Link)``

export const Phone = styled(Link)``

export const Divider = styled.div`
  background: #8A52D8;
  height: 1px;
  width: 50px;
  margin: 20px auto;
`

export const Employee = styled.div``
