import styled from 'styled-components';
import * as GB from './../../Globals';
import breakpoint from 'styled-components-breakpoint';

export const Section = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 60px 30px;

  ${breakpoint('medium')`
    padding: 57px 75px;
  `}
`;

export const Value = styled(GB.H2)`
  padding: 6px;
  margin-bottom: 0;
`;

export const Stats = styled.div`
  background: ${props => props.theme.colors.backdropPurple};
  text-align: center;
`;

export const Label = styled(GB.Description)`
  margin-bottom: 0;
  padding: 0 5px;
  line-height: 130%;
  font-size: 14px;
`;

export const Metrics = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  ${breakpoint('large')`
    flex-wrap: nowrap;
  `}
`

export const Metric = styled.div`
  margin-bottom: 36px;
  text-align: center;
  display: block;
  max-width: 165px;
`;

export const Bold = styled.span`
  font-weight: bold;
`

export const Description = styled(GB.Description)`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  ${breakpoint('large')`
    text-align: left;
  `}
`
