import React, {Fragment, useEffect, useState} from 'react'
import {Cell, Grid} from 'react-foundation'
import Pie from '../../../../Charts/Pie'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  ChartContainer,
  Heading,
  DateRange,
  Text
} from './InvestmentProfileWidget.style'

/**
 * Displays the profile of cases funded by LexShares, including damages
 * involved, stage at investment and jurisdiction. The data is manually set
 * in admin > site > investment profiles.
 */
function InvestmentProfileWidget() {
  let [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`/widget/lexshares-investment-profile.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Widget className="js-ls-investment-profile-widget">
      <Content loading={data ? "false" : "true"}>
        { data &&
          <Fragment>
            <Heading>LexShares Investment Profile</Heading>
            <DateRange>{data.disclosure.period}</DateRange>
            <Text>{data.disclosure.description}</Text>
            <Grid className="grid-margin-x">
              <Cell small={12} medium={4}>
                <ChartContainer className="js-damage-pie">
                  <Pie data={data.profile.data["damages"]} heading="Damages" />
                </ChartContainer>
              </Cell>
              <Cell small={12} medium={4}>
                <ChartContainer className="js-stage-pie">
                  <Pie data={data.profile.data["stage at investment"]} heading="Stage at Investment" />
                </ChartContainer>
              </Cell>
              <Cell small={12} medium={4}>
                <ChartContainer className="js-jurisdiction-pie">
                  <Pie data={data.profile.data["jurisdiction"]} heading="Jurisdiction" />
                </ChartContainer>
              </Cell>
            </Grid>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default InvestmentProfileWidget
