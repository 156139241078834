import React, { Fragment } from "react";
import breakpoint from "styled-components-breakpoint";
import { Cell, Grid } from "react-foundation";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import Navbar from "../../components/attorney-resource-center/Navbar/Navbar";
import Footer from "../../components/Footer";
import Sidenav from "../../components/attorney-resource-center/Sidenav/Sidenav";
import ArticleSection from "../../components/attorney-resource-center-show/ArticleSection/ArticleSection";
import ReadingProgress from "../../components/ReadingProgress/ReadingProgress";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const Container = styled.div`
  margin-top: -5px;

  ${breakpoint("medium")`
    margin-top: 75px;
  `}
`;

const SidenavContainer = styled.div`
  display: none;

  ${breakpoint("medium")`
    display: block;
  `}
`;

const ReadingProgressContainer = styled.div`
  top: 80px;
  position: sticky;
`;

class AttorneyResourceCenterShow extends React.Component {
  constructor(props) {
    super(props);
    this.articleSectionRef = React.createRef(null);
  }
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Page>
            <Navbar
              attorneyResourceCenterPath={this.props.attorneyResourceCenterPath}
              caseStudiesPath={this.props.caseStudiesPath}
              fundingAttorneysPath={this.props.fundingAttorneysPath}
              fundingPlaintiffsPath={this.props.fundingPlaintiffsPath}
              fundingGeneralCounselPath={this.props.fundingGeneralCounselPath}
              fundingCasePath={this.props.fundingCasePath}
              rootPath={this.props.rootPath}
              fundingInsightsPath={this.props.fundingInsightsPath}
              regulationsTrendPath={this.props.regulationsTrendPath}
              slipOpinionPath={this.props.slipOpinionPath}
              whitepapersPath={this.props.whitepapersPath}
            />
            <ReadingProgressContainer>
              <ReadingProgress
                target={this.articleSectionRef}
                article={this.props.article}
              />
            </ReadingProgressContainer>
            <Container>
              <Grid>
                <Cell small={12} medium={4} large={2}>
                  <SidenavContainer>
                    <Sidenav
                      caseStudiesPath={this.props.caseStudiesPath}
                      fundingAttorneysPath={this.props.fundingAttorneysPath}
                      fundingPlaintiffsPath={this.props.fundingPlaintiffsPath}
                      fundingGeneralCounselPath={
                        this.props.fundingGeneralCounselPath
                      }
                      fundingCasePath={this.props.fundingCasePath}
                      rootPath={this.props.rootPath}
                      fundingInsightsPath={this.props.fundingInsightsPath}
                      regulationsTrendPath={this.props.regulationsTrendPath}
                      attorneyResourceCenterPath={
                        this.props.attorneyResourceCenterPath
                      }
                      whitepapersPath={this.props.whitepapersPath}
                    />
                  </SidenavContainer>
                </Cell>
                <Cell small={12} medium={8} large={10}>
                  <ArticleSection
                    articleSectionRef={this.articleSectionRef}
                    article={this.props.article}
                  />
                </Cell>
              </Grid>
            </Container>
          </Page>
          <Footer bgColor="lighterPurple" />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default AttorneyResourceCenterShow;
