import React from 'react'
import { Cell, Grid } from 'react-foundation'

import {
  Section,
  Content,
  SubsectionContainer,
  Subsection,
  InfoText,
  Label,
  ColumnHeading,
  Investments,
  Container
} from './FundInformation.style'

function FundInformation({visible, investments}) {

  return (
    <Section className={visible ? "selected" : ""}>
      <Content>
        <Grid className="grid-margin-x">
          <Cell small={12} medium={9} large={9}>
            { investments.current_investments.length > 0 &&
              <Container>
                <Investments>
                  <SubsectionContainer>
                    <ColumnHeading>Active</ColumnHeading>
                  </SubsectionContainer>
                  <Grid className="grid-margin-x grid-heading">
                    <Cell small={2}>Name</Cell>
                    <Cell small={3}>Type</Cell>
                    <Cell small={2}>Stage at Investment</Cell>
                    <Cell small={2}>Fund Invested</Cell>
                    <Cell small={3}>Date Invested</Cell>
                  </Grid>
                  { investments.current_investments.map((investment, i) =>
                    <Grid key={`current-${i}`} className="grid-margin-x">
                      <Cell small={2}>{investment.name}</Cell>
                      <Cell small={3}>{investment.type}</Cell>
                      <Cell small={2}>{investment.stage_at_investment}</Cell>
                      <Cell small={2}>{investment.fund_invested}</Cell>
                      <Cell small={3}>{investment.date_invested}</Cell>
                    </Grid>
                  )}
                </Investments>
              </Container>
            }
            { investments.partial_investments.length > 0 &&
              <Container>
                <Investments>
                  <SubsectionContainer>
                    <ColumnHeading>Partially Resolved</ColumnHeading>
                  </SubsectionContainer>
                  <Grid className="grid-margin-x grid-heading">
                    <Cell small={2}>Name</Cell>
                    <Cell small={3}>Type</Cell>
                    <Cell small={2}>Fund Invested</Cell>
                    <Cell small={2}>Cumulative Fund Gross Return</Cell>
                    <Cell small={3}>Date Resolved</Cell>
                  </Grid>
                  { investments.partial_investments.map((investment, i) =>
                    <Grid key={`current-${i}`} className="grid-margin-x">
                      <Cell small={2}>{investment.name}</Cell>
                      <Cell small={3}>{investment.type}</Cell>
                      <Cell small={2}>{investment.fund_invested}</Cell>
                      <Cell small={2}>{investment.gross_return}</Cell>
                      <Cell small={3}>{investment.date_resolved}</Cell>
                    </Grid>
                  )}
                </Investments>
              </Container>
            }
            { investments.resolved_investments.length > 0 &&
              <Container>
                <Investments>
                  <SubsectionContainer>
                    <ColumnHeading>Resolved</ColumnHeading>
                  </SubsectionContainer>
                  <Grid className="grid-margin-x grid-heading">
                    <Cell small={2}>Name</Cell>
                    <Cell small={3}>Type</Cell>
                    <Cell small={2}>Fund Invested</Cell>
                    <Cell small={2}>Fund Gross Return</Cell>
                    <Cell small={3}>Date Resolved</Cell>
                  </Grid>
                  { investments.resolved_investments.map((investment, i) =>
                    <Grid key={`current-${i}`} className="grid-margin-x">
                      <Cell small={2}>{investment.name}</Cell>
                      <Cell small={3}>{investment.type}</Cell>
                      <Cell small={2}>{investment.fund_invested}</Cell>
                      <Cell small={2}>{investment.gross_return}</Cell>
                      <Cell small={3}>{investment.date_resolved}</Cell>
                    </Grid>
                  )}
                </Investments>
              </Container>
            }
          </Cell>
          <Cell small={12} medium={3} large={3}>
            <ColumnHeading>Investor Relations</ColumnHeading>
            <Subsection>
              <InfoText className="js-email">
                <Label>Email:</Label>
                investors@lexshares.com
              </InfoText>
              <InfoText className="js-phone">
                <Label>Phone:</Label>
                (877) 290-4443
              </InfoText>
            </Subsection>
          </Cell>
        </Grid>
      </Content>
    </Section>
  )
}

export default FundInformation
