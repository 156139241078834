import React from "react"
import { ThemeProvider } from 'styled-components'
import Navbar from '../../components/Navbar'
import theme from '../../config/theme'


class NavbarComponent extends React.Component {
  render () {
    return (
      <ThemeProvider theme={theme}>
        <Navbar theme={this.props.theme} roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor}  currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
      </ThemeProvider>
    )
  }
}

export default NavbarComponent
