import React from "react";
import {
  Container,
  Header,
  Subheading,
  DownloadButton,
} from "./BarometerCta.style";

const BarometerCta = () => {
  const onClick = () => {
    const element = document.getElementById("barometer-form-container");
    const y = element.getBoundingClientRect().top + window.pageYOffset - 50;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <Container>
      <Header>The Litigation Funding Barometer</Header>
      <Subheading>
        A LexShares Special Report on What Litigation Funders Want
      </Subheading>
      <DownloadButton onClick={onClick}>Download Report</DownloadButton>
    </Container>
  );
};

export default BarometerCta;
