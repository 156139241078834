import React, { Fragment } from "react";
import { Cell, Grid } from "react-foundation";
import { Banner, Content, Heading, Subheading } from "./BannerSection.style";

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>$100 million funded, and counting</Heading>
              <Subheading>
                The following investments demonstrate our platform’s extensive
                track record.
              </Subheading>
            </Cell>
          </Grid>
        </Content>
      </Banner>
    </Fragment>
  );
}

export default BannerSection;
