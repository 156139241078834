import React from "react";
import { Grid, Cell } from "react-foundation";
import {
  BenefitDescription,
  BenefitHeading,
  Container,
  Heading,
  ImageContainer,
} from "./LitigationInvestingBenefits.style";
import Spacer from "../../Spacer/Spacer";
import UncorrelatedImg from '../../../../assets/images/invest/uncorrelated-asset.png';
import HistoricalPerformance from '../../../../assets/images/invest/historical-performance.png';
import InstitutionalStrat from '../../../../assets/images/invest/institutional-strategy.png';

const LitigationInvestingBenefits = () => {
  return (
    <React.Fragment>
      <Container>
        <Heading>Potential benefits of investing in litigation</Heading>
        <Grid className="grid-margin-x grid-margin-y">
          <Cell small={12} medium={6} style={{ margin: "auto" }}>
            <BenefitHeading>Uncorrelated Asset</BenefitHeading>
            <BenefitDescription>
              Legal claims are largely uncorrelated to broader market conditions
              and macroecomonic activity.
            </BenefitDescription>
          </Cell>
          <Cell small={12} medium={6}>
            <ImageContainer width={300}>
              <img src={UncorrelatedImg} />
            </ImageContainer>
            <Spacer height={20} />
          </Cell>
          <Grid>
            <Cell
              small={12}
              medium={6}
              className="small-order-2 medium-order-1"
            >
              <ImageContainer width={267}>
                <img src={HistoricalPerformance} />
              </ImageContainer>
              <Spacer height={20} />
            </Cell>
            <Cell
              small={12}
              medium={6}
              style={{ margin: "auto" }}
              className="small-order-1 medium-order-2"
            >
              <BenefitHeading>Historical Performance</BenefitHeading>
              <BenefitDescription>
                LexShares has grown into an industry-leading litigation finance
                investment platform, with a track record spanning back to 2014.
              </BenefitDescription>
            </Cell>
          </Grid>
          <Cell small={12} medium={6} style={{ margin: "auto" }}>
            <BenefitHeading>Institutional Strategy</BenefitHeading>
            <BenefitDescription>
              LexShares’ platform makes it possible to access an asset class
              previously limited to large institutional investors.
            </BenefitDescription>
          </Cell>
          <Cell small={12} medium={6}>
            <ImageContainer width={327}>
              <img src={InstitutionalStrat} />
            </ImageContainer>
          </Cell>
        </Grid>
      </Container>
      {/* will add this section back at a later time */}
      {/* <RequestAccessSection>
        <CaseContainer>
          <CaseType>Whistleblower Claim</CaseType>
          <CaseTitle>Multiple Plaintiffs vs. Initech Corporation</CaseTitle>
          <CaseDescription>Defendant allegedly breached agreements with plaintiffs by failing to pay contractual royalties due and misappropriating plaintiffs' trade secrets.</CaseDescription>
          <FundingType>Funding Available</FundingType>
          <FundingAmount>$2,250,000</FundingAmount>
          <MinimumContainer>
            <MinimumAmount>$5,000</MinimumAmount><Light>min</Light>
            <MinimumAmount>$5,000</MinimumAmount><Light>min</Light>
          </MinimumContainer>
        </CaseContainer>
        <RequestAccessContainer>
          <RequestAccessTitle>Request Access</RequestAccessTitle>
          <RequestAccessDescription>Once you have been granted access to this investment opportunity, you will receive an email notification immediately. Thank you for your patience, and for being a LexShares investor.</RequestAccessDescription>
          <Button href="/invest/signup">Request Access</Button>
        </RequestAccessContainer>
      </RequestAccessSection> */}
    </React.Fragment>
  );
};

export default LitigationInvestingBenefits;
