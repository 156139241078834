import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, ContentHeading, ContentBody, Divider,
  SummarySection, SummaryHeader, Title, Description, SummaryDivider
} from './CaseStudyDetailSection.style';

function CaseStudyDetailSection({caseStudy = {}}) {
  return (
    <Fragment>
      <Section>
        <Grid>
          <Cell small={12} medium={8} large={7} className="medium-order-1 small-order-2">
            <Content>
              <ContentHeading>
                Case Details
              </ContentHeading>
              <ContentBody>
                { caseStudy.case_description }
              </ContentBody>
              <Divider />
              <ContentHeading>
                Funding Impact
              </ContentHeading>
              <ContentBody>
                { caseStudy.funding_impact }
              </ContentBody>
            </Content>
          </Cell>
          <Cell small={12} medium={4} large={5} className="medium-order-2 small-order-1">
            <SummarySection>
              <SummaryDivider  />

              <SummaryHeader>Case at a glance</SummaryHeader>
              <SummaryDivider  />

              <Title>
                Law Firm
              </Title>

              <Description>
                { caseStudy.law_firm }
              </Description>

              <SummaryDivider  />

              <Title>
                Engagement Structure
              </Title>

              <Description>
                { caseStudy.engagement_structure }
              </Description>

              <SummaryDivider  />

              <Title>
                Plaintiff
              </Title>

              <Description>
                { caseStudy.plaintiff_description }
              </Description>

              <SummaryDivider  />

              <Title>
                Defendant
              </Title>

              <Description>
                { caseStudy.defendant_description }
              </Description>

              <SummaryDivider  />

              <Title>
                Case Stage at Investment Date
              </Title>

              <Description>
                { caseStudy.case_stage }
              </Description>

              <SummaryDivider  />
            </SummarySection>
          </Cell>
        </Grid>
      </Section>
    </Fragment>
  )
}


export default CaseStudyDetailSection;
