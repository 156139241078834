import styled from 'styled-components';
import * as Globals from './../Globals';
import breakpoint from 'styled-components-breakpoint';
import theme from '../../config/theme';

export const Container = styled.div`
  background: ${theme.colors.lightPurple};
  padding: 25px;

  ${breakpoint('medium')`
    padding: 5% 15% 4% 7%;
  `};
`;

export const Heading = styled(Globals.H2)`
  font-size: 28px !important;
  line-height: 32px !important;
  margin-bottom: 25px;
  color: ${theme.colors.darkGrey};

  ${breakpoint('medium')`
    font-size: 48px !important;
    line-height: 44px !important;
    margin-bottom: 33px;
  `};
`;

export const Description = styled(Globals.Description)`
  margin-bottom: 23px;
`;

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
  background: ${theme.colors.mediumPurple};

  ${breakpoint('medium')`
    min-width: 185px;
    width: unset;
  `};
`

export const InfoContainer = styled.div`
  padding: 25px 0px;

  ${breakpoint('medium')`
    padding: 100px 40px;
  `};
`;

export const ImageContainer = styled.div`
  max-width: 600px;
`;