import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import Navbar from '../../components/investor-dashboard/Navbar'
import Footer from '../../components/Footer'
import { BannerSection, TaxDocumentList } from '../../components/page-components/tax-center'

const TaxCenterPage = styled.div`
  background-color: ${props => props.theme.colors.white};
`

const TaxCenter = (props) => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Navbar
          theme="transparent"
          roleDashboardPath={props.roleDashboardPath}
          roleProfilePath={props.roleProfilePath}
          userSignedIn={props.userSignedIn}
          isInvestor={props.isInvestor}
          currentRole={props.isInvestor}
          name={props.name}
          csrfToken={props.csrfToken}
          hasTaxDocs={props.hasTaxDocs}
          hasInvestments={true}
          unseenNotifications={props.investor.unseen_notifications}
        />
        <TaxCenterPage>
          <BannerSection />
          <TaxDocumentList investments={props.investments}/>
        </TaxCenterPage>
        <Footer />
      </ThemeProvider>
    </Fragment>
  )
}

export default TaxCenter;