import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';

export const AuthorImage = styled.img`
  width: 39px;
  height: 40px;
  border-radius: 300px;
`

export const AuthorName = styled.div`
  ${Globals.proximaFontStack}
  font-weight: 700;
  font-size: 16px;
  margin-top: 14px;
`;

export const AuthorLink = styled.a`
  ${Globals.proximaFontStack}
  font-weight: 700;
  font-size: 16px;
  margin-top: 14px;
  display: block;
  color: #0a0a0a;

  &:hover { 
    color: #0a0a0a;
  }
`;

export const AuthorBio = styled.p`
  ${Globals.proximaFontStack}
  font-size: 16px;
  color: ${theme.colors.purpleGrey};
  line-height: 25px;
  margin-bottom: 0px;
`;