import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const HeaderImage = styled.img`
  height: 400px;
  width: 100%;
  object-fit: cover;
`

export const Tag = styled.p`
  color: ${theme.colors.purpleGrey};
  height: 24px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  ${Globals.proximaFontStack}
  margin-bottom: 0px;
  margin-top: 10px;

  &:hover {
    color: ${theme.colors.purpleGrey};
  }

  ${breakpoint('medium')`
    margin-bottom: 30px;
    margin-top: 0px;
  `}
`

export const ArticleContainer = styled.div`
  margin: 10px 20px 6px 20px;

  ${breakpoint('large')`
    margin: 45px 80px 26px 40px;
  `}
`;

export const Header = styled(Globals.H1)`
  text-align: left;
`;

export const ContentContainer = styled.div`
  margin-right: 0px;

  ${breakpoint('large')`
    margin-right: 65px;
  `}
`;

export const MobileView = styled.div`
  display: block;
  margin-top: 10px;

  ${breakpoint('medium')`
    display: none;
  `}
`;

export const NonMobileView = styled.div`
  display: none;

  ${breakpoint('medium')`
    display: block;
  `}
`;