import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Stats, Content, Label, Metric } from './StatsSection.style';

function StatsSection({caseStudy}) {
  return (
    <Fragment>
      <Stats>
        <Content>
          <Grid>
            <Cell small={12} medium={4}>
              <Label>
                LexShares Investment
              </Label>
              <Metric>
                { caseStudy.investment }
              </Metric>
            </Cell>
            <Cell small={12} medium={4}>
              <Label>
                Case Type
              </Label>
              <Metric>
                { caseStudy.case_type }
              </Metric>
            </Cell>
            <Cell small={12} medium={4}>
              <Label>
                Jurisdiction
              </Label>
              <Metric>
                { caseStudy.jurisdiction }
              </Metric>
            </Cell>
          </Grid>
        </Content>
      </Stats>
    </Fragment>
  )
}

export default StatsSection;
