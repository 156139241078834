import React, {Fragment, useEffect, useState} from 'react'
import ReactTooltip from 'react-tooltip'
import {Menu} from 'react-foundation'
import {
  TopBarLogo, MenuContainer, MenuLink, MenuItem, MainMenu, SubMenuLink,
  SubMenuItem, SubMenu, TopBar, LogoLink, MobileTopBar, TopBarRight,
  MenuLinkRight, MenuItemRight, TopBarLogoRight,
  MenuButton, TopBarLeft, SecondaryButton, MenuRight, Lock,
  Notifications
} from './Navbar.style';
import SlideDown from '../../SlideDown';
import MenuImg from '../../../../assets/images/icons/menu.png';
import SkinnyArrow from '../../../../assets/images/icons/skinny-arrow.png';
import WhiteClose from '../../../../assets/images/icons/white-close.png'


function Navbar({userSignedIn, currentRole, isInvestor,
  roleProfilePath, name, csrfToken, theme, clickHandler, selected,
  hasInvestments, hasTaxDocs, visibleAlert = false, unseenNotifications = 0, lmf1_investor, lmf2_investor, has_lmf2_access} ) {

  const [active, setActive] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [trueWhite, setTrueWhite] = useState(false)
  const [alertPresent, setAlertPresent] = useState(false)

  const getType = () => {
    if (scrolled) { return "scrolled" }
    if (trueWhite) { return "trueWhite" }
    return theme
  }

  const handleScroll = () => {
    let scrollTop = $(document).scrollTop()
    if(scrollTop > 80 && !scrolled) {
      setScrolled(true)
    }
    if(scrollTop == 0 && scrolled) {
      setScrolled(false)
    }
  }

  const handleChange = () => {
    if ($('.full-screen-white-modal').length > 0) {
      setTrueWhite(true)
    }
    else {
      setTrueWhite(false)
    }
  }

  const signOut = () => {
    fetch('/users/sign_out', {
      method: 'DELETE',
      redirect: 'follow',
      credentials: 'include',
      headers: {
        "X-CSRF-Token": csrfToken
      },
    }).then((res)=> {
      window.location = res.url;
    })
  }

  const toggleAlertPadding = () => {
    if($('.alert').length > 0) {
      setAlertPresent(true)
    } else {
      setAlertPresent(false)
    }
  }

  useEffect(() => {
    $('.navbar').foundation()
    setInterval(() => {
      toggleAlertPadding()
    }, 100)
  }, [])

  const toggleMenu = (val) => {
    setActive(val)
    if(val) {
      $('body').css('overflow', 'hidden')
    } else {
      $('body').css('overflow', 'visible')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [scrolled])

  useEffect(() => {
    let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    let observer = new MutationObserver(handleChange);
    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  }, [trueWhite])

  return (
    <>
      <MenuContainer className="navbar" visibleAlert={visibleAlert}>
        <MobileTopBar shown={active.toString()}>
          <TopBarLogo>
            <Menu>
              <LogoLink type={getType()} href="/" />
            </Menu>
          </TopBarLogo>
          <TopBarLogoRight>
            { active || <MenuButton onClick={() => toggleMenu(true)} >
              <img src={MenuImg} />
            </MenuButton> }
            { active && <MenuButton onClick={() => toggleMenu(false)}>
              <img src={WhiteClose} />
            </MenuButton> }
          </TopBarLogoRight>
        </MobileTopBar>

        <SlideDown show={active}>
          <TopBar type={getType()} margin={alertPresent.toString()}>
            <TopBarLogo>
              <Menu>
                <LogoLink type={getType()} href="/" />
              </Menu>
            </TopBarLogo>

            <TopBarLeft full={userSignedIn.toString()} >
              <MainMenu isDropdown={true} isVertical={true} data-multi-open="false" data-responsive-menu="accordion large-dropdown" className="large-horizontal">
                <MenuItem>
                  <MenuLink
                    href="/dashboard#activity-feed"
                    onClick={(e) => clickHandler ? clickHandler(e, toggleMenu) : null}
                    type={getType()}
                    className={selected == 'activity feed' ? 'selected' : ''}
                    data-name="activity feed"
                  >
                    Activity Feed
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    href="/dashboard#investment-portfolio"
                    onClick={(e) => clickHandler ? clickHandler(e, toggleMenu) : null}
                    type={getType()}
                    className={selected == 'investment portfolio' ? 'selected' : ''}
                    data-name="investment portfolio"
                  >
                    Investment Portfolio
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    href={hasInvestments ? "/dashboard#case-updates" : "javascript:void(0)"}
                    onClick={(e) => (clickHandler && hasInvestments) ? clickHandler(e, toggleMenu) : null}
                    type={getType()}
                    className={selected == 'case updates' ? 'selected' : hasInvestments ? '' : 'locked'}
                    data-tip={hasInvestments ? "" : "Unlock your Case Updates tab after you make your first investment."}
                    data-place="bottom"
                    data-name="case updates"
                  >
                    Case Updates
                    { !hasInvestments &&
                      <Fragment>
                        <Lock />
                        <ReactTooltip />
                      </Fragment>
                    }
                    { unseenNotifications > 0 &&
                      <Notifications className="js-case-notifications">
                        {unseenNotifications}
                      </Notifications>
                    }
                  </MenuLink>
                </MenuItem>
                { (lmf1_investor || lmf2_investor || has_lmf2_access) &&
                <MenuItem >
                  <MenuLink type={getType()}
                  href="/dashboard#case-updates">
                    Fund Updates
                    <img src={SkinnyArrow} />
                  </MenuLink>
                  
                  <SubMenu>
                    {
                      lmf1_investor && currentRole && <SubMenuItem>
                        <SubMenuLink 
                          href={"/dashboard#fund-updates"}
                          onClick={(e) => (clickHandler) ? clickHandler(e, toggleMenu) : null}
                          type={getType()}
                          className={selected == 'fund 1 updates' ? 'selected' : ''}
                          data-name="fund 1 updates">
                            Fund I Updates
                            <img src={SkinnyArrow} />
                          </SubMenuLink>
                        </SubMenuItem>
                    }
                    {
                      (lmf2_investor || has_lmf2_access) && <SubMenuItem>
                        <SubMenuLink 
                          href={"/dashboard#fund-updates"}
                          onClick={(e) => (clickHandler) ? clickHandler(e, toggleMenu) : null}
                          type={getType()}
                          className={selected == 'fund 2 updates' ? 'selected' : ''}
                          data-name="fund 2 updates">
                          Fund II Updates
                          <img src={SkinnyArrow} />
                        </SubMenuLink>
                      </SubMenuItem>
                    }
                  </SubMenu>
                  
                </MenuItem>
                }
                {
                  userSignedIn && <MenuItem className="hide-for-large">
                    <MenuLink type={getType()}>
                      { name }
                      <img src={SkinnyArrow} />
                    </MenuLink>
                    <SubMenu isVertical={true} type={getType()}>
                      {
                        isInvestor && currentRole && <SubMenuItem>
                          <SubMenuLink href={roleProfilePath} type={getType()}>
                            My Profile
                            <img src={SkinnyArrow} />
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      {
                        isInvestor && hasTaxDocs && currentRole && <SubMenuItem>
                          <SubMenuLink href={"/tax-center"}>
                            Tax Center
                            <img src={SkinnyArrow} />
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      <SubMenuItem>
                        <SubMenuLink onClick={() => signOut()}>
                          Log Out
                          <img src={SkinnyArrow} />
                        </SubMenuLink>
                      </SubMenuItem>
                    </SubMenu>
                  </MenuItem>
                }
              </MainMenu>
            </TopBarLeft>
            {
              userSignedIn && <TopBarRight className="show-for-large">
                <Menu isDropdown={true} isVertical={true} data-responsive-menu="accordion large-dropdown" className="large-horizontal">
                  <MenuItem>
                    <MenuLink type={getType()} className="js-menu-name">
                      { name }
                    </MenuLink>
                    <SubMenu isVertical={true} className="menu vertical">
                      {
                        isInvestor && currentRole && <SubMenuItem>
                          <SubMenuLink href={roleProfilePath}>
                            My Profile
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                       {
                        isInvestor && hasTaxDocs && currentRole && <SubMenuItem>
                          <SubMenuLink href={"/tax-center"}>
                            Tax Center
                          </SubMenuLink>
                        </SubMenuItem>
                      }
                      <SubMenuItem>
                        <SubMenuLink onClick={() => signOut()} >
                          Log Out
                        </SubMenuLink>
                      </SubMenuItem>
                    </SubMenu>
                  </MenuItem>
                </Menu>
              </TopBarRight>
            }
            <TopBarRight>
              <MenuRight>
              { userSignedIn || <MenuItemRight className="show-for-large">
                <MenuLinkRight type={getType()} href="tel:(877) 290-4443">
                (877) 290-4443
                </MenuLinkRight>
              </MenuItemRight> }

              { userSignedIn && <MenuItemRight className="hide-for-large">
                <SecondaryButton onClick={() => signOut()}>
                  Log out
                </SecondaryButton>
                </MenuItemRight> }
              </MenuRight>
            </TopBarRight>
          </TopBar>
        </SlideDown>
      </MenuContainer>
    </>
  )
}

export default Navbar;
