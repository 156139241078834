import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H1, P } from '../../Globals'

export const Section = styled.div`
  background: black;
  color: white;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1230px;
  padding: 60px 5%;

  ${breakpoint('medium')`
    padding: 136px 0;
  `}
`

export const Heading = styled(H1)`
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: center;

  ${breakpoint('large')`
    margin-top: 150px;
    text-align: left;
  `}

`

export const Subheading = styled(P)`
  padding: 0 20px;
  text-align: center;
  margin-bottom: 40px;

  ${breakpoint('large')`
    text-align: left;
  `}
`

export const InvestmentContainer = styled.div`
  color: black;
  width: 400px;
  margin: 0 auto;
  max-width: 100%;
`
