import React, {Fragment, useEffect, useState} from 'react'
import {Cell, Grid} from 'react-foundation'
import PortfolioWidget from '../SharedWidgets/PortfolioWidget'
import FaqWidget from '../SharedWidgets/FaqWidget'
import ActivityWidget from './ActivityWidget'
import axios from 'axios'
import Mixpanel from '../../Mixpanel'
import {
  Content,
  Heading,
  Tabs,
  Link
} from './CaseUpdateView.style'

/**
 * Loads all active and resolved investmntns and displays activity
 * associated with those investments. Those with unread notifications
 * are shown highlighted.
 */
function CaseUpdateView({investorId, csrfToken, updateUnseenNotifications,
  totalNotifications, viewClickHandler, investmentCount}) {

  let [investments, setInvestments] = useState(null);
  let [currentTab, setCurrentTab] = useState('active')

  // Handles changing of tabs.
  const tabClickHandler = (tab) => {
    setCurrentTab(tab)
  }

  // Handles click to dismiss notifications when appropriate
  const handleClick = (e, investments) => {
    if (
        (e.target.parentElement.nodeName.toLowerCase() == 'li') && 
        e.target.getAttribute('data-name') &&
        (e.target.getAttribute('data-name').match(/investment portfolio|activity feed/))) 
    {
      ['active', 'resolved'].forEach((type) => {
        investments[type].forEach((investment) => {
          if (investment.has_unread_notifications) {
            const payload = {
              court_case_notification: {
                court_case_id: investment.case_id,
                investor_id: investorId
              },
              authenticity_token: csrfToken
            }
            axios.put(`/case-notifications/${investment.case_id}.json`, payload)
          }
        })
      })
      updateUnseenNotifications(0) 
    }
  }

  // Pulls all non-LMF active and resolved investments along with activity.
  useEffect(() => {
    axios.get('/widget/my-cases.json').then(res => {
      setInvestments(res.data)

      // Attaches click listener so we can dimiss notifications
      window.addEventListener('click', () => { handleClick(event, res.data) })

      // Defaults to resolved if no active investments.
      if (res.data.active.length == 0) {
        setCurrentTab('resolved')
      }
    })
  }, [])

  // Pages Mixpanel with current view.
  useEffect(() => {
    Mixpanel.track('Viewed case updates tab', {
      tab: currentTab
    })
  }, [])

  // Sets the side panel widgets in the correct order depending on previous investor activity.
  const sideWidgets = () => {
    let widgets = []

    if (investmentCount == 0) {
      widgets = [
        <FaqWidget key="faq-widget" />
      ]
    }
    else if (investmentCount == 1) {
      widgets = [
        <PortfolioWidget viewClickHandler={viewClickHandler} key="portfolio-widget"/>,
        <FaqWidget key="faq-widget" />
      ]
    }
    else if (investmentCount > 1) {
      widgets = [
        <PortfolioWidget viewClickHandler={viewClickHandler} key="portfolio-widget"/>,
        <FaqWidget key="faq-widget" />
      ]
    }

    return widgets
  }

  return (
    <Fragment>
      <Content>
        <Heading>Case Updates</Heading>
        { investments &&
        <Fragment>
        <Tabs>
          { investments.active.length > 0 &&
            <Link
              className={currentTab == 'active' ? 'selected' : ''}
              onClick={() => { tabClickHandler('active') }}
            >
              Active
            </Link>
          }
          { investments.resolved.length > 0 &&
            <Link
              className={currentTab == 'resolved' ? 'selected' : ''}
              onClick={() => { tabClickHandler('resolved') }}
            >
              Resolved
            </Link>
          }
          { investments.partially_resolved.length > 0 &&
            <Link
              className={currentTab == 'partially resolved' ? 'selected' : ''}
              onClick={() => { tabClickHandler('partially resolved') }}
            >
              Partially Resolved
            </Link>
          }
        </Tabs>
        <Grid className="grid-margin-x">
          { currentTab == 'active' &&
            <Cell id="js-widget-cell" medium={12} large={8}>
              { investments.active.map((investment, i) => (
                <Fragment key={i}>
                  <ActivityWidget
                    id={investment.id}
                    heading={investment.heading}
                    caseType={investment.case_type}
                    caseStage={investment.case_stage}
                    amount={investment.amount}
                    investmentDate={investment.investment_date}
                    timeline={investment.timeline}
                    caseId={investment.case_id}
                    hasUnreadNotifications={investment.has_unread_notifications}
                    docketUrl={investment.docket_url}
                    updateUnseenNotifications={updateUnseenNotifications}
                    totalNotifications={totalNotifications}
                    investorId={investorId}
                    csrfToken={csrfToken}
                  />
                </Fragment>
              ))}
            </Cell>
          }
          { currentTab == 'resolved' &&
            <Cell id="js-widget-cell" medium={12} large={8}>
              { investments.resolved.map((investment, i) => (
                <Fragment key={i}>
                  <ActivityWidget
                    id={investment.id}
                    heading={investment.heading}
                    caseType={investment.case_type}
                    caseStage={investment.case_stage}
                    amount={investment.amount}
                    investmentDate={investment.investment_date}
                    timeline={investment.timeline}
                    caseId={investment.case_id}
                    hasUnreadNotifications={investment.has_unread_notifications}
                    docketUrl={investment.docket_url}
                    updateUnseenNotifications={updateUnseenNotifications}
                    totalNotifications={totalNotifications}
                    investorId={investorId}
                    csrfToken={csrfToken}
                  />
                </Fragment>
              ))}
            </Cell>
          }
          { currentTab == 'partially resolved' &&
            <Cell id="js-widget-cell" medium={12} large={8}>
              { investments.partially_resolved.map((investment, i) => (
                <Fragment key={i}>
                  <ActivityWidget
                    id={investment.id}
                    heading={investment.heading}
                    caseType={investment.case_type}
                    caseStage={investment.case_stage}
                    amount={investment.amount}
                    investmentDate={investment.investment_date}
                    timeline={investment.timeline}
                    caseId={investment.case_id}
                    hasUnreadNotifications={investment.has_unread_notifications}
                    docketUrl={investment.docket_url}
                    updateUnseenNotifications={updateUnseenNotifications}
                    totalNotifications={totalNotifications}
                    investorId={investorId}
                    csrfToken={csrfToken}
                  />
                </Fragment>
              ))}
            </Cell>
          }
          <Cell medium={12} large={4}>
            {
              sideWidgets().map((widget, i) =>
                <Fragment key={`case-update-view-${i}`}>
                  {widget}
                </Fragment>
              )
            }
          </Cell>
        </Grid>
        </Fragment>
        }
      </Content>
    </Fragment>
  )
}

export default CaseUpdateView
