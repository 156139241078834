import React, { Fragment } from "react";
import breakpoint from "styled-components-breakpoint";
import { Grid, Cell } from "react-foundation";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import BarometerCta from "../../components/barometer/BarometerCta/BarometerCta";
import BannerImage from "../../components/barometer/BannerImage/BannerImage";
import DescriptionSection from "../../components/barometer/DescriptionSection/DescriptionSection";
import FormSection from "../../components/barometer/FormSection/FormSection";
import BarometerDownloadedPop from "../../components/barometer/BarometerDownloadedPop/BarometerDownloadedPop";
import RelatedResourcesSection from "../../components/barometer/RelatedResourcesSection/RelatedResourcesSection";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const Container = styled.div`
  margin: 0px;
  background-color: ${theme.colors.backdropPurple};
  padding: 0px 0px 40px 0px;

  ${breakpoint("medium")`
    margin: 40px 0px 0px 0px;
    padding: 40px;
  `}

  ${breakpoint("large")`
    margin: 80px 0px 0px 0px;
    padding: 120px 0px 120px 80px;
  `}
`;

class Barometer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barometerConfirmationPopup: this.props.barometerConfirmationPopup,
    };
  }
  render() {
    const setShowPop = (state) => {
      this.setState({
        barometerConfirmationPopup: state,
      });
    };

    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Container>
              <Grid>
                <Cell
                  small={12}
                  large={6}
                  className="small-order-2 large-order-1"
                >
                  <BarometerCta />
                </Cell>
                <Cell
                  small={12}
                  large={6}
                  className="small-order-1 large-order-2 show-for-large"
                >
                  <BannerImage />
                </Cell>
              </Grid>
            </Container>
            <DescriptionSection />
            <FormSection csrfToken={this.props.csrfToken} />
            <RelatedResourcesSection />
          </Page>
          {this.state.barometerConfirmationPopup && (
            <BarometerDownloadedPop setShowPop={setShowPop} />
          )}
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Barometer;
