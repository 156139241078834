import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: 0;
`

export const Line = styled.div`
  position: absolute;
  box-shadow: inset 0 0 0 1px #D3D8DD;
  height: 1px;
  width: 100%;

  &.horizontal {
    height: 1px;
    width: 100%;
  }

  &.vertical {
    width: 1px;
    height: var(--divider-height, 100%);
  }
`;
