import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from '../../../SimpleBanner';
import * as Globals from '../../../Globals';

export const Content = styled(SB.Content)`
  max-width: 1235px;
  ${breakpoint('medium')`
    padding: 3% 3%;
  `}
`;

export const Heading = styled(Globals.H2)`
  text-align: left;
  margin-bottom: 40px;
`;

export const Tabs = styled.div`
  border-bottom: 1px solid rgba(40, 35, 49, 0.4);
`

export const TabsContainer = styled.div`
  max-width: 1235px;
  margin: 0 auto;

  ${breakpoint('medium')`
    padding: 0% 3%;
  `}
`

export const Tab = styled.div`
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 20px;
  display: inline-block;
  text-transform: uppercase;
  color: ${props => props.active ? props.theme.colors.purple : props.theme.colors.black };
  border-bottom: ${props => props.active ? "5px solid #8247BA" : "none" };
`
