import React, { Fragment } from 'react'
import ModalPop from '../../../ModalPop'
import {
  Content, Heading, CloseButton,
  Logo, LinkContainer, Link, Arrow,
  HeadingCloseButton, BannerProp
} from  './SignUpModal.style'

import { Grid, Cell } from 'react-foundation';

import WhiteCloseImg from '../../../../../assets/images/icons/white-close.png';
import LexSmallImg from '../../../../../assets/images/logos/lexshares-small.png';

function SignUpModal({hideHandler}) {

  const renderContent = () => {
    return (
      <Content>
        <BannerProp />
        <Heading className="hide-for-large">
          <HeadingCloseButton onClick={hideHandler}>
            <img src={WhiteCloseImg}/>
          </HeadingCloseButton>
          <Logo>
            <img src={LexSmallImg}/>
          </Logo>
        </Heading>
        <Grid className="grid-margin-x">
          <Cell small={12}>
            <CloseButton className="show-for-large" onClick={hideHandler}>
              &times;
            </CloseButton>
            <LinkContainer>
              <Grid className="grid-margin-x align-center">
                <Cell large={5}>
                  <Link href="/funding/case">
                    <Arrow>
                      ➔
                    </Arrow>
                    Get funding
                  </Link>
                </Cell>

                <Cell large={5} className="large-offset-1">
                  <Link href="/invest/signup">
                    <Arrow>
                      ➔
                    </Arrow>
                    Sign up to invest
                  </Link>
                </Cell>
              </Grid>
            </LinkContainer>
          </Cell>
        </Grid>
      </Content>
    )
  }

  return (
    <Fragment>
      <ModalPop
        popVisible={true}
        content={renderContent()}
        size="full"
        color="#131017"
      />
    </Fragment>
  )
}

export default SignUpModal;
