import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import { BannerSection } from "../../components/cases";
import LearnMoreSection from "../../components/LearnMoreSection";
import PurpleBackground from "../../../assets/images/home/purple-background";
import OpenInvestmentCardsSection from "../../components/funding/OpenInvestmentCardsSection";
import PrivateCaseAccessWatcher from "../../components/PrivateCaseAccessWatcher";
import Footer from "../../components/Footer";
import ResourcesSection from "../../components/ResourcesSection";
import NewNavbar from "../../components/Navbar/NewNavbar";
import { OpenInvestmentsSection } from "../../components/funding";
import { RecentlyFundedSection } from "../../components/cases";
import LitFinBook from '../../../assets/images/attorney_resource_center/litigation-finance-book.png';

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

class CasesIndex extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
            hasTaxDocs={this.props.hasTaxDocs}
          />
          <Page>
            <BannerSection />
            {(this.props.viewableInvestments.length > 0 ||
              this.props.approvedInvestments.length > 0) && (
              <OpenInvestmentsSection
                viewableInvestments={this.props.viewableInvestments}
                approvedInvestments={this.props.approvedInvestments}
                csrfToken={this.props.csrfToken}
              />
            )}

            {this.props.recentlyFundedInvestments.length > 0 && (
              <RecentlyFundedSection
                investments={this.props.recentlyFundedInvestments}
                csrfToken={this.props.csrfToken}
              />
            )}
            <OpenInvestmentCardsSection investments={this.props.investments} />
            <ResourcesSection
              imagePath={LitFinBook}
              heading="Learn the concepts, structures, pricing models, and more"
              subheading="Our comprehensive library of litigation finance content covers everything from introductory concepts to detailed regulatory analysis."
            />
            <LearnMoreSection
              background={PurpleBackground}
              heading="Discuss funding with LexShares"
              subheading="Connect with LexShares to discuss your potential financing needs."
              buttonText="Contact Us"
              buttonLink="/funding/case"
            />
          </Page>
          <Footer />
          <PrivateCaseAccessWatcher />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default CasesIndex;
