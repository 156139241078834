import React from 'react'
import { Container, Line } from './Divider.style';

const Divider = ({orientation}) => {
  return(
    <Container>
      <Line className={orientation === 'vertical' ? "vertical" : "horizontal"} />
    </Container>
  )
}

export default Divider;