import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import * as Globals from '../../Globals';

export const Section = styled(SB.Banner)``;

export const Content = styled(SB.Content)`
  max-width: 1230px;
  text-align: left;
  padding: 10% 7%;
  ${breakpoint('medium')`
    padding: 3%;
  `}
`;

export const BannerProp = styled(SB.Diamonds)``;

export const MediaContent = styled.div`
  ${breakpoint('medium')`
    justify-content: left;
  `}

  margin: 100px auto 0;
  position: relative;
`

export const Heading = styled(SB.Heading)`
  margin-bottom: 40px;
`;

export const Subheading = styled(SB.Subheading)`
  margin-bottom: 40px;
`;

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
`

export const ButtonInverse = styled(Button)`
  background: transparent !important;
  color: ${props => props.theme.colors.white};
  border: 2px solid #8247ba;
  padding: 13px 24px 13px 24px;
  &:hover {
    background: transparent !important;
    color: ${props => props.theme.colors.white} !important;
    opacity: .7;
  }
`
