import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import { H1, H3 } from './../../Globals';
import theme from '../../../config/theme';
import Hero from '../../../../assets/images/litigation_finance_101/hero.jpg';

export const Section = styled.div`
  position: relative;
  background: url(${Hero}) no-repeat center center/cover;
  margin: 0 auto;
  background-color: ${theme.colors.black};
  padding: 1% 0 0;
`;

export const Content = styled(SB.Content)`
  margin: 0 auto;
  margin-top: 2%;
  max-width: 1235px;
  padding: 7% 7% !important;

  ${breakpoint('medium')`
    padding: 10% 7% !important;
  `}

  ${breakpoint('large')`
    padding: 5% 2% !important;
  `}
`;

export const Header = styled(H1)`
  display: none;
  color: white !important;

  ${breakpoint('large')`
    display: block;
    margin-bottom: 30px;
    text-align: left;
  `}
`;

export const SecondaryHeader = styled(H1)`
  color: white !important;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 32px;

  ${breakpoint('medium')`
    font-size: 2.4rem;
    text-align: center;
  `}

  ${breakpoint('large')`
    display: none;
  `}
`;

export const Subheading = styled.p`
  text-align: left;
  color: white;
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`;

export const SecondarySubheading = styled.p`
  text-align: center;
  color: white;
  display: block;

  ${breakpoint('large')`
    display: none;
  `}
`;

export const Logo = styled.img`
  width: auto;
  max-width: 60px;
  max-height: 15px;

  ${breakpoint('medium')`
    max-width: 100px;
    max-height: 20px;
  `}
  
`;

export const LogosContainer = styled.div`
  margin: 50px 20px;

  ${breakpoint('medium')`
    margin: 50px 0px;
    margin-left: -32px;
  `}
`;

export const InfoContainer = styled.div`
  ${breakpoint('large')`
    max-width: 490px;
  `}
`;

export const FormHeader = styled(H3)`
  color: white;
  font-size: 1.4em !important;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'proxima-nova' !important;
  text-align: left;
`;

export const FormContainer = styled.div`
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 5px;
  background: ${theme.colors.darkGrey};

  ${breakpoint('medium')`
    padding: 30px 50px;
  `}
`;

export const ListItem = styled.div`
  position: relative;
`;

export const InfoItem = styled.p`
  color: white;
  font-family: 'proxima-nova' !important;
  margin-bottom: 15px;
  padding-left: 30px;
  font-weight: 300;
  font-size: 1rem;
  text-align: left;
`;

export const CheckmarkContainer = styled.div`
  position: absolute;
`;

export const Checkmark = styled.img`
  width: 15px;
  height: 15px;
`;

export const InputField = styled.input`
  height: 45px;
  border-radius: 3px;
  padding: 20px 15px;
  font-size: 17px;
  font-weight: 300;
  box-shadow: unset !important;

  ${breakpoint('medium')`
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  `}
`;

export const Form = styled.form`
  margin-top: 40px;
`;

export const InputSubmit= styled.input`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: 0;
  min-width: 200px !important;
  height: 45px !important;
  line-height: 18px;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  background: ${theme.colors.veryLightPurple};
  color: ${theme.colors.creamWhite};
  font-weight: 600;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-size: 13px;
  -webkit-font-smoothing: auto;
  width: 100%;

  &:hover {
    background-color: ${theme.colors.backgroundPurple};
    cursor: pointer;
  }

  ${breakpoint('medium')`
    display: block;
  `}

  ${breakpoint('large')`
    display: inline-block;
  `}
`;