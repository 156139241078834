import React, {Fragment} from 'react'
import {Cell, Grid} from 'react-foundation'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text,
  Metric,
  Label,
  Disclosure
} from './IrrWidget.style'

/**
 * Simple widget that renders historical performance for LexShares or
 * personal portfolio by displaying provided IRR and ROIC.
 */
function IrrWidget({heading, irr, roic, dateRange, text, disclosure}) {
  return (
    <Widget className="js-irr-widget">
      <Content loading={heading ? "false" : "true"}>
        { heading &&
          <Fragment>
            <Heading>{heading}</Heading>
            <DateRange>{dateRange}</DateRange>
            <Text>{text}</Text>
            <Grid className="grid-margin-x">
              <Cell small={12} medium={6}>
                <Metric>{irr}</Metric>
                <Label>Median Internal Rate of Return (IRR)</Label>
              </Cell>
              <Cell small={12} medium={6}>
                <Metric>{roic}</Metric>
                <Label>Median Return on Invested Capital (ROIC)</Label>
              </Cell>
            </Grid>
            { disclosure &&
              <Disclosure>
                {disclosure}
              </Disclosure>
            }
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default IrrWidget
