export const disableScrolling = () => {
  document.querySelector('body').style.overflow = 'hidden'
}

export const enableScrolling = () => {
  document.querySelector('body').style.overflow = 'visible'
}

export const scrollTo = (selector, offset) => {
  window.scroll({
    top: document.querySelector(selector).offsetTop - offset,
    behavior: 'smooth'
  });
}