import styled from "styled-components";
import * as Globals from "./../../Globals";
import breakpoint from "styled-components-breakpoint";
import theme from "../../../config/theme";

export const Container = styled.div`
  background: ${theme.colors.lightPurple};
  padding: 0px 7% 5% 7%;
`;

export const Heading = styled(Globals.H2)`
  font-size: 32px !important;
  line-height: 36px !important;
  margin-bottom: 25px;
  color: ${theme.colors.darkGrey};

  ${breakpoint("medium")`
    font-size: 48px !important;
    line-height: 44px !important;
    margin-bottom: 33px;
  `};
`;

export const Description = styled(Globals.Description)`
  margin-bottom: 23px;
`;

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
  background: ${theme.colors.mediumPurple};

  ${breakpoint("medium")`
    min-width: 185px;
    width: unset;
  `};
`;

export const InfoContainer = styled.div`
  padding: 0px;

  ${breakpoint("medium")`
    padding: 0px 40px;

    h2 {
      margin-top: 100px;
    }
  `};
`;

export const ImageContainer = styled.div`
  margin-bottom: 34px;
`;

export const ForbesLogo = styled.div`
  max-width: 84px;
`;

export const WsjLogo = styled.div`
  max-width: 53px;
`;

export const BloombergLogo = styled.div`
  max-width: 109px;
`;

export const MsnbcLogo = styled.div`
  max-width: 107px;
`;

export const QuotesContainer = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  height: 100%;
  padding: 0px 10px;
`;

export const Quote = styled(Globals.H2)`
  color: ${theme.colors.darkGrey};
  margin: 30px 0px 20px 0px;
  text-align: center;
  padding: 0px;

  ${breakpoint("medium")`
    margin: 121px 0px 45px 0px;
  `};
`;

export const LeftChevronContainer = styled.div`
  margin-left: 0px;
  position: relative;
  height: 100%;
  min-width: 12px;

  ${breakpoint("medium")`
    margin-left: 30px;
  `};

  img {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const RightChevronContainer = styled.div`
  text-align: right;
  margin-right: 30px;
  position: relative;
  height: 100%;
  min-width: 12px;

  img {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const PurpleLine = styled.div`
  border-top: 5px solid ${theme.colors.purple};
  width: 100px;
  margin: auto auto 45px auto;
`;

export const PressLogoContainer = styled.div`
  text-align: center;
  margin-bottom: 15px;

  img {
    height: 40px;
  }

  ${breakpoint("medium")`
    img {
      height: 55px;
    }
  `};

  ${breakpoint("medium")`
    margin-bottom: 50px;
  `};
`;

export const DotContainer = styled.div`
  text-align: center;
  margin-bottom: 41px;
`;

export const Dot = styled.div`
  display: inline-block;
  width: 8px;
  border-radius: 8px;
  margin: 0px 5px;
  height: 8px;
  background: ${(props) =>
    props.active ? `${theme.colors.darkGrey}` : "#28233140"};

  &:hover {
    cursor: pointer;
  }

  ${breakpoint("medium")`
    width: 13px;
    border-radius: 13px;
    height: 13px;
  `};
`;

export const ChevronContainer = styled.div``;

export const Spacer = styled.div`
  ${(props) => `height: ${props.height - 60}px;`}

  ${breakpoint("medium")`
    ${(props) => `height: ${props.height}px;`}
  `};
`;

export const Link = styled.a``;
