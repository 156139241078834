import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  margin: 60px 0px 0px 0px;

  ${breakpoint('medium')`
    margin: 73px 0px 0px 0px;
  `}
`;

export const DescriptionContainer = styled.div`
  margin: 0px;

  ${breakpoint('medium')`
    margin: 0px 60px 0px 0px;
  `}
`;

export const Heading = styled(Globals.H6)`
  font-size: 19px;
  line-height: 32px !important;
  font-weight: bold !important;
  color: ${theme.colors.backdropPurple};
  margin-top: 20px;
`;

export const Description = styled(Globals.P)`
  margin-top: 8px;
  font-size: 16px !important;
  line-height: 27px !important;
  color: ${theme.colors.backdropPurple};

  ${breakpoint('medium')`
    font-size: 24px !important;
    line-height: 36px !important;
  `}
`;

export const ListContainer = styled.div`
  box-shadow: 0px 0px 84px rgba(30, 9, 46, 0.12);
  border-radius: 8px;
  background-color: ${theme.colors.white};
  list-style-type: none;
  margin: 30px 0px;
  padding: 25px 25px 29px 23px;

  ${breakpoint('medium')`
    margin: 0px 40px 0px 0px;
    padding: 45px 15px 45px 53px;
  `}

  ${breakpoint('large')`
    margin: 0px 130px 0px 0px;
    padding: 55px 78px 51px 63px;
  `}
`;

export const ListItem = styled.div`
  position: relative;
`;

export const CheckmarkContainer = styled.div`
  position: absolute;
`;

export const InfoItem = styled.p`
  color: ${theme.colors.backdropPurple};
  ${Globals.proximaFontStack}
  line-height: 19px;
  margin-left: 45.5px;
  font-size: 16px;

  ${breakpoint('medium')`
    margin-left: 40px;
    font-size: 21px;
    line-height: 26px;
  `}
`;
