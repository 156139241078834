import styled from 'styled-components';
import * as Globals from '../../../Globals';

export const PopupHeading = styled(Globals.H2)``

export const Heading = styled(Globals.Body)`
  font-weight: 600;
  color: #282331;
`

export const Description = styled(Globals.Description)`
  color: #282331;
  margin-top: 20px;
`

export const DropArea = styled.div`
  border: 1px dashed #282331;
  display: block;
  padding: 30px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
`



export const DocumentList = styled.div`

`

export const Document = styled.div`
  margin-top: 10px;
`

export const DropzoneContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
