import React, { Fragment, useState, useEffect } from 'react';
import { PrefundingNotice, ModalHeading, Text } from './PrefundedPop.style'
import ModalPop from '../ModalPop'

function PrefundedPop({additionalCopy = "", smallCopy = false,
  popOnly = false, showPop = false, externalCloseHandler}) {

  const [prefundingPop, setPrefundingPop] = useState(false)

  useEffect(() => {
    if (showPop) {
      setPrefundingPop(true)
    }
  }, [showPop])

  const openPrefundingPop = () => {
    setPrefundingPop(true)
  }

  const closePrefundingPop = () => {
    setPrefundingPop(false)
    if (externalCloseHandler) {
      externalCloseHandler()
    }
  }

  const popContent =
    <Fragment>
      <ModalHeading>What does it mean that this deal was prefunded?</ModalHeading>
      <Text>
        Prefunding means that the LLC in which investors in this deal will participate has received initial funding to enable it
        to make its investment in the underlying legal claim. This initial funding includes a short-term loan from a LexShares
        affiliate and capital entirely or primarily from one or more investment funds managed by LexShares affiliates, which is
        deployed into deals prior to capacity being made available on LexShares.com. Investors’ equity investments into this deal
        will serve as permanent funding that will replace the short-term portion of the LLC’s prefunding capital.
      </Text>
      <Text>
        Additional information regarding prefunding and how it accomplishes a number of key objectives can be found on our <a href="/faq">FAQ </a>
        page and in the Investor Document Packet for each deal, which all investors should carefully review prior to investing.
        If you have questions about the investment structure, please contact us at <a href="mailto:investors@lexshares.com">investors@lexshares.com</a>.
      </Text>
    </Fragment>

  return (
    <Fragment>
      { popOnly == false &&
        <PrefundingNotice small={smallCopy}>
          {additionalCopy} This deal was prefunded. <a onClick={openPrefundingPop}> Learn more.</a>
        </PrefundingNotice>
      }
      <ModalPop
        popVisible={prefundingPop}
        content={popContent}
        hideHandler={closePrefundingPop}
      />
    </Fragment>
  )
}

export default PrefundedPop
