import React from 'react'
import { Transition } from 'react-transition-group'
import { Slide } from './SlideRight.style'

const duration = 300

const defaultStyle = {}

const transitionStyles = {
  entering: { transform: "translateX(-100%)" },
  entered:  { transform: "translateX(0%)" },
  exiting:  { transform: "translateX(0%)" },
  exited:  { transform: "translateX(-100%)" }
}

function SlideRight({show, children}) {
  return (
    <Transition in={show} timeout={duration}>
      {state => (
        <Slide style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
          { children }
        </Slide>
      )}
    </Transition>
  )
}

export default SlideRight;
