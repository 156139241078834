import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as Globals from "./../../Globals";
import theme from "../../../config/theme";

export const Container = styled.div`
  padding: 25px;

  ${breakpoint("medium")`
    padding: 30px 120px;
  `};
`;

export const Heading = styled(Globals.H2)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: ${theme.colors.darkPurple};
  font-weight: 900;
  margin-bottom: 25px;

  ${breakpoint("medium")`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
    margin-bottom: 70px;
  `}
`;

export const TrDescription = styled(Globals.P)`
  font-size: 32px !important;
  line-height: 39px !important;
  text-align: center;
  color: ${theme.colors.darkPurple};
  font-weight: 500;

  ${breakpoint("medium")`
    font-size: 36px !important;
    line-height: 42px !important;
    margin: 0px;
  `};

  ${breakpoint("large")`
    font-size: 42px !important;
    line-height: 52px !important;
  `};
`;

export const SmallTrDescription = styled(Globals.P)`
  font-size: 32px !important;
  line-height: 39px !important;
  text-align: center;
  color: ${theme.colors.darkPurple};
  font-weight: 500;

  ${breakpoint("medium")`
    margin: 0px;
  `};

  ${breakpoint("large")`
    font-size: 33px !important;
    line-height: 42px !important;
  `};
`;

export const ImageContainer = styled.div`
  position: absolute;
  padding-top: 20px;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, 0%);

  ${breakpoint("medium")`
    padding-top: 0px;
  `};

  img {
    width: 44px;
  }
`;

export const ImageBackground = styled.div`
  margin: auto;
  -moz-border-radius: 60px;
  border-radius: 60px;
  background-color: white;
  color: white;
  text-align: center;
  font-size: 2em;
  height: 100px;
  width: 100px;
  line-height: 91px;
  border: 1px solid ${theme.colors.lighterGrey};
`;

export const ViewCaseButton = styled(Globals.ButtonLink)`
  width: 100%;
  background: ${theme.colors.mediumPurple};
  margin-top: 30px;

  ${breakpoint("medium")`
    min-width: 185px;
    width: unset;
    margin-top: 0px;
  `};
  ${breakpoint("large")`
    min-width: 232px;
    margin-top: 35px;
  `};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 55px;
`;

export const CellContent = styled.div`
  background: white;
  padding: 40px 30px 60px 30px;
  margin-bottom: 75px;
  position: relative;
  border: 1px solid ${theme.colors.lighterGrey};

  ${breakpoint("medium")`
    margin-right: 35px;
  `};

  ${breakpoint("large")`
    min-height: 275px;
    margin-right: 65px;
    margin-bottom: 25px;
  `};
`;
