import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Content, Heading, Subheading, InvestmentContainer } from './CallToActionSection.style';
import { InvestmentCard } from '../../funding';

function CallToActionSection({investment}) {
  return (
    <Fragment>
      <Section>
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12} large={6}>
              <Heading>
                New to LexShares? Start investing today.
              </Heading>

              <Subheading>
                By signing up as an investor, you will be notified of every offering, like our most recent investment pictured here.
              </Subheading>
            </Cell>
            <Cell small={12} large={6}>
              <InvestmentContainer>
                <InvestmentCard
                  {...investment}
                  background="#FFFFFF"
                  linkText="Get Started"
                  linkUrl="/invest/signup"
                />
              </InvestmentContainer>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default CallToActionSection;
