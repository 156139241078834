import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';

export const Card = styled.a`
  width: 100%;
`

export const FeatureImage = styled.img`
  width: 100%;
  height: 200px;
  background: linear-gradient(0deg, rgba(130, 71, 186, 0.5), rgba(130, 71, 186, 0.5)), url(${props => props.src});
  background-blend-mode: overlay, normal;
  border-radius: 5px;
  object-fit: cover;

  ${breakpoint('medium')`
    height: 166px;
  `}
`

export const Tag = styled.span`
  color: ${theme.colors.purpleGrey};
  height: 24px;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 15px;
  ${Globals.proximaFontStack}

  &:hover {
    color: ${theme.colors.purpleGrey};
  }
`

export const Title = styled(Globals.H6)`
  ${Globals.tiempoFontStack};
  color: ${theme.colors.backdropPurple};
  line-height: 1.4 !important;
  letter-spacing: -0.5px;
  font-weight: normal;
  margin-top: 10px;
  flex-basis: 100%;

  &:hover {
    color: ${theme.colors.backdropPurple};
  }

  ${breakpoint('medium')`
    line-height: 32px !important;
  `}
`;

export const Excerpt = styled.p`
  ${Globals.proximaFontStack}
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.backdropPurple};
  margin-top: 13px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;