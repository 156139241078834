import React, {Fragment, useEffect, useState} from 'react'
import LexSharesPortfolio from './LexSharesPortfolio'
import PersonalPortfolio from './PersonalPortfolio'
import FundPortfolio from './FundPortfolio'
import ReactTooltip from 'react-tooltip'
import axios from '../../Axios'
import {
  Content,
  Heading,
  Tabs,
  Link,
  Lock
} from './PortfolioView.style'

/**
 * Wrapper view for LexShares, individual and fun related portfolio sub views.
 */
function PortfolioView({currentTab, tabClickHandler, investmentCount,
  extendedCaseVisibility, hasInvestments = false}) {

  let [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`/widget/portfolio.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Fragment>
      <Content>
        { data &&
          <Fragment>
            <Heading>Investment Portfolio</Heading>
            <Tabs>
              <Link
                className={currentTab == 'lexshares' ? 'selected' : ''}
                onClick={tabClickHandler}
                data-name="lexshares"
              >
                LexShares
              </Link>
              { (hasInvestments || (!hasInvestments && (data.length == 0))) &&
                <Link
                  className={currentTab == 'personal' ? 'selected' : hasInvestments ? '' : 'locked'}
                  onClick={hasInvestments ? tabClickHandler : null}
                  data-tip="Unlock your Personal tab after you make your first investment."
                  data-place="bottom"
                  data-name="personal"
                >
                  Personal
                  { !hasInvestments &&
                    <Fragment>
                      <Lock />
                      <ReactTooltip />
                    </Fragment>
                  }
                </Link>
              }
              { data && data.map((fund, i) => (
                <Link
                  key={`tab-link-${i}`}
                  className={currentTab == fund.name.toLowerCase() ? 'selected' : ''}
                  onClick={tabClickHandler}
                  data-name={fund.name}
                >
                  {fund.name}
                </Link> 
              ))}
            </Tabs>
            { currentTab == 'lexshares' &&
              <LexSharesPortfolio investmentCount={investmentCount} extendedCaseVisibility={extendedCaseVisibility} />
            }
            { (currentTab == 'personal') && hasInvestments &&
              <PersonalPortfolio investmentCount={investmentCount} />
            }
            { data && data.map((fund, i) => (
              currentTab == fund.name.toLowerCase() &&
                <FundPortfolio
                  key={`fund-portfolio-${i}`}
                  parentFundId={fund.parent_id}
                  fundId={fund.id}
                  investmentCount={investmentCount}
                />
            ))}
          </Fragment>
        }
      </Content>
    </Fragment>
  )
}

export default PortfolioView
