import React from 'react';
import { Grid, Cell } from 'react-foundation';
import { Container, Description, DescriptionContainer, Heading, ListContainer, ListItem, CheckmarkContainer, InfoItem } from './WhitepaperTopics.style';
import CheckBlackImg from '../../../../assets/images/icons/checkmark-black.png';

const WhitepaperTopics = ({whitepaper}) => {
  return (
    <Container>
      <Grid>
        <Cell small={12} medium={6}>
          <DescriptionContainer>
            <Heading>About This Resource</Heading>
            <Description>{whitepaper.description}</Description>
          </DescriptionContainer>
        </Cell>
        <Cell small={12} medium={6}>
          {whitepaper.topics.length > 0 &&
            <ListContainer>
              {whitepaper.topics.map((e) => (
                <ListItem key={e}>
                  <CheckmarkContainer>
                    <img src={CheckBlackImg} />
                  </CheckmarkContainer>
                  <InfoItem>{e}</InfoItem>
                </ListItem>
              ))}
            </ListContainer>
          }
        </Cell>
      </Grid>
    </Container>
  )
}

export default WhitepaperTopics;