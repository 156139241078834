import React, {Fragment} from 'react'
import FaqWidget from '../SharedWidgets/FaqWidget'

/**
 * Displays portfolio specific side bar
 */
function SideColumn({investmentCount, advisorText}) {

  const sideWidgets = () => {
    let widgets = []

    if (investmentCount <= 1) {
      widgets = [
        <FaqWidget key="faq-widget" />
      ]
    }
    else {
      widgets = [
      ]
    }

    return widgets
  }

  return (
    <Fragment>
      {
        sideWidgets().map((widget, i) =>
          <Fragment key={`side-column-widget-${i}`}>
            {widget}
          </Fragment>
        )
      }
    </Fragment>
  )
}

export default SideColumn
