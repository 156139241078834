import React from "react";
import { Cell, Grid } from "react-foundation";
import {
  Container,
  Heading,
  ButtonContainer,
  ReadCaseStudiesButton,
  Paragraph,
  Spacer,
} from "./CaseStudySamples.style";
import InfoCard from "./InfoCard";
import CaseStudyCard from "./CaseStudyCard";

const CaseStudySamples = ({ heading, caseStudies, subtext = "" }) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      {caseStudies.map((caseStudy, i) => (
        <Grid key={i} className="grid-margin-x grid-margin-y">
          <Cell
            small={12}
            medium={6}
            className={
              i % 2 === 0
                ? "small-order-1 medium-order-1"
                : "small-order-2 medium-order-1"
            }
          >
            {i % 2 === 0 ? (
              <InfoCard
                heading={caseStudy.heading}
                subheading={caseStudy.subheading}
              />
            ) : (
              <CaseStudyCard
                caseType={caseStudy.caseType}
                fundingType={caseStudy.fundingType}
                caseFundingAmount={caseStudy.caseFundingAmount}
                caseDescription={caseStudy.caseDescription}
                iconPath={caseStudy.iconPath}
                iconPosition="left"
              />
            )}
            <Spacer height={100} />
          </Cell>
          <Cell
            small={12}
            medium={6}
            className={
              i % 2 === 1
                ? "small-order-1 medium-order-2"
                : "small-order-2 medium-order-2"
            }
          >
            {i % 2 === 1 ? (
              <InfoCard
                heading={caseStudy.heading}
                subheading={caseStudy.subheading}
              />
            ) : (
              <CaseStudyCard
                caseType={caseStudy.caseType}
                fundingType={caseStudy.fundingType}
                caseFundingAmount={caseStudy.caseFundingAmount}
                caseDescription={caseStudy.caseDescription}
                iconPath={caseStudy.iconPath}
                iconPosition="right"
              />
            )}
            <Spacer height={100} />
          </Cell>
        </Grid>
      ))}
      {subtext && <Paragraph>{subtext}</Paragraph>}
      <ButtonContainer>
        <ReadCaseStudiesButton href="/case-studies">
          Read Case Studies
        </ReadCaseStudiesButton>
      </ButtonContainer>
    </Container>
  );
};

export default CaseStudySamples;
