import React, {Fragment, useEffect, useState} from 'react'
import {ResponsiveLine} from '@nivo/line'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  ChartContainer,
  Heading,
  DateRange,
  Text,
  Disclosure
} from './InvestmentHistoryWidget.style'

/**
 * Displays quarter by quarter LexShares performance. The data is manually set
 * and pulled from admin > site > investment history.
 */
function InvestmentHistoryWidget() {
  let [performance, setPerformance] = useState(null);

  const theme = useTheme()
  const mediumUp = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    axios.get(`/widget/lexshares-history.json`).then(res => {
      setPerformance(res.data)
    })
  }, [])

  return (
    <Widget className="js-ls-investment-activity-widget">
      <Content loading={performance ? "false" : "true"}>
        { performance &&
          <Fragment>
            <Heading>LexShares Investment Activity by Quarter</Heading>
            <DateRange>{performance.disclosure.period}</DateRange>
            <Text>{performance.disclosure.description}</Text>
            <ChartContainer>
              <ResponsiveLine
                theme={
                  {
                    textColor: '#F8F7FA',
                    fontSize: '10px',
                    fontWeight: '300',
                    crosshair: {line: {stroke: '#cccccc'}},
                    legends: {
                      text: {
                        fontSize: 13,
                        fontFamily: 'proxima-nova'
                      }
                    },
                    axis: {
                      domain: {
                        line: {
                          stroke: '#323034',
                          strokeWidth: 1
                        }
                      }
                    }
                  }
                }
                colors={['#8247BA', '#1D86B4']}
                data={performance.history.data}
                enablePoints={true}
                enableSlices={'x'}
                enableArea={true}
                areaBlendMode={'normal'}
                areaOpacity={0.1}
                curve={'monotoneX'}
                margin={{top: 15, right: 15, bottom: mediumUp ? 85 : 100, left: 25}}
                xScale={{type: 'point' }}
                yScale={{type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false}}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                sliceTooltip={({ slice }) => {
                  return (
                    <div
                      style={{
                        background: '#1E1924',
                        padding: '8px',
                        border: '1px solid #0b0a0d',
                        borderRadius: '5px'
                      }}
                    >
                    <div
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '15px'
                      }}        
                    >
                      {slice.points[0].data.xFormatted}
                    </div>
                    {slice.points.sort((a, b) => {return a.index - b.index}).map(point => (
                      <div
                        key={point.id}
                        style={{
                          color: point.serieColor,
                          padding: '3px 0',
                          fontSize: '14px'
                        }}
                      >
                        <strong>{point.serieId}:</strong> {point.serieId == 'Case Investments' ? parseInt(point.data.yFormatted) : `$${parseFloat(point.data.yFormatted).toFixed(1)}M`}
                      </div>
                    ))}
                  </div>
                  )
                }}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 0,
                  tickPadding: 10,
                  tickRotation: -45,
                  legend: '',
                  legendOffset: 36,
                  legendPosition: 'middle',
                  domain: {line: {stroke: '#323034', strokeWidth: 1}}
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 0,
                  tickPadding: 10,
                  tickRotation: 0,
                  tickValues: 6,
                  legend: '',
                  legendOffset: -40,
                  legendPosition: 'middle'
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={11}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={1}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: 'bottom-left',
                    direction: mediumUp ? 'row' : 'column',
                    justify: false,
                    translateX: -10,
                    translateY: mediumUp ? 80 : 95,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 150,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 14,
                    symbolShape: 'circle',
                    fontSize: 14,
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
              />
            </ChartContainer>
            <Disclosure>
              {performance.disclosure.disclosure}
            </Disclosure>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default InvestmentHistoryWidget
