import styled from "styled-components";
import breakpoint from 'styled-components-breakpoint';
import theme from '../../config/theme';

export const Container = styled.div`
  max-width: 400px;
  margin: auto;
  position: relative;
`;

export const ImageContainer = styled.div`
  position: absolute;
  border: 3px solid ${theme.colors.lightPurple};
  background: ${theme.colors.white};
  border-radius: 60px;
  ${props => props.iconPosition}: -20px;
  bottom: -20px;
  margin: auto;
  line-height: 40px;
  text-align: center;
  width: 50px;
  height: 50px;

  img {
    width: 20px;
  }
  
  ${breakpoint('medium')`
    width: 100px;
    height: 100px;
    line-height: 90px;
    bottom: -50px;
    ${props => props.iconPosition}: -50px;

    img {
      width: 36px;
    }
  `}
`;