import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, InfoCard, CardHeader, CardDivider, CardDescription, Stat, Value, Label, Logos, LinkContainer, Link, BannerProp, Content } from './InfoSection.style';
import DiamondArrayImg from '../../../../assets/images/backgrounds/diamond-array-purple.png';
import PressLogoImg from '../../../../assets/images/logos/press-logo-in-house.png';
import ArrowImg from '../../../../assets/images/icons/arrow.png';

function InfoSection({metrics = null}) {
  return (
    <Fragment>
      <Section>
        <Content>
          <BannerProp>
            <img src={DiamondArrayImg} />
          </BannerProp>
          <Grid className="grid-margin-x grid-padding-x">
            <Cell small={12} large={6}>
              <InfoCard>
                <CardHeader>
                  A diversified investment portfolio
                </CardHeader>

                <CardDivider></CardDivider>

                <CardDescription>
                  Our technology-optimized approach to deal origination, and experienced in-house legal underwriting team has helped drive investment performance since 2014.
                </CardDescription>

                <Grid>
                  <Cell large={6} small={12}>
                    <Stat>
                      <Value>
                        {metrics.total_investments}
                      </Value>
                      <Label>
                        Investments as of 6/30/21
                      </Label>
                    </Stat>
                  </Cell>
                  <Cell large={6} small={12}>
                    <Stat>
                      <Value style={{marginLeft: "-30px", marginRight: "-30px"}}>
                        {metrics.median_duration_months}
                      </Value>
                      <Label>
                        Median duration of resolved investments as of 6/30/21
                      </Label>
                    </Stat>
                  </Cell>
                </Grid>

              </InfoCard>
            </Cell>
            <Cell small={12} large={6}>
              <InfoCard>
                <CardHeader>
                  One of the most active funders in the industry
                </CardHeader>

                <CardDivider></CardDivider>

                <CardDescription>
                  LexShares’ Diamond Mine technology has sourced more than one million case investment prospects--representing billions in funding opportunities.

                </CardDescription>

                <Logos>
                  <img src={PressLogoImg} />
                </Logos>
                <LinkContainer>
                  <Link href="/press">
                    View Press
                    <img src={ArrowImg} />
                  </Link>
                </LinkContainer>

              </InfoCard>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default InfoSection;
