import React, {Fragment} from 'react'
import {ResponsiveBar} from '@nivo/bar'
import {
  ChartContainer,
  Legend,
  LegendLabel,
  Break
} from './Bar.style'

/**
 * Generates a Nivo bar chart using a LexShares theme with custom legend.
 * Data format: [{type: 'Breach of contract', count: 3}, ..]
 */
function Bar({data, wantLegend = true}) {

  const colors = [
    '#9238DA',
    '#580C75',
    '#2A2134',
    '#9EA1A4',
    '#9EA1A4',
    '#626466',
    '#DCE1E6',
    '#D29DFE',
    '#9238DA',
    '#580C75',
    '#2A2134',
    '#524066',
    '#B891E6',
    '#A481CC',
    '#604C78',
    '#B952F9'
  ]

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  return (
    <Fragment>
      <ChartContainer>
        <ResponsiveBar
          theme={
            {
              tooltip: {container: {background: '#1E1924', border: '1px solid #0b0a0d'}},
              textColor: '#F8F7FA',
              fontSize: '10px',
              fontWeight: '300'
            }
          }
          data={data}
          enableGridX={false}
          enableGridY={false}
          enableLabel={false}
          keys={['count']}
          indexBy="type"
          colorBy="index"
          margin={{top: 0, right: 0, bottom: 0, left: 0}}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{enable: false}}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          borderColor="#ffffff"
          onMouseEnter={(_data, event) => {
            event.target.style.strokeWidth = '2'
          }}
          onMouseLeave={(_data, event) => {
            event.target.style.strokeWidth = '0'
          }}
          tooltip={(params) => {
            return (
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  color: 'white'
                }}
              >
                <Fragment>
                  {capitalize(params.data.type)}
                  <span
                    style={{fontWeight: 'normal', display: 'block'}}
                  >
                    {params.data.count} {`case${params.data.count > 1 ? 's' : ''}`}
                  </span>
                </Fragment>
              </span>
            )
          }}
        />
      </ChartContainer>
      { wantLegend &&
        <Legend>
          { data.map((point, i) => (
            <LegendLabel key={`legend-key${i}`} color={colors[i]}>{point.type}</LegendLabel>
          ))}
          <Break />
        </Legend>
      }
    </Fragment>
  )
}

export default Bar
