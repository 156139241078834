import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';



export const Heading = styled(Globals.H2)`
  margin-top: 20px;
  font-size: 32px;
  ${breakpoint('medium')`
    font-size: 32px;
  `}
  ${breakpoint('large')`
    font-size: 32px;
  `}
`

export const Header = styled.div`
  padding: 20px;
  margin: -15px 40px 0px;
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  font-size: .9rem;
  font-weight: 600;
  background: #9450d4;
  background: linear-gradient(90deg, #9450d4 0%, #591db4 100%);
}
`

export const Body = styled.div`
  color: ${props => props.theme.colors.black};
`

export const Description = styled(Globals.Description)`
  margin-bottom: 20px;
  color: ${props => props.theme.colors.black};
`

export const Type = styled.div``

export const Details = styled.div`
  color: ${props => props.theme.colors.mediumPurple};
  margin-bottom: 30px;
`

export const Divider = styled.div`
  border: 3px solid ${props => props.theme.colors.black};
  border-radius: 10px;
  width: 15px;
  margin: 20px auto;
`

export const Link = styled.div`
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .9rem;
  margin: 5px 0;
  color: inherit;
  display: inline-block;
  &:hover {
    color: inherit;
  }
`

export const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid;
  border-color: ${props => props.theme.colors.mediumGrey};

  &::after {
    content: '\f0da';
    font-family: 'FontAwesome';
    top: 0px;
    margin-left: 5px;
    font-size: 14px;
    position: relative;
    color: ${props => props.theme.colors.black}
  }
`

export const Card = styled.div`
  position: relative;
  border: 1px solid #8247ba;
  text-align: center;
  overflow: visible;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 5px;
  transition: all .5s ease;
  margin-bottom: 20px;
  min-height: 360px;
  background: #FFFFFF;
  &:hover {
    transition: all .4s ease;
    transform: translate3D(0,-1px,0) scale(1.02);
  }
`

export const Container = styled.a`
  padding: 10px;
  width: 392px !important;
  padding-top: 20px;
  margin: 0 auto;
  max-width: 100%;

  display: block;
  color: unset;

  &:hover {
    color: unset;
  }

  &:focus, &:visited {
    color: unset;
  }
`
