import styled from 'styled-components'

export const LsRadio = styled.div`
  padding: 10px;
  position: relative;
`

export const RadioButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #dfdddd;
  margin-top: 18px;
  &:after {
    content: "";
    position: absolute;
    display: none;
    border-radius: 50%;
    background: white;
  }
  &.error {
    border: 1px solid red;
  }
`

export const Input = styled.input`
  position: absolute !important;
  margin-top: -7px;
  margin-left: -7px;
  position: relative;
  z-index: 10;
  opacity: 0;
  width: 100%;
  height: 100%;
  &:checked ~ ${RadioButton} {
    background-color: ${props => props.theme.colors.teal};
    border: 1px solid ${props => props.theme.colors.teal};
    &:after {
      display: block;
      top: 5px;
      left: 5px;
      width: 14px;
      height: 14px;
    }
  }
`

export const TextContainer = styled.div`
  margin-left: 25px;
  margin-top: -2px;
  line-height: 1.6rem;
  min-width: 250px;
`

export const Text = styled.p`
  font-size: 16px;
  &:nth-child(1) {
    margin: 8px 0 0 10px;
  }
  &:nth-child(2) {
    margin: 0px 0 0 10px;
  }
`

export const Label = styled.label`
  font-weight: bold;
  text-transform: uppercase;
`
