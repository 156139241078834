import styled from 'styled-components'
import * as Globals from '../../Globals'

export const Content = styled.div`
`

export const Heading = styled(Globals.H3)`
  color: white;
  margin-bottom: 50px;
`

export const Text = styled.p`
  color: white;
`
