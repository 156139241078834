import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import { BannerSection, CategoriesSection } from '../../components/faqs';
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from '../../components/Footer'

const FaqsPage = styled.div`
  background-color: ${props => props.theme.colors.white};
`

class Faqs extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar theme="transparent" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor} currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <FaqsPage>
            <BannerSection />
            <CategoriesSection categories={this.props.categories} />
          </FaqsPage>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Faqs;
