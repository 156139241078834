import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Banner = styled.div`
  background-color: #282331;
`

export const BannerContent = styled.div`
  padding: 12% 3% 10% 3%;
  text-align: center;

  ${breakpoint('medium')`
    padding: 7% 3% 6% 3%;
  `}
`

export const Heading = styled.h1`
  text-align: center;
  color: white;
  margin-top: 3%;
  ${breakpoint('medium')`
    line-height: 66px;
  `}
`
