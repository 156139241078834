import styled from 'styled-components';
import * as Globals from '../../Globals';
import breakpoint from 'styled-components-breakpoint';
import leftArrowIcon from '../../../../assets/images/icons/left-arrow.png';
import rightArrowIcon from '../../../../assets/images/icons/right-arrow.png';

export const Section = styled.div`
  background: white;
`;

export const Content = styled.div`
  max-width: 1330px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  margin-bottom: 20px;
  text-align: center !important;
`;

export const Subheading = styled(Globals.P)`
  text-align: center;
  width: 850px;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 100%;
`;

export const CarouselContainer = styled.div`
  width: 100%;
`

export const LeftArrow = styled.div`
  background: url(${leftArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const RightArrow = styled.div`
  background: url(${rightArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const Link = styled.a`
  color: #8247ba;
  text-decoration: underline;
  &:hover, &:focus {
    color: #8247ba;
  }
`
