import React, {Fragment, useEffect} from 'react'
import {ResponsivePie} from '@nivo/pie'

function Pie({data, heading, bigHeading, capitalizeDataLabel = true}) {

  useEffect(() => {}, [data, heading,bigHeading])

  const colors = [
    '#9238DA',
    '#580C75',
    '#2A2134',
    '#9EA1A4',
    '#9EA1A4',
    '#D29DFE',
    '#9238DA',
    '#580C75',
    '#2A2134'
  ]

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  const centeredText = ({centerX, centerY}) => {
    if(bigHeading) {
      return (
        <>
          <text
          x={centerX}
          y={centerY - 15}
          textAnchor="middle"
          dominantBaseline="central"
          fill="white"
          style={{
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '32px'
          }}
        >
          {bigHeading}
        </text>
        <text
          x={centerX}
          y={centerY + 15}
          textAnchor="middle"
          dominantBaseline="central"
          fill="white"
          style={{
            fontSize: '16px',
            fontWeight: '300',
            lineHeight: '16px'
          }}
        >
          {heading}
        </text>
      </>)
    }

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="white"
        style={{
          fontSize: '12px',
          fontWeight: '300',
          lineHeight: '16px'
        }}
      >
        {heading}
      </text>
    )
  }

  return (
    <ResponsivePie
      data={data}
      theme={{
        tooltip: {container: {background: '#1E1924', border: '1px solid #0b0a0d'}}
      }}
      margin={{top: 5, right: 0, bottom: 5, left: 0}}
      innerRadius={0.85}
      colors={colors}
      borderWidth={0}
      padAngle={2}
      enableRadialLabels={false}
      enableSliceLabels={false}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor="#333333"
      legends={[]}
      isInteractive={true}
      layers={['slices', 'sliceLabels', 'radialLabels', 'legends', centeredText]}
      borderColor="#ffffff"
      onMouseEnter={(_data, event) => {
        event.target.style.strokeWidth = '2'
      }}
      onMouseLeave={(_data, event) => {
        event.target.style.strokeWidth = '0'
      }}
      tooltip={(params) => {
        return (
          <span
            style={{
              fontSize: '10px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#1E1924',
              border: '1px solid #0b0a0d',
              padding: '15px 10px',
              display: 'block'
            }}
            >
            <Fragment>
              {capitalizeDataLabel ? capitalize(params.datum.data.label) : params.datum.data.label}
              <span
                style={{fontWeight: 'normal', display: 'block'}}
              >
                {params.datum.data.valueLabel || `${params.datum.data.value} case${params.datum.data.value > 1 ? 's' : ''}`}
              </span>
            </Fragment>
          </span>
        )
      }}
    />
  )
}

export default Pie
