import styled from "styled-components";
import * as Globals from "./../Globals";
import breakpoint from "styled-components-breakpoint";
import theme from "../../config/theme";

export const Container = styled.div`
  padding: 25px;

  ${breakpoint("medium")`
    padding: 35px 75px;
  `};

  ${breakpoint("large")`
    padding: 75px 175px;
  `};
`;

export const Heading = styled(Globals.H1)`
  color: ${theme.colors.darkGrey};
  text-align: center;
  margin-bottom: 25px;

  ${breakpoint("medium")`
    margin-bottom: 78px;
  `};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 55px;
`;

export const ReadCaseStudiesButton = styled(Globals.ButtonLink)`
  width: 100%;
  background: ${theme.colors.mediumPurple};

  ${breakpoint("medium")`
    min-width: 185px;
    width: unset;
  `};
`;

export const Spacer = styled.div`
  ${(props) => props.height && `height: ${props.height - 80}px;`}

  ${breakpoint("medium")`
    ${(props) => props.height && `height: ${props.height}px;`}
  `};
`;

export const Paragraph = styled.p`
  font-family: "proxima-nova" !important;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 50px;
  color: #0a0a0a;
  padding: 0px;

  ${breakpoint("large")`
    padding: 0px 210px;
  `};
`;
