import React, {Fragment} from 'react'
import {Cell, Grid} from 'react-foundation'
import DownloadCard from '../../DownloadCard'
import {
  Section,
  Content,
  ColumnHeading,
  BigHeading,
  Subcopy
} from './CaseDocuments.style'

function CaseDocuments({packet, psa, documents, visible, marketplaceFund, trackedData}) {
  return (
    <Section className={visible ? "selected" : ""}>
      <Content>
        <Grid className="grid-margin-x">
          <Cell small={12}>
            <ColumnHeading>Documents</ColumnHeading>
            { marketplaceFund
              ?
                <BigHeading>Fund Updates</BigHeading>
              :
                <Fragment>
                  <BigHeading>Case Resources</BigHeading>
                  <Subcopy>The following documents are representative in nature, and include select documents provided by plaintiff’s counsel related to the case.</Subcopy>
                </Fragment>
              }
              <Grid className="grid-margin-x">
                { documents && documents.map((doc, i) =>
                  <Cell small={12} medium={6} large={3} key={`case-doc-cell-${i}`}>
                    <DownloadCard
                      date={doc.date}
                      title={doc.title}
                      description={doc.description}
                      link={doc.link}
                      text="View"
                      track={true}
                      trackedData={trackedData}
                    />
                  </Cell>)
                }
              </Grid>
            { marketplaceFund
              ?
                <BigHeading>Fund Notices</BigHeading>
              :
                <BigHeading>LexShares Investment Documents</BigHeading>
            }
            <Grid className="grid-margin-x">
              <Cell small={12} medium={6} large={3}>
                <DownloadCard
                  title={packet.title}
                  description={packet.description}
                  link={packet.link}
                  text="View"
                  track={true}
                  trackedData={trackedData}
                />
              </Cell>
              <Cell small={12} medium={6} large={3}>
                {psa.link &&
                  <DownloadCard
                    title={psa.title}
                    description={psa.description}
                    link={psa.link}
                    text="Download"
                    track={true}
                    trackedData={trackedData}
                  />
                }
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Content>
    </Section>
  )
}

export default CaseDocuments
