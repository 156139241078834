import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import * as Globals from '../../Globals'

export const Section = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  .card-container {
    margin-bottom: 15px;
  }

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H3)`
  text-align: center;
  margin-bottom: 40px;
  font-size: 25px;
  line-height: 32px;
`;

export const Subheading = styled.div`
  font-size: 1.5rem;
  margin-bottom: 24px;
`

export const Description = styled(Globals.Description)`
  margin-bottom: 16px;
`

export const Button = styled(Globals.ButtonLink)`
  margin: 0 auto;
  background: transparent !important;
  color: #8247ba;
  border: 2px solid #8247ba;
  padding: 13px 24px;
  margin: 0 auto;
  width: 140px;
  margin-top: 48px;
  display: block;
  &:hover {
    background: transparent !important;
    color: #8247ba !important;
    opacity: .7;
  }
`

export const InvestmentContainer = styled.div`
  display: block;
  padding-bottom: 40px;

  ${breakpoint('medium')`
    width: 392px;
    margin: 0 auto;
  `}

  ${breakpoint('large')`
    width: 100%;
  `}
`;

export const MarginCell = styled.div`
  ${breakpoint('large')`
    margin-right: 30px;
  `}
`
