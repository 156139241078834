import React from "react";
import {
  CaseType,
  Container,
  DealSize,
  Description,
  Pill,
} from "./InvestmentCardWhite.style";

const InvestmentCardWhite = ({ investment, className = "" }) => {
  return (
    <Container className={className}>
      <CaseType>{investment.case_type}</CaseType>
      <DealSize>{investment.long_offering_size}</DealSize>
      <Description
        dangerouslySetInnerHTML={{
          __html: investment.case_description,
        }}
      />
      <Pill>{investment.funding_type}</Pill>
    </Container>
  );
};

export default InvestmentCardWhite;
