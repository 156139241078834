import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../components/Globals'

export const Content = styled(Globals.Section)`
  ${breakpoint('medium')`
    padding-top: 13%;
  `}
  ${breakpoint('large')`
    padding-top: 9%;
  `}
   z-index: 9;
`

export const Heading = styled(Globals.H2)`
  color: white;
  text-align: center;
  margin-top: 7%;
  font-size: 32px;
  line-height: unset;
  ${breakpoint('large')`
    font-size: 48px;
  `}
`

export const Subheading = styled.p`
  ${Globals.proximaFontStack};
  color: white;
  max-width: 560px;
  margin: 0 auto;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 65px;
`

export const StepsContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

export const StepNumber = styled.span`
  ${Globals.tiempoFontStack};
  font-size: 42px;
  color: ${props => props.theme.colors.purple};
`

export const StepHeading = styled.span`
  display: block;
  color: white;
  font-weight: bold;
  font-siZe: 18px;
  margin-top: 10px;
`

export const StepDescription = styled.p`
  color: white;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  margin-top: 15px;
`

export const LinkContainer = styled.div`
  text-align: center;
  margin-top: 70px;
`

export const Button = styled(Globals.ButtonLink)`
  margin-bottom: 15px;
`

export const Link = styled(Globals.Link)`
  font-size: 14px;
  display: block;
  margin-bottom: 50px;
`

export const DarkDiamonds = styled(Globals.DarkDiamonds)`
  z-index: 0;
  bottom: 0px;
  display: none;
  ${breakpoint('large')`
    display: block;
  `}
`
