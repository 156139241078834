import React, { useRef } from "react";
import axios from "axios";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { Grid, Cell } from "react-foundation";
import * as yup from "yup";
import { Container, Form, FormContainer, Heading } from "./FormSection.style";
import InputSelect from "../../Form/InputSelect";
import InputField from "../../Form/InputField";
import InputButton from "../../Form/InputButton";

const roleTypes = [
  "Commercial litigator",
  "Mass tort litigator",
  "Corporate counsel",
  "Business owner, founder, or executive",
  "Individual plaintiff",
  "Investor",
  "Litigation funder or broker",
  "Student or educator",
];

const yesNo = ["Yes", "No"];

const fundingSoughtOptions = [
  "Less than $200,000",
  "Between $200,000 and $1 million",
  "Between $1 million and $5 million",
  "More than $5 million",
];

const FormSection = ({ csrfToken }) => {
  const formRef = useRef(null);
  const schema = yup.object().shape({
    name: yup.string().required().label("Name"),
    email: yup.string().email().required().label("Business Email"),
    company: yup.string().required().label("Company Name"),
    role_type: yup.string().required().label("Role Type"),
    seeking_funding: yup
      .string()
      .required()
      .oneOf(["Yes", "No"], "required")
      .label("Seeking Funding"),
    funding_sought: yup.string().when("seeking_funding", {
      is: "Yes",
      then: yup.string().required().label("Funding Amount"),
    }),
    how_did_you_hear_about_us: yup
      .string()
      .required()
      .label("How did you hear about us"),
  });

  const defaultValues = {
    name: "",
    email: "",
    company: "",
    role_type: "",
    seeking_funding: "",
    how_did_you_hear_about_us: "",
  };

  const roleTypeOptions = roleTypes.map((label) => {
    return {
      label,
      value: label,
    };
  });

  const yesNoOptions = yesNo.map((label) => {
    return {
      label,
      value: label,
    };
  });

  const fundingSoughtSelectOptions = fundingSoughtOptions.map((label) => {
    return {
      label,
      value: label,
    };
  });

  const handleSubmit = (data) => {
    const payload = {
      barometer: data,
      authenticity_token: csrfToken,
    };

    axios
      .post(`/barometer`, payload)
      .then(() => {
        formRef.current.resetForm();
        window.location = "/barometer?barometer_confirmation=true";
      })
      .catch((e) => {
        const shared_ui = new LexShares.SharedUI();
        shared_ui.showAlert("error", e.response.data.error, false);
      })
      .then(() => {
        formRef.current.setSubmitting(false);
      });
  };

  return (
    <Container>
      <FormContainer id="barometer-form-container">
        <Heading>Read the Inaugural Barometer Report</Heading>
        <Formik
          initialValues={defaultValues}
          onSubmit={(e) => handleSubmit(e)}
          validationSchema={schema}
          innerRef={formRef}
        >
          {({
            errors,
            handleSubmit,
            handleChange,
            isSubmitting,
            values,
            dirty,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid className="grid-margin-x">
                <Cell small={12}>
                  <InputField
                    type="text"
                    label="Name*"
                    name="name"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={touched.name && errors.name}
                    value={values.name}
                  />
                </Cell>
                <Cell small={12}>
                  <InputField
                    type="email"
                    label="Business Email*"
                    name="email"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={touched.email && errors.email}
                    value={values.email}
                  />
                </Cell>
                <Cell small={12}>
                  <InputField
                    type="text"
                    label="Firm/company name*"
                    name="company"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={touched.company && errors.company}
                    value={values.company}
                  />
                </Cell>
                <Cell small={12}>
                  <InputSelect
                    label="Which best describes you?*"
                    name="role_type"
                    options={roleTypeOptions}
                    error={touched.role_type && errors.role_type}
                    value={roleTypeOptions.find(
                      (option) => option.value === values.role_type
                    )}
                    changeHandler={(option) =>
                      setFieldValue("role_type", option.value)
                    }
                  />
                </Cell>
                <Cell small={12}>
                  <InputSelect
                    label="Are you an active litigant or counsel to a litigant seeking litigation funding?*"
                    name="seeking_funding"
                    options={yesNoOptions}
                    error={touched.seeking_funding && errors.seeking_funding}
                    value={yesNoOptions.find(
                      (option) => option.value === values.seeking_funding
                    )}
                    changeHandler={(option) =>
                      setFieldValue("seeking_funding", option.value)
                    }
                  />
                </Cell>
                {values.seeking_funding === "Yes" && (
                  <Cell small={12}>
                    <InputSelect
                      label="How much funding do you estimate you would require?*"
                      name="funding_sought"
                      options={fundingSoughtSelectOptions}
                      error={touched.funding_sought && errors.funding_sought}
                      value={fundingSoughtSelectOptions.find(
                        (option) => option.value === values.funding_sought
                      )}
                      changeHandler={(option) =>
                        setFieldValue("funding_sought", option.value)
                      }
                    />
                  </Cell>
                )}
                <Cell small={12}>
                  <InputField
                    type="textarea"
                    label="How did you hear about us?*"
                    name="how_did_you_hear_about_us"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={
                      touched.how_did_you_hear_about_us &&
                      errors.how_did_you_hear_about_us
                    }
                    value={values.how_did_you_hear_about_us}
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-y">
                <InputButton
                  type="submit"
                  value={isSubmitting ? "Requesting.." : "Request Your Copy"}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                  disabledColor="#D4D4D4"
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
};

export default FormSection;
