import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';


export const Section = styled.div`

`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  margin-bottom: 20px;
  text-align: center !important;
`;

export const Subheading = styled(Globals.P)`
  margin-bottom: 40px;
  text-align: center;
`;

export const CardSection = styled.div`
  padding: 40px;
  border-top: 1px solid #d4d4d4;

  ${breakpoint('large')`
    border-left: 1px solid #d4d4d4;
    border-top: 0;
  `}
`

export const HeadlineCard = styled.div`
  border: none;
  margin-bottom: 120px;
  padding: 0 !important;
  text-align: left;
  box-shadow: 0 3px 9px rgba(0,0,0,0.05), 0 3px 26px rgba(0,0,0,0.05);
  background-color: #fefefe;
  margin: 20px 0 60px;

  ${breakpoint('medium')`
    margin: 20px 40px 60px;
  `}


  .cell {
    &:first-child {
      ${CardSection} {
        border-left: 0;
        border-top: 0;
      }
    }
  }
`

export const Icon = styled.div`
  margin: 5px 0 30px 0px;
  text-align: center;

  img {
    max-width: 39px;
    max-height: 39px;
  }
`

export const CardHeader = styled(Globals.P)`
  font-weight: bold;
`

export const CardDescription = styled(Globals.Description)`
  margin-bottom: 16px;
`

export const Button = styled(Globals.ButtonLink)`
  width: 100%;

  ${breakpoint('medium')`
    width: unset;
  `}
`
