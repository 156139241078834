import React from 'react'
import { Container, Content, Heading, Paragraph, Button, ImageContainer, InfoContainer, Name, Title } from './ContentSection.style'
import MattRImg from '../../../../assets/images/about/matt-reason.jpg';

function ContentSection() {
  return (
    <Container>
      <Content>
        <Heading>One more step to expedite your inquiry</Heading>
        <Paragraph>Thank you for sharing some initial information about your funding needs.</Paragraph>
        <Paragraph>As LexShares begins to review your submission, we encourage you to spend two minutes providing us with a few extra details regarding your case.</Paragraph>
        <Paragraph>While this step is optional, presenting a fuller picture of your legal and financial situation will allow our Investments team to more quickly process your request and determine the appropriate next steps.</Paragraph>
        <Paragraph>To do so, please access the form below:</Paragraph>
        <Paragraph>
          <Button href="/funding/case-details">Complete Form</Button>
        </Paragraph>
        <Paragraph>
          <ImageContainer>
            <img src={MattRImg} />
          </ImageContainer>
          <InfoContainer>
            <Name>Matt Reason</Name>
            <Title>Chief Revenue Officer</Title>
          </InfoContainer>
        </Paragraph>
      </Content>
    </Container>
  )
}

export default ContentSection;