import React, { Fragment } from 'react';
import {
  Container, Card, DealSize, DealType, DealParties,
  CaseDescription, Status, Link, ProgressContainer, DealStatus,
  DealInfo, ReverseLink
} from './InvestmentCard.style';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

function InvestmentCard({
  plaintiff_display_name, deal_size, case_type, defendant_display_name,
  case_description, marketplace_fund, contextual_lmf_name, fully_subscribed,
  progress, short_amount_remaining, user_signed_in, waitlist_enabled,
  investor, allowed_access, closed, background, linkUrl, linkText,
  start_date, future, user_pending_access, id, csrfToken, inverted,
  viewable, onboarded
}) {

  const openLoginModal = (e) => {
    e.preventDefault();
    $('#login-modal').foundation();
    $('#login-modal').foundation("open");
  }

  const submitPrivateCaseRequest = () => {
    fetch("/private_case_access_requests", {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',

      },
      body: JSON.stringify({
        court_case_id: id,
        authenticity_token: csrfToken
      })
    }).then((res)=> {
      window.location = `${res.url}?confirm-request`;
    })
  }

  const renderLink = () => {
    if (marketplace_fund) {
      return <Link href={"/marketplace-fund"}> Access Details </Link>
    }
    else if (user_signed_in && investor) {
      if(allowed_access) {
        if(future) {
          return <ReverseLink href={`/cases/${id}`}> Read the Case Details </ReverseLink>
        }
        if(progress == 100) {
          return <Link href={`/cases/${id}`}> Read the Case Details </Link>
        }
        return <Link href={`/cases/${id}`}> View Details </Link>
      } else if (user_pending_access) {
        return <Link disabled={true}> Request Pending </Link>
      }
      if (onboarded) {
        return <Link onClick={(e) => { submitPrivateCaseRequest(e) }}>Request Access</Link>
      }
      else {
        return <Link href="/investors/new">Request Access</Link>
      }
    }
    return <Link href="#" onClick={(e) => { openLoginModal(e) }}> Access Details </Link>

  }

  const renderStatus = () => {
    if(linkUrl && linkText) {
      return <Link href={linkUrl}> {linkText} </Link>
    }

    //eslint-disable-next-line
    if((fully_subscribed || closed) && !waitlist_enabled) {

    } else {
      return (
        <Fragment>
          { renderLink() }
        </Fragment>
      )
    }
  }

  const renderParties = () => {
    let names = ''
    if(marketplace_fund) {
      names = contextual_lmf_name
    } else {
      names = `${plaintiff_display_name} vs.<br/> ${defendant_display_name}` 
    }
    return {__html: names}
  }

  const renderDealInfo = () => {
    if(future) {
      return `Available ${start_date}`
    }

    if(closed) {
      return "Resolved"
    }

    if(fully_subscribed || progress == 100 || short_amount_remaining == "$0.00") {
      return "Closed"
    }

    return (short_amount_remaining && investor) ? `${short_amount_remaining} Available` : 'Deal Size'
  }

  const renderDealStatus = () => {
    if(future) {
      return "Launching"
    }

    if(viewable) {
      return "Open Soon"
    }

    if(progress == 0) {
      return "Open Now";
    }

    return `${progress}% Funded`;
  }

  const renderProgress = () => {
    if(marketplace_fund) {
      return <DealSize inverted={inverted}>
        { deal_size }
      </DealSize>
    }

    return (
      <ProgressContainer>
        <CircularProgressbarWithChildren value={progress} strokeWidth={6} className="poop" styles={buildStyles({
          rotation: 0,
          strokeLinecap: 'butt',
          pathTransitionDuration: 0.5,
          pathColor: "#8247BA",
          trailColor: '#533572'
        })}>

          <DealStatus>
            { renderDealStatus() }
          </DealStatus>
          <DealSize inverted={inverted}>
            { deal_size }
          </DealSize>
          <DealInfo inverted={inverted}>
            { renderDealInfo() }
          </DealInfo>
        </CircularProgressbarWithChildren>
      </ProgressContainer>
    );
  }

  return (
    <Container>
      <Card background={background} inverted={inverted} className="js-card">
        <DealParties dangerouslySetInnerHTML={renderParties()} />

        { renderProgress() }

        <DealType inverted={inverted}>
          { case_type }
        </DealType>

        <CaseDescription inverted={inverted}>
          <div dangerouslySetInnerHTML={{
            __html: case_description
          }} />
        </CaseDescription>
        <Status>
          { renderStatus() }
        </Status>
      </Card>
    </Container>
  );
}

export default InvestmentCard;
