import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, InvestmentContainer, Heading, Subheading, Description, MarginCell } from './RecentlyFundedSection.style';
import { InvestmentCard } from '../../funding';


function RecentlyFundedSection({investments = [], csrfToken = null}) {
  const renderInvestments = () => {
    return investments.map((investment, i) => {
      return (
        <InvestmentContainer key={i}>
          <InvestmentCard {...investment} background="#FFFFFF" csrfToken={csrfToken} />
        </InvestmentContainer>
      );
    });
  }

  return (
    <Fragment>
      <Section>
        <Heading>
          Recently funded opportunity
        </Heading>
        <Grid className="grid-margin-x">
          <Cell small={12}>
            <Grid className="align-center">
              <MarginCell className="cell small-12 large-4">
                { renderInvestments() }
              </MarginCell>
              <Cell small={12} large={4}>
                <Subheading>
                  Funding Complete
                </Subheading>
                <Description>
                  Thank you for your interest in our most recent single case investment opportunity.
                </Description>
                <Description>
                  Due to high investor demand, the offering is already fully subscribed.
                </Description>
                <Description>
                  We apologize if you were unable to gain access to our most recent single case offering prior to it being fully subscribed. We are planning to launch a number of new offerings in the coming weeks and will keep you apprised of these opportunities.
                </Description>
                <Description>
                  If you have any questions, contact us at <a href="mailto:investors@lexshares.com">investors@lexshares.com</a> or call us at (877) 290-4443.
                </Description>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Section>
    </Fragment>
  )
}

export default RecentlyFundedSection;
