import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import Hero from '../../../../assets/images/backgrounds/diamond-array-purple.png'
import { H2, ButtonLinkInverse } from './../../Globals';

export const Banner = styled(SB.Banner)`
  background: linear-gradient(90deg, rgba(148,80,212,1) 0%, rgba(89,29,180,1) 100%);
  text-align: center;
  overflow: hidden;
  position: relative;

  ${breakpoint('medium')`
    padding: 2% 0 2% !important;
  `}
`;

export const DiamondBackground = styled(SB.Banner)`
  background: url(${Hero}) no-repeat center center/cover;
  position: absolute;
  left: 0;
  top: unset;
  width: 600px;
  height: 300px;
  margin-top: 300px
`;

export const Content = styled(SB.Content)`
  padding: 13% 3%;
`;

export const Header = styled(H2)`
  color: white !important;
`;

export const Subheading = styled.p`
  max-width: 630px;
  text-align: center;
  margin: 0 auto;
  color: white;

  ${breakpoint('medium')`
    font-size: 1.3rem;
  `}
`;

export const Button = styled(ButtonLinkInverse)`
  margin-top: 30px;
  z-index: 100;

  ${breakpoint('medium')`
    width: unset;
  `}
`;