import React, {useState, useEffect} from 'react'
import {Cell, Grid} from 'react-foundation'
import SideColumn from '../SideColumn'
import IrrWidget from '../IrrWidget'
import CasePerformanceWidget from './CasePerformanceWidget'
import ResolvedInvestmentsWidget from './ResolvedInvestmentsWidget'
import AllInvestmentsWidget from './AllInvestmentsWidget'
import InvestmentTypeWidget from './InvestmentTypeWidget'
import InvestmentProfileWidget from './InvestmentProfileWidget'
import axios from '../../../Axios'
import Mixpanel from '../../../Mixpanel'

/**
 * Wrapper component for all personal  portfolio performance data related to
 * historical performance, investment metrics, investment activity, investment
 * types and profile of the cases.
 */
function PersonalPortfolio({investmentCount}) {
  let [returns, setReturns] = useState(null)
  let [pluralized, setPluralized] = useState(null)

  // Fetches irr / roic data for current investor.
  useEffect(() => {
    axios.get(`/widget/portfolio-returns.json`).then(res => {
      setReturns(res.data)
      setPluralized(res.data.resolved_cases > 1 ? 'investments' : 'investment')
    })
  }, [])

  useEffect(() => {
    Mixpanel.track('Viewed personal portfolio tab')
  }, [])

  return (
    <Grid className="grid-margin-x">
      <Cell medium={12} large={8}>
        { returns && (returns.resolved_cases > 0) &&
          <IrrWidget
            irr={returns.irr}
            roic={returns.roic}
            heading="Your Historical Investment Performance"
            dateRange={returns.period}
            text={`The following performance metrics represent median figures 
              for your ${returns.resolved_cases} fully resolved ${pluralized}.
              This data is updated each time you have an investment resolution.`}
            disclosure={`Past performance is not indicative of future performance. 
              Results reported reflect the simple median annualized rate of return 
              per the xirr Excel function and ROIC net of fees and expenses for 
              resolved case investments. Diversification does not guarantee 
              profits or protect against losses.`}
          />
        }
        <CasePerformanceWidget />
        <ResolvedInvestmentsWidget />
        <AllInvestmentsWidget />
        <InvestmentTypeWidget />
        <InvestmentProfileWidget />
      </Cell>
    </Grid>
  )
}

export default PersonalPortfolio
