import React, {Fragment, useEffect, useState} from 'react'
import { Container, Overlay, CloseIcon } from './ModalPop.style';

function ModalPop({popVisible, hideHandler, content, size = 'large',
  disableClose = false, color = null}) {

  let [visible, setVisible] = useState(false)

  useEffect(() => {
    // Clicks custon button to open or close modal
    if ((popVisible && !visible) || (!popVisible && visible)) {
      setVisible(popVisible)
    }
  })

  const closeHandler = () => {
    setVisible(false)
    hideHandler()
  }

  return (
    <Fragment>
      <Overlay className={visible == false ? 'hidden' : ''}>
        <Container className={size} color={color}>
          { hideHandler && !disableClose &&
            <CloseIcon onClick={() => closeHandler()} />
          }
          {content}
        </Container>
      </Overlay>
    </Fragment>
  )
}

export default ModalPop
