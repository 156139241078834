import React, {Fragment, useEffect, useState} from 'react'
import axios from '../../../Axios'
import {
  Widget,
  Icon,
  Heading,
  Question,
  Panel
} from './FaqWidget.style'

/**
 * Fetch the most important investor questions from our FAQ. Questions
 * that appear in this widget are those tagged in admin to be widget featured.
 */
function FaqWidget() {
  let [selectedItems, setSelectedItems] = useState([]);
  let [questions, setQuestions] = useState(null)

  // Show answer for each clicked question; doesn't toggle previously opened
  const onClick = (id) => {
    if (selectedItems.includes(id)) {
      let reducedSelectedItems = selectedItems.filter(_id => _id !== id)
      setSelectedItems(reducedSelectedItems)
    } else {
      setSelectedItems(selectedItems.concat(id));
    }
  };

  // Fecth the featured questions as designated in admin
  useEffect(() => {
    axios.get('/widget/faqs.json').then(res => {
      setQuestions(res.data)
    })
  }, [])

  return (
    <Widget className="js-faq-widget">
      <Icon />
      <Heading>Frequently Asked Questions</Heading>
      { questions && questions.map((item, i) => (
        <Fragment key={i}>
          <Question onClick={() => {onClick(item.id)}} show={selectedItems.includes(item.id)} className="js-faq-question">
            {item.question}
          </Question>
          <Panel show={selectedItems.includes(item.id)}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.answer
              }}></div>
          </Panel>
        </Fragment>
      ))}
    </Widget>
  )
}

export default FaqWidget
