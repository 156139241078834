import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Medium = styled.div`
  padding: 0 10px;
  margin: 20px 0 0 0;

  img {
    max-width: 100px;
    max-height: 13px;
    width: auto;
    ${breakpoint('medium')`
      max-height: 20px;
    `}
  }

  ${breakpoint('medium')`
    display: block;
    margin: 0 15px 0 -10px;
    padding: 0 10px;
  `}

  ${breakpoint('large')`
    display: block;
    margin: 0 3% 0 -10px;
  `}
`

export const MediaContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  margin-top: 50px;
  ${breakpoint('large')`
    justify-content: left;
  `}
`
