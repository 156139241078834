import React, { Fragment } from "react";
import { Cell, Grid } from 'react-foundation'

import { CallToAction, Content, Heading, Tagline, Button } from './CallToActionSection.style';

function CallToActionSection({heading, tagline, cta, link}) {
  return (
    <Fragment>
      <CallToAction>
        <Content>
          <Grid className="grid-margin-x align-center">
            <Cell small={12} medium={9}>
              <Heading>
                { heading }
              </Heading>
            </Cell>
          </Grid>
          <Grid className="grid-margin-x align-center">
            <Cell small={12} medium={8} style={{textAlign: 'center'}}>
              <Tagline>
                { tagline }
              </Tagline>
              <Button href={link} target='_blank'>
                {cta}
              </Button>
            </Cell>
          </Grid>
        </Content>
      </CallToAction>
    </Fragment>
  )
}

export default CallToActionSection
