import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../../Globals'
import raybot from '../../../../../assets/images/about/ray-marchand.jpg'

export const Headshot = styled.div`
  background: url(${raybot}) no-repeat center center/cover;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  margin-top: -55px;
  margin-bottom: 20px;
`

export const Widget = styled(Globals.Widget)`
  padding: 21px 31px;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 25px;
  ${breakpoint('large')`
    margin-top: 0;
  `}
`

export const Heading = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-bottom: 18px;
  color: white;
`

export const Text = styled.p`
  color: white;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
`

export const ScheduleButton = styled(Globals.ButtonLink)`
  width: 100%;
`
