import React, { useState } from "react";
import { Grid, Cell } from "react-foundation";
import {
  ButtonContainer,
  ContactUsButton,
  Container,
  Description,
  Header,
  RelativeContainer,
  SampleLegalClaimContainerLightGrey,
  Pill,
  SampleHeading,
  SampleLegalClaimDescription,
  SampleLegalClaimContainerDarkGrey,
  RadioButton,
  SampleLegalClaimSubheading,
  SampleLegalClaimSubtitle,
  SampleLegalUnderWritingSubHeading,
  SampleLegalUnderWritingDescription,
  Step,
  StepDescription,
  Title,
  SampleLegalUnderWritingContainerLightGrey,
  SampleLegalUnderWritingContainerDarkGrey,
  Ring,
  PercentageFunded,
  AmountFunded,
  FundingStatus,
  FundingHeading,
  FundingSubHeading,
  FundingContainerLightGrey,
  FundingContainerDarkGrey,
  LegalUnderWritingRelativeContainer,
  FundingRelativeContainer,
  InfoIcon,
  Tooltip,
  TooltipTitle,
  TooltipDescription,
  Spacer,
  SampleLegalClaimContainer,
  SampleLegalUnderWritingContainer,
  FundingContainer,
} from "./HowItWorksSection.style";
import InfoImg from '../../../../assets/images/icons/info.svg';
import CheckImg from '../../../../assets/images/icons/checkmark-white.png';

function HowItWorksSection({ initialReview, funding }) {
  const [showLegalClaimToolTip, setShowLegalClaimToolTip] = useState(false);
  const [showDiligenceListToolTip, setShowDiligenceListToolTip] =
    useState(false);
  const [showFundingToolTip, setShowFundingToolTip] = useState(false);

  return (
    <Container>
      <Header>How our process works</Header>
      <Description>
        Reach out to our team to start discussing whether funding is right for
        your legal claim.
      </Description>
      <ButtonContainer>
        <ContactUsButton href="/funding/case">Contact Us</ContactUsButton>
      </ButtonContainer>
      <Grid>
        <Cell small={2} large={1}>
          <Spacer height={150} />
          <Step>1</Step>
        </Cell>
        <Cell small={10} large={5}>
          <Spacer height={150} />
          <Title>Initial Review</Title>
          <StepDescription>
            Our team works efficiently to provide feedback on your legal claim.
            If we believe the case is suited for funding, we will present a term
            sheet.
          </StepDescription>
          <Pill>1 - 2 Weeks</Pill>
        </Cell>
        <Cell small={12} large={6}>
          <div>
            <RelativeContainer>
              <SampleLegalClaimContainer>
                <SampleHeading>Sample Legal Claim</SampleHeading>
                <InfoIcon
                  onMouseOver={() => setShowLegalClaimToolTip(true)}
                  onMouseLeave={() => setShowLegalClaimToolTip(false)}
                >
                  <img src={InfoImg} />
                </InfoIcon>
                {showLegalClaimToolTip && (
                  <Tooltip>
                    <TooltipTitle>Criteria</TooltipTitle>
                    <TooltipDescription>
                      Our preliminary review focuses on the claim’s legal
                      merits, damages, and defendant’s ability to pay on a
                      settlement or judgement.
                    </TooltipDescription>
                  </Tooltip>
                )}
                <SampleLegalClaimSubtitle>Case Type</SampleLegalClaimSubtitle>
                <SampleLegalClaimSubheading>
                  {initialReview.caseType}
                </SampleLegalClaimSubheading>
                <img src={CheckImg} />
                <SampleLegalClaimDescription>
                  {initialReview.caseDescription}
                </SampleLegalClaimDescription>
                <SampleLegalClaimSubtitle>Damages</SampleLegalClaimSubtitle>
                <SampleLegalClaimSubheading>
                  {initialReview.damagesAmount}
                </SampleLegalClaimSubheading>
                <img src={CheckImg} />
                <SampleLegalClaimSubtitle>Defendant</SampleLegalClaimSubtitle>
                <SampleLegalClaimSubheading>
                  {initialReview.defendant}
                </SampleLegalClaimSubheading>
                <img src={CheckImg} />
                <SampleLegalClaimDescription>
                  {initialReview.defendantDescription}
                </SampleLegalClaimDescription>
              </SampleLegalClaimContainer>
              <SampleLegalClaimContainerLightGrey />
              <SampleLegalClaimContainerDarkGrey />
            </RelativeContainer>
          </div>
        </Cell>
        <Cell small={2} large={1}>
          <Spacer height={117} />
          <Step>2</Step>
        </Cell>
        <Cell small={10} large={5}>
          <Spacer height={117} />
          <Title>Claims Assessment</Title>
          <StepDescription>
            Once material terms are solidified and deal documentation is
            executed, the claim is assessed.
          </StepDescription>
          <Pill>3 - 4 Weeks</Pill>
        </Cell>
        <Cell small={12} large={6}>
          <LegalUnderWritingRelativeContainer>
            <SampleLegalUnderWritingContainer>
              <SampleHeading>Sample Diligence List</SampleHeading>
              <InfoIcon
                onMouseOver={() => setShowDiligenceListToolTip(true)}
                onMouseLeave={() => setShowDiligenceListToolTip(false)}
              >
                <img src={InfoImg} />
              </InfoIcon>
              {showDiligenceListToolTip && (
                <Tooltip>
                  <TooltipTitle>Our Underwriting Process</TooltipTitle>
                  <TooltipDescription>
                    We will provide a checklist of due diligence items that we
                    need to complete the underwriting process.
                  </TooltipDescription>
                </Tooltip>
              )}
              <Grid>
                <Cell small={1}>
                  <RadioButton />
                </Cell>
                <Cell small={11} style={{ marginBottom: "22px" }}>
                  <SampleLegalUnderWritingSubHeading>
                    Engagement Agreement
                  </SampleLegalUnderWritingSubHeading>
                  <SampleLegalUnderWritingDescription>
                    Documents outlining the plaintiff’s engagement structure
                    with counsel.
                  </SampleLegalUnderWritingDescription>
                </Cell>
              </Grid>
              <Grid>
                <Cell small={1}>
                  <RadioButton />
                </Cell>
                <Cell small={11} style={{ marginBottom: "22px" }}>
                  <SampleLegalUnderWritingSubHeading>
                    Damages Analysis
                  </SampleLegalUnderWritingSubHeading>
                  <SampleLegalUnderWritingDescription>
                    Information supporting how the expected damages were
                    calculated by the legal team.
                  </SampleLegalUnderWritingDescription>
                </Cell>
              </Grid>
              <Grid>
                <Cell small={1}>
                  <RadioButton />
                </Cell>
                <Cell small={11}>
                  <SampleLegalUnderWritingSubHeading>
                    Projected Litigation Budget
                  </SampleLegalUnderWritingSubHeading>
                  <SampleLegalUnderWritingDescription>
                    Budget projections for various upcoming legal fees, court
                    costs, expert reports, etc.
                  </SampleLegalUnderWritingDescription>
                </Cell>
              </Grid>
            </SampleLegalUnderWritingContainer>
            <SampleLegalUnderWritingContainerLightGrey />
            <SampleLegalUnderWritingContainerDarkGrey />
          </LegalUnderWritingRelativeContainer>
        </Cell>
        <Cell small={2} large={1}>
          <Spacer height={117} />
          <Step>3</Step>
        </Cell>
        <Cell small={10} large={5}>
          <Spacer height={117} />
          <Title>Funding</Title>
          <StepDescription>
            After the claims are assessed, a notice of approval may be provided,
            followed by swift and secure delivery of funding.
          </StepDescription>
          <Pill>1 Week</Pill>
        </Cell>
        <Cell small={12} large={6}>
          <FundingRelativeContainer>
            <FundingContainer>
              <Ring>
                <PercentageFunded>100% Funded</PercentageFunded>
                <AmountFunded>{funding.damagesAmount}</AmountFunded>
                <FundingStatus>Closed</FundingStatus>
              </Ring>
              <FundingHeading>
                Non-Recourse Funding
                <InfoIcon
                  onMouseOver={() => setShowFundingToolTip(true)}
                  onMouseLeave={() => setShowFundingToolTip(false)}
                >
                  <img src={InfoImg} />
                </InfoIcon>
                {showFundingToolTip && (
                  <Tooltip>
                    <TooltipTitle>Our Role</TooltipTitle>
                    <TooltipDescription>
                      We are passive investors and are not involved in guiding
                      litigation strategy or settlement negotiations.
                    </TooltipDescription>
                  </Tooltip>
                )}
              </FundingHeading>
              <FundingSubHeading>
                There is no obligation to repay LexShares’ investment if the
                case is lost.
              </FundingSubHeading>
            </FundingContainer>
            <FundingContainerLightGrey />
            <FundingContainerDarkGrey />
          </FundingRelativeContainer>
        </Cell>
      </Grid>
    </Container>
  );
}

export default HowItWorksSection;
