import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals';
import Hero from '../../../../assets/images/backgrounds/dashboard-bg-strip.png';


export const Stats = styled.div`
  background-color: #5129ad;
  background-repeat: no-repeat repeat;
  color: white;
  text-align: center;
  background: url(${Hero});
  background-repeat: repeat-y;
  background-position: left top;
  background-size: contain;

  ${breakpoint('large')` {
    position: relative;
    margin-top: -5%;
  `}
`;

export const Content = styled.div`
  padding: 18px 0;
  max-width: 1235px;
  margin: 0 auto;

  .cell {
    border-right: 1px solid #fefefe;
    padding: 15px !important;

    &:last-child {
      border-right: none;
    }

    ${breakpoint('large')` {
      padding: 30px !important;
    `}
  }

`;

export const Label = styled.div`
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 10px;
  font-size: 16px;

  ${breakpoint('large')`
    font-size: 18px;
  `}
`;

export const Metric = styled(Globals.H2)`
  font-size: 24px !important;
  line-height: 1.2 !important;

  ${breakpoint('large')`
    font-size: 32px !important;
  `}
`;
