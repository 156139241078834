import * as yup from 'yup'
import React, { useState } from 'react';
import { Formik } from 'formik'
import { get } from 'lodash';
import { Grid, Cell } from 'react-foundation';
import axios from 'axios'
import InputCheck from '../../Form/InputCheck';
import InputButton from '../../Form/InputButton';
import ProgressBar from '../../ProgressBar';
import { ButtonsContainer, BackButton, Container, Error, ErrorContainer, FormContainer, Heading, Subheading, FinishProfileLaterContainer, FinishProfileLaterLink } from './OnboardingStepFour.style';
import FocusError from '../../FocusError/FocusError';

const OnboardingStepFour = ({csrfToken, investor, setOnboardingStep, setCurrentInvestor}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    bear_risk: yup.boolean().required().oneOf([true], 'Required'),
    legal_review: yup.boolean().required().oneOf([true], 'Required'),
    terms_of_service: yup.boolean().required().oneOf([true], 'Required'),
  })

  const defaultValues = {
    bear_risk: get(investor, 'bear_risk'),
    legal_review: get(investor, 'legal_review'),
    terms_of_service: get(investor, 'terms_of_service'),
  }

  const handleSubmit = ({bear_risk, legal_review, terms_of_service}) => {
    setIsSubmitting(true);
    const payload = {
      investor: {
        bear_risk: bear_risk ? 1 : 0,
        legal_review: legal_review ? 1 : 0,
        terms_of_service: terms_of_service ? 1 : 0,
        has_completed_profile: 0,
      },
      authenticity_token: csrfToken
    }

    axios.patch(`/investors/${investor.id}.json`, payload).then((response) => {
      setOnboardingStep(5);
      setCurrentInvestor(response.data.investor);
    }).catch((e) => {
      setIsSubmitting(false);
      const shared_ui = new LexShares.SharedUI();
      shared_ui.showAlert('error', e.response.data.error, false);
    })
  }

  return(
    <Container>
      <ProgressBar step={4} steps={5} percent={80} />
      <Heading>Representations</Heading>
      <Subheading>Please read and agree to each of the following representations.</Subheading>
      <Formik
        initialValues={defaultValues}
        onSubmit={(e) => handleSubmit(e) }
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {
          ({ errors, handleSubmit, handleChange, values, isValid }) =>
            <form onSubmit={ handleSubmit }>
              <FocusError />
              <FormContainer>
                <ErrorContainer className={errors.bear_risk ? 'error': null}>
                  <InputCheck
                    label="By checking this box, you represent that you have such knowledge and
                    experience in financial and business matters that you are capable of
                    evaluating the merits and risks of investments in private placements
                    through LexShares, and you are able to bear the economic risk of these
                    investments including the risk of complete loss."
                    name="bear_risk"
                    checked={values.bear_risk}
                    changeHandler={handleChange}
                    blockFormatting={true}
                  />
                </ErrorContainer>
                {errors.bear_risk && <Error>Required</Error>}
                <ErrorContainer className={errors.legal_review ? 'error': null}>
                  <InputCheck
                    label="By checking this box, you (a) understand that LexShares will rely on the
                    foregoing representations to, among other things, maintain compliance with
                    securities laws, (b) certify that your answers to the foregoing questions are
                    correctly stated to the best of your knowledge, information and belief, (c)
                    you further agree that LexShares may require (in order for you to remain a
                    member of LexShares or participate in any investment) that you (or your
                    accountant or attorney) furnish any additional information to LexShares
                    that it deems reasonably necessary in order to verify your answers to the
                    foregoing questions."
                    name="legal_review"
                    checked={values.legal_review}
                    changeHandler={handleChange}
                    blockFormatting={true}
                  />
                </ErrorContainer>
                {errors.legal_review && <Error>Required</Error>}
                <ErrorContainer className={errors.terms_of_service ? 'error': null}>
                  <InputCheck
                    label="By checking this box, you represent that you have reviewed, understood, and
                    agree to be bound to our
                    <a target='_blank' href='/terms-of-use'>Terms of Use</a> and
                    <a target='_blank' href='/privacy-policy'>Privacy Policy</a> and other
                    policies posted on this site, each as amended from time to time."
                    name="terms_of_service"
                    checked={values.terms_of_service}
                    changeHandler={handleChange}
                    blockFormatting={true}
                  />
                </ErrorContainer>
                {errors.terms_of_service && <Error>Required</Error>}
              </FormContainer>
              <ButtonsContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6} className="medium-order-1 small-order-2">
                    <BackButton onClick={() => setOnboardingStep(3)}>Back</BackButton>
                  </Cell>
                  <Cell small={12} medium={6} className="medium-order-2 small-order-1">
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value={isSubmitting ? 'Submitting..' : 'Continue'}
                      name="submit-button"
                      disabled={isSubmitting || !isValid}
                    />
                  </Cell>
                </Grid>
              </ButtonsContainer>
            </form>
        }
      </Formik>
      <FinishProfileLaterContainer>
        <FinishProfileLaterLink href="/dashboard">Finish Profile Later</FinishProfileLaterLink>
      </FinishProfileLaterContainer>
    </Container>
  )
}

export default OnboardingStepFour;