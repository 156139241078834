import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../Globals';

import { H2, P } from '../Globals';

export const CallToAction = styled.div`
  background-color: #282331;
`

export const Content = styled.div`
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`

export const Heading = styled(H2)`
  text-align: center;
  color: white;
  margin-bottom: 12px;
`

export const Tagline = styled(P)`
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  color: white;
`

export const Button = styled(Globals.ButtonLink)`
  min-width: 200px;
`
