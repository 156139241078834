import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H3, Description } from '../../Globals'
import * as SB from '../../SimpleBanner'

export const Section = styled.div`
  position: relative;
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 0;

  ${breakpoint('large')`
    padding: 60px;
  `}
`;


export const BannerProp = styled(SB.Diamonds)`
  top: 60%;
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`;

export const InfoCard = styled.div`
  background: #FFFFFF;
  padding: 44px 35px 35px;
  margin-bottom: 40px;
  position: relative;

  ${breakpoint('medium')`
    min-height: 525px;
    padding: 64px 55px 35px;
  `}

`;

export const CardHeader = styled(H3)`
  text-align: center;
  color: #000000;
`

export const CardDescription = styled(Description)`
  text-align: center;
  color: #000000;
`

export const CardDivider = styled.div`
  height: 5px;
  background: #9450D4;
  width: 100px;
  margin: 35px auto;
`

export const Stat = styled.div`
  margin-top: 35px;
`

export const Value = styled.div`
  font-size: 48px;
  color: #9450D4;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
`

export const Label = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 0 20px;
  color: #000000;
`

export const Logos = styled.div`
  margin-top: 60px;
  margin-bottom: 29px;
`

export const LinkContainer = styled.div`
  text-align: center;
`

export const Link = styled.a`
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #9450D4;
  font-weight: bold;

  &:hover, &:visited {
    color: #9450D4;
  }
`

export const Disclaimer = styled.div`
  font-size: 12px;
  text-align: left !important;
  color: #757474;
  margin-top: 20px;
  z-index: 1;
`
