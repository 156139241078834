import * as yup from 'yup'
import InputField from '../../../components/Form/InputField'
import InputButton from '../../../components/Form/InputButton'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { Grid, Cell } from 'react-foundation';
import { isEmpty } from 'lodash'
import axios from 'axios'

const schema = yup.object().shape({
  email: yup.string().label("Email"),
  //eslint-disable-next-line
  password: yup.string().min(8, "Password is too weak").matches(/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/, "Password is too weak").label("Password"),
  password_confirmation: yup.string().required().oneOf([yup.ref('password')], "Must match passwoord").label("Password confirmation")
})

function SecurityForm({csrfToken, investor}) {
  const formRef = useRef(null)

  const defaultValues = {
    email: '' || investor.user.email,
    password: '',
    password_confirmation: ''
  }

  const handleSubmit = ({email, password, password_confirmation}) => {

    const payload = {
      user_attributes: { id: investor.user.id, email, password, password_confirmation },
      authenticity_token: csrfToken
    }

    axios.put(`/investors/${investor.id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-security">
            <Grid className="grid-margin-x">
              <Cell large={12}>
                <InputField
                  type="text"
                  label="Email address"
                  name="email"
                  changeHandler={handleChange}
                  error={errors.email}
                  value={values.email}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="password"
                  label="Change password"
                  name="password"
                  changeHandler={handleChange}
                  error={errors.password}
                  value={values.password}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="password"
                  label="Please re-type to confirm"
                  name="password_confirmation"
                  changeHandler={handleChange}
                  error={errors.password_confirmation}
                  value={values.password_confirmation}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-y">
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default SecurityForm
