import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Banner, BannerContent, BannerProp, Heading, Subheading, Medium, MediaContent
} from './BannerSection.style';
import BannerImg from '../../../../assets/images/shared/banner-prop.svg';
import WsjImg from '../../../../assets/images/logos/wsj-logo-white.png';
import AbaImg from '../../../../assets/images/logos/abajournal-logo-white.png';
import BloombergImg from '../../../../assets/images/logos/bloomberg-logo-white.png';
import ForbesImg from '../../../../assets/images/logos/forbes-logo-white.png';
import Law360Img from '../../../../assets/images/logos/law360-logo-white.png';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <BannerProp>
          <img src={BannerImg}/>
        </BannerProp>

        <BannerContent>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                In the news
              </Heading>
              <Subheading>
                For all media inquiries, please contact us at press@lexshares.com
              </Subheading>
            </Cell>
          </Grid>

        </BannerContent>
        <MediaContent>
          <Medium>
            <img src={WsjImg}/>
          </Medium>
          <Medium>
            <img src={BloombergImg}/>
          </Medium>
          <Medium>
            <img src={AbaImg}/>
          </Medium>
          <Medium>
            <img src={ForbesImg}/>
          </Medium>
          <Medium>
            <img src={Law360Img}/>
          </Medium>
        </MediaContent>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
