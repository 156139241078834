import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as SB from './../../SimpleBanner'
import * as Globals from '../../Globals'

export const Content = styled(SB.Content)`
  max-width: 1235px;
  margin: 0px auto;
  padding: 0px 7%;
  text-align: center;
  min-height: 64px;
  position: relative;
  z-index: 0;
  ${breakpoint('medium')`
    padding-top: 0;
    padding-bottom: 0;
  `}
  ${breakpoint('large')`
    padding: 0px 2%;
  `}
`

export const Links = styled.div`
  display: block;
  ${breakpoint('large')`
    float: left;
  `}
`

export const Link = styled.span`
  margin-left: 10px;
  margin-right: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  font-family: 'proxima-nova';
  letter-spacing: 2px;
  cursor: pointer;
  color: ${props => props.theme.colors.inactivePurple};
  &.selected {
    color: ${props => props.theme.colors.backdropPurple};
    border-bottom: 5px solid ${props => props.theme.colors.neonPurple};
  }
  ${breakpoint('medium')`
    margin-left: 0px;
  `}
  ${breakpoint('large')`
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-right: 35px;
  `}
`

export const Controls = styled.div`
  display: block;
  clear: right;
  margin-top: 7px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  ${breakpoint('large')`
    margin-bottom: 0;
    float: right;
  `}
`

export const InvestmentButton = styled(Globals.ButtonPrimary)`
  min-width: 120px;
  margin-right: 20px;
  &:disabled {
    background-color: #979797;

  }
  display: block;
  margin: 0 auto;
  ${breakpoint('large')`
    display: inline-block;
    min-width: 175px;
  `}
`

export const FundingAmounts = styled.div`
  display: inline-block;
  border-bottom: 1px solid #eee8f8;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
  ${breakpoint('medium')`
    font-size: 15px;
  `}
  ${breakpoint('large')`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 16px;
  `}
`

export const TipHeading = styled.div`
  font-weight: bold;
  color: white;
`

export const TipInfo = styled.div`
  color: white;
  text-align: left;
  max-width: 285px;
`

export const MinMaxAmount = styled.span`
  display: inline-block;
  font-size: 16px;
  color: ${props => props.theme.colors.backdropPurple};
  text-align: center;
  span.amount {
    font-weight: bold;
  }
  span.description {
    font-weight: normal;
  }
  &:nth-of-type(2) {
    margin-left: 7px;
  }
`

export const Tip = styled.div`
  position: absolute;
  height: 23px;
  width: 23px;
  font-weight: bold;
  text-align: center;
  background-color: #eee8f8;
  border-radius: 50%;
  display: block;
  margin-left: -30px;
  margin-top: 2px;
  color: ${props => props.theme.colors.purple};
`

export const Count = styled.span`
  color: ${props => props.theme.colors.neonPurple} !important;
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  margin-top: -6px;
`

export const PopButton = styled(Globals.ButtonPrimary)`
  display: block;
  margin: 0 auto;
  margin-top: 30px;
`

export const PopCopy = styled.p`
  text-align: center;
`

export const PopHeader = styled.div`
  ${Globals.tiempoFontStack};
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 20px;
`


