import styled from 'styled-components'

export const Container = styled.div`
`

export const StepNumber = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  float: left;
  color: #7d7d7d;
`

export const StepProgress = styled.div`
  clear: both;
`

export const ProgressWrapper = styled.div`
  height: .6rem;
  border-radius: 6px;
  overflow: hidden;
  clear: both;
  .progress {
    background-color: ${props => props.theme.colors.lightPurple};
  }
  .progress-meter {
    background-color: ${props => props.theme.colors.purple};
    background: linear-gradient(to right, #9450D4, #9450D4);
    background-size: 200% 100%;
    background-position: left bottom;
    transition:all 2s ease;
  }
`
