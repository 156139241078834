import React, {Fragment} from 'react'
import {ResponsiveBar} from '@nivo/bar'
import {
  ChartContainer,
} from './SingleBar.style'

/**
 * Generates a Nivo single horizontal stacked bar chart using a LexShares
 * theme with custom legend.
 * Data format: [{type: 'Breach of contract', count: 3}, ..]
 */
function SingleBar({keys, data, showMoney = true, labelWidth = 120, moneyType = "",
  verticalLegend = false}) {

  const colors = [
    '#9238DA',
    '#DAB1FC',
    '#614F70'
  ]

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <Fragment>
      <ChartContainer verticalLegend={verticalLegend}>
        <ResponsiveBar
          theme={{
            tooltip: {container: {background: '#1E1924', border: '1px solid #0b0a0d'}},
            textColor: '#F8F7FA',
            fontSize: '10px',
            fontWeight: '300',
            legends: {
              text: {
                fontSize: 13,
                fontFamily: 'proxima-nova'
              }
            },
          }}
          data={data}
          layout="horizontal"
          enableGridX={false}
          enableGridY={false}
          enableLabel={false}
          keys={keys}
          indexBy="type"
          margin={{top: 0, right: 0, bottom: (verticalLegend ? 70 : 50), left: 0}}
          padding={0.3}
          groupMode="stacked"
          valueScale={{ type: 'linear' }}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={null}
          borderColor="#ffffff"
          onMouseEnter={(_data, event) => {
            event.target.style.strokeWidth = '2'
          }}
          onMouseLeave={(_data, event) => {
            event.target.style.strokeWidth = '0'
          }}
          tooltip={(params) => {
            let count = params.data[`${params.id} Count`]
            return (
              <span
                style={{
                   fontSize: '10px',
                   fontWeight: 'bold',
                   color: 'white'
                 }}
               >

                { showMoney == false
                  ?
                   <Fragment>
                     {capitalize(params.id)}
                     <span
                       style={{fontWeight: 'normal', display: 'block'}}
                     >
                       {params.value} cases
                     </span>
                   </Fragment>
                  :
                   <Fragment>
                     {capitalize(`${count ? count + ' ' : ''}${params.id}`)}
                     <span
                       style={{fontWeight: 'normal', display: 'block'}}
                     >
                       ${numberWithCommas(params.value)} {moneyType}
                     </span>
                   </Fragment>
                 }

               </span>
             )
          }}
           labelSkipWidth={12}
           labelSkipHeight={12}
           labelTextColor="#ffffff"
           animate={true}
           motionStiffness={90}
           motionDamping={15}
           isInteractive={true}
           legends={[
             {
               anchor: 'bottom-left',
               direction: verticalLegend ? 'column' : 'row',
               justify: false,
               translateX: 0,
               translateY: verticalLegend ? 70 : 35,
               itemsSpacing: 0,
               itemDirection: 'left-to-right',
               itemWidth: labelWidth,
               itemHeight: 25,
               itemOpacity: 0.75,
               symbolSize: 14,
               symbolShape: 'circle',
               symbolBorderColor: 'rgba(0, 0, 0, .5)',
               effects: [
                 {
                   on: 'hover',
                   style: {
                     itemBackground: 'rgba(0, 0, 0, .03)',
                     itemOpacity: 1
                   }
                 }
               ]
             }
           ]}
        />
      </ChartContainer>
    </Fragment>
  )
}

export default SingleBar
