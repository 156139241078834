import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Section, Content, BannerProp, Heading, Subheading, Label
} from './BannerSection.style';
import BannerImg from '../../../../assets/images/shared/banner-prop.svg';


function BannerSection() {
  return (
    <Fragment>
      <Section>
        <BannerProp>
          <img src={BannerImg} />
        </BannerProp>

        <Content>
          <Grid>
            <Cell large={7}>
              <Label>
                8 Minute Read
              </Label>
              <Heading>
                Litigation Finance 101
              </Heading>
              <Subheading>
                The following covers the basic concepts, key participants, benefits and resources critical to an introductory understanding of commercial litigation finance.
              </Subheading>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default BannerSection;
