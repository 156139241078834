import React from 'react'
import {Cell, Grid} from 'react-foundation'
import ProgressBar from '../../../../ProgressBar'
import {
  Widget,
  PaperImage,
  Heading,
  ProgressContainer,
  Explanation,
  Button
} from './ProfileWidget.style'

/**
 * Profile widget appears until a user finishes onboarding. Once onboarded,
 * this wiget no longer appears.
 */
function ProfileWidget({completedStep, totalSteps, completedPercent}) {
  return (
    <Widget className="js-profile-widget">
      <Grid className="">
        <Cell small={1} className="hide-for-small-only">
          <PaperImage />
        </Cell>
        <Cell small={12} medium={11}>
          <Heading>
            Complete your profile to invest in your first case.
          </Heading>
          <ProgressContainer>
            <ProgressBar step={completedStep} steps={totalSteps} percent={completedPercent} />
          </ProgressContainer>
          <Explanation>
            To maintain a secure platform, we require investor profiles be completed
            prior to making investments in new LexShares offerings.
          </Explanation>
          <Button href="/investors/new">
            Complete profile
          </Button>
        </Cell>
      </Grid>
    </Widget>
  )
}

export default ProfileWidget
