import React from 'react';

import { Container } from './ArticleContent.style';


const ArticleContent = ({html}) => {
  return(
    <Container
      dangerouslySetInnerHTML={{
        __html: html.replace(/href/g, "target='_blank' href")
      }} />
  )
}

export default ArticleContent;