import React, {Fragment, useState, useEffect} from 'react'
import SingleBar from '../../../../Charts/SingleBar'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text,
  AmountContainer,
  Amount,
  AmountLabel
} from './FundInvestments.style'

/**
 * Displays the breakdown of open, partially resolved and resolved fund
 * investments in form of a horizontal bar.
 */
function FundInvestments({fundId}) {
  let [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`/widget/fund-investments/${fundId}.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Widget className="js-fund-investments-widget">
      <Content loading={data ? "false" : "true"}>
        { data &&
          <Fragment>
            <Heading>All {data.name} Investments</Heading>
            <DateRange>{data.date_range}</DateRange>
            <Text>
              The following data represents your total {data.name} investment
              metrics including quantity of case investments made and your total
              capital invested.
            </Text>
            <AmountContainer>
              <Amount className="js-total-cases">{data.total_cases}</Amount>
              <AmountLabel>
                Total Cases
              </AmountLabel>
            </AmountContainer>
            <SingleBar
              verticalLegend={true}
              showMoney={false}
              keys={['Active Cases', 'Partially Resolved Cases', 'Fully Resolved Cases']}
              data={[data.state_counts]}
            />
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default FundInvestments
