import styled from 'styled-components';
import * as Globals from '../../Globals';

export const Icon = styled.div `
  width: 37px;
  height: 37px;
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  img {
    width: 100%;
  }
`

export const Overview = styled.div`
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 24px;
  width: 80%;
`

export const Heading = styled(Globals.Body)`
  font-weight: 600;
  color: #282331;
`

export const Description = styled(Globals.Description)`
  color: #282331;
`
