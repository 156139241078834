import React, { Fragment, useRef } from 'react'
import { Cell, Grid } from 'react-foundation'
import SideNav from '../../SideNav'
import Mixpanel from '../../../components/Mixpanel'

import {
  Section,
  Content,
  SubsectionContainer,
  Subsection,
  SmallHeading,
  MediumHeading,
  BigHeading,
  InfoText,
  Label,
  SiteLink,
  ColumnHeading,
  CashFlowImage,
  InvestmentAnalysisDisclosure
} from './BackgroundInformation.style'

function BackgroundInformation({
  investmentAnalysisUrl, hasUnderwritingAnalysis, whyWeLikeIt, whatToConsider,
  plaintiffName, plaintiffDescription, defendantName, defendantDescription,
  background, longOfferingSize, prefunded, judgeName,
  jurisdiction, lawFirmName, lawFirmDescription, lawFirmFounded, lawFirmLead,
  lawFirmFeeStructure, lawFirmSize, lawFirmLawyers, lawFirmWebSite,
  lawFirmLinkedIn, visible, investmentAnalysisDisclosure,
  amountRemaining, funded, resolved, investable, trackedData
  }) {

  let sectionList = [
    {
      label: "The parties",
      title: "The parties",
      ref: useRef(null),
      body:
      <Fragment>
        <MediumHeading className="js-plaintiff">Plaintiff: {plaintiffName}</MediumHeading>
        <p className="js-plaintiff-description">{plaintiffDescription}</p>
        <MediumHeading className="js-defendant">Defendant: {defendantName}</MediumHeading>
        <p className="js-defendant-description">{defendantDescription}</p>
      </Fragment>
    }
  ]

  sectionList.push(
    {
      label: "Background",
      title: "Background",
      ref: useRef(null),
      body:
      <div dangerouslySetInnerHTML={{__html: background}}></div>
    }
  )

  if (hasUnderwritingAnalysis) {
    sectionList.push(
      {
        label: "Why we like this opportunity",
        title: "Why we like this opportunity",
        ref: useRef(null),
        body:
        <div dangerouslySetInnerHTML={{__html: whyWeLikeIt}}></div>
      },
      {
        label: "What you should consider",
        title: "What you should consider",
        ref: useRef(null),
        body:
        <div dangerouslySetInnerHTML={{__html: whatToConsider}}></div>
      }
    )
  }

  sectionList.push(
    {
      label: "Cash flow model",
      title: "Cash flow model",
      ref: useRef(null),
      body:
      <Fragment>
        <CashFlowImage src={investmentAnalysisUrl} />
        <InvestmentAnalysisDisclosure
          dangerouslySetInnerHTML={{__html: investmentAnalysisDisclosure}}
        />
      </Fragment>
    }
  )

  const trackClick = (title) => {
    trackedData['counselLink'] = title
    Mixpanel.track('Clicked counsel link', trackedData)
  }

  return (
    <Section className={visible ? "selected" : ""}>
      <Content>
        <Grid className="grid-margin-x">
          <Cell small={12} medium={6} large={2} className="show-for-large">
            <SideNav
              list={sectionList}
              offset={-310}
              clickOffset={150}
              track={true}
              scrollData={trackedData}
            />
          </Cell>
          <Cell small={12} medium={9} large={7}>
            <SubsectionContainer>
              <ColumnHeading>Case Details</ColumnHeading>
              {
                sectionList.map((section, i) =>
                  <Subsection ref={section.ref} key={i}>
                    <BigHeading>
                      { section.title }
                    </BigHeading>
                    { section.body }
                  </Subsection>
                )
              }
            </SubsectionContainer>
          </Cell>
          <Cell small={12} medium={3} large={3}>
            <ColumnHeading>Overview</ColumnHeading>
            <Subsection>
              <SmallHeading>Deal</SmallHeading>
              <InfoText className="js-deal-size">
                <Label>Size:</Label>
                {longOfferingSize}
              </InfoText>
              <InfoText className="js-remaining-capacity">
                { (!prefunded || (prefunded && investable)) &&
                  <Fragment>
                    <Label>Status:</Label>
                    {funded ? 'Fully Funded' : resolved ? 'Resolved' : `${amountRemaining} available`}
                  </Fragment>
                }
              </InfoText>
            </Subsection>
            <Subsection>
              <SmallHeading>Parties</SmallHeading>
              <InfoText>
                <Label>Plaintiff:</Label>
                {plaintiffName}
              </InfoText>
              <InfoText>
                <Label>Defendant:</Label>
                {defendantName}
              </InfoText>
              <InfoText className="js-judge-name">
                <Label>Judge:</Label>
                {judgeName}
              </InfoText>
              <InfoText className="js-jurisdiction">
                <Label>Jurisdiction:</Label>
                {jurisdiction}
              </InfoText>
            </Subsection>
            { lawFirmName &&
              <Subsection>
                <SmallHeading>Counsel</SmallHeading>
                <InfoText>
                  <Label>Firm:</Label>
                  {lawFirmName}
                </InfoText>
                <InfoText>
                  <Label>Type:</Label>
                  {lawFirmDescription}
                </InfoText>
                <InfoText>
                  <Label>Founded:</Label>
                  {lawFirmFounded}
                </InfoText>
                <InfoText>
                  <Label>Lead Counsel</Label>
                  {lawFirmLead}
                </InfoText>
                <InfoText>
                <Label>Fee Structure:</Label>
                  {lawFirmFeeStructure}
                </InfoText>
                <InfoText>
                  <Label>Team Size:</Label>
                  {lawFirmSize}
                </InfoText>
                <InfoText>
                  <Label>Lawyers:</Label>
                  {lawFirmLawyers}
                </InfoText>
                <InfoText>
                  {lawFirmWebSite && <SiteLink href={lawFirmWebSite} target="_blank" onClick={() => trackClick('website')}>Website</SiteLink>}
                  {lawFirmLinkedIn && <SiteLink href={lawFirmLinkedIn} target="_blank" onClick={() => trackClick('linkedin')}>Linkedin</SiteLink>}
                </InfoText>
              </Subsection>
            }
          </Cell>
        </Grid>
      </Content>
    </Section>
  )
}

export default BackgroundInformation
