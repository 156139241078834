import React, { Fragment } from 'react'
import InputField from '../../../../components/Form/InputField'
import InputRadio from '../../../../components/Form/InputRadio'
import { Error } from '../../../../components/Form/Globals'

import {
  Heading,
  Subheading,
  RadioContainer,
  ErrorsContainer,
  TaxContainer
} from './InvestmentAccreditation.style'

function InvestmentAccreditation({inputHandler, error, investmentMethod,
  accreditationMethod, accreditationMethodHandler, ssn, ein}) {

  const secLink = 'https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3'

  const incomeDescription = `I made $200,000 or more in each of the two 
  most recent years and have the expectation to make at least that amount 
  this year or I have a joint income with my spouse that has exceeded $300,000
  for each of the last 2 years and I expect it will exceed that this year.`

  const netWorthDescription = `I have an individual net worth, or joint net
  worth with my spouse, that exceeds $1,000,000 today (excluding my primary 
  residence).`

  const businessDescription = `I invest on behalf of an entity with at least
  $5,000,000 in assets or in which all the equity owners are accredited.`

  const taxIdToolTip = `To comply with federal law and provide tax
  documentation, we require this information to verify and protect your
  identity.`

  return (
    <Fragment>
      <Heading>Accreditation</Heading>
      <Subheading>
        You must be
        <a target="_blank" href={secLink} rel="noreferrer"> accredited </a> 
	to invest on the LexShares platform.<br/>
        <strong>Select one of the below qualifications.</strong>
      </Subheading>
      <RadioContainer className={error.accreditationMethod ? 'error' : null}>
        { investmentMethod.value == 'entity' ?
          <InputRadio
            label="Investment for Business"
            value="investment_for_business"
            description={businessDescription}
            name="accreditation-method"
            selected={accreditationMethod == 'investment_for_business'}
            changeHandler={() => accreditationMethodHandler('investment_for_business')}
          />
          :
          <Fragment>
            <InputRadio
              label="Income"
              value="income"
              description={incomeDescription}
              name="accreditation-method"
              selected={accreditationMethod == 'income'}
              changeHandler={() => accreditationMethodHandler('income')}
            />
            <InputRadio
              label="Net Worth"
              value="net_worth"
              description={netWorthDescription}
              name="accreditation-method"
              selected={accreditationMethod == 'net_worth'}
              changeHandler={() => accreditationMethodHandler('net_worth')}
            />
          </Fragment>
        }
        { investmentMethod.value == 'trust' &&
          <InputRadio
            label="Investment for Entity"
            value="investment_for_business"
            description={businessDescription}
            name="accreditation-method"
            selected={accreditationMethod == 'investment_for_business'}
            changeHandler={() => accreditationMethodHandler('investment_for_business')}
          />
        }
      </RadioContainer>
      <ErrorsContainer>
        { error.accreditationMethod != null &&
          <Error>{error.accreditationMethod}</Error>
        }
      </ErrorsContainer>
      { investmentMethod.domestic &&
        <TaxContainer>
          { (investmentMethod.value == 'entity') || ((investmentMethod.value == 'trust') && (accreditationMethod == 'investment_for_business')) ?
            <InputField
              type="number"
              label="Tax ID"
              name="ein"
              value={ein}
              placeholder="XX-XXXXXXX"
              format="##-#######"
              changeHandler={inputHandler}
              error={error.ein}
              tooltip={taxIdToolTip}
            />
            :
            <InputField
              type="number"
              label="Social security number"
              name="ssn"
              value={ssn}
              placeholder="XXX-XX-XXXX"
              format="###-##-####"
              changeHandler={inputHandler}
              error={error.ssn}
              tooltip={taxIdToolTip}
            />
          }
        </TaxContainer>
      }
    </Fragment>
  )
}

export default InvestmentAccreditation
