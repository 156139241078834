import React from 'react'
import EntityForm from './EntityForm'
import TrustForm from './TrustForm'
import RetirementAccountForm from './RetirementAccountForm'
import { Icon, Overview, Heading, Description } from './AccountForm.style'
import { Grid, Cell } from 'react-foundation'
import BusinessAreaImg from '../../../../assets/images/icons/business-area.png';
import GovernmentImg from '../../../../assets/images/icons/government.png';


function AccountForm({csrfToken, investor}) {

  return (
    <>
      <Grid className="grid-margin-y">
        <Cell small={12}>
          <Icon>
            <img src={BusinessAreaImg} />
          </Icon>
          <Overview>
            <Heading>
              Entity
            </Heading>
            <Description>
              Invest on behalf of a business or other legal entity.
            </Description>
          </Overview>
          <EntityForm csrfToken={csrfToken} investor={investor} />
        </Cell>
      </Grid>
      <Grid className="pt-10">
        <Cell small={12}>
          <Icon>
            <img src={BusinessAreaImg} />
          </Icon>
          <Overview>
            <Heading>
              Trust
            </Heading>
            <Description>
              Invest on behalf of a business or other legal entity.
            </Description>
          </Overview>
          <TrustForm csrfToken={csrfToken} investor={investor} />
        </Cell>
      </Grid>
      <Grid className="pt-10">
        <Cell small={12}>
          <Icon>
            <img src={GovernmentImg} />
          </Icon>
          <Overview>
            <Heading>
              IRA
            </Heading>
            <Description>
              Invest through an existing retirement account.
            </Description>
          </Overview>
          <RetirementAccountForm csrfToken={csrfToken} investor={investor} />
        </Cell>
      </Grid>
    </>
  )
}

export default AccountForm
