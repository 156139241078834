import React from "react";
import { FormContainer, Heading } from "./RegisterForm.style";
import InputField from "../../../components/Form/InputField";
import InputButton from "../../../components/Form/InputButton";
import { SmallText } from "../../../components/Globals";
import validate from "../../../components/Form/Validate";
import axios from "axios";

class RegisterForm extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      email: "",
      password: "",
      submitted: false,
      submissionError: null,
      inProgress: false,
      validationErrors: {
        email: null,
        password: null,
      },
    };
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
        // Validate after state has been updated
        let errors = validate(this.state);
        this.setState({ validationErrors: errors.messages });
    });
}

  handleSubmit(event) {
    let errors = validate(this.state);
    this.setState({ validationErrors: errors.messages, submissionError: null });
    event.preventDefault();
    if (errors.count > 0) {
      this.setState({ inProgress: false });
      return false;
    }
    this.setState({ inProgress: true });
    const payload = {
      user: {
        email: this.state.email,
        password: this.state.password,
      },
      authenticity_token: this.props.csrfToken,
    };
    axios({
      method: "post",
      responseType: "json",
      url: "/invest/signup.json",
      data: payload,
    })
      .then((res) => {
        window.location.replace(res.data.location);
      })
      .catch((e) => {
        this.setState({ inProgress: false });
        this.setState({ submissionError: e.response.data.message });
      });
  }

  render() {
    return (
      <FormContainer>
        <Heading>Sign Up to Invest</Heading>
        <form noValidate onSubmit={this.handleSubmit}>
          <input
            type="hidden"
            name="authenticity_token"
            value={this.props.csrfToken}
          />
          <InputField
            type="text"
            label="Your email address:"
            name="email"
            changeHandler={this.handleInputChange}
            error={
              this.state.validationErrors.email || this.state.submissionError
            }
          />
          <InputField
            type="password"
            label="Choose a password:"
            name="password"
            hint="Must contain at least 8 characters (one letter, one number and one special character)"
            changeHandler={this.handleInputChange}
            error={this.state.validationErrors.password}
            autoComplete="off"
          />
          <InputButton
            type="submit"
            value={this.state.inProgress ? "Submitting.." : "Continue"}
            name="submit-button"
            disabled={this.state.inProgress ? true : false}
          />
          <SmallText>
            Already have an account? <a href="/users/sign_in">Sign in</a>
          </SmallText>
        </form>
      </FormContainer>
    );
  }
}

export default RegisterForm;
