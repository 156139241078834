import React, {Fragment, useState, useEffect} from 'react'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  Status,
  CaseLink,
  Divider,
  Clear,
  StatusLabel,
  Expand
} from './FundInvestmentsWidget.style'

/**
 * Displays a list of LMF2 fund investments for those with extended visibility
 * turned on.
 */
function FundInvestmentsWidget() {
  let [performance, setPerformance] = useState(null)
  let [expanded, setExpanded] = useState(true)

  const onClick = (isExpanded) => {
    setExpanded(isExpanded)
  }

  useEffect(() => {
    axios.get(`/widget/lexshares-fund-investments.json`).then(res => {
      setPerformance(res.data)
      if (res.data.length > 3) {
        setExpanded(false)
      }
    })
  }, [])

  return (
    <Widget
      hide={!expanded}
      bottomOffset={(performance && (performance.length) > 1 && expanded ? true : false)}>
      <Content loading={performance ? "false" : "true"}>
        { performance &&
          <Fragment>
            <Heading>LexShares Marketplace Fund II Investments</Heading>
            { performance.map((caseInfo, i) => (
              <Fragment key={`metric-${i}`}>
                <Status>
                  <StatusLabel status={caseInfo.status}>
                    {caseInfo.status}
                  </StatusLabel>
                </Status>
                <CaseLink href={`/cases/${caseInfo.case_id}`} target="_blank">
                  {caseInfo.name}
                </CaseLink>
                <Clear />
                <Divider />
              </Fragment>
            ))}
          </Fragment>
        }
      </Content>
      { performance && (performance.length > 3) &&
        <Fragment>
          { expanded
            ?
              <Expand onClick={() => {onClick(false)}} expanded={true}>View Less</Expand>
            :
              <Expand onClick={() => {onClick(true)}} collapsed={true} className="js-view-more-link">View More</Expand>
          }
        </Fragment>
      }
    </Widget>
  )
}

export default FundInvestmentsWidget
