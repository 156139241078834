import styled from 'styled-components';
import * as Globals from '../../Globals';

export const Heading = styled(Globals.Body)`
  font-weight: 600;
  color: #282331;
`

export const Description = styled(Globals.Description)`
  color: #282331;
`

export const Selectors = styled.div`
  display: flex;
  margin-top: 40px;
`

export const Selector = styled.a`
  font-weight: bold;
  font-size: 16px;
  background: ${props => props.selected ? "#914cde" : "#CACACA"};
  padding: 20px;
  width: 200px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: center;
  color: ${props => props.selected ? "#FFFFFF" : "#000000"};
  cursor: ${props => props.selected ? "default" : "pointer"};

  &:hover, &:focus {
    color: ${props => props.selected ? "#FFFFFF" : "#000000"  };
  }
`
