import React, { Fragment, useRef, useEffect, useState } from 'react'
import { Grid, Cell } from 'react-foundation';
import {
  Section, Content, Nav, NavList, NavItem, FaqSection,
  FaqBody, FaqHeading, Sidebar, Main, CompletedMark,
  Description
} from './ContentSection.style';
import PersonalInfoForm from '../PersonalInfoForm'
import SecurityForm from '../SecurityForm'
import InvestorSummaryForm from '../InvestorSummaryForm'
import SuitabilityForm from '../SuitabilityForm'
import AccountForm from '../AccountForm'
import FundingForm from '../FundingForm'
import AccreditationForm from '../AccreditationForm'
import CheckImg from '../../../../assets/images/icons/checkmark.png';

const OFFSET = 120

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  const y = ele.getBoundingClientRect().top + window.pageYOffset - OFFSET;
  window.scrollTo({top: y, behavior: 'smooth'});
};

function ContentSection({csrfToken, investor}) {
  const [visibleSection, setVisibleSection] = useState();

  const sidebarRef = useRef(null);

  const sectionList = [
    {
      label: "Personal Info",
      title: "Personal Info",
      completed: true,
      ref: useRef(null),
      body: <FaqBody>
        <PersonalInfoForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
    {
      label: "Suitability",
      title: "Suitability",
      completed: investor.investing_objectives ? true : false,
      description: "We are required to collect suitability information for all investors.",
      ref: useRef(null),
      body: <FaqBody>
        <SuitabilityForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
    {
      label: "Investor Summary",
      title: "Investor Summary",
      completed: investor.employer ? true : false,
      description: "This may be shown to funding recipients when you request access to cases that have an extra layer of privacy.",
      ref: useRef(null),
      body:  <FaqBody>
        <InvestorSummaryForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
    {
      label: "Accounts",
      title: "Accounts",
      completed: (investor.entity || investor.trust || investor.retirement_account) ? true : false,
      ref: useRef(null),
      body: <FaqBody>
        <AccountForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
    {
      label: "Funding Sources",
      title: "Funding Sources",
      completed: (
        investor.bank_account_number ||
        (investor.entity && investor.entity.bank_account_number) ||
        (investor.trust && investor.trust.bank_account_number) || 
        (investor.retirement_account && investor.retirement_account.bank_account_number)) ? true : false,
      description: "Used to fund individual or entity investments. You can also add this when making an investment. The same instructions will be used to disburse funds via ACH upon successful resolution of investments.",
      ref: useRef(null),
      body: <FaqBody>
        <FundingForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
    {
      label: "Accreditation",
      title: "Accreditation",
      completed: investor.accreditation_documents.length > 0 ? true : false,
      descriptions: "Select an option below to view your accreditation status and upload supporting documents.",
      ref: useRef(null),
      body: <FaqBody>
        <AccreditationForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
    {
      label: "Security",
      title: "Security",
      ref: useRef(null),
      completed: true,
      body: <FaqBody>
        <SecurityForm investor={investor} csrfToken={csrfToken} />
      </FaqBody>
    },
  ]

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + OFFSET + 1;

      const selected = sectionList.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);

          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.label !== visibleSection) {
        setVisibleSection(selected.label);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <Fragment>
      <Section>
        <Content>
          <Grid>
            <Cell large={3} className="show-for-large">
              <Sidebar ref={sidebarRef}>
                <Nav>
                  <NavList>
                    {
                      sectionList.map((faq, i) =>
                        faq.label && <NavItem selected={visibleSection == faq.label} key={i} onClick={() => scrollTo(faq.ref.current)}>
                          {faq.label}
                        </NavItem>
                      )
                    }
                  </NavList>
                </Nav>
              </Sidebar>
            </Cell>

            <Cell large={8}>
              <Main>
                {
                  sectionList.map((faq, i) =>
                    <FaqSection ref={faq.ref} key={i}>
                      <FaqHeading>
                        { faq.title }
                        <CompletedMark completed={faq.completed}>
                          <img src={CheckImg} />
                        </CompletedMark>
                      </FaqHeading>
                      { faq.description && <Description>
                        {faq.description }
                      </Description> }
                      { faq.body }
                    </FaqSection>
                  )
                }
              </Main>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default ContentSection;
