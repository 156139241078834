import React, {Fragment, useState, useEffect} from 'react'
import SingleBar from '../../../../Charts/SingleBar'
import NumberFormat from 'react-number-format'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text,
  AmountContainer,
  Amount,
  AmountLabel
} from './ResolvedInvestmentsWidget.style'

/**
 * Displays a single horizontal bar chart showing investment and return
 * amounts for all resolved investments.
 */
function ResolvedInvestmentsWidget() {
  let [resolved, setResolved] = useState(null);

  const theme = useTheme()
  const mediumUp = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    axios.get(`/widget/resolved-investments.json`).then(res => {
      setResolved(res.data)
    })
  }, [])

  return (
    <Fragment>
      { resolved && resolved.investment_amount &&
        <Widget className="js-resolved-investments-widget">
          <Content>
          <Fragment>
            <Heading>Your Fully Resolved Investments</Heading>
            <DateRange>{resolved.date_range}</DateRange>
            <Text>
              The following data represents your investment profit for resolved case
              investments made on the LexShares platform. Your investment amount
              below is for resolved cases only.
            </Text>
            <AmountContainer>
              <Amount className="js-investment-amount">
                <NumberFormat
                  value={resolved.investment_amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Amount>
              <AmountLabel>
                Investment Amount
              </AmountLabel>
            </AmountContainer>
            <AmountContainer>
              <Amount className="js-profit-amount">
                <NumberFormat
                  value={resolved.profit}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Amount>
              <AmountLabel>
                Resolved Profit
              </AmountLabel>
            </AmountContainer>
            <AmountContainer>
              <Amount className="js-return-amount">
                <NumberFormat
                  value={resolved.return_amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Amount>
              <AmountLabel>
                Return Amount
              </AmountLabel>
            </AmountContainer>
            <SingleBar
              verticalLegend={mediumUp ? false : true}
              labelWidth={165}
              keys={['Investment Amount', 'Resolved Profit']}
              data={[{
                'Investment Amount': resolved.investment_amount,
                'Resolved Profit': resolved.profit
              }]}
            />
            </Fragment>
          </Content>
        </Widget>
      }
    </Fragment>
  )
}

export default ResolvedInvestmentsWidget
