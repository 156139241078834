import React, { Fragment, useRef, useState } from "react";
import ModalPop from "../../../ModalPop";
import {
  ForgotPasswordLink,
  Heading,
  InputButtonContainer,
  SignUpLink,
  SignUpText,
  ErrorMessage,
} from "./LogInModal.style";
import { Cell, Grid } from "react-foundation";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import InputField from "../../../Form/InputField";
import InputButton from "../../../Form/InputButton";

const LogInModal = ({ hideHandler, csrfToken }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const formRef = useRef(null);
  const handleSubmit = ({ email, password }) => {
    const payload = {
      user: {
        email: email,
        password: password,
      },
      authenticity_token: csrfToken,
    };

    axios
      .post(`/users/sign_in.json`, payload)
      .then((response) => {
        window.location = response.data.location;
      })
      .catch((e) => {
        console.log(e.response.data.error);
        setErrorMessage(e.response.data.error);
      });
  };
  const schema = yup.object().shape({
    email: yup.string().email().required("Required").label("Email"),
    password: yup.string().required("Required").label("Password"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const content = (
    <Fragment>
      <Heading>Log in</Heading>
      <Grid className="grid-x grid-margin-x">
        <Cell small={12}>
          <Formik
            initialValues={defaultValues}
            onSubmit={(e) => handleSubmit(e)}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={true}
            innerRef={formRef}
          >
            {({ errors, handleSubmit, handleChange, values, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit}>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Grid className="grid-margin-x">
                  <Cell small={12}>
                    <InputField
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      changeHandler={handleChange}
                      error={errors.email}
                      value={values.email}
                      autoFocus={true}
                    />
                  </Cell>
                  <Cell small={12}>
                    <InputField
                      type="password"
                      name="password"
                      placeholder="Password"
                      changeHandler={handleChange}
                      error={errors.password}
                      value={values.password}
                    />
                  </Cell>
                </Grid>
                <InputButtonContainer>
                  <InputButton
                    type="submit"
                    color="#914cde"
                    value="Log in"
                    disabled={isSubmitting || !isValid}
                  />
                </InputButtonContainer>
              </form>
            )}
          </Formik>
          <ForgotPasswordLink href="/users/password/new">
            Forgot password?
          </ForgotPasswordLink>
          <SignUpText>
            Not a LexShares investor yet?{" "}
            <SignUpLink href="/invest/signup">Sign Up →</SignUpLink>
          </SignUpText>
        </Cell>
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <ModalPop
        popVisible={true}
        content={content}
        size="medium"
        hideHandler={hideHandler}
      />
    </Fragment>
  );
};

export default LogInModal;
