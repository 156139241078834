import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/users-confirmations-new/Banner/Banner'
import Content from '../../components/users-confirmations-new/Content/Content'

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`

class UsersConfirmationsNew extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Banner />
            <Content userConfirmationPath={this.props.userConfirmationPath} csrfToken={this.props.csrfToken} newUserSessionPath={this.props.newUserSessionPath} />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default UsersConfirmationsNew;
