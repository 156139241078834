import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Content, Heading, Tagline, Diamonds } from './RegisterSection.style'
import Checklist from '../../Checklist'
import WhitePressLogos from '../../WhitePressLogos'
import RegisterForm from '../RegisterForm'
import DiamondArray from '../../../../assets/images/backgrounds/diamond-array-gray.png';

function RegisterSection({csrfToken = null}) {
  const expectations = [
    'Get notified when new opportunities are posted, and invest online.',
    'Use your LexShares dashboard to track investment progress.',
    'If the plaintiff prevails, you could share in the recovery.'
  ];
  return (
    <Section>
      <Content>
        <Grid className="grid-margin-x align-center">
          <Cell medium={12} large={6} className="small-order-2 large-order-1">
            <Heading>
              Start investing in commercial litigation.
            </Heading>
            <Grid className="grid-margin-x align-center">
              <Cell small={12} className="small-order-2 large-order-1">
                <Tagline>
                  Here is what you can expect after signing up for a LexShares investor account:
                </Tagline>
                <Checklist list={expectations} />
              </Cell>
              <Cell small={12} className="small-order-1 large-order-2">
                <WhitePressLogos />
              </Cell>
            </Grid>
          </Cell>
          <Cell medium={12} large={6} className="small-order-1 large-order-2">
            <Heading>
              Start investing in commercial litigation.
            </Heading>
            <RegisterForm csrfToken={csrfToken} />
          </Cell>
        </Grid>
      </Content>
      <Diamonds>
        <img src={DiamondArray} />
      </Diamonds>
    </Section>
  )
}

export default RegisterSection
