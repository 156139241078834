import React from 'react';
import { SimpleCardContainer, Icon, Heading, Description, Link } from './SimpleCard.style';
import ArrowImg from '../../../../assets/images/icons/arrow.png';

function SimpleCard({src, heading, description, href}) {
  return (
    <SimpleCardContainer href={href}>
      <Icon>
        <img src={ src } />
      </Icon>
      <Heading>
        { heading }
      </Heading>
      <Description>
        { description }
      </Description>
      <Link>
        Read More
        <img src={ArrowImg} />
      </Link>
    </SimpleCardContainer>
  );
}

export default SimpleCard;
