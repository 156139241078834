import React from "react";
import { Cell, Grid, Column, Row } from "react-foundation";
import { Container, Heading, WwdTitle, WwdItem, UL } from "./WhatWeDo.style";

function WhatWeDo() {
  const sectionA = [
    {
      title: "SINGLE CASE COMMERCIAL LITIGATION",
      content: [
        "Non-recourse funding for legal fees, litigation expenses, working capital, etc",
        "Case types include: breach of contract, breach of fiduciary duty, theft of trade secrets, antitrust, business squeeze outs, qui tam, and more;",
        "Stage agnostioc: funding is available from prefiling through trial",
      ],
    },
    {
      title: "PORTFOLIOS & LAW FIRM FUNDING",
      content: [
        "Custom financing solutions collateralized by multiple related or unrelated matters",
        "Law firms: Fund overhead expenses, grow your dockets, and amplify marketing programs",
        "Businesses: Offset risk and control costs without negatively impacting your P&L",
      ],
    },
    {
      title: "POST-SETTLEMENT MONETIZATION",
      content: [
        "Help claimants and law firms expedite payment of awards and fees",
        "Ease collection pressure and alleviate cash flow concerns",
      ],
    },
    {
      title: "INTERNATIONAL ARBITRATION",
      content: [
        "Fund fees and filing expenses while hedging against unpredicatble costs across multiple arbitration forums",
      ],
    },
  ];
  return (
    <Container>
      <Heading>What We Do</Heading>
      <Grid>
        {sectionA.map((section, i) => (
          <Cell small={12} medium={6} key={i}>
            <Row small={12} medium={4} large={3}>
              <Column key={i}>
                <WwdTitle dangerouslySetInnerHTML={{ __html: section.title }} />
              </Column>
              <UL>
                {section.content.map((item, j) => (
                  <WwdItem key={j}>{item}</WwdItem>
                ))}
              </UL>
            </Row>
          </Cell>
        ))}
      </Grid>
    </Container>
  );
}

export default WhatWeDo;
