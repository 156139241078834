import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import theme from "../../../config/theme";
import * as Globals from "./../../Globals";

export const Container = styled.div`
  padding: 20px;
  border-radius: 10px;
  background: ${theme.colors.white};

  ${breakpoint("medium")`
    padding: 40px 35px 35px 35px;
  `};
`;

export const CaseType = styled(Globals.H6)`
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${theme.colors.mediumPurple};
`;

export const DealSize = styled(Globals.H2)`
  line-height: 44px !important;
  text-align: center;
  color: ${theme.colors.darkGrey};
  font-style: normal !important;
  font-weight: normal !important;
  margin-top: 15px;

  ${breakpoint("medium")`
    font-size: 32px !important;
    line-height: 28px !important;
    margin-top: 49px;
  `};

  ${breakpoint("large")`
    font-size: 52px !important;
    line-height: 44px !important;
  `};
`;

export const Description = styled(Globals.Description)`
  line-height: 23.4px !important;
  text-align: center;
  margin: 25px 0px 30px 0px;
  height: 120px;

  ${breakpoint("medium")`
    margin: 65px 0px 50px 0px;
    height: 140px;
  `};
`;

export const Pill = styled.span`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: ${theme.colors.darkGrey};
  font-weight: bold;
  border: 1px solid ${theme.colors.lightGrey4};
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px 15px;
  display: table;
  margin: 0 auto;
`;
