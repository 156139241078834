import * as yup from "yup";
import InputSelect from "../../Form/InputSelect";
import InputButton from "../../Form/InputButton";
import { isEmpty } from "lodash";
import { Formik } from "formik";
import React, { useRef } from "react";
import { Grid, Cell } from "react-foundation";
import axios from "axios";

const litigationTypeSelectOptions = [
  {
    label: "A single case (commercial)",
    value: "A single case (commercial)",
  },
  {
    label: "A single case (non-commercial)",
    value: "A single case (non-commercial)",
  },
  {
    label: "Multiple cases",
    value: "Multiple cases",
  },
  {
    label: "I’m unsure",
    value: "I’m unsure",
  },
];

const natureOfDisputeSelectOptions = [
  {
    label: "Contract Dispute",
    value: "Contract Dispute",
  },
  {
    label: "Theft of Trade Secrets",
    value: "Theft of Trade Secrets",
  },
  {
    label: "Trademark / Copyright Infringement",
    value: "Trademark / Copyright",
  },
  {
    label: "Fraud",
    value: "Fraud",
  },
  {
    label: "Antitrust",
    value: "Antitrust",
  },
  {
    label: "Product Liability",
    value: "Mass Tort",
  },
  {
    label: "Patent Infringement",
    value: "Patent",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const litigationPlaceSelectOptions = [
  {
    label: "U.S. Federal Court",
    value: "US Federal",
  },
  {
    label: "U.S. State court",
    value: "US State",
  },
  {
    label: "Arbitration",
    value: "Arbitration",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const litigationStageSelectOptions = [
  {
    label: "Pre-litigation",
    value: "Pre-litigation",
  },
  {
    label: "Discovery",
    value: "Discovery",
  },
  {
    label: "Pre-trial",
    value: "Pre-trial",
  },
  {
    label: "Trial",
    value: "Trial",
  },
  {
    label: "Post-trial",
    value: "Other",
  },
];

const expectedRecoverySelectOptions = [
  {
    label: "Less than $2 million",
    value: "Less than $2M",
  },
  {
    label: "Between $2 million and $20 million",
    value: "$2M to $20M",
  },
  {
    label: "$20 million to $50 million",
    value: "$20M to $50M",
  },
  {
    label: "More than $50 million",
    value: "$50M+",
  },
];

const attorneyPresentSelectOptions = [
  {
    label: "Yes / I am an attorney",
    value: "Yes / I am an attorney",
  },
  {
    label: "Not yet but I intend to",
    value: "Not yet but I intend to",
  },
  {
    label: "I am representing myself (pro se)",
    value: "I am representing myself (pro se)",
  },
];

const attorneyClientBillingAgreementSelectOptions = [
  {
    label: "Full or partial contingency",
    value: "Full or partial contingency",
  },
  {
    label: "Capped hourly",
    value: "Capped hourly",
  },
  {
    label: "Hourly",
    value: "Hourly",
  },
];

const schema = yup.object().shape({
  litigation_type: yup
    .string()
    .label("What type of litigation do you require financing for?"),
  nature_of_dispute: yup.string().label("What is the nature of your dispute?"),
  litigation_place: yup.string().label("Where is the litigation taking place?"),
  litigation_stage: yup.string().label("At what stage is your litigation?"),
  expected_recovery: yup.string().label("What are the anticipated damages?"),
  attorney_present: yup
    .string()
    .label("Have you retained legal representation?"),
  attorney_client_billing_agreement: yup
    .string()
    .label("Which best describes the attorney-client billing agreement?"),
});

function Form({ csrfToken, caseSubmissionId }) {
  const formRef = useRef(null);
  const defaultValues = {
    litigation_type: "",
    nature_of_dispute: "",
    litigation_place: "",
    litigation_stage: "",
    expected_recovery: "",
    attorney_present: "",
    attorney_client_billing_agreement: "",
  };

  const handleSubmit = ({
    litigation_type,
    nature_of_dispute,
    litigation_place,
    litigation_stage,
    expected_recovery,
    attorney_present,
    attorney_client_billing_agreement,
  }) => {
    const payload = {
      raise_funds: {
        litigation_type,
        nature_of_dispute,
        litigation_place,
        litigation_stage,
        expected_recovery,
        attorney_present,
        attorney_client_billing_agreement,
      },
      authenticity_token: csrfToken,
    };

    axios
      .patch(`/raise-funds/signup/${caseSubmissionId}`, payload)
      .then(() => {
        window.location = "/funding/case-review";
      })
      .catch((e) => {
        const shared_ui = new LexShares.SharedUI();
        shared_ui.showAlert("error", e.response.data.error, false);
      })
      .then(() => {
        formRef.current.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e)}
      validationSchema={schema}
      innerRef={formRef}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        values,
        dirty,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="mt-5">
          <Grid className="grid-margin-x">
            <Cell small={12} className="js-litigation-type-cell">
              <InputSelect
                label="What type of litigation do you require financing for?"
                name="litigation_type"
                options={litigationTypeSelectOptions}
                error={touched.litigation_type && errors.litigation_type}
                value={litigationTypeSelectOptions.find(
                  (option) => option.value === values.litigation_type
                )}
                changeHandler={(option) =>
                  setFieldValue("litigation_type", option.value)
                }
              />
            </Cell>
            <Cell small={12} className="js-nature-of-dispute-cell">
              <InputSelect
                label="What is the nature of your dispute?"
                name="nature_of_dispute"
                options={natureOfDisputeSelectOptions}
                error={touched.nature_of_dispute && errors.nature_of_dispute}
                value={natureOfDisputeSelectOptions.find(
                  (option) => option.value === values.nature_of_dispute
                )}
                changeHandler={(option) =>
                  setFieldValue("nature_of_dispute", option.value)
                }
              />
            </Cell>
            <Cell small={12} className="js-litigation-place-cell">
              <InputSelect
                label="Where is the litigation taking place?"
                name="litigation_place"
                options={litigationPlaceSelectOptions}
                error={touched.litigation_place && errors.litigation_place}
                value={litigationPlaceSelectOptions.find(
                  (option) => option.value === values.litigation_place
                )}
                changeHandler={(option) =>
                  setFieldValue("litigation_place", option.value)
                }
              />
            </Cell>
            <Cell small={12} className="js-litigation-stage-cell">
              <InputSelect
                label="At what stage is your litigation?"
                name="litigation_stage"
                options={litigationStageSelectOptions}
                error={touched.litigation_stage && errors.litigation_stage}
                value={litigationStageSelectOptions.find(
                  (option) => option.value === values.litigation_stage
                )}
                changeHandler={(option) =>
                  setFieldValue("litigation_stage", option.value)
                }
              />
            </Cell>
            <Cell small={12} className="js-expected-recovery-cell">
              <InputSelect
                label="What are the anticipated damages?"
                name="expected_recovery"
                options={expectedRecoverySelectOptions}
                error={touched.expected_recovery && errors.expected_recovery}
                value={expectedRecoverySelectOptions.find(
                  (option) => option.value === values.expected_recovery
                )}
                changeHandler={(option) =>
                  setFieldValue("expected_recovery", option.value)
                }
              />
            </Cell>
            <Cell small={12} className="js-attorney-present-cell">
              <InputSelect
                label="Have you retained legal representation?"
                name="attorney_present"
                options={attorneyPresentSelectOptions}
                error={touched.attorney_present && errors.attorney_present}
                value={attorneyPresentSelectOptions.find(
                  (option) => option.value === values.attorney_present
                )}
                changeHandler={(option) =>
                  setFieldValue("attorney_present", option.value)
                }
              />
            </Cell>
            <Cell
              small={12}
              className="js-attorney-client-billing-agreement-cell"
            >
              <InputSelect
                label="Which best describes the attorney-client billing agreement?"
                name="attorney_client_billing_agreement"
                options={attorneyClientBillingAgreementSelectOptions}
                error={
                  touched.attorney_client_billing_agreement &&
                  errors.attorney_client_billing_agreement
                }
                value={attorneyClientBillingAgreementSelectOptions.find(
                  (option) =>
                    option.value === values.attorney_client_billing_agreement
                )}
                changeHandler={(option) =>
                  setFieldValue(
                    "attorney_client_billing_agreement",
                    option.value
                  )
                }
              />
            </Cell>
          </Grid>
          <Grid className="grid-margin-y">
            <InputButton
              type="submit"
              value={isSubmitting ? "Submitting.." : "Submit"}
              name="submit-button"
              disabled={!dirty || isSubmitting || !isEmpty(errors)}
              disabledColor="#D4D4D4"
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default Form;
