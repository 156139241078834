import styled from 'styled-components'
import * as Globals from '../../../../Globals'
import page from '../../../../../../assets/images/icons/page.png'

export const Widget = styled(Globals.Widget)`
  min-height: 200px;
`

export const PaperImage = styled.div`
  background: url(${page}) no-repeat center left/43px;
  height: 63px;
`

export const Heading = styled.span`
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 15px;
`

export const ProgressContainer = styled.div`
  > div
    > div:first-of-type {
      padding: 15px 0 10px 0 !important;
  }
  .progress {
    background-color: #67646a !important;
  }
  margin-bottom: 15px;
`

export const Explanation = styled.p`
  color: white;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
`

export const Button = styled(Globals.ButtonLink)`
`
