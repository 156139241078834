import React from "react";
import { Cell, Grid } from "react-foundation";
import {
  Card,
  Description,
  FeatureImage,
  Tag,
  Title,
} from "./WhitepaperCard.style";

const WhitepaperCard = (props) => {
  const url =
    props.tag === "Article" ? props.slug : `/whitepapers/${props.slug}`;
  return (
    <Cell small={12} medium={6}>
      <Card href={url}>
        <Grid className="grid-padding-x">
          <FeatureImage alt={props.title} src={props.logo_link} />
          <Tag>{props.tag ? props.tag : "Whitepaper"}</Tag>
          <Title>{props.title}</Title>
          <Description>{props.description}</Description>
        </Grid>
      </Card>
    </Cell>
  );
};

export default WhitepaperCard;
