import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import * as Globals from './../Globals'

export const CardContainer = styled.div`
  padding: 30px 25px;
  border-radius: 10px;
  height: ${props => props.hasDescription ? '345px' : '235px'};
  display: block;
  width: 100%;
  max-width: unset;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(107, 107, 107, 0.2);
  ${breakpoint('large')`
    float: left;
    max-width: 275px;
  `}
`

export const DocDate = styled.div`
  font-size: 12px;
  font-weight: 300px;
  margin-bottom: 20px;
  color: #1E1924;
`

export const Title = styled.div`
  font-size: 22px;
  height: 65px;
  margin-bottom: 17px;
  line-height: 27px;
  ${Globals.tiempoFontStack};
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 25px;
`

export const Button = styled(Globals.ButtonLink)`
  width: 150px;
  min-width: 150px;
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
`
