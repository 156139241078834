import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';


export const Section = styled.div`
  background-color: #282331;
`

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`
