import styled from 'styled-components'

export const Container = styled.div`
  z-index: 9999;
  text-align: center;
  padding: 10px;
  color: white;
  font-weight: 500;
  margin: 0;
  background-color: linear-gradient(90deg, rgba(57,21,112,1) 0%, rgba(113,69,176,1) 50%, rgba(57,21,112,1) 100%);
  position: fixed;
  width: 100%;
  font-size: .9em;
  line-height: 1em;
  background: linear-gradient(90deg, rgba(57,21,112,1) 0%, rgba(113,69,176,1) 50%, rgba(57,21,112,1) 100%);
  min-height: 42px;
`

export const Message = styled.p`
  margin: 0;
  padding: 0;
`
