import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Hero } from "../../components/funding";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import LearnMoreSection from "../../components/LearnMoreSection";
import theme from "../../config/theme";
import HeroBackground from "../../../assets/images/attorney/hero.png";
import PressBanner from "../../components/PressBanner";
import HowItWorksSection from "../../components/home/HowItWorksSection/HowItWorksSection";
import PurpleBackground from "../../../assets/images/home/purple-background";
import ResourcesSection from "../../components/ResourcesSection";
import CaseStudySamples from "../../components/CaseStudySamples/CaseStudySamples";
import CheckmarkInShield from '../../../assets/images/icons/checkmark-in-shield.png';
import DollarSign from '../../../assets/images/icons/dollar-sign.png';
import Gavel from '../../../assets/images/icons/gavel.png';
import AttorneyGuide from '../../../assets/images/attorney_resource_center/attorney-guide-book.png';
import AttorneyWhiteImg from '../../../assets/images/logos/press-logo-attorneys-white.png'

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;

const caseStudies = [
  {
    heading: "Manage Risk",
    subheading:
      "Create liquidity by reducing or rebalancing contingency fee risk and arrears.",
    caseType: "Breach Of Contract",
    caseFundingAmount: "$500,000",
    caseDescription:
      "In this case, funding helped offset hourly legal fee arrears and covered additional legal fees as discovery proceeded.",
    fundingType: "Single Case",
    iconPath: CheckmarkInShield,
  },
  {
    heading: "Drive Growth",
    subheading:
      "Obtain working capital for marketing programs and growth initiatives using a portfolio funding arrangement.",
    caseType: "Antitrust",
    caseFundingAmount: "$4,000,000",
    caseDescription:
      "Litigation funding allowed this law firm to pay for depositions, damages expert fees, and a class certification notice—all while remaining on full contingency.",
    fundingType: "Portfolio",
    iconPath: DollarSign,
  },
  {
    heading: "Acquire and Retain Clients",
    subheading:
      "Offer more client-friendly engagement structures by using outside capital to enable alternative fee arrangements.",
    caseType: "Breach of Fiduciary Duty",
    caseFundingAmount: "$750,000",
    caseDescription:
      "With the plaintiff on the brink of bankruptcy, swift funding from LexShares made it possible to retain high-caliber counsel and cover all legal expenses.",
    fundingType: "Single Case",
    iconPath: Gavel,
  },
];

const initialReview = {
  caseType: "Breach of Fiduciary Duty",
  caseDescription:
    "Shareholders of Sterling Cooper allege the agency's financial advisors failed to disclose significant conflicts of interests with a rival firm after a recent acquisition.",
  damagesAmount: "$20,000,000",
  defendant: "Cutler Gleason Chaough",
  defendantDescription:
    "Owners of a large M&A advisory firm tasked with finding the best possible buyer for Sterling Cooper, which was allegedly sold below fair market value.",
};

const funding = {
  damagesAmount: "$2.00M",
};

class Attorneys extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Hero
              heading="Work with a leader in litigation funding."
              subheading="Recognized by Chambers & Partners as a top litigation finance firm, LexShares is trusted by boutique, mid-sized, and AmLaw firms."
              buttonText="Contact Us"
              background={HeroBackground}
              buttonLink="/funding/case"
              secondaryButtonText="View Resource Center"
              secondaryButtonLink="/resources"
            />
            <PressBanner src={AttorneyWhiteImg} />
            <CaseStudySamples
              heading="A powerful resource for attorneys and clients"
              caseStudies={caseStudies}
            />
            <HowItWorksSection
              initialReview={initialReview}
              leaglUnderwriting={{}}
              funding={funding}
            />
            <ResourcesSection
              imagePath={AttorneyGuide}
              heading="Litigation funding insights for attorneys, by attorneys."
              subheading="Visit our resource center for the latest industry trends, case law anaylsis, and ethics best practices—all in one place."
            />
            <LearnMoreSection
              background={PurpleBackground}
              heading="Discuss funding with LexShares."
              subheading="LexShares has funded a wide range of case types at various stages of litigation. If there is interest in obtaining financing, please contact our team."
              buttonText="Contact Us"
              buttonLink="/funding/case"
            />
          </Page>
          <Footer attorneyPage={true} />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Attorneys;
