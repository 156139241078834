import styled from 'styled-components'
import * as Globals from '../../../Globals'

export const Widget = styled(Globals.Widget)`
  padding: 21px 31px;
  background-color: #1E1924;
`

export const Heading = styled.p`
  color: white;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: left;
`

export const Title = styled.a`
  ${Globals.tiempoFontStack};
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  display: block;
  &:hover, &:active, &:visited {
    color: white;
  }
`

export const Amount = styled.div`
  color: white;
  font-weight: 700;
  font-size: 16px;
  text-align: right;
`

export const Date = styled.div`
  font-weight: 400;
  color: white;
  font-size: 12px;
  margin-bottom: 20px;
  color: #F8F7FA;
  opacity: 0.75;
`

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
`
