import React, {useEffect} from 'react'
import {Cell, Grid} from 'react-foundation'
import SideColumn from '../SideColumn'
import FundSummary from './FundSummary'
import FundInvestments from './FundInvestments'
import FundInvestmentTypes from './FundInvestmentTypes'
import FundInvestmentProfile from './FundInvestmentProfile'
import Mixpanel from '../../../Mixpanel'

function FundPortfolio({fundId, parentFundId, investmentCount}) {
  useEffect(() => {
    Mixpanel.track('Viewed fund portfolio tab')
  }, [])

  return (
    <Grid className="grid-margin-x">
      <Cell medium={12} large={8}>
        <FundSummary fundId={fundId} />
        <FundInvestments fundId={parentFundId} />
        <FundInvestmentTypes fundId={parentFundId} />
        <FundInvestmentProfile fundId={parentFundId} />
      </Cell>
    </Grid>
  )
}

export default FundPortfolio
