import React from "react";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
  Header,
  PressLogo,
  PressUrl,
  Subheading,
  Text,
} from "./WhitepaperCta.style";
import DownloadGuideForm from "../DownloadGuideForm/DownloadGuideForm";

const WhitepaperCta = ({ csrfToken, whitepaper, whitepapersPath }) => {
  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItemLink href={whitepapersPath}>Home</BreadcrumbItemLink>
        &nbsp;—&nbsp;
        <BreadcrumbItem>Whitepapers</BreadcrumbItem>
      </Breadcrumb>
      <Header>{whitepaper.title}</Header>
      <Subheading>{whitepaper.subheader}</Subheading>
      <DownloadGuideForm csrfToken={csrfToken} whitepaper={whitepaper} />
      {whitepaper.press_logo_link && (
        <React.Fragment>
          <Text>
            Select insights featured on{" "}
            {whitepaper.press_url ? (
              <PressUrl target="_blank" href={whitepaper.press_url}>
                <PressLogo src={whitepaper.press_logo_link} />
              </PressUrl>
            ) : (
              <PressLogo src={whitepaper.press_logo_link} />
            )}
          </Text>
        </React.Fragment>
      )}
    </Container>
  );
};

export default WhitepaperCta;
