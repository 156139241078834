import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation'

import { Article, Logo, Date, Title, Preview, Button } from './ArticleCard.style';

function ArticleCard(props) {
  return (
    <Fragment key={props.id}>
      <Article>
        <Grid className="grid-x grid-margin-x">
          <Cell medium={3}>
            <Logo src={props.logo} />
          </Cell>
          <Cell medium={9}>
            <Date>
              { props.byline }
            </Date>
            <Title>
              { props.title }
            </Title>
            <Preview>
              { props.body }
            </Preview>
            <Button href={props.story_url} target="_blank">
              View Full Article
            </Button>
          </Cell>
        </Grid>
      </Article>
    </Fragment>
  )
}

export default ArticleCard;
