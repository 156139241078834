import styled from "styled-components";
import breakpoint from 'styled-components-breakpoint';
import * as Globals from './../Globals';
import theme from '../../config/theme';

export const Container = styled.div`
  padding: 0px;

  ${breakpoint('medium')`
    padding: 75px 20px 75px 0px;
  `};
`;

export const Heading = styled(Globals.H2)`
  color: ${theme.colors.darkGrey};
  margin-bottom: 20px;
`;

export const Subheading = styled(Globals.Description)`
`;