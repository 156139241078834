import React, {Fragment, useEffect, useState} from 'react'
import Bar from '../../../../Charts/Bar'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text
} from './InvestmentTypeWidget.style'

/**
 * Displays the types of cases that have been funded by LexShares. The data
 * is manually set and pulled from admin > site > investment types.
 */
function InvestmentTypeWidget() {
  let [type, setType] = useState(null);

  useEffect(() => {
    axios.get(`/widget/lexshares-investment-type.json`).then(res => {
      setType(res.data)
    })
  }, [])

  return (
    <Widget className="js-ls-investment-type-widget">
      <Content loading={type ? "false" : "true"}>
        { type &&
          <Fragment>
            <Heading>LexShares Case Investment Type</Heading>
            <DateRange>{type.disclosure.period}</DateRange>
            <Text>{type.disclosure.description}</Text>
            <Bar data={type.types.data} tipLabel="cases" />
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default InvestmentTypeWidget
