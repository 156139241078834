import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Slide = styled.div`
  transition: transform 250ms linear;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  ${breakpoint('large')`
    transition: unset;
    position: unset;
    height: unset;
    width: unset;
    top: unset;
    left: unset;
  `}
`
