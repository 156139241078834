import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  BannerSection,
  TeamSection,
  QuoteSection,
} from "../../components/about";
import CallToActionSection from "../../components/CallToActionSection";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import theme from "../../config/theme";

const AboutPage = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;

class AboutUs extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <AboutPage>
            <BannerSection />
            <TeamSection employees={this.props.employees}/>
            <QuoteSection />
            <CallToActionSection
              heading="Explore careers with LexShares"
              tagline="Visit the LexShares careers page to see if there are any current opportunities for someone with your unique talents."
              cta="Explore Openings"
              link="https://www.linkedin.com/company/lexshares-inc-/jobs"
            />
          </AboutPage>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default AboutUs;
