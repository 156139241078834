import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Banner, BannerContent, BannerProp, Heading, StatsContent, Metric, Value, Label } from './BannerSection.style';
import BannerImg from '../../../../assets/images/shared/banner-prop.svg';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <BannerProp>
          <img src={BannerImg}/>
        </BannerProp>

        <BannerContent>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                We are litigators, bankers, technologists, and entrepreneurs.
              </Heading>
            </Cell>
          </Grid>
        </BannerContent>
        <StatsContent>
          <Grid>
            <Cell small={6} className="medium-auto divider">
              <Metric>
                <Value>
                  2014
                </Value>
                <Label>
                  Year LexShares was founded
                </Label>
              </Metric>
            </Cell>
            <Cell small={6} className="medium-auto divider">
              <Metric>
                <Value>
                  1,000,000+
                </Value>
                <Label>
                  Cases evaluated by LexShares
                </Label>
              </Metric>
            </Cell>
            <Cell small={6} className="medium-auto divider">
              <Metric>
                <Value>
                  70+
                </Value>
                <Label>
                  Combined years of industry experience
                </Label>
              </Metric>
            </Cell>
            <Cell small={6} className="medium-auto divider">
              <Metric>
                <Value>
                  150,000+
                </Value>
                <Label>
                  Lines of code powering LexShares platform
                </Label>
              </Metric>
            </Cell>
          </Grid>
        </StatsContent>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
