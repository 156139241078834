import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import Hero from '../../../../assets/images/backgrounds/diamond-array-purple.png'

export const Container = styled.div`
  margin-bottom: 67px;
  margin-top: 0px;

  ${breakpoint('medium')`
    margin-bottom: 120px;
  `}
`;

export const Breadcrumb = styled.div`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  margin-top: 7px;
  font-weight: bold;
  text-align: center;

  ${breakpoint('medium')`
    text-align: left;
  `}
`;

export const BreadcrumbItemLink = styled.a`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  font-weight: bold;

  &:hover {
    color: ${theme.colors.purpleGrey};
  }
`;

export const BreadcrumbItem = styled.span`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  font-weight: bold;
`;

export const Header = styled(Globals.H1)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  color: ${theme.colors.backdropPurple};
  margin-top: 7px;
  line-height: 29px;
  text-align: center;

  ${breakpoint('medium')`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
    margin-top: 31px;
  `}
`;

export const Subheading = styled(Globals.H6)`
  ${Globals.proximaFontStack}
  margin-top: 16px;
  line-height: 36px;
  font-weight: normal;
  color: ${theme.colors.backdropPurple};
  text-align: center;

  ${breakpoint('medium')`
    text-align: left;
  `}
`;

export const SlipOpinionContainer = styled.div`
  width: 120px;
  margin: auto auto 36px auto;

  ${breakpoint('medium')`
    width: 250px;
    margin: 0px 40px 0px auto;
  `}

  ${breakpoint('large')`
    width: 400px;
    margin: 0px 188px 0px auto;
  `}
`;

export const DiamondBackground = styled(SB.Banner)`
  display: none;

  ${breakpoint('large')`
    display: block;
    background: url(${Hero}) no-repeat center center/cover;
    position: absolute;
    right: 22px;
    top: unset;
    width: 410px;
    height: 332px;
    margin-top: 99px
  `}
`;

export const ImageContainer = styled.div`
  margin-top: 0px;
  position: relative;

  ${breakpoint('medium')`
    margin-top: 80px;
  `}

  ${breakpoint('large')`
    margin-top: 0px;
  `}
`;