import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H2, H4, Body } from '../../Globals';

export const Overview = styled.div`
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 8% 3%;
  `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

export const Name = styled(H2)``

export const Title = styled(H4)`
  color: ${props => props.theme.colors.purple};
  margin-bottom: 60px;
`

export const Label = styled(Body)`
  font-weight: bold;
`

export const Value = styled.a`
  color: #878291;
  font-size: 18px;
`

export const Info = styled.div`
  margin-bottom: 50px;
`

export const Photo = styled.div`
  img {
    width: 100%;
    margin-bottom: 15px;
  }

  ${breakpoint('medium')`
    max-width: 50%;
  `}
`

export const AboutLink = styled.a `
  color: #979797;

  &:hover {
    color: #979797;
  }
`

export const Breadcrumb = styled.div`
  font-size: 16px;
  margin-bottom: 20px;

  ${breakpoint('medium')`
    margin-bottom: 46px;
  `}

`

export const Description = styled(Body)`
  margin-bottom: 18px;
`

export const ContentBody = styled.div`
  padding: 0;
`
