import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.ExpandableWidget)`
  ${({ hide }) => hide && `
    height: 925px;
    &::after {
      background: linear-gradient(to top,
         rgba(30, 25, 36, 1) 5%,
         rgba(30, 25, 36, 0) 10%
      );
    }
 `}
  ${breakpoint('medium')`
    ${({ hide }) => hide && `
      height: 455px;
      &::after {
        background: linear-gradient(to top,
           rgba(30, 25, 36, 1) 15%,
           rgba(30, 25, 36, 0) 25%
        );
      }
   `}
  `}
`

export const Expand = styled(Globals.WidgetExpander)`
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
  padding-bottom: 10px;
`

export const Heading = styled(Globals.WidgetHeading)`
  margin-bottom: 26px;
`

export const DateRange = styled(Globals.WidgetDateRange)`
`

export const CaseRow = styled.div`
  width: 100%;
  margin-top: 5px;
`

export const Status = styled.div`
  width: 90px;
  margin-bottom: 15px;
  margin-top: 3px;
  ${breakpoint('medium')`
    float: left;
    margin-bottom: 0;
    margin-bottom: 100px;
  `}
  ${breakpoint('large')`
    float: left;
    margin-bottom: 0;
    margin-bottom: 10px;
  `}
`

export const CaseLink = styled.a`
  color: white;
  font-family: 'TiemposHeadlineWeb';
  font-size: 18px;
  line-height: 22px;
  display: block;
  z-index: 99;
  position: relative;
  &:hover, &:active, &:visited {
    color: white;
  }
`

export const Clear = styled.div`
  clear: both;
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin: 15px 0 20px 0;
  border-bottom: 1px solid white;
  opacity: .2;
  &:last-of-type {
    border: 0;
    margin-bottom: 10px;
  }
`

export const StatusLabel = styled.div`
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  border-radius: 10px;
  background-color: ${props => props.status == 'Resolved' ? '#8247BA' : props.status == 'Partial' ? '#1D86B4' : '#2A2233'};
  text-align: center;
  width: 80%;
  padding: 4px 3px 2px 3px;
`

export const Link = styled(Globals.Link)`
  z-index: 99;
  position: relative;
`
