import React, { Fragment } from 'react'
import { Grid } from 'react-foundation'
import {
  Team, Content, Department, Heading, Subheading, VerticalSpacer
} from './TeamSection.style';
import EmployeeList from '../EmployeeList/EmployeeList';

function TeamSection(props) {
  return (
    <Fragment>
      <Team>
        <Content>
          <Department>
            <Heading>
              Our Team
            </Heading>
            <Subheading>
              Our unique blend of veteran litigators, experienced finance professionals, and versatile, creative operators has helped us become one of the most active litigation finance firms in the world.
            </Subheading>
            <VerticalSpacer />
            <Grid className="grid-margin-x grid-margin-y grid-padding-y align-center">
              { EmployeeList({employees: props.employees}) }
            </Grid>
          </Department>
        </Content>
      </Team>
    </Fragment>
  )
}

export default TeamSection;
