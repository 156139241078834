import * as yup from "yup";
import InputField from "../../Form/InputField";
import InputSelect from "../../Form/InputSelect";
import InputButton from "../../Form/InputButton";
import { isEmpty } from "lodash";
import { Formik } from "formik";
import React, { useRef } from "react";
import { Grid, Cell } from "react-foundation";
import axios from "axios";

const fundingSoughtOptions = [
  "Less than $200,000",
  "Between $200,000 and $1 million",
  "Between $1 million and $5 million",
  "More than $5 million",
];

const fundingRecipientOptions = ["Business", "Attorney", "Individual"];

const schema = yup.object().shape({
  name: yup.string().required().label("Name"),
  email: yup.string().email().required().label("Email"),
  funding_needed: yup.string().required().label("Funding Sought"),
  funding_recipient: yup.string().required().label("Funding Recipient"),
  referral_method: yup.string().required().label("How did you hear about us"),
  more_details: yup.string().required().label("More Details"),
});

function Form({ csrfToken }) {
  const formRef = useRef(null);
  const defaultValues = {
    name: "",
    email: "",
    funding_recipient: "",
    funding_needed: "",
    more_details: "",
    referral_method: "",
    nature_of_dispute: "",
  };

  const handleSubmit = ({
    email,
    name,
    more_details,
    funding_recipient,
    funding_needed,
    referral_method,
    nature_of_dispute,
  }) => {
    const payload = {
      raise_funds: {
        email,
        name,
        more_details,
        funding_recipient,
        funding_needed,
        referral_method,
        nature_of_dispute,
      },
      authenticity_token: csrfToken,
    };

    axios
      .post(`/raise-funds/signup`, payload)
      .then((res) => {
        window.location = res.request.responseURL;
      })
      .catch((e) => {
        const shared_ui = new LexShares.SharedUI();
        shared_ui.showAlert("error", e.response.data.error, false);
      })
      .then(() => {
        formRef.current.setSubmitting(false);
      });
  };

  const fundingSoughtSelectOptions = ["", ...fundingSoughtOptions].map(
    (label) => {
      return {
        label,
        value: label,
      };
    }
  );

  const fundingRecipientSelectOptions = ["", ...fundingRecipientOptions].map(
    (label) => {
      return {
        label,
        value: label,
      };
    }
  );

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e)}
      validationSchema={schema}
      innerRef={formRef}
    >
      {({
        errors,
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        dirty,
        handleBlur,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit} className="mt-5 raise-capital-form">
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <InputField
                type="text"
                label="Name*"
                name="name"
                onBlur={handleBlur}
                changeHandler={handleChange}
                error={touched.name && errors.name}
                value={values.name}
              />
            </Cell>
            <Cell small={12}>
              <InputField
                type="email"
                label="Email address*"
                name="email"
                onBlur={handleBlur}
                changeHandler={handleChange}
                error={touched.email && errors.email}
                value={values.email}
              />
            </Cell>
            <Cell medium={6} className="js-funding-recipient-cell">
              <InputSelect
                label="Funding recipient*"
                name="funding_recipient"
                options={fundingRecipientSelectOptions}
                error={touched.funding_recipient && errors.funding_recipient}
                value={fundingRecipientSelectOptions.find(
                  (option) => option.value === values.funding_recipient
                )}
                changeHandler={(option) =>
                  setFieldValue("funding_recipient", option.value)
                }
              />
            </Cell>
            <Cell small={12} className="js-funding-needed-cell">
              <InputSelect
                label="How much capital are you seeking from LexShares?*"
                name="funding_needed"
                options={fundingSoughtSelectOptions}
                error={touched.funding_needed && errors.funding_needed}
                value={fundingSoughtSelectOptions.find(
                  (option) => option.value === values.funding_needed
                )}
                changeHandler={(option) =>
                  setFieldValue("funding_needed", option.value)
                }
              />
            </Cell>
            <Cell small={12}>
              <InputField
                type="textarea"
                label="Briefly describe the nature of the dispute:*"
                id="more_details"
                onBlur={() => setFieldTouched("more_details")}
                error={touched.more_details && errors.more_details}
                changeHandler={handleChange}
                name="more_details"
              />
            </Cell>
            <Cell small={12}>
              <InputField
                type="textarea"
                label="How did you hear about us?*"
                id="referral_method"
                onBlur={() => setFieldTouched("referral_method")}
                error={touched.referral_method && errors.referral_method}
                changeHandler={handleChange}
                name="referral_method"
              />
            </Cell>
          </Grid>
          <Grid className="grid-margin-y">
            <InputButton
              type="submit"
              value={isSubmitting ? "Submitting.." : "Submit"}
              name="submit-button"
              disabled={!dirty || isSubmitting || !isEmpty(errors)}
              disabledColor="#D4D4D4"
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default Form;
