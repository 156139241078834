import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  margin: 30px 0px 26px 0px;

  p {
    ${Globals.proximaFontStack};
    font-size: 16px;
    line-height: 27.2px;

    ${breakpoint('medium')`
      font-size: 18px;
    `}
    ${breakpoint('large')`
      font-size: 21px;
      line-height: 36px;
    `}
  }

  a {
    color: ${theme.colors.mediumPurple};
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.mediumPurple};
    }
  }

  ul {
    line-height: 1.6;
  }

  li {
    font-weight: 400;
    ${Globals.proximaFontStack}
    font-size: 16px;

    ${breakpoint('large')`
      font-size: 21px;
    `}
  }

  h1 {
    ${Globals.tiempoFontStack};
    font-size: 24px;
    line-height: 32px;
    ${breakpoint('medium')`
      font-size: 38px;
      line-height: 42px;
    `}
    ${breakpoint('large')`
      font-size: 54px;
      line-height: 64px;
    `}
  }

  h2 {
    ${Globals.tiempoFontStack}
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;

    ${breakpoint('medium')`
      font-size: 28px;
      line-height: 43px;
    `}

    ${breakpoint('large')`
      font-size: 36px;
      line-height: 44px;
    `}
  }

  h3 {
    ${Globals.tiempoFontStack};

    font-size: 21px;
    line-height: 25px;

    ${breakpoint('medium')`
      font-size: 28px;
      line-height: 35px;
    `}
    ${breakpoint('large')`
      font-size: 32px;
      line-height: 40px;
    `}
  }

  h4 {
    ${Globals.proximaFontStack};
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;

    ${breakpoint('medium')`
      font-size: 32px;
      line-height: 120%;
    `}
  }

  h5 {
    ${Globals.proximaFontStack};
    font-size: 28px;
    line-height: 120%;
  }

  h6 {
    ${Globals.proximaFontStack};
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;

    ${breakpoint('medium')`
      font-size: 24px;
      line-height: 24px;
    `}
  }
`