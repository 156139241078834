import React from 'react'
import { Cell, Grid } from 'react-foundation'
import validate from '../../../components/Form/Validate'
import ProgressBar from '../../../components/ProgressBar'
import InvestmentAmount from './InvestmentAmount'
import InvestmentAccreditation from './InvestmentAccreditation'
import InvestmentRepresentations from './InvestmentRepresentations'
import InvestmentSuitability from './InvestmentSuitability'
import InvestmentCommitment from './InvestmentCommitment'
import InvestmentReducedOffer from './InvestmentReducedOffer'
import axios from 'axios'
import Mixpanel from '../../Mixpanel'

import {
  Container,
  Content,
  ButtonContainer,
  BackButton,
  ContinueButton
} from './InvestmentFunnel.style'

const dashCaseToCamel =
  str => str.replace(/-[a-z]/g, letter => `${letter.toUpperCase().replace('-', '')}`)

const currencyToNumber =
  str => parseInt(str.replace(/[,$]/g, ''))

class InvestmentFunnel extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInvestMethodChange = this.handleInvestMethodChange.bind(this)
    this.handleAccreditationMethodChange = this.handleAccreditationMethodChange.bind(this)
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this)
    this.handleRepresentationChange = this.handleRepresentationChange.bind(this)
    this.handleNewBankChange = this.handleNewBankChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleDocuSign = this.handleDocuSign.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleForward = this.handleForward.bind(this)
    this.hideReducedOffer = this.hideReducedOffer.bind(this)
    this.commitReducedHandler = this.commitReducedHandler.bind(this)
    this.getPercent = this.getPercent.bind(this)
    this.trackProgress = this.trackProgress.bind(this)
    this.state = {
      currentStep: 1,
      currentPercent: 20,
      totalSteps: 5,
      submitted: false,
      submissionError: null,
      amount: null,
      investmentMethod: props.investmentMethods[0],
      paymentMethod: 'wire',
      newBankAccount: false,
      accountNumber: null,
      verifyAccountNumber: null,
      routingNumber: null,
      accountType: null,
      investmentMethods: props.investmentMethods,
      currentAch: null,
      currentDomestic: true,
      ssn: null,
      ein: null,
      accreditationMethod: null,
      representationCount: 0,
      representation1: false,
      representation2: false,
      representation3: false,
      suitabilityObjectives: props.suitabilityObjectives,
      suitabilityExperience: props.suitabilityExperience,
      suitabilityRisk: props.suitabilityRisk,
      suitabilityAssets: props.suitabilityAssets,
      suitabilitySecurities: props.suitabilitySecurities,
      suitabilityTax: props.suitabilityTax,
      assetAttestation: false,
      docuSignEnvelopeId: null,
      docuSigned: false,
      invested: false,
      reducedOfferPop: false,
      validationErrors: {
        amount: null,
        investmentMethod: null,
        accountNumber: null,
        verifyAccountNumber: null,
        routingNumber: null,
        accountType: null,
        ssn: null,
        ein: null,
        accreditationMethod: null,
        representationCount: 0,
        suitabilityObjectives: null,
        suitabilityExperience: null,
        suitabilityRisk: null,
        suitabilityAssets: null,
        suitabilitySecurities: null,
        suitabilityTax: null,
        assetAttestation: false
      }
    }
  }

  trackProgress() {
    const suitabilityObjectives = this.state.suitabilityObjectives ?
      this.state.suitabilityObjectives.label : null
    const suitabilityExperience = this.state.suitabilityExperience ?
      this.state.suitabilityExperience.label : null
    const suitabilityRisk = this.state.suitabilityRisk ?
      this.state.suitabilityRisk.label : null
    const suitabilitySecurities = this.state.suitabilitySecurities ?
      this.state.suitabilitySecurities.label : null
    const suitabilityTax = this.state.suitabilityTax ?
      this.state.suitabilityTax.label : null

    const stepToText = {
      1: "User entered investment amount",
      2: "User entered accreditation",
      3: "User entered representations",
      4: "User entered suitability",
      5: "Investment complete"
    }

    const funnelData = {
      stepCompleted: this.state.currentStep,
      amount: this.state.amount,
      investmentMethod: this.state.investmentMethod.value,
      paymentMethod: this.state.paymentMethod,
      accreditationMethod: this.state.accreditationMethod,
      representationsCount: this.state.representationCount,
      suitabilityObjectives: suitabilityObjectives,
      suitabilityExperience: suitabilityExperience,
      suitabilityRisk: suitabilityRisk,
      suitabilityAssets: this.state.suitabilityAssets,
      suitabilitySecurities: suitabilitySecurities,
      suitabilityTax: suitabilityTax,
      assetAttestation: this.state.assetAttestation,
      docuSigned: this.state.docuSigned ? 'yes' : 'no',
      invested: this.state.invested ? 'yes' : 'no',
    }

    let allTrackedData = {...this.props.trackedData, ...funnelData}

    Mixpanel.track(stepToText[this.state.currentStep || 5], allTrackedData)
  }

  handleInputChange(event) {
    let name = dashCaseToCamel(event.target.name)
    this.setState({
      [name]: 
        event.target.type == 'checkbox' ? event.target.checked : event.target.value
    })
  }

  handlePaymentMethodChange(method) {
    let newState = {
      paymentMethod: method,
      newBankAccount: method == 'wire' ? false : this.state.newBankAccount,
      investmentMethod: method == 'wire' ? this.state.investmentMethod : null
    }
    this.setState(newState)
  }

  handleInvestMethodChange(selection) {
    this.setState({
      investmentMethod: selection,
      currentDomestic: selection ? selection.domestic : null,
      currentAch: selection ? selection.ach : null,
      newBankAccount: selection && selection.ach ? false : true,
      paymentMethod: (selection && selection.value == 'retirement_account') ? 
        'wire' : this.state.paymentMethod
    })
  }

  handleSelectChange(selection, propName) {
    let selectState = {}
    if ((!Array.isArray(selection) && selection) || (Array.isArray(selection) && selection.length > 0)) {
      let name = Array.isArray(selection) ? selection[0].name : selection.name
      selectState[name] = Array.isArray(selection) ? selection : selection
      this.setState(selectState)
    }
    else if (propName) {
      selectState[propName] = null
      this.setState(selectState)
    }
  }

  handleAccreditationMethodChange(method) {
    this.setState({accreditationMethod: method})
  }

  handleRepresentationChange(event) {
    let index = event.target.getAttribute('data-index')
    let indexName = `representation${index}`
    let change = event.target.checked ? 1 : -1
    let newCount = this.state.representationCount + change
    let newState = {representationCount: newCount}
    newState[indexName] = event.target.checked
    this.setState(newState)
  }

  handleNewBankChange(event) {
    this.setState({newBankAccount: event.target.checked})
  }

  handleDocuSign(envelopeId) {
    this.setState(
      {
        docuSigned: true,
        docuSignEnvelopeId: envelopeId
      }, 
      () => {
        this.props.pendingHandler()
        this.handleSubmit()
      }
    )
  }

  hideReducedOffer() {
    this.setState({reducedOfferPop: false})
  }

  commitReducedHandler() {
    this.setState({
      amount: this.props.maximumInvestmentFormatted,
      reducedOfferPop: false
    })
    this.props.disableNotificationsHandler()
  }

  handleSubmit() {
    const payload = {
      investment_form: {
        amount: this.state.amount.replace(/[,$]/g,''),
        account_type: this.state.accountType,
        payment_method: this.state.paymentMethod,
        account_number: this.state.accountNumber,
        account_number_confirmation: this.state.verifyAccountNumber,
        routing_number: this.state.routingNumber,
        investment_method: this.state.investmentMethod.value,
        ssn: this.state.ssn,
        ein: this.state.ein,
        use_saved_bank_account: !this.state.newBankAccount,
        docusign_envelope_id: this.state.docuSignEnvelopeId,
        accreditations: this.state.accreditationMethod
      },
      investor: {
        risk_tolerance: this.state.suitabilityRisk.value,
        private_securities_percentage: this.state.suitabilitySecurities.value,
        investing_experience: this.state.suitabilityExperience.value,
        investing_objectives: this.state.suitabilityObjectives.value,
        asset_attestation: this.state.assetAttestation,
        other_investments: this.state.suitabilityAssets.map(s => s.value),
        tax_rate: this.state.suitabilityTax.value
      },
      authenticity_token: this.props.csrfToken
    }
    const postUrl = `/cases/${this.props.caseId}`
    this.props.disableNotificationsHandler()
    axios({
      headers: {'Accept': 'application/json'},
      method: 'put',
      responseType: 'json',
      url: postUrl,
      data: payload
    })
     .then(() => {
       this.setState({invested: true, currentStep: null})
       this.props.closeHandler()
       this.props.confirmationHandler()
       this.trackProgress()
     }).catch((e) => {
       this.setState({submissionError: e.response.data.error})
       const sharedUi = new LexShares.SharedUI()
       sharedUi.showAlert('error', e.response.data.error)
     })
  }

  handleForward() {
    let errors = validate(this.state)
    this.setState({validationErrors: errors.messages})
    if (errors.count > 0) {
      return false
    }
    if (this.state.currentStep < this.state.totalSteps) {
      this.trackProgress()
      let nextStep = this.state.currentStep + 1
      let percent = this.getPercent(nextStep)
      this.setState({currentStep: nextStep, currentPercent: percent})
      window.scrollTo(0, 0)
    }
  }

  handleBack() {
    if (this.state.currentStep > 1) {
      let nextStep = this.state.currentStep - 1
      let percent = this.getPercent(nextStep)
      this.setState({currentStep: nextStep, currentPercent: percent})
      window.scrollTo(0, 0)
    }
    else {
      this.props.closeHandler()
    }
  }

  getPercent(step) {
    let percent = {
      1: 20,
      2: 40,
      3: 60,
      4: 80,
      5: 100
    }[step]
    return percent
  }

  componentDidUpdate() {
    if ((this.state.amount != null) &&
        (currencyToNumber(this.state.amount) > this.props.maximumInvestment) &&
        (this.state.currentStep > 1) &&
        !this.state.reducedOfferPop)
    {
      this.setState({reducedOfferPop: true})
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Container className="full-screen-white-modal">
        { this.state.reducedOfferPop &&
          <InvestmentReducedOffer
            amountRemaining={this.props.maximumInvestment}
            popVisible={this.state.reducedOfferPop}
            hideHandler={this.hideReducedOffer}
            cancelHandler={this.props.closeHandler}
            paymentMethod={this.state.paymentMethod}
            investmentMethod={this.state.investmentMethod.value}
            caseId={this.props.caseId}
            commitReducedHandler={this.commitReducedHandler}
            csrfToken={this.props.csrfToken}
          />
        }
        <Content>
          <ProgressBar
            step={this.state.currentStep} 
            steps={this.state.totalSteps}
            percent={this.state.currentPercent}
          />
          <form noValidate onSubmit={this.handleSubmit}>
            { this.state.currentStep == 1 &&
              <InvestmentAmount
                amount={this.state.amount}
                investmentMethod={this.state.investmentMethod}
                inputHandler={this.handleInputChange}
                paymentMethodHandler={this.handlePaymentMethodChange}
                bankChangeHandler={this.handleNewBankChange}
                error={this.state.validationErrors}
                minimum={this.props.minimumInvestment}
                maximum={this.props.maximumInvestment}
                newBankAccount={this.state.newBankAccount}
                accountNumber={this.state.accountNumber}
                verifyAccountNumber={this.state.verifyAccountNumber}
                routingNumber={this.state.routingNumber}
                accountType={this.state.accountType}
                paymentMethod={this.state.paymentMethod}
                investmentMethods={this.state.investmentMethods}
                investmentMethodHandler={this.handleInvestMethodChange}
                accountTypeHandler={this.handleSelectChange}
                currentDomestic={this.state.currentDomestic}
                currentAch={this.state.currentAch}
              />
            }
            { this.state.currentStep == 2 &&
              <InvestmentAccreditation
                investmentMethod={this.state.investmentMethod}
                ssn={this.state.ssn}
                ein={this.state.ein}
                accreditationMethod={this.state.accreditationMethod}
                inputHandler={this.handleInputChange}
                accreditationMethodHandler={this.handleAccreditationMethodChange}
                error={this.state.validationErrors}
              />
            }
            { this.state.currentStep == 3 &&
              <InvestmentRepresentations
                representationHandler={this.handleRepresentationChange}
                representationOneCheck={this.state.representation1}
                representationTwoCheck={this.state.representation2}
                representationThreeCheck={this.state.representation3}
                error={this.state.validationErrors}
              />
            }
            { this.state.currentStep == 4 &&
              <InvestmentSuitability
                suitabilityObjectives={this.state.suitabilityObjectives}
                suitabilityExperience={this.state.suitabilityExperience}
                suitabilityRisk={this.state.suitabilityRisk}
                suitabilityAssets={this.state.suitabilityAssets}
                suitabilitySecurities={this.state.suitabilitySecurities}
                suitabilityTax={this.state.suitabilityTax}
                suitabilityHandler={this.handleSelectChange}
                attestHandler={this.handleInputChange}
                assetAttestation={this.state.assetAttestation}
                error={this.state.validationErrors}
              />
            }
            { this.state.currentStep == 5 &&
              <InvestmentCommitment 
                investmentAmount={this.state.amount}
                investmentMethod={this.state.investmentMethod}
                accreditationMethod={this.state.accreditationMethod}
                caseId={this.props.caseId}
                investorId={this.props.investorId}
                signedHandler={this.handleDocuSign}
                signed={this.state.docuSigned}
                trackedData={this.props.trackedData}
              />
            }
            { (this.state.currentStep < 5 || this.state.docuSignLoaded) &&
              <ButtonContainer>
                <Grid>
                  <Cell small={12} medium={6} className="small-order-2 medium-order-1">
                    <BackButton
                      onClick={this.handleBack}
                      className="js-back-link"
                    >
                      Back
                    </BackButton>
                  </Cell>
                  <Cell small={12} medium={6} className="small-order-1 medium-order-2">
                    <ContinueButton
                      onClick={this.handleForward} 
                      className="js-continue-link"
                    >
                      Continue
                    </ContinueButton>
                  </Cell>
                </Grid>
              </ButtonContainer>
            }
          </form>
        </Content>
      </Container>
    )
  }
}

export default InvestmentFunnel
