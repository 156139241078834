import React from 'react';
import { Cell, Grid } from 'react-foundation';

import { AuthorBio, AuthorImage, AuthorLink, AuthorName } from './Sidebar.style';
import PublishSection from '../PublishSection/PublishSection';
import Mixpanel from '../../Mixpanel'

const EMPLOYEES_MAPPING = {
  'max': 'max-volsky',
  'kenneth': 'kenneth-harmon',
  'matt': 'matt-reason',
  'max-schmidt': 'max-schmidt',
  'alex': 'alex-wieck',
  'wali': 'wali-rahman',
}

const Sidebar = ({ article }) => {
  const tag = article.tags.find(t => ['funding-insights', 'regulations-trends'].includes(t.slug));

  const trackClick = () => {
    const publishDate = new Date(article.published_at)

    Mixpanel.track('Clicked author bio link', {
      resourceTitle: article.title,
      publishDate: publishDate.toISOString(),
      authorName: article.authors ? article.authors.map(author => author.name).join(', ') : '',
      resourceType: `${tag.name} — Article`,
    })
  }

  return (
    <React.Fragment>
      <Grid className="grid-margin-x grid-margin-y grid-padding-y align-left">
        {article.authors.map(author =>
          <React.Fragment key={author.slug}>
            <Cell small={6} medium={12} large={12}>
              <AuthorImage src={author.profile_image} />
              {EMPLOYEES_MAPPING[author.slug] ? <AuthorLink href={`/about/${EMPLOYEES_MAPPING[author.slug]}`} target="_blank" onClick={trackClick}>{author.name} →</AuthorLink> : <AuthorName>{author.name}</AuthorName>}
              <AuthorBio>{author.bio}</AuthorBio>
            </Cell>
          </React.Fragment>
        )}
        <Cell small={6} medium={12} large={12}>
          <PublishSection html={article.html} />
        </Cell>
      </Grid>
    </React.Fragment>
  )
}

export default Sidebar;