import React from 'react'
import { Transition } from 'react-transition-group'
import { Slide } from './Slidedown.style'

const duration = 300

  const defaultStyle = {}

  const transitionStyles = {
    entering: { transform: "translateY(-100%)" },
    entered:  { transform: "translateY(0%)" },
    exiting:  { transform: "translateY(0%)" },
    exited:  { transform: "translateY(-100%)" }
  }

function SlideDown({show, children}) {
  return (
    <Transition in={show} timeout={duration}>
      {state => (
        <Slide style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
          { children }
        </Slide>
      )}
    </Transition>
  )
}

export default SlideDown
