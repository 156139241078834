import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.Widget)`
  background-color: ${props => props.active ? '#190E26' : '#1E1924'};
  min-height: 324px;
  border: ${props => props.active ? '2px solid #8247BA' : '0px'};
  padding: 0;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const TitleBar = styled(Globals.WidgetTitleBar)`
`

export const Logo = styled(Globals.WidgetLogo)`
`

export const Title = styled.div`
  display: block;
  font-size: 16px;
  line-height: 17px;
  color: white;
  margin-left: 30px;
  ${breakpoint('medium')`
    line-height: 25px;
    display: inline-block;
    margin-left: 0px;
  `}
`

export const DateStamp = styled(Globals.WidgetDateStamp)`
  margin-top: 3px;
`

export const Container = styled.div`
  padding: 21px 31px 40px 31px
`

export const InfoContainer = styled.div`
  margin-top: 35px;
  ${breakpoint('medium')`
    margin-top: 0px;
    padding-bottom: 20px;
  `}
`

export const DealStatus = styled.div`
  color: #A01FE5;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -20px;
`

export const DealInvested = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: white;
  ${breakpoint('medium')`
    font-size: 25px;
  `}
  ${breakpoint('large')`
    font-size: 32px;
  `}
`

export const DealInfo = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.purpleWhite};
  ${breakpoint('medium')`
    font-size: 12px;
  `}
  ${breakpoint('large')`
    font-size: 16px;
  `}
`

export const ProgressContainer = styled.div`
  max-height: 180px;
  max-width: 180px;
  margin: 0 auto;
`


export const CaseType = styled.span`
  ${Globals.proximaFontStack};
  text-transform: uppercase;
  color: ${props => props.theme.colors.purple};
  font-weight: bold;
  font-size: 13px;
  letter-spacing: .5px;
`

export const Parties = styled.h3`
  ${Globals.tiempoFontStack};
  color: white;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin: 15px 0;
`

export const Description = styled.p`
  color: ${props => props.theme.colors.purpleWhite};
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 8px;
  -webkit-font-smoothing: antialiased;
`

export const Button = styled(Globals.ButtonLink)`
  padding: 12px;
  margin-top: 10px;
  background-color: ${props => props.pending ? props.theme.colors.darkPurple : props.theme.colors.purple}
`
