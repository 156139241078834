import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  margin: 5px 0px 0px 0px;
  
`;

export const Breadcrumb = styled.div`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  margin-top: 7px;
  font-weight: bold;
  text-align: center;

  ${breakpoint('medium')`
    text-align: left;
  `}
`;

export const BreadcrumbItemLink = styled.a`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  font-weight: bold;

  &:hover {
    color: ${theme.colors.purpleGrey};
  }
`;

export const BreadcrumbItem = styled.span`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  font-weight: bold;
`;

export const Header = styled(Globals.H1)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  color: ${theme.colors.backdropPurple};
  margin-top: 7px;
  line-height: 29px;
  text-align: center;

  ${breakpoint('medium')`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
    margin-top: 31px;
  `}
`;

export const Subheading = styled(Globals.H6)`
  ${Globals.proximaFontStack}
  margin-top: 14px;
  line-height: 22px !important;
  font-weight: normal;
  color: ${theme.colors.backdropPurple};
  text-align: center;
  font-size: 17px;

  ${breakpoint('medium')`
    text-align: left;
    margin-top: 16px;
    line-height: 36px !important;
  `}
`;

export const Text = styled.p`
  ${Globals.proximaFontStack}
  color: ${theme.colors.purpleGrey};
  margin-top: 15px;
`;

export const PressLogo = styled.img`
  margin-left: 3px;
  height: 18px;
`;

export const PressUrl = styled.a`
`;