import React, { useRef } from 'react';
import axios from 'axios';
import { Formik } from "formik";
import { Grid, Cell } from "react-foundation";
import { isEmpty } from "lodash";
import * as yup from "yup";
import InputField from "../../Form/InputField";
import InputSelect from "../../Form/InputSelect";
import { Container, Form, InputSubmit, EmailDisclaimer } from './DownloadGuideForm.style';
import Mixpanel from '../../Mixpanel'

const roleTypes = [
  "Commercial litigator",
  "Mass tort litigator",
  "Corporate counsel",
  "Business owner, founder, or executive",
  "Individual plaintiff",
  "Investor",
  "Litigation funder or broker",
  "Student or educator",
];

const DownloadGuideForm = ({csrfToken, whitepaper}) => {
  const formRef = useRef(null);
  
  const schema = yup.object().shape({
    name: yup.string().required().label("Name"),
    email: yup.string().email().required().label("Business Email"),
    company: yup.string().required().label("Company Name"),
    role_type: yup.string().required().label("Job Title"),
  });

  const defaultValues = {
    name: "",
    email: "",
    company: "",
    role_type: "",
  };

  const roleTypeOptions = roleTypes.map((label) => {
    return {
      label,
      value: label,
    };
  });

  const handleSubmit = (data) => {

    const payload = {
      slug: whitepaper.slug,
      whitepaper: data,
      authenticity_token: csrfToken
    }

    axios
      .post("/whitepapers", payload)
      .then(() => {
        formRef.current.resetForm();
        window.location = '/whitepapers?whitepaper_confirmation=true';
      })
      .catch((e) => {
        const shared_ui = new LexShares.SharedUI();
        shared_ui.showAlert("error", e.response.data.error, false);
      })
      .then(() => {
        formRef.current.setSubmitting(false);
      });
      Mixpanel.track('User requested whitepaper', {
      whitepaperTitle: whitepaper.title
    });
  };

  return(
    <Container>
        <Formik
          initialValues={defaultValues}
          onSubmit={(e) => handleSubmit(e)}
          validationSchema={schema}
          innerRef={formRef}
        >
          {({
            errors,
            handleSubmit,
            handleChange,
            isSubmitting,
            values,
            dirty,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid className="grid-margin-x">
                  <Cell small={4}>
                  <InputField
                    type="text"
                    label="Name*"
                    name="name"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={touched.name && errors.name}
                    value={values.name}
                  />
                  </Cell>
                  <Cell small={4}>
                  <InputField
                    type="email"
                    label="Business Email*"
                    name="email"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={touched.email && errors.email}
                    value={values.email}
                  />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                <Cell small={4}>
                  <InputField
                    type="text"
                    label="Company Name*"
                    name="company"
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    error={touched.company && errors.company}
                    value={values.company}
                  />
                </Cell>
                <Cell small={4}>
                  <InputSelect
                    label="Which best describes you?*"
                    name="role_type"
                    options={roleTypeOptions}
                    error={touched.role_type && errors.role_type}
                    value={roleTypeOptions.find(
                      (option) => option.value === values.role_type
                    )}
                    changeHandler={(option) =>
                      setFieldValue("role_type", option.value)
                    }
                  />
                </Cell>
              </Grid>
              <Grid className="grid-margin-y">
                <Cell small={12} centerOnSmall>
                  <InputSubmit 
                    type="submit"
                    disabled={!dirty || isSubmitting || !isEmpty(errors)}
                    value={isSubmitting ? "Requesting.." : "Request Your Copy"} 
                  />
                </Cell>
                <EmailDisclaimer>We will never sell, share or rent your email.</EmailDisclaimer>
              </Grid>
            </Form>
          )}
        </Formik>
    </Container>
  )
}

export default DownloadGuideForm;