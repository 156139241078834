import styled from "styled-components";
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';
import theme from "../../../config/theme";

export const Container = styled.div`
  padding: 25px;

  ${breakpoint('medium')`
    padding: 87px 171px 110px 110px;
  `}
`;

export const Heading = styled(Globals.H1)`
  color: ${theme.colors.white} !important;
  text-align: center;
  margin-bottom: 50px;

  ${breakpoint('medium')`
    margin-bottom: 108px;
  `}
`;

export const BenefitHeading = styled(Globals.H2)`
  color: ${theme.colors.white} !important;
  margin: 20px 0px;
  font-size: 20px !important;

  ${breakpoint('medium')`
    font-size: 28px !important;
  `}
`;

export const BenefitDescription = styled(Globals.Description)`
  color: ${theme.colors.white} !important;
  margin-bottom: 30px;

  ${breakpoint('medium')`
    margin-bottom: 0px;
  `}
`;

export const RequestAccessSection = styled.div`
  ${breakpoint('medium')`
    width: 100%;
    display: flex;
  `}
`;

export const CaseContainer = styled.div`
  width: 100%;
  padding: 20px 25px;
  height: 100%;

  ${breakpoint('medium')`
    display: inline-block;
    width: 50%;
    padding: 150px 65px;
  `}

  ${breakpoint('large')`
    padding: 300px 133px 346px 128px;
  `}
`;

export const RequestAccessContainer = styled.div`
  width: 100%;
  padding: 100px 25px;
  background: ${theme.colors.nightPurple2};

  ${breakpoint('medium')`
    display: inline-block;
    width: 50%;
    padding: 150px 100px 0px 50px;
  `}

  ${breakpoint('large')`
    padding: 353px 232px 0px 101px;
  `}
`;

export const CaseType = styled(Globals.H6)`
  font-size: 13px !important;
  line-height: 13px !important;
  color: ${theme.colors.mediumPurple};
  margin-bottom: 30px;
`;

export const CaseTitle = styled(Globals.H2)`
  color: ${theme.colors.white} !important;
  margin-bottom: 9px;
`;

export const CaseDescription = styled(Globals.Description)`
  color: ${theme.colors.purpleWhite} !important;
  margin-bottom: 30px;
`;

export const FundingType = styled.p`
  ${Globals.proximaFontStack}
  font-size: 12px;
  line-height: 20px;
  color: ${theme.colors.white};
  opacity: 0.75;
  margin-bottom: 3px;
`;

export const FundingAmount = styled.p`
  ${Globals.proximaFontStack}
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.white};
  margin-bottom: 3px;
`;

export const MinimumContainer = styled.p`
  ${Globals.proximaFontStack}
  color: ${theme.colors.purpleWhite};
  font-size: 14px;
`;

export const MinimumAmount = styled.span`
  font-weight: bold;
  color: ${theme.colors.white} !important;
  margin-right: 4px;
  font-size: 12px;
`;

export const Light = styled.span`
  opacity: 0.75;
  margin-right: 6px;
`;

export const RequestAccessTitle = styled.p`
  ${Globals.proximaFontStack}
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: ${theme.colors.white};
  margin-bottom: 10px;
`;

export const RequestAccessDescription = styled(Globals.Description)`
  color: ${theme.colors.purpleWhite} !important;
  margin-bottom: 27px;
`;

export const Button = styled(Globals.ButtonLink)`
  ${Globals.proximaFontStack};
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  background: ${theme.colors.mediumPurple};
  border-radius: 2px;
  height: 50px;
`

export const ImageContainer = styled.div`
  width: ${props => props.width-150}px;
  margin: auto;

  ${breakpoint('medium')`
    width: ${props => props.width}px;
  `}
`;