import React, { Fragment } from 'react';

import ArticleList from '../ArticleList';
import { Articles, Content } from './ArticlesSection.style';

function ArticlesSection(props) {
  return (
    <Fragment>
      <Articles>
        <Content>
          { ArticleList({articles: props.articles}) }
        </Content>
      </Articles>
    </Fragment>
  )
}

export default ArticlesSection;
