import styled from 'styled-components';
import * as Globals from '../../Globals';
import breakpoint from 'styled-components-breakpoint';

export const Section = styled.div`
  background: white;
`;

export const Content = styled.div`
  max-width: 1330px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)`
  margin-bottom: 20px;
  text-align: center !important;
  margin-bottom: 50px;
`;
