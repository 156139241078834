import styled from "styled-components";
import * as rf from "react-foundation";
import logo from "../../../assets/images/logos/lexshares.png";
import logoBlack from "../../../assets/images/logos/lexshares-black.png";
import breakpoint from "styled-components-breakpoint";
import * as Globals from "../Globals";

const theme = {
  trueWhite: {
    background: "#FFFFFF",
    color: "black",
    border: "1px solid black",
    logo: logoBlack,
  },
  white: {
    background: "#f8f7fa",
    color: "black",
    border: "1px solid black",
    logo: logoBlack,
  },
  transparent: {
    background: "unset",
    color: "white",
    border: "1px solid white",
    logo: logo,
  },
  transparentBlack: {
    background: "unset",
    color: "black",
    border: "1px solid white",
    logo: logoBlack,
  },
  scrolled: {
    background: "#131017",
    color: "white",
    border: "1px solid white",
    logo: logo,
  },
};

export const TopBarLogo = styled(rf.TopBarLeft)`
  flex: 1 1 auto !important;

  ${breakpoint("large")`
    flex: 0 1 auto !important;
  `}
`;

export const CloseButton = styled.a`
  font-size: 53px;
  font-weight: 300;
  color: white;
  &:hover {
    color: white;
  }
`;

export const MenuButton = styled.a`
  img {
    width: 20px;
  }
`;

export const TopBarLogoRight = styled(rf.TopBarRight)`
  flex: 0 1 auto !important;
  padding-right: 30px;

  ${breakpoint("large")`
    padding-right: 15px;
  `}
`;

export const TopBarLeft = styled(rf.TopBarLeft)`
  overflow: scroll;

  ${breakpoint("large")`
    height: unset;
    overflow: unset;
  `}
`;

export const TopBarRight = styled(rf.TopBarRight)`
  margin-right: 20px;
  margin-left: 20px !important;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #282331;

  ${breakpoint("large")`
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 0 !important;
    border-top: 0;
  `}
`;

export const MenuLink = styled.a`
  display: block;

  background: #fefefe;
  position: relative;
  color: white !important;
  padding: 0.7rem 0 0.7rem 0 !important;
  background: transparent !important;
  font-size: 27px;
  font-weight: 300;
  ${Globals.tiempoFontStack};

  ${breakpoint("medium")`
    font-size: 32px;
  `}

  @media all and (max-width: 1060px) {
    padding: 0.7rem 1.2rem 0.7rem 0;
    &::after {
      right: 6px;
    }
  }

  img {
    float: right;
    width: 17px;
    line-height: 1;
    vertical-align: middle;
    margin-top: 10px;
    transform: rotate(180deg);
  }

  &::after {
    display: none !important;
  }

  ${breakpoint("large")`
    margin-bottom: 0px !important;
    ${Globals.proximaFontStack};
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    -webkit-font-smoothing: auto;
    padding: 0.7rem 1.5rem 0.7rem 0 !important;
    img {
      display: none;
    }
  `}

  ${breakpoint("large")`
    color: ${(props) => theme[props.type]["color"]} !important;
  `}

  ${breakpoint("large")`
    &::after {
      display: block !important;
      top: 19px;
      border-color: ${(props) =>
        theme[props.type]["color"]} transparent transparent !important;
      border-width: 4px !important;
      right: 10px !important;
    }
  `}
`;

export const MenuLinkRight = styled.a`
  visibility: visible;
  list-style: none;
  text-transform: uppercase;
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
  line-height: 1;
  text-decoration: none;
  display: block;
  margin-bottom: 0;
  background: #fefefe;
  color: ${(props) => theme[props.type]["color"]} !important;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  -webkit-font-smoothing: auto;
  padding: 0.7rem 1.1rem !important;
  background-color: unset !important;
`;

export const SubMenuLink = styled.a`
  padding: 0px 100px 16px 16px !important;
`;

export const MenuContainer = styled.div`
  position: fixed;
  z-index: 9999;
  margin-top: 37px;
  padding-top: 0;
  width: 100%;
  left: 50%;
  top: 0;
  margin-left: -50%;
  margin-top: 0;
`;

export const MenuItem = styled(rf.MenuItem)`
  opacity: 0.75;
  margin-bottom: 13px;

  &[aria-expanded="true"] {
    opacity: 1;

    > a img {
      transform: rotate(0deg);
    }
  }

  ${breakpoint("large")`
    opacity: 1;
    margin-left: 1.5rem !important;
    margin-bottom: 0;
  `}

  @media all and (max-width: 1053px) {
    margin-left: 1rem !important;
  }

  @media all and (max-width: 1024px) {
    margin-left: 0 !important;
  }
`;

export const MenuItemRight = styled(rf.MenuItem)`
  opacity: 0.75;

  &[aria-expanded="true"] {
    opacity: 1;
  }

  ${breakpoint("large")`
    opacity: 1;
  `}
`;

export const SignUpButton = styled.a`
  font-family: "proxima-nova" !important;
  visibility: visible;
  list-style: none;
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  color: ${(props) => theme[props.type]["color"]} !important;
  font-weight: 600;
  line-height: 21px !important;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  background: unset !important;
  min-width: unset;
  border: 1px solid ${(props) => theme[props.type]["color"]};
  padding: 0.3rem 1rem !important;
  margin-left: 1.1rem;
  margin-top: 2px;
`;

export const PrimaryButton = styled.a`
  background: #9450d4;
  padding: 17px !important;
  line-height: 16px;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px !important;
`;

export const SecondaryButton = styled.a`
  background: #131017;
  padding: 17px !important;
  line-height: 16px;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  border: 2px solid #282331;
`;

export const SubMenuItem = styled(rf.MenuItem)`
  display: inline-block;
`;

export const SubMenu = styled(rf.Menu)`
  border: none;

  ${breakpoint("large")`
    position: fixed;
    padding: 23px 52px 57px 50px;
    background: black;
    width: 100%;
  `}
`;

export const MainMenu = styled(rf.Menu)`
  padding: 37px 31px 30px;

  ${breakpoint("large")`
    padding: unset;
  `}
`;

export const MenuRight = styled(rf.Menu)`
  display: block !important;
  ${breakpoint("large")`
    display: flex !important;
  `}
`;

export const TopBar = styled(rf.TopBar)`
  padding: 15px 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
  margin-top: 0;
  flex-wrap: wrap;
  padding-top: 0;
  display: block;
  background-color: ${(props) => props.theme.colors.backdropPurple};
  height: 100%;
  overflow: scroll;

  ${breakpoint("large")`
    flex-wrap: nowrap;
    display: flex;
    height: unset;
    overflow: visible;
    transition: background-color 0.5s;
    background-color: ${(props) => theme[props.type]["background"]};
  `}

  ${breakpoint("large")`
    margin-top: ${(props) => (props.margin == "true" ? "42px" : "0")};
  `}
`;

export const MobileTopBar = styled(rf.TopBar)`
  padding: 15px 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
  margin-top: 0;
  flex-wrap: nowrap;
  padding-top: 0;
  background-color: unset;
  display: flex;
  background-color: ${(props) => props.theme.colors.backdropPurple};
  border-bottom: ${(props) =>
    props.shown == "true" ? "1px solid #2A2232" : "none"};
  z-index: 99999;

  ${breakpoint("large")`
    flex-wrap: nowrap;
    display: none;
  `}
`;

export const LogoLink = styled.a`
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 165px;
  margin: 20px 40px 20px 20px !important;
  background: url(${logo}) no-repeat center center/cover;

  ${breakpoint("large")`
    background: url(${(props) =>
      theme[props.type]["logo"]}) no-repeat center center/cover;
  `}
`;

export const ContactUsButton = styled(Globals.ButtonLink)`
  ${Globals.proximaFontStack};
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: none;
  background: #8247ba;
  border-radius: 2px;
  height: 36px;

  ${breakpoint("medium")`
    min-width: 140px;
    width: unset;
  `};
`;

export const VerticalDivider = styled.div`
  width: 0px;
  height: 37.5px;
  margin: 0px 25px;
  border: 1px solid ${(props) => theme[props.type]["color"]};
`;

export const ButtonInverse = styled(Globals.ButtonLink)`
  min-width: 76px;
  height: 36px;
  background: transparent !important;
  color: ${(props) => theme[props.type]["color"]};
  border: 2px solid ${(props) => theme[props.type]["color"]};
  padding: 13px 24px 13px 24px;
  text-transform: none;
  letter-spacing: 0px;

  &:hover {
    background: transparent !important;
    color: black !important;
    opacity: 0.7;
  }
`;

export const MenuHeading = styled.div`
  visibility: hidden;
  ${Globals.proximaFontStack};
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 18px;

  ${breakpoint("large")`
    visibility: visible;
  `}
`;

export const IconContainer = styled.div`
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background: #282331;
  margin: auto;
  text-align: center;
  font-size: 1em;
  line-height: 34px;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const SubMenuLinkHeading = styled.div`
  ${Globals.proximaFontStack};
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #ffffff;
`;

export const SubMenuLinkDescription = styled.div`
  ${Globals.proximaFontStack};
  font-size: 13px;
  line-height: 19.5px;
  color: #ffffff;
`;

export const DownloadGuide = styled.div`
  ${Globals.proximaFontStack};
  font-weight: 800;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #8247ba;
  margin-top: 21px;
`;

export const PurpleBorder = styled.span`
  width: ${(props) => props.width}px;
  border-bottom: 4px solid #8247ba;
  position: absolute;
  bottom: -22px;
  left: 0;
  display: none;

  ${breakpoint("large")`
    display: block;
  `}
`;
