import React, { Fragment } from 'react'
import { ListContainer, ListItem } from './Checklist.style'

function Checklist({list}) {
  const listItems = list.map((item) =>
    <ListItem key={item}>{item}</ListItem>
  )
  return (
    <Fragment>
      <ListContainer>
        {listItems}
      </ListContainer>
    </Fragment>
  )
}

export default Checklist
