import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Content
} from './ContentSection.style';

function ContentSection() {

  return (
    <Fragment>
      <Content>
        <Grid className="grid-margin-x">
          <Cell small={12}>
            <h5>Last Updated January 8, 2015</h5>
            <p>
              Welcome to LexShares.com, a website and online service owned and
              operated by LexShares, Inc. (&quot;LexShares,&quot; &quot;we,&quot; or &quot;us&quot;). This page
              explains the terms by which you may use our service. By accessing or
              using the website at www.lexshares.com, including any related subdomain
              (the “Site”), the LexShares services, website, applications and software
              provided through or in connection with the service, including through a
              mobile device (the &quot;Service&quot;), you (the “User” or “you”) confirm that
              you have read, understood, and agree to be bound by this terms of use
              agreement (the &quot;Terms of Service&quot;), whether or not you are a registered
              User of our Service. For the avoidance of doubt, “User” or “Users” means
              anyone who accesses and/or uses the Site.
            </p>

            <p>
              LexShares is a technology platform that permits Accredited Investors (as
              defined below) and certain other persons to independently connect with
              issuers of securities relating to investments in legal claims. These
              Terms of Service govern your access and use of the Service and all
              content, services and/or products provided through the Service. You
              agree to be bound by the Terms of Use along with all other operating
              rules, policies, and procedures that may be published on the Site by
              LexShares, each of which is incorporated by reference and each of which
              may be updated by us from time to time without notice to you or
              liability for such change. Therefore, Users are advised to periodically
              review the Site for any changes and contact LexShares or your legal
              advisor with any questions. In addition, other services offered through
              the Site may be subject to additional terms and conditions adopted by
              LexShares and/or agreement entered between you and LexShares.
            </p>

            <p>
              <strong
                >LEXSHARES IS NOT A REGISTERED BROKER-DEALER, FUNDING PORTAL, OR
                INVESTMENT ADVISOR AND DOES NOT CONDUCT ANY ACTIVITY THAT WOULD
                REQUIRE SUCH REGISTRATION</strong
              >. Equity securities are offered through WealthForge Securities, LLC, a
              Virginia limited liability company (“WealthForge”), which is a
              securities broker/dealer registered with the U.S. Securities and
              Exchange Commission and a User of Financial Industry Regulatory
              Authority, Inc. Individual personnel of LexShares are registered
              representatives of WealthForge. WealthForge has developed a business
              continuity plan on how they will respond to events that significantly
              disrupt business. WealthForge is located at 6800 Paragon Place, Suite
              237, Richmond, Virginia, 23230. Telephone: (804) 308-0431.
              Website:&nbsp;<a href="https://www.wealthforge.com/" target="_blank" rel="noreferrer"
                >www.wealthforge.com</a
              >
            </p>

            <p>
              PLEASE READ THIS DOCUMENT CAREFULLY BEFORE YOU ACCESS OR USE THE
              SERVICE. BY ACCESSING OR REGISTERING AT THE SITE, YOU AGREE TO BE BOUND
              BY THE TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT WISH TO BE
              BOUND BY THESE TERMS AND CONDITIONS, PLEASE DO NOT ACCESS OR REGISTER
              FOR OUR SITE. IF YOU VIOLATE ANY OF THESE TERMS OF SERVICE (WHICH
              INCLUDE BY REFERENCE LEXSHARES’ PRIVACY POLICY), OR OTHERWISE VIOLATE AN
              AGREEMENT BETWEEN YOU AND US, LEXSHARES MAY TERMINATE YOUR PRIVILEGES,
              DELETE YOUR PROFILE AND ANY CONTENT OR INFORMATION THAT YOU HAVE POSTED
              ON THE SITE AND/OR PROHIBIT YOU FROM USING OR ACCESSING THE SERVICE OR
              THE SITE (OR ANY PORTION, ASPECT OR FEATURE OF THE SERVICE OR THE SITE),
              AT ANY TIME IN ITS SOLE DISCRETION, WITH OR WITHOUT NOTICE.
            </p>

            <h4>Changes to these Terms of Service</h4>

            <p>
              We reserve the right to amend or restate these Terms of Service, change
              the Site, stop providing the Site, applications or the Service, or
              create usage limits for the Site, or change, improve or correct the
              information, materials and descriptions on the Site at any time for any
              reason at our sole discretion, at any time and without notice. If we do
              this, we will post the amended Terms of Service on this page and
              indicate at the top of the page the date of the last revision. Your
              continued use of the Service following the posting of any changes to the
              Terms of Service constitutes acceptance of those changes. Upon
              termination of these Terms of Service or your access to the Site for any
              reason or no reason, you will continue to be bound by these Terms of
              Service which, by their nature, should survive termination, including
              without limitation ownership provisions, warranty disclaimers,
              indemnity, and limitations of liability. The information and materials
              on the Site may contain typographical errors or inaccuracies. Any dated
              information is published as of its date only, and LexShares does not
              undertake any obligation or responsibility to update or amend any such
              information. You agree that LexShares and its subsidiaries and
              affiliates will not be liable to you or to any third party for any such
              modification, suspension or discontinuance.
            </p>

            <h4>Language of this Agreement</h4>

            <p>
              The language of these Terms of Service shall be English. LexShares may
              offer these Terms of Service in other languages in the future, but the
              English version shall always be controlling.
            </p>

            <h4>Other Agreements</h4>

            <p>
              In addition to these Terms of Service, you may enter into other
              agreements with us or others that will govern your use of the Service or
              related services offered by us or others. If there is any contradiction
              between these Terms of Service and another agreement that you enter into
              which is applicable to specific aspects of the Service, the other
              agreement shall take precedence in relation to those specific aspects of
              the Service to which it applies.
            </p>

            <h4>Permission to Use Service</h4>

            <p>
              LexShares grants you permission to use the Service as set forth in these
              Terms of Service, provided that: (i) you will not copy, distribute, or
              disclose any part of the Service in any medium; (ii) you will not alter
              or modify any part of the Service other than as may be reasonably
              necessary to use the Service for its intended purpose; (iii) you will
              not engage in Prohibited Conduct; and (iv) you will not violate any
              rights of third parties and otherwise comply with the terms and
              conditions of these Terms of Service.
            </p>

            <p>
              You will need to register with LexShares, create a User account and
              provide personal information to complete your profile (“User Profile”).
              Your account gives you access to the services and functionality that we
              may establish and maintain from time to time and in our sole discretion.
              You may never use another User’s account without permission. You are
              solely responsible for updating any and all account registration
              information. Failure to do so shall constitute a breach of the Terms of
              Service, which may result in immediate termination of your account. You
              will also choose a password and a user name. LexShares reserves the
              right in its sole discretion to refuse registration of or cancel a user
              name, and domain name. You are solely responsible for maintaining the
              confidentiality of your password and account. You agree to notify
              LexShares immediately in writing of any unauthorized use of your account
              or any other breach of security. You will not share your password, let
              anyone else access your account, or do anything else that might
              jeopardize the security of your account. You will not transfer your
              account to anyone without first getting our written permission. You
              acknowledge and agree that you are liable for any damages or losses to
              LexShares and other Users by any use of your account, either authorized
              or unauthorized.
            </p>

            <p>
              You agree that your account will be self-directed and that you are
              solely responsible for all purchases, orders, investment decisions and
              instructions placed in your account. Although the Site may provide data,
              information or content provided by third parties or us relating to
              investment strategies and/or opportunities to buy and/or sell
              securities, you should not interpret any such content as tax, legal,
              financial, or investment advice or a recommendation to invest in any
              offering posted on the Site. Any decision to invest shall be based
              solely on your own consideration and analysis of the risks involving a
              particular offering and is made at your own risk. You acknowledge and
              agree that you are solely responsible for determining the suitability of
              an investment or strategy and accept the risks associated with such
              decisions, which include the risk of losing the entire amount of your
              principal. We have no special relationship with or fiduciary duty to you
              and your use of the Site or the Services does not create such a
              relationship. You agree that you are solely responsible for conducting
              legal, accounting and other due diligence review on the companies posted
              on the Site. You are strongly advised to consult a licensed legal
              professional and investment advisor for any legal, tax, insurance, or
              investment advice as the Site does not provide any of the foregoing
              advice or recommendations or provide any due diligence.
            </p>

            <p>
              You may use your account settings to control your User Profile. By
              providing LexShares with your email address, you consent to our using
              such email address to send you Service-related notices, including any
              notices required by law, in lieu of communication by postal mail. We may
              also use your email address to send you other messages, including
              changes to features of the Service and special offers. If you do not
              want to receive such email messages, you may opt out by informing us.
              Opting out may prevent you from receiving email messages regarding
              updates, improvements, or offers.
            </p>

            <p>
              LexShares may permanently or temporarily terminate, suspend, or
              otherwise refuse to permit your access to the Service without notice and
              liability, if, in LexShares&apos; sole determination, you violate any terms
              of these Terms of Service, including any Prohibited Conduct (as defined
              below). Upon termination for any reason, you continue to be bound by
              these Terms of Service.
            </p>

            <h4>Eligibility</h4>

            <p>
              This Site is intended solely for Users who are eighteen (18) years of
              age or older, and any registration by, use of or access to the Site by
              anyone under 18 is unauthorized, unlicensed and in violation of these
              Terms of Service. By using the Service or the Site, you represent and
              warrant that you are 18 or older and that you agree to and to abide by
              all of the terms and conditions of these Terms of Service.
            </p>

            <p>
              The segment of our Service (and certain web pages of the Site) that
              relate to the viewing of actual investment opportunities or to making
              investments in the securities offered therein are available only to
              certain eligible, registered and authorized users. Such segments of our
              Service and the Site may therefore not be available in all jurisdictions
              or to all Users. Where sample investment opportunities are displayed on
              public pages for illustrative purposes, only eligible Users will be able
              to view the full offering details.
            </p>

            <p>
              For persons who reside in the United States, only “accredited investors”
              <a href="https://www.sec.gov/answers/accred.htm" target="_blank" rel="noreferrer"
                > https://www.sec.gov/answers/accred.htm</a
              >&nbsp;as defined in rule 501 of regulation d of the securities act of
              1933, as amended (the “securities act”), with a valid user id and
              password, are authorized to access such services and web pages (such
              persons being “accredited investors”). Generally, to qualify as an
              accredited investor, individuals must have a net worth of more than $1
              million (excluding their primary residence), or gross income for each of
              the last two years of at least $200,000 ($300,000 jointly with their
              spouse) with the expectation of a similarly qualifying income during the
              current year. securities rules and regulations require investors to
              provide supporting documents to issuers that provide proof that you are
              an accredited investor. such authorization may require completion of an
              accredited investor questionnaire and satisfactory background
              information screening. your failure to provide any information and
              documentation that is requested to confirm your status as an accredited
              investor will prevent you from investing in offerings posted on
              lexshares and cause lexshares to immediately discontinue your use of the
              service by preventing your access to the website and the service. you
              agree that in the event any material changes occur after your
              registration that may affect your status as an accredited investor, you
              shall immediately notify lexshares of such changes in writing.
            </p>

            <p>
              Persons who reside outside the United States are allowed access to such
              investment opportunities only if such access does not violate the laws
              of their country of residence. Our Services (and certain pages of the
              Site) that relate to the viewing of actual investment opportunities or
              to making investments in the securities offered therein may not be used
              by any person or entity in any jurisdiction where the provision or use
              thereof would be contrary to applicable laws, rules or regulations of
              any governmental authority or where LexShares or one of its related
              entities is not authorized to provide such information or services. You
              agree that it is solely your responsibility to be aware of the
              applicable laws and regulations of your country of residence. The
              content provided on this Site does not constitute an offer or
              solicitation to sell securities referred to on this Site, by anyone in
              any jurisdiction in which such offer, solicitation or distribution would
              be unlawful or in which the person making such offer or solicitation is
              not qualified to do so or to anyone to whom it is unlawful to make such
              offer or solicitation.
            </p>

            <h4>Issuer Information</h4>

            <p>
              The Site permits Accredited Investors to independently connect with
              private venture capital funds (“Issuer” or “Fund”), each established for
              the purpose of investing in a single legal claim or several related
              legal claims. Each Issuer is solely responsible for the material it
              posts on this Site and, by posting such material, each represents that:
              (i) it has complied in all material respects with all applicable rules
              and regulations; and (ii) such information will not contain any untrue
              statement of a material fact or omit to state a material fact required
              to be stated or necessary to make such statements not misleading in
              light of the circumstances under which they are made. LexShares makes no
              representations regarding whether any offering materials or content on
              the Site are complete, truthful and accurate nor is LexShares
              responsible to verify or investigate the completeness, truthfulness and
              accuracy of such representation or offering materials.
            </p>

            <p>
              Federal law requires that Issuers independently verify a User’s status
              as an Accredited Investor before accepting a subscription, among other
              requirements. Users agree to cooperate with any requests for additional
              information or documentation. Each Issuer is responsible for ensuring
              that its securities offering complies with state and federal law,
              including without limitation, the use of the Site to offer and sell
              securities, the determination of whether an investor is an Accredited
              Investor, and whether any investment complies with the applicable
              exemption from registering the offering with the SEC. Users of the Site
              acknowledge that LexShares is not liable to and will not verify or
              investigate the accuracy and completeness of the offering materials and
              other information posted on the Site. LexShares shall have the right but
              not the obligation to remove, cancel or reject the posting of any
              securities offerings. Failure to remove, cancel or reject securities
              offerings suspected of violating the securities laws does not constitute
              an endorsement or approval of said securities offerings by LexShares.
              The information provided herein shall not be deemed legal advice. You
              are strongly advised to consult with your own legal counsel.
            </p>

            <h4>Securities Offerings; No Professional Advice Provided</h4>

            <p>
              The securities offered on this Site or this Service have not been
              registered under the Securities Act of 1933, in reliance on the
              exemptive provisions of Section 4(a) (2) of the Securities Act and
              Regulation D and Rule 506, and/or Regulation S, promulgated thereunder.
              Securities sold through private placements are restricted and not
              publicly traded, and are therefore illiquid. Neither the U.S. Securities
              and Exchange Commission nor any state securities commission or other
              regulatory authority has approved, passed upon or endorsed the merits of
              any offering on this Site or this Service.
            </p>

            <p>
              The securities posted on the Site are only suitable for Accredited
              Investors who are familiar with and willing to accept the high risks
              associated with investments in private issuers, including the risk of a
              complete loss of your investment. Securities sold through private
              placements are speculative and not publicly traded. Additionally,
              securities may be subject to restrictions on resale or transfer
              including holding period requirements. Investing in private placements
              requires a high risk tolerance, low liquidity need, and long-term
              commitments. Users must be able to afford to lose their entire
              investment. Investment products are not FDIC insured, may lose value,
              and there is no bank guarantee.
            </p>

            <p>
              The securities offerings posted on the Site enable you to invest
              indirectly in legal claims by purchasing securities issued by a single
              purpose venture capital fund -- a separate limited liability company
              created for the sole purpose of investing in the legal claim
              (hereinafter the “Fund” or collectively the “Funds”). Our affiliate,
              LawShares, LLC, manages the Funds in accordance with the terms of each
              Fund’s operating documents. Typically, the investment manager manages,
              controls, conducts the affairs of the Fund, and exercises any management
              rights negotiated with the Company. Investors of the Fund do not have
              the same management rights as the investment manager and may or may not
              have redemption or withdrawal rights pursuant to the provisions under
              the Fund’s operating agreement. Investors in the Fund may be charged an
              administrative fee to cover the actual out-of-pocket costs of the
              investment fund; the administrative fee is disclosed to the investors at
              the time of the Fund formation. Investors in a Fund have the benefit of
              the investment manager’s experience in related transactions. An
              investment manager may receive a share of any profit (i.e. after you
              received your principal back) of the Fund for providing management
              services (“carried interest”), but may not receive any transaction based
              fees such as commission. Terms may vary so you are required to read all
              offering materials carefully and consult with your legal, financial and
              investment counsel. The foregoing description is non-exhaustive and
              should not be deemed to be legal advice.
            </p>

            <p>
              The securities being offered have not been registered under the
              Securities Act, in reliance, among other exemptions, on the exemptive
              provisions of Regulation D under the Securities Act. Issuers represent
              and warrant that each fund is structured to qualify as an exempt
              investment company under Section 3(c)(1) of the Investment Company Act
              of 1940, as amended (the “Investment Company Act”), which provides an
              exemption from registration for a private investment company that (i)
              has no more than one-hundred (100) beneficial owners; and (ii) does not
              make or propose to make a public offering of its securities. Users must
              read each Issuer’s offering memorandum and transaction documents for
              more information and discuss any questions with each Issuer directly
              prior to investing. Similar reliance has been placed on apparently
              available exemptions from securities registration or qualification
              requirements under applicable state securities laws. No assurance can be
              given that any offering currently qualifies or will continue to qualify
              under one or more of such exemptive provisions due to, among other
              things, the adequacy of disclosure and the manner of distribution, the
              existence of similar offerings in the past or in the future, or a change
              of any securities law or regulation that has retroactive effect. The
              exemptions relied upon for such offerings are significantly dependent
              upon the accuracy of the representations of the Users to be made to the
              Site and Issuers on the Site in connection with the offering. In the
              event that any such representations prove to be untrue, the registration
              exemptions relied upon by an Issuer in selling the securities might not
              be available and substantial liability to such Issuer would result under
              applicable securities laws for rescission or damages. These risks are
              non-exhaustive and are intended to highlight certain risks associate
              with investing in securities that are not registered with the SEC. WE
              STRONGLY ADVISE YOU TO CONSULT A LEGAL, TAX AND FINANCIAL PROFESSIONAL
              BEFORE INVESTING, AND CAREFULLY REVIEW ALL THE SPECIFIC RISK DISCLOSURES
              PROVIDED AS PART OF ANY OFFERING MATERIALS AND ASK THE ISSUER ANY
              QUESTIONS YOU MAY HAVE OR REQUEST ADDITIONAL INFORMATION.
            </p>

            <p>
              All subscription funds are held by WealthForge,&nbsp;<a
                href="https://www.wealthforge.com/"
                target="_blank" rel="noreferrer"
                >www.wealthforge.com</a
              >, which also acts as a third-party payment processor and agent for
              escrowed funds held at Atlantic Capital Bank. The use of WealthForge
              services is subject to their terms of use and privacy policy.
            </p>

            <p>
              Investment overviews on the Site contain summaries of the purpose and
              principal terms of the investment opportunities. Such summaries are
              intended for informational purposes only and do not purport to be
              complete, and each is qualified in its entirety by reference to the more
              detailed discussions contained in the investor document package relating
              to such investment opportunity. The information contained on the Site
              and the Service has been prepared by LexShares without reference to any
              particular user&apos;s investment requirements or financial situation, and
              potential investors are encouraged to consult with professional tax,
              legal and financial advisors before making any investment.
            </p>

            <p>
              You may not participate in any offering in the event you are subject to
              certain “bad actor” disqualifying events described in Rule 506(d) (a
              “Disqualifying Event”). You represent that you are not subject to a
              Disqualifying Event and that you will promptly notify LexShares in
              writing should any Disqualifying Events be applicable to you. LexShares
              is not liable or responsible for making Rule 506(e) disclosures, nor for
              determining whether any User is subject to a Disqualifying Event.
            </p>

            <h4>No Representations or Warranties</h4>

            <p>
              LexShares makes no warranty or representation as to the completeness or
              accuracy of the information provided on the Site, nor as to any Issuer’s
              compliance with state and/or federal law, rules and regulations. To the
              maximum extent permissible under law, LexShares assumes no liability or
              responsibility for any errors or omissions in the content of the Site.
              LexShares does not endorse or represent the reliability or accuracy of
              any content or information distributed through or accessed from the
              Site, and has not performed any investigation into such information.
              LexShares shall not be liable for any investment decisions made based
              upon such information. You agree that any reliance upon any content or
              information distributed through or accessed from the Site is at your
              sole risk. LexShares is entitled to rely upon the information provided
              by its Users. You acknowledge and agree that LexShares does not provide
              any representation, warranty or assurance that the offering on the Site
              are made in accordance with state and/or Federal securities law,
              including the exemption to the sale of unregistered securities and the
              prohibition against the general solicitation of unregistered securities.
              Each Issuer, and not the Site, is responsible for ensuring that any
              securities offering is done in accordance with state, federal law and
              regulation promulgated by the SEC and Financial Industry Regulatory
              Authority. We make no representation or warranties regarding the
              legality or compliance of any offering. LexShares has not reviewed all
              of the links provided on the Site and is not responsible for the content
              of any off-Site pages.
            </p>

            <h4>Prohibited Conduct</h4>

            <p>
              This Service may only be used for purposes that are legal, proper and in
              accordance with these Terms of Service and any applicable laws, rules or
              regulations. You hereby agree that you shall not engage in the following
              conduct (“Prohibited Conduct”):
            </p>

            <ul>
              <li>
                <p>
                  upload, email, or otherwise transmit any images or other content
                  that are unlawful, obscene, harmful, hateful, invade the privacy of
                  any third party, contain nudity or pornography, or are otherwise
                  objectionable;
                </p>
              </li>
              <li>
                <p>
                  submit material that is intentionally false, defamatory, unlawfully
                  threatening, or unlawfully harassing;
                </p>
              </li>
              <li>
                <p>
                  defame, abuse, harass, stalk, threaten or otherwise violate the
                  legal rights (such as rights of privacy and publicity) of others;
                </p>
              </li>
              <li>
                <p>
                  transmit materials that contain any viruses or other
                  computer-programming routines that are intended to damage,
                  detrimentally interfere with, surreptitiously intercept, or
                  expropriate any system, data, or personal information;
                </p>
              </li>
              <li>
                <p>
                  use the Service to artificially generate traffic or page links to a
                  website or for any other purpose not expressly allowed under these
                  Terms of Service;
                </p>
              </li>
              <li>
                <p>
                  use the Service in a manner that could disable, overburden, or
                  impair the Service or interfere with any other party&apos;s use and
                  enjoyment of the Service, such as through sending &quot;spam&quot; email;
                </p>
              </li>
              <li>
                <p>
                  seek to obtain access to any materials or information through
                  &quot;hacking,&quot; &quot;data harvesting,&quot; or through other means we have not
                  intentionally made available to you through the Service;
                </p>
              </li>
              <li>
                <p>
                  collect or harvest any personal information from Users or the
                  Service;
                </p>
              </li>
              <li>
                <p>
                  create user accounts by automated means or under false or fraudulent
                  pretenses;
                </p>
              </li>
              <li>
                <p>
                  upload, post, email or transmit, or otherwise make available through
                  the Service any content that infringes any patent, trademark,
                  copyright, trade secret or other proprietary right of any party,
                  unless you are the owner of such rights or have the permission of
                  the owner to post such content;
                </p>
              </li>
              <li>
                <p>
                  upload, post, email or transmit, or otherwise make available through
                  the Service any materials that promote pyramid schemes, chain
                  letters or disruptive commercial messages or advertisements, or
                  anything else prohibited by law, these Terms of Service;
                </p>
              </li>
              <li>
                <p>
                  run Maillist, Listserv, or any form of auto-responder or “spam” on
                  the Service;
                </p>
              </li>
              <li>
                <p>
                  use manual or automated software, devices, bots, scripts or other
                  automated procedures, or other processes to “crawl” or “spider” any
                  page of the Site, including to engage in the practices of &quot;screen
                  scraping,&quot; &quot;database scraping&quot; or any other activity with the
                  purpose of obtaining content or other information;
                </p>
              </li>
              <li>
                <p>
                  interfere or attempt to interfere with the proper working of the
                  Service or any activities conducted on the Service, including to
                  utilize framing techniques to enclose any Content or other
                  proprietary information, place pop-up windows over the Site&apos;s pages,
                  or otherwise affect the display of the Site&apos;s pages;
                </p>
              </li>
              <li>
                <p>
                  download any file posted by another user that you know, or
                  reasonably should know, cannot be legally distributed in such
                  manner;
                </p>
              </li>
              <li>
                <p>
                  impersonate another person or entity, or falsify or delete any
                  author attributions, legal or other proper notices or proprietary
                  designations or labels of the origin or source of any materials;
                </p>
              </li>
              <li>
                <p>
                  remove any copyright, trademark or other proprietary rights notices
                  contained in or on the Service;
                </p>
              </li>
              <li>
                <p>
                  post or submit content that falsely expresses or implies that such
                  content is sponsored or endorsed by LexShares, any of its affiliates
                  or any third parties;
                </p>
              </li>
              <li>
                <p>
                  use the Service for any illegal or unauthorized purpose (including,
                  without limitation, in violation of any United States federal and
                  state securities or blue sky laws or regulations, securities
                  exchange or self-regulatory organization’s rules or regulations, or
                  equivalent laws or regulations in foreign jurisdictions);
                </p>
              </li>
              <li>
                <p>
                  promote or provide instructional information about illegal
                  activities or promote physical harm or injury against any group or
                  individual;
                </p>
              </li>
              <li>
                <p>
                  share or disclose with anyone any information obtained through the
                  Service about any investment offerings;
                </p>
              </li>
              <li>
                <p>
                  use the Service for any commercial purpose whatsoever other than for
                  your personal use, including (without limitation) soliciting other
                  users for investments of any kind, offering or selling any products
                  or services of any kind, and making investment recommendations to
                  other users; or
                </p>
              </li>
              <li><p>engage in offline transactions relating to the Service.</p></li>
            </ul>

            <h4>Non-Circumvention Restrictions</h4>

            <p>
              By using the Site, you acknowledge and agree that LexShares has invested
              considerable resources to develop its business model that connects
              plaintiffs involved in legal claims with investors. Accordingly, you
              agree that for a period while you are a registered User of the Service
              and until two (2) years following any termination of your account, you
              may not solicit, initiate, encourage, or engage in discussions or
              negotiations with any plaintiff, attorney or investor or other third
              party that has been introduced to you by LexShares, without the express
              written permission of LexShares. These Non-Circumvention Restrictions
              shall survive any termination of these Terms of Service.
            </p>

            <h4>Our Proprietary Rights</h4>

            <p>
              The Service and its materials, including, without limitation, software,
              images, text, graphics, illustrations, logos, patents, trademarks,
              service marks, copyrights, photographs, audio, videos and music (the
              &quot;LexShares Content&quot;), and all intellectual property rights related
              thereto, are the exclusive property of LexShares and its licensors.
              Except as explicitly provided herein, nothing in these Terms of Service
              shall be deemed to create a license in or under any such intellectual
              property rights, and you agree not to sell, license, rent, modify,
              distribute, copy, reproduce, transmit, publicly display, publicly
              perform, publish, adapt, edit or create derivative works from any
              materials or content accessible on the Service. Use of the LexShares
              Content or materials on the Service for any purpose not expressly
              permitted by these Terms of Service is strictly prohibited. LexShares
              neither warrants nor represents that your use of materials displayed on
              the Site will not infringe rights of third parties not owned or
              affiliated with LexShares.
            </p>

            <p>
              You may choose to or we may invite you to submit comments or ideas about
              the Service, including without limitation about how to improve the
              Service or our products (“Ideas”). By submitting any Idea, you agree
              that your disclosure is gratuitous, unsolicited and without restriction
              and will not place LexShares under any fiduciary or other obligation,
              that we are free to disclose the Ideas on a non-confidential basis to
              anyone or otherwise use the Ideas without any additional compensation to
              you. You acknowledge that, by acceptance of your submission, LexShares
              does not waive any rights to use similar or related ideas previously
              known to LexShares, or developed by its employees, or obtained from
              sources other than you.
            </p>

            <p>
              By providing content on the Site, you grant us a royalty-free,
              non-exclusive, worldwide license, under your intellectual property
              rights, to copy, reformat, index, modify, display, distribute and put to
              commercial and other uses the Site. No compensation will be paid with
              respect to our use such content.
            </p>

            <h4>No Professional Advice</h4>

            <p>
              You agree that, while some of the Content on the Site may relate to
              legal, tax, investment, or accounting matters, neither LexShares nor any
              of the professionals providing Content on the Site are providing
              professional advice to you. Featuring of Issuers on the Site does not
              constitute endorsement by LexShares or representation of the quality of
              any potential investment in such offerings. LexShares does not endorse
              any Issuer or claim described on the Site.
            </p>

            <h4>Cautionary Note Regarding Forward-Looking Statements</h4>

            <p>
              The content published on the Site may include “forward-looking
              statements” within the meaning of the safe harbor provisions of the U.S.
              Private Securities Litigation Reform Act or similar applicable
              regulation. In some cases, you can identify these statements by
              forward-looking words such as “may,” “might,” “will,” “should,”
              “expect,” “plan,” “anticipate,” “believe,” “estimate,” “predict,”
              “potential” or “continue,” and the negative of these terms and other
              comparable terminology. Such forward-looking statements which are
              subject to known and unknown risks, uncertainties and assumptions about
              us, may include, but are not limited to, projections of financial
              performance based on growth strategies and anticipated trends,
              statements concerning Issuer’s plans, objectives, expectations and
              intentions and other statements that are not historical or current
              facts. Forward-looking statements are only predictions based current
              expectations about future events. They involve risks and uncertainties
              that could cause actual results, level of activity, performance or
              achievements to differ materially from those expressed or implied in
              such forward-looking statements. You should not rely upon
              forward-looking statements as predictions of future events. We are under
              no duty to update any forward-looking statements after the date of
              publication to conform our prior statements to actual results or revised
              expectations and we do not intend to do so.
            </p>

            <h4>Limited License Grant</h4>

            <p>
              Subject to the terms and conditions of these Terms of Service, you are
              hereby granted a non-exclusive, limited, personal license to use the
              Service. LexShares reserves all rights not expressly granted herein in
              the Service and the LexShares Content. LexShares may terminate this
              license at any time for any reason or no reason.
            </p>

            <h4>Links to Third-Party Websites or Services</h4>

            <p>
              You acknowledge that LexShares may contain links to third-party websites
              or services that are not owned or controlled by LexShares. LexShares has
              no control over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third-party websites or services. If you
              access a third-party website from LexShares, you do so at your own risk,
              and you understand that these Terms of Service and LexShares’ Privacy
              Policy do not apply to your use of such sites. You expressly release
              LexShares from any and all liability arising from your use of any
              third-party website or services or third-party owned content. We
              encourage you to be aware of when you leave the Service, and to read the
              terms and conditions and privacy policy of any third-party website or
              service that you visit.
            </p>

            <h4>User Content</h4>

            <p>
              The Service may allow you and other users to submit, post, transmit and
              share content with other Users. You are solely responsible for any such
              content (which may include photos, profiles, messages, notes, text,
              information, music, video, contact information for you or others,
              advertisements or other content) that you upload, publish, provide or
              display on or through the Service or the Site, or transmit to or share
              with other Users (collectively, the “User Content”). It is against these
              Terms of Service to contact plaintiffs, defendants, attorneys or
              investors in an attempt to enter into any transactions with such persons
              or entities outside of the Service or for any other purpose.
            </p>

            <p>
              You understand and agree that LexShares may, but is not obligated to,
              review and delete or remove (without notice) any User Content in its
              sole discretion, including without limitation, User Content that in the
              sole judgment of LexShares violates these Terms of Service, might be
              offensive or illegal, or might violate the rights of, harm, or threaten
              the safety of, Users or others.
            </p>

            <p>
              By posting User Content to any part of the Site, notwithstanding
              anything to the contrary in these Terms of Service, you automatically
              agree to license to LexShares the use of the User Content that you
              provide, by granting to LexShares a non-exclusive, but perpetual and
              irrevocable, and worldwide right to exercise any and all copyright,
              publicity, and database rights you may have in the information you
              provide to us, in any media, known or unknown, on a royalty-free basis.
              Furthermore, pursuant to such license, we are permitted to sub-license
              all of your rights. You may remove your User Content from the Site at
              any time. If you choose to remove your User Content, the license granted
              above will not expire.
            </p>

            <p>
              You may review personal information posted by other Users on the Site,
              but you are not authorized to disclose or otherwise use such information
              for any purpose than the one contemplated by the Service.
            </p>

            <h4>Confidentiality</h4>

            <p>
              The Site and the Service contains confidential and sensitive trade
              secrets of LexShares. LexShares also provides confidential and sensitive
              trade secrets of issuers and plaintiffs involved in legal claims
              (collectively, hereinafter the “Confidential Information). You agree not
              to disclose this Confidential Information to anyone other than another
              your attorneys and Users shall not use this Confidential Information for
              any purposes other than those contemplated by our Services.
            </p>

            <p>
              Confidential information under these Terms of Service consists of all
              non-public information whether oral or in writing (a) that is designated
              as &quot;Confidential&quot; or &quot;Proprietary&quot; by LexShares, an issuer, a plaintiff,
              or an attorney at the time of disclosure or within a reasonable period
              thereafter; (b) that is only available to Users, or (c) that you should
              reasonable understand is confidential. Confidential Information includes
              non-public information that LexShares, its affiliates, issuers,
              plaintiffs, or attorneys furnish or otherwise make available to Users
              with respect to any investment opportunities, together with any reports,
              analyses, compilations, forecasts, memoranda, notes, studies and any
              other written or electronic materials prepared by LexShares, an issuer,
              a plaintiff, or an attorney. Confidential Information includes, without
              limitation, information relating to LexShares’ services, the marketing
              or promotion of any service, business policies or practices, strategic
              plans, pricing, rates, plans, pro-formas, agreements, work-product,
              settlement agreements, valuations, reports, market comparables, verdict
              data, client or attorney memoranda, management systems, and information
              received from others that LexShares is obligated to treat as
              confidential.
            </p>

            <p>
              You shall retain the Confidential Information in confidence, and shall
              not use such Confidential Information except as expressly permitted in
              these Terms of Service. You will use at least the same degree of care in
              safeguarding the Confidential Information as you use in safeguarding
              your own confidential information and trade secrets, but shall use not
              less than reasonable care and diligence.
            </p>

            <p>
              Your right to the Confidential Information under these Terms of Service
              will not apply to Confidential Information which you can demonstrate:
              (i) is or becomes a matter of public knowledge through no fault of your
              own; (ii) was or becomes available to you on a non-confidential basis
              from a third party, provided that such third party is not bound by an
              obligation of confidentiality to LexShares with respect to such
              Confidential Information; (iii) was independently developed by you
              without reference to the Confidential Information; or (iv) is required
              to be disclosed by law, provided that you promptly notify LexShares in
              order to provide LexShares an opportunity to seek a protective order or
              other relief with respect to such impending disclosure.
            </p>

            <h4>Copyright Complaints</h4>

            <p>
              LexShares respects the intellectual property rights of others and
              requires that its Users do the same. If you believe that any content on
              the Site or the Service or other activity taking place on the Site or
              the Service constitutes infringement of a work protected by copyright (a
              &quot;Work&quot;), please notify our agent, designated under the Digital
              Millennium Copyright Act (17 U.S.C. §512) (the “DMCA”) to respond to
              such concerns, as follows:
            </p>

            <p>
              LexShares, Inc. <br />
              Attn: General Counsel<br />
              125 High Street<br />
              25th Floor<br />
              Boston, MA 02110<br />
              Telephone: 877-290-4443<br />
              E-mail: <a href="mailto:info@lexshares.com">info@lexshares.com</a><br />
            </p>

            <p>
              Your notice must comply with the DMCA. Upon receipt of a compliant
              notice, we will respond and proceed in accordance with the DMCA. You
              acknowledge, accept and agree that if we receive a notice of a claim of
              copyright infringement, we may immediately remove the identified
              materials from our Site without liability to you or any other party and
              that the claims of the complaining party will be referred to the United
              States Copyright Office for adjudication as provided in the DMCA. Please
              note that this procedure is exclusively for notifying LexShares and its
              affiliates that your copyrighted material has been infringed. The
              preceding requirements are intended to comply with our rights and
              obligations under the DMCA, including 17 U.S.C. §512(c), but do not
              constitute legal advice. It may be advisable to contact an attorney
              regarding your rights and obligations under the DMCA and other
              applicable laws. LexShares may, in its sole discretion, terminate the
              accounts of those who are accused of copyright infringement or other
              intellectual property rights.
            </p>

            <h4>Consent to Electronic Transactions and Disclosures</h4>

            <p>
              Because LexShares operates largely on the Internet, it is necessary for
              you to consent to transact business with us online and electronically.
              Before you decide to do business electronically with LexShares and
              WealthForge, you must satisfy the following computer hardware and
              software requirements: access to the Internet; an email account and
              related software capable of receiving email through the Internet; a web
              browser which is SSL-compliant and supports secure sessions, such as
              Internet Explorer 5.0 or above and Netscape Navigator 6.0 or above, or
              the equivalent software; and hardware capable of running this software.
            </p>

            <p>
              LexShares and/or WealthForge (or a third-party servicer or escrow
              provider that we or WealthForge may retain) generally receive all
              payments, and make all disbursements, through electronic funds transfers
              (ACH transfers) using the bank (or other financial institution) account
              information you provide to us. You authorize such bank or other
              financial account to pay any amounts described herein, and authorize
              LexShares or WealthForge (or any such third-party servicer or escrow
              provider) to make any and all investment disbursements, to such account.
              You agree to provide LexShares updated information regarding your bank
              or other account upon LexShares’ request and at any time that the
              information earlier provided is no longer valid.
            </p>

            <p>
              As part of doing business with LexShares and WealthForge, you must also
              consent to our giving you certain disclosures electronically, either via
              our Site or to the email address you provide to us. By agreeing to the
              Terms of Service, you agree to receive electronically all documents,
              communications, notices, contracts, and agreements, including any IRS
              Form 1099, or other tax forms, schedules or information statements,
              arising from or relating to your registration as an investor on our
              Site, any investments you may make, your use of this Service, and the
              servicing of any investment you may make (each, a “Disclosure”), from
              LexShares, WealthForge, or any service provider either of us may use.
              The decision to do business with LexShares and WealthForge
              electronically is yours. This document informs you of your rights
              concerning Disclosures.
            </p>

            <p>
              Your consent to receive Disclosures and transact business
              electronically, and our agreement to do so, applies to any transactions
              to which such Disclosures relate, whether between you and LexShares or
              between you and WealthForge. Your consent will remain in effect for so
              long as you are a User and, if you are no longer a User, will continue
              until such a time as all Disclosures relevant to transactions that
              occurred while you were a User have been made.
            </p>

            <p>
              You may not withdraw such consent as long as you have outstanding any
              investments made through the Site. If you have no outstanding
              investments made through the site and wish to withdraw consent to doing
              business electronically, we will terminate your registered user account
              with us.
            </p>

            <p>
              You also expressly consent to receiving calls and messages, including
              auto-dialed and pre-recorded message calls, and SMS messages (including
              text messages) from us, our affiliates, marketing partners, agents and
              others calling at their request or on their behalf, at any telephone
              numbers that you have provided or may provide in the future (including
              any cellular telephone numbers). Your cellular or mobile telephone
              provider will charge you according to the type of plan you carry.
            </p>

            <p>
              If you are accessing our site and the Disclosures electronically via a
              mobile device (such as a smart phone, tablet, and the like), in addition
              to the above requirements you must make sure that you have software on
              your mobile device that allows you to print and save the Disclosures
              presented to you during the application process. These applications can
              be found for most mobile devices in the device&apos;s respective &quot;app store.&quot;
              If you do not have these capabilities on your mobile device, please
              access our site through a device that provides these capabilities.
            </p>

            <h4>Updates</h4>

            <p>
              You are required to keep us informed regarding any changes to your email
              address, your home mailing address, or your telephone number so we may
              continue to communicate with you about your authorization as a
              registered User and so that you continue to promptly receive all
              Disclosures. To notify us of any changes, you can contact us by email at
              <a href="mailto:info@lexshares.com">info@lexshares.com</a> or by calling
              us at 877-290-4443.
            </p>

            <p>You may also write to us as follows:</p>

            <p>
              LexShares, Inc.<br />
              125 High Street<br />
              25th Floor<br />
              Boston, MA 02110<br />
              Telephone: 877-290-4443<br />
              E-mail: <a href="mailto:info@lexshares.com">info@lexshares.com</a><br />
            </p>

            <h4>User Disputes</h4>

            <p>
              You are solely responsible for your interactions with other Users. We
              reserve the right, but have no obligation, to monitor disputes between
              you and other Users.
            </p>

            <h4>Privacy</h4>

            <p>
              Please review our Privacy Policy. By using the Site or the Service, you
              are consenting to have your personal data transferred to and processed
              in the United States.
            </p>

            <h4>Fees</h4>

            <p>
              You agree that LexShares reserves the right to charge certain fees from
              time-to-time in connection with the use of the Site as permitted by
              applicable law.
            </p>

            <p>
              All fees hereunder are subject to change, and fees can be added,
              revised, or discontinued, at any time, at LexShares’ sole discretion and
              without any prior notice. Any new fees will become effective
              immediately, provided, however, new fees will not apply to active
              transactions at the time of their change.
            </p>

            <h4>Taxes</h4>

            <p>
              YOU ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR THE
              REPORTING AND PAYMENT OF ANY AND ALL TAXES THAT MAY ARISE AS A
              CONSEQUENCE OF YOUR ACTIVITIES. YOU SHALL NOT RELY ON LEXSHARES TO
              REPORT YOUR ACTIVITIES FOR TAX PURPOSES AND IT IS YOUR RESPONSIBILITY TO
              MAKE SURE YOU ARE IN FULL COMPLIANCE WITH ANY APPLICABLE TAX LAWS.
            </p>

            <h4>No Warranty and Disclaimers</h4>

            <p>
              THE SERVICE IS PROVIDED ON AN &quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND “WHEN
              AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. THE SERVICE IS
              PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, COMPLIANCE WITH STATE, FEDERAL OR
              FOREIGN LAW (INCLUDING ANY SECURITIES REGULATIONS), OR NON-INFRINGEMENT.
              WITHOUT LIMITING THE FOREGOING, LEXSHARES, ITS SUBSIDIARIES, AND ITS
              LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
              CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE
              WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR
              SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE
              SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT
              DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS
              DOWNLOADED OR OTHERWISE OBTAINED AT YOUR OWN RISK AND YOU WILL BE SOLELY
              RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR LOSS OF DATA THAT RESULTS
              THEREFROM.
            </p>

            <p>
              LEXSHARES DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
              FOR ANY PRODUCT OR SERVICE OFFERED BY A THIRD PARTY THROUGH THE
              LEXSHARES SERVICE OR ANY HYPERLINKED WEBSITE OR SERVICE, OR FEATURED IN
              ANY BANNER OR OTHER ADVERTISING, AND LEXSHARES WILL NOT BE A PARTY TO OR
              IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS
              OF PRODUCTS OR SERVICES.
            </p>

            <p>
              WE DO NOT GUARANTEE THE ACCURACY OF ANY CONTENT POSTED ON THE SERVICE.
              ALTHOUGH WE PROVIDE RULES FOR USER CONDUCT AND POSTINGS, WE DO NOT
              CONTROL AND ARE NOT RESPONSIBLE FOR WHAT USERS POST ON THE SERVICE AND
              ARE NOT RESPONSIBLE FOR ANY OFFENSIVE, INAPPROPRIATE, OBSCENE, UNLAWFUL
              OR OTHERWISE OBJECTIONABLE CONTENT YOU MAY ENCOUNTER.
            </p>

            <p>
              WE ARE NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF
              ANY USER OF THE SITE OR SERVICE. WE CANNOT GUARANTEE AND DO NOT PROMISE
              ANY SPECIFIC RESULTS (RELATING TO INVESTMENTS OR OTHERWISE) FROM USE OF
              THE SITE AND/OR THE SERVICE.
            </p>

            <p>
              WE RESERVE THE RIGHT TO CHANGE ANY AND ALL CONTENT CONTAINED IN THE SITE
              AND ANY SERVICES OFFERED THROUGH THE SITE AT ANY TIME WITHOUT NOTICE.
              REFERENCE TO ANY PRODUCTS, SERVICES, PROCESSES OR OTHER INFORMATION, BY
              TRADE NAME, TRADEMARK, MANUFACTURER, SUPPLIER OR OTHERWISE DOES NOT
              CONSTITUTE OR IMPLY ENDORSEMENT, SPONSORSHIP OR RECOMMENDATION THEREOF,
              OR ANY AFFILIATION THEREWITH, BY LEXSHARES.
            </p>

            <h4>Indemnity</h4>

            <p>
              You agree to defend, indemnify and hold harmless LexShares, WealthForge
              and their respective subsidiaries, agents, managers, and other
              affiliated companies, and their employees, contractors, agents, officers
              and directors, from and against any and all claims, damages,
              obligations, losses, liabilities, costs or debt, and expenses (including
              but not limited to attorney&apos;s fees) arising from: (i) your use of and
              access to the Service, including any data or work transmitted or
              received by you; (ii) your violation of any term of these Terms of
              Service, including without limitation, your breach of any
              representations; (iii) your violation of any third-party right,
              including without limitation any right of privacy, publicity rights or
              intellectual property rights; (iv) your violation of any law, rule or
              regulation of the United States or any other country; (v) any claim or
              damages that arise as a result of any of your User Content or any that
              are submitted via your account; or (vi) any other party’s access and use
              of the Service with your unique User name, password or other appropriate
              security code. LexShares reserves the right to assume, at its sole
              expense, the exclusive defense and control of any such claim or action
              and all negotiations for settlement or compromise, and you agree to
              fully cooperate with LexShares in the defense of any such claim, action,
              settlement or compromise negotiations, as requested by LexShares.
            </p>

            <h4>Limitation of Liability</h4>

            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
              LEXSHARES, ITS AFFILIATES, DIRECTORS, EMPLOYEES OR ITS LICENSORS BE
              LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES
              FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES,
              THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THIS SERVICE. UNDER NO
              CIRCUMSTANCES WILL LEXSHARES BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR
              INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR
              USE OF THE SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
            </p>

            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, LEXSHARES ASSUMES NO
              LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
              NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICE;
              (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
              AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
              CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES,
              TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR
              SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT
              OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT
              POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE
              SERVICE; AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR
              ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT SHALL LEXSHARES, ITS
              AFFILIATES, DIRECTORS, EMPLOYEES, OR LICENSORS BE LIABLE TO YOU FOR ANY
              CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS.
              YOUR EXCLUSIVE REMEDY AGAINST LEXSHARES OR ITS DIRECTORS, OFFICERS AND
              EMPLOYEES FOR ANY DISSATISFACTION WITH OUR SERVICES, YOUR TRADING
              EXPERIENCE, OR THE TERMS AND CONDITIONS OF THESE TERMS OF SERVICE IS
              YOUR DISCONTINUATION OF YOUR USE OF THE SERVICE.
            </p>

            <p>
              THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
              LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR
              ANY OTHER BASIS, EVEN IF LEXSHARES HAS BEEN ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
              FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
            </p>

            <h4>Assignment</h4>

            <p>
              These Terms of Service, and any rights and licenses granted hereunder,
              may not be transferred or assigned by you, but may be assigned by
              LexShares without restriction.
            </p>

            <h4>Governing Law</h4>

            <p>
              You agree that: (i) the Service shall be deemed solely based in the
              State of New York; and (ii) the Service shall be deemed a passive one
              that does not give rise to personal jurisdiction over LexShares, either
              specific or general, in jurisdictions other than the State of New York.
              These Terms of Service shall be governed by the internal substantive
              laws of the State of New York, without respect to its conflict of laws
              principles.
            </p>

            <h4>Arbitration of Disputes Brought by a User</h4>

            <p>
              You agree that any claim asserted against LexShares, or its directors,
              officers or employees, or any controversy or claim arising out of or
              relating to the Services, these Terms of Service, the Privacy Policy or
              other operating rules, policies, and procedures that may be published on
              the Site (including the question of whether any particular matter is
              arbitrable under these Terms of Service or concerning the construction,
              interpretation and effect of these Terms of Service or any claims in
              these Terms of Service, or the rights and liabilities of a User or
              LexShares), or the breach thereof, shall be settled by arbitration
              administered by the American Arbitration Association in accordance with
              its Commercial Arbitration Rules, and judgment on the award rendered by
              the arbitrator may be entered in any court having jurisdiction thereof.
              The location for any such arbitration shall be in New York City. Only
              individuals who are (1) lawyers engaged full-time in the practice and
              (2) on the AAA register of arbitrators shall be selected as an
              arbitrator. There shall be one arbitrator who shall be chosen in
              accordance with the rules of the AAA. In the event of any claim,
              controversy or alleged dispute between you and LexShares, its members or
              affiliates you hereby agree to attempt in good faith to amicably resolve
              any such dispute at least thirty (30) days before instituting any legal
              proceeding.
            </p>

            <h4>Resolution of Disputes Brought by LexShares</h4>

            <p>
              You agree that any claim by LexShares against a User may be brought
              pursuant to the rules of the AAA or may be adjudicated by a court of
              competent jurisdiction, either in New York, New York, USA, or where the
              User is located (either the User’s home address or the address of the
              User’s principal place of business). With respect to the foregoing, the
              manner of dispute resolution and its venue is at LexShares’ discretion
              and without reference to the choice of laws provisions of any
              jurisdiction. The User agrees to submit to the personal jurisdiction of
              the courts located in New York, New York, USA. YOU HEREBY WAIVE TRIAL BY
              JURY.
            </p>

            <h4>Class Action Waiver</h4>

            <p>
              Any claim must be brought in the respective party’s individual capacity,
              and not as a plaintiff or class User in any purported class, collective,
              representative, multiple plaintiff, or similar proceeding (“Class
              Action”). The parties expressly waive any ability to maintain any Class
              Action in any forum. The arbitrator shall not have authority to combine
              or aggregate similar claims or conduct any Class Action nor make an
              award to any person or entity not a party to the arbitration. Any claim
              that all or part of this Class Action Waiver is unenforceable,
              unconscionable, void, or voidable may be determined only by a court of
              competent jurisdiction and not by an arbitrator.
            </p>

            <h4>Waiver of Counterclaims</h4>

            <p>
              You agree to waive all rights to interpose any claims, deductions,
              setoffs or counterclaims of any nature (other then compulsory
              counterclaims) in any action or proceeding with respect to these Terms
              of Service or any matter arising therefrom or relating hereto or
              thereto.
            </p>

            <h4>Notification Procedures</h4>

            <p>
              LexShares may provide notifications, whether such notifications are
              required by law or are for marketing or other business related purposes,
              to you via email notice, written or hard copy notice, or through
              conspicuous posting of such notice on the Site, as determined by
              LexShares in our sole discretion. LexShares reserves the right to
              determine the form and means of providing notifications to our Users,
              provided that you may opt out of certain means of notification as
              described in these Terms of Service.
            </p>

            <h4>
              Electronic Signatures in Global and National Commerce Act/Uniform
              Electronic Transactions Act
            </h4>

            <p>
              The Federal Electronic Signatures in Global and National Commerce Act
              (“ESIGN”) and similar state laws, particularly the Uniform Electronic
              Transactions Act (“UETA”), authorize the creation of legally binding and
              enforceable agreements utilizing electronic records and signatures.
              ESIGN and UETA require businesses that want to use electronic records or
              signatures in consumer transactions to obtain the consumer’s consent to
              receive information electronically. When an Issuer or potential investor
              registers on the platform, we obtain his or her consent to transact
              business electronically and maintain electronic records in compliance
              with ESIGN and UETA requirements.
            </p>

            <p>
              Your use of electronic signatures to sign documents legally binds you in
              the same manner as if you had manually signed such documents. The use of
              electronic versions of documents fully satisfies any requirement that
              such documents be provided to you in writing. If you sign
              electronically, you represent that you have the ability to access and
              retain a record of such documents. You agree that you are responsible
              for understanding these documents and agree to conduct business by
              electronic means. You are obligated to review the Site periodically for
              changes and modifications and agree not to contest the admissibility or
              enforceability the Site&apos;s electronically stored copy of this Agreement
              in any proceeding arising out of this Agreement.
            </p>

            <p>
              Although you consent to electronic delivery, you may elect to deliver
              communications by other means and such delivery shall not affect your
              consent. You may revoke consent to electronic delivery of communications
              and receive a paper version at your election. LexShares shall have a
              reasonable period to effect such a change and LexShares may charge you a
              reasonable fee for sending such paper copies. If you elect to use
              electronic delivery, you agree and represent that you have a suitable
              computer with Internet access, an email address and the availability to
              download, save and/or print communications to retain a record of such
              communications. You agree that you are solely responsible for
              maintaining such equipment and services required for online access.
            </p>

            <h4>Entire Agreement/Severability</h4>

            <p>
              These Terms of Service together with any other legal notices and Terms
              of Service published by LexShares via the Service, shall constitute the
              entire Terms of Service between you and LexShares concerning the
              Service. If any provision of these Terms of Service is deemed invalid by
              a court of competent jurisdiction, the invalidity of such provision
              shall not affect the validity of the remaining provisions of these Terms
              of Service, which shall remain in full force and effect.
            </p>

            <h4>No Waiver</h4>

            <p>
              No waiver of any term of these Terms of Service shall be deemed a
              further or continuing waiver of such term or any other term, and
              LexShares&apos; failure to assert any right or provision under these Terms of
              Service shall not constitute a waiver of such right or provision.
            </p>

            <h4>No Agency</h4>

            <p>
              You agree that no agency, partnership or joint venture relationship, or
              that of employer to employee or franchisor to franchisee, exists between
              you and LexShares.
            </p>

            <h4>Miscellaneous</h4>

            <p>
              Headings are for the purpose of reference only and in no way modify,
              amend, or delimit the provisions to which they refer.
            </p>

            <p>
              Notices under these Terms of Service will be sent to&nbsp;<a
                href="mailto:info@lexshares.com"
                >info@lexshares.com</a
              >&nbsp;if to LexShares or the Service, and, if to the User, to the Email
              address provided in the User’s Profile. LexShares may also send notice
              to you by certified mail, return receipt requested, to the address
              indicated in your User Profile.
            </p>

            <p>
              These Terms of Service may be terminated by either party without notice
              at any time for any reason. The appropriate provisions shall reasonably
              survive any termination of these Terms of Service.
            </p>

            <p>
              Please contact us at
              <a href="mailto:info@lexshares.com"> info@lexshares.com</a> with any
              questions regarding these Terms of Service.
            </p>
          </Cell>
        </Grid>
      </Content>
    </Fragment>
  )
}

export default ContentSection;
