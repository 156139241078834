import React, { Fragment, useState } from "react";
import axios from "axios";
import { Cell, Grid } from "react-foundation";
import {
  Banner,
  BannerWave,
  DownloadHeader,
  Form,
  FormContainer,
  InputField,
  InputSubmit,
  Heading,
  Subheading,
  Content,
  Description,
} from "./Banner.style";

const BarometerBanner = ({ csrfToken }) => {
  const [downloaded, setDownloaded] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      barometer_linkedin: { email: email },
      authenticity_token: csrfToken,
    };

    axios
      .post(`/barometer-linkedin`, payload)
      .then(() => {
        setDownloaded(true);
        window.location = "/barometer-linkedin?confirmation=true";
        setErrorMessage("");
      })
      .catch((e) => {
        const shared_ui = new LexShares.SharedUI();
        setErrorMessage(e.response.data.error);
        shared_ui.showAlert("error", errorMessage, false);
      });
  };

  return (
    <Fragment>
      <Banner>
        <BannerWave />
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>The Litigation Funding Barometer</Heading>
              <Subheading>
                A Special Report on What Litigation Funders Want
              </Subheading>
              <Description>
                Which cases appeal to litigation funders, and why? That question
                lies at the heart of this first-of-its- kind resource, which
                uses data collected from more than 30,000 federal and state
                cases to give lawyers and their clients a better understanding
                of how this growing industry really works.
              </Description>
              <DownloadHeader>
                Access a complimentary copy of the report today
              </DownloadHeader>
              <FormContainer>
                <Form onSubmit={ e => { handleSubmit(e) }}>
                  {errorMessage && <div>{errorMessage}</div>}
                  <InputField
                    type="email"
                    disabled={downloaded ? "disabled" : ""}
                    placeholder="yourname@email.com"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    required={true}
                  />
                  <InputSubmit
                    type="submit"
                    disabled={downloaded ? "disabled" : ""}
                    value={downloaded ? "✓ Requested" : "Download"}
                  />
                </Form>
              </FormContainer>
            </Cell>
          </Grid>
        </Content>
      </Banner>
    </Fragment>
  );
};

export default BarometerBanner;
