import styled from 'styled-components'
import * as Globals from '../Globals'

export const Header = styled.div`
  ${Globals.tiempoFontStack};
  font-size: 28px;
  line-height: 35px;
  text-align: center;
`

export const Button = styled(Globals.ButtonLink)`
  display: block;
  margin: 0 auto;
  margin-top: 30px;
`
