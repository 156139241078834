import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import ModalPop from '../../../ModalPop'
import NumberFormat from 'react-number-format'
import axios from 'axios'

import {
  Heading,
  ButtonContainer,
  CancelLink,
  ConfirmButton,
  Clear
} from './InvestmentReducedOffer.style'

function InvestmentReducedOffer({amountRemaining, popVisible,
  cancelHandler, caseId, investmentMethod, paymentMethod, commitReducedHandler,
  csrfToken}) {

  const acceptReducedHandler = (e) => {
    e.preventDefault()
    const payload = {
      court_case_id: caseId,
      investment_method: investmentMethod,
      payment_method: paymentMethod,
      amount: amountRemaining,
      authenticity_token: csrfToken
    }
    commitReducedHandler()
    axios({
      headers: {'Accept': 'application/json'},
      method: 'post',
      responseType: 'json',
      url: '/investments',
      data: payload
    })
     .then(() => {}).catch(() => {
       // Race condition; case is fully funded. Reloads page and shows that
       // it's fully funded. Ideally, we don't reload and just show waitlist pop.
       location.reload()
     })
  }

  const amountRemainingFormatted = 
    <NumberFormat
      value={amountRemaining}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      renderText={value => <span>{value}</span>}
    />

  let content =
    <Fragment>
      <Heading>
         The remaining capacity is now less than your desired investment.
      </Heading>
      <p>
        As a result of extremely high investor demand, the capacity to invest
        in this case has decreased, and is now less than the investment amount
        you inputted. <b>There is now just {amountRemainingFormatted} available.</b>
      </p>
      <p>
        In order to move forward with the new investment amount, please tap &quot;Accept&quot;.
      </p>
      <ButtonContainer>
        <Grid className="align-center">
          <Cell small={12} medium={6} className="small-order-2 medium-order-1">
            <CancelLink onClick={cancelHandler}>Cancel my investment</CancelLink>
          </Cell>
          <Cell small={12} medium={6} className="small-order-1 medium-order-2">
            <ConfirmButton className="js-accept-reduced-button" onClick={acceptReducedHandler}>Accept</ConfirmButton>
          </Cell>
        </Grid>
      </ButtonContainer>
      <Clear />
    </Fragment>

  return (
    <ModalPop
      popVisible={popVisible}
      content={content}
    />
  )
}

export default InvestmentReducedOffer
