import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Why, Content, Heading, Subheading } from './WhySection.style';

function WhySection() {
  return (
    <Why>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Why use it?
              </Heading>
              <Subheading>
                Lawsuits can be exceedingly expensive and progress slowly. Utilizing external law firm funding provides significant growth opportunities for attorneys, law firms and their clients. Not only does it help reduce cash flow volatility, but it offers the flexibility to acquire new clients, retain current clients and pursue new business opportunities.
              </Subheading>
            </Cell>
          </Grid>
        </Section>
      </Content>
    </Why>
  )
}

export default WhySection;
