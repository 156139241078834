import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals';

export const Section = styled.div``;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 20px;
  `}
`;


export const Sidebar = styled.div`
  position: sticky;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 10;

  ${breakpoint('xlarge')`
    top: 140px;
  `}
`

export const Nav = styled.div`
  margin-bottom: 65px;
  width: 210px;
`

export const NavHeading = styled.div`
  color: #8247BA;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 13px;
`

export const NavList = styled.div``

export const NavItem = styled.div`
  color: ${props => props.selected ? props.theme.colors.black : '#979797'};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 13px;
  cursor: pointer;
  position: relative;

  &::after {
    display: ${props => props.selected ? 'block' : 'none'};
    content: '\f0da';
    font-family: 'FontAwesome';
    top: -3px;
    left: -20px;
    margin-left: 5px;
    font-size: 16px;
    position: absolute;
    color: ${props => props.theme.colors.purple}
  }
`

export const FaqSection = styled.div``

export const FaqHeading = styled(Globals.H2)`
  margin-bottom: 30px;
`

export const FaqBody = styled(Globals.P)`
  margin-bottom: 80px;

  li {
    font-weight: 400;
    font-size: inherit;
  }

  ${breakpoint('large')`
    font-size: 21px;
    line-height: 36px;
  `}
`

export const BodyHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  ${breakpoint('large')`
    font-size: 24px;
  `}
`

export const BodySection = styled.div`
  margin-bottom: 20px;
`

export const Link = styled.a`
  color: #9450D4;
  display: block;
  &:hover, &:focus {
    color: #9450D4;
  }
`

export const BodyLink = styled.a`
  color: #8247ba;
  &:hover, &:focus {
    color: #8247ba;
  }
`

export const EndNote = styled.sup`
  font-size: 10px;
  top: -7px;
  margin-right: 5px;
  a {
    font-weight: bold;
    color: #9450D4 !important;
  }
`

export const EndNoteContainer = styled.div`
  margin-top: 30px;
`

export const EndNoteText = styled.p`
  font-size: 14px;
`

export const Quote = styled.div`
  font-size: 28px;
  line-height: 120%;
  color: #000000;
  margin-bottom: 20px;
`

export const QuoteAuthor = styled.div`
  font-size: 18px;
  line-height: 150%;
  color: #8247BA;
  font-weight: bold;
`

export const QuoteAuthorBackground = styled.div`
  font-size: 18px;
  line-height: 150%;
  color: black;
`

export const QuoteContainer = styled.div`
  margin-bottom: 110px;
`

export const DownloadFormContainer = styled.div`
  width: 210px;
`

export const List = styled.ul``

export const ListItem = styled.li`
  -webkit-font-smoothing: antialiased;
`

export const Main = styled.div`
  padding: 0 30px;
`
