import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Intro, Content, Heading, Subheading } from './IntroSection.style';

function IntroSection() {
  return (
    <Intro>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                What is law firm funding?
              </Heading>
              <Subheading>
                Law firm financing, also known as law firm funding, is an innovative way for attorneys and law firms to cover litigation expenses, expand services to clients, and maximize cash flow by using non-recourse capital from a third-party in exchange for a portion of the financial recovery from a portfolio of lawsuits.
              </Subheading>
              <Subheading>
                Litigation funding levels the playing field for litigation participants, by enabling parties with meritorious claims who might otherwise be unable to effectively bring a lawsuit to have the requisite funding for their litigation needs and expenses.
              </Subheading>
            </Cell>
          </Grid>
        </Section>
      </Content>
    </Intro>
  )
}

export default IntroSection;
