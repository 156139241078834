import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import { Section, Content, Heading, Subheading, Link } from './CaseStudiesSection.style';
import CaseStudiesCarousel from '../../case-studies/CaseStudiesCarousel';

function CaseStudiesSection({caseStudies = []}) {
  return (
    <Fragment>
      <Section>
        <Content>
          <Grid>
            <Cell small={12}>
              <Heading>
                Some of our recent investments
              </Heading>
              <Subheading>
                LexShares invests in a wide range of case types - from single commercial matters, to portfolios, to direct law firm fundings. Below is a look at some recently funded claims or you can <Link href="/case-studies"> view all of our case studies here</Link>.
              </Subheading>
              <Grid>
                <CaseStudiesCarousel caseStudies={caseStudies} />
              </Grid>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default CaseStudiesSection;
