import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import Mixpanel from '../../../Mixpanel'
import {
  Heading,
  DocuSign,
  TimeNotice,
  Frame
} from './InvestmentCommitment.style'
import LoadingImg from '../../../../../assets/images/icons/loading.gif'

function InvestmentCommitment({investmentAmount, investmentMethod,
  accreditationMethod, caseId, investorId, signedHandler, signed, trackedData}) {

  const [signingUrl, setSigningUrl] = useState(false)
  const [envelopeId, setEnvelopeId] = useState(null)

  const loadHandler = () => {
    const docuFrameContent = document.querySelector('#docuframe').contentDocument
    if (docuFrameContent) {
      const resultInput = docuFrameContent.querySelector('#docusign_result')
      if (resultInput && (resultInput.value == 1)) {
        Mixpanel.track('Signed DocuSign', trackedData)
        signedHandler(envelopeId)
      }
    }
  }

  useEffect(() => {
    const params = {
      investment_amount: investmentAmount.replace(/[,$]/g,''),
      investment_method: investmentMethod.value,
      accreditations: `${accreditationMethod},`, // tmp hack
    }
    const postUrl = `/pdfs/${caseId}?court_case_id=${caseId}&investor_id=${investorId}`
    axios({
      headers: {'Accept': 'application/json'},
      method: 'get',
      responseType: 'json',
      url: postUrl,
      params: params
    })
     .then(res => {
       setSigningUrl(res.data.url)
       setEnvelopeId(res.data.envelope_id)
       Mixpanel.track('Generated DocuSign', trackedData)
     }).catch(() => {
       console.log('Error generating DocuSign')
     })
  }, [investmentAmount])

  return (
    <Fragment>
      <Heading>Review & Sign</Heading>
      <DocuSign>
        { (signingUrl && !signed) &&
          <Frame id="docuframe" src={signingUrl} onLoad={(e) => loadHandler(e)} />
        }
        { (!signingUrl || signed) &&
            <TimeNotice>
              { signed ?
                  <p>Processing, please wait..</p>
                :
                  <p>Generating investment document packet may take up to 10 seconds.</p>
              }
              <img src={LoadingImg} />
            </TimeNotice> 
        }
      </DocuSign>
    </Fragment>
  )
}

export default InvestmentCommitment
