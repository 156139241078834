import React, { Fragment, useState, useEffect } from "react";
import { Cell, Grid } from "react-foundation";
import InvestmentCardWhite from "../InvestmentCardWhite/InvestmentCardWhite";
import {
  Section,
  Content,
  FilterSection,
  Label,
  Dropdown,
  DropdownSection,
  LoadMoreLink,
} from "./OpenInvestmentCardsSection.style";

const caseTypes = [
  "All",
  "Breach of Contract",
  "Breach of Fiduciary Duty",
  "Law Firm Funding",
  "Other",
];

const OpenInvestmentCardsSection = ({ investments = [] }) => {
  const [lastCaseIndex, setLastCaseIndex] = useState(9);
  const [caseType, setCaseType] = useState("All");
  const [fundingSize, setFundingSize] = useState("All");
  const [fundingType, setFundingType] = useState("All");

  const defaultFilters =
    caseType === "All" && fundingSize === "All" && fundingType === "All";

  const loadMore = (e) => {
    e.preventDefault();
    if (defaultFilters) {
      setLastCaseIndex(lastCaseIndex + 9);
    } else {
      setLastCaseIndex(lastCaseIndex + 6);
    }
  };

  if (investments.length === 0) {
    return null;
  }

  useEffect(() => {
    if (!defaultFilters) {
      setLastCaseIndex(6);
    }
  }, [caseType, fundingSize, fundingType]);

  const filteredInvestments = investments
    .filter((i) => {
      switch (caseType) {
        case "All":
          return true;
        case "Other":
          return ![
            "Breach of Contract",
            "Breach of Fiduciary Duty",
            "Law Firm Funding",
          ]
            .map((e) => e.toLowerCase())
            .includes(i.case_type.toLowerCase());
        default:
          return i.case_type?.toLowerCase().includes(caseType.toLowerCase());
      }
    })
    .filter((i) =>
      fundingType === "All"
        ? true
        : i.funding_type?.toLowerCase() === fundingType.toLowerCase()
    )
    .filter((i) => {
      switch (fundingSize) {
        case "All":
          return true;
        case "200k":
          return (
            i.offering_size_num >= 200000 && i.offering_size_num <= 1000000
          );
        case "1M":
          return (
            i.offering_size_num > 1000000 && i.offering_size_num <= 2000000
          );
        case "2M":
          return (
            i.offering_size_num > 2000000 && i.offering_size_num <= 6000000
          );
        default:
          return false;
      }
    });
  const hasLoadMoreButton = filteredInvestments.length > lastCaseIndex;

  return (
    <Fragment>
      <FilterSection>
        <DropdownSection>
          <Label htmlFor="case_type">Case Type</Label>
          <Dropdown
            width="200"
            name="case_type"
            onChange={(e) => setCaseType(e.target.value)}
          >
            {caseTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Dropdown>
        </DropdownSection>
        <DropdownSection>
          <Label htmlFor="funding_size">Funding Size</Label>
          <Dropdown
            width="130"
            name="funding_size"
            onChange={(e) => setFundingSize(e.target.value)}
          >
            <option value="All">All</option>
            <option value="200k">$200K - $1M</option>
            <option value="1M">$1M - $2M</option>
            <option value="2M">$2M - $6M</option>
          </Dropdown>
        </DropdownSection>
        <DropdownSection>
          <Label htmlFor="funding_type">Investment Type</Label>
          <Dropdown
            width="125"
            name="funding_type"
            onChange={(e) => setFundingType(e.target.value)}
          >
            <option value="All">All</option>
            <option value="single case">Single Case</option>
            <option value="portfolio">Portfolio</option>
          </Dropdown>
        </DropdownSection>
      </FilterSection>
      <Section className="open-investments">
        <Content>
          <Grid className="grid-margin-x grid-margin-y align-center">
            {filteredInvestments
              .slice(0, lastCaseIndex)
              .map((investment, i) => (
                <Cell small={12} large={4} key={i}>
                  <InvestmentCardWhite investment={investment} />
                </Cell>
              ))}
          </Grid>
          {hasLoadMoreButton && (
            <LoadMoreLink href="#" onClick={loadMore}>
              Load More
            </LoadMoreLink>
          )}
        </Content>
      </Section>
    </Fragment>
  );
};

export default OpenInvestmentCardsSection;
