import React, {Fragment, useState, useEffect} from 'react'
import axios from '../../../../Axios'
import Bar from '../../../../Charts/Bar'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text
} from './InvestmentTypeWidget.style'

/**
 * Displays vertical bar chart showing case type spread for all 
 * personal investments.
 */
function InvestmentTypeWidget() {
  let [types, setTypes] = useState(null);

  useEffect(() => {
    axios.get(`/widget/investment-type.json`).then(res => {
      setTypes(res.data)
    })
  }, [])

  return (
    <Widget className="js-investment-type-widget">
      <Content loading={types ? "false" : "true"}>
        { types &&
          <Fragment>
            <Heading>Your Case Investment Types</Heading>
            <DateRange>{types.date_range}</DateRange>
            <Text>
              The following chart represents all of your investments categorized
              by primary case type (some litigations are comprised of multiple
              case types).
            </Text>
            <Bar data={types.types} tipLabel="cases" />
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default InvestmentTypeWidget
