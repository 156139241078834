import React, {Fragment, useState, useEffect} from 'react'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  Text,
  AmountContainer,
  Amount,
  AmountLabel,
  Button
} from './FundSummary.style'

/**
 * Displays fund investment amount and links to fund investment details page.
 */
function FundSummary({fundId}) {
  let [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`/widget/fund-summary/${fundId}.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Widget className="js-fund-summary-widget">
      <Content loading={data ? "false" : "true"}>
        { data &&
          <Fragment>
            <Heading>Your {data.name} Investment</Heading>
            <Text>
              You can view case-by-case investment metrics for {data.name} below:
            </Text>
            <AmountContainer>
              <Amount>{data.amount}</Amount>
              <AmountLabel>
                Your Investment Amount
              </AmountLabel>
            </AmountContainer>
            <Button href={data.details_link} target="_blank">View fund details</Button>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default FundSummary
