import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import { Section, Content, Heading, InvestmentContainer } from './OpenInvestmentsSection.style';
import InvestmentCard from '../InvestmentCard';
import PrefundedPop from '../../PrefundedPop'

function OpenInvestmentsSection({approvedInvestments = [], viewableInvestments = [], csrfToken = null}) {
  const renderInvestmentsCards = () => {
    let investments = viewableInvestments.length > 0 ? viewableInvestments : approvedInvestments
    return investments.map((investment, i) => {
      return (
        <Cell small={12} large={4} key={i}>
          <InvestmentContainer>
            <InvestmentCard {...investment} inverted={true} csrfToken={csrfToken} />
          </InvestmentContainer>
          { (investment.viewable || investment.approved) && investment.prefunding &&
            <Fragment>
              <PrefundedPop smallCopy={true} />
            </Fragment>
          }
        </Cell>
      )
    });
  }

  return (
    <Fragment>
      <Section className="open-investments">
        <Content>
          <Grid className="grid-margin-x align-center">
            <Cell large={12}>
              <Heading>
                { viewableInvestments.length > 0 ? 'Launching soon' : 'Open investments' }
              </Heading>
              <Grid className="align-center">
                { renderInvestmentsCards() }
              </Grid>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default OpenInvestmentsSection;
