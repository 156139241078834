import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from './../../Globals'
import * as SB from './../../SimpleBanner'

export const Banner = styled(SB.Banner)`
`

export const Content = styled(SB.Content)`
  max-width: 1235px;
  margin: 0 auto;
  padding: 0% 7%;
  ${breakpoint('medium')`
    padding: 7% 7%;
  `}
  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`

export const CaseType = styled.span`
  text-transform: uppercase;
  color: ${props => props.theme.colors.purple};
  font-weight: bold;
  font-size: 13px;
  font-family: proxima-nova;
  letter-spacing: 2px;
`

export const Heading = styled(Globals.H2)`
  color: white;
  margin-top: 20px;
`

export const Description = styled.p`
  color: white;
  margin-bottom: 10px;
`

export const Notice = styled.p`
  color: white;
  margin-bottom: 30px;
`

export const NoticeContainer = styled.div`
  margin-bottom: 30px;
`

export const SmallHeading = styled.span`
  color: white;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 16px;
  display: block;
  ${breakpoint('medium')`
    font-size: 14px;
  `}
  ${breakpoint('large')`
    font-size: 20px;
  `}
`

export const SmallDescription = styled.span`
  color: ${props => props.theme.colors.purpleWhite};
  text-transform: capitalize;
  font-size: 16px;
  display: block;
  opacity: .75;
`

export const DealStatus = styled.div`
  color: #A01FE5;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -20px;
`

export const DealInvested = styled.div`
  font-size: 34px;
  font-weight: bold;
  margin: 8px 0;
  ${breakpoint('medium')`
    font-size: 25px;
  `}
  ${breakpoint('large')`
    font-size: 34px;
  `}
`

export const DealInfo = styled.div`
  font-size: 16px;
  color: ${props => props.theme.colors.purpleWhite};
  ${breakpoint('medium')`
    font-size: 12px;
  `}
  ${breakpoint('large')`
    font-size: 16px;
  `}
`

export const ProgressContainer = styled.div`
  max-height: 250px;
  max-width: 250px;
  margin: 0 auto;
  margin-top: 50px;
  ${breakpoint('medium')`
    margin-top: 50px;
  `}
  ${breakpoint('large')`
    margin-top: 30px;
  `}
`
