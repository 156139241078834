import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H2 } from './../../Globals';

export const Categories = styled.div`
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 8% 3%;
  `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
`

export const Category = styled.div`
  border-bottom: 1px solid #9450D4;
  padding: 30px 0;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  ${breakpoint('medium')`
    padding: 55px 0;
  `}

  p, li {
    font-family: 'proxima-nova' !important;
    font-weight: 400 !important;
    -webkit-font-smoothing: antialiased;
  }
`

export const Title = styled(H2)`
  margin-bottom: 30px;

  ${breakpoint('medium')`
    margin-top: -5px;
  `}
`
