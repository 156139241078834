import React from 'react';

const BannerLink = ({link, children}) => {
  const handleClick = () => {
    window.location = link;
  }

  return(
    <div onClick={handleClick}>
      {children}
    </div>
  )
}

export default BannerLink;