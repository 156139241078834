import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import { PersonasSection } from '../../components/funding'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import LearnMoreBanner from '../../components/funding/LearnMoreBanner/LearnMoreBanner';
import ConfirmationSection from '../../components/funding/ConfirmationSection/ConfirmationSection';

const Page = styled.div`
  background-color: ${props => props.theme.colors.lightPurple};
`

class CaseConfirmation extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="transparent" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor} currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <ConfirmationSection fundingCasePath={this.props.fundingCasePath} header="Your funding inquiry is in review." subheading="While our Investments team reviews your submission, we recommend reading our comprehensive, free litigation finance guide." buttonText="Download Now" buttonPath="https://d82jt2cluomp.cloudfront.net/docs/litigation-finance-guide.pdf" />
          <LearnMoreBanner fundingCasePath={this.props.fundingCasePath} />
          <Page>
            <PersonasSection />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default CaseConfirmation;
