import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import theme from '../../config/theme';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Form from '../../components/investors-new/Form/Form'

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`

const Container = styled.div`
  padding-bottom: 75px;
  margin: 25px auto auto auto;
  max-width: 1235px;
  justify-content: center;
  align-items: center;
  padding: 15px 35px 75px 35px;

  ${breakpoint('medium')`
    margin: 80px auto auto auto;
  `}

  ${breakpoint('large')`
    padding: 70px 0px 75px 0px;
  `}
`;

class InvestorsNew extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="trueWhite" roleDashboardPath={this.props.roleDashboardPath} roleProfilePath={this.props.roleProfilePath} userSignedIn={this.props.userSignedIn} isInvestor={this.props.isInvestor} currentRole={this.props.isInvestor} name={this.props.name} csrfToken={this.props.csrfToken} />
          <Page>
            <Container>
              <Form csrfToken={this.props.csrfToken} investor={this.props.investor}/>
            </Container>
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default InvestorsNew;
