import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const Button = styled.button`
  border-radius: 3px;
  background: ${props => props.color || props.theme.colors.purple};
  font-size: 13px;
  letter-spacing: 1px;
  color: white;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  width: 100%;
  margin-top: 20px;
  &:hover {
    background: ${props => props.theme.colors.darkPurple};
    cursor: pointer;
  }
  &:disabled {
    background: ${props => props.disabledColor ? props.disabledColor : props.theme.colors.mediumGrey};
    cursor: default;
  }
  ${breakpoint('medium')`
    max-width: 200px;
    display: block;
    position: relative;
  `}
  ${breakpoint('large')`
    max-width: unset;
  `}
`
