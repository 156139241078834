import styled from 'styled-components'

export const Label = styled.label`
  color: ${props => props.labelColor ? props.labelColor : 'black'};
  font-size: 15px;
`

export const InputContainer = styled.div`
  margin: 0 0 1rem;
  input {
    height: 30px !important;
  }
  .basic-multi-select > div > div:nth-child(1) {
    height: unset;
    overflow: auto;
  }
  .basic-multi-select > div > div > div:nth-child(2) {
    height: 37px !important;
  }
`

export const Hint = styled.p`
  font-size: 12px;
  color: #979797;
`

export const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`
