import styled from 'styled-components'
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.Widget)`
  overflow: auto;
  min-height: 292px;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const Heading = styled(Globals.WidgetHeading)`
`

export const DateRange = styled(Globals.WidgetDateRange)`
`

export const Text = styled(Globals.WidgetText)`
`

export const AmountContainer = styled(Globals.WidgetAmountContainer)`
  float: unset !important;
`

export const Amount = styled(Globals.WidgetAmount)`
`

export const AmountLabel = styled(Globals.WidgetAmountLabel)`
`

export const Button = styled(Globals.ButtonLink)`
`
