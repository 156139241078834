import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import * as Globals from './../../Globals';
import Hero from '../../../../assets/images/law_firm_funding/hero.jpg'


export const Banner = styled(SB.Banner)`
  background: url(${Hero}) no-repeat center center/cover;
  position: relative;
  height: 100vh;
  ${breakpoint('medium')`
    height: 100vh;
  `}
`;

export const BannerContent = styled(SB.Content)`
  position: static;
  padding: 12% 3% 10% 2%;
  ${breakpoint('large')`
    padding: 12% 3% 10% 2%;
  `}
`;

export const BannerProp = styled(SB.Diamonds)`
`;

export const Heading = styled(SB.Heading)`
  font-size: 2rem;
  text-align: center;
  ${breakpoint('medium')`
    text-align: center;
  `}
`;

export const Subheading = styled(SB.P) `
  margin: 20px 0 30px 0;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  ${breakpoint('medium')`
    text-align: center;
  `}
`;

export const Press = styled.div`
  position: absolute;
  bottom: 0;
  background-color: #282331;
  width: 100%;
  left: 0;

  img {
    max-height: 13px;
    margin: 0 10px;

    ${breakpoint('medium')`
      max-height: 18px;
      max-width: 100px;
      max-height: 20px;
      width: auto;
      margin: 0 4.8%;
    `};
  }
`

export const PressContent = styled(SB.Content)`
  text-align: center;
  padding: 1.5% 0 !important;
  max-width: 1600px;
`

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
  ${breakpoint('medium')`
    min-width: 200px;
    width: unset;
  `};
`
