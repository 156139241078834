import styled from 'styled-components'

const Images = require.context('../../../assets/images', true)

export const ListContainer = styled.ul`
  list-style-type: none;
  margin-left: 30px;
`

export const ListItem = styled.li`
  color: white;
  margin-bottom: 20px;
  &::before {
    content: " ";
    background: url(${() => Images("./icons/checkmark.png").default}) no-repeat center center/cover;
    position: absolute;
    margin-left: -30px;
    margin-top: 5px;
    height: 15px;
    width: 15px;
  }
`
