import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import { P, ButtonLink, H2 } from '../../Globals'
import theme from '../../../config/theme';

export const Container = styled.div`
  position: relative;
  padding-top: 20px;
  margin: 0 auto;

  ${breakpoint('medium')`
    padding-top: 80px;
  `};
`

export const Content = styled.div`
  padding-bottom: 0;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 15px;

  ${breakpoint('medium')`
    padding: 80px 30px;
    max-width: 800px;
  `};
`

export const Paragraph = styled(P)`
  margin-bottom: 20px;
  font-size: 14px !important;
  line-height: 21px !important;
  padding: 0px 25px;

  ${breakpoint('medium')`
    padding: 0px 75px;
    font-size: 18px !important;
    line-height: 24px !important;
  `};
`

export const Heading = styled(H2)`
  margin-bottom: 30px;
  text-align: center;
`

export const Button = styled(ButtonLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageContainer = styled.div`
  display: inline-block;
  vertical-align: top;

  img {
    width: 50px;
    height: 50px;
    border-radius: 500px;
    border: 0.25px solid ${theme.colors.lightGrey4};
  }
`;

export const InfoContainer = styled.div`
  display: inline-block;
  padding: 8px 0px 0px 10px;
`;

export const Name = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`

export const Title = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`