import React, { useState } from 'react'
import { Menu } from 'react-foundation'

import { LearnMoreSection, LogoLink, MenuBar, MenuBox, MenuContainer, MenuButton, MobileTopBar, MenuTopBar, TopBar, TopBarLogo, TopBarLogoLeft, TopBarRight, LearnMoreButton, Subheading, Header } from './Navbar.style';
import SlideRight from '../../SlideRight';
import Sidenav from '../Sidenav/Sidenav';
import StaticImage from '../../StaticImage';
import Mixpanel from '../../Mixpanel'

const Navbar = ({ caseStudiesPath, fundingAttorneysPath, fundingPlaintiffsPath, fundingGeneralCounselPath, fundingCasePath, rootPath, fundingInsightsPath, regulationsTrendPath, attorneyResourceCenterPath, slipOpinionPath, hideSlipOpinionCta, whitepapersPath }) => {
  const [active, setActive] = useState(false);
  const toggleMenu = (val) => {
    setActive(val)
    if (val) {
      $('body').css('overflow', 'hidden')
    } else {
      $('body').css('overflow', 'visible')
    }
  }

  const trackClick = event => {
    Mixpanel.track(event)
  }

  return (
    <MenuContainer>
      <MobileTopBar shown={active.toString()}>
        <TopBarLogo>
          <TopBarLogoLeft>
            {active || <MenuButton onClick={() => toggleMenu(true)} >
              <MenuBar />
              <MenuBar />
              <MenuBar />
            </MenuButton>}
            {active && <MenuButton onClick={() => toggleMenu(false)} style={{ marginLeft: '8px', marginTop: '2px' }}>
              <StaticImage src="icons/x-icon.png" styled={{ width: '22px', height: '22px' }} />
            </MenuButton>}
          </TopBarLogoLeft>
          <MenuBox>
            <Menu style={{ justifyContent: 'center' }}>
              <LogoLink href={caseStudiesPath} onClick={() => trackClick('Clicked "Attorney Resource Center" home button')} />
              <Header href={caseStudiesPath} onClick={() => trackClick('Clicked "Attorney Resource Center" home button')}>Attorney Resource Center</Header>
            </Menu>
          </MenuBox>
        </TopBarLogo>
      </MobileTopBar>

      <TopBar>
        <TopBarLogo>
          <Menu style={{ justifyContent: 'center' }}>
            <LogoLink href={caseStudiesPath} />
            <Header href={caseStudiesPath} onClick={() => trackClick('Clicked "Attorney Resource Center" home button')}>Attorney Resource Center</Header>
          </Menu>
        </TopBarLogo>

        <TopBarRight>
          {!active && !hideSlipOpinionCta &&
            <LearnMoreSection>
              <Subheading>Join the 20,000+ attorneys who read The Slip Opinion.</Subheading>
              <LearnMoreButton href={slipOpinionPath} onClick={() => trackClick('Clicked Slip Opinion "learn more"')}>Learn More</LearnMoreButton>
            </LearnMoreSection>}
          {active && <Sidenav caseStudiesPath={caseStudiesPath} fundingAttorneysPath={fundingAttorneysPath} fundingPlaintiffsPath={fundingPlaintiffsPath} fundingGeneralCounselPath={fundingGeneralCounselPath} fundingCasePath={fundingCasePath} rootPath={rootPath} fundingInsightsPath={fundingInsightsPath} regulationsTrendPath={regulationsTrendPath} attorneyResourceCenterPath={whitepapersPath} hoverMenuDisabled={true} whitepapersPath={whitepapersPath} />}
        </TopBarRight>
      </TopBar>
      <SlideRight show={active}>
        <MenuTopBar>
          <TopBarLogo>
            <Menu style={{ justifyContent: 'center' }}>
              <LogoLink href={caseStudiesPath} onClick={() => trackClick('Clicked "Attorney Resource Center" home button')} />
              <Header href={caseStudiesPath} onClick={() => trackClick('Clicked "Attorney Resource Center" home button')}>Attorney Resource Center</Header>
            </Menu>
          </TopBarLogo>

          <TopBarRight>
            {!active && !hideSlipOpinionCta &&
              <LearnMoreSection>
                <Subheading>Join the 20,000+ attorneys who read The Slip Opinion.</Subheading>
                <LearnMoreButton href={slipOpinionPath} onClick={() => trackClick('Clicked Slip Opinion "learn more"')}>Learn More</LearnMoreButton>
              </LearnMoreSection>}
            {active && <Sidenav caseStudiesPath={caseStudiesPath} fundingAttorneysPath={fundingAttorneysPath} fundingPlaintiffsPath={fundingPlaintiffsPath} fundingGeneralCounselPath={fundingGeneralCounselPath} fundingCasePath={fundingCasePath} rootPath={rootPath} fundingInsightsPath={fundingInsightsPath} regulationsTrendPath={regulationsTrendPath} attorneyResourceCenterPath={whitepapersPath} hoverMenuDisabled={true} whitepapersPath={whitepapersPath} />}
          </TopBarRight>
        </MenuTopBar>
      </SlideRight>
    </MenuContainer>
  )
}

export default Navbar;
