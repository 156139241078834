import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';
import * as Globals from '../../Globals';

export const Section = styled(SB.Banner)`
  overflow: visible;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${breakpoint('large')`
    padding: 0;
  `}
`;

export const Content = styled(SB.Content)`
  max-width: 1235px;
  text-align: left;
  position: unset;
  padding: 0% 7% 7% 7%;

  ${breakpoint('medium')`
    padding: 11% 7% 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 8% 3% 10% 2%;
  `}
`;

export const BannerProp = styled(SB.Diamonds)``;

export const MediaContent = styled.div`
  width: 500px;
  max-width: 100%;
  margin-top: 8%;
  position: relative;

  ${breakpoint('medium')`
    justify-content: left;
  `}
`

export const Heading = styled(SB.Heading)`
  margin-top: 5%;
  margin-bottom: 20px;
  font-size: 32px;
  color: white;
`;

export const Subheading = styled(SB.Subheading)`
  margin-bottom: 40px;
  color: white;
`;

export const Container = styled.div`
  ${breakpoint('large')`
    padding-right: 40px;
  `}
`

export const Button = styled(Globals.ButtonLink)`
  width: 100%;

  ${breakpoint('medium')`
    width: unset;
  `}
`

export const SecondaryButton = styled(Globals.ButtonWhite)`
  width: 100%;
  margin-top: 20px;

  ${breakpoint('medium')`
    width: unset;
    margin-left: 20px;
    margin-top: 0px;
  `}
`

