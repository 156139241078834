import styled from "styled-components";
import * as Globals from "../../Globals";
import { breakpoint } from "styled-components-breakpoint";
import theme from "../../../config/theme";

export const Container = styled.div`
  ${breakpoint("medium")`
    padding: 60px 40px;
  `}

  ${breakpoint("large")`
    padding: 80px;
  `}
`;

export const FormContainer = styled.div`
  background-color: rgb(248, 247, 250);
  padding: 60px 20px 80px 20px;

  ${breakpoint("medium")`
    padding: 60px 60px 80px 60px;
  `}
`;

export const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
`;

export const Heading = styled(Globals.H6)`
  ${Globals.tiempoFontStack};
  font-size: 32px !important;
  line-height: 42px !important;
  font-weight: bold !important;
  color: ${theme.colors.backdropPurple};
  margin-bottom: 20px;
  text-align: center;
`;
