import styled from 'styled-components';
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  padding: 7% 7%;
  padding-bottom: 75px;
  max-width: 1235px;
  margin: 0 auto;

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Form = styled.form`
`;

export const InputSubmit = styled.input`
  border-radius: 3px;
  background: ${theme.colors.veryLightPurple};
  font-size: 13px;
  letter-spacing: 2px !important;
  color: #fefefe;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  background-color: ${theme.colors.teal};
  margin: 20px 0px 16px 0px;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: ${theme.colors.mutedTeal};
  }

  ${breakpoint('medium')`
    width: auto;
  `}

  ${breakpoint('large')`
    letter-spacing: 1px;
  `}
`;

export const Error = styled.small`
  background-color: white;
  color: #bd0000;
  padding: 0px;
  font-style: normal;
  font-size: 1rem;
  margin-top: -15px;
  margin-bottom: 5px;
  display: block;
`;

export const Alert = styled.div`
  background-color: ${theme.colors.veryLightPurple};
  border-color: ${theme.colors.veryLightPurple};
  margin-bottom: 30px;
  border-radius: 3px;
  padding: 20px;
  color: white;
`;