import styled from 'styled-components'
import * as Globals from '../Globals'

export const PrefundingNotice = styled.div`
  ${({ small }) => small && `
    clear: both;
    font-size: 14px;
    text-align: center;
    color: white;
  `}
`

export const Text = styled.p`
  color: black;
  font-size: 16px;
`

export const ModalHeading = styled(Globals.H2)`
  color: black !important;
  text-align: left !important;
  font-size: 35px !important;
`
