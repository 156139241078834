import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import { Container, DiamondBackground, Breadcrumb, BreadcrumbItem, BreadcrumbItemLink, Header, ImageContainer, SlipOpinionContainer, Subheading } from './SlipOpinionCta.style';
import SubscribeForm from '../SubscribeForm/SubscribeForm'
import SlipSampleImg from '../../../../assets/images/slip_opinion/slip-opinion-sample.png';

const SlipOpinionCta = ({attorneyResourceCenterPath, csrfToken, slipOpinionRequestsPath}) => {
  return(
    <Fragment>
      <Container>
        <Grid>
          <Cell className="small-order-2 medium-order-1" small={12} medium={6}>
          <Breadcrumb>
            <BreadcrumbItemLink href={attorneyResourceCenterPath}>Home</BreadcrumbItemLink>&nbsp;—&nbsp;
            <BreadcrumbItem>The Slip Opinion</BreadcrumbItem>
          </Breadcrumb>
          <Header>Join 20,000+ attorneys who use The Slip Opinion to stay informed.</Header>
          <Subheading>Comprehensive litigation funding news and insights, delivered to your inbox every month.</Subheading>
          <SubscribeForm csrfToken={csrfToken} slipOpinionRequestsPath={slipOpinionRequestsPath} />
          </Cell>
          <Cell className="small-order-1 medium-order-2" small={12} medium={6}>
            <SlipOpinionContainer>
              <DiamondBackground />
              <ImageContainer>
                <img src={SlipSampleImg} />
              </ImageContainer>
            </SlipOpinionContainer>
          </Cell>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default SlipOpinionCta;