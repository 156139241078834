import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { H2, Description } from './../../Globals';
import * as SB from './../../SimpleBanner';

export const Banner = styled(SB.Banner)`
`;

export const BannerContent = styled(SB.Content)`
`;

export const BannerProp = styled(SB.Diamonds)`
`;

export const StatsContent = styled(SB.Content)`
  padding: 0 3%;
  max-width: 1235px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  ${breakpoint('medium')`
    padding: 0 3%;
  `}
`;

export const Heading = styled(SB.Heading)`
  ${breakpoint('medium')`
    text-align: center;
  `}
`;

export const Value = styled(H2)`
  padding: 6px;
  margin-bottom: 0;
`;

export const Stats = styled.div`
  background: ${props => props.theme.colors.backdropPurple};
  text-align: center;
`;

export const Label = styled(Description)`
  margin-bottom: 0;
  padding: 0 5px;
  line-height: 130%;
`;

export const Metric = styled.div`
  margin-bottom: 36px;
`;
