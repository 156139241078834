import React, { Fragment } from 'react';
import styled from 'styled-components';
import EmployeeCard from '../EmployeeCard';

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

function EmployeeList(props) {
  return (
    <Fragment>
      {
        props.employees.map((e, i) => (
          <Fragment key={e.id}>
            <EmployeeCard {...e} />
            {
              (i === 14) &&  <Break/>
            }
          </Fragment>
        ))
      }
    </Fragment>
  )
}

export default EmployeeList;
