import React, {Fragment} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import theme from '../../config/theme'
import {Content} from './InvestorDashboard.style'
import Navbar from '../../components/investor-dashboard/Navbar'
import Footer from '../../components/Footer'
import ActivityView from '../../components/investor-dashboard/ActivityView'
import CaseUpdateView from '../../components/investor-dashboard/CaseUpdateView'
import FundUpdateView from '../../components/investor-dashboard/FundUpdateView'
import PortfolioView from '../../components/investor-dashboard/PortfolioView'
import AlertBar from '../../components/AlertBar'
import TabControl from '../../components/TabControl'
import Mixpanel from '../../components/Mixpanel'

const Page = styled.div`
  background-color: ${props => props.theme.colors.nightPurple};
  padding-top: ${props => props.hasAlert ? '37px' : '0'};
`

/**
 * This is the the top level dashboard component that keeps state of
 * which views are currently visible. It's responsible for loding the
 * widget that corresponds to the current view.
 */
class InvestorDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.handleViewChange = this.handleViewChange.bind(this)
    this.handleSubViewChange = this.handleSubViewChange.bind(this)
    this.defaultView = this.defaultView.bind(this)
    this.defaultSubView = this.defaultSubView.bind(this)
    this.setAlertBar = this.setAlertBar.bind(this)
    this.updateUnseenNotifications = this.updateUnseenNotifications.bind(this)

    // Override default view if passed in the url as ref
    let views = TabControl.getViews(window)
    let currentView = views[0] ? views[0] : this.defaultView()
    let currentSubView = views[1] ? views[1] : this.defaultSubView()

    // Sends user back to default view on refresh and strips the anchor.
    if (TabControl.isRefresh(window)) {
      currentView = this.defaultView()
    }

    this.state = {
      currentView: currentView,
      currentSubView: currentSubView,
      hasAlert: false,
      alertMessage: '',
      unseenNotifications: this.props.investor.unseen_notifications
    }
  }

  /**
   * Sets the default view based on investor activity
   */
  defaultView() {
    // Defaults to activity feed if no investments or there are currently fundable cases
    if (this.props.investor.fundable_cases || (this.props.investor.has_any_investments == 0)) {
      return 'activity feed'
    }
    // Also defaults to activity feed if less than 3 investments and no LMF
    else if ((this.props.investor.investment_count < 3) && !this.props.investor.has_lmf2_investment && !this.props.investor.has_lmf1_investment ) {
      return 'activity feed'
    }
    // Defaults to investment porfolio for investors with enough investments
    else {
      return 'investment portfolio'
    }
  }

  /**
   * Sets the deafult subview based on investor activity
   */
  defaultSubView() {
    let subView = 'lexshares'

    if (this.props.investor.investment_count > 0) {
      subView = 'personal'
    }
    else if (this.props.investor.has_lmf2_investment) {
      subView = 'fund ii'
    }
    else if (this.props.investor.has_lmf1_investment) {
      subView = 'fund i'
    }
    
    return subView
  }

  /**
   * Sets alert bar after an event
   */
  setAlertBar(message) {
    this.setState({hasAlert: true, alertMessage: message})
  }

  /**
   * Responsible for navigating top level dashboard views
   * activity feed, investment portfolio, case updates
   */
  handleViewChange(event, toggleMenu, tab) {
    if (event) {
      event.preventDefault()
    }
    if (toggleMenu) {
      toggleMenu(false)
    }
    const viewName = tab ? tab : event.target.dataset.name
    if (viewName != undefined) {
      this.setState({currentView: viewName})
    }
    // Snaps to top after any view change
    window.scrollTo(0, 0)
  }

  /**
  * Responsible for navigating tab of individual top
  * level views
  */
  handleSubViewChange(event) {
    event.preventDefault()
    const subViewName = event.target.getAttribute('data-name').toLowerCase()
    this.setState({currentSubView: subViewName})
    // Snaps to top after any view change
    window.scrollTo(0, 0)
  }

  /**
   * Sets the number of unseeen notifications in the nav
   */
  updateUnseenNotifications(count) {
    this.setState({unseenNotifications: count})
  }

  /**
   * Initiate tracking
   */
  componentDidMount() {
    Mixpanel.identify(this.props.investor.user_id)
  }

  render () {
    return (
      <Fragment>
        { this.state.hasAlert &&
          <AlertBar message={this.state.alertMessage} />
        }
        <ThemeProvider theme={theme}>
          <Navbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
            clickHandler={this.handleViewChange}
            selected={this.state.currentView}
            hasInvestments={this.props.investor.has_investments}
            hasTaxDocs={this.props.investor.has_tax_docs}
            visibleAlert={this.state.hasAlert}
            unseenNotifications={this.state.unseenNotifications}
            lmf1_investor={this.props.investor.lmf1_investor}
            lmf2_investor={this.props.investor.lmf2_investor}
            has_lmf2_access={this.props.investor.has_lmf2_access}
          />
          <Page hasAlert={this.state.hasAlert}>
            <Content>
              { this.state.currentView == 'activity feed' &&
                <ActivityView
                  onboarded={this.props.investor.onboarded}
                  completedStep={this.props.investor.completed_step}
                  completedPercent={this.props.investor.completed_percent}
                  articles={this.props.articles}
                  courtCases={this.props.court_cases}
                  csrfToken={this.props.csrfToken}
                  setAlertBar={this.setAlertBar}
                  userId={this.props.investor.user_id}
                  viewClickHandler={this.handleViewChange}
                  investmentCount={this.props.investor.investment_count}
                />
              }
              { this.state.currentView == 'investment portfolio' &&
                <PortfolioView
                  tabClickHandler={this.handleSubViewChange}
                  currentTab={this.state.currentSubView}
                  hasInvestments={this.props.investor.has_investments}
                  userId={this.props.investor.user_id}
                  investmentCount={this.props.investor.investment_count}
                  extendedCaseVisibility={this.props.investor.extended_case_visibility}
                />
              }
              { this.state.currentView == 'case updates' &&
                <CaseUpdateView
                  investorId={this.props.investor.id}
                  csrfToken={this.props.csrfToken}
                  viewClickHandler={this.handleViewChange}
                  updateUnseenNotifications={this.updateUnseenNotifications}
                  totalNotifications={this.state.unseenNotifications}
                  investmentCount={this.props.investor.investment_count}
                />
              }
              { this.state.currentView == 'fund 1 updates' &&
                <FundUpdateView
                  investorId={this.props.investor.id}
                  csrfToken={this.props.csrfToken}
                  viewClickHandler={this.handleViewChange}
                  totalNotifications={this.state.unseenNotifications}
                  investmentCount={this.props.investor.investment_count}
                  fund={1}
                />
              }
              { this.state.currentView == 'fund 2 updates' &&
                <FundUpdateView
                  investorId={this.props.investor.id}
                  csrfToken={this.props.csrfToken}
                  viewClickHandler={this.handleViewChange}
                  totalNotifications={this.state.unseenNotifications}
                  investmentCount={this.props.investor.investment_count}
                  fund={2}
                />
              }
            </Content>
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    )
  }
}

export default InvestorDashboard
