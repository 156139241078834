import styled from "styled-components";
import * as Globals from "../../Globals";
import breakpoint from "styled-components-breakpoint";
import theme from "../../../config/theme";

export const Container = styled.div`
  padding: 20px;
  background-color: ${theme.colors.creamWhite};

  ${breakpoint("medium")`
    padding: 40px;
  `}
`;

export const Heading = styled(Globals.H1)`
  margin-bottom: 10px;
  text-align: left;
  font-size: 28px;

  ${breakpoint("medium")`
    font-size: 54px;
  `}
`;

export const Subheading = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: ${theme.colors.backdropPurple};
  font-family: "proxima-nova" !important;
  margin-bottom: 20px;
  text-align: left;

  ${breakpoint("medium")`
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 50px;
  `}
`;

export const Card = styled.a``;

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 10px;

  ${breakpoint("medium")`
    margin-bottom: 15px;
  `}
`;

export const Image = styled.img`
  border-radius: 5px;
  object-fit: cover;
  height: 166px;
  width: 100%;
`;

export const Tag = styled.p`
  font-family: "proxima-nova" !important;
  font-size: 11px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.purpleGrey};
  margin-bottom: 5px;

  ${breakpoint("medium")`
    margin-bottom: 10px;
  `}
`;

export const CaseTitle = styled(Globals.H3)`
  font-family: "TiemposHeadlineWeb" !important;
  line-height: 32px;
  font-weight: 400;
  color: ${theme.colors.backdropPurple};
  margin-bottom: 0px;

  ${breakpoint("medium")`
    margin-bottom: 8px;
  `}
`;
