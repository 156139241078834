import * as yup from 'yup'
import React, { useState } from 'react';
import { Formik } from 'formik'
import { get } from 'lodash';
import InputRadio from '../../Form/InputRadio'
import InputButton from '../../Form/InputButton';
import { Grid, Cell } from 'react-foundation';
import ProgressBar from '../../ProgressBar';
import axios from 'axios'
import { BackButton, ButtonsContainer, Container, ErrorMessage, FinishProfileLaterContainer, FinishProfileLaterLink, FormContainer, Heading, Subheading, Link } from './OnboardingStepThree.style';
import FocusError from '../../FocusError/FocusError';

const OnboardingStepThree = ({csrfToken, investor, setOnboardingStep, accountTypes, setCurrentInvestor}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    accreditation: yup.string().required()
  })
  .test(
    'atLeastOneChecked',
    null,
    (obj) => {
      if (obj.accreditation) {
        return true;
      } else {
        return new yup.ValidationError(
          'you must select at least one option.',
          null,
          'accreditationCount'
        )
      }
    }
  );
  
  
  const defaultValues = {
    accreditation: get(investor, 'accreditation') || '',
  }

  const handleSubmit = ({ accreditation }) => {
    setIsSubmitting(true);
    const payload = {
      investor: {
        accreditation: accreditation,
        has_completed_profile: 0,
      },
      authenticity_token: csrfToken
    }

    axios.patch(`/investors/${investor.id}.json`, payload).then((response) => {
      setOnboardingStep(4);
      setCurrentInvestor(response.data.investor);
    }).catch((e) => {
      setIsSubmitting(false);
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    })
  }

  const handleBack = () => {
    const { account_individual, account_entity, account_trust, account_ira } = accountTypes
    account_individual && !account_entity && !account_trust && !account_ira ? setOnboardingStep(2) : setOnboardingStep(2.5)
  }

  return(
    <Container>
      <ProgressBar step={3} steps={5} percent={60} />
      <Heading>Accreditation</Heading>
      <Subheading>
        You must be <Link target="_blank" href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3">accredited</Link> to invest in cases on the LexShares platform.<br/>
        <strong>Select one of the below qualifications.</strong>
      </Subheading>
      <Formik
        initialValues={defaultValues}
        onSubmit={(e) => handleSubmit(e) }
        validationSchema={schema}
      >
        {
          ({ errors, handleSubmit, handleChange, values  }) =>
            <form onSubmit={handleSubmit}>
              <FocusError />
              <FormContainer>
                <InputRadio
                  label="Income"
                  description="I made $200,000 or more in each of the two most recent
                  years and have the expectation to make at least that amount this year or I have a joint
                  income with my spouse that has exceeded $300,000 for each of the last 2 years and I expect
                  it will exceed that this year."
                  name="accreditation"
                  value="income"
                  selected={values.accreditation === 'income'}
                  changeHandler={handleChange}
                />
                <InputRadio
                  label="Net Worth"
                  description="I have an individual net worth, or joint net worth
                  with my spouse, that exceeds $1,000,000 today (excluding my primary residence)."
                  name="accreditation"
                  value="net_worth"
                  selected={values.accreditation === 'net_worth'}
                  changeHandler={handleChange}
                />
                <InputRadio
                  label="Investment for Business"
                  description="I invest on behalf of an entity with at least $5,000,000 in assets or in which all the equity owners are accredited."
                  name="accreditation"
                  value="investment_for_business"
                  selected={values.accreditation === 'investment_for_business'}
                  changeHandler={handleChange}
                />
                {Object.keys(errors).length > 0 && <ErrorMessage>{Object.values(errors).join(', ')}</ErrorMessage>}
            </FormContainer>
              <ButtonsContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6} className="medium-order-1 small-order-2">
                    <BackButton onClick={handleBack}>Back</BackButton>
                  </Cell>
                  <Cell small={12} medium={6} className="medium-order-2 small-order-1">
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value={isSubmitting ? 'Submitting..' : 'Continue'}
                      name="submit-button"
                      disabled={isSubmitting}
                    />
                  </Cell>
                </Grid>
              </ButtonsContainer>
            </form>
        }
      </Formik>
      <FinishProfileLaterContainer>
        <FinishProfileLaterLink href="/dashboard">Finish Profile Later</FinishProfileLaterLink>
      </FinishProfileLaterContainer>
    </Container>
  )
}

export default OnboardingStepThree;