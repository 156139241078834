import React from 'react';
import { Container, ImageContainer } from './CaseStudyCard.style';
import InvestmentCardWhite from '../funding/InvestmentCardWhite';

const CaseStudyCard = ({caseType, fundingType, caseFundingAmount, caseDescription, iconPath, iconPosition}) => {
  const investment = {
    case_type: caseType,
    long_offering_size: caseFundingAmount,
    case_description: caseDescription,
    funding_type: fundingType
  }

  return(
    <Container>
      <InvestmentCardWhite investment={investment}/>
      <ImageContainer iconPosition={iconPosition}>
        <img src={iconPath} />
      </ImageContainer>
    </Container>
  )
}

export default CaseStudyCard;