import styled, { css, keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import darkDiamondsBg from '../../../assets/images/backgrounds/diamond-array-dark-purple.png'
import loaderIcon from '../../../assets/images/icons/ajax-loader.gif'
import logoIcon from '../../../assets/images/logos/lexshares-logo-icon.png'

export const tiempoFontStack = css`
  font-family: 'TiemposHeadlineWeb' !important;
`;

export const proximaFontStack = css`
  font-family: 'proxima-nova' !important;
`;


export const Text = styled.p`
  margin: 26px 0px;
  color: black;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  -webkit-font-smoothing: antialiased
`

export const H1 = styled.h1`
  ${tiempoFontStack};
  font-size: 24px;
  line-height: 32px;
  ${breakpoint('medium')`
    font-size: 38px;
    line-height: 42px;
  `}
  ${breakpoint('large')`
    font-size: 54px;
    line-height: 64px;
  `}
`;

export const H2 = styled.h2`
  ${tiempoFontStack};
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  ${breakpoint('medium')`
    font-size: 28px;
    line-height: 43px;
  `}
  ${breakpoint('large')`
    font-size: 36px;
    line-height: 44px;
  `}
`;

export const H3 = styled.h3`
  ${tiempoFontStack};

  font-size: 21px;
  line-height: 25px;

  ${breakpoint('medium')`
    font-size: 28px;
    line-height: 35px;
  `}
  ${breakpoint('large')`
    font-size: 32px;
    line-height: 40px;
  `}

`;

export const H4 = styled.h4`
  ${proximaFontStack};
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 100%;

  ${breakpoint('medium')`
    font-size: 32px;
    line-height: 120%;
  `}
`;

export const H5 = styled.h5`
  ${proximaFontStack};
  font-size: 28px;
  line-height: 120%;
`;

export const H6 = styled.h6`
  ${proximaFontStack};
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;

  ${breakpoint('medium')`
    font-size: 24px;
    line-height: 24px;
  `}
`;

export const P = styled.div`
  ${proximaFontStack};
  font-size: 16px;
  line-height: 22px;

  ${breakpoint('medium')`
    font-size: 18px;
    line-height: 24px;
  `}
  ${breakpoint('large')`
    font-size: 21px;
    line-height: 29px;
  `}
`;

export const Body = styled.div`
  ${proximaFontStack};
  font-size: 18px;
  line-height: 150%;
`

export const Description = styled.div`
  ${proximaFontStack};
  font-size: 16px;
  line-height: 25px;
`;

export const ButtonPrimary = styled.button`
  border-radius: 3px;
  background: #914cde;
  font-size: 13px;
  letter-spacing: 1px;
  color: #fefefe;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #fefefe;
    background: #554267;
  }
  &:focus {
    color: #fefefe;
  }
`

export const ButtonLink = styled.a`
  border-radius: 3px;
  background: #914cde;
  font-size: 13px;
  letter-spacing: 1px;
  color: #fefefe;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #fefefe;
    background: #554267;
  }
  &:focus {
    color: #fefefe;
  }
`

export const ButtonLinkInverse = styled.a`
  border-radius: 3px;
  background-color: #fefefe;
  font-size: 13px;
  letter-spacing: 1px;
  color: #914cde;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #914cde;
    background-color: #fefefe;
  }
  &:focus {
    color: #914cde;
  }
`

export const ButtonInverse = styled(ButtonLink)`
  background-color: transparent !important;
  color: #8247ba;
  border: 2px solid #8247ba;
  padding: 13px 24px 13px 24px;
  &:hover {
    background-color: transparent !important;
    color: #8247ba;
    opacity: 0.7;
  }
`;

export const ButtonWhite = styled(ButtonLink)`
  background-color: transparent !important;
  color: white;
  border: 2px solid white;
  padding: 13px 24px 13px 24px;
  &:hover {
    background-color: transparent !important;
    color: white;
    opacity: 0.7;
  }
`;

export const ButtonSecondaryInverse = styled.button`
  border: 2px solid #007095;
  border-radius: 3px;
  background: transparent;
  font-size: 13px;
  letter-spacing: 1px;
  color: #007095;
  font-weight: 600;
  line-height: 21px;
  padding: 15px 24px 15px 24px;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-font-smoothing: auto;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #007095;
    background: transparent;
  }
  &:focus {
    color: #007095;
  }

  ${breakpoint('medium')`
    max-width: 200px;
    display: block;
    position: relative;
  `}
  ${breakpoint('large')`
    max-width: unset;
  `}
`

export const Link = styled.a`
  color: ${props => props.theme.colors.purple};
  &:hover {
    color: #554267;
  }
  &:active, &:visited, &:focus {
    color: ${props => props.theme.colors.purple}
  }
`

export const SmallText = styled.p`
  font-size: 12px;
  color: #979797;
  text-align: center;
  margin-top: 15px;
  > a {
    &::after {
      margin-left: 5px;
      content: "\u2192";
    } 
  }
`

export const Section = styled.div`
  padding: 7% 7%;
  margin: 0 auto;
  position: relative;
  max-width: 1235px;
  ${breakpoint('large')` 
    padding: 5% 2%;
  `}
`

export const DarkDiamonds = styled.div`
  background: url(${darkDiamondsBg}) no-repeat center;
  height: 400px;
  width: 400px;
  position: absolute;
  right: 0;
  bottom: 0;
`

// Widget related

export const Widget = styled.div`
  padding: 0;
  background: ${props => props.loading == "true" ? `url(${loaderIcon}) no-repeat center center` : 'none'};
  border: ${props => props.highlight ? '2px solid #8247BA' : '0px'};
  background-color: ${props => props.highlight ? '#190E26' : '#1E1924'};
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 31px 31px;
  position: relative;
  -webkit-font-smoothing: antialiased;
`

export const ExpandableWidget = styled(Widget)`
  padding-bottom: 0px;
  ${({ hide }) => hide && `
    height: 360px;
    overflow: hidden;
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      content: "";
      background: linear-gradient(to top,
         rgba(30, 25, 36, 1) 20%,
         rgba(30, 25, 36, 0) 40%
      );
    }
 `}

  ${({ highlight }) => highlight && `
    &::after {
      background: linear-gradient(to top,
         rgba(25, 14, 38, 1) 20%,
         rgba(25, 14, 38, 0) 40%
      );
    }
 `}

  ${({ bottomOffset }) => bottomOffset && `
    padding-bottom: 31px;
  `}
`

export const WidgetExpander = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;
  text-align: center;
  background-color: #1c1524;
  color: white;
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  z-index: 99;
  cursor: pointer;

  ${({ collapsed }) => collapsed && `
    &::after {
      position: relative;
      content: ' ';
      width: 12px;
      height: 12px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
      display: block;
      margin: 0 auto;
      left: 65px;
      top: -18px;
    }
 `}

  ${({ expanded }) => expanded && `
    margin-top: 30px;
    &::after {
      position: relative;
      content: ' ';
      width: 12px;
      height: 12px;
      border-top: 2px solid white;
      border-left: 2px solid white;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      display: block;
      margin: 0 auto;
      left: 65px;
      top: -12px;
    }
 `}
`

export const WidgetLogo = styled.div`
  background: url(${logoIcon}) no-repeat center center/cover;
  display: inline-block;
  height: 24px;
  width: 20px;
  padding: 0;
  margin-right: 10px;
  float: left;
`

export const WidgetTitleBar = styled.div`
  padding: 21px 31px;
  margin-left: -18px;
  ${breakpoint('medium')`
    margin-left: 0;
  `}
`

export const WidgetTitle = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 25px;
  color: white;
`

export const WidgetDateStamp = styled.div`
  display: block;
  margin-left: 30px;
  font-size: 12px;
  font-weight: 300;
  color: #F8F7FA;
  margin-top: -1px;
  ${breakpoint('medium')`
    float: right;
    margin-left: 0;
    display: inline-block;
    margin-top: 3px;
  `}
`

export const WidgetHeading = styled.span`
  display: block;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
`

export const WidgetDateRange = styled.span`
  font-weight: 300;
  font-size: 12px;
  color: white;
`

export const WidgetText = styled.p`
  margin: 26px 0px;
  color: white;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  -webkit-font-smoothing: antialiased
`

export const WidgetAmountContainer = styled.div`
  margin-right: 30px;
  text-align: left;
  ${breakpoint('medium')`
    float: left;
  `}
`

export const WidgetAmount = styled.div`
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  color: white;
  margin-top: 30px;
`

export const WidgetAmountLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: white;
  line-height: 20px;
  opacity: .75;
  margin-top: 8px;
  margin-bottom: 30px;
`

// Special effects

export const FadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 100;}
`

export const FadeContent = styled.div`
  animation: ${FadeIn} ease 3s;
  -webkit-animation: ${FadeIn} ease 3s;
  -moz-animation: ${FadeIn} ease 3s;
  -o-animation: ${FadeIn} ease 3s;
  -ms-animation: ${FadeIn} ease 3s;
`

