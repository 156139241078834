import React, { Fragment } from "react";
import { Cell, Grid } from 'react-foundation'

import { Quote, Content, Heading, Person, Dash, Title } from './QuoteSection.style';

function QuoteSection() {
  return (
    <Fragment>
      <Quote>
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                &ldquo;The next logical step is using a technology platform like LexShares to equalize access to the legal system.&rdquo;
              </Heading>
            </Cell>
          </Grid>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Person>
                <Dash>
                  &mdash;
                </Dash>
                {' '} Richard Painter
              </Person>
              <Title>
                Former Chief White House Ethics Counsel
              </Title>
            </Cell>
          </Grid>
        </Content>
      </Quote>
    </Fragment>
  )
}

export default QuoteSection
