import React from 'react'
import {Container, Message} from './AlertBar.style'

function AlertBar({message}) {
  return (
    <Container className="alert">
      <Message>{message}</Message>
    </Container>
  )
}

export default AlertBar
