import React, { useRef, useEffect, useState } from 'react'
import { Sidebar, Nav, NavList, NavItem } from './SideNav.style'
import Mixpanel from '../Mixpanel'

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect()
  const offsetTop = ele.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}

const scrollTo = (ele, clickOffset) => {
  const y = ele.getBoundingClientRect().top + window.pageYOffset - clickOffset;
  window.scrollTo({top: y, behavior: 'smooth'});
}

function SideNav({list, offset = 0, clickOffset = 50, track = false, scrollData = null}) {
  const [visibleSection, setVisibleSection] = useState()

  const sidebarRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + offset + 1;

      const selected = list.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);

          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.label !== visibleSection) {
        setVisibleSection(selected.label)
        if (track) {
          Mixpanel.track(`Scrolled to ${selected.label}`, scrollData)
        }
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <Sidebar ref={sidebarRef}>
      <Nav>
        <NavList>
          {
            list.map((item, i) =>
              item.label && <NavItem selected={visibleSection == item.label} key={i} onClick={() => scrollTo(item.ref.current, clickOffset)}>
                {item.label}
              </NavItem>
            )
          }
        </NavList>
      </Nav>
    </Sidebar>
  )
}

export default SideNav
