import React, { Fragment } from 'react';
import { Section, Content } from './TestimonialSection.style';
import TestimonialCarousel from '../TestimonialCarousel'

const testimonials = [
  {
    heading: '“LexShares is disrupting the litigation finance market.”',
    image: "logos/crains-logo-white.png"
  },
  {
    heading: '“LexShares financing provides access to justice.”',
    image: "logos/pymnts-logo-white.png"
  },
  {
    heading: '“Investing in lawsuits is heating up, aided by LexShares.”',
    image: "logos/forbes-logo-white.png"
  }
]

function TestimonialSection() {
  return (
    <Fragment>
      <Section className="js-testimonial">
        <Content>
          <TestimonialCarousel testimonials={testimonials}/>
        </Content>
      </Section>
    </Fragment>
  )
}

export default TestimonialSection;
