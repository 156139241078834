import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import InputCheck from '../../../../components/Form/InputCheck'
import InputSelect from '../../../../components/Form/InputSelect'

import {
  Heading,
  SelectContainer,
  AttestContainer
} from './InvestmentSuitability.style'

function InvestmentSuitability({error, suitabilityHandler, attestHandler,
  suitabilityObjectives, suitabilityExperience, suitabilityRisk,
  suitabilityAssets, suitabilitySecurities, suitabilityTax, assetAttestation}) {

  const objectives = [
    { value: 'a', label: 'Capital Preservation', name: 'suitabilityObjectives' },
    { value: 'b', label: 'Income', name: 'suitabilityObjectives' },
    { value: 'c', label: 'Growth & Income', name: 'suitabilityObjectives' },
    { value: 'd', label: 'Growth', name: 'suitabilityObjectives' },
    { value: 'e', label: 'Aggressive Growth', name: 'suitabilityObjectives' }
  ]

  const experience = [
    { value: 'a', label: 'Less than 2 years', name: 'suitabilityExperience' },
    { value: 'b', label: 'Between 2 and 5 years', name: 'suitabilityExperience' },
    { value: 'c', label: 'Between 5 and 10 years', name: 'suitabilityExperience' },
    { value: 'd', label: 'Greater than 10 years', name: 'suitabilityExperience' },
  ]

  const risk = [
    { value: 'a', label: 'Conservative', name: 'suitabilityRisk' },
    { value: 'b', label: 'Moderate', name: 'suitabilityRisk' },
    { value: 'c', label: 'Significant', name: 'suitabilityRisk' },
    { value: 'd', label: 'Speculative', name: 'suitabilityRisk' },
  ]

  const assets = [
    {
      value: 'a', 
      label: 'Cash, deposits, certificate of deposits, capital protected products, bonds',
      name: 'suitabilityAssets'
    },
    {
      value: 'd',
      label: 'Investment Real Estate, REITs, Interests in real estate partnerships',
      name: 'suitabilityAssets'
    },
    {
      value: 'b',
      label: 'Foreign currencies, non-capital protected currency linked structured products',
      name: 'suitabilityAssets'
    },
    {
      value: 'c',
      label: 'Stocks, ETFs, mutual funds, commodities, derivatives',
      name: 'suitabilityAssets'
    }
  ]

  const securities = [
    { value: 'a', label: 'Less than $150,000', name: 'suitabilitySecurities' },
    { value: 'b', label: '$150,000 to $250,000', name: 'suitabilitySecurities' },
    { value: 'c', label: 'Greater than $250,000 USD', name: 'suitabilitySecurities' },
  ]

  const taxRate = [
    { value: 'a', label: 'Less than 32%', name: 'suitabilityTax' },
    { value: 'b', label: 'Between 32% and 37%', name: 'suitabilityTax' },
    { value: 'c', label: 'Greater than 37%', name: 'suitabilityTax' },
  ]

  return (
    <Fragment>
      <Heading>Suitability</Heading>
      <SelectContainer>
        <Grid className="grid-margin-x grid-margin-y">
          <Cell small={12} medium={6} className="js-objectives-cell">
            <InputSelect
              value={suitabilityObjectives}
              label="My primary investment objectives are:"
              options={objectives}
              changeHandler={suitabilityHandler}
              error={error.suitabilityObjectives}
              name="suitability-objectives"
            />
          </Cell>
          <Cell small={12} medium={6} className="js-experience-cell">
            <InputSelect
              value={suitabilityExperience}
              label="My relevant investment experience is:"
              options={experience}
              changeHandler={suitabilityHandler}
              error={error.suitabilityExperience}
              name="suitability-experience"
            />
          </Cell>
        </Grid>
        <Grid className="grid-margin-x grid-margin-y">
          <Cell small={12} medium={6} className="js-risk-cell">
            <InputSelect
              value={suitabilityRisk}
              label="My willingness to accept risk for this investment is:"
              options={risk}
              changeHandler={suitabilityHandler}
              error={error.suitabilityRisk}
              name="suitability-risk"
            />
          </Cell>
          <Cell small={12} medium={6} className="js-assets-cell">
            <InputSelect
              value={suitabilityAssets}
              label="Are you currently holding any of the below investments:"
              options={assets}
              changeHandler={e => suitabilityHandler(e, 'suitabilityAssets')}
              error={error.suitabilityAssets}
              name="suitability-assets"
              className="basic-multi-select"
              isMulti={true}
              clearable={true}
            />
          </Cell>
        </Grid>
        <Grid className="grid-margin-x grid-margin-y">
          <Cell small={12} medium={6} className="js-allocation-cell">
            <InputSelect
              value={suitabilitySecurities}
              label="My current portfolio allocation for private securities is:"
              options={securities}
              changeHandler={suitabilityHandler}
              error={error.suitabilitySecurities}
              name="suitability-securities"
            />
          </Cell>
          <Cell small={12} medium={6} className="js-tax-cell">
            <InputSelect
              value={suitabilityTax}
              label="My approximate tax rate is:"
              options={taxRate}
              changeHandler={suitabilityHandler}
              error={error.suitabilityTax}
              name="suitability-tax"
            />
          </Cell>
        </Grid>
        <AttestContainer>
          <InputCheck
            changeHandler={attestHandler}
            name="asset-attestation"
            label="I attest that I have liquid assets equal to or greater than the aforementioned amount."
            checked={assetAttestation}
            error={error.assetAttestation}
          />
        </AttestContainer>
      </SelectContainer>
    </Fragment>
  )
}

export default InvestmentSuitability
