import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals';
import leftArrowIcon from '../../../../assets/images/icons/left-arrow-white.png';
import rightArrowIcon from '../../../../assets/images/icons/right-arrow-white.png';

export const CarouselContainer = styled.div`
  width: 100%;
`;

export const LeftArrow = styled.div`
  background: url(${leftArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

export const RightArrow = styled.div`
  background: url(${rightArrowIcon}) no-repeat center center/cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

export const TestimonialContainer = styled.div`
  padding: 0;
  margin-bottom: 40px;
  ${breakpoint('medium')`
    margin-bottom: 80px;
    width: 600px !important;
  `};

  ${breakpoint('large')`
    width: 1000px !important
  `};
`;

export const Heading = styled(Globals.H2)`
  color: white !important;
  text-align: center;
`;

export const Divider = styled.div`
  width: 80px;
  height: 5px;
  background-color: #bc74ff;
  margin: 30px auto;
`;

export const Source = styled.div`
  text-align: center;
  img {
    width: 120px;

    ${breakpoint('medium')`
      width: 190px
    `};
  }
`;
