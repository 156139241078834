import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as SB from "../../SimpleBanner";
import * as Globals from "./../../Globals";
import theme from "../../../config/theme";
import Hero from "../../../../assets/images/barometer_linkedin/report-cover.png";
import HeaderWave from "../../../../assets/images/barometer_linkedin/header-wave.png";

export const Container = styled.div`
  width: 186px;
  margin: auto;
  position: absolute;

  ${breakpoint("medium")`
    width: 250px;
    margin: 0px 40px 0px auto;
  `}

  ${breakpoint("large")`
    width: 400px;
    margin: 0px;
  `}
`;

export const Banner = styled(SB.Banner)`
  background: url(${Hero}) no-repeat center center/cover;

  ${breakpoint("large")`
    padding: 0px;
  `}
`;

export const BannerWave = styled(SB.Banner)`
  background: url(${HeaderWave}) no-repeat center center/cover;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 235px;

  ${breakpoint("large")`
    padding: 0px;
  `}
`;

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  color: ${theme.colors.white};
  line-height: 29px;
  text-align: center;

  ${breakpoint("medium")`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
  `}
`;

export const Subheading = styled(Globals.H6)`
  ${Globals.proximaFontStack}
  margin-top: 14px;
  line-height: 22px !important;
  font-weight: normal;
  color: ${theme.colors.white};
  text-align: center;
  font-size: 17px;

  ${breakpoint("medium")`
    text-align: left;
    margin-top: 16px;
    line-height: 36px !important;
  `}
`;

export const Description = styled(SB.P)`
  text-align: left;
  font-size: 16px !important;
  line-height: 21px !important;
  margin: 25px 0px 50px 0px;
  max-width: 700px;
`;

export const Content = styled(SB.Content)`
  margin: 0px;
  padding: 0px 20px 40px 20px;

  ${breakpoint("medium")`
    margin: 40px 0px 0px 0px;
    padding: 40px;
  `}

  ${breakpoint("large")`
    margin: 80px 0px 0px 0px;
    padding: 80px 0px 80px 120px;
  `}
`;

export const DownloadHeader = styled(SB.P)`
  font-size: 16px;
  font-weight: 900;
`;

export const FormContainer = styled.div`
  padding: 18px 0px 0px 0px;
  text-align: left;

  ${breakpoint("medium")`
    padding: 20px 0px;
  `}
`;

export const InputField = styled.input`
  padding: 14px 10px 11px 10px;
  font-size: 16px;
  line-height: 25px;
  width: 50%;
  height: 50px !important;
  margin: 0px;
  display: inline-block;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  ${breakpoint("medium")`
    border-radius: 3px !important;
    max-width: 310px;
    width: 60%;
  `}

  ${breakpoint("large")`
    display: inline-block;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  `}
`;

export const InputSubmit = styled.input`
  text-transform: uppercase;
  width: 50%;
  height: 50px !important;
  background: ${theme.colors.purple};
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-weight: 800;
  line-height: 13px;
  letter-spacing: 2px;
  ${Globals.proximaFontStack};
  color: ${theme.colors.white};
  cursor: pointer;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid ${theme.colors.purple} !important;

  ${breakpoint("medium")`
    border-radius: 3px !important;
    max-width: 210px;
    width: 40%;
  `}

  ${breakpoint("large")`
    display: inline-block;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  `}

  &:hover {
    color: white;
    background-color: ${theme.colors.backgroundPurple};
  }
`;

export const Form = styled.form`
  height: 50px !important;
`;
