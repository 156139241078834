import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Section,
  Content,
  Container,
  Heading,
  Subheading,
  MediaContent,
  Button,
  SecondaryButton
} from './Hero.style';


function Hero({heading, subheading, buttonText, secondaryButtonText, buttonLink,
  secondaryButtonLink, background, pressLogo}) {

  return (
    <Fragment>
      <Section style={{backgroundImage: `url(${background})`}}>
        <Content>
          <Grid>
            <Cell large={7} small={12}>
              <Container>
                <Heading>
                  { heading }
                </Heading>
                <Subheading>
                  { subheading }
                </Subheading>
                <Button href={ buttonLink }>
                  { buttonText }
                </Button>
                { secondaryButtonText &&
                  <SecondaryButton href={ secondaryButtonLink}>
                    { secondaryButtonText }
                  </SecondaryButton>
                }
                {pressLogo && 
                  <MediaContent>
                    <img src={ pressLogo } />
                  </MediaContent>
                }
              </Container>
            </Cell>
            <Cell large={5} small={12}>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default Hero;
