import styled from 'styled-components'
import * as Globals from '../../../Globals'

export const SuccessContainer = styled.div`
  position: fixed;
  height: 100%;
  background-color: black;
  width: 100%;
  top: 0;
  z-index: 9999999999;
  opacity: .9;
`

export const Content = styled.div`
  text-align: center;
  color: white;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 150px;
  padding: 20px;
`

export const Text = styled.p`
  color: white;
  margin: 30px 0;
`

export const Heading = styled(Globals.H2)`
  text-align: center !important
  margin-bottom: 20px
  color: white
`

export const LinkButton = styled(Globals.ButtonInverse)`
  border: 2px solid #cacaca;
  color: white;
  &:hover {
    background-color: transparent !important;
    color: #cacaca;
  }
`
