import styled from 'styled-components';
import * as Globals from '../../Globals';

export const Form = styled.form``

export const Button = styled(Globals.ButtonPrimary)`
  width: 100%;
`

export const Input = styled.input`
  height: 50px;
  mix-blend-mode: normal;
  opacity: 0.9;
  border: 1px solid #878291;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 13px !important;
`

export const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 10px;
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 22px;
`

export const Success = styled.div``

export const Icon = styled.div`
  img {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }
`
