import React, { Fragment } from 'react'
import { Label, InputContainer, Hint, Error } from './InputSelect.style'
import Select from 'react-select'

function InputSelect({
  name, label, labelColor, hint, options = [], clearable = false,
  changeHandler, error, value = {}, isMulti = false, className, onBlur, touched}) {

  const inputLabel = label != null ? <Label labelColor={labelColor}>{label}</Label> : null
  const inputHint = hint != null ? <Hint>{hint}</Hint> : null

  const customStyles = {
    //eslint-disable-next-line
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: state.isFocused ? '1px solid #8838db !important' : error ? '1px solid red' : '1px solid #d4d4d4',
    }),
    //eslint-disable-next-line
    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
    }),
    //eslint-disable-next-line
    menu: (provided, state) => ({
      ...provided,
      'z-index': '1000000'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#8838db' : state.isFocused ? '#f8f7fa' : 'inherit',
      padding: isMulti ? '3px 8px !important' : '8px 8px',
      margin: isMulti ? '10px 0' : '0px 0',
      lineHeight: '1rem'
    }),
  }

  return (
    <Fragment>
      {inputLabel}
      <InputContainer>
        <Select
          options={options}
          value={value}
          styles={customStyles}
          placeholder="Select an option"
          onChange={changeHandler}
          isMulti={isMulti}
          onBlur={onBlur}
          className={className}
          name={name}
          isClearable={clearable}
        />
      </InputContainer>
      {error && (
        <Error>
          {error}
        </Error>
      )}
      {inputHint}
      {touched && <div></div>}
    </Fragment>
  )
}

export default InputSelect
