import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import theme from "../../config/theme";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import GuideSection from "../../components/funding/GuideSection/GuideSection";
import ExploreFundingOptionsBanner from "../../components/funding/ExploreFundingOptionsBanner/ExploreFundingOptionsBanner";

class GuideConfirmation extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <GuideSection
            litigationFinanceRequestsPath={
              this.props.litigationFinanceRequestsPath
            }
            guideConfirmationPath={this.props.guideConfirmationPath}
            csrfToken={this.props.csrfToken}
          />
          <ExploreFundingOptionsBanner
            fundingCasePath={this.props.fundingCasePath}
          />
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default GuideConfirmation;
