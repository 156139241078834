import React, {useEffect, useState} from 'react'
import axios from '../../../Axios'
import {Cell} from 'react-foundation'
import Pie from '../../../Charts/Pie'
import NumberFormat from 'react-number-format'

import {
  Widget,
  Heading,
  Metrics,
  Metric,
  Value,
  Label,
  ChartContainer
} from './PortfolioWidget.style'

function PortfolioWidget({viewClickHandler}) {
  let [portfolio, setPortfolio] = useState([]);
  let [data, setData] = useState({});
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const pluralize = (word, num) => {
    return `${word}${num > 1 ? 's' : ''}`
  }

  const mapValues = (data) => {
    let array = []

    if(data.total_active_cases > 0) {
      array.push(
        {
          "id": "Active Investments",
          "label": `${data.total_active_cases} Active ${pluralize("Investment", data.total_active_cases)}`,
          "value": data.total_active_cases,
          "valueLabel": `${formatter.format(data.total_active_amount)} Invested`
        }
      )
    }
    if(data.total_resolved_cases > 0) {
      array.push(
        {
          "id": "Fully Resolved Investments",
          "label": `${data.total_resolved_cases} Fully Resolved ${pluralize("Investment", data.total_resolved_cases)}`,
          "value": data.total_resolved_cases,
          "valueLabel": `${formatter.format(data.total_resolved_amount)} Invested`
        }
      )
    }

    setData(data)
    setPortfolio(array)
  }

  useEffect(() => {
    axios.get(`/widget/all-investments.json`).then(res => {
      mapValues(res.data)
    })
  }, [])

  if(!data.total_active_cases && !data.total_resolved_cases) {
    return null;
  }

  if(data.total_active_cases < 1 && data.total_resolved_cases < 1) {
    return null;
  }

  const totalCases = data.total_resolved_cases + data.total_active_cases;

  return (
    <Widget className="js-faq-widget" onClick={() => viewClickHandler(null, null, 'investment portfolio')}>
      <Heading>Your Portfolio</Heading>
      <Cell small={12}>
        <ChartContainer className="js-jurisdiction-donut">
          <Pie data={portfolio} heading={"Total Investments"} bigHeading={totalCases} capitalizeDataLabel={false} pluralizeLabel={false} label="Invested" />
        </ChartContainer>
      </Cell>
      <Metrics>
        {
          data.total_active_amount && <Metric single={!data.total_resolved_amount}>
            <Value>
              <NumberFormat
                value={data.total_active_amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Value>
            <Label>
              Total Amount Invested
            </Label>
          </Metric>
        }
        {
          data.total_resolved_amount_returned > 0 && <Metric>
            <Value>
              <NumberFormat
                value={data.total_resolved_amount_returned}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Value>
            <Label>
              Return Amount
            </Label>
          </Metric>
        }
      </Metrics>
    </Widget>
  )
}

export default PortfolioWidget
