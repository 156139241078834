import React, {Fragment, useState, useEffect} from 'react'
import {Cell, Grid} from 'react-foundation'
import Pie from '../../../../Charts/Pie'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text,
  ChartContainer
} from './FundInvestmentProfile.style'

/**
 * Displays three pie charts showing how fund investments are
 * diversified across damage, stage and jurisdiction profiles.
 */
function FundInvestmentProfile({fundId}) {
  let [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`/widget/fund-investment-profile/${fundId}.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Widget className="js-fund-profile-widget">
      <Content loading={data ? "false" : "true"}>
        { data &&
          <Fragment>
            <Heading>{data.name} Investment Profile</Heading>
            <DateRange>{data.date_range}</DateRange>
            <Text>
              The following charts represent additional breakdowns of your {data.name} case 
              investments, specifically at the time each investment was made.
            </Text>
            <Grid className="grid-margin-x">
              <Cell small={12} medium={4}>
                <ChartContainer className="js-damages-donut">
                  <Pie data={data.damages_profile} heading="Damages" />
                </ChartContainer>
              </Cell>
              <Cell small={12} medium={4}>
                <ChartContainer className="js-stage-donut">
                  <Pie data={data.stage_profile} heading="Stage" />
                </ChartContainer>
              </Cell>
              <Cell small={12} medium={4}>
                <ChartContainer className="js-jurisdiction-donut">
                  <Pie data={data.jurisdiction_profile} heading="Jurisdiction" />
                </ChartContainer>
              </Cell>
            </Grid>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default FundInvestmentProfile
