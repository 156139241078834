import React from 'react'
import { Progress } from 'react-foundation'
import {
  Container,
  StepNumber,
  ProgressWrapper
} from './ProgressBar.style'

function ProgressBar({step = 1, stepCount = 5, percent = 0}) {
  return (
    <Container>
      <StepNumber>
        Step {step} of {stepCount}
      </StepNumber>
      <ProgressWrapper>
        <Progress tabIndex='0' min={0} max={100} value={percent} />
      </ProgressWrapper>
    </Container>
  )
}

export default ProgressBar
