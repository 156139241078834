import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Banner = styled.div`
  background: linear-gradient(90deg, #9450d4 0%, #591db4 100%);
  text-align: center;
  color: white;
  padding: 30px 20px 20px 20px;
  cursor: pointer;
`;

export const Header = styled.p`
  font-size: 1.3em;

  &::after {
    display: inline-block;
    content: '\f054';
    font-family: 'FontAwesome';
    margin-left: 15px;
    top: 2px;
    position: relative;
  }

  ${breakpoint('medium')`
    font-size: 1.3em;
  `}

  ${breakpoint('small')`
    font-size: 1.1em;
  `}
`

export const Link = styled.a`
  color: white;
  font-weight: bold;
  outline: none;

  &:hover {
    color: white;
  }
`