let actions = {
  isRefresh: (currentWindow) => {
    if (currentWindow.performance && currentWindow.performance.navigation.type == 1) {
      currentWindow.location.replace("#")
      if (typeof currentWindow.history.replaceState == 'function') {
        history.replaceState({}, '', currentWindow.location.href.slice(0, -1))
        return true
      }
    }
  },
  getViews: (currentWindow) => {
    let currentView = null
    let currentSubView = null
    const anchor = currentWindow.location.toString().indexOf('#')
    const argument = currentWindow.location.toString().indexOf('?tab=')
    if (anchor > 0) {
      let hash = currentWindow.location.toString().substring(anchor + 1).replace('-', ' ')
      const subAnchor = hash.indexOf(':')
      if (subAnchor > 0) {
        let subHash = hash.substring(subAnchor + 1).replace('-', ' ')
        currentSubView = subHash
      }
      currentView = hash.replace(/:.*/, '')
    }
    else if (argument > 0) {
      let hash = currentWindow.location.toString().substring(argument + 1).replace('tab=', '').replace('-', ' ')
      currentView = hash.replace(/:.*/, '')
    }
    return [currentView, currentSubView]
  }
}

let TabControl = actions

export default TabControl
