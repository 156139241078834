import styled from "styled-components";
import * as Globals from "./../../Globals";
import breakpoint from "styled-components-breakpoint";
import theme from "../../../config/theme";

export const Container = styled.div`
  background: ${theme.colors.white};
  padding: 20px;

  ${breakpoint("medium")`
    padding: 40px 85px 70px 120px;
  `};

  ${breakpoint("large")`
    padding: 79px 191px 139px 180px;
  `};
`;

export const Header = styled(Globals.H2)`
  text-align: center;
  color: ${theme.colors.darkGrey};
  margin-bottom: 21px;
`;

export const Description = styled(Globals.Description)`
  text-align: center;
  color: ${theme.colors.darkGrey};
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  text-align: center;

  ${breakpoint("medium")`
    margin-bottom: 55px;
  `};
`;

export const ContactUsButton = styled(Globals.ButtonLink)`
  width: 100%;
  background: ${theme.colors.mediumPurple};

  ${breakpoint("medium")`
    min-width: 185px;
    width: unset;
  `};
`;

export const Step = styled.span`
  ${Globals.proximaFontStack}
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  background: ${theme.colors.darkerPurple};
  width: 32px;
  height: 32px;
  border-radius: 30px;
  justify-content: center;
  margin-top: 10px;
`;

export const Title = styled(Globals.H2)`
  margin-bottom: 12px;
`;

export const StepDescription = styled(Globals.Description)`
  color: ${theme.colors.darkGrey};
  margin-bottom: 35px;
  padding-right: 0px;

  ${breakpoint("medium")`
    padding-right: 80px;
  `};
`;

export const Pill = styled.span`
  ${Globals.proximaFontStack}
  border: 1px solid ${theme.colors.lightGrey4};
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
`;

export const FundingContainer = styled.div`
  background: ${theme.colors.lightPurple};
  width: 100%;
  border-radius: 10px;
  padding: 30px 25px 30px 30px;
  position: absolute;
  z-index: 3;
  margin-top: 50px;
  height: 422px;

  ${breakpoint("medium")`
    width: 400px;
  `};

  ${breakpoint("large")`
    margin-top: 0px;
  `};
`;

export const SampleLegalUnderWritingContainer = styled.div`
  background: ${theme.colors.lightPurple};
  width: 100%;
  border-radius: 10px;
  padding: 30px 25px 30px 30px;
  position: absolute;
  z-index: 3;
  margin-top: 50px;
  height: 380px;

  ${breakpoint("medium")`
    width: 400px;
    height: 336px;
  `};

  ${breakpoint("large")`
    margin-top: 0px;
  `};
`;

export const SampleLegalClaimContainer = styled.div`
  background: ${theme.colors.lightPurple};
  width: 100%;
  border-radius: 10px;
  padding: 30px 25px 30px 30px;
  position: absolute;
  z-index: 3;
  margin-top: 50px;
  height: 502px;

  ${breakpoint("medium")`
    width: 400px;
    height: 460px;
  `};

  ${breakpoint("large")`
    margin-top: 0px;
  `};
`;

export const SampleHeading = styled.div`
  ${Globals.proximaFontStack}
  color: ${theme.colors.mediumPurple};
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 21px;
  display: inline-block;
`;

export const SampleLegalClaimSubtitle = styled.div`
  ${Globals.proximaFontStack}
  color: rgba(40, 35, 49, 0.5);
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 9px;
`;

export const SampleLegalClaimSubheading = styled.div`
  font-size: 20px;
  line-height: 25px;
  ${Globals.proximaFontStack}
  color: ${theme.colors.darkGrey};
  font-weight: bold;
  margin-bottom: 12px;
  display: inline-block;
  margin-right: 10px;
`;

export const SampleLegalClaimDescription = styled.div`
  opacity: 0.5;
  font-size: 14px;
  line-height: 20px;
  ${Globals.proximaFontStack}
  color: ${theme.colors.darkGrey};
  margin-bottom: 12px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  height: 502px;
  margin-bottom: 94px;
  margin-left: 0px;

  ${breakpoint("medium")`
    margin-left: 15%;
    height: 460px;
  `};

  ${breakpoint("large")`
    margin-left: 100px;
  `};
`;

export const SampleLegalClaimContainerLightGrey = styled.div`
  background: #aea8bc;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 2;
  height: 502px;
  margin: 60px 0px 0px 10px;
  text-indent: -9999px;

  ${breakpoint("medium")`
    width: 400px;
    height: 460px;
  `};

  ${breakpoint("large")`
    margin: 10px 0px 0px 10px;
  `};
`;

export const SampleLegalClaimContainerDarkGrey = styled.div`
  background: #77727b;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 502px;
  margin: 70px 0px 0px 20px;
  text-indent: -9999px;

  ${breakpoint("medium")`
    width: 400px;
    height: 460px;
  `};

  ${breakpoint("large")`
    margin: 20px 0px 0px 20px;
  `};
`;

export const RadioButton = styled.div`
  border: 1px solid #979797;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  display: inline-block;
`;

export const SampleLegalUnderWritingSubHeading = styled.div`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 26px;
  color: ${theme.colors.darkGrey};
  font-weight: bold;
  margin: -5px 0px 3px 14px;
`;

export const SampleLegalUnderWritingDescription = styled(Globals.Description)`
  color: ${theme.colors.darkGrey};
  margin-left: 14px;
  font-size: 14px !important;
  line-height: 20px !important;
`;

export const SampleLegalUnderWritingContainerLightGrey = styled.div`
  background: #aea8bc;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 2;
  height: 380px;
  margin: 60px 0px 0px 10px;

  ${breakpoint("medium")`
    width: 400px;
    height: 336px;
  `};

  ${breakpoint("large")`
    margin: 10px 0px 0px 10px;
  `};
`;

export const SampleLegalUnderWritingContainerDarkGrey = styled.div`
  background: #77727b;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 380px;
  margin: 70px 0px 0px 20px;

  ${breakpoint("medium")`
    width: 400px;
    height: 336px;
  `};

  ${breakpoint("large")`
    margin: 20px 0px 0px 20px;
  `};
`;

export const Ring = styled.div`
  border: 11px solid ${theme.colors.mediumPurple};
  width: 200px;
  height: 200px;
  border-radius: 100px;
  margin: 20px auto auto auto;
`;

export const PercentageFunded = styled(Globals.H6)`
  font-size: 13px !important;
  line-height: 16px !important;
  margin-top: 47px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${theme.colors.mediumPurple};
`;

export const AmountFunded = styled(Globals.H4)`
  line-height: 32px !important;
  margin-top: 12px;
  text-align: center;
  color: ${theme.colors.darkGrey};
  font-weight: bold;
`;

export const FundingStatus = styled.div`
  ${Globals.proximaFontStack}
  margin-top: 6px;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  opacity: 0.75;
  color: ${theme.colors.darkGrey};
`;

export const FundingHeading = styled.div`
  font-size: 20px;
  line-height: 25px;
  ${Globals.proximaFontStack}
  color: ${theme.colors.darkGrey};
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 30px;
`;

export const FundingSubHeading = styled(Globals.Description)`
  color: ${theme.colors.darkGrey};
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: center;
  margin-bottom: 16px;
  padding: 0px 20px;
`;

export const FundingContainerLightGrey = styled.div`
  background: #aea8bc;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 2;
  height: 422px;
  margin: 60px 0px 0px 10px;

  ${breakpoint("medium")`
    width: 400px;
  `};

  ${breakpoint("large")`
    margin: 10px 0px 0px 10px;
  `};
`;

export const FundingContainerDarkGrey = styled.div`
  background: #77727b;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 422px;
  margin: 70px 0px 0px 20px;

  ${breakpoint("medium")`
    width: 400px;
  `};

  ${breakpoint("large")`
    margin: 20px 0px 0px 20px;
  `};
`;

export const LegalUnderWritingRelativeContainer = styled.div`
  position: relative;
  height: 380px;
  margin-bottom: 94px;
  margin-left: 0px;

  ${breakpoint("medium")`
    height: 336px;
    margin-left: 15%;
  `};

  ${breakpoint("large")`
    margin-left: 100px;
  `};
`;

export const FundingRelativeContainer = styled.div`
  position: relative;
  height: 422px;
  margin-bottom: 94px;
  margin-left: 0px;

  ${breakpoint("medium")`
    margin-left: 15%;
  `};

  ${breakpoint("large")`
    margin-left: 100px;
  `};
`;

export const InfoIcon = styled.div`
  display: inline-block;
  margin-left: 6px;

  img {
    margin-top: -2px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 15px;
  margin: -180px 0px 0px 200px;
  font-family: "proxima-nova";
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  max-width: 280px;
  width: 280px;
  text-transform: none;
  z-index: 1;
`;

export const TooltipTitle = styled.div`
  ${Globals.proximaFontStack}
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
`;

export const TooltipDescription = styled.p`
  ${Globals.proximaFontStack}
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.darkGrey};
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: left;
`;

export const Spacer = styled.div`
  ${(props) => props.height && `height: ${props.height - 75}px;`}

  ${breakpoint("medium")`
    ${(props) => props.height && `height: ${props.height}px;`}
  `};
`;
