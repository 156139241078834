import React from "react";
import { Cell, Grid } from "react-foundation";
import {
  Container,
  Heading,
  AboutDescription,
  AboutHeading,
  SubHeading,
  ImageContainer,
} from "./About.style";
import AwardIcon from '../../../../assets/images/icons/award-icon.png';
import TimeIcon from '../../../../assets/images/icons/time-icon.png';
import CalendarIcon from '../../../../assets/images/icons/calendar-icon.png';
import IdeaIcon from '../../../../assets/images/icons/idea-icon.png';

function About() {
  const facts = [
    {
      src: AwardIcon,
      heading: "Award-Winning",
      description: "Chambers-ranked in the U.S. for two years running",
    },
    {
      src: TimeIcon,
      heading: "Efficient Investment Process",
      description:
        "Time from application to investment can be as little as 30 days",
    },
    {
      src: CalendarIcon,
      heading: "Years of Dedication",
      description:
        "70+ years of combined industry experience, 1M+ cases evaluated by our team",
    },
    {
      src: IdeaIcon,
      heading: "Industry Leadership",
      description:
        "Proprietary industry data and analysis in our first-of-their-kind research reports",
    },
  ];

  return (
    <Container>
      <Heading>LexShares: A Leader in U.S. Legal Finance</Heading>
      <SubHeading>
        We are one of the industry&apos;s most active litigation funders -
        providing bespoke financing arrangements across a wide range of
        commercial disputes and law firm portfolios.
      </SubHeading>
      <Grid>
        {facts.map((fact, i) => (
          <Cell small={12} medium={6} large={3} key={`fact-${i}`}>
            <ImageContainer style={{ textAlign: "center" }}>
              <img src={fact.src} />
            </ImageContainer>
            <AboutHeading>{fact.heading}</AboutHeading>
            <AboutDescription>{fact.description}</AboutDescription>
          </Cell>
        ))}
      </Grid>
    </Container>
  );
}

export default About;
