import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation'

import { Divider, Photo, Name, Position, Email, Phone, PhotoOverlay, ReadBioLink, Flag } from './EmployeeCard.style';

function EmployeeCard(props) {
  return (
    <Fragment key={props.id}>
      <Cell small={12} medium={6} large={4}>
        <Grid className="grid-padding-x">
          <Cell className="align-self-middle divider">
            <Photo href={props.path} >
              <PhotoOverlay>
                <Flag></Flag>
                <ReadBioLink>
                  Read Bio +
                </ReadBioLink>
              </PhotoOverlay>
              <img src={props.profile_image} />
            </Photo>
          </Cell>
          <Cell>
            <Grid>
              <Cell>
                <Name>
                  { props.full_name }
                </Name>
                <Position>
                  { props.title }
                </Position>
              </Cell>
              <Divider />
              <Cell className="align-self-middle contact">
                <Email href={`mailto:${props.email}`}>
                  { props.email }
                </Email>

                {props.hidePhone || <Phone href={`tel:${props.phone}`}>
                  { props.phone }
                </Phone> }
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Cell>
    </Fragment>
  )
}

export default EmployeeCard;
