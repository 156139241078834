import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';

export const Container = styled.div`
  padding: 18px 0px 0px 0px;

  ${breakpoint('medium')`
    padding: 30px 0px;
  `}
`

export const InputField = styled.input`
  padding: 14px 10px 11px 10px;
  font-size: 16px;
  line-height: 25px;
  width: 50%;
  height: 49px !important;
  margin: 0px;
  display: inline-block;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  ${breakpoint('medium')`
    border-radius: 3px !important;
    max-width: 310px;
    width: 60%;
  `}

  ${breakpoint('large')`
    display: inline-block;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  `}
`;

export const Form = styled.form`
`;

export const InputSubmit= styled.input`
  text-transform: uppercase;
  width: 50%;
  height: 49px;
  background: ${theme.colors.purple};
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-weight: 800;
  line-height: 13px;
  letter-spacing: 2px;
  ${Globals.proximaFontStack};
  color: ${theme.colors.white};
  cursor: pointer;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid ${theme.colors.purple} !important;

  ${breakpoint('medium')`
    border-radius: 3px !important;
    max-width: 210px;
    width: 40%;
  `}

  ${breakpoint('large')`
    display: inline-block;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  `}

  &:hover {
    color: white;
    background-color: ${theme.colors.backgroundPurple};
  }
`;

export const ErrorMessage = styled.div`
`;