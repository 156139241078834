export default function validate(data) {
  let errors = {}
  let keys = Object.keys(data.validationErrors)
  let camelToDashCase = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
  let numberWithCommas = str => str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  keys.forEach(function(k) {
    errors[k] = null
    let element = document.getElementsByName(camelToDashCase(k))[0]
    if (element) {

      if (k == 'email') {
        if (!data.email) {
          errors.email = 'Enter a valid email address.'
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Email address is invalid.'
        }
      }

      if (k == 'password') {
        if (!data.password) {
          errors.password = 'Password cannot be blank.'
        //eslint-disable-next-line
        } else if (!/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(data.password)) {
          errors.password = 'Your password is too weak.'
        }
      }

      if (k == 'amount') {
        if (!data.amount) {
          errors.amount = 'Amount is required.'
        }
        else {
          let el = document.querySelector('[name="amount"]')
          let range = el.getAttribute('data-range')
          if (range) {
            let [min, max] = range.split(/,/)
            let amount = parseInt(data.amount.replace(/\$|,/g, ''))
            if ((amount < parseInt(min)) || (amount > parseInt(max))) {
              if (min == max) {
                errors.amount = `Amount must be $${numberWithCommas(min)}.`
              }
              else {
                errors.amount = `Amount must be between $${numberWithCommas(min)} and $${numberWithCommas(max)}.`
              }
            }
          }
        }
      }

      if (k == 'investmentMethod') {
        if (!data.investmentMethod) {
          errors.investmentMethod = 'You must select an investment method.'
        }
      }

      if (k == 'accountNumber') {
        if (!data.accountNumber) {
          errors.accountNumber = 'You must enter an account number.'
        }
      }

      if (k == 'verifyAccountNumber') {
        if (!data.verifyAccountNumber) {
          errors.verifyAccountNumber = 'You must verify account number.'
        }
        if (data.verifyAccountNumber != data.accountNumber) {
          errors.verifyAccountNumber = 'Account numbers do not match.'
	}
      }

      if (k == 'routingNumber') {
        if (!data.routingNumber) {
          errors.routingNumber = 'You must enter a routing number.'
        }
      }

      if (k == 'accountType') {
        if (!data.accountType) {
          errors.accountType = 'You must select an account type.'
        }
      }

      if (k == 'ssn') {
        if (!data.ssn || !data.ssn.match(/^\d\d\d-\d\d-\d\d\d\d$/)) {
          errors.ssn = 'You must enter a valid SSN.'
        }
      }

      if (k == 'ein') {
        if (!data.ein || !data.ein.match(/^\d\d-\d\d\d\d\d\d\d$/)) {
          errors.ein = 'You must enter a valid EIN.'
        }
      }

      if (k == 'accreditationMethod') {
        if (!data.accreditationMethod) {
          errors.accreditationMethod = 'You must select an accreditation method.'
        }
      }

      if (k == 'representationCount') {
        if (data.representationCount != 3) {
          errors.representationCount = 'You must agree to each representation.'
        }
      }

      if (k.match(/^suitability/)) {
        if (!data[k]) {
          errors[k] = 'This is a required field.'
	}
      }

      if (k == 'assetAttestation') {
        if (data.assetAttestation != true) {
          errors.assetAttestation = 'Liquid asset attestation is required.'
        }
      }
    }

  })

  return {
    messages: errors,
    count: Object.keys(errors).filter(k => errors[k]).length 
  }
}
