import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  bottom: 0;
  width: 100%;
  left: 0;

  ${breakpoint('medium')`
    height: 118px;
  `};

  img {
    width: 100%;
  }
`