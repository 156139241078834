import React from 'react'
import { Grid, Cell } from 'react-foundation'
import { Container, Content, InfoContainer, H1, P, H1Secondary, FormContainer, H3, EmailLink } from './ContentSection.style'
import Form from '../Form'

function ContentSection({ csrfToken }) {
  return (
    <Container>
      <Content>
        <Grid className="grid-margin-x">
          <Cell medium={12} large={6} className="small-order-2 large-order-1">
            <InfoContainer>
              <H1>
                Speak to one of our team members.
              </H1>
              <P>
                For any inquiries regarding your investments at LexShares, or potential funding opportunities, please reach out to Grant Rutledge at <EmailLink href="mailto:grant.rutledge@lexshares.com"> Grant.Rutledge@LexShares.com</EmailLink>
              </P>
            </InfoContainer>
          </Cell>
          <Cell medium={12} large={6} className="medium-order-1 large-order-2">
            <H1Secondary>
              Speak to one of our team members.
            </H1Secondary>
          </Cell>
        </Grid>
      </Content>
    </Container>
  )
}

export default ContentSection
