import * as yup from 'yup'
import InputField from '../../../Form/InputField'
import InputButton from '../../../Form/InputButton'
import InputSelect from '../../../Form/InputSelect'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { Grid, Cell } from 'react-foundation';
import { get, isEmpty } from 'lodash';
import axios from 'axios'
import {
  COUNTRIES, US_STATES
} from '../../../../config/constants.js.erb'

const schema = yup.object().shape({
  name: yup.string().required().label("Name"),
  representative_position: yup.string().required().label("Representative position"),
  representative_first_name: yup.string().required().label("Representative First Name"),
  representative_last_name: yup.string().required().label("Representative Last Name"),
  phone: yup.string().when('country', {
    is: "United States",
    then: yup.string().required().matches(/^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be (000) 000-0000"}),
  }).when('country', {
    is: "Canada",
    then: yup.string().required().matches(/^\+1 (\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be +1 (000) 000-0000"}),
  }).label("Phone"),
  extension: yup.string().label("Phone extension"),
  street: yup.string().required().label("Street"),
  country: yup.string().required().label("Country"),
  city: yup.string().required().label("City"),
  state: yup.string().when('country', {
    is: "United States",
    then: yup.string().required()
  }).label("State"),
  zip: yup.string().when('country', {
    is: "United States",
    then: yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}),
  }).label("Zip"),
})

function EntityForm({csrfToken, investor}) {
  const formRef = useRef(null)
  const defaultValues = {
    name: get(investor, 'entity.name') || '',
    representative_position: get(investor, 'entity.representative_position') || '',
    representative_first_name: get(investor, 'entity.representative_first_name') || '',
    representative_last_name: get(investor, 'entity.representative_last_name') || '',
    phone: get(investor, 'entity.phone') || '',
    extension: get(investor, 'entity.extension') || '',
    street: get(investor, 'entity.address.street') || '',
    country: get(investor, 'entity.address.country') || "United States",
    city: get(investor, 'entity.address.city') || '',
    state: get(investor, 'entity.address.state') || '',
    zip: get(investor, 'entity.address.zip') || ''
  }

  const handleSubmit = ({name, representative_first_name, representative_last_name, representative_position, phone, extension, street, country, city, state, zip}) => {
    const payload = {
      entity_attributes: {
        id: get(investor, 'entity.id'), name, representative_first_name, representative_last_name, representative_position, phone, extension,
        address_attributes: {
          id: get(investor, 'entity.address.id'), street, country, city, state, zip
        }
      },
      authenticity_token: csrfToken
    }

    axios.put(`/investors/${investor.id}.json`, payload).then(() => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('notice', 'Successfully updated', false)
    }).catch((e) => {
      const shared_ui = new LexShares.SharedUI()
      shared_ui.showAlert('error', e.response.data.error, false)
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }

  const stateOptions = [{label: "Select a state", value: ''}, ...US_STATES]

  const phoneFormat = (country) => {
    if(country == "United States") {
      return "(###) ###-####"
    }

    if(country == "Canada") {
      return "+1 (###) ###-####"
    }
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(e) => handleSubmit(e) }
      validationSchema={schema}
      innerRef={formRef}
    >
      {
        ({ errors, handleSubmit, handleChange, isSubmitting, values, setFieldValue, dirty }) =>
          <form onSubmit={ handleSubmit } className="login-form mt-5 js-entity">
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Legal entity name"
                  name="name"
                  changeHandler={handleChange}
                  error={errors.name}
                  value={values.name}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Representative position"
                  name="representative_position"
                  changeHandler={handleChange}
                  error={errors.representative_position}
                  value={values.representative_position}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Representative first name"
                  name="representative_first_name"
                  changeHandler={handleChange}
                  error={errors.representative_first_name}
                  value={values.representative_first_name}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Representative last name"
                  name="representative_last_name"
                  changeHandler={handleChange}
                  error={errors.representative_last_name}
                  value={values.representative_last_name}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6}>
                <InputField
                  type="number"
                  label="Phone number"
                  name="phone"
                  format={phoneFormat(values.country)}
                  changeHandler={handleChange}
                  error={errors.phone}
                  value={values.phone}
                  mask="_"
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Extension"
                  name="extension"
                  changeHandler={handleChange}
                  error={errors.extension}
                  value={values.extension}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell small={12}>
                <InputField
                  type="text"
                  label="Street"
                  name="street"
                  changeHandler={handleChange}
                  error={errors.street}
                  value={values.street}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x">
              <Cell medium={6} className="js-country-cell">
                <InputSelect
                  label="Country"
                  name="country"
                  options={COUNTRIES}
                  error={errors.country}
                  value={COUNTRIES.find(option => option.value === values.country)}
                  changeHandler={(option) => setFieldValue("country", option.value)}
                />
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="City"
                  name="city"
                  changeHandler={handleChange}
                  error={errors.city}
                  value={values.city}
                />
              </Cell>
            </Grid>
            <Grid className="grid-margin-x grid-margin-y">
              <Cell medium={6} className="js-state-cell">
                {values.country == "United States" && <InputSelect
                  label="State"
                  name="state"
                  options={stateOptions}
                  error={errors.state}
                  value={stateOptions.find(option => option.value === values.state)}
                  changeHandler={(option) => setFieldValue("state", option.value)}
                />}
              </Cell>
              <Cell medium={6}>
                <InputField
                  type="text"
                  label="Zip/Postal"
                  name="zip"
                  changeHandler={handleChange}
                  error={errors.zip}
                  value={values.zip}
                />
              </Cell>
            </Grid>
            <Grid small={12}>
              <Cell large={3}>
                <InputButton
                  type="submit"
                  color="#1488B6"
                  value={isSubmitting ? 'Submitting..' : 'Save'}
                  name="submit-button"
                  disabled={!dirty || isSubmitting || !isEmpty(errors)}
                />
              </Cell>
            </Grid>
          </form>
      }
    </Formik>
  )
}

export default EntityForm
