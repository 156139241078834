import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'
import theme from '../../../config/theme'

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack};
  text-align: center;
  font-size: 32px;
  line-height: 1.2;
  margin: 40px 0 15px 0;

  ${breakpoint('medium')`
    margin: 40px 0 35px 0;
  `}

  ${breakpoint('large')`
    font-size: 3.4rem;
  `}
`

export const Subheading = styled.p`
  font-weight: 300;
`;

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const SubheadingContainer = styled.div`
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
`;

export const FormContainer = styled.div`
  margin-top: 44px !important;
  max-width: 400px;
  margin: 0 auto;

  &.error {
    border: 1px solid ${theme.colors.errorRed};
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
  }
`;

export const ButtonsContainer = styled.div`
  margin: 30px auto 30px auto;
  max-width: 450px;
`;

export const FinishProfileLaterContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const FinishProfileLaterLink = styled.a`
`;

export const BackButton = styled(Globals.ButtonSecondaryInverse)`
  margin-top: 35px;
  width: 100%;
  height: 51px;

  ${breakpoint('medium')`
    margin-top: 20px;
  `}
`;

export const ErrorMessage = styled.p`
  color: ${theme.colors.errorRed};
`;