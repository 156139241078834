import React, {Fragment} from 'react'
import {Cell, Grid} from 'react-foundation'
import styled, {ThemeProvider} from 'styled-components'
import theme from '../../config/theme'
import Navbar from '../../components/investor-dashboard/Navbar'
import Footer from '../../components/Footer'
import Mixpanel from '../../components/Mixpanel'
import {
  Content,
  Heading,
  Subheading,
  StepsContainer,
  StepNumber,
  StepHeading,
  StepDescription,
  LinkContainer,
  Button,
  Link,
  DarkDiamonds
} from './InvestorIntro.style'

const Page = styled.div`
  background-color: ${props => props.theme.colors.nightPurple}
`

class InvestorIntro extends React.Component {
  constructor(props) {
    super(props)
    this.goToDashboard = this.goToDashboard.bind(this)
  }

  /**
   * Heads to dashboard without onboarding. This gets treated as abandoned
   * profile completion. 
   */
  goToDashboard() {
    Mixpanel.identify(this.props.userId)
    Mixpanel.track('Abandoned profile completion')
    window.location = '/dashboard'
  }

  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Content>
              <Heading>Welcome to LexShares.</Heading>
              <Subheading>
                As a LexShares investor, you now have access to invest in 
                commercial litigation on our online platform. Here is what to 
                expect: 
              </Subheading>
              <StepsContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={4}>
                    <Grid className="grid-margin-x">
                      <Cell small={2}>
                        <StepNumber>1</StepNumber>
                      </Cell>
                      <Cell small={10}>
                        <StepHeading>
                          Get case launch notifications
                        </StepHeading>
                        <StepDescription>
                          You will receive email notifications when new legal 
                          claim investment opportunities become available. 
                        </StepDescription>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell small={12} medium={4}>
                    <Grid className="grid-margin-x">
                      <Cell small={2}>
                        <StepNumber>2</StepNumber>
                      </Cell>
                      <Cell small={10}>
                        <StepHeading>
                          Make an investment
                        </StepHeading>
                        <StepDescription>
                          When a new offering launches, you can read LexShares&apos; 
                          summary of the investment opportunity.
                        </StepDescription>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell small={12} medium={4}>
                    <Grid className="grid-margin-x">
                      <Cell small={2}>
                        <StepNumber>3</StepNumber>
                      </Cell>
                      <Cell small={10}>
                        <StepHeading>
                          Follow the litigation
                        </StepHeading>
                        <StepDescription>
                           If you decide to invest in a new LexShares offering, 
                           you follow case updates from your Investor Dashboard.
                        </StepDescription>
                      </Cell>
                    </Grid>
                  </Cell>
                </Grid>
                <LinkContainer>
                  <Button href="/investors/new">Complete your profile</Button>
                  <Link onClick={() => this.goToDashboard()}>Continue to Dashboard</Link>
                </LinkContainer>
              </StepsContainer>
            </Content>
            <DarkDiamonds />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    )
  }
}

export default InvestorIntro
