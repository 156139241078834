import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack};
  text-align: center;
  font-size: 32px;
  margin: 40px 0 35px 0;
  line-height: 1.2;

  ${breakpoint('large')`
    font-size: 3.4rem;
  `}
`
export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  margin: 30px auto 30px auto;
  height: 86px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const NextStepButton = styled.div`
  width: 200px;
`;

export const FinishProfileLaterContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const FinishProfileLaterLink = styled.a`
`;