import React, { useRef, useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from 'yup';
import InputField from '../../Form/InputField';
import { Alert, Container, Error, InputButtonContainer } from './Content.style';
import InputButton from '../../Form/InputButton';

const Content = ({ csrfToken, userPasswordPath, resetPasswordToken}) => {
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = ({password, password_confirmation}) => {
    const payload = {
      user: {
        reset_password_token: resetPasswordToken,
        password: password,
        password_confirmation: password_confirmation,
      },
      authenticity_token: csrfToken
    }

    axios.put(`${userPasswordPath}.json`, payload).then((response) => {
      window.location = response.data.location;
      setErrorMessage("");
    }).catch((e) => {
      setErrorMessage(e.response.data.error);
    }).then(() => {
      formRef.current.setSubmitting(false)
    })
  }
  const schema = yup.object().shape({
    //eslint-disable-next-line
    password: yup.string().required("Must contain at least one letter, one number and one special character.").min(8, "Must contain at least one letter, one number and one special character.").matches(/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/, "Must contain at least one letter, one number and one special character.").label("Password"),
    password_confirmation: yup.string().required("Must contain at least one letter, one number and one special character.").oneOf([yup.ref('password')], "Password does not match.").label("Password confirmation")
  })

  const defaultValues = {
    password: '',
    password_confirmation: '',
  }

  return(
    <Container>
      <Grid className="grid-x grid-margin-x">
        <Cell small={12} large={8} className="large-offset-2">
          <Formik
            initialValues={defaultValues}
            onSubmit={(e) => handleSubmit(e) }
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
          >
            {
              ({ errors, handleSubmit, handleChange, values, isSubmitting }) =>
                <form onSubmit={ handleSubmit }>
                  <Grid className="grid-margin-x">
                    <Cell small={12}>
                      {errorMessage && <Alert>Please review the problems below</Alert>}
                      <InputField
                        type="password"
                        label="New password"
                        name="password"
                        changeHandler={handleChange}
                        error={errors.password}
                        value={values.password}
                        autoFocus={true}
                      />
                    </Cell>
                    <Cell small={12}>
                      <InputField
                        type="password"
                        label="Confirm your new password"
                        name="password_confirmation"
                        changeHandler={handleChange}
                        error={errors.password_confirmation}
                        value={values.password_confirmation}
                      />
                    </Cell>
                  </Grid>
                  {errorMessage && <Error>{errorMessage}</Error>}
                  <InputButtonContainer>
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value="Change my password"
                      disabled={isSubmitting}
                    />
                  </InputButtonContainer>
                </form>
            }
          </Formik>
        </Cell>
      </Grid>
    </Container>
  )
}

export default Content;