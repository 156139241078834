import React, { Fragment } from 'react'
import {
  LsCheck,
  Input,
  CheckButton,
  TextContainer,
  Text,
  Error
} from './InputCheck.style'

function InputCheck({
  name, label = "Placeholder Text", changeHandler = null,
  error = null, blockFormatting = false, checked, data = {}}) {
  return (
    <Fragment>
      <LsCheck>
        <Input
          type="checkbox"
          name={name}
          onChange={changeHandler}
          defaultChecked={checked}
          {...data}
        />
	<CheckButton className={error ? 'error' : ''} />
	<TextContainer className={blockFormatting ? 'text-block' : ''} >
          <Text dangerouslySetInnerHTML={{__html: label}} />
	</TextContainer>
        {error &&
          <Error>
            {error}
          </Error>
        }
      </LsCheck>
    </Fragment>
  )
}

export default InputCheck
