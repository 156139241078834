import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';


export const Banner = styled(SB.Banner)`
`;

export const BannerContent = styled(SB.Content)`
`;

export const BannerProp = styled(SB.Diamonds)`
`;

export const Heading = styled(SB.Heading)`
  ${breakpoint('medium')`
    text-align: center;
  `}
  margin-bottom: 40px;
`;

export const Subheading = styled(SB.P) `
  ${breakpoint('medium')`
    text-align: center;
  `}
`;
