import styled from 'styled-components';
import * as Globals from '../Globals';
import footer from '../../../assets/images/plaintiffs/footer.jpg'
import breakpoint from 'styled-components-breakpoint';

export const Section = styled.div`
  background: url(${props => props.background ? props.background : footer}) no-repeat center center/cover;
  background-color: black;
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 10% 7%;
  max-width: 1235px;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
  text-align: center;
`;

export const Heading = styled(Globals.H2)`
  margin-bottom: 15px;
  text-align: center !important;
  color: white !important;
`;

export const Subheading = styled(Globals.P)`
  margin-bottom: 40px;
  text-align: center;
  color: white;
`;

export const Button = styled(Globals.ButtonLink)`
  min-width: ${props => props.largeButton ? '250px !important' : 'none'};
  width: ${props => props.largeButton ? '100%' : 'auto'};
`

export const SecondaryButton = styled(Globals.ButtonLink)`
  min-width: 185px;
  margin-right: 15px;
`

export const SecondaryButtonInverse = styled(SecondaryButton)`
  background: transparent !important;
  color: #FFFFFF;
  border: 2px solid white;
  padding: 13px 24px 13px 24px;
  margin-right: 0;
  &:hover {
    background: transparent !important;
    color: #FFFFFF !important;
    opacity: .7;
  }
`

export const Disclaimer = styled.div`
  font-size: 12px;
  text-align: left !important;
  color: #a5a4a4;
  margin-top: 20px;
  margin-bottom: -10px;
`
