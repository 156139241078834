import styled from 'styled-components'
import * as Globals from '../../../Globals'

export const Widget = styled(Globals.Widget)`
  min-height: 316px;
  padding: 31px 31px 10px 31px;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const Heading = styled(Globals.WidgetHeading)`
`

export const DateRange = styled(Globals.WidgetDateRange)`
`

export const Text = styled(Globals.WidgetText)`
`

export const Metric = styled.div`
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  color: white;
  margin-bottom: 10px;
`

export const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  color: ${props => props.theme.colors.widgetGrey};
`

export const Disclosure = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;
`
