import React from "react";
import { Cell, Grid } from "react-foundation";
import {
  Container,
  Description,
  Heading,
  ImageBackground,
  ImageContainer,
  ProductDescription,
  ProductHeading,
  SubHeading,
  ViewCaseButton,
  ButtonContainer,
} from "./InnovativeFinancialSection.style";
import InvestmentCardsSection from "../../funding/InvestmentCardsSection";
import CheckMark from '../../../../assets/images/icons/checkmark-in-shield.png'
import DollarSign from '../../../../assets/images/icons/dollar-sign.png';
import Gavel from '../../../../assets/images/icons/gavel.png';

const InnovativeFinancialSection = () => {
  const products = [
    {
      src: CheckMark,
      heading: "Reduce Risk",
      description:
        "With fully non-recourse funding, there is no obligation to repay if the case is lost.",
    },
    {
      src: DollarSign,
      heading: "Control Costs",
      description:
        "Open up alternative fee arrangement possibilities for clients with limited access to capital.",
    },
    {
      src: Gavel,
      heading: "Optimize Outcomes",
      description:
        "Financing helps litigants stay the course without settling for less than the damages merit.",
    },
  ];

  return (
    <Container>
      <Heading>Innovative financial products for your litigation</Heading>
      <Grid>
        {products.map((product, i) => (
          <Cell small={12} medium={4} key={i}>
            <ImageContainer>
              <ImageBackground>
                <img src={product.src} />
              </ImageBackground>
            </ImageContainer>
            <ProductHeading>{product.heading}</ProductHeading>
            <ProductDescription>{product.description}</ProductDescription>
          </Cell>
        ))}
      </Grid>
      <SubHeading>$100 million funded, and counting</SubHeading>
      <Description>
        Since its inception in 2014, LexShares has provided strategic capital to
        a wide range of companies and law firms to help them pursue their legal
        claims.
      </Description>
      <ButtonContainer>
        <ViewCaseButton href="/cases">View Case Portfolio</ViewCaseButton>
      </ButtonContainer>
      <InvestmentCardsSection />
    </Container>
  );
};

export default InnovativeFinancialSection;
