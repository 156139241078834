import React, { useRef } from 'react';
import { Cell, Grid } from 'react-foundation';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from 'yup';
import InputField from '../../Form/InputField';
import { Container, ForgotPasswordLink, InputButtonContainer } from './Content.style';
import InputButton from '../../Form/InputButton';

const Content = ({newUserPasswordPath, csrfToken, userSessionPath, setAlertMessage}) => {
  const formRef = useRef(null);
  const handleSubmit = ({email, password}) => {
    const payload = {
      user: {
        email: email,
        password: password,
      },
      authenticity_token: csrfToken
    }

    axios.post(`${userSessionPath}.json`, payload).then((response) => {
      window.location = response.data.location;
      setAlertMessage("");
    }).catch((e) => {
      setAlertMessage(e.response.data.error);
    }).then(() => {
      formRef.current.setSubmitting(false);
    })
  }
  const schema = yup.object().shape({
    email: yup.string().email().required('Required').label("Email"),
    password: yup.string().required('Required').label("Password"),
  })

  const defaultValues = {
    email: '',
    password: '',
  }

  return(
    <Container>
      <Grid className="grid-x grid-margin-x">
        <Cell small={12} large={8} className="large-offset-2">
          <Formik
            initialValues={defaultValues}
            onSubmit={(e) => handleSubmit(e) }
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={true}
            innerRef={formRef}
          >
            {
              ({ errors, handleSubmit, handleChange, values, isSubmitting, isValid }) =>
                <form onSubmit={ handleSubmit }>
                  <Grid className="grid-margin-x">
                    <Cell small={12}>
                      <InputField
                        type="email"
                        label="Email"
                        name="email"
                        changeHandler={handleChange}
                        error={errors.email}
                        value={values.email}
                        autoFocus={true}
                      />
                    </Cell>
                    <Cell small={12}>
                      <InputField
                        type="password"
                        label="Password"
                        name="password"
                        changeHandler={handleChange}
                        error={errors.password}
                        value={values.password}
                      />
                    </Cell>
                  </Grid>
                  <InputButtonContainer>
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value="Log in"
                      disabled={isSubmitting || !isValid}
                    />
                  </InputButtonContainer>
                </form>
            }
          </Formik>
          <ForgotPasswordLink href={newUserPasswordPath}>Forgot password?</ForgotPasswordLink>
        </Cell>
      </Grid>
    </Container>
  )
}

export default Content;