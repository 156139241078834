import React from 'react';
import { Container, Heading, Subheading } from './InfoCard.style';

const InfoCard = ({heading, subheading}) => {
  return(
    <Container>
      <Heading>{heading}</Heading>
      <Subheading>{subheading}</Subheading>
    </Container>
  )
}

export default InfoCard;