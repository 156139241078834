import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import {
  Section, Content, BannerProp, Heading, Subheading, MediaContent, Button, ButtonInverse
} from './BannerSection.style';
import BannerImage from '../../../../assets/images/shared/banner-prop.svg';
import PressLogo from '../../../../assets/images/logos/press-logo-spread-persona-white.png';

function BannerSection() {
  return (
    <Fragment>
      <Section>
        <BannerProp>
          <img src={BannerImage} />
        </BannerProp>

        <Content>
          <Grid>
            <Cell large={7}>
              <Heading>
                Over 100 cases funded, and we’re just getting started.
              </Heading>
              <Subheading>
                Learn more about how LexShares can provide funding solutions for your litigation, helping create flexibility and reduce risk.
              </Subheading>

              <Grid className="grid-margin-x grid-margin-y">
                <Cell medium={4} small={12}>
                  <Button href="/funding/case">
                    Submit a Case
                  </Button>
                </Cell>

                <Cell medium={4} small={12}>
                  <ButtonInverse href="/guide">
                    Read our Guide
                  </ButtonInverse>
                </Cell>
              </Grid>

              <MediaContent>
                <img src={PressLogo} />
              </MediaContent>
            </Cell>
          </Grid>
        </Content>
      </Section>
    </Fragment>
  )
}

export default BannerSection;
