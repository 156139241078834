import React, {Fragment, useState, useEffect, useCallback} from 'react'
import GhostContentAPI from '@tryghost/content-api'
import Moment from 'react-moment'
import Mixpanel from '../../../../Mixpanel'
import {
  Widget,
  Content,
  TitleBar,
  Logo,
  Title,
  DateStamp,
  Image,
  Container,
  Heading,
  Text
} from './BlogWidget.style'

/**
 * Takes in a slug and retrieves the blog article summary and image
 * from the LexShares Ghost blog.
 */
function BlogWidget({ghostUrl, ghostKey, slugName}) {
  let [article, setArticle] = useState(null);

  const fetchPost = useCallback(async () => {
    const api = new GhostContentAPI({
      url: ghostUrl,
      key: ghostKey,
      version: 'v3'
    })
    const content = await api.posts
      .read({
        slug: slugName
      })
      .catch(err => {
        console.error(err);
      })
    setArticle(content)
  }, [])

  useEffect(() => {
    fetchPost()
  }, [])

  const handleClick = () => {
    // Opens article in a new window.
    window.open(`/blog/${slugName}`)

    // Tracks click via Mixpanel.
    Mixpanel.track('Clicked content widget', {
      contentType: 'Blog',
      contentDate: article.published_at,
      contentTitle: article.title
    })
  }

  return (
    <Widget onClick={handleClick} className="js-widget js-blog-widget">
      <Content loading={article ? "false" : "true"}>
        { article &&
          <Fragment>
            <TitleBar>
              <Logo />
              <Title>LexShares Investing Resources</Title>
              <DateStamp>
                <Moment format="MMMM YYYY">{article.published_at}</Moment>
              </DateStamp>
            </TitleBar>
            <Image source={article.feature_image}/>
            <Container>
              <Heading>
                {article.title}
              </Heading>
              <Text>
                {article.meta_description}
              </Text>
            </Container>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default BlogWidget
