import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import { BannerSection, ContentSection } from '../../components/profile'
import theme from '../../config/theme';
import Navbar from '../../components/investor-dashboard/Navbar'
import Footer from '../../components/Footer'

const ProfilePage = styled.div`
  background-color: ${props => props.theme.colors.white};
`

class Profile extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
            hasTaxDocs={this.props.investor.has_tax_docs}
            hasInvestments={this.props.investor.has_investments}
            unseenNotifications={this.props.investor.unseen_notifications}
          />
          <ProfilePage>
            <BannerSection />
            <ContentSection investor={this.props.investor} csrfToken={this.props.csrfToken} />
          </ProfilePage>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Profile;
