import React, { useEffect, useState } from 'react'
import { ReadingProgressBar } from './ReadingProgress.style';
import Mixpanel from '../Mixpanel';

const ReadingProgress = ({ article, target }) => {
  const [tracked, setTracked] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element         = target.current; 
    const totalHeight     = element.clientHeight - element.offsetTop - (window.innerHeight) + 100;
    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (windowScrollTop === 0) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      if (!tracked) {
        const publishDate = new Date(article.published_at);
        const tag = article.tags.find(t => ['funding-insights', 'regulations-trends'].includes(t.slug))

        const resourceType = `${tag.name} — Article`;
        Mixpanel.track('Scrolled entire article', {
          resourceTitle: article.title,
          publishDate: publishDate.toISOString(),
          authorName: article.authors ? article.authors.map(author => author.name).join(', ') : '',
          resourceType: resourceType,
        });
      }
      setTracked(true);
      return setReadingProgress(100);
    }

    setReadingProgress((windowScrollTop / totalHeight) * 100);
  };
  
  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  });

  return <ReadingProgressBar readingProgress={readingProgress} />;
};

export default ReadingProgress;