import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Intro, Content, Heading, Subheading, Bold, Button, ButtonContainer } from './AccelerateSection.style';

function AccelerateSection() {
  return (
    <Intro>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Accelerate growth with law firm funding
              </Heading>
              <Subheading>
                Law firm financing is an effective mechanism for attorneys and law firms seeking to ease the lumpy cash flow cycle associated with contingency fee and commercial legal practices.
              </Subheading>
              <Subheading>
                Law firm funding provides liquidity for attorneys and law firms engaged in a diversified practice, allowing them to focus on litigating cases, and improving litigation outcomes against well-capitalized defendants.
              </Subheading>
              <Subheading>
                <Bold>Improved Cash Flow & Operating Capital</Bold>
                Attorneys and law firms require a steady flow of working capital to effectively run their practice. It can be difficult to succeed in a competitive market if faced with delayed payments, out of pocket litigation costs, and protracted cases. LexShares enables attorneys and law firms to monetize illiquid assets, generate working capital, and build a cushion for personal expenses, when necessary.
              </Subheading>
              <Subheading>
                <Bold>Growth of Your Law Firm & Legal Practice</Bold>
                Without adequate funding, attorneys and law firms might otherwise be unable to execute marketing campaigns to acquire new clients. LexShares’ law firm funding enables attorneys and law firms to expand their reach.
              </Subheading>
              <Subheading>
                <Bold>Acquisition of Top Legal Talent & Expert Witnesses</Bold>
                The outcome of a case and size of the recovery often depends on the testimony of an expert witness. However, expert witnesses can be expensive, with rates that can exceed $1,000 per hour and engagements sometimes lasting weeks or months.
              </Subheading>
              <Subheading>
                To ease the costly and time-consuming process of identifying, vetting, engaging with, and paying trusted litigation support, LexShares partnered with <a target="_blank" href="https://glg.it/law" rel="noreferrer">GLG Law</a>, one of the leading expert witness services in the legal industry. LexShares’ partnership with GLG creates a more unified, efficient experience for law firms and clients striving to improve litigation outcomes by securing highly regarded expert witnesses.
              </Subheading>
            </Cell>
          </Grid>
          <ButtonContainer>
            <Button href="/funding/case">
              Get Funding
            </Button>
          </ButtonContainer>
        </Section>
      </Content>
    </Intro>
  )
}

export default AccelerateSection;
