import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import theme from '../../../config/theme';
import * as Globals from '../../Globals'

export const Container = styled.div`
  display: none;
  overflow: auto;

  ${breakpoint('medium')`
    transition: 1s;
    display: block;
    position: fixed;
    padding: 23px 27px 120px 27px;
    width: 370px;
    top: 80px;
    background: ${theme.colors.darkestPurple};
    height: 100%;
    z-index: 1;
    left: 0px;

    &.slideout {
      left: 250px;
    }
  `}

  ${breakpoint('large')`
    left: 0px;

    &.slideout {
      left: 280px;
    }
  `}
`;

export const SubText = styled.p`
  ${Globals.proximaFontStack}
  font-size: 11px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  margin-bottom: 8px;
`

export const ImageTile = styled.img`
  width: 317px;
  background: linear-gradient(0deg, rgba(130, 71, 186, 0.5), rgba(130, 71, 186, 0.5)), url(${props => props.src});
  background-blend-mode: overlay, normal;
  margin-bottom: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const MainTitle = styled.a`
  ${Globals.tiempoFontStack}
  color: ${theme.colors.white};
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 45px;
  display: block;

  &:hover {
    color: ${theme.colors.white};
  }
`;

export const Topic = styled.p`
  ${Globals.proximaFontStack}
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  margin-bottom: 15px;
`;

export const Title = styled.a`
  ${Globals.tiempoFontStack}
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  color: ${theme.colors.white};
  display: block;

  &:hover {
    color: ${theme.colors.white};
  }
`;

export const Link = styled.a`
  ${Globals.proximaFontStack}
  color: ${theme.colors.mediumPurple};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    color: ${theme.colors.mediumPurple};
  }
`;