import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../../Globals'

export const Heading = styled(Globals.H2)`
  margin-bottom: 25px;
`

export const Subheading = styled.p`
  text-align: center;
`

export const ButtonContainer = styled.div`
  margin-top: 40px;
  text-align: center;
  ${breakpoint('medium')`
    margin-top: 20px;
    float: right;
  `};
`

export const ConfirmButton = styled(Globals.ButtonLink)`
`

export const CancelLink = styled.a`
  margin-right: 25px;
  margin-top: 13px;
  display: block;
`

export const Clear = styled.div`
  clear: both;
`
