import React, {Fragment, useState} from 'react'
import {Cell, Grid } from 'react-foundation'
import NumberFormat from 'react-number-format'
import ReactTooltip from 'react-tooltip'
import ModalPop from '../../ModalPop'
import Mixpanel from '../../Mixpanel'
import {
  Content,
  Links,
  Link,
  Controls,
  InvestmentButton,
  MinMaxAmount,
  Count,
  FundingAmounts,
  Tip,
  PopButton,
  PopHeader,
  PopCopy,
  TipHeading,
  TipInfo
} from './EngagementControls.style'

function EngagementControls({
  investButtonEnabled, investButtonText, minimumInvestment, investButtonHandler,
  tabLinkHandler, documentCount, currentTab, fundedOrResolved = false,
  marketplaceFund, maximumInvestment}) {

  const [popVisible, setPopVisible] = useState(false)

  const hidePop = () => {
    setPopVisible(false)
    Mixpanel.track('Dismissed Max Learn More')
  }

  const showPop = () => {
    if (maximumInvestment) {
      setPopVisible(true)
      Mixpanel.track('Clicked Investment Max Learn More')
    }
  }

  const toolTipBody = () => {
    if (maximumInvestment) {
      return (
        <Fragment>
          <TipHeading>Minimums and Maximums</TipHeading>
          <TipInfo>
            Investment minimums and maximums can vary based on deal-specific factors. Click to learn more.
          </TipInfo>
        </Fragment>
      )
    } else {
      return null
    }
  }

  const popContent =
    <Fragment>
      <PopHeader>Investment Maximums</PopHeader>
      <PopCopy>
        In response to high investor demand, LexShares may set a maximum investment
        amount based on the offering size and other deal-specific factors.
      </PopCopy>
      <PopCopy>
        This is designed to help create more equitable access for those interested
        in participating in LexShares investment opportunities.
      </PopCopy>
      <PopCopy>
        If you have questions or concerns regarding our investment minimums or
        maximums, we encourage you to email investors@lexshares.com
      </PopCopy>
      <PopButton onClick={() => hidePop()}>OK</PopButton>
    </Fragment>

  return (
    <Fragment>
      <Content>
        <Grid className="grid-margin-x">
          <Cell medium={12} large={6} className="small-text-center">
            <Links>
              <Link
                onClick={tabLinkHandler}
                className={currentTab == "tab-1" ? "selected js-background-link" : "js-background-link"}
                data-tab="tab-1">
                {marketplaceFund ? 'Investments' : 'Case Details'}
              </Link>
              <Link
                onClick={tabLinkHandler}
                className={currentTab == "tab-2" ? "selected js-documents-link" : "js-documents-link"}
                data-tab="tab-2">
                Documents
                <Count>{documentCount}</Count>
              </Link>
            </Links>
          </Cell>
          <Cell medium={12} large={6} className="small-text-center">
           { !marketplaceFund &&
              <Controls>
                { !fundedOrResolved &&
                  <Fragment>
                    <FundingAmounts
                      onClick={() => showPop()}
                      data-place="bottom"
                      data-name="min-max"
                      data-tip=""
                      data-for="hint-hover"
                    >
                      { maximumInvestment && <Tip>i</Tip> }
                      <MinMaxAmount className="js-minimum-investment">
                        <NumberFormat
                          value={minimumInvestment}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                          renderText={value => <Fragment><span className="amount">{value} </span><span className="description">{maximumInvestment ? 'min' : 'minimum'}</span></Fragment>}
                        />
                      </MinMaxAmount>
                      { maximumInvestment &&
                        <MinMaxAmount className="js-maximum-investment">
                          <NumberFormat
                            value={maximumInvestment}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => <Fragment><span className="amount">{value} </span><span className="description">max</span></Fragment>}
                          />
                        </MinMaxAmount>
                      }
                    </FundingAmounts>
                  </Fragment>
                }
                <InvestmentButton
                  className="js-invest-button" 
                  disabled={!investButtonEnabled} 
                  onClick={investButtonHandler}
                >
                  {investButtonText}
                </InvestmentButton>
              </Controls>
            }
          </Cell>
        </Grid>
      </Content>
      <ModalPop
        popVisible={popVisible}
        content={popContent}
        hideHandler={hidePop}
        size="medium"
        disableClose={true}
      />
      <ReactTooltip id="hint-hover" getContent={toolTipBody} />
    </Fragment>
  )
}

export default EngagementControls
