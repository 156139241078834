import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';

export const Container = styled.div`
  padding: 60px 20px 80px 20px;

  ${breakpoint("medium")`
    padding: 60px 60px 80px 60px;
  `}
`;  

export const Form = styled.form`
`;

export const InputSubmit= styled.input`
  text-transform: uppercase;
  width: 50%;
  height: 49px;
  background: ${theme.colors.purple};
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-weight: 800;
  line-height: 13px;
  letter-spacing: 2px;
  ${Globals.proximaFontStack};
  color: ${theme.colors.white};
  cursor: pointer;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid ${theme.colors.purple} !important;
  input

  ${breakpoint('medium')`
    display: inline-block;
    border-radius: 3px !important;
    max-width: 210px;
    width: 55%;
  `}

  ${breakpoint('large')`
    display: inline-block;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  `}

  ${breakpoint('small')`

  `}

  &:hover {
    color: white;
    background-color: ${theme.colors.backgroundPurple};
  }

  &:disabled {
    color: white;
    background-color: ${theme.colors.backgroundPurple};
  }
`;

export const ErrorMessage = styled.div`
`;

export const EmailDisclaimer = styled.div`
  font-size: 13px;
`;  