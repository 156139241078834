import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Intro, Content, Heading, Subheading, Bold, LinkContainer, Link } from './BenefitsSection.style';

function BenefitsSection() {
  return (
    <Intro>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Law firm funding offers a number of benefits
              </Heading>
              <Subheading>
                <Bold>Capital for Litigation Expenses</Bold>
                Law firm funding is an essential method for paying expert witness fees, court expenses, and numerous other litigation costs, all while diversifying risk. Law firm financing also helps offset and reduce the volatile and potentially negative accounting impacts associated with contingency work.
              </Subheading>
              <Subheading>
                <Bold>Reduced Risk For Law Firms</Bold>
                Our law firm funding is non-recourse, which means that if you are unsuccessful in your settlement, judgment, or growth venture, you do not have to repay the lawsuit financing. Our capital is solely collateralized by your firm’s fees and we only collect a return in the event of a positive settlement or recovery from a judgment.
              </Subheading>
              <Subheading>
                <Bold>Flexible Payment Arrangements</Bold>
                Advanced overhead and working capital enables law firms to provide prospective clients with flexible and alternative fee arrangements, and ensures highly trained associates and appropriate resources are used for the case at hand.
              </Subheading>
              <Subheading>
                <Bold>Fee Acceleration</Bold>
                After a settlement, receiving payment can take longer than anticipated. Litigation funding helps avoid cash flow delays by injecting capital upfront after the case has been settled while the process of receiving the settlement is ongoing.
              </Subheading>
            </Cell>
          </Grid>
          <LinkContainer>
            <Link href="/guide">
              Read our litigation finance 101 guide
            </Link>
          </LinkContainer>
        </Section>
      </Content>
    </Intro>
  )
}

export default BenefitsSection;
