import React, { Fragment } from 'react'
import InputCheck from '../../../../components/Form/InputCheck'
import { Error } from '../../../../components/Form/Globals'

import {
  Heading,
  Subheading,
  CheckContainer,
  ErrorsContainer,
} from './InvestmentRepresentations.style'

function InvestmentRepresentations({error,
  representationHandler, representationOneCheck, representationTwoCheck,
  representationThreeCheck}) {

  const representationOne = `I represent that I have such knowledge and 
  experience in financial and business matters that I am capable of evaluating 
  the merits and risks of investments in private placements through LexShares, 
  and I am able to bear the economic risk of these investments including the 
  risk of a complete loss.`

  const representationTwo = `I (a) understand that LexShares will rely on the 
  foregoing representations to, among other things, maintain compliance with 
  securities laws, (b) certify that my answers to the foregoing questions are 
  correctly stated to the best of my knowledge, information and belief, (c) 
  further agree that LexShares may require (in order for me to remain a member 
  of LexShares or participate in any investment) that I (or my accountant or 
  attorney) may furnish any additional information to LexShares that it deems 
  reasonably necessary in order to verify my answers to foregoing questions.`

  const representationThree = `By checking this box, I represent that I have 
  reviewed, understood, and agree to be bound to the LexShares 
  <a href="/terms-of-use" target="_blank">Terms of Use</a> and 
  <a href="/privacy-policy" target="_blank">Privacy Policy</a> and other 
  policies posted on this site, each as amended from time to time.`

  return (
    <Fragment>
      <Heading>Representations</Heading>
      <Subheading>
         Please read and agree to each of the below representations. 
      </Subheading>
      <CheckContainer className={error.representationCount ? 'error' : null}>
        <InputCheck
          changeHandler={representationHandler}
          label={representationOne}
          name="representation-count"
          checked={representationOneCheck}
          data={{'data-index': 1}}
          blockFormatting={true} 
        />
        <InputCheck 
          changeHandler={representationHandler}
          label={representationTwo}
          name="representation-count"
          checked={representationTwoCheck}
          data={{'data-index': 2}}
          blockFormatting={true}
        />
        <InputCheck
          changeHandler={representationHandler}
          label={representationThree}
          name="representation-count"
          checked={representationThreeCheck}
          data={{'data-index': 3}}
          blockFormatting={true}
        />
      </CheckContainer>
      <ErrorsContainer>
        { error.representationCount &&
          <Error>{error.representationCount}</Error>
        }
      </ErrorsContainer>
    </Fragment>
  )
}

export default InvestmentRepresentations
