import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as SB from './../../SimpleBanner';


export const Banner = styled(SB.Banner)`
`;

export const BannerContent = styled(SB.Content)`
`;

export const BannerProp = styled(SB.Diamonds)`
`;

export const Medium = styled.div`
  padding: 0 10px;
  margin: 20px 0 0 0;

  img {
    max-width: 100px;
    max-height: 13px;
    width: auto;
    ${breakpoint('medium')`
      max-height: 20px;
    `}
  }

  ${breakpoint('medium')`
    display: block;
    margin: 0 15px;
    padding: 0 10px;
  `}

  ${breakpoint('large')`
    display: block;
    margin: 0 4%;
  `}
`

export const MediaContent = styled(SB.Content)`
  display: flex;
  justify-content: center;
  padding: 0 3%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  ${breakpoint('medium')`
    padding: 0 3%;
  `}
`

export const Heading = styled(SB.Heading)`
  cursor: pointer;
  ${breakpoint('medium')`
    text-align: center;
  `}
  margin-bottom: 40px;
`;

export const Subheading = styled(SB.Subheading)`
  ${breakpoint('medium')`
    text-align: center;
  `}
`;
