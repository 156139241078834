import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import * as SB from "../../components/SimpleBanner";
import About from "../../components/BarometerLinkedin/About/About";
import BarometerBanner from "../../components/BarometerLinkedin/Banner/Banner";
import TrackRecord from "../../components/BarometerLinkedin/TrackRecord/TrackRecord";
import WhatWeDo from "../../components/BarometerLinkedin/WhatWeDo/WhatWeDo";
import BarometerDownloadedPop from "../../components/barometer/BarometerDownloadedPop/BarometerDownloadedPop";
import theme from "../../config/theme";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import HeaderWave from "../../../assets/images/barometer_linkedin/header-wave.png";
import PurpleHero from "../../../assets/images/barometer_linkedin/purple-ombre-background.png";
import Wave from "../../../assets/images/barometer_linkedin/wave.png";

const BannerWave = styled(SB.Banner)`
  background: url(${HeaderWave}) no-repeat center center/cover;
  width: 100%;
  height: 612px;
  position: absolute;
  margin-top: 237px;
  padding: 0px !important;
`;

const PurpleBackground = styled(SB.Banner)`
  background: url(${PurpleHero}) no-repeat center center/cover;
  width: 100%;
  height: 100%;
  padding: 0px !important;
`;

const WaveBackground = styled(SB.Banner)`
  background: url(${Wave}) no-repeat center center/cover;
  width: 100%;
  height: 820px;
  position: absolute;
  padding: 0px !important;
  margin-top: -75px;
`;

class BarometerLinkedin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationPopup: this.props.confirmationPopup,
    };
  }
  render() {
    const setShowPop = (state) => {
      this.setState({
        confirmationPopup: state,
      });
    };
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <div>
            <BannerWave id="banner-wave" />
            <BarometerBanner csrfToken={this.props.csrfToken} />
            <PurpleBackground id="purple">
              <About />
              <WaveBackground />
              <TrackRecord />
              <WhatWeDo />
            </PurpleBackground>
          </div>
          {this.state.confirmationPopup && (
            <BarometerDownloadedPop setShowPop={setShowPop} />
          )}
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default BarometerLinkedin;
