import * as yup from 'yup'
import React, { useState } from 'react';
import { get } from 'lodash';
import { Grid, Cell } from 'react-foundation';
import axios from 'axios';
import { Formik } from 'formik';
import InputField from '../../Form/InputField';
import InputButton from '../../Form/InputButton'
import InputCheck from '../../Form/InputCheck';
import InputSelect from '../../Form/InputSelect';
import ProgressBar from '../../ProgressBar';
import { AccountTypeContainer, BackButton, ButtonsContainer, Container, Heading, ImageContainer, IraStreetAddressDescription, AccountType, RemoveAccount } from './OnboardingStepTwoContinued.style';
import { COUNTRIES, US_STATES } from '../../../config/constants.js.erb'
import FocusError from '../../FocusError/FocusError';
import BusinessImg from '../../../../assets/images/icons/business-area.png';
import GovernmentImg from '../../../../assets/images/icons/government.png';
import PortfolioImg from '../../../../assets/images/icons/portfolio.png';

const stateOptions = [{label: "Select a state", value: ''}, ...US_STATES];

const OnboardingStepTwoContinued = ({csrfToken, investor, accountTypes, setAccountTypes, setOnboardingStep, setCurrentInvestor}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const removeAccount = (event, account) => {
    event.preventDefault();

    const moveBack = [accountTypes.account_entity, accountTypes.account_trust, accountTypes.account_ira].filter(a => !a).length === 2
    if (moveBack) {
      setOnboardingStep(2);
    }

    setAccountTypes({
      ...accountTypes,
      [account]: false,
    })
  }

  const schema = yup.object().shape({
    entity_name: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("Legal entity name")
    }),
    entity_representative_position: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("Representative position")
    }),
    entity_representative_first_name: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("Representative first name"),
    }),
    entity_representative_last_name: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("Representative last name"),
    }),
    entity_phone: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().when('entity_address_country', {
        is: "United States",
        then: yup.string().required().matches(/^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be (000) 000-0000"}),
      }).when('entity_address_country', {
        is: "Canada",
        then: yup.string().required().matches(/^\+1 (\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be +1 (000) 000-0000"}),
      }).label("Phone"),
    }),
    entity_phone_extension: yup.string().label("Phone extension"),
    entity_address_street: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("Street"),
    }),
    entity_address_city: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("City"),
    }),
    entity_address_state: yup.string().when('entity_address_country', {
      is: "United States",
      then: yup.string().required()
    }).label("State"),
    entity_address_zip: yup.string().when('entity_address_country', {
      is: "United States",
      then: yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}),
    }).label("Zip"),
    entity_address_country: yup.string().when('show_entity_form', {
      is: true,
      then: yup.string().required().label("Country"),
    }),

    trust_name: yup.string().when('show_trust_form', {
      is: true,
      then: yup.string().required().label("Trust name"),
    }),
    trust_trustee_name: yup.string().when('show_trust_form', {
      is: true,
      then: yup.string().required().label("Trustee name"),
    }),
    trust_phone: yup.string().when('show_trust_form', {
      is: true,
      then: yup.string().required().when('trust_address_country', {
        is: "United States",
        then: yup.string().required().matches(/^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be (000) 000-0000"}),
      }).when('trust_address_country', {
        is: "Canada",
        then: yup.string().required().matches(/^\+1 (\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/, {message: "Phone format must be +1 (000) 000-0000"}),
      }).label("Phone"),
    }),
    trust_phone_extension: yup.string().label("Phone extension"),
    trust_address_street: yup.string().when('show_trust_form', {
      is: true,
      then: yup.string().required().label("Street"),
    }),
    trust_address_city: yup.string().when('show_trust_form', {
      is: true,
      then: yup.string().required().label("City"),
    }),
    trust_address_state: yup.string().when('trust_address_country', {
      is: "United States",
      then: yup.string().required()
    }).label("State"),
    trust_address_zip: yup.string().when('trust_address_country', {
      is: "United States",
      then: yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}),
    }).label("Zip"),
    trust_address_country: yup.string().when('show_trust_form', {
      is: true,
      then: yup.string().required().label("Country"),
    }),
    trust_irrevocable: yup.boolean(),

    ira_title: yup.string().when('show_ira_form', {
      is: true,
      then: yup.string().required().label("Account title"),
    }),
    ira_address_street: yup.string().when('show_ira_form', {
      is: true,
      then: yup.string().required().label("Street"),
    }),
    ira_address_city: yup.string().when('show_ira_form', {
      is: true,
      then: yup.string().required().label("City"),
    }),
    ira_address_state: yup.string().when('show_ira_form', {
      is: true,
      then: yup.string().required().label("State"),
    }),
    ira_address_zip: yup.string().when('show_ira_form', {
      is: true,
      then:  yup.string().required().matches(/^[0-9]{5}$/, {message: "Zip code format must be 00000"}).label("Zip"),
    }),
  })

  const phoneFormat = (country) => {
    if(country == "United States") {
      return "(###) ###-####"
    }

    if(country == "Canada") {
      return "+1 (###) ###-####"
    }
  }

  const defaultValues = {
    show_entity_form: accountTypes.account_entity,
    entity_name: get(investor, 'entity.name') || '',
    entity_representative_position: get(investor, 'entity.representative_position') || '',
    entity_representative_first_name: get(investor, 'entity.representative_first_name') || '',
    entity_representative_last_name: get(investor, 'entity.representative_last_name') || '',
    entity_phone: get(investor, 'entity.phone') || '',
    entity_phone_extension: get(investor, 'entity.phone_extension') || '',
    entity_address_street: get(investor, 'entity.address.street') || '',
    entity_address_city: get(investor, 'entity.address.city') || '',
    entity_address_state: get(investor, 'entity.address.state') || '',
    entity_address_zip: get(investor, 'entity.address.zip') || '',
    entity_address_country: get(investor, 'entity.address.country'),
    entity_address_id: get(investor, 'entity.address.id') || '',

    show_trust_form: accountTypes.account_trust,
    trust_name: get(investor, 'trust.name') || '',
    trust_trustee_name: get(investor, 'trust.trustee') || '',
    trust_phone: get(investor, 'trust.phone') || '',
    trust_phone_extension: get(investor, 'trust.phone_extension') || '',
    trust_address_street: get(investor, 'trust.address.street') || '',
    trust_address_city: get(investor, 'trust.address.city') || '',
    trust_address_state: get(investor, 'trust.address.state') || '',
    trust_address_zip: get(investor, 'trust.address.zip') || '',
    trust_address_country: get(investor, 'trust.address.country'),
    trust_irrevocable: get(investor, 'trust.irrevocable'),

    show_ira_form: accountTypes.account_ira,
    ira_title: get(investor, 'retirement_account.name') || '',
    ira_address_street: get(investor, 'retirement_account.address.street') || '',
    ira_address_city: get(investor, 'retirement_account.address.city') || '',
    ira_address_state: get(investor, 'retirement_account.address.state') || '',
    ira_address_zip: get(investor, 'retirement_account.address.zip') || '',
    ira_address_id: get(investor, 'retirement_account.address.id') || '',
  }

  const handleSubmit = ({entity_name, entity_representative_position, entity_representative_first_name, entity_representative_last_name, entity_phone, entity_phone_extension, entity_address_street, entity_address_city, entity_address_state, entity_address_zip, entity_address_country, entity_address_id, trust_name, trust_trustee_name, trust_phone, trust_phone_extension, trust_address_street, trust_address_city, trust_address_state, trust_address_zip, trust_address_country, trust_irrevocable, ira_title, ira_address_street, ira_address_city, ira_address_state, ira_address_zip}) => {
    setIsSubmitting(true);
    const payload = {
      investor: {
        account_individual: accountTypes.account_individual ? 1 : 0,
        account_entity: accountTypes.account_entity ? 1 : 0,
        account_trust: accountTypes.account_trust ? 1 : 0,
        account_ira: accountTypes.account_ira ? 1 : 0,
        entity_attributes: {
          name: entity_name,
          representative_position: entity_representative_position,
          representative_first_name: entity_representative_first_name,
          representative_last_name: entity_representative_last_name,
          phone: entity_phone,
          phone_extension: entity_phone_extension,
          address_attributes: {
            street: entity_address_street,
            city: entity_address_city,
            state: entity_address_state,
            zip: entity_address_zip,
            country: entity_address_country,
            id: entity_address_id,
          }
        },
        trust_attributes: {
          name: trust_name,
          trustee: trust_trustee_name,
          phone: trust_phone,
          phone_extension: trust_phone_extension,
          address_attributes: {
            street: trust_address_street,
            city: trust_address_city,
            state: trust_address_state,
            zip: trust_address_zip,
            country: trust_address_country,
          },
          irrevocable: trust_irrevocable,
        },
        retirement_account_attributes: {
          name: ira_title,
          address_attributes: {
            street: ira_address_street,
            city: ira_address_city,
            state: ira_address_state,
            zip: ira_address_zip,
          }
        },
        has_completed_profile: 0,
      },
      authenticity_token: csrfToken
    }
    axios.patch(`/investors/${investor.id}.json`, payload).then((response) => {
      setOnboardingStep(3);
      setCurrentInvestor(response.data.investor);
    }).catch((e) => {
      setIsSubmitting(false);
      const shared_ui = new LexShares.SharedUI();
      shared_ui.showAlert('error', e.response.data.error, false);
    })
  }

  return(
    <Container>
      <ProgressBar step={2} steps={5} percent={50} />
      <Heading>Connect Account - Continued</Heading>
      <Formik
        initialValues={defaultValues}
        onSubmit={(e) => handleSubmit(e)}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {
          ({ errors, handleSubmit, handleChange, values, setFieldValue, isValid }) =>
            <form onSubmit={ handleSubmit }>
              <FocusError />
              {accountTypes.account_entity && <>
                <AccountTypeContainer>
                  <ImageContainer>
                    <img src={BusinessImg} />
                  </ImageContainer>
                  <AccountType>Entity</AccountType>
                  <RemoveAccount href="#" onClick={(event) => removeAccount(event, 'account_entity')}>X Remove</RemoveAccount>
                </AccountTypeContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="Legal entity name"
                      name="entity_name"
                      placeholder="New Frontier, LLC"
                      changeHandler={handleChange}
                      error={errors.entity_name}
                      value={values.entity_name}
                    />
                  </Cell>
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="Representative Position"
                      name="entity_representative_position"
                      placeholder="CEO"
                      changeHandler={handleChange}
                      error={errors.entity_representative_position}
                      value={values.entity_representative_position}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="Representative first name"
                      name="entity_representative_first_name"
                      changeHandler={handleChange}
                      placeholder="John"
                      error={errors.entity_representative_first_name}
                      value={values.entity_representative_first_name}
                    />
                  </Cell>
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="Representative last name"
                      name="entity_representative_last_name"
                      placeholder="Smith"
                      changeHandler={handleChange}
                      error={errors.entity_representative_last_name}
                      value={values.entity_representative_last_name}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6}>
                    <InputField
                      type="number"
                      label="Phone"
                      name="entity_phone"
                      placeholder="(123) 555-1212"
                      format={phoneFormat(values.entity_address_country)}
                      changeHandler={handleChange}
                      error={errors.entity_phone}
                      value={values.entity_phone}
                      mask="_"
                    />
                  </Cell>
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="Extension"
                      name="entity_phone_extension"
                      changeHandler={handleChange}
                      placeholder="123"
                      error={errors.entity_phone_extension}
                      value={values.entity_phone_extension}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12}>
                    <InputField
                      type="text"
                      label="Street"
                      name="entity_address_street"
                      placeholder="123 Street"
                      changeHandler={handleChange}
                      error={errors.entity_address_street}
                      value={values.entity_address_street}
                    />
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="City"
                      name="entity_address_city"
                      changeHandler={handleChange}
                      error={errors.entity_address_city}
                      value={values.entity_address_city}
                    />
                  </Cell>
                  <Cell small={12} medium={6} className="js-state-cell">
                    {values.entity_address_country == "United States" &&
                      <InputSelect
                        label="State"
                        name="entity_address_state"
                        options={stateOptions}
                        error={errors.entity_address_state}
                        value={stateOptions.find(option => option.value === values.entity_address_state)}
                        changeHandler={(option) => setFieldValue("entity_address_state", option.value)}
                      />
                    }
                  </Cell>
                </Grid>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6}>
                    <InputField
                      type="text"
                      label="Zip/Postal"
                      name="entity_address_zip"
                      changeHandler={handleChange}
                      error={errors.entity_address_zip}
                      value={values.entity_address_zip}
                      placeholder="02139"
                    />
                  </Cell>
                  <Cell small={12} medium={6} className="js-state-cell__entity-country">
                    <InputSelect
                      label="Country"
                      name="entity_address_country"
                      options={COUNTRIES}
                      error={errors.entity_address_country}
                      value={COUNTRIES.find(option => option.value === values.entity_address_country)}
                      changeHandler={(option) => {
                        setFieldValue("entity_address_country", option.value)
                        .then(() => setFieldValue("entity_phone", document.querySelector('input[name="entity_phone"]').value))
                      }}
                    />
                  </Cell>
                </Grid>
              </>}
              {accountTypes.account_trust &&
                <>
                  <AccountTypeContainer>
                    <ImageContainer>
                      <img src={GovernmentImg} />
                    </ImageContainer>
                    <AccountType>Trust</AccountType>
                    <RemoveAccount href="#" onClick={(event) => removeAccount(event, 'account_trust')}>X Remove</RemoveAccount>
                  </AccountTypeContainer>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={6}>
                      <InputField
                        type="text"
                        label="Trust name"
                        name="trust_name"
                        placeholder="Family Trust"
                        changeHandler={handleChange}
                        error={errors.trust_name}
                        value={values.trust_name}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <InputField
                        type="text"
                        label="Trustee name"
                        name="trust_trustee_name"
                        placeholder="Dan Smith"
                        changeHandler={handleChange}
                        error={errors.trust_trustee_name}
                        value={values.trust_trustee_name}
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={6}>
                      <InputField
                        type="number"
                        label="Phone"
                        name="trust_phone"
                        format={phoneFormat(values.trust_address_country)}
                        changeHandler={handleChange}
                        placeholder="(123) 555-1212"
                        error={errors.trust_phone}
                        value={values.trust_phone}
                        mask="_"
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <InputField
                        type="text"
                        label="Extension"
                        name="trust_phone_extension"
                        changeHandler={handleChange}
                        placeholder="123"
                        error={errors.trust_phone_extension}
                        value={values.trust_phone_extension}
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-margin-x">
                    <Cell small={12}>
                      <InputField
                        type="text"
                        label="Street"
                        name="trust_address_street"
                        placeholder="123 Street"
                        changeHandler={handleChange}
                        error={errors.trust_address_street}
                        value={values.trust_address_street}
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={6}>
                      <InputField
                        type="text"
                        label="City"
                        name="trust_address_city"
                        changeHandler={handleChange}
                        error={errors.trust_address_city}
                        value={values.trust_address_city}
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      {values.trust_address_country == "United States" &&
                        <InputSelect
                          label="State"
                          name="trust_address_state"
                          options={stateOptions}
                          error={errors.trust_address_state}
                          value={stateOptions.find(option => option.value === values.trust_address_state)}
                          changeHandler={(option) => setFieldValue("trust_address_state", option.value)}
                        />
                      }
                    </Cell>
                  </Grid>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={6}>
                      <InputField
                        type="text"
                        label="Zip/Postal"
                        name="trust_address_zip"
                        changeHandler={handleChange}
                        error={errors.trust_address_zip}
                        value={values.trust_address_zip}
                        placeholder="02139"
                      />
                    </Cell>
                    <Cell small={12} medium={6}>
                      <InputSelect
                        label="Country"
                        name="trust_address_country"
                        options={COUNTRIES}
                        error={errors.trust_address_country}
                        value={COUNTRIES.find(option => option.value === values.trust_address_country)}
                        changeHandler={(option) => {
                          setFieldValue("trust_address_country", option.value)
                          .then(() => setFieldValue("trust_phone", document.querySelector('input[name="trust_phone"]').value))
                        }}
                      />
                    </Cell>
                  </Grid>
                  <InputCheck
                    label="Is this an irrevocable trust?"
                    name="trust_irrevocable"
                    changeHandler={(event) => setFieldValue("trust_irrevocable", event.target.checked)}
                    checked={values.trust_irrevocable}
                    error={errors.trust_irrevocable}
                    blockFormatting={true}
                  />
                </>
              }
              {accountTypes.account_ira &&
                  <>
                    <AccountTypeContainer>
                      <ImageContainer>
                        <img src={PortfolioImg}/>
                      </ImageContainer>
                      <AccountType>IRA</AccountType>
                      <RemoveAccount href="#" onClick={(event) => removeAccount(event, 'account_ira')}>X Remove</RemoveAccount>
                    </AccountTypeContainer>
                    <Grid className="grid-margin-x">
                      <Cell small={12}>
                        <InputField
                          type="text"
                          label="Account title"
                          name="ira_title"
                          placeholder="Company FBO First Last IRA Account # XXXXXXX"
                          changeHandler={handleChange}
                          error={errors.ira_title}
                          value={values.ira_title}
                        />
                        <IraStreetAddressDescription>Please enter the address of retirement clearing firm below.</IraStreetAddressDescription>
                      </Cell>
                    </Grid>
                    <Grid className="grid-margin-x">
                    <Cell small={12}>
                      <InputField
                        type="text"
                        label="Street"
                        name="ira_address_street"
                        placeholder="123 Street"
                        changeHandler={handleChange}
                        error={errors.ira_address_street}
                        value={values.ira_address_street}
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-margin-x">
                    <Cell small={12} medium={4}>
                      <InputField
                        type="text"
                        label="City"
                        name="ira_address_city"
                        changeHandler={handleChange}
                        error={errors.ira_address_city}
                        value={values.ira_address_city}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <InputSelect
                        label="State"
                        name="ira_address_state"
                        options={stateOptions}
                        error={errors.ira_address_state}
                        value={stateOptions.find(option => option.value === values.ira_address_state)}
                        changeHandler={(option) => setFieldValue("ira_address_state", option.value)}
                      />
                    </Cell>
                    <Cell small={12} medium={4}>
                      <InputField
                        type="text"
                        label="Zip/Postal"
                        name="ira_address_zip"
                        changeHandler={handleChange}
                        error={errors.ira_address_zip}
                        value={values.ira_address_zip}
                        placeholder="02139"
                      />
                    </Cell>
                  </Grid>
                </>
              }
              <ButtonsContainer>
                <Grid className="grid-margin-x">
                  <Cell small={12} medium={6} className="medium-order-1 small-order-2">
                    <BackButton onClick={() => setOnboardingStep(2)}>Back</BackButton>
                  </Cell>
                  <Cell small={12} medium={6} className="medium-order-2 small-order-1">
                    <InputButton
                      type="submit"
                      color="#1488B6"
                      value={isSubmitting ? 'Submitting..' : 'Continue'}
                      name="submit-button"
                      disabled={isSubmitting || !isValid}
                    />
                  </Cell>
                </Grid>
              </ButtonsContainer>
            </form>
        }
      </Formik>
    </Container>
  )
}

export default OnboardingStepTwoContinued;