import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import theme from '../../../config/theme';
import * as Globals from '../../Globals'

export const Container = styled.div`
  left: 0px;
  padding: 22px 30px;
  position: ${props => props.position};
  display: block;
  z-index: 99;
  top: 80px;
  height: ${props => props.hoverMenuOpened ? '100%' : 'auto'};
  background: ${props => props.hoverMenuOpened ? 'white' : 'none'};

  ${breakpoint('large')`
    width: 250px;
  `}

  ${breakpoint('large')`
    width: 280px;
  `}
`;

export const Header = styled.a`
  width: 120px;
  height: 25px;
  top: 0px;
  margin-left: calc(50% - 120px/2 - 45px);
  line-height: 25px !important;
  font-size: 16px !important;
  color: ${theme.colors.backdropPurple};
  font-style: normal;
  font-weight: normal !important;
  margin: 0px !important;

  &:hover {
    color: ${theme.colors.backdropPurple}
  }
`;

export const Subheader = styled.div`
  color: ${theme.colors.purpleGrey};
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 35px;
  min-height: 15px;
  ${Globals.proximaFontStack}
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0px;
`;

export const ListItem = styled.li`
  margin: 3px 0px 0px 0px;
  line-height: normal;
  min-height: 25px;
  position: relative;
`;

export const Link = styled.a`
  height: 25px;
  font-size: 16px;
  color: ${theme.colors.backdropPurple};
  ${Globals.proximaFontStack}

  &.attorney-resource-center__sidenav-topic {
    &:hover {
      color: ${theme.colors.mediumPurple} !important;
    }
  }

  &:hover {
    color: ${theme.colors.backdropPurple} !important;
  }
`;

export const Triangle = styled.div`
  display: none;

  ${breakpoint('medium')`
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid ${theme.colors.darkestPurple}; 
    position: absolute;
    right: -60px;
    bottom: 5px;
    display: block;
  `}

  ${breakpoint('large')`
    right: -30px;
  `}
`;