import React from 'react';
import { Container, Card, Heading, Header, Type, Divider, Details, Footer, Link } from './CaseStudyCard.style';

function CaseStudyCard({type, investment, plaintiff, defendant, slug}) {
  return (
    <Container className='case-study' href={`/case-studies/${slug}`}>
      <Card>
        <Header>
          { type }
        </Header>
        <Heading>
          { investment }
        </Heading>
        <Type>
          Investment
        </Type>
        <Divider />
        <Details>
          { plaintiff }
          <br />
          <b> vs. </b>
          <br />
          { defendant }
        </Details>
        <Footer>
          <Link>
            Learn More
          </Link>
        </Footer>
      </Card>
    </Container>
  );
}

export default CaseStudyCard;
