import styled from 'styled-components';

import { Body, Description } from '../Globals';

export const Heading = styled(Body)`
  font-weight: 600;
  margin-bottom: 10px;
  margin-right: 30px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '-';
    color: #591DB4;
    position: absolute;
    right: -27px;
    z-index: 2;
    top: 0px;
    font-size: 24px;
    display: ${props => props.show ? 'block' : 'none'}
  }

  &::after {
    content: '+';
    color: #591DB4;
    position: absolute;
    right: -30px;
    z-index: 2;
    top: 0px;
    font-size: 24px;
    display: ${props => props.show ? 'none' : 'block' }
  }
`
export const Panel = styled(Description)`
  max-height: ${props => props.show ? 'unset' : '0px'};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`

export const Item = styled.div`
  margin-bottom: 17px;
`
