import React, { Fragment } from 'react'
import ModalPop from '../../ModalPop'
import InputField from '../../../components/Form/InputField'
import InputButton from '../../../components/Form/InputButton'
import validate from '../../../components/Form/Validate'
import axios from 'axios'

import {
  Heading
} from './WaitlistPop.style'

class WaitlistPop extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = {
      amount: 0,
      submitted: false,
      submissionError: null,
      inProgress: false,
      validationErrors: {
        amount: null
      }
    }
  }

  handleInputChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }

  handleSubmit(event) {
    const errors = validate(this.state)
    this.setState({
      validationErrors: errors.messages,
      submissionError: null
    })
    event.preventDefault()
    if (errors.count == 0) {
      this.setState({inProgress: true})
      const payload = {
        waitlisted_investments: {
          amount: this.state.amount,
          court_case_id: this.props.courtCaseId
        },
        authenticity_token: this.props.csrfToken
      }
      axios({
        method: 'post',
        responseType: 'json',
        url: '/waitlisted_investments.json',
        data: payload
      })
      .then(() => {
        this.setState({inProgress: false, submitted: true})
        this.props.hideHandler()
       }).catch((e) => {
         this.setState({inProgress: false})
         this.setState({submissionError: e.response.data.message})
      })
    }
  }

  render() {
    const content =
      <Fragment>
        <Heading>The opportunity is now fully funded.</Heading>
        <p>As a result of extremely high investor demand, this opportunity is now fully funded. We apologize that we could not accommodate your participation.</p>
        <p>If you would like to join the waitlist to invest in this opportunity, please indicate your anticipated investment amount below. This is not a commitment — just an indication of interest.</p>
        <p>You will be contacted by our Investment Operations Team directly should an allocation open up for you.</p>
        <form noValidate onSubmit={this.handleSubmit}>
          <InputField
            type="currency"
            label="Desired investment amount:"
            name="amount"
            changeHandler={this.handleInputChange}
            error={this.state.validationErrors.amount}
           />
          <InputButton
            type="submit"
            value={this.state.inProgress ? 'Submitting..' : this.state.submitted ? 'Submitted' : 'Submit'}
            name="submit-button"
            disabled={this.state.inProgress || this.state.submitted ? true : false}
          />
        </form>
      </Fragment>

    return (
      <ModalPop
        popVisible={this.props.visible}
        hideHandler={this.props.hideHandler}
        content={content}
      />
    )
  }
}

export default WaitlistPop
