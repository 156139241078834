import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'
import lockIcon from '../../../../assets/images/icons/lock.svg'

export const Content = styled.div`
`

export const Heading = styled(Globals.H3)`
  color: white;
`

export const Text = styled.p`
  color: white;
`

export const Tabs = styled.div`
  margin-top: 40px;
  color: white;
  margin-bottom: 35px;
  margin-left: 3px;
`

export const Link = styled.a`
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  ${breakpoint('medium')`
    margin-right: 30px;
  `}
  &:hover, &.selected {
    color: #8247BA;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 120%;
      height: 5px;
      background-color: #8247BA;
      margin-top: 10px;
      margin-left: -10%;
    }
  }
  &.locked {
    color: #878688;
    cursor: not-allowed;
    &:hover, &.selected {
      color: #878688;
      border-width: 0px;
      border-color: unset;
      &:after {
        height: 0;
        display: none;
     }
  }
  .__react_component_tooltip {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    width: 300px;
    text-transform: initial;
    background-color: #131017;
  }
`

export const Lock = styled.span`
  position: absolute;
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  background-color: unset;
  border-width: 0px;
  border-color: unset;
  background: url(${lockIcon}) no-repeat center center/cover;
  margin-right: 0;
  margin-top: 0;
  right: -18px !important;
  top: 3px;
  text-transform:
  ${breakpoint('medium')`
    top: 2px;
  `}
`
