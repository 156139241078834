import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as Globals from "./../../Globals";
import theme from "../../../config/theme";
import WhiteDiamond from "../../../../assets/images/icons/white-diamond.png";

export const Container = styled.div`
  padding: 25px;

  ${breakpoint("medium")`
    padding: 75px 120px;
  `};
`;

export const Heading = styled(Globals.H2)`
  ${Globals.tiempoFontStack}
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: ${theme.colors.darkPurple};
  font-weight: 900;
  margin-bottom: 25px;

  ${breakpoint("medium")`
    font-size: 56px;
    line-height: 64px;
    text-align: left;
    margin-bottom: 70px;
    margin-top: 20px;
  `}
`;

export const WwdTitle = styled(Globals.H6)`
  color: ${theme.colors.white};
  margin: 20px 0px 30px 0px;
  text-transform: uppercase;
  max-width: 310px;
  line-height: 28px !important;
`;

export const WwdItem = styled.li`
  font-size: 21px;
  line-height: 25px;
  color: ${theme.colors.white};
  margin-bottom: 16px;
  max-width: 500px;
  background: url(${WhiteDiamond}) no-repeat left top;
  padding-left: 44px;
`;

export const UL = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;
