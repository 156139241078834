import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../Globals'
import theme from '../../../config/theme'

export const Container = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

export const Heading = styled(Globals.H1)`
  ${Globals.tiempoFontStack};
  text-align: center;
  font-size: 32px;
  margin: 40px 0 15px 0;
  line-height: 1.2;

  ${breakpoint('large')`
    font-size: 3.4rem;
  `}
`

export const Subheading = styled.p`
  text-align: center;
  max-width: 700px;
  margin: 0 auto 20px auto;
  font-weight: 300;
`;

export const ButtonsContainer = styled.div`
  margin: 30px auto 30px auto;
  max-width: 450px;
`;

export const BackButton = styled(Globals.ButtonSecondaryInverse)`
  margin-top: 35px;
  width: 100%;
  height: 51px;

  ${breakpoint('medium')`
    margin-top: 20px;
  `}
`;

export const FormContainer = styled.div`
  max-width: 650px;
  margin: 28px auto 0px auto;
`;

export const FinishProfileLaterContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const FinishProfileLaterLink = styled.a`
`;

export const ErrorContainer = styled.div`
  &.error {
    border: 1px solid ${theme.colors.errorRed};
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
  }
`;

export const Error = styled.p`
  font-size: 12px;
  color: red;
  margin-top: -10px;
`;