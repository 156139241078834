import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import * as SB from "./../../SimpleBanner";
import * as Globals from "./../../Globals";
import Hero from "../../../../assets/images/home/hero.png";
import theme from "../../../config/theme";

export const Banner = styled(SB.Banner)`
  background: url(${Hero}) no-repeat center center/cover;
  position: relative;
  overflow: visible;
  padding: 10% 0 10% 0;

  ${breakpoint("medium")`
    height: calc(100vh - 106px);
    padding: 10% 0 10% 0;
  `}
  ${breakpoint("large")`
    height: calc(100vh - 118px);
    padding: 7% 0 11% 0;
  `}
`;

export const PressLogos = styled.div`
  height: 20px;
`;

export const BannerContent = styled(SB.Content)`
  position: relative;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  padding: 0 5%;
  text-align: center;
  height: 100%;
  max-width: 1235px;

  .align-middle {
    height: 100%;
  }

  ${breakpoint("medium")`
    top: 0;
    padding-top: 0px;
    max-width: 1235px;
    padding: 0 2%;
  `}
`;

export const BannerProp = styled(SB.Diamonds)``;

export const Heading = styled(SB.Heading)`
  color: black;
  text-align: center;
  font-size: 2rem;
  line-height: 32px;

  ${breakpoint("medium")`
    text-align: left;
    font-size: 68px;
    line-height: 110%;
  `}
`;

export const Subheading = styled(SB.P)`
  -webkit-font-smoothing: antialiased;
  margin: 30px auto 30px auto;
  color: ${theme.colors.backdropPurple};
  font-size: 20px;
  line-height: 25px;

  ${breakpoint("medium")`
    margin: 60px auto 16px auto;
    font-size: 24px;
    line-height: 33.6px;
  `}
`;

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
  float: left;
  margin-bottom: 10px;
  background: ${theme.colors.mediumPurple};

  ${breakpoint("medium")`
    min-width: 185px;
    width: unset;
  `};
  ${breakpoint("large")`
    min-width: 225px;
    margin-right: 18px;
  `};
`;

export const ActionButtons = styled.div`
  max-width: 430px;
  margin: 0 auto;
`;

export const ButtonInverse = styled(Button)`
  background: transparent !important;
  color: ${theme.colors.mediumPurple};
  border: 2px solid ${theme.colors.mediumPurple};
  padding: 13px 24px 13px 24px;
  float: left;

  &:hover {
    background: transparent !important;
    color: ${theme.colors.mediumPurple};
    opacity: 0.7;
  }

  ${breakpoint("large")`
    min-width: 242px;
  `};
`;

export const PillIconContainer = styled.div`
  text-align: center;
  margin-top: 25px;

  ${breakpoint("medium")`
    text-align: left;
  `};
`;

export const Pill = styled.span`
  color: ${theme.colors.darkGrey};
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  font-family: "proxima-nova";
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 30px;
  margin-right: 10px;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
  }

  &[data-descr]:hover::after,
  &[data-descr]:focus::after {
    content: attr(data-descr);
    position: absolute;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px;
    margin: 45px 0px 0px -20px;
    font-family: "proxima-nova";
    font-size: 14px;
    line-height: 19.6px;
    font-weight: 400;
    max-width: 237px;
    text-transform: none;
  }

  ${(props) => props.position == "last" && "display: inline-block;"}
  ${(props) => props.position == "last" && "margin-top: 10px;"}

  ${breakpoint("medium")`
    font-size: 14px;
    display: inline;
  `};
`;

export const ImageContainer = styled.div`
  bottom: 0;
  width: 100%;
  left: 0;

  ${breakpoint("medium")`
    height: 118px;
  `};

  img {
    width: 16.66%;
  }
`;
