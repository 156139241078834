import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Criteria, Content, Heading, Subheading } from './CriteriaSection.style';

function CriteriaSection() {
  return (
    <Criteria>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Criteria for funding
              </Heading>
              <Subheading>
                At LexShares, we welcome clients from most U.S. jurisdictions. Funding must exceed USD $200,000, with overall collateral valued at $2 million or greater. The maximum funding amount is uncapped.
              </Subheading>
              <Subheading>
                LexShares funds all types of commercial claims, including whistleblower cases, breach of contract, intellectual property, fraud, judgments, professional negligence, MDL, business torts, arbitration, and insolvency and bankruptcy.
              </Subheading>
              <Subheading>
                LexShares’ funding and underwriting process is highly transparent and straightforward, so funding recipients will always have a clear understanding of where they stand during the process.
              </Subheading>
            </Cell>
          </Grid>
        </Section>
      </Content>
    </Criteria>
  )
}

export default CriteriaSection;
