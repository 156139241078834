import React, {Fragment, useState, useEffect} from 'react'
import axios from '../../../../Axios'
import ReactTooltip from 'react-tooltip'
import {
  Widget,
  Content,
  Heading,
  Table,
  Status,
  CaseRow,
  PropertyBlock,
  PropertyHeader,
  Property,
  CaseLink,
  Divider,
  Link,
  Clear,
  StatusLabel,
  Expand
} from './CasePerformanceWidget.style'

/**
 * Displays a list of personal investments, their statuses and associated
 * returns if data is available.
 */
function CasePerformanceWidget() {
  let [performance, setPerformance] = useState(null)
  let [expanded, setExpanded] = useState(true)

  const onClick = (isExpanded) => {
    setExpanded(isExpanded)
  }

  useEffect(() => {
    axios.get(`/widget/case-performance.json`).then(res => {
      setPerformance(res.data)
      if (res.data.length > 3) {
        setExpanded(false)
      }
    })
  }, [])

  return (
    <Widget
      className="js-investment-metrics-widget"
      hide={!expanded}
      bottomOffset={(performance && (performance.length) > 1 && expanded ? true : false)}>
      <Content loading={performance ? "false" : "true"}>
        { performance &&
          <Fragment>
            <Heading>Your Case Investment Metrics</Heading>
            { performance.map((caseInfo, i) => (
              <Fragment key={`metric-${i}`}>
                <Status>
                  <StatusLabel status={caseInfo.status}>
                    {caseInfo.status}
                  </StatusLabel>
                </Status>
                <CaseLink href={`/cases/${caseInfo.case_id}`} target="_blank">
                  {caseInfo.name}
                </CaseLink>
                <Table>
                  <CaseRow key={`perform-row-${i}`} className="js-case-row heading">
                    <PropertyBlock>
                      <PropertyHeader>Investment</PropertyHeader>
                      <Property>{caseInfo.investment}</Property>
                    </PropertyBlock>
                    <PropertyBlock>
                      <PropertyHeader>Date Invested</PropertyHeader>
                      <Property>{caseInfo.invested}</Property>
                    </PropertyBlock>
                    { caseInfo.has_resolutions
                      ?
                      <Fragment>
                        { !caseInfo.is_partial &&
                          <Fragment>
                            <PropertyBlock>
                              <PropertyHeader>IRR</PropertyHeader>
                              <Property>{caseInfo.irr}</Property>
                            </PropertyBlock>
                            <PropertyBlock>
                              <PropertyHeader>ROIC</PropertyHeader>
                              <Property>{caseInfo.roic}</Property>
                            </PropertyBlock>
                          </Fragment>
                        }
                        { caseInfo.is_partial ? 
                            <PropertyBlock
                              className="last-property partial-tooltip"
                              data-tip={`<b>Partial Resolutions</b><p>This case investment ` +
                                `is partially resolved.<br/>IRR and ROIC will be calculated ` +
                                `when the<br/> matter is fully resolved.</p>`}
                              data-place="bottom"
                              data-html={true}
                              data-background-color={'#282331'}
                              data-name={`partial-#{caseInfo.id}`}
                            >
                              <PropertyHeader>Return to Date<sup>?</sup></PropertyHeader>
                              <Property>{caseInfo.return}</Property>
                              <ReactTooltip />
                            </PropertyBlock>
                          :
                            <PropertyBlock>
                              <PropertyHeader>Return</PropertyHeader>
                              <Property>{caseInfo.return}</Property>
                            </PropertyBlock>
                        }
                        { !caseInfo.is_partial &&
                          <PropertyBlock>
                            <PropertyHeader>Resolved</PropertyHeader>
                            <Property>{caseInfo.resolved}</Property>
                          </PropertyBlock>
                        }
                      </Fragment>
                      :
                      <Fragment>
                        <PropertyBlock className="last-property">
                          <PropertyHeader>Case Updates</PropertyHeader>
                          <Property>
                            <Link href="/dashboard#case-updates" target="_blank">View Activity</Link>
                          </Property>
                        </PropertyBlock>
                      </Fragment>
                    }
                    <Clear />
                  </CaseRow>
                </Table>
                <Divider />
              </Fragment>
            ))}
          </Fragment>
        }
      </Content>
      { performance && (performance.length > 3) &&
        <Fragment>
          { expanded
            ?
              <Expand onClick={() => {onClick(false)}} expanded={true}>View Less</Expand>
            :
              <Expand onClick={() => {onClick(true)}} collapsed={true} className="js-view-more-link">View More</Expand>
          }
        </Fragment>
      }
    </Widget>
  )
}

export default CasePerformanceWidget
