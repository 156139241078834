import React from 'react'
import { Cell, Grid } from 'react-foundation'
import { Section, Types, Content, Heading, Subheading, Header, Summary, Icon } from './TypesSection.style';
import PortfolioImg from '../../../../assets/images/icons/portfolio-funding.png';
import BusinessImg from '../../../../assets/images/icons/business-area.png';

function TypesSection() {
  return (
    <Types>
      <Content>
        <Section>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
               Types of law firm funding available
              </Heading>
              <Subheading>
                LexShares offers two core funding products for attorneys, all designed to help share risk and avoid downside exposure.
              </Subheading>

              <Grid className="grid-margin-x">
                <Cell medium={6} small={12}>
                  <Grid className="grid-margin-x">
                    <Cell small={2} className="hide-for-medium">
                      <Icon>
                        <img src={PortfolioImg} />
                      </Icon>
                    </Cell>
                    <Cell small={10} medium={12}>
                      <Icon className="show-for-medium">
                        <img src={PortfolioImg} />
                      </Icon>
                      <Header className="small-text-left medium-text-center">
                        Portfolio Funding
                      </Header>
                      <Summary className="small-text-left medium-text-center">
                        We provide funding collateralized by multiple contingency fee matters which may or may not be related. Examples includes but are not limited to class actions and toxic and mass torts. Funding can be used for marketing programs, firm, or litigation related expenses.
                      </Summary>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell medium={6} small={12}>
                  <Grid className="grid-margin-x">
                    <Cell small={2} className="hide-for-medium">
                      <Icon>
                        <img src={BusinessImg} />
                      </Icon>
                    </Cell>
                    <Cell small={10} medium={12}>
                      <Icon className="show-for-medium">
                        <img src={BusinessImg} />
                      </Icon>
                      <Header className="small-text-left medium-text-center">
                        Firm Funding
                      </Header>
                      <Summary className="small-text-left medium-text-center">
                        We provide funding to cover capital for law firms collateralized against their entire case portfolio. Funding does not exclusively need to cover case costs but can be used to finance payroll and other firm expenses.
                      </Summary>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </Section>
      </Content>
    </Types>
  )
}

export default TypesSection;
