import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';


export const Section = styled.div`
  background: white;
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(Globals.H2)``;

export const TabContainer = styled.div`
  margin: 20px 40px 30px 0;
`

export const TabItem = styled.div`
  border-bottom: 1px solid #d4d4d4;
  padding: 20px 10px;
  position: relative;
  cursor: ${props => props.selected ? 'default' : 'pointer'};

  &:hover {
    &::before {
      content: "";
      border-left: 10px solid #d4d4d4;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -10px;
      animation: fadeIn 0.5s ease-in-out;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  &::after {
    display: ${props => props.selected ? 'block' : 'none'};
    content: "";
    border-left: 10px solid #8247ba;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -10px;
    animation: fadeIn 0.5s ease-in-out;
    z-index: 1;
  }
`

export const ItemHeader = styled.div`
  font-weight: bold;

`

export const HeadlineCard = styled.div`
  border: none;
  margin-bottom: 120px;
  padding: 0 !important;
  text-align: left;
  position: relative;
  min-height: 400px;
  width: 100%;
  box-shadow: 0 3px 9px rgba(0,0,0,0.05), 0 3px 26px rgba(0,0,0,0.05);
  display: none;

  ${breakpoint('large')`
    display: block;
  `}
`

export const Headline = styled.div`
  ${Globals.tiempoFontStack};
  color: #8247ba;
  font-size: 21px;
  line-height: 2;
  top: 50%;
  position: absolute;
  transform: translate(0%, -50%);
  padding: 0 10%;
`

export const ItemDescription = styled.div`

`

export const Button = styled(Globals.ButtonLink)`
  width: 100%;
`

export const ButtonInverse = styled(Button)`
  background-color: transparent !important;
  color: #8247ba;
  border: 2px solid #8247ba;
  padding: 13px 24px 13px 24px;
  &:hover {
    background-color: transparent !important;
    color: #8247ba;
    opacity: 0.7;
  }
`;

export const Disclaimer = styled.div`
  font-size: 12px;
  text-align: left !important;
  font-weight: 400;
  -webkit-font-smoothing: auto;
  margin-top: 30px;
`;
