//= require foundation
//= require parsley
//= require js.cookie
//= require rest_in_place
//= require js-routes
//= require_directory .
//= require text-encoding
//= require_tree ../../../app/assets/javascripts

function importAll (r) {
  r.keys().forEach(r);
}
importAll(require.context('coffee', true, /\.coffee$/));