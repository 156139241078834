import styled from 'styled-components';
import * as Globals from './../../Globals';
import breakpoint from 'styled-components-breakpoint';
import Hero from '../../../../assets/images/backgrounds/purple-bg-strip.png'
import DiamondArray from '../../../../assets/images/backgrounds/diamond-array.png'

export const Section = styled.div`
  background-color: #5129ad;
  background-image: url(${Hero});
  background-size: contain;
  background-repeat: no-repeat repeat;
  text-align: center;

  .step {
    text-align: center;
    padding: 1px 30px;
    border-bottom: 1px solid #914cde;
    background: white;

    ${breakpoint('medium')`
      border-right: 1px solid #914cde;
      flex: 0 0 auto;
    `}
  }
`;

export const Diamond = styled.div`
  background-position: 0 500px;
  background-image: url(${DiamondArray});
  background-repeat: no-repeat;
`

export const Heading = styled(Globals.H2)`
  margin-bottom: 12px;
  text-align: center !important;
  color: white;
`;

export const Subheading = styled(Globals.P)`
  margin-bottom: 1rem;
  text-align: center;
  color: white;
`;

export const Step = styled.div`

`

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}

  .cell {
    &:last-child {
      ${Step} {
        border-right: 0;
        border-bottom: 0;
      }
    }
  }
`;

export const StepIcon = styled.div`
  margin-top: 60px;
  margin-bottom: 8px;
  img {
    height: 39px;
    max-width: unset;
  }
`

export const StepNumber = styled.div`
  font-size: 2.4rem;
  color: #914cde;
`

export const StepTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
`

export const StepDescription = styled.div`
  margin-bottom: 70px;
`

export const Link = styled.a`
  color: #8247ba;
  text-decoration: underline;

  &:hover, &:focus {
    color: #8247ba;
  }
`

export const Button = styled(Globals.ButtonLink)`
  min-width: 200px;
  background-color: white;
  color: #914cde;
  margin-top: 30px;
  display: inline-block;
`
