import React from 'react';
import { Cell, Grid } from 'react-foundation';

import { ArticleContainer, ContentContainer, Header, HeaderImage, MobileView, NonMobileView, Tag } from './ArticleSection.style';
import ArticleContent from '../ArticleContent/ArticleContent';
import Sidebar from '../Sidebar/Sidebar';

const ArticleSection = ({article, articleSectionRef}) => {
  const tag = article.tags.find(t => ['funding-insights', 'regulations-trends'].includes(t.slug))

  return(
    <div ref={articleSectionRef}>
      <HeaderImage src={article.feature_image} />
      <ArticleContainer>
        <Tag>{tag.name} — Article</Tag>
        <Grid className="grid-margin-x grid-margin-y grid-padding-y align-left">
          <Cell className="small-order-2 medium-order-1" small={12} medium={9} large={9} style={{marginTop: '0px', paddingTop: '0px'}}>
              <ContentContainer>
                <Header>{article.title}</Header>
                <MobileView>
                  <Sidebar article={article} />
                </MobileView>
                <ArticleContent html={article.html} />
              </ContentContainer>
          </Cell>
          <Cell style={{padding: '0px'}} className="small-order-1 medium-order-2" small={12} medium={3} large={3}>
            <NonMobileView>
              <Sidebar article={article} />
            </NonMobileView>
          </Cell>
        </Grid>
      </ArticleContainer>
    </div>
  )
}

export default ArticleSection;