import React from 'react';

import { Container, PublisherLogo, PublisherLink, PublisherText } from './PublishSection.style';

const PublishSection = ({html}) => {
  const match = html.match(/<ls_data_published(.*)\/>/);
  const imageUrl = match ? match[1].match(/logo="(.*?)"/)?.[1] : '';
  const text = match ? match[1].match(/text="(.*?)"/)?.[1] : '';
  const link = match ? match[1].match(/url="(.*?)"/)?.[1] : '';
  return(
    <React.Fragment>
      {link ? (
        <PublisherLink href={link} target="_blank">
          {imageUrl && <PublisherLogo src={imageUrl} />}
          {text && <PublisherText>{text}</PublisherText>}
        </PublisherLink>
      ) : (
        <Container>
          {imageUrl && <PublisherLogo src={imageUrl} />} 
           {text && <PublisherText>{text}</PublisherText>}
        </Container>
      )}
    </React.Fragment>
  )
}

export default PublishSection;