import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { H3, P, ButtonLink } from '../../Globals'

export const Section = styled.div`
  background: #f7f7f7;
`;

export const Content = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 10% 7%;

  .card-container {
    margin-bottom: 15px;
  }

  ${breakpoint('medium')`
    padding: 7% 7%;
  `}

  ${breakpoint('large')`
    padding: 5% 2%;
  `}
`;

export const Heading = styled(H3)`
  text-align: center;
  margin-bottom: 20px;
`

export const Subheading = styled(P)`
  text-align: center;
  margin-bottom: 50px;
`

export const Button = styled(ButtonLink)`
  margin: 0 auto;
  background: transparent !important;
  color: #8247ba;
  border: 2px solid #8247ba;
  padding: 13px 24px;
  margin: 0 auto;
  width: 140px;
  margin-top: 48px;
  display: block;
  &:hover {
    background: transparent !important;
    color: #8247ba !important;
    opacity: .7;
  }
`

export const InvestmentContainer = styled.div`
  min-height: 100%;
  display: flex;
  padding-bottom: 20px;

  ${breakpoint('medium')`
    width: 392px;
    margin: 0 auto;
  `}

  ${breakpoint('large')`
    width: 100%;
  `}
`;

export const LoadMoreLink = styled.a`
  text-align: center;
  display: block;
  text-transform: uppercase;
  color: #914cde;
  font-weight: bold;
  margin-top: 10px;

  &:after {
    content: '\f078';
    font-family: 'FontAwesome';
    color: #7d7d7d;
    font-weight: normal;
    margin: 0 0 0 15px;
  }
`
