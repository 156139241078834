import React from 'react';
import { Container } from './PressBanner.style';

const PressBanner = ({src}) => {
  return(
    <Container>
      <img src={src} />
    </Container>
  )
}

export default PressBanner;