import React, {Fragment, useState, useEffect} from 'react'
import {Cell, Grid} from 'react-foundation'
import axios from '../../../../Axios'
import Pie from '../../../../Charts/Pie'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text,
  ChartContainer
} from './InvestmentProfileWidget.style'

/**
 * Displays three pie charts showing how personal investments are 
 * diversified across damage, stage and jurisdiction profiles.
 */
function InvestmentProfileWidget() {
  let [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`/widget/investment-profile.json`).then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <Widget className="js-personal-investment-profile-widget">
      <Content loading={data ? "false" : "true"}>
        { data &&
          <Fragment>
            <Heading>Investment Profile</Heading>
            <DateRange>{data.date_range}</DateRange>
            <Text>
              The following charts represent additional properties of your LexShares
              case investments, specifically at the time the initial investment was made.
            </Text>
            <Grid className="grid-margin-x">
              <Cell small={12} medium={4}>
                <ChartContainer className="js-damages-donut">
                  <Pie data={data.damages_profile} heading="Damages" />
                </ChartContainer>
              </Cell>
              <Cell small={12} medium={4}>
                <ChartContainer className="js-stage-donut">
                  <Pie data={data.stage_profile} heading="Stage" />
                </ChartContainer>
              </Cell>
              <Cell small={12} medium={4}>
                <ChartContainer className="js-jurisdiction-donut">
                  <Pie data={data.jurisdiction_profile} heading="Jurisdiction" />
                </ChartContainer>
              </Cell>
            </Grid>
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default InvestmentProfileWidget
