import React, { Fragment } from 'react';
import { Cell, Grid } from 'react-foundation';
import {
  Section, Content, Heading, Subheading, Button, SecondaryButton, SecondaryButtonInverse, Disclaimer
} from './LearnMoreSection.style';

function LearnMoreSection({background, heading, subheading, buttonLink, buttonText, secondaryButtonLink, secondaryButtonText, disclaimer, largeButton = true}) {
  return (
    <Fragment>
      <Section background={background}>
        <Content>
          <Grid>
            <Cell small={12} large={8} className="large-offset-2">
              <Heading>
                { heading }
              </Heading>
              <Subheading>
                { subheading }
              </Subheading>
              <Grid className="align-center">
                { !secondaryButtonLink && <Cell small={12} medium={5}>
                  <Button href={buttonLink} largeButton={largeButton}>
                    { buttonText }
                  </Button>
                </Cell> }
                { secondaryButtonLink && <Cell small={12} medium={8}>
                  <SecondaryButton href={buttonLink}>
                    { buttonText }
                  </SecondaryButton>
                  <SecondaryButtonInverse href={secondaryButtonLink}>
                    { secondaryButtonText }
                  </SecondaryButtonInverse>
                </Cell>}
              </Grid>
            </Cell>
          </Grid>
          {disclaimer && <Disclaimer>
            { disclaimer }
          </Disclaimer>}
        </Content>
      </Section>
    </Fragment>
  )
}

export default LearnMoreSection;
