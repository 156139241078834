import React, { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Hero } from "../../components/funding";
import NewNavbar from "../../components/Navbar/NewNavbar";
import Footer from "../../components/Footer";
import LearnMoreSection from "../../components/LearnMoreSection";
import theme from "../../config/theme";
import HeroBackground from "../../../assets/images/attorney/hero.png";
import PressBanner from "../../components/PressBanner";
import HowItWorksSection from "../../components/home/HowItWorksSection/HowItWorksSection";
import PurpleBackground from "../../../assets/images/home/purple-background";
import ResourcesSection from "../../components/ResourcesSection";
import CaseStudySamples from "../../components/CaseStudySamples/CaseStudySamples";
import CheckmarkInShield from '../../../assets/images/icons/checkmark-in-shield.png';
import DollarSign from '../../../assets/images/icons/dollar-sign.png';
import Gavel from '../../../assets/images/icons/gavel.png';
import BusinessGuide from '../../../assets/images/attorney_resource_center/plaintiff-guide-book-center.png';
import BusinessWhiteImg from '../../../assets/images/logos/press-logo-businesses-white.png'

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.lightPurple};
`;

const caseStudies = [
  {
    heading: "Access Top Resources",
    subheading:
      "Leverage greater financial flexibility to engage with high-quality legal resources—attorneys, experts, and support services.",
    caseType: "Business Squeeze out",
    caseFundingAmount: "$500,000",
    caseDescription:
      "With primary income streams and dividends in dispute, the minority owner of this business used funding to retain top-tier legal representation.",
    fundingType: "Single Case",
    iconPath: CheckmarkInShield,
  },
  {
    heading: "Unlock Liquidity",
    subheading:
      "Operating a business while fighting a lawsuit can be challenging — litigation finance can help cover your legal expenses and even working capital.",
    caseType: "Theft Of Trade Secrets",
    caseFundingAmount: "$2,250,000",
    caseDescription:
      "Operating a thinly capitalized business, the plaintiff used funding to unlock the value of a pre-settled legal claim, the company’s largest balance sheet asset.",
    fundingType: "Single Case",
    iconPath: DollarSign,
  },
  {
    heading: "Improve Outcomes",
    subheading:
      "Litigation finance helps democratize access to justice, leveling the playing field against economically advantaged defendants.",
    caseType: "Trademark Infringement",
    caseFundingAmount: "$1,445,000",
    caseDescription:
      "This plaintiff wanted to use funding to lever its own resources in order to bolster a trademark enforcement campaign.",
    fundingType: "Single Case",
    iconPath: Gavel,
  },
];

const initialReview = {
  caseType: "Theft of Trade Secrets",
  caseDescription:
    "Plaintiff Bluth Company claims a competitor and an ex-employee conspired to steal proprietary data and develop a copycat real estate valuation algorithm.",
  damagesAmount: "$15,000,000",
  defendant: "Sitwell Enterprises",
  defendantDescription:
    "Executives in charge of day-to-day operations and growth strategy for a successful real estate firm, which invests in the same regions as Bluth Company.",
};

const funding = {
  damagesAmount: "$1.50M",
};

class Businesses extends React.Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <NewNavbar
            theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Hero
              heading="Monetize your company’s legal claims."
              subheading="Litigation finance can help you fight strong cases and stay the course, while mitigating the financial impact to your business."
              buttonText="Get In Touch"
              background={HeroBackground}
              buttonLink="/funding/case"
              secondaryButtonText="Download Guide"
              secondaryButtonLink="/whitepapers/business-guide"
            />
            <PressBanner src={BusinessWhiteImg} />
            <CaseStudySamples
              heading="Lift the financial burden of litigation"
              caseStudies={caseStudies}
              subtext="LexShares has helped make substantial recoveries possible for clients across various industries, including technology, real estate, and energy, among others."
            />
            <HowItWorksSection
              initialReview={initialReview}
              leaglUnderwriting={{}}
              funding={funding}
            />
            <ResourcesSection
              imagePath={ BusinessGuide }
              heading="Read our detailed introduction to litigation finance."
              subheading="LexShares’ guide covers key topics and frameworks essential to understanding how funding works."
              buttonHref="/whitepapers/business-guide"
              buttonText="Download Guide"
            />
            <LearnMoreSection
              background={PurpleBackground}
              heading="Discuss funding with LexShares."
              subheading="If you have a case that may benefit from financing, or simply wish to learn more about how LexShares works, reach out to our team."
              buttonText="Contact Us"
              buttonLink="/funding/case"
            />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Businesses;
