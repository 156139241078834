import React, { Fragment } from "react"
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../config/theme';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/devise-passwords-edit/Banner/Banner'
import Content from '../../components/devise-passwords-edit/Content/Content'

const Page = styled.div`
  background-color: ${props => props.theme.colors.white};
`

class DevisePasswordsEdit extends React.Component {
  render () {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Navbar theme="transparent"
            roleDashboardPath={this.props.roleDashboardPath}
            roleProfilePath={this.props.roleProfilePath}
            userSignedIn={this.props.userSignedIn}
            isInvestor={this.props.isInvestor}
            currentRole={this.props.isInvestor}
            name={this.props.name}
            csrfToken={this.props.csrfToken}
          />
          <Page>
            <Banner />
            <Content
              csrfToken={this.props.csrfToken}
              userPasswordPath={this.props.userPasswordPath}
              resetPasswordToken={this.props.resetPasswordToken}
            />
          </Page>
          <Footer />
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default DevisePasswordsEdit;
