import React from 'react'

import {
  SuccessContainer,
  Content,
  Heading,
  Text,
  LinkButton
} from './InvestmentCompleted.style'

function InvestmentCompleted({caseName}) {
  return (
    <SuccessContainer>
      <Content>
        <Heading>
          Congratulations!
          <br />
          Your commitment is submitted.
        </Heading>
        <Text>
          Thank you for your commitment to invest in {caseName}.
          As a next step in the subscription process, we recommend you begin
          uploading your accreditation documents to your Investor Profile: 
        </Text>
        <LinkButton href="/profile#accreditation">
          Investor Profile
        </LinkButton>
      </Content>
    </SuccessContainer>
  )
}

export default InvestmentCompleted
