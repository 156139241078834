import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';

export const Container = styled.div`
  padding: 30px 20px;
`

export const Header = styled(Globals.H6)`
  color: ${theme.colors.backdropPurple};
  ${Globals.proximaFontStack};
  line-height: 29px !important;
  font-weight: normal !important;
  padding-right: 30px;
  margin-bottom: 20px;

  ${breakpoint('large')`
    margin-bottom: 0px;
  `}
`

export const ConfirmationHeader = styled(Globals.H2)`
`;

export const ConfirmationMessage = styled.p`
  ${Globals.proximaFontStack};
  font-size: 21px;
  line-height: 36px;
  color: ${theme.colors.purpleGrey};
`;

export const InputField = styled.input`
  padding: 14px 10px 11px 10px;
  font-size: 16px;
  line-height: 25px;
  width: 250px;
  height: 49px !important;
  margin: 0px;
  display: inline-block;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  ${breakpoint('medium')`
    border-radius: 3px !important;
    display: block;
  `}

  ${breakpoint('large')`
    display: inline-block;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  `}
`;

export const Form = styled.form`
  ${breakpoint('large')`
    float: right; 
  `}
`;

export const InputSubmit= styled.input`
  text-transform: uppercase;
  width: 150px;
  height: 49px;
  background: ${theme.colors.purple};
  padding: 19px 15px 18px 15px;
  font-size: 13px;
  font-weight: 800;
  line-height: 13px;
  letter-spacing: 2px;
  ${Globals.proximaFontStack};
  color: ${theme.colors.white};
  cursor: pointer;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid ${theme.colors.purple} !important;

  ${breakpoint('medium')`
    border-radius: 3px !important;
    display: block;
  `}

  ${breakpoint('large')`
    display: inline-block;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  `}
`;

export const ErrorMessage = styled.div`
`;