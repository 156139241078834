import React, { useState } from 'react';
import { Cell, Grid } from 'react-foundation';
import axios from 'axios';
import InputField from '../../Form/InputField';
import Spacer from '../../Spacer/Spacer';
import { Alert, Error, Form, Container, InputSubmit } from './Content.style';

const Content = ({userConfirmationPath, csrfToken, newUserSessionPath}) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      user: {
        email: email,
      },
      authenticity_token: csrfToken
    }

    axios.post(`${userConfirmationPath}.json`, payload).then(() => {
      window.location = newUserSessionPath;
      setErrorMessage("");
      setIsSubmitting(false);
    }).catch((e) => {
      setErrorMessage(e.response.data.error);
      setIsSubmitting(false);
    })
  }

  return(
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid className="grid-x grid-margin-x">
          <Cell small={12} large={8} className="large-offset-2">
            {errorMessage && <Alert>Please review the problems below</Alert>}
            <p>You must confirm your email with LexShares before you can log in and complete your sign up.</p>
            <p>Please check your email for the confirmation that was previously sent to you.</p>
            <p>If you did not receive a confirmation email, you can request another one by entering your email address below.</p>
            <p>Remember to check your spam folder if you do not receive your confirmation email.</p>
            <Spacer height={10} />
            <InputField
              type="text"
              label="Please enter your email address below."
              name="email"
              changeHandler={(e) => setEmail(e.target.value)}
              value={email}
            />
            {errorMessage && <Error>{errorMessage}</Error>}
            <InputSubmit type="submit" value="Resend Confirmation Email" disabled={isSubmitting} />
          </Cell>
        </Grid>
      </Form>
    </Container>
  )
}

export default Content;