import React from 'react';
import { Cell, Grid } from 'react-foundation'
import { Container, Button, InfoContainer, Heading, Description, ImageContainer } from './ResourcesSection.style';

const ResourcesSection = ({imagePath, heading, subheading, buttonHref = "/resources", buttonText = "View Resources"}) => {
  return(
    <Container>
      <Grid>
        <Cell small={12} large={6}>
          <ImageContainer>
            <img src={imagePath} />
          </ImageContainer>
        </Cell>
        <Cell small={12} large={6}>
          <InfoContainer>
            <Heading>{heading}</Heading>
            <Description>{subheading}</Description>
            <Button href={buttonHref}>{buttonText}</Button>
          </InfoContainer>
        </Cell>
      </Grid>
    </Container>
  )
}

export default ResourcesSection;