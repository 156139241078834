import styled from 'styled-components'

export const Sidebar = styled.div`
  position: sticky;
  top: 150px;
  left: 0;
  right: 0;
  z-index: 10;
`

export const Nav = styled.div`
  margin-top: 80px;
`

export const NavList = styled.div``

export const NavItem = styled.div`
  color: ${props => props.selected ? props.theme.colors.black : props.theme.colors.inactivePurple};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 13px;
  cursor: pointer;
  position: relative;

  &::after {
    display: ${props => props.selected ? 'block' : 'none'};
    content: '\f0da';
    font-family: 'FontAwesome';
    top: -3px;
    left: -20px;
    margin-left: 5px;
    font-size: 16px;
    position: absolute;
    color: ${props => props.theme.colors.purple}
  }
`
