import React, { Fragment } from 'react';
const Images = require.context('../../../assets/images', true);

function StaticImage(props) {
  return (
    <Fragment>
      <img src={Images(`./${props.src}`).default} />
    </Fragment>
  );
}

export default StaticImage;
