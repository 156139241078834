import React, { Fragment } from "react";
import { Cell, Grid } from "react-foundation";
import {
  Card,
  Heading,
  Container,
  Subheading,
  ImageContainer,
  Image,
  Tag,
  CaseTitle,
} from "./CaseStudiesSection.style";

function CaseStudiesSection({ caseStudies = [] }) {
  return (
    <Fragment>
      <Container>
        <Heading>Case Studies</Heading>
        <Subheading>
          Explore nine real world examples of how LexShares litigation funding
          was used.
        </Subheading>
        <Grid className="grid-margin-x grid-margin-y grid-padding-y">
          {caseStudies.map((caseStudy, i) => (
            <Cell small={12} medium={6} large={4} key={`case-study-${i}`}>
              <Card
                className="case-study"
                href={`/case-studies/${caseStudy.slug}`}
              >
                <ImageContainer>
                  <Image src={caseStudy.banner} />
                </ImageContainer>
                <Tag>Case study - {caseStudy.case_type}</Tag>
                <CaseTitle>
                  {caseStudy.plaintiff_name} vs. {caseStudy.defendant_name}
                </CaseTitle>
              </Card>
            </Cell>
          ))}
        </Grid>
      </Container>
    </Fragment>
  );
}

export default CaseStudiesSection;
