import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import * as Globals from '../../components/Globals'

export const Content = styled(Globals.Section)`
  ${breakpoint('medium')`
    padding-top: 13%;
  `}
  ${breakpoint('large')`
    padding-top: 9%;
  `}
`
