import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

import {
  Banner,
  Content,
  CaseType,
  Heading,
  Description,
  Notice,
  NoticeContainer,
  SmallHeading,
  SmallDescription,
  DealStatus,
  DealInvested,
  DealInfo,
  ProgressContainer
} from './BannerSection.style'

function BannerSection({
  type, title, description, stage, damages, progress, amountFundedText,
  prefundingAmount, prefunded, marketplaceFund,
  commitment, fundInvestments, state, dealSize,
  displayPrefundingPop, progressText}) {

  const confidentialCopy = 'All information in this case summary is confidential and cannot be shared or posted outside LexShares.'

  return (
    <Fragment>
      <Banner>
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12} medium={8} className="text-left">
              <CaseType>
                {type}
              </CaseType>
              <Heading>
                {title}
              </Heading>
              <Description>
                {description}
              </Description>
              <NoticeContainer>
                { prefundingAmount
                    ?
                      <Notice>
                        {confidentialCopy} This deal was prefunded. <a onClick={displayPrefundingPop}>Learn more</a>.
                      </Notice>
                    :
                      <Notice>{confidentialCopy}</Notice>
                }
              </NoticeContainer>
              <Grid className="grid-margin-x">

                { marketplaceFund
                  ?
                    <Fragment>
                      <Cell small={4} medium={3}>
                        <SmallHeading className="js-lmf-cases">{fundInvestments.total_investments}</SmallHeading>
                        <SmallDescription>Invested Cases</SmallDescription>
                      </Cell>
                      <Cell small={4} medium={3}>
                        <SmallHeading className="js-lmf-resolved">{fundInvestments.resolved_investments.length}</SmallHeading>
                        <SmallDescription>Resolved Cases</SmallDescription>
                      </Cell>
                      <Cell small={4} medium={4}>
                        <SmallHeading className="js-lmf-commitment">{commitment}</SmallHeading>
                        <SmallDescription>Your Capital Commitment</SmallDescription>
                      </Cell>
                    </Fragment>
                  :
                    <Fragment>
                      <Cell small={4}>
                        <SmallHeading className="js-stage">{stage}</SmallHeading>
                        <SmallDescription>Stage</SmallDescription>
                      </Cell>
                      <Cell small={4}>
                        <SmallHeading className="js-damages">{damages}</SmallHeading>
                        <SmallDescription>Damages</SmallDescription>
                      </Cell>
                    </Fragment>
                  }
              </Grid>
            </Cell>
            <Cell small={12} medium={4} className="text-left">
              { !marketplaceFund &&
                <ProgressContainer>
                  <CircularProgressbarWithChildren value={progress} strokeWidth={4} styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: 'butt',
                    pathTransitionDuration: 0.5,
                    pathColor: "#8247BA",
                    trailColor: '#533572'
                  })}>
                    <DealStatus className="js-deal-percent">
                      {state == 'viewable' ? 'Open Soon' : `${progress}% Funded`}
                    </DealStatus>
                    <DealInvested>
                      {prefunded ? dealSize : amountFundedText}
                    </DealInvested>
                    <DealInfo>
                      {progressText}
                    </DealInfo>
                  </CircularProgressbarWithChildren>
                </ProgressContainer>
              }
            </Cell>
          </Grid>
        </Content>
      </Banner>
    </Fragment>
  )
}

export default BannerSection
