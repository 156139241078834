import styled from 'styled-components';
import * as Globals from '../../Globals'
import theme from '../../../config/theme';
import breakpoint from 'styled-components-breakpoint';

export const Header = styled(Globals.H6)`
  ${Globals.proximaFontStack}
  line-height: 32.3px;
  font-style: normal;
  font-weight: bold;
  color: ${theme.colors.backdropPurple};
  margin: 0px 0px 20px 0px;
  font-size: 19px;

  ${breakpoint('medium')`
    margin: 43px 84px 8px 0px;
    line-height: 36px;
  `}
`

export const Description = styled.p`
  ${Globals.proximaFontStack}
  font-weight: normal;
  font-size: 16px;
  line-height: 27.2px;
  color: ${theme.colors.backdropPurple};
  margin: 0px;

  ${breakpoint('medium')`
    margin-right: 84px;
    font-size: 24px;
    line-height: 36px;
  `}
`;

export const ListContainer = styled.div`
  box-shadow: 0px 0px 84px rgba(30, 9, 46, 0.12);
  border-radius: 8px;
  background-color: ${theme.colors.white};
  list-style-type: none;
  margin: 20px 0px;
  padding: 25px 25px 29px 23px;

  ${breakpoint('medium')`
    margin: 0px 40px 0px 0px;
    padding: 45px 15px 45px 53px;
  `}

  ${breakpoint('large')`
    margin: 0px 80px 0px 0px;
    padding: 55px 25px 55px 30px;
  `}
`;

export const ListItem = styled.div`
  position: relative;
`;

export const InfoItem = styled.p`
  color: ${theme.colors.backdropPurple};
  ${Globals.proximaFontStack}
  line-height: 19px;
  margin-left: 45.5px;
  font-size: 16px;

  ${breakpoint('medium')`
    margin-left: 40px;
    font-size: 21px;
    line-height: 26px;
  `}
`;

export const CheckmarkContainer = styled.div`
  position: absolute;
`;