import React, {Fragment, useState, useEffect} from 'react'
import SingleBar from '../../../../Charts/SingleBar'
import NumberFormat from 'react-number-format'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from '../../../../Axios'
import {
  Widget,
  Content,
  Heading,
  DateRange,
  Text,
  AmountContainer,
  Amount,
  AmountLabel
} from './AllInvestmentsWidget.style'

/**
 * Displays a single stacked horizontal bar chart showing all personal
 * investments broken into active and resolved.
 */
function AllInvestmentsWidget() {
  let [all, setAll] = useState(null);

  const theme = useTheme()
  const mediumUp = useMediaQuery(theme.breakpoints.up('sm'))

  useEffect(() => {
    axios.get(`/widget/all-investments.json`).then(res => {
      setAll(res.data)
    })
  }, [])

  return (
    <Widget className="js-all-investments-widget">
      <Content loading={all ? "false" : "true"}>
        { all &&
          <Fragment>
            <Heading>All Investments</Heading>
            <DateRange>{all.date_range}</DateRange>
            <Text>
              The following data represents all case investments you have made on
              the LexShares platform, including your total invested capital.
            </Text>
            <AmountContainer>
              <Amount className="js-total-cases">{all.total_cases}</Amount>
              <AmountLabel>
                Total Cases
              </AmountLabel>
            </AmountContainer>
            <AmountContainer>
              <Amount className="js-total-amount">
                <NumberFormat
                  value={all.investment_amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Amount>
              <AmountLabel>
                Investment Amount
              </AmountLabel>
            </AmountContainer>
            <SingleBar 
              moneyType="invested"
              verticalLegend={mediumUp && (all.total_partially_resolved_cases == 0) ? false : true}
              keys={all.total_partially_resolved_cases > 0 ? ['Active Cases', 'Partially Resolved Cases', 'Fully Resolved Cases'] : ['Active Cases', 'Fully Resolved Cases']}
              data={[{
                'Active Cases': all.total_active_amount_without_partial,
                'Active Cases Count': all.total_active_cases_without_partial,
                'Partially Resolved Cases': all.total_partially_resolved_amount,
                'Partially Resolved Cases Count': all.total_partially_resolved_cases,
                'Fully Resolved Cases': all.total_resolved_amount,
                'Fully Resolved Cases Count': all.total_resolved_cases
              }]}
            />
          </Fragment>
        }
      </Content>
    </Widget>
  )
}

export default AllInvestmentsWidget
