import React, { Fragment } from 'react'
import { Cell, Grid } from 'react-foundation'
import { Banner, Content, Heading, Subheading } from './BannerSection.style';

function BannerSection() {
  return (
    <Fragment>
      <Banner>
        <Content>
          <Grid className="grid-margin-x">
            <Cell small={12}>
              <Heading>
                Millions recovered, and counting.
              </Heading>
              <Subheading>
                LexShares has helped make substantial recoveries possible for our fast-growing network of attorneys and plaintiffs, some of which we feature below.
              </Subheading>
            </Cell>
          </Grid>
        </Content>
      </Banner>
    </Fragment>
  )
}

export default BannerSection;
