import styled from 'styled-components'
import * as Globals from '../../../../Globals'

export const Widget = styled(Globals.Widget)`
  overflow: auto;
  min-height: 428px;
`

export const Content = styled(Globals.FadeContent)`
  display: ${props => props.loading == "true" ? 'none' : 'block'};
`

export const Heading = styled(Globals.WidgetHeading)`
`

export const DateRange = styled(Globals.WidgetDateRange)`
`

export const Text = styled(Globals.WidgetText)`
`

export const AmountContainer = styled(Globals.WidgetAmountContainer)`
`

export const Amount = styled(Globals.WidgetAmount)`
`

export const AmountLabel = styled(Globals.WidgetAmountLabel)`
`

export const Status = styled.span`
  font-weight: 300;
`

export const ChartContainer = styled.div`
  height: 100px;
  clear: both;
`
